import FeaturedSection from "./HomePage/Featured";
import { Featured } from "./util";

const HomePage = () => {
  return (
    <div>
      <FeaturedSection
        title="FEATURED NEWS [BIG]"
        position={Featured.HOME_TOP_MAIN}
        allowedObjects={[
          "game_news",
          "staff_reviews",
          "blog_posts",
          "gallery",
          "video",
        ]}
      />
      <FeaturedSection
        title="FEATURED SIDE [SMALL]"
        position={Featured.HOME_TOP_SIDE}
      />
      <FeaturedSection
        title="FEATURED MOST READ"
        position={Featured.HOME_MOST_READ}
      />
    </div>
  );
};

export default HomePage;
