import { authRequest } from "src/utils/Axios";

export async function getUserNotifications(page = 1) {
  console.log(page);

  return await authRequest({
    url: "/notifications/api/push/notifications/list",
    method: "GET",
    params: {
      page,
    },
  });
}
