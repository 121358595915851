const CheckBox = ({ name, label, callback, type, enabled = false }) => {
  const onChangeHandler = (e) => {
    callback?.(e.target.checked, name, type);
  };
  const id = name + type;

  return (
    <div className="input">
      <input
        type="checkbox"
        name={name}
        id={id}
        checked={enabled}
        onChange={onChangeHandler}
      />
      <span className="checkmark"></span>
      <label htmlFor={id} hidden>
        {label}
      </label>
    </div>
  );
};

const Browser = ({ item, enabled, callback, type }) => {
  return (
    <CheckBox
      name={item.key}
      enabled={enabled}
      callback={callback}
      type={type}
      label={"Browser Notification"}
    />
  );
};

const Email = ({ item, enabled, callback, type }) => {
  return (
    <CheckBox
      name={item.key}
      enabled={enabled}
      callback={callback}
      type={type}
      label={"Email Notification"}
    />
  );
};
const notificationTypes = [
  { key: "email", displayText: "Email", Element: Email },
  { key: "push", displayText: "Browser", Element: Browser },
];

export default notificationTypes;
