import { useState } from "react";
import { LoadMore, sort } from "src/components/Common";
import useDebouncedCallback from "src/hooks/useDebounceCallback";
import { authRequest } from "src/utils/Axios";
import { toEuro } from "src/utils/number-format";
import { withoutLastPage } from "src/utils/withoutLastPage";
import useSWRInfinite from "swr/infinite";
import Table, { getColumn } from "../../Table/Table";
import { geoOptions } from "../Options";
import { SubmitButton } from "../../Common";
import Popup from "src/components/common/Popup";
import GeoExportPopup from "./GeoExportPopup";
import { Input } from "src/components/Member/General";
import { useToggle } from "src/hooks/useToggle";

const columns = [
  getColumn("COUNTRY", { sortKey: "name", accessKey: "name" }),
  getColumn("Amount", {
    sortKey: "amount",
    accessFn: (stat) => toEuro(stat.amount),
  }),
  getColumn("VAT Amount", {
    sortKey: "vat_amount",
    accessFn: (stat) => toEuro(stat.vat_amount),
  }),
  getColumn("VAT %", {
    sortKey: "vat",
    accessFn: (stat) => `${parseFloat(stat.vat)} %`,
  }),
  getColumn("Transactions", {
    sortKey: "transactions",
    accessKey: "transactions",
  }),
  getColumn("Treshold", { sortKey: "threshold", accessKey: "threshold" }),
];

const getGeoData = (search) => async (url) => {
  try {
    return authRequest({
      url,
      params: {
        search_term: search,
      },
    });
  } catch {
    return [];
  }
};

const Geographical = () => {
  const [showExportOption, toggleExportOption] = useToggle();
  const [search, setSearch] = useState("");

  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) => [
      `/store/api/spicy-tokens/statistics/geographical?page=${index + 1}`,
      search,
    ],
    getGeoData(search),
    { revalidateOnFocus: false }
  );

  const onSearchChange = useDebouncedCallback((text) => setSearch(text), 700);

  const stats = withoutLastPage(data?.flat?.() ?? []);

  return (
    <div className="Geo">
      <div className="actions">
        <SubmitButton
          type="secondary"
          label="Export Geo Data"
          request={toggleExportOption}
        />
        <div className="search">
          <Input callback={onSearchChange} placeholder="Search Country" />
          <i className="icon fa fa-search"></i>
        </div>
      </div>
      <Table
        columns={columns}
        data={stats}
        refreshData={() => mutate(stats, true)}
        onSort={(key, order) => mutate(sort(key, stats, order), false)}
        getOptions={() => geoOptions()}
        getPopupData={(stat) => ({
          edit_vat: {
            country_code: stat.code,
            country_name: stat.name,
            vat_percentage: stat.vat,
          },
        })}
      />
      {error && <div className="error">{error.message}</div>}
      <LoadMore onClick={() => setSize((prev) => prev + 1)} />

      <Popup
        open={showExportOption}
        onClose={toggleExportOption}
        className="member cms member-pages transactions"
      >
        <GeoExportPopup />
      </Popup>
    </div>
  );
};

export default Geographical;
