import { DEV_STORAGE_URL, STORAGE_URL } from "../constants/urls";
import { isDev } from "../utils/Axios";

const CHUNK_SIZE = 1024 * 1024 * 5; // 5mb
const URL = !isDev ? STORAGE_URL : DEV_STORAGE_URL;

export const FileTypes = {
  TRAILER: "Trailer",
  ARTICLE: "Article",
  RESOURCE: "Resource",
  VIDEO: "Community Video",
};

/**
 *
 * @param {} param0
 * @returns
 * @deprecated
 */
function FileUpload() {
  return (
    <div>
      <h1>This endpoint is not being used anymore</h1>
      <span>removed on fri 22 sept, 2023</span>
    </div>
  );
}

export default FileUpload;
