import { toast } from "react-toastify";
import { LoaderInside, sort } from "src/components/Common";
import Popup from "src/components/common/Popup";
import { useToggle } from "src/hooks/useToggle";
import { dateFormatForDateInput } from "src/utils/date-format";
import SubmitButton from "src/v2/components/SubmitButton";
import { getAllSalesByTeamId } from "src/v2/services/gameSalesService";
import useSWR from "swr";
import { z } from "zod";
import { Roles, useRoleInfo, useTeamID } from "../../RoleContext";
import { Table } from "../../Table";
import { getColumn } from "../../Table/Table";
import AddNewSale from "./AddNewSale/AddNewSale";
import { saleSchema } from "./dataModels";
import EditSale from "./EditSale";
import { Chip } from "src/components/Chip";
import { SaleProducts } from "./AddNewSale/SaleProducts";

const salesColumns = [
  getColumn("Sale Name", {
    accessFn: (sale) => (
      <div>
        {sale.name}
        {sale.public && <Chip>Public Sale</Chip>}
      </div>
    ),
    sortKey: "name",
  }),
  getColumn("Expire Date", {
    accessFn: (sale) => dateFormatForDateInput(sale.expiry),
    sortKey: "expiry",
  }),
  getColumn("Start Date", {
    accessFn: (sale) => dateFormatForDateInput(sale.start_date),
    sortKey: "start_date",
  }),
  getColumn("No. Of Products", {
    accessFn: (sale) => sale.products?.length,
  }),
];

const getSalesOptions = (isPublicSale) => {
  const allOptions = [
    {
      icon: "fa-edit",
      text: "Edit Sale",
      id: "edit",
      popup: ({ saleId }) => <EditSale saleId={saleId} />,
      popupProps: {
        maxWidth: 840,
      },
    },
    {
      icon: "fa-edit",
      text: "Edit Products",
      id: "edit_products",
      popup: ({ saleId }) => (
        <EditSale defaultPageId="products" saleId={saleId} />
      ),
      popupProps: {
        maxWidth: 840,
      },
    },
  ];

  const publicOnlyOptions = [
    {
      icon: "fa-plus",
      text: "Add Products",
      id: "public_edit_products",
      popup: ({ saleId, products }) => (
        <SaleProducts
          defaultValues={{ products }}
          defaultPageId="products"
          saleId={saleId}
        />
      ),
      popupProps: {
        maxWidth: 840,
      },
    },
  ];

  if (isPublicSale) {
    return publicOnlyOptions;
  }
  return allOptions;
};

const getSales = async (teamId) => {
  try {
    const res = await getAllSalesByTeamId(teamId);
    const sales = z.array(saleSchema).parse(res);
    const today = new Date();
    return {
      sales: sales?.filter(
        (sale) => sale.expiry >= today && sale.start_date <= today
      ),
      upcomingSales: sales?.filter(
        (sale) => sale.expiry >= today && sale.start_date > today
      ),
      previousSales: sales?.filter((sale) => sale.expiry < today),
    };
  } catch (error) {
    toast.error(error.message);
  }
};

const GameSales = () => {
  const { role } = useRoleInfo();
  const [showNewSalePopup, toggleNewSalePopup] = useToggle();
  const [showNewPublicSalePopup, toggleNewPublicSalePopup] = useToggle();

  const teamId = useTeamID();

  const { data, isValidating, mutate } = useSWR(
    ["game-sales", teamId],
    () => getSales(teamId),
    { revalidateOnFocus: false }
  );

  return (
    <div className="pt-5">
      <section>
        <legend className="flex justify-between items-center">
          <span className="flex gap-5">
            Ongoing Sales
            {isValidating && <LoaderInside className="inline !min-h-0 !w-7" />}
          </span>
          <div className="flex gap-5">
            {role === Roles.ADMIN && (
              <SubmitButton
                onClick={toggleNewPublicSalePopup}
                icon={"fa-plus"}
                className="text-base"
                type="secondary"
              >
                Add Public New Sale
              </SubmitButton>
            )}
            <SubmitButton
              onClick={toggleNewSalePopup}
              icon={"fa-plus"}
              className="text-base"
            >
              Add New Sale
            </SubmitButton>
          </div>
        </legend>

        <Table
          columns={salesColumns}
          data={data?.sales}
          getOptions={(sale) => getSalesOptions(sale.public)}
          getPopupData={(sale) => ({
            edit: { saleId: sale.id },
            edit_products: { saleId: sale.id },
            public_edit_products: { saleId: sale.id, products: sale.products },
            new_product: { saleId: sale.id },
          })}
          onSort={(key, order) =>
            mutate({ ...data, sales: sort(key, data?.sales, order) }, false)
          }
          refreshData={() => mutate(data, true)}
        />
      </section>

      {!!data?.upcomingSales?.length && (
        <section>
          <legend className="flex justify-between items-center">
            Upcoming Sales
          </legend>

          <Table
            data={data?.upcomingSales}
            columns={salesColumns}
            getOptions={(sale) => getSalesOptions(sale.public)}
            getPopupData={(sale) => ({
              edit: { saleId: sale.id },
              edit_products: { saleId: sale.id },
              public_edit_products: {
                saleId: sale.id,
                products: sale.products,
              },
              new_product: { saleId: sale.id },
            })}
            onSort={(key, order) =>
              mutate(
                { ...data, sales: sort(key, data?.upcomingSales, order) },
                false
              )
            }
            refreshData={() => mutate(data, true)}
          />
        </section>
      )}

      {!!data?.previousSales?.length && (
        <section>
          <legend className="flex justify-between items-center">
            Previous Sales
          </legend>

          <Table columns={salesColumns} data={data?.previousSales} />
        </section>
      )}
      <Popup
        maxWidth={840}
        defaultClose={false}
        showCloseButton
        open={showNewPublicSalePopup}
        onClose={() => {
          toggleNewPublicSalePopup();
          mutate(data, true);
        }}
      >
        <AddNewSale isPublicSale />
      </Popup>
      <Popup
        maxWidth={840}
        defaultClose={false}
        showCloseButton
        open={showNewSalePopup}
        onClose={() => {
          toggleNewSalePopup();
          mutate(data, true);
        }}
      >
        <AddNewSale />
      </Popup>
    </div>
  );
};

export default GameSales;
