import React from "react";
import { Link } from "react-router-dom";
import { SubmitButton } from "./CMS/Common";
import "./CMS/css/ldbtn.min.css";
import "./css/ForceLogin.css";
import { saveGotoRoute } from "src/utils/keepConsistentRouteAfterLogin";

const ForceLogin = ({ url }) => {
  return (
    <div id="force-login">
      <h2>To Continue!</h2>
      <p>Please Login or Create an Account. It is FREE</p>
      <div className="btns">
        <Link to={`/login${url}`}>
          <SubmitButton icon="fa-user-circle-o" label={"Log In"} />
        </Link>

        <Link to="/register" onClick={() => saveGotoRoute(url)}>
          <SubmitButton label={"Register"} />
        </Link>
      </div>
    </div>
  );
};

export default ForceLogin;
