import useSWRInfinite from "swr/infinite";
import RequestError from "../../../errors/RequestError";
import { withoutLastPage } from "../../../utils/withoutLastPage";
import { Header, LoadMore, sort } from "../../Common";
import { fetcher } from "../Cms";
import { REVALIDATE_ON_FOCUS } from "../Common";
import "../css/GameCreation.css";
import { MainHeader } from "../Headers";
import { ApproveTile } from "../Tile";
import { commentsModOptions } from "./Options";
import dateFormat from "dateformat";

const CommentModerations = ({ toggler, subHeader }) => {
  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) => {
      return `${
        window.domainName
      }/products/api/comments/unapproved-comments/list?page=${index + 1}`;
    },
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );

  const reports = data ? withoutLastPage(data.flat()) : [];
  const options = commentsModOptions();

  const sortState = (key) => (order) => {
    mutate(sort(key, reports, order), false);
  };
  const headers = [
    { text: "AUTHOR", sort: sortState("author") },
    { text: "COMMENT", sort: sortState("text") },
    { text: "REPORED BY", sort: sortState("reported_by") },
    { text: "POSTED DATE", sort: sortState("date_posted") },
  ];

  return (
    <div className="body">
      <MainHeader buttons={false} toggler={toggler} header={subHeader} />
      <div className="content">
        <main id="game-article">
          <div
            className="header"
            style={{
              gridTemplateColumns: `repeat(${headers.length}, var(--game-item-frac)) 2fr`,
            }}
          >
            {headers.map((header, i) => (
              <Header header={header} key={i} />
            ))}
          </div>
          <div className="body">
            {reports.map((report) => {
              const data = [
                report.author,
                report.text,
                report.reported_by,
                dateFormat(report.date_posted, "longDate"),
              ];
              return (
                <ApproveTile
                  headers={headers.map((header) => header.text)}
                  options={options}
                  revalidate={() => mutate(reports, true)}
                  popupData={{
                    view: {
                      user: report.author,
                      comment: report.text,
                    },
                    approve: {
                      user: report.author,
                      id: report.id,
                      approve: true,
                    },
                    deny: {
                      user: report.author,
                      id: report.id,
                      approve: false,
                    },
                  }}
                  data={data}
                  key={report.id}
                />
              );
            })}
          </div>
          <RequestError error={error?.message} />
          <LoadMore onClick={() => setSize((prev) => prev + 1)} />
        </main>
      </div>
    </div>
  );
};

export default CommentModerations;
