const currencies = [
  {
    id: "EUR",
    symbol: "€",
    name: "Euro",
    locale: "en-EU",
  },
  {
    id: "USD",
    symbol: "$",
    name: "United States Dollar",
    locale: "en-US",
  },
  {
    id: "GBP",
    symbol: "£",
    name: "Pound sterling",
    locale: "en-GB",
  },
  {
    id: "AUD",
    symbol: "A$",
    name: "Australian dollar",
    locale: "en-AU",
  },
  {
    id: "CAD",
    symbol: "C$",
    name: "Canadian dollar",
    locale: "en-CA",
  },
  {
    id: "DKK",
    symbol: "Kr",
    name: "Danish krone",
    locale: "da-DK",
  },
  {
    id: "CZK",
    symbol: "Kč",
    name: "Czech koruna",
    locale: "cs-CZ",
  },
  {
    id: "INR",
    symbol: "₹",
    name: "Indian rupee",
    locale: "en-IN",
  },
  {
    id: "IDR",
    symbol: "Rp",
    name: "Indonesian rupiah",
    locale: "id-ID",
  },
  {
    id: "CHF",
    symbol: "Fr.",
    name: "Swiss franc",
    locale: "fr-CH",
  },
  {
    id: "NZD",
    symbol: "$",
    name: "New Zealand dollar",
    locale: "en-NZ",
  },
  {
    id: "NOK",
    symbol: "kr",
    name: "Norwegian krone",
    locale: "nb-NO",
  },
  {
    id: "PLN",
    symbol: "zł",
    name: "Polish złoty",
    locale: "pl-PL",
  },
];
export default currencies;
