import React, { useContext } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import relativeDate from "relative-date";
import useSWRInfinite from "swr/infinite";
import { LoadMore } from "../../Common";
import { fetcher } from "../Cms";
import { REVALIDATE_ON_FOCUS } from "../Common";
import { MainHeader } from "../Headers";
import { RoleContext } from "../RoleContext";
import { ContentTile } from "../Tile";
import { reportedLinkOptions } from "./Options";

// /products/api/games/unapproved
const ReportedLinks = ({ toggler, subHeader }) => {
  const { role } = useContext(RoleContext);
  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) => {
      return `${window.domainName}/products/api/broken-links/list?page=${
        index + 1
      }`;
    },
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );

  const links = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];
  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  const options = reportedLinkOptions(role);
  const headers = ["GAME TITLE", "REPORTED BY", "REPORTED DATE"];

  return (
    <div className="body">
      <MainHeader
        toggler={toggler}
        header={subHeader}
        buttons={false}
        // element={"back-button"}
      />
      <div className="content">
        <main id="approve-content-requests" className="cms-details approve">
          <div
            className="header"
            style={{
              gridTemplateColumns: `1fr 1fr 1fr 1fr`,
            }}
          >
            {headers.map((header, i) => (
              <div className="item" key={i}>
                {header}
              </div>
            ))}
          </div>

          <TransitionGroup className="body" component="div">
            {links.map((link, i) => {
              const data = [
                link.game,
                // link.url,
                link.reported_by,
                // link.link_type,
                relativeDate(new Date(link.reported_date)),
              ];

              return (
                <CSSTransition key={i} timeout={300} classNames="popup">
                  <ContentTile
                    columns={headers.length + 1}
                    headers={headers}
                    options={options}
                    revalidate={() => mutate(links, true)}
                    popupData={{
                      edit: {
                        id: link.game_id,
                        uuid: link.reported_url_uuid,
                      },
                      delete: {
                        id: link.game_id,
                        uuid: link.reported_url_uuid,
                        game: link.game,
                        date: relativeDate(new Date(link.reported_date)),
                      },
                    }}
                    data={data}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
          {errors && <div className="error">{errors.error}</div>}
          {error && <div className="error">{error.message}</div>}
          <LoadMore onClick={() => setSize((prev) => prev + 1)} />
        </main>
      </div>
    </div>
  );
};

export default ReportedLinks;
