import { useEffect, useState } from "react";
import { LoaderInside } from "src/components/Common";
import { Player } from "src/components/Video/Player";
import { BASE_CDN_URL, request } from "src/utils/Axios";

const VideosSection = ({ teamId, teamName }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (teamId) getVideos();
  }, [teamId]);

  const getVideos = async () => {
    try {
      setLoading(true);
      const res = await request({
        url: "/community/team/videos",
        baseURL: BASE_CDN_URL,
        withCredentials: true,
        params: {
          team_id: teamId,
        },
      });
      setVideos(res);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <LoaderInside />;
  return (
    <section className="SearchPage">
      <h2 className="header">Latest {teamName} Videos</h2>
      <div className="search_results_content">
        {videos.map((video) => (
          <Player key={video.id} video={video} />
        ))}
      </div>

      {videos.length === 0 && (
        <div className="nothing">
          <p>No Videos were Created by {teamName} yet</p>
        </div>
      )}
    </section>
  );
};

export default VideosSection;
