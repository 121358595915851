import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useToggle } from "src/hooks/useToggle";
import { authRequest } from "src/utils/Axios";
import Popup from "../common/Popup";
import { generateOriginalURL, generateURL } from "../resizeImages";
import { getClass } from "src/utils/getClass";

/**
 * @typedef {Object} ImageProps 
 * @property {string} props.image - The Image Object
 * @property {string} props.owner - The owner name
 * @property {number} props.width - The width of the image.
 * @property {string} props.className 
 * @property {number} props.maxHeight - The maximum height of the image. Defaults to `DEFAULT_MAX_IMG_HEIGHT`.
 * @property {string} props.loading - The loading strategy for the image. Defaults to "lazy".
 * @property {boolean} props.noRedirect
 * @property {boolean} props.removeImageCompression
 * @property {React.Ref} ref - A ref object used to interact with the component.
  
 */

const SRCSET_VALUES = [50, 100, 200, 320, 480, 640, 768, 1024, 1280];

const DEFAULT_ASPECT_RATIO = 16 / 9;

const DEFAULT_MAX_IMG_HEIGHT = 300;

const SCALEUP_FACTOR = 1.2;

function generateSrcset(url, width, aspectRatio, widths = []) {
  const srcset = widths
    .filter((w) => w < width * SCALEUP_FACTOR)
    .map((w) => {
      const compressedImageWidth = w * SCALEUP_FACTOR;
      const compressedImageHeight = compressedImageWidth / aspectRatio;

      const src = generateURL(compressedImageWidth, compressedImageHeight, url);

      return `${src} ${w}w`;
    })
    .join(", ");

  return srcset;
}

/**
 * Spicygaming Standard image.
 * @param {ImageProps} props
 */
const Component = (
  {
    image,
    owner,
    width,
    onLoad,
    maxHeight = DEFAULT_MAX_IMG_HEIGHT,
    loading = "lazy",
    noRedirect = false,
    className,
    removeImageCompression = false,
  },
  ref
) => {
  const aspect = image?.aspect_ratio || DEFAULT_ASPECT_RATIO; // can't be null or 0

  const history = useHistory();

  const gotoCollection = () => {
    if (noRedirect) return;
    history.push(`/gallery/${image.gallery_id}?id=${image.id}`);
  };

  const finalWidth = useMemo(() => {
    const height = width / aspect;

    if (height > maxHeight) return maxHeight * aspect;
    return width;
  }, [width, maxHeight, aspect]);

  const reportImage = async () => {
    if (!image.id) return;
    try {
      await toast.promise(
        authRequest({
          url: "/products/api/spicyart/gallery/image/report",
          method: "POST",
          data: {
            image_id: image.id,
          },
        }),
        {
          pending: "Reporting...",
          success: "You've reported this image.",
          error: "Couldn't report. Try again later.",
        }
      );
    } catch {}
  };
  if (!image) return null;
  return (
    <figure className={getClass("Image", className)}>
      <picture
        itemScope
        itemType="https://schema.org/ImageObject"
        onClick={gotoCollection}
      >
        {removeImageCompression ? (
          <img
            onLoad={onLoad}
            ref={ref}
            width={finalWidth}
            height={finalWidth / aspect}
            src={generateOriginalURL(image.filepath)}
            alt={image.name}
            itemProp="contentUrl"
            loading={loading}
          />
        ) : (
          <>
            {!!image.filepath && (
              <source
                srcSet={generateSrcset(
                  image.filepath,
                  finalWidth,
                  aspect,
                  SRCSET_VALUES
                )}
                sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 800px"
              />
            )}
            <img
              onLoad={onLoad}
              ref={ref}
              width={finalWidth}
              height={finalWidth / aspect}
              src={image.image}
              alt={image.name}
              itemProp="contentUrl"
              loading={loading}
            />
          </>
        )}

        {(!!image.uploader || !!owner) && (
          <span
            itemProp="creator"
            itemType="https://schema.org/Person"
            itemScope
          >
            <meta itemProp="name" content={image.uploader ?? owner} />
          </span>
        )}
      </picture>
      {[image.description, owner, image.uploader].some((item) => !!item) && (
        <figcaption>
          <div>{image.description}</div>
          <div>by @{image.uploader ?? owner}</div>
        </figcaption>
      )}
      {!!image.id && (
        <button
          data-tooltip="Report Image"
          className="report"
          onClick={reportImage}
        >
          <i className="fa fa-flag"></i>
        </button>
      )}
    </figure>
  );
};
/**
 * @param {ImageProps} props
 */
export const ImageWithPopup = (props) => {
  const [showPopup, togglePopup] = useToggle();

  return (
    <div>
      <div onClick={togglePopup}>
        <Image {...props} noRedirect />
      </div>
      <Popup
        maxWidth={980}
        open={showPopup}
        onClose={togglePopup}
        showCloseButton
      >
        <Image {...props} width={1440} maxHeight={800} />
      </Popup>
    </div>
  );
};

const Image = React.forwardRef(Component);
export default Image;
export { default as BasicImage } from "./BasicImage";
