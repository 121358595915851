import { CustomLink } from "src/components/AdultGames/CustomSearch";
import { osIconFinder } from "src/components/common/osIconFinder";
import { MultiLang } from "src/components/MultiLang";

const GameSpecs = ({ game }) => {
  return (
    <div className="meta-info">
      <div className="cell">
        <div className="name">
          <MultiLang sl="trenutna verzija">Current Version</MultiLang>
        </div>
        <div className="content"> v{game.version}</div>
      </div>
      <div className="cell">
        <div className="name">
          <MultiLang sl="Jezik">Language</MultiLang>
        </div>
        <div className="content genres">
          {game.language?.map((language, i) => (
            <CustomLink param={language} type={"languages"} key={i}>
              <span className="genre">{language}</span>
            </CustomLink>
          ))}
        </div>
      </div>
      <div className="cell">
        <div className="name">
          <MultiLang sl="Status">Status</MultiLang>
        </div>
        <div className="content">
          <CustomLink param={game.status} type={"status"}>
            {game.status}
          </CustomLink>
        </div>
      </div>
      <div className="cell">
        <div className="name">
          <MultiLang sl="Žanr">Genre</MultiLang>
        </div>
        <div className="content genres">
          <CustomLink param={game.genre} type={"genre"}>
            <span className="genre">{game.genre}</span>
          </CustomLink>
        </div>
      </div>
      <div className="cell">
        <div className="name">
          <MultiLang sl="Orodje">Engine Used</MultiLang>
        </div>
        <div className="content">
          <CustomLink param={game.engine} type={"engine"}>
            {game.engine}
          </CustomLink>
        </div>
      </div>
      <div className="cell">
        <div className="name">
          <MultiLang sl="oznake">Tags</MultiLang>
        </div>
        <div className="content tags">
          {game.tags?.map((tag, i) => (
            <div className="tag" key={i}>
              <CustomLink type="tags" param={tag}>
                {tag}
              </CustomLink>
            </div>
          ))}
        </div>
      </div>
      <div className="cell">
        <div className="name">
          <MultiLang sl="OS">OS</MultiLang>
        </div>
        <div className="content">
          {game.operating_system?.map((os, i) => {
            const [icon] = osIconFinder(os);
            return (
              <span data-tooltip={os} key={i}>
                <CustomLink type="os" param={os}>
                  <span title={os} className={`fa ${icon}`}></span>
                </CustomLink>
              </span>
            );
          })}
        </div>
      </div>
      <div className="cell">
        <div className="name"></div>
        <div className="content"></div>
      </div>
    </div>
  );
};

export default GameSpecs;
