import { useState } from "react";
import { InnerHeader } from "../Headers";
import "../css/ArticleCommon.css";
import "../css/GameCreation.css";
import "../css/ldbtn.min.css";
import BlogArticleInfo from "./ArticleComponents/BlogArticleInfo";
import UploadArticleTabs from "./UploadArticleTabs";

/**
 * @param {Object} props
 * @param {Boolean} props.editMode - Indicates edit mode
 * @param {Object} props.defaults
 * @param {Object} props.defaults.formData - The default formdata _using in editor mode_
 * @param {Object[]} props.defaults.objects - The default objects _using in editor mode_
 * @param {Object} props.defaults.images - The default images _using in editor mode_
 * @returns
 */

//   BLOG ARTICLE -> SITE NEWS

const UploadBlogArticle = ({
  subHeader,
  header,
  defaults,
  editMode = false,
}) => {
  const [galleryID, setGalleryID] = useState(defaults?.formData.media_gallery);
  const [postID, setPostID] = useState(defaults?.id ?? null);

  return (
    <div className="body">
      <InnerHeader
        header={header}
        subHeader={subHeader}
        element={"back-button"}
      />
      {/* <AvoidRouteChange /> */}
      <div className="content">
        <main id="article-creation" className="cms-details">
          <UploadArticleTabs
            articleID={postID}
            Info={BlogArticleInfo}
            propsForEachPageComponent={{
              galleryID,
              setGalleryID,
              setPostID,
              postID,
              defaults,
              editMode,
              type: "Blog Posts",
            }}
          />
        </main>
      </div>
    </div>
  );
};

export default UploadBlogArticle;
