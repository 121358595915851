import { authRequest, request } from "src/utils/Axios";
import { dateFormatForBackend } from "src/utils/date-format";

export async function createBundle({
  name,
  teamId,
  startDate,
  expirationDate,
}) {
  return await authRequest({
    url: "/store/api/teams/manage-bundles",
    data: {
      name,
      team_id: teamId,
      expiry: dateFormatForBackend(expirationDate),
      start_date: dateFormatForBackend(startDate),
    },
    method: "POST",
  });
}

export async function editBundle({
  bundleId,
  name,
  startDate,
  expirationDate,
}) {
  await authRequest({
    url: "/store/api/teams/manage-bundles",
    data: {
      name,
      bundle_id: bundleId,
      expiry: dateFormatForBackend(expirationDate),
      start_date: dateFormatForBackend(startDate),
    },
    method: "PUT",
  });
}

export async function deleteBundle(bundleId) {
  await authRequest({
    url: "/store/api/teams/manage-bundles",
    data: {
      bundle_id: bundleId,
    },
    method: "DELETE",
  });
}

export async function getAllBundlesByTeamId(teamId) {
  return await authRequest({
    url: "/store/api/teams/manage-bundles",
    method: "GET",
    params: { team_id: teamId },
  });
}

export async function getTeamBundleByBundleId(bundleId, teamId) {
  return await authRequest({
    url: "/store/api/teams/manage-bundles",
    method: "GET",
    params: { bundle_id: bundleId, team_id: teamId },
  });
}

export async function addProductsToBundles({ bundleId, products = [] }) {
  return await authRequest({
    url: "/store/api/teams/manage-bundles/sale-products",
    method: "POST",
    data: {
      products,
      bundle_id: bundleId,
    },
  });
}

export async function getBundlesByGameId(gameId) {
  return await request({
    url: "/products/api/games/bundles",
    params: {
      game_id: gameId,
    },
  });
}

export async function getFullBundleByBundleId(bundleId) {
  return await request({
    url: "/store/api/bundles",
    params: {
      bundle_id: bundleId,
    },
  });
}
