import { useMemo, useState } from "react";
import { Link, generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ASPECT_RATIO } from "src/components/Video/Player/Player";
import Popup from "src/components/common/Popup";
import { generateURL } from "src/components/resizeImages";
import { SubmitButton } from "../CMS/Common";
import { Avatar } from "../common/Avatar";
import "./style.css";
import shrinkText from "src/utils/shrinkText";
import { authRequest } from "src/utils/Axios";
import { BasicImage } from "./Image";

const COLLECTION_MAX_WIDTH = 400;

const ImageGallery = ({
  collection,
  onActionTriggered,
  horizontal = false,
  actions = [],
  maxWidth = COLLECTION_MAX_WIDTH,
}) => {
  const history = useHistory();
  const [confirmation, setConfirmation] = useState(null);
  const clearConfirmation = () => setConfirmation(null);

  const allActions = useMemo(
    () => [
      {
        id: "public",
        icon: "fa-user",
        text: collection.public ? "Unpublic Gallery" : "Make Gallery Public",
        confirmination: {
          title: collection.public
            ? "Unpublic gallery"
            : "Make This gallery public",
          description:
            "By making this public, you're inviting members of Spicygaming to upload their images to this gallery",
        },
        onClick: async (gallery) => {
          await authRequest({
            url: "/products/api/spicyart/gallery/make-public",
            method: "POST",
            data: {
              gallery_id: gallery.id,
            },
          });
        },
      },
      {
        id: "download",
        icon: "fa-download",
        text: collection.allow_downloads ? "Stop Downloads" : "Allow Downloads",
        confirmination: {
          title: collection.allow_downloads
            ? "Stop Gallery from Download"
            : "Allow Gallery to Download",
          description:
            "By allowing gallery downloads, you let Spicygaming users to download this gallery as an archive. You can stop download access at any time.",
        },
        onClick: async (gallery) => {
          await toast.promise(
            authRequest({
              url: "/products/api/spicyart/gallery/create-archive",
              method: "POST",
              data: {
                gallery_id: gallery.id,
              },
            }),
            { success: "We will notify you when the Archive is ready" }
          );
        },
      },
      {
        id: "edit",
        icon: "fa-edit",
        text: "Edit Collection",
        onClick: () =>
          history.push(
            generatePath("/cms/content/spicy-art/edit/:id", {
              id: collection.id,
            })
          ),
      },
      {
        id: "delete",
        icon: "fa-trash",
        text: "Delete Gallery",
        confirmination: {
          title: "Are you sure you want to delete this Gallery?",
          description:
            "This gallery will be removed completely from the platform",
        },
        onClick: async (gallery) => {
          await authRequest({
            url: "/products/api/spicyart/gallery",
            method: "DELETE",
            data: {
              gallery_id: gallery.id,
            },
          });
        },
      },
    ],
    [history, collection.id]
  );
  const selectedActionForConfirmation = useMemo(
    () => allActions.find((action) => action.id === confirmation),
    [allActions, confirmation]
  );

  return (
    <div
      className="Player MiniPlaylist ImageGallery"
      data-horizontal={horizontal}
      data-testid="MiniPlaylist"
    >
      <Link to={`/gallery/${collection.id}`}>
        <div className="image">
          <BasicImage
            width={maxWidth}
            height={maxWidth / ASPECT_RATIO}
            alt={collection.name}
            src={collection.cover.filepath}
          />
          <div className="count">
            <i className="fa fa-list"></i>
            <span>{collection.image_count ?? 0} Images</span>
          </div>
        </div>
      </Link>

      <div className="details">
        {!horizontal && (
          <Avatar
            width={40}
            src={generateURL(72, 72, null)}
            name={collection.owner}
          />
        )}

        <div className="info">
          <h3 title={collection.name}>
            <Link to={`/gallery/${collection.id}`}>
              {shrinkText(collection.name, 30)}
            </Link>
          </h3>
          <Link to={`/team/@${collection.owner}/galleries`}>
            <span className="uploader">{collection.owner}</span>
          </Link>
        </div>

        {actions.length > 0 && (
          <button className="options">
            <span className="fa fa-ellipsis-v"></span>
            <div className="menu">
              {allActions
                .filter((action) => actions.includes(action.id))
                .map((action) => {
                  const onClickOption = async () => {
                    if (action.confirmination)
                      return setConfirmation(action.id);
                    const output = action.onClick?.(collection);
                    if (output instanceof Promise) {
                      await toast.promise(output, {
                        pending: "Working...",
                        success: "Successful",
                        error: "Error Occured",
                      });
                    }
                    onActionTriggered?.(action.id, collection);
                  };
                  return (
                    <div
                      role="button"
                      className="opt"
                      key={action.id}
                      onClick={onClickOption}
                    >
                      <span className={`fa ${action.icon}`}></span>
                      <span>{action.text}</span>
                    </div>
                  );
                })}
            </div>
          </button>
        )}
      </div>

      <Popup
        open={!!confirmation}
        onClose={clearConfirmation}
        showCloseButton
        className={"Player_action_confirmation"}
      >
        <h3>{selectedActionForConfirmation?.confirmination?.title}</h3>
        <p>{selectedActionForConfirmation?.confirmination?.description}</p>

        <SubmitButton
          label={selectedActionForConfirmation?.text}
          icon={selectedActionForConfirmation?.icon}
          request={async () => {
            const output = selectedActionForConfirmation?.onClick?.(collection);
            if (output instanceof Promise) {
              await toast.promise(output, {
                pending: "Working...",
                success: "Successful",
                error: "Error Occured",
              });
            }
            onActionTriggered?.(selectedActionForConfirmation?.id, collection);

            clearConfirmation();
          }}
        />
      </Popup>
    </div>
  );
};

export default ImageGallery;
