import Image from "src/components/SpicyArt/Image";
import useSWRInfinite from "swr/infinite";
import RequestError from "../../../errors/RequestError";
import { withoutLastPage } from "../../../utils/withoutLastPage";
import { LoadMore } from "../../Common";
import { fetcher } from "../Cms";
import { REVALIDATE_ON_FOCUS, SubmitButton } from "../Common";
import { MainHeader } from "../Headers";
import "../css/GameCreation.css";
import { authRequest } from "src/utils/Axios";
import { toast } from "react-toastify";

const url = "/products/api/spicyart/gallery/image/approve";
const ImageModerations = ({ toggler, subHeader }) => {
  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) => {
      return `${window.domainName + url}?page=${index + 1}`;
    },
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );

  const reports = data ? withoutLastPage(data.flat()) : [];

  const approveImage = (approve, id) => async () => {
    try {
      const res = await authRequest({
        url,
        method: "POST",
        data: {
          approve,
          image_id: id,
        },
      });
      if (res.succes) toast.success("Successful");
      mutate(data, true);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="body ImageReports">
      <MainHeader buttons={false} toggler={toggler} header={subHeader} />
      <div className="content">
        <main id="game-article">
          <div className="body">
            {reports.map((report) => {
              return (
                <div className="report-image">
                  <Image image={report} key={report.id} width={400} />
                  <div className="actions">
                    <SubmitButton
                      label="Approve"
                      request={approveImage(true, report.id)}
                    />
                    <SubmitButton
                      label="Deny"
                      type="secondary"
                      request={approveImage(false, report.id)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <RequestError error={error?.message} />
          <LoadMore onClick={() => setSize((prev) => prev + 1)} />
        </main>
      </div>
    </div>
  );
};

export default ImageModerations;
