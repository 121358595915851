import React, { useEffect, useState } from "react";
import { LoaderInside } from "src/components/Common";
import { Player } from "src/components/Video/Player";
import { authRequest } from "src/utils/Axios";
import { withoutLastPage } from "src/utils/withoutLastPage";
import { SubmitButton } from "../Common";
import { useToggle } from "src/hooks/useToggle";
import VideoUpload from "src/components/Video/Upload/Upload";

const VideoSelect = ({ getVideoID, defaultSuggestions, alreadyAdded = [] }) => {
  const [loading, setLoading] = useState(true);
  const [searchedVideos, setSearchedVideos] = useState([]);
  const [query, setQuery] = useState("");
  const [showUploadPage, toggleUploadPage] = useToggle(false);

  useEffect(() => {
    searchVideos();
  }, []);

  const searchVideos = async (query) => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: "/community/community-videos/list",
        params: { page: 1, search_term: query },
      });
      setSearchedVideos(withoutLastPage(res));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="object-selector VideoSelect" id="video-upload">
      <legend>Add Video</legend>
      <div className="title">
        <div className="search">
          <input
            type="search"
            id="object-selecter-search"
            placeholder="Search.."
            onChange={(e) => setQuery(e.target.value)}
          />
          <button
            className="fa fa-search"
            id="object-selecter-submit"
            onClick={() => {
              searchVideos(query);
            }}
          ></button>
        </div>
        <SubmitButton
          type="secondary"
          className="upload"
          icon={showUploadPage ? "fa-search" : "fa-upload"}
          label={showUploadPage ? "Browse Videos" : "Upload a New Video"}
          request={() => toggleUploadPage()}
        />
      </div>
      {showUploadPage ? (
        <VideoUpload
          onSuccess={getVideoID}
          defaultSuggestions={defaultSuggestions}
        />
      ) : loading ? (
        <LoaderInside />
      ) : (
        <div className="content adult-games">
          {searchedVideos.map((video) => (
            <Player
              video={video}
              key={video.id}
              getVideoId={getVideoID}
              disableAction={alreadyAdded.includes(video.id)}
            />
          ))}
        </div>
      )}
    </section>
  );
};

export default VideoSelect;
