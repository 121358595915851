import axios from "axios";
import React, { useState } from "react";
import GoogleLogin from "react-google-login";
import { useToggle } from "../hooks/useToggle";
import { useLanguage } from "../providers/LanguageProvider";
import { SubmitButton } from "./CMS/Common";
import Popup from "./common/Popup";
import "./css/ForgotPassword.css";
import { MultiLang } from "./MultiLang";
import { inputPlaceholders } from "./Register";
import { GOOGLE_CLIENT_ID } from "src/constants/config";

const ForgotPassword1 = () => {
  const language = useLanguage();

  const [email, setEmail] = useState("");
  const [reset, toggleReset] = useToggle(false);
  const [error, setError] = useState(false);

  const googleLogin = (res) => {
    const email = res.profileObj.email;
    var data = new FormData();
    data.append("email", email);

    var config = {
      method: "post",
      url: window.domainName + "/api/social-login",

      data: data,
    };

    axios(config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        setError(error.message);
      });
  };
  const googleLoginError = (e) => {
    console.log(e);
  };

  const forgetPassword = async () => {
    // setReset(true);

    if (email === "") {
      setError("Empty Credentials");
    } else {
      try {
        setError("Requesting...");

        const req = await axios({
          url: `${window.domainName}/api/members/request-password-reset`,
          method: "POST",
          data: { email },
        });
        const res = await req.data;

        if (res.success) {
          toggleReset();
        } else {
          setError(res.error);
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const input = (value, state) => state(value);

  return (
    <main className="login" id="forgot-password">
      <div className="inner">
        <header>
          <MultiLang sl="POZABLJENO GESLO">FORGOT PASSWORD</MultiLang>
        </header>
        <div className="message">
          {!error ? (
            <MultiLang sl="Vpišite vaš email naslov">
              Please provide your email to proceed
            </MultiLang>
          ) : (
            <div className="error">{error}</div>
          )}
        </div>
        <div className="inputs">
          <div className="input">
            <div className="fa fa-envelope"></div>
            <input
              type="email"
              onChange={(e) => input(e.target.value, setEmail)}
              value={email}
              id="login-email"
              placeholder={inputPlaceholders(language, "email")}
            />
          </div>
        </div>
        <SubmitButton
          label={<MultiLang sl="Nadaljuj">Continue</MultiLang>}
          request={forgetPassword}
          id="password-reset"
        />

        <div className="footer">
          <div className="title">
            <MultiLang sl="Ali pa se prijavi z">or login with</MultiLang>
          </div>
          <div className="oauth">
            <div className="oauth-btn">
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                // buttonText="Log in with Google"
                onSuccess={googleLogin}
                onFailure={googleLoginError}
                theme="dark"
                tag="div"
                className="google-btn"
                scope="profile email"
                cookiePolicy={"single_host_origin"}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  id="google-logo"
                >
                  <path
                    d="M4.432,6.685l-.7,2.6-2.544.054A10.017,10.017,0,0,1,1.118,0h0L3.384.415l.992,2.252a5.968,5.968,0,0,0,.056,4.018Z"
                    transform="translate(0 5.401)"
                    fill="#fbbb00"
                  />
                  <path
                    d="M9.605,0A10.011,10.011,0,0,1,9.78,1.868a10.019,10.019,0,0,1-.219,2.088,10,10,0,0,1-3.521,5.71h0L3.187,9.52,2.783,7A5.96,5.96,0,0,0,5.347,3.956H0V0H9.605Z"
                    transform="translate(10.22 8.132)"
                    fill="#518ef8"
                  />
                  <path
                    d="M15.068,5.712h0a9.958,9.958,0,0,1-6.26,2.2A10,10,0,0,1,0,2.653L3.241,0A5.946,5.946,0,0,0,8.808,3.858a5.913,5.913,0,0,0,3-.813Z"
                    transform="translate(1.192 12.086)"
                    fill="#28b446"
                  />
                  <path
                    d="M15.265,2.3l-3.24,2.652A5.947,5.947,0,0,0,3.258,8.068L0,5.4H0A10,10,0,0,1,15.265,2.3Z"
                    transform="translate(1.118)"
                    fill="#f14336"
                  />
                </svg>
              </GoogleLogin>
            </div>
          </div>
        </div>
      </div>

      <Popup
        id="forgot-popup"
        showCloseButton
        open={reset}
        onClose={toggleReset}
      >
        <div>
          <div className="title">PASSWORD HAS BEEN RESET</div>
          <div className="description">
            Password reset link has been sent to your email, please check your
            email to set your new password
          </div>
        </div>
      </Popup>
    </main>
  );
};

export default ForgotPassword1;
