import { useState } from "react";

import RequestError from "src/errors/RequestError";
import { authRequest } from "src/utils/Axios";
import { SubmitButton } from "../../Common";
import { Input } from "src/components/Member/General";

const GeoExportPopup = () => {
  const [dates, setDates] = useState({});
  const [error, setError] = useState();

  const updateDates = (key) => (value) =>
    setDates((prev) => ({ ...prev, [key]: value }));

  const submit = async () => {
    try {
      if (!(dates.start_date && dates.end_date))
        throw new Error("Both dates are required");

      const res = await authRequest({
        url: `/store/api/spicy-tokens/statistics/geographical/export`,
        data: {
          ...dates,
        },
        method: "POST",
      });
      if (res.success) setError("Successfully requested");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="export-popup">
      <h2>Export Geo Data</h2>
      <div className="inputs">
        <div className="dates">
          <Input
            label="Start Date"
            type="date"
            callback={updateDates("start_date")}
          />
          <Input
            label="End Date"
            type="date"
            callback={updateDates("end_date")}
          />
        </div>
      </div>

      <RequestError error={error} />
      <SubmitButton label={"Request"} request={submit} />
    </div>
  );
};

export default GeoExportPopup;
