import { useMemo, useState } from "react";
import currencies from "src/constants/currencies";
import { useSpicyStore } from "src/providers/SpicyStoreProvider";
import { convertToSpicyTokens } from "src/utils/currency-format";
import { useToggle } from "../../hooks/useToggle";
import { useLanguage } from "../../providers/LanguageProvider";
import { sort } from "../Common";
import { Input } from "../Member/General";
import { Checkbox } from "../Register";

const translations = [
  {
    key: "tags",
    label: { en: "tags", sl: "Oznake" },
  },
  {
    key: "os",
    label: { en: "Operating System", sl: "OS" },
  },
  {
    key: "status",
    label: { en: "status", sl: "Status" },
  },
  {
    key: "genre",
    label: { en: "genre", sl: "Žanr" },
  },
  {
    key: "languages",
    label: { en: "languages", sl: "Jezik" },
  },
  {
    key: "engine",
    label: { en: "engine", sl: "Orodje" },
  },
  {
    key: "download_type",
    label: { en: "Download Type", sl: "" },
  },
  {
    key: "video tags",
    label: { en: "Tags", sl: "" },
  },
  {
    key: "price",
    label: { en: "Price", sl: "" },
  },
  {
    key: "gallery_types",
    label: { en: "Gallery Types", sl: "" },
  },
];

const FilterMini = ({ title, options, search, setFilter, filters }) => {
  options = sort("name", options, "asc");

  const language = useLanguage();

  const [expand, toggleExpand] = useToggle(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchOptions, setSearchOptions] = useState(options);

  const optionsArray = search ? searchOptions : options;

  const searchSetter = (e) => {
    if (search) {
      setSearchKeyword(e.target.value);
      let filteredOptions = options.filter((option) =>
        option.name.toLowerCase().includes(searchKeyword) ? option : null
      );
      e.target.value.length !== 0
        ? setSearchOptions(filteredOptions)
        : setSearchOptions(options);
    }
  };

  const displayTitle = useMemo(
    () => translations.find((trans) => trans.key === title)?.label[language],
    [language, title]
  );

  const toggleFilter = (checked, title, param) => {
    if (checked) {
      setFilter((prev) => {
        return {
          ...prev,
          [title]: [...prev[title], param],
        };
      });
    } else {
      setFilter((prev) => ({
        ...prev,
        [title]: prev[title].filter((filter) => filter !== param),
      }));
    }
  };
  return (
    <div role="group" aria-label={title} className="filter">
      <div className={`title ${expand ? "show" : ""}`} onClick={toggleExpand}>
        {displayTitle}
      </div>
      <div className="content">
        {search && (
          <div className="search-bar">
            <input
              type="search"
              onChange={searchSetter}
              className="search"
              placeholder={"search..."}
            />
            <div aria-hidden className="fa fa-search"></div>
          </div>
        )}
        <ul aria-label="Filter Options" className="inputs">
          {optionsArray.map((input, i) => {
            const id = `${input.name}-${i}`;
            const allOptions = filters[title] || [];
            return (
              <li aria-label={input.name} className="input" key={id}>
                <div className="w-full">
                  <Checkbox
                    id={id}
                    value={allOptions.includes(input.param)}
                    label={input.name}
                    callback={(_, checked) =>
                      toggleFilter(checked, title?.toLowerCase(), input.param)
                    }
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export const PriceFilter = ({ filters, setFilter }) => {
  const language = useLanguage();
  const spicyStore = useSpicyStore();

  const localCurrency = currencies.find(
    (c) => c.id === spicyStore.localCurrencyId
  );

  const [expand, toggleExpand] = useToggle(false);

  const displayTitle = useMemo(
    () =>
      translations.find((trans) => trans.key === "price")?.label?.[language],
    [language]
  );

  const onChange = (key) => (priceInLocalCurrency) => {
    const eurPrice = spicyStore.localPriceToEuro(priceInLocalCurrency);
    const tokenAmount = convertToSpicyTokens(eurPrice);

    if (isNaN(tokenAmount)) return;

    return setFilter((prev) => ({
      ...prev,
      [key]: tokenAmount,
    }));
  };

  return (
    <div className="filter filter--price">
      <div className={`title ${expand ? "show" : ""}`} onClick={toggleExpand}>
        {displayTitle}
      </div>
      <div className="content">
        <Checkbox
          checked={filters.on_sale}
          label="Show Only Games on Sales"
          id="games-in-sale"
          callback={(_, checked) =>
            setFilter((prev) => ({ ...prev, on_sale: checked }))
          }
        />

        <div className="flex justify-evenly items-center gap-3">
          <Input
            label={`Min (${localCurrency.symbol})`}
            callback={onChange("price_min")}
          />
          <span className="dash">-</span>
          <Input
            label={`Max (${localCurrency.symbol})`}
            callback={onChange("price_max")}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterMini;
