import { toast } from "react-toastify";
import { LoaderInside, sort } from "src/components/Common";
import Popup from "src/components/common/Popup";
import { useToggle } from "src/hooks/useToggle";
import { dateFormatForDateInput } from "src/utils/date-format";
import SubmitButton from "src/v2/components/SubmitButton";
import { getAllBundlesByTeamId } from "src/v2/services/gameBundlesService";
import useSWR from "swr";
import { z } from "zod";
import { useTeamID } from "../../../RoleContext";
import { Table } from "../../../Table";
import { getColumn } from "../../../Table/Table";
import { bundleSchema } from "../dataModels";
import AddNewBundle from "./AddNewBundle";
import { default as EditBundle, default as EditSale } from "./EditBundle";
import { LocalPrice } from "src/components/LocalPrice";
import { getDiscountRate } from "src/utils/getDiscountRate";
import DeleteBundle from "./DeleteBundle";
import { RedirectTo } from "src/components/CMS/Common";

const bundleColumns = [
  getColumn("Bundle Name", {
    accessFn: (bundle) => <div>{bundle.name}</div>,
    sortKey: "name",
  }),
  getColumn("Price", {
    accessFn: (bundle) => (
      <div className="leading-none flex gap-1">
        <strike className="text-neutral-500">
          <LocalPrice
            hideDisclaimer
            amountInEUR={bundle.price?.total}
            showOnlyLocalPrice
          />
        </strike>
        <div className="font-medium">
          <LocalPrice
            hideDisclaimer
            amountInEUR={bundle.price?.discounted}
            showOnlyLocalPrice
          />
        </div>
      </div>
    ),
  }),
  getColumn("Total Discount", {
    accessFn: (bundle) => {
      const discountRate = getDiscountRate(
        bundle.price?.total,
        bundle.price?.discounted
      );

      if (!discountRate) return <div>No Discount</div>;

      return <div>{discountRate}%</div>;
    },
  }),
  getColumn("Expire Date", {
    accessFn: (bundle) => dateFormatForDateInput(bundle.expiry),
    sortKey: "expiry",
  }),
  getColumn("No. Of Products", {
    accessFn: (bundle) => bundle.products?.length,
  }),
];

const getBundlesOptions = () => {
  const allOptions = [
    {
      icon: "fa-eye",
      text: "View Bundle",
      id: "view",
      popup: ({ bundleId }) => <RedirectTo to={`/game-bundle/${bundleId}`} />,
    },
    {
      icon: "fa-edit",
      text: "Edit Bundle",
      id: "edit",
      popup: ({ bundleId }) => <EditBundle bundleId={bundleId} />,
      popupProps: {
        maxWidth: 840,
      },
    },
    {
      icon: "fa-edit",
      text: "Edit Products",
      id: "edit_products",
      popup: ({ bundleId }) => (
        <EditSale defaultPageId="products" bundleId={bundleId} />
      ),
      popupProps: {
        maxWidth: 840,
      },
    },
    {
      icon: "fa-trash",
      text: "Delete Bundle",
      id: "delete",
      popup: DeleteBundle,
      danger: true,
    },
  ];
  return allOptions;
};

const getBundles = async (teamId) => {
  try {
    const res = await getAllBundlesByTeamId(teamId);
    const bundles = z.array(bundleSchema).parse(res);
    return bundles;
  } catch (error) {
    toast.error(error.message);
  }
};

const GameBundles = () => {
  const [showNewBundlePopup, toggleNewBundlePopup] = useToggle();

  const teamId = useTeamID();

  const { data, isValidating, mutate } = useSWR(
    ["game-bundles", teamId],
    () => getBundles(teamId),
    { revalidateOnFocus: false }
  );

  return (
    <div className="pt-5">
      <section>
        <legend className="flex justify-between items-center">
          <span className="flex gap-5">
            Your Bundles
            {isValidating && <LoaderInside className="inline !min-h-0 !w-7" />}
          </span>
          <div className="flex gap-5">
            <SubmitButton
              onClick={toggleNewBundlePopup}
              icon={"fa-plus"}
              className="text-base"
            >
              Add New Bundle
            </SubmitButton>
          </div>
        </legend>

        <div className="my-10 space-y-5">
          <p className="text-neutral-400">
            Bundles allow you to group multiple products into a single,
            discounted offer. To create a bundle, start by naming it and
            optionally setting its availability period. Next, select the
            products you want to include in the bundle and specify the discount
            for each one.
          </p>
          <p className="text-neutral-400">
            When a customer purchases the bundle, they will receive all the
            products included in the offer.
          </p>
        </div>

        <Table
          columns={bundleColumns}
          data={data}
          getOptions={() => getBundlesOptions()}
          getPopupData={(bundle) => ({
            edit: { bundleId: bundle.id },
            edit_products: { bundleId: bundle.id },
            view: { bundleId: bundle.id },
            delete: { bundleId: bundle.id, bundleName: bundle.name },
          })}
          onSort={(key, order) =>
            mutate({ ...data, sales: sort(key, data, order) }, false)
          }
          refreshData={() => mutate(data, true)}
        />
      </section>

      <Popup
        maxWidth={840}
        defaultClose={false}
        showCloseButton
        open={showNewBundlePopup}
        onClose={() => {
          toggleNewBundlePopup();
          mutate(data, true);
        }}
      >
        <AddNewBundle />
      </Popup>
    </div>
  );
};

export default GameBundles;
