import React from "react";
import "./Preview.css";
import { loggedInValidation } from "../Validation";
import Error404 from "../Errors";
import { createContext } from "react";

export const PreviewContext = createContext(false);

const Preview = ({ children }) => {
  if (!loggedInValidation()) return <Error404 />;

  return (
    <PreviewContext.Provider value={true}>
      <div className="preview-page">
        <h2>This is a preview! Make sure you publish your changes!</h2>
        {children}
      </div>
    </PreviewContext.Provider>
  );
};

export default Preview;
