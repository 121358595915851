import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import {
  getAdultGameReviews,
  getBestOfPorn,
  getBestPornGamesOfYear,
  getGameNewsForYou,
  getInterviews,
  getLatestGameNews,
  getLatestIndustryBlog,
  getMostReadNews,
} from "./Article/getArticles";
import Banner from "./Banners";
import { Featured } from "./CMS/Ads/FeaturedListPages/util";
import Header from "./Header";
import DontMissSection from "./Home/DontMissWrapper";

import "./css/Article.css";
import { LazyLoaded } from "./LazyLoaded";
import { LoaderInside } from "./Common";
import FeaturedGames from "./Home/sections/FeaturedGames";
import { getFeaturedGames } from "src/v2/services/gamesService";

const PublicPageNewsSection = React.lazy(() =>
  import("./PublicPageNewsSection/PublicPageNewsSection")
);

const Article = () => {
  const { query } = useParams();
  const history = useHistory();

  const search = ({ search }) => {
    if (search?.length <= 0) return;
    history.push(`/articles/${search}/1`);
  };

  return (
    <>
      <Helmet>
        <title>Porn game news, Adult game reviews - Spicygaming</title>
        <meta
          name="description"
          content="News from the adult gaming world. Porn game reviews, Adult game news, News from adult gaming developers. Game walkthroughs."
        />
        <link
          rel="canonical"
          href="https://www.spicygaming.net/articles/"
        ></link>
      </Helmet>
      <main id="article" className="article ">
        <div className="inner">
          <Header
            gameManu={false}
            searchCallback={search}
            defaultQuery={query}
          />
          <DontMissSection
            position={Featured.FEATURED_ARTICLES}
            title="FEATURED NEWS"
            sectionRedirectLink={"/articles"}
          />
          <LazyLoaded rootMargin={"200px"}>
            <Suspense fallback={<LoaderInside />}>
              <PublicPageNewsSection
                bigListLink={"/articles/porn-game-news/1"}
                smallListLink={"/articles/best-of-porn/1?sort=last_updated"}
                bigListTitle={"Latest Porn Game News"}
                smallListTitle={"The Best of Porn"}
                getArticlesForBigList={getLatestGameNews}
                getArticlesForSmallList={getBestOfPorn}
                adComponent={
                  <Banner
                    id={"article:1"}
                    width={970}
                    height={250}
                    jsAd="86032"
                  />
                }
              />
            </Suspense>
          </LazyLoaded>
          <LazyLoaded rootMargin={"200px"}>
            <Suspense fallback={<LoaderInside />}>
              <PublicPageNewsSection
                bigListLink={"/articles/1?sort=views"}
                smallListLink={"/articles/news-from-adult-industry/1"}
                bigListTitle={"MOST READ ARTICLES"}
                smallListTitle={"LATEST PORN INDUSTRY NEWS"}
                getArticlesForBigList={getMostReadNews}
                getArticlesForSmallList={getLatestIndustryBlog}
                adComponent={
                  <Banner
                    id={"article:2"}
                    width={970}
                    height={250}
                    jsAd="86033"
                  />
                }
              />
            </Suspense>
          </LazyLoaded>
          <LazyLoaded rootMargin={"200px"}>
            <Suspense fallback={<LoaderInside />}>
              <FeaturedGames
                id="featured-games"
                title={"Featured & Recommended"}
                getGames={getFeaturedGames}
              />
              <PublicPageNewsSection
                bigListLink={"/articles/porn-game-reviews/1?sort=last_updated"}
                smallListLink={"/articles/best-of-porn/1?sort=last_updated"}
                bigListTitle={"Adult Game reviews"}
                smallListTitle={`Best porn games of ${new Date().getFullYear()}`}
                getArticlesForBigList={getAdultGameReviews}
                getArticlesForSmallList={getBestPornGamesOfYear}
                adComponent={
                  <Banner
                    id={"article:3"}
                    width={970}
                    height={250}
                    jsAd="86034"
                  />
                }
              />
            </Suspense>
          </LazyLoaded>
          <LazyLoaded rootMargin={"200px"}>
            <Suspense fallback={<LoaderInside />}>
              <PublicPageNewsSection
                bigListLink={
                  "/articles/interviews-adult-content-creators/1?sort=last_updated"
                }
                smallListLink={"/articles/?sort=views"}
                bigListTitle={"Interviews with XXX Creators"}
                smallListTitle={"News for you"}
                getArticlesForBigList={getInterviews}
                getArticlesForSmallList={getGameNewsForYou}
                adComponent={
                  <Banner
                    id={"article:4"}
                    width={970}
                    height={250}
                    jsAd="86031"
                  />
                }
              />
              <DontMissSection
                position={Featured.DONT_MISS}
                title="DON'T MISS"
              />
            </Suspense>
          </LazyLoaded>
        </div>
      </main>
    </>
  );
};

export const NotFound = () => {
  return (
    <div className="not-found">
      <div>Not found</div>
    </div>
  );
};

export default Article;
