import { useEffect, useState } from "react";
import useCheckForURLBlock from "src/hooks/useCheckForURLBlock";
import { useIndexedDBGlobal } from "src/providers/IndexedDBProvider";
import { SubmitButton } from "../CMS/Common";
import { loggedInValidation } from "../Validation";
import Popup from "../common/Popup";
import "./warnings.css";

const key = "age_accepted";

function AdultWarn() {
  const renderedInEmbed = useCheckForURLBlock("embed");

  const [accepted, setAccepted] = useState(true);

  const db = useIndexedDBGlobal();

  useEffect(() => {
    db.get(key, (data) => {
      setAccepted(data);
    });
  }, [db]);

  const confirm = () => {
    db.set(key, true);
    setAccepted(true);
  };
  if (renderedInEmbed) return null;
  return (
    <div>
      <Popup className="adult-popup" open={!accepted && !loggedInValidation()}>
        <div data-nosnippet="data-nosnippet">
          <span className="heading">Important</span>
          <p>
            THIS PAGE IS INTENDED FOR MATURE AUDIENCE ONLY. PLEASE CONFIRM YOU
            ARE OF LEGAL AGE OR CLOSE THIS WEBSITE
          </p>
          <SubmitButton
            label="I am 18 or older"
            id="adult-confirm"
            request={confirm}
          />
        </div>
      </Popup>
    </div>
  );
}

export default AdultWarn;
