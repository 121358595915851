import Input from "src/v2/components/form-inputs/Input";
import Form from "src/v2/components/form/Form";
import { getUserBillingInfo } from "src/v2/services/storeService";
import useSWR from "swr";
import { z } from "zod";
import SelectCountry from "../Member/SelectCountry";
import { Checkbox } from "../Register";
import { useEffect } from "react";

export const billingSchema = z.object({
  address: z.string(),
  city: z.string(),
  post_code: z.string(),
  country: z.string(),
});

const BillingForm = ({ form, shouldSaveBilling, toggleShouldSaveBilling }) => {
  const { data, isValidating } = useSWR(
    "user-billing",
    () => getUserBillingInfo(),
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (!data) return;
    if (isValidating) return;

    form.setValue("post_code", data?.post_code);
    form.setValue("country", data?.country);
    form.setValue("city", data?.city);
    form.setValue("address", data?.address);
  }, [data]);

  return (
    <details>
      <summary className="my-3">Billing info</summary>

      <Form form={form}>
        <Form.Item label="Address" name="address">
          <Input />
        </Form.Item>
        <Form.Item label="City" name="city">
          <Input />
        </Form.Item>
        <Form.Item label="Post Code" name="post_code">
          <Input />
        </Form.Item>
        <Form.Item label="Country" name="country">
          <SelectCountry />
        </Form.Item>
      </Form>

      <Checkbox
        id="save-billing"
        value={shouldSaveBilling}
        label={"Save Billing for future usage"}
        callback={toggleShouldSaveBilling}
      />
    </details>
  );
};

export default BillingForm;
