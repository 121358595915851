export function dateFormatForDateInput(date) {
  // yyyy-mm-dd
  // uses in <input type='date' />

  if (!date) return;

  const formatted = new Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);

  return formatted;
}

export const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
});

export function dateFormatForBackend(date) {
  if (!date) return;
  const d = new Date(date);
  return d.toJSON().replace(/Z$/, "");
}
