import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  SpicyStoreError,
  useSpicyStore,
} from "src/providers/SpicyStoreProvider";
import { authRequest } from "src/utils/Axios";
import { sleep } from "src/utils/sleep";
import { CONVERSION_RATE } from "../../constants/store";
import RequestError from "../../errors/RequestError";
import { useToggle } from "../../hooks/useToggle";
import BuyTokens from "../BuyTokens";
import PaymentOptions from "../BuyTokens/PaymentOptions";
import SelectedPackageDetails from "../BuyTokens/SelectedPackageDetails";
import { SubmitButton } from "../CMS/Common";
import { toSpicyTokens } from "../CMS/Users/Withdraw";
import SpicyTokensPrice from "../LocalPrice/SpicyTokensPrice";
import { MultiLang } from "../MultiLang";
import Popup from "../common/Popup";
import "./payment-popup.css";
import { LocalPrice } from "../LocalPrice";
import { usePostHog } from "posthog-js/react";
import { convertToSpicyTokens } from "src/utils/currency-format";
import useSWR from "swr";
import { getProductPrice } from "src/v2/services/storeService";
import { getClass } from "src/utils/getClass";

export const MINIMUM_DIRECT_CHECKOUT_AMOUNT_SG = 799;

const PaymentPopup = ({
  show,
  onClose,
  title,
  description,
  supportMsg,
  onPayTokens,
  otherDescription,
  productType,
  productId,
  minimumPrice, // in euros
  initialPrice = 0, // in euros
  prices = [1, 2, 5, 10], // in euros,
}) => {
  const { tokens } = useSpicyStore();
  const history = useHistory();
  const posthog = usePostHog();
  const [priceSG, setPriceSG] = useState(convertToSpicyTokens(initialPrice));
  const [error, setError] = useState(null);
  const [showDirectCheckoutTokens, toggleDirectCheckoutTokens] =
    useToggle(false);
  const [showBuyTokens, toggleBuyTokens] = useToggle();
  const spicyStore = useSpicyStore();

  const { data: latestPriceEur } = useSWR(
    ["get-product-price", productId, productType],
    () => getProductPrice(productId, productType, initialPrice)
  );

  useEffect(() => {
    setPriceSG(convertToSpicyTokens(latestPriceEur));
  }, [latestPriceEur]);

  const updatePriceSG = (amount) => {
    if (initialPrice > 0) return; // donations only for free products
    return setPriceSG((prev) => prev + amount);
  };

  const onPriceChange = (e) => {
    const price = parseFloat(e.target.value);

    setPriceSG(isNaN(price) ? 0 : price);
  };

  const resetPrice = () => {
    return setPriceSG(convertToSpicyTokens(latestPriceEur));
  };

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("credit_card");

  const onChangePaymentMethod = (e) => {
    setSelectedPaymentMethod(e.target.value);
  };

  const onProceed = async (data) => {
    if (!selectedPaymentMethod) throw new Error("Payment method not selected");

    return await authRequest({
      url: "/store/api/members/cart/individual-checkout",
      method: "POST",
      data: {
        data: {
          product_type: productType,
          product_id: productId,
          price: priceSG / CONVERSION_RATE,
        },
        billing: data.billing,
        checkout_method: selectedPaymentMethod,
        transaction_token: data?.token,
      },
    });
  };

  const onSuccessfullBuy = async (orderId) => {
    if (orderId) {
      const info = await spicyStore.getOrderInfo(orderId);
      spicyStore.savePurchaseRecord(info);
    }
    await spicyStore.refresh();
    onClose?.(false);
    history.push("/library/purchased-games");
  };

  const payWithTokens = async () => {
    try {
      const finalTokenPrice = priceSG;

      const firstTokenPrice = convertToSpicyTokens(latestPriceEur);
      const miniumTokenPrice = minimumPrice
        ? convertToSpicyTokens(minimumPrice)
        : firstTokenPrice;

      if (miniumTokenPrice > finalTokenPrice)
        throw new Error(
          `The minimum amount is ${toSpicyTokens(
            convertToSpicyTokens(minimumPrice ?? latestPriceEur)
          )}`
        );

      if (finalTokenPrice > tokens) {
        await sleep(1000);

        if (finalTokenPrice > MINIMUM_DIRECT_CHECKOUT_AMOUNT_SG) {
          toggleDirectCheckoutTokens();
        } else {
          toggleBuyTokens();
        }
        return;
      }

      const successMsg = await onPayTokens?.(Math.floor(finalTokenPrice)); // in tokens
      setError(successMsg);
    } catch (error) {
      if (error instanceof SpicyStoreError) {
        // these errors will be opened by the store. and the popup should be dismissed
        return setError(
          <div>
            <span>{error.message} </span>
            <SubmitButton
              label="Retry"
              icon="fa-repeat"
              request={error.retry}
            />
          </div>
        );
      }
      posthog.capture("Payment Error", {
        type: "Pay Buy Tokens",
        error: error.message,
      });

      setError(error.message);
    }
  };

  return (
    <Popup
      open={show}
      onClose={onClose}
      showCloseButton
      className={"payment-popup"}
    >
      <div>
        <header>
          <h2>{title}</h2>
        </header>
        <p>
          <span className="description">{description} for </span>
          <strong>
            <LocalPrice
              hideDisclaimer
              showOnlyLocalPrice
              amountInEUR={priceSG / CONVERSION_RATE}
            />
            {" / "}
            <SpicyTokensPrice tokens={priceSG} />{" "}
          </strong>
          <MultiLang sl="ali več.">or more.</MultiLang>
        </p>
        <p>{otherDescription}</p>

        <div className="input-price">
          <input
            className={getClass(
              latestPriceEur > 0 && "opacity-30 cursor-not-allowed"
            )}
            disabled={latestPriceEur > 0}
            type="number"
            step={1}
            value={priceSG}
            min={0}
            onChange={onPriceChange}
          />
          <SubmitButton
            request={resetPrice}
            label={<span className="fa fa-refresh"></span>}
          />
        </div>

        {initialPrice === 0 && (
          <div className="support">
            {supportMsg}
            <div className="buttons">
              {prices.map((amountEUR) => (
                <SupportButton
                  key={amountEUR}
                  label={
                    <SpicyTokensPrice
                      tokens={convertToSpicyTokens(amountEUR)}
                    />
                  }
                  amount={convertToSpicyTokens(amountEUR)}
                  onClick={updatePriceSG}
                />
              ))}
            </div>
          </div>
        )}
        <div>
          <RequestError error={error} />
        </div>

        <div className="buttons pay">
          {onPayTokens && (
            <SubmitButton
              request={payWithTokens}
              className="pay-button"
              label={"Buy"}
            />
          )}
        </div>

        <footer>
          <PaymentTOSLabel />
        </footer>
      </div>

      <BuyTokens show={showBuyTokens} onClose={toggleBuyTokens} />
      <Popup
        defaultClose={false}
        showCloseButton
        open={showDirectCheckoutTokens}
        maxWidth={1080}
        onClose={toggleDirectCheckoutTokens}
      >
        <div className="TokenBuyCheckout">
          <SelectedPackageDetails
            productId={productId}
            productType={productType}
            selectedPkg={{
              title,
              spicy_tokens: priceSG,
              eur: priceSG / CONVERSION_RATE,
            }}
          />

          <PaymentOptions
            onChangePaymentMethod={onChangePaymentMethod}
            selectedPaymentMethod={selectedPaymentMethod}
            onProceed={onProceed}
            onSuccessfullBuy={onSuccessfullBuy}
            selectablePaymentMethods={["coingate", "credit_card"]}
          />
        </div>
      </Popup>
    </Popup>
  );
};

const SupportButton = ({ amount, onClick, label }) => {
  return <button onClick={() => onClick?.(amount)}>{label}</button>;
};

export const PaymentTOSLabel = () => {
  return (
    <>
      <MultiLang sl="Z izvebo plačila podajate privolitev k našim">
        By completing a payment you agree to spicygaming's
      </MultiLang>
      &nbsp;
      <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">
        <MultiLang sl="pogojem poslovanja">Terms of Service</MultiLang>
      </a>
      &nbsp;
      <MultiLang sl="ter">and</MultiLang> &nbsp;
      <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
        <MultiLang sl="politike zasebnosti">Privacy Policy</MultiLang>
      </a>
    </>
  );
};
export default PaymentPopup;
