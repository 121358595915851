import React from "react";
import { Link } from "react-router-dom";
import relativeDate from "relative-date";
import { generateURL } from "../resizeImages";
import { getURL } from "./FeaturedBig";

const FeaturedNews = ({ img, title, date, description, metadata = {} }) => {
  return (
    <div
      role="article"
      aria-labelledby="side_news"
      className="news-article-container"
    >
      <Link to={getURL(metadata.type, metadata.id)}>
        <div className="image">
          <img
            src={img ? generateURL(500, 500, img) : ""}
            alt={title}
            width={500}
            height={500}
            draggable="false"
            fetchPriority="high"
          />
        </div>
      </Link>
      <div className="text">
        <h2 id="side_news" className="title">
          <Link to={getURL(metadata.type, metadata.id)}>{title}</Link>
        </h2>

        <div className="info">
          <span aria-hidden className="fa fa-clock-o"></span>
          <span>{relativeDate(new Date(date))}</span>
        </div>
        <div className="description">{description}</div>
      </div>
    </div>
  );
};

export default FeaturedNews;
