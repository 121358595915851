import React from "react";
import { getClass } from "../../utils/getClass";
import "./style.css";

const Badge = ({ count, children, className }) => {
  return (
    <div
      data-count={count >= 100 ? "99+" : count}
      className={getClass("Badge", className, count > 0 && "new")}
    >
      {children}
    </div>
  );
};

export default Badge;
