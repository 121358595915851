import React from "react";
import Trumbowyg from "react-trumbowyg";

const RichTextInput = React.forwardRef(
  (
    { label, error, id, className, data = "", onChange, ...inputProps },
    ref
  ) => {
    return (
      <Trumbowyg
        {...inputProps}
        ref={ref}
        className={className}
        data={data}
        buttons={[
          ["formatting"],
          ["bold", "italic", "underline", "strikethrough"],
          ["link"],
          ["horizontalRule"],
          "btnGrp-justify",
          "btnGrp-lists",
        ]}
        id={"react-trumbowyg"}
        onChange={(e) => {
          onChange?.(e.target.innerHTML);
        }}
        tagsToRemove={["script"]}
        defaultLinkTarget={"_blank"}
        removeformatPasted
        semantic
        urlProtocol
        minimalLinks
      />
    );
  }
);

export default RichTextInput;
