import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_CDN_URL, BASE_URL } from "src/utils/Axios";
import updateViewed from "src/utils/updateViewed";
import { Loader } from "../Common";
import Error404 from "../Errors";
import ArticleDetails from "./ArticleDetail";

function BlogArticle({ match, preview = false }) {
  const articleID = match.params.article;
  const [article, setArticle] = useState({});
  const [comments, setComments] = useState([]);
  const [author, setAuthor] = useState({});

  useEffect(() => {
    request(`/news/api/news/blog-posts?article_id=${articleID}`, setArticle);
    request(`/news/api/news/blog-posts/${articleID}/comments`, setComments);
    updateViewed(articleID, "Blog Posts");
  }, [articleID]);
  useEffect(() => {
    if (article.author && !article.error) {
      request(
        `/api/members/public-profile?username=${article.author}`,
        setAuthor
      );
    }
  }, [article]);

  const request = async (url, state) => {
    try {
      state({});
      const req = await axios({
        method: "get",
        url: url,
        baseURL: preview ? BASE_URL : BASE_CDN_URL,
        params: {
          preview,
        },
      });
      const res = await req.data;
      state(res);
    } catch {}
  };
  if (article.error) return <Error404 />;

  if (!article.id) {
    return <LoaderLayout />;
  }
  return (
    <ArticleDetails
      article={article}
      comments={comments}
      author={author}
      type="blog"
    />
  );
}

export const LoaderLayout = () => {
  return (
    <div className="article-loader">
      <Loader />
    </div>
  );
};
export default BlogArticle;
