import React from "react";
import { useHistory } from "react-router-dom";
import { SubmitButton } from "../CMS/Common";

const Actions = () => {
  const history = useHistory();

  const navigate = (url) => () => history.push(url);

  return (
    <div className="order-done-actions">
      <SubmitButton
        label="Go to Purchased Games"
        request={navigate("/library/purchased-games")}
      />
      <SubmitButton
        label="Browse More Games"
        request={navigate("/porn-games")}
      />
    </div>
  );
};

export default Actions;
