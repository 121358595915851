import { useParams } from "react-router-dom";
import { LoaderInside } from "src/components/Common";
import { Tabs } from "src/components/Tabs";
import SomethingWentWrong from "src/errors/SomethingWentWrong";
import { InnerHeader } from "../../Headers";
import { ImagePermissions } from "./Image";
import { InfoContext, pages } from "./UploadCollection";
import useCollection from "./useCollectionInfo";

export const EditCollection = ({
  defaultId,
  firstPageId = "info",
  selectedPages = pages.map((p) => p.id),
  onSaveChanges: onSave,
}) => {
  const collection = useCollection(defaultId);

  if (collection.loading) return <LoaderInside />;
  if (collection.error)
    return (
      <div className="body">
        <SomethingWentWrong />
      </div>
    );
  return (
    <InfoContext.Provider value={collection}>
      <div className="UploadCollection" data-testid="UploadCollection">
        <Tabs
          pages={pages.filter((p) => selectedPages.includes(p.id))}
          firstPageId={firstPageId}
          propsForEachPageComponent={{
            id: collection.id,
            setId: collection.setId,
            editMode: true,
            onSave,
            permissions: [
              ImagePermissions.CHANGE_COVER,
              ImagePermissions.EDIT_METADATA,
              ImagePermissions.MAKE_PREMIUM,
              ImagePermissions.APPLY_EDIT_TO_ALL_IMAGES,
            ],
          }}
        />
      </div>
    </InfoContext.Provider>
  );
};

const EditCollectionCMS = ({ header, subHeader, toggler }) => {
  const params = useParams();

  return (
    <div className="body">
      <InnerHeader
        header={header}
        subHeader={subHeader}
        toggler={toggler}
        element={"back-button"}
      />
      <div className="content">
        <EditCollection defaultId={params.id} />
      </div>
    </div>
  );
};

export default EditCollectionCMS;
