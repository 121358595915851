import { toast } from "react-toastify";
import { sort } from "src/components/Common";
import { BASE_CDN_URL, authRequest, request } from "src/utils/Axios";

export const Featured = {
  HOME_MOST_READ: "Most_Read_Home_Articles",
  HOME_TOP_MAIN: "Top_Home_Main",
  HOME_TOP_SIDE: "Top_Home_Side",
  DONT_MISS: "Dont_Miss",
  FEATURED_VIDEOS: "Featured_Videos",
  FEATURED_GALLERY: "Featured_Galleries",
  FEATURED_ARTICLES: "Featured_Articles",

  BEST_FROM_ADULT_GAMES: "Best_From_Adult_Games",
  BEST_PORN_GAMES: "Best_Porn_Games",
  BEST_OF_PORN_GAMES: "Best_Of_Porn_Games",
};

export const saveChanges = async (position, objects) => {
  try {
    const res = await authRequest({
      url: "/products/api/featured-spots/featured-list/cms",
      method: "POST",
      data: {
        position,
        objects,
      },
    });
    if (res.success) toast.success("Successfully Updated");
  } catch (error) {
    toast.error(
      <div>
        <p>Error Occured. Try Again.</p>
        <i>Error: {error.message}</i>
      </div>
    );
  }
};

export const getPublicFeaturedContent = async (position) => {
  const res = await request({
    url: "/products/api/featured-spots/featured-list",
    baseURL: BASE_CDN_URL,
    params: { position },
  });

  return sort("order", res, "asc");
};
export const getCMSFeaturedContent = async (position) => {
  const res = await authRequest({
    url: "/products/api/featured-spots/featured-list/cms",
    params: { position },
  });

  return sort("order", res, "asc");
};
