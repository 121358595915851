import React, { useMemo, useState } from "react";
import "./style.css";
import { ASPECT_RATIO } from "src/components/Video/Player/Player";
import { generateURL } from "src/components/resizeImages";
import { Link, useHistory, generatePath } from "react-router-dom";
import { authRequest } from "src/utils/Axios";
import Popup from "src/components/common/Popup";
import { toast } from "react-toastify";
import { SubmitButton } from "../../CMS/Common";

const VIDEO_MAX_WIDTH = 400;

const MiniPlaylist = ({ playlist, onActionTriggered, actions = [] }) => {
  const history = useHistory();
  const [confirmation, setConfirmation] = useState(null);
  const clearConfirmation = () => setConfirmation(null);

  const allActions = useMemo(
    () => [
      {
        id: "remove",
        icon: "fa-trash-o",
        text: "Delete Playlist",
        confirmination: {
          title: "Are you sure you want to delete this playlist?",
          description:
            "This playlist will be removed completely from the platform and this action cannot be undone",
        },
        onClick: async (playlist) => {
          await authRequest({
            url: "/community/playlists",
            method: "DELETE",
            data: {
              playlist_id: playlist.id,
            },
          });
        },
      },
      {
        id: "edit",
        icon: "fa-edit",
        text: "Edit Playlist",
        onClick: () =>
          history.push(
            generatePath("/cms/content/playlists/edit/:id", { id: playlist.id })
          ),
      },
    ],
    [history, playlist.id]
  );
  const selectedActionForConfirmation = useMemo(
    () => allActions.find((action) => action.id === confirmation),
    [allActions, confirmation]
  );

  return (
    <div className="Player MiniPlaylist" data-testid="MiniPlaylist">
      <Link to={`/playlist/${playlist.id}`}>
        <div className="image">
          <img
            width={VIDEO_MAX_WIDTH}
            height={VIDEO_MAX_WIDTH / ASPECT_RATIO}
            alt={playlist.name}
            src={generateURL(
              VIDEO_MAX_WIDTH,
              VIDEO_MAX_WIDTH / ASPECT_RATIO,
              playlist.thumbnail
            )}
          />
          <div className="count">
            <i className="fa fa-list"></i>
            <span>{playlist.video_count} Videos</span>
          </div>
        </div>
      </Link>

      <div className="details">
        <img
          width={36}
          height={36}
          className="dp"
          src={
            playlist.team.profile_picture
              ? generateURL(72, 72, playlist.team.profile_picture)
              : "/images/user.svg"
          }
          alt={playlist.team.name}
        />

        <div className="info">
          <h3>
            <Link to={`/playlist/${playlist.id}`}>{playlist.name}</Link>
          </h3>
          <Link to={`/team/@${playlist.team.name}/playlists`}>
            <span className="uploader">{playlist.team.name}</span>
          </Link>
        </div>

        {actions.length > 0 && (
          <button className="options">
            <span className="fa fa-ellipsis-v"></span>

            <div className="menu">
              {allActions
                .filter((action) => actions.includes(action.id))
                .map((action) => {
                  const onClickOption = async () => {
                    if (action.confirmination)
                      return setConfirmation(action.id);
                    const output = action.onClick?.(playlist);
                    if (output instanceof Promise) {
                      await toast.promise(output, {
                        pending: "Working...",
                        success: "Successful",
                        error: "Error Occured",
                      });
                    }

                    onActionTriggered?.(action.id, playlist);
                  };
                  return (
                    <div
                      role="button"
                      className="opt"
                      key={action.id}
                      onClick={onClickOption}
                    >
                      <span className={`fa ${action.icon}`}></span>
                      <span>{action.text}</span>
                    </div>
                  );
                })}
            </div>
          </button>
        )}
      </div>

      <Popup
        open={!!confirmation}
        onClose={clearConfirmation}
        showCloseButton
        className={"Player_action_confirmation"}
      >
        <h3>{selectedActionForConfirmation?.confirmination?.title}</h3>
        <p>{selectedActionForConfirmation?.confirmination?.description}</p>

        <SubmitButton
          label={selectedActionForConfirmation?.text}
          icon={selectedActionForConfirmation?.icon}
          request={async () => {
            const output = selectedActionForConfirmation?.onClick?.(playlist);
            if (output instanceof Promise) {
              await toast.promise(output, {
                pending: "Working...",
                success: "Successful",
                error: "Error Occured",
              });
            }
            onActionTriggered?.(selectedActionForConfirmation?.id, playlist);

            clearConfirmation();
          }}
        />
      </Popup>
    </div>
  );
};

export default MiniPlaylist;
