import axios, { AxiosRequestConfig } from "axios";
import {
  DEV_CDN_URL,
  DEV_TUS_URL,
  DEV_URL,
  PROD_CDN_URL,
  PROD_TUS_URL,
  PROD_URL,
} from "../constants/urls";
import { refresh } from "./authenticate";
import { getCredentails } from "src/components/Validation";

export const BUILD_STATUS = process.env.REACT_APP_NODE_ENV;
export const isDev = BUILD_STATUS === "development";
export const BASE_URL = isDev ? DEV_URL : PROD_URL;
export const BASE_CDN_URL = isDev ? DEV_CDN_URL : PROD_CDN_URL;
export const BASE_UPLOAD_URL = isDev ? DEV_TUS_URL : PROD_TUS_URL;

export const MAX_RETRY_COUNT = 5;

export const backend = axios.create({
  baseURL: BASE_URL,
});

/** Make authorized Requests
 * @param {AxiosRequestConfig} config
 */
export const request = async (config) => {
  try {
    const req = await backend(config);
    const res = await req.data;
    if (res.error) throw new Error(res.error);

    return res;
  } catch (error) {
    const errorMessage =
      error.response?.status === 500
        ? "Sorry. an unexpected error occured"
        : error.response?.data?.error ??
          error.response?.data?.detail ??
          error.message;

    throw new Error(errorMessage);
  }
};

/** Make authorized Requests
 * @param {AxiosRequestConfig} config
 */
export const authRequest = async (config, returnFullError = false) => {
  const data = getCredentails();
  if (!data.username) throw new Error("Not Logged in");

  try {
    const req = await backend({
      ...config,
      withCredentials: true,
    });

    const res = await req.data;
    if (res.error) {
      if (returnFullError) throw res;
      throw new Error(res.error);
    }

    return res;
  } catch (error) {
    if (error.response?.status === 401) {
      const listenTillFinish = await refresh();

      const event = await listenTillFinish();

      if (event.detail === false) {
        throw new Error("Your Login Session expired. Please Login Again.");
      }

      const res = await authRequest(config, returnFullError);

      return res;
    }
    if (returnFullError) throw error;

    const errorMessage =
      error.response?.status === 500
        ? "Sorry. an unexpected error occured"
        : error.response?.data?.error ??
          error.response?.data?.detail ??
          error.message;

    throw new Error(errorMessage);
  }
};
