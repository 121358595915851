import { useContext } from "react";
import { authRequest } from "src/utils/Axios";
import { getClass } from "../../utils/getClass";
import { ArticleMetaDataContext } from "../Article/MetaDataProvider";
import { SimpleDivider } from "../Atoms/SimpleDivider";
import { SubmitButton } from "../CMS/Common";
import { MultiLang } from "../MultiLang";
import { PreviewContext } from "../Previews/Preview";
import Image from "../SpicyArt/Image";
import generateImageObjectFromPath from "../SpicyArt/generateImageObject";
import CreatorInfo, { ContentMetaInfo } from "../Video/FullPage/CreatorInfo";
import Description from "./Description";
import "./css/ArticleDetailed-Article.css";

const Article = ({ article, author, type, likeURL }) => {
  const likedArticles = useContext(ArticleMetaDataContext)[type];

  const isLikedThis = !!likedArticles.find((id) => id === article.id);

  const previewMode = useContext(PreviewContext);
  const scrollToReviews = () =>
    document.querySelector("#article_reviews")?.scrollIntoView();

  const likeArticle = async () => {
    try {
      const res = await authRequest({
        url: likeURL,
        method: "POST",
      });
      const liked = res.status;
      return liked;
    } catch (error) {}
  };

  const shareText = `${article.title}`;
  return (
    <div id="article-detailed-article">
      <Image
        image={generateImageObjectFromPath(article.landscape_cover)}
        noRedirect
        owner={author.username}
        maxHeight={600}
        width={1080}
      />
      <div>
        <CreatorInfo
          likeContent={likeArticle}
          numOfLikes={article.likes}
          team={article.team}
          shareLink={window.location.href}
          shareText={article.title}
          isLiked={isLikedThis}
          actionButtons={[
            <SubmitButton request={scrollToReviews} label={"Add Comment"} />,
          ]}
        />
        <ContentMetaInfo
          dangerouslySetInnerHTML
          creation_date={article.date_posted}
          tags={
            type === "blog"
              ? [{ name: article.category, param: article.category }]
              : undefined
          }
          views={article.views}
          setTagUrl={(param) => `/articles/1?category=${param}`}
          extraLittleInfo={
            type === "staff" ? (
              <span className="tag">
                <span
                  className="fa fa-star"
                  style={{
                    background: `linear-gradient(360deg, gold ${article.score}%, #535353 0%)`,
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                ></span>
                {article.score}/100
              </span>
            ) : null
          }
        />
      </div>
      {/*   
        {type === "staff" && (
          <div className="tag">
            <span
              className="fa fa-star"
              style={{
                background: `linear-gradient(360deg, gold ${article.score}%, #535353 0%)`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            ></span>
            {article.score}/100
          </div>
        )}
          
      <br />
      {type === "blog" && (
        <div className="search-tags">
          <div className="tag">{article.category}</div>
        </div>
      )} */}

      <SimpleDivider />
      {/* the massive description */}
      <Description
        article={article}
        elements={article.content.elements || []}
      />
      <div className="share" data-disable={!!previewMode}>
        <a
          href={`https://www.facebook.com/sharer.php?u=${window.location.href}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Share icon="fa-facebook" name="Facebook" />
        </a>
        <a
          href={`https://twitter.com/share?url=${window.location.href}&text=${shareText}&via=spicygamingnet`}
          target="__blank"
          rel="noopener noreferrer"
        >
          <Share icon="fa-twitter" name="Twitter" />
        </a>
        <a
          href={`https://reddit.com/submit?url=${window.location.href}&title=${shareText}`}
          target="__blank"
          rel="noopener noreferrer"
        >
          <Share icon="fa-reddit" name="Reddit" />
        </a>
        <a
          href={`mailto:?subject=${shareText}&body=Check out this article: ${window.location.href}`}
          target="__blank"
          title="Share by Email"
          rel="noopener noreferrer"
        >
          <Share icon="fa-envelope" name="Email" />
        </a>
      </div>
    </div>
  );
};

const Share = ({ icon, name }) => {
  return (
    <div className="tag">
      <span className={getClass("fa", icon)}></span>
      <span>
        <MultiLang sl="Deli na">Share on</MultiLang>
        {name}
      </span>
    </div>
  );
};

export default Article;
