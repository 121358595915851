import { useContext, useState } from "react";
import { authRequest } from "../../utils/Axios";
import { numberCompact } from "../../utils/number-format";
import { useGamesMetaData } from "../AdultGames/MetaDataProvider";
import "../ArticleDetails/css/ArticleDetail-Game.css";
import { SimpleDivider } from "../Atoms/SimpleDivider";
import Banner from "../Banners";
import { AD_IDS } from "../CMS/Ads/Section";
import { TinyButtonLoader } from "../Common";
import { PreviewContext } from "../Previews/Preview";
import RatingStar from "../common/RatingStar";
import { SideCreatorInfo } from "../common/SideCreatorInfo";
import SuggestGames from "./SuggestGames";
import "./css/GameDetail_Game.css";
import { useParams } from "react-router-dom";

const GameDetailGame = ({ game, creator }) => {
  const { game: gameId } = useParams();
  return (
    <aside id="game-detail" className="game-detail-game">
      <SideCreatorInfo
        team={creator}
        extraInfo={
          <div className="meta-info">
            <div className="tag" data-tooltip="Views">
              <span className="fa fa-eye"></span>
              {numberCompact(game.views)}
            </div>
            <div className="tag" data-tooltip="Likes">
              <span className="fa fa-heart"></span>
              {numberCompact(game.likes)}
            </div>
            <RatingStar percentage={game.avg_score} />
          </div>
        }
      />
      <SimpleDivider />

      <Banner id={AD_IDS.game.side} jsAd="94330" width={300} height={250} />
      <SimpleDivider />
      <SuggestGames gameId={gameId} teamName={creator.name} />
    </aside>
  );
};
const request = async (url, data) => {
  try {
    return await authRequest({ url, method: "post", data });
  } catch {
    return {};
  }
};
export const Follow = ({ buttonText, url, onSuccess }) => {
  const previewMode = useContext(PreviewContext);

  const { updateMetaData } = useGamesMetaData();

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState(buttonText);
  const followRequest = async () => {
    try {
      setLoading(true);
      const res = await request(url);

      setText(res.is_followed ? "Unsubscribe Team" : "Subscribe Team");
      onSuccess?.(res.is_followed);
      await updateMetaData();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="btn follow"
      onClick={loading || previewMode ? undefined : followRequest}
      data-disable={!!previewMode}
    >
      {loading ? (
        <TinyButtonLoader backgroundColor="#0dac50" />
      ) : (
        <>
          <span className="fa fa-user-plus"></span>
          <span>{text}</span>
        </>
      )}
    </div>
  );
};
export const Like = ({ buttonText, url, data, editGame = () => null }) => {
  const previewMode = useContext(PreviewContext);
  const { updateMetaData } = useGamesMetaData();

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState(buttonText);
  const likeRequest = async () => {
    try {
      setLoading(true);

      const res = await request(url, data);
      editGame(res.likes);
      setText(res.status ? "Unlike" : "Like");
      await updateMetaData();
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      className="btn like like-button"
      onClick={loading || previewMode ? undefined : likeRequest}
      data-disable={!!previewMode}
    >
      {loading ? (
        <TinyButtonLoader backgroundColor="#ff3403" />
      ) : (
        <>
          <span className="fa fa-heart"></span>
          <span>{text}</span>
        </>
      )}
    </button>
  );
};

export default GameDetailGame;
