import { createContext, useContext, useEffect, useState } from "react";
import { authRequest } from "../../utils/Axios";

const INITIAL_STATE = {
  liked: [],
  followed: [],
  updateMetaData: async () => {},
};
export const GameMetaDataContext = createContext(INITIAL_STATE);

export const GameMetaDataProvider = ({ children }) => {
  const [metaData, setMetaData] = useState(INITIAL_STATE);
  useEffect(() => {
    getMetaData();
  }, []);
  const getMetaData = async () => {
    try {
      const res = await authRequest({
        url: `/api/members/gamer-profile/get-liked-and-followed-games`,
      });
      if (!res?.error) setMetaData(res);
    } catch {}
  };
  return (
    <GameMetaDataContext.Provider
      value={{ ...metaData, updateMetaData: getMetaData }}
    >
      {children}
    </GameMetaDataContext.Provider>
  );
};

export const useGamesMetaData = () => useContext(GameMetaDataContext);
