/**
 *
 * @param {String} role - the role string
 * @param {Object} allRoles - the All available roles
 * @param {*} allRoles.content_creator
 * @param {*} allRoles.trusted_content_creator
 * @param {*} allRoles.staff
 * @param {*} allRoles.admin
 * @deprecated
 * @returns
 */
const filterRole = (role = "", allRoles = {}) => {
  role = role.toLowerCase().replace(/ /g, "_");

  const availableRoles = [
    "member",
    "content_creator",
    "trusted_content_creator",
    "staff",
    "admin",
    "leader",
    "co-leader",
    "content_manager",
  ];
  if (!availableRoles.find((availableRole) => availableRole === role)) return;

  return allRoles[role] || [];
};

const roleFilter = (role = "", allPermissions = [], specifiedRoles = {}) => {
  role = role.toLowerCase();

  const rolePermissions = specifiedRoles[role] || [];
  const specifiedPermissions = allPermissions.filter((permission) =>
    rolePermissions.includes(permission.id)
  );
  return specifiedPermissions;
};

export { roleFilter };
export default filterRole;
