import { useRouteMatch } from "react-router-dom";
import { LoadMore } from "src/components/Common";
import { Player } from "src/components/Video/Player";
import { authRequest } from "src/utils/Axios";
import { withoutLastPage } from "src/utils/withoutLastPage";
import useSWRInfinite from "swr/infinite";
import { fetcher } from "../Cms";
import { REVALIDATE_ON_FOCUS } from "../Common";
import { MainHeader } from "../Headers";
import "./style.css";

const AllVideos = ({ subHeader, toggler }) => {
  const match = useRouteMatch();
  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) =>
      `${
        window.domainName
      }/community/community-videos/list?member=true&cms=true&page=${index + 1}`,
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );
  const videos = data ? withoutLastPage(data.flat()) : [];

  const search = async (query) => {
    try {
      const res = await authRequest({
        url: "/community/community-videos/list",
        params: {
          search_term: query,
        },
      });

      return res;
    } catch (error) {
      return [];
    }
  };
  const searchCallback = (data) => {
    mutate(data, false);
  };

  return (
    <div className="body Videos" data-testid="Videos">
      <MainHeader
        header={subHeader}
        toggler={toggler}
        uploadLink={match.path + "/upload"}
        search={{
          func: search,
          callback: searchCallback,
        }}
      />
      <div className="content">
        {videos.map((video) => (
          <Player
            onActionTriggered={() => mutate(videos, true)}
            actions={["remove", "edit"]}
            video={video}
            key={video.id}
          />
        ))}
      </div>
      <LoadMore onClick={() => setSize((prev) => prev + 1)} />
    </div>
  );
};

export default AllVideos;
