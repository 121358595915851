import { SECTIONS } from "src/components/Member/FavGames";
import { authRequest, BASE_CDN_URL, request } from "src/utils/Axios";
import { convertToSpicyTokens } from "src/utils/currency-format";
import { withoutLastPage } from "src/utils/withoutLastPage";

export async function deleteResource(resourceId) {
  await authRequest({
    url: "/downloads/api/games/downloads/resources/delete",
    method: "DELETE",
    data: {
      resource_id: resourceId,
    },
  });
}

export async function addToWishlist(gameId) {
  await authRequest({
    url: "/api/members/gamer-profile/add-game",
    params: {
      game_list: SECTIONS.wishlisted,
      game_id: gameId,
    },
    method: "POST",
  });
}

// New & recently updated, Best sellers, Best mobile games,

export async function getNewGames() {
  return withoutLastPage(
    await request({
      url: "/products/api/games/initial-search-page",
      baseURL: BASE_CDN_URL,
      params: {
        option: "latest",
        page: 1,
      },
    })
  );
}

export async function getTrendingGames() {
  return withoutLastPage(
    await request({
      url: "/products/api/games/initial-search-page",
      baseURL: BASE_CDN_URL,
      params: {
        option: "recommended",
        page: 1,
      },
    })
  );
}

export async function getBestMobileGames() {
  return withoutLastPage(
    await request({
      url: "/products/api/games/initial-search-page",
      baseURL: BASE_CDN_URL,
      params: {
        option: "best_mobile",
        page: 1,
      },
    })
  );
}

export async function getPreSearchGames(options = {}) {
  return withoutLastPage(
    await request({
      url: "/products/api/games/search-games",
      baseURL: BASE_CDN_URL,
      params: {
        layout: "home",
        page: 1,
        ...options,
      },
    })
  );
}

export async function getPreSearchFeaturedGames(options = {}) {
  return withoutLastPage(
    await request({
      url: "/products/api/games/search-games",
      baseURL: BASE_CDN_URL,
      params: {
        layout: "featured",
        page: 1,
        ...options,
      },
    })
  );
}

export async function getGamesBelowPrice(priceEUR = 0) {
  return withoutLastPage(
    await request({
      url: "/products/api/games/search-games",
      withCredentials: true,
      baseURL: BASE_CDN_URL,
      params: {
        page: 1,
        title: "",
        layout: "cached_search",
        price_max: convertToSpicyTokens(priceEUR), // in tokens
      },
    })
  );
}

export async function getFeaturedGames() {
  return await request({
    url: "/products/api/games/featured-games",
    withCredentials: true,
    baseURL: BASE_CDN_URL,
    params: {
      random: true,
      num: 10,
    },
  });
}

export async function getGameImages(gameId) {
  return await request({
    url: `/products/api/games/galleries/${gameId}`,
    baseURL: BASE_CDN_URL,
  });
}

export async function reportGameDownload(gameId, linkUniqueId) {
  return await authRequest({
    url: "/products/api/broken-links/create",
    method: "POST",
    data: {
      game_id: gameId,
      link_unique_id: linkUniqueId,
    },
  });
}

export function decodeRedirectData(data = "") {
  const parsed = JSON.parse(atob(data));
  return {
    gameId: parsed.gameId,
    uuid: parsed.uuid,
    redirectLink: parsed.redirectLink,
  };
}

export function encodeRedirectData({ gameId, uuid, redirectLink }) {
  const encoded = btoa(JSON.stringify({ gameId, uuid, redirectLink }));
  return encoded;
}
