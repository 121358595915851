import { useEffect, useRef } from "react";

import loadScript from "../../utils/loadScript";
import GTags from "../GTags";

import useCheckForURLBlock from "src/hooks/useCheckForURLBlock";

import "./styles/ima.css";
import "./styles/reset.css";
import "./styles/styles.css";
import "./styles/videojs.css";
import { usePostHog } from "posthog-js/react";

const plugins = [
  ["/videojs/nuevo.min.js", "nuevo"],
  ["/videojs/plugins/vastpro.js", "vast_ad"],
];

const VideoPlayer = ({
  videoID,
  pullzoneID,
  options = {},
  thumbnailName,
  vastZone,
  nuevoOptions = {},
  playADs = true,
}) => {
  const ref = useRef();
  const posthog = usePostHog();
  const src = `https://${pullzoneID}.b-cdn.net/${videoID}/playlist.m3u8`;
  const videoPlayed = useRef(false);

  const renderedInCMS = useCheckForURLBlock("cms");
  const renderedInEmbed = useCheckForURLBlock("embed");

  useEffect(() => {
    let vjs;
    if (!videoID) return;

    loadScript("/videojs/video.min.js", "videojs")
      .then(() => loadScript("/videojs/nuevo.min.js", "nuevo"))
      .then(() => {
        vjs = window.videojs(
          ref.current,
          {
            preload: "auto",
            controls: true,
            playsinline: true,
            responsive: true,
            poster: thumbnailName,
            sources: [
              {
                src,
                type: "application/x-mpegURL",
              },
            ],
            ...options,
          },
          function () {
            // Load Vast/Vpaid plugin and initialize ad
            if (!vastZone) return;
            if (!playADs) return;

            if (renderedInCMS) return;

            loadScript("/videojs/plugins/vastpro.js", "vast_ad").then(() => {
              vjs.vastAds?.({
                map: [
                  {
                    tagURL: `https://srv.aso1.net/vast?z=${vastZone}`,
                    timeOffset: "start",
                    id: 102898,
                  },
                  {
                    tagURL: `https://srv.aso1.net/vast?z=${vastZone}`,
                    timeOffset: "00:03:00",
                    id: 102902,
                  },
                  {
                    tagURL: `https://srv.aso1.net/vast?z=${vastZone}`,
                    timeOffset: "00:06:00",
                    id: 102903,
                  },
                  {
                    tagURL: `https://srv.aso1.net/vast?z=${vastZone}`,
                    timeOffset: "00:09:00",
                    id: 102904,
                  },
                ],
              });
            });
          }
        );

        vjs.nuevo({ qualityMenu: true, shareMenu: false, ...nuevoOptions });
      });

    return () => vjs?.current?.dispose();
  }, [videoID]);

  const onPlay = () => {
    if (videoPlayed.current === true) return;
    GTags.videoPlay(src);
    posthog?.capture("Video Played", { src: src });
    videoPlayed.current = true;
  };

  return (
    <div className="video-player">
      <div data-vjs-player>
        <video
          ref={ref}
          onPlay={onPlay}
          preload="auto"
          className="video-js vjs-fluid"
        ></video>
      </div>
    </div>
  );
};

const importPlugins = async (plugins = []) => {
  const loaders = plugins.map((plugin) => {
    const [path, id] = plugin;

    return loadScript(path, id);
  });
  Promise.allSettled(loaders);
};

export default VideoPlayer;
