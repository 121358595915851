import React, { useMemo } from "react";
import "./style.css";
import Popup from "../Popup";
import Facebook from "src/Icons/LogosFacebook";
import Twitter from "src/Icons/LogosTwitter";
import RedditIcon from "src/Icons/LogosRedditIcon";
import MailIcon from "src/Icons/IcBaselineMail";
import { Input } from "src/components/Member/General";
import { SubmitButton } from "src/components/CMS/Common";
import { toast } from "react-toastify";
import { useRoleInfo } from "src/components/CMS/RoleContext";
import { loggedInValidation } from "src/components/Validation";
import { usePostHog } from "posthog-js/react";

const shareList = [
  {
    name: "Facebook",
    icon: <Facebook />,
    genLink(_text, link) {
      return `https://www.facebook.com/sharer.php?u=${link}`;
    },
  },
  {
    name: "Twitter",
    icon: <Twitter />,
    genLink(text, link) {
      return `https://twitter.com/share?url=${link}&text=${text}&via=spicygamingnet`;
    },
  },
  {
    name: "Reddit",
    icon: <RedditIcon />,
    genLink(text, link) {
      return `https://reddit.com/submit?url=${link}&title=${text}`;
    },
  },
  {
    name: "Mail",
    icon: <MailIcon />,
    genLink(text, link) {
      return `mailto:?subject=${text}&body=Check this out: ${link}`;
    },
  },
];

const ShareMenu = ({
  show,
  onClose,
  shareLink,
  shareText,
  customOptions = <></>,
}) => {
  const { affiliateId } = useRoleInfo();
  const posthog = usePostHog();
  const finalLink = useMemo(() => {
    try {
      const url = new URL(shareLink, window.location.origin);
      if (affiliateId) url.searchParams.append("af", affiliateId);
      return url.toString();
    } catch {
      return shareLink;
    }
  }, [shareLink]);

  const link = useMemo(() => encodeURIComponent(finalLink), [finalLink]);

  const copy = (customLink) => {
    navigator.clipboard.writeText(decodeURIComponent(customLink));
    toast.success("Share Link copied to clipboard");
    posthog.capture("Affiliate Link Copied", { affiliateId });
    onClose?.();
  };
  return (
    <Popup
      open={show}
      onClose={onClose}
      showCloseButton
      className="VideoShareMenu"
    >
      <section>
        <legend className="text-3xl border-b border-neutral-700 pb-2">
          Share
        </legend>
      </section>

      <div className="types">
        {customOptions}
        {shareList.map((item) => (
          <a
            href={item.genLink(shareText, link)}
            className="icon"
            target="_blank"
            rel="noopener noreferrer"
            key={item.name}
          >
            {item.icon}
            <span>{item.name}</span>
          </a>
        ))}
      </div>
      {loggedInValidation() && (
        <div className="bg-green-950 border border-green-700 rounded-md p-3 my-4">
          <h4 className="text-green-300">Tip!</h4>
          <span className="text-green-400 text-sm">
            Earn free tokens by sharing your unique link! Simply copy the link
            below and share it across the web: on forums, social media or chats.
            For every purchase made through your link, you'll receive 50 Spicy
            Tokens as a reward.
          </span>
        </div>
      )}
      <div className="copy">
        <Input controlledValue={finalLink} />
        <SubmitButton label="copy" icon="fa-copy" request={() => copy(link)} />
      </div>
    </Popup>
  );
};

export default ShareMenu;
