import { useMemo, useReducer } from "react";

/**
 * Function which makes a request.
 * @typedef {() => Promise<void>} RequestToMake
 */

/**
 * Given an array of requestsToMake and a limit on the number of max parallel requests,
 * queue up those requests and start firing them.
 *
 * @param {RequestToMake[]} requestsToMake - An array of functions that make requests.
 * @param {number} maxParallelRequests - The maximum number of requests to make (defaults to 6).
 * @returns {Promise<void[]>} - An array of promises representing the results of the requests.
 */
async function throttleRequests(requestsToMake, maxParallelRequests = 6) {
  // Queue up simultaneous calls
  const queue = [];
  for (let i = 0; i < requestsToMake.length; i++) {
    // Fire the async function, add its promise to the queue,
    // and remove it from the queue when complete
    const request = requestsToMake[i];
    const promise = request().then(() => {
      const index = queue.indexOf(promise);
      if (index !== -1) {
        queue.splice(index, 1);
      }
    });
    queue.push(promise);

    // If the number of queued requests matches our limit, then
    // wait for one to finish before enqueueing more
    if (queue.length >= maxParallelRequests) {
      await Promise.race(queue);
    }
  }
  // Wait for the rest of the calls to finish
  await Promise.all(queue);
}

/**
 * The state that represents the progress in processing throttled requests.
 * @typedef {Object} ThrottledProgress
 * @property {number} totalRequests - The number of requests that will be made.
 * @property {Error[]} errors - The errors that came from failed requests.
 * @property {any[]} values - The responses that came from successful requests.
 * @property {number} percentageLoaded - A value between 0 and 100, representing the percentage of requests that have been completed (whether successfully or not).
 * @property {boolean} loading - Whether the throttle is currently processing requests.
 */

/**
 * Create a ThrottleRequests and an updater.
 *
 * @template TValue
 * @returns {{throttle: ThrottledProgress<TValue>, updateThrottle: { queueRequests: (requestsToMake: RequestToMake[], maxParallelRequests?: number) => Promise<void[]>, requestSucceededWithData: (value: TValue) => void, requestFailedWithError: (error: Error) => void }}}
 */
function useThrottleRequests() {
  /**
   * A reducing function which takes the supplied ThrottledProgress and applies a new value to it.
   * @param {ThrottledProgress} currentProgress - The current progress state.
   * @param {AsyncState} newData - The new data to apply to the progress state.
   * @returns {ThrottledProgress} - The updated progress state.
   */
  function updateThrottledProgress(currentProgress, newData) {
    const errors = newData.error
      ? [...currentProgress.errors, newData.error]
      : currentProgress.errors;

    const values = newData.value
      ? [...currentProgress.values, newData.value]
      : currentProgress.values;

    const percentageLoaded =
      currentProgress.totalRequests === 0
        ? 0
        : Math.round(
            ((errors.length + values.length) / currentProgress.totalRequests) *
              100
          );

    const loading =
      currentProgress.totalRequests === 0
        ? false
        : errors.length + values.length < currentProgress.totalRequests;

    return {
      totalRequests: currentProgress.totalRequests,
      loading,
      percentageLoaded,
      errors,
      values,
    };
  }

  /**
   * A reducer function to handle state updates.
   * @param {ThrottledProgress} throttledProgressAndState - The current progress state.
   * @param {ThrottleActions} action - The action to perform on the state.
   * @returns {ThrottledProgress} - The updated progress state.
   */
  function reducer(throttledProgressAndState, action) {
    switch (action.type) {
      case "initialise":
        return createThrottledProgress(action.totalRequests);

      case "requestSuccess":
        return updateThrottledProgress(throttledProgressAndState, {
          loading: false,
          value: action.value,
        });

      case "requestFailed":
        return updateThrottledProgress(throttledProgressAndState, {
          loading: false,
          error: action.error,
        });
      default:
        return;
    }
  }

  const [throttle, dispatch] = useReducer(reducer, createThrottledProgress(0));

  const updateThrottle = useMemo(() => {
    /**
     * Update the throttle with a successful request.
     * @param {TValue} value - The value from the successful request.
     */
    function requestSucceededWithData(value) {
      return dispatch({
        type: "requestSuccess",
        value,
      });
    }

    /**
     * Update the throttle upon a failed request with an error message.
     * @param {Error} error - The error.
     */
    function requestFailedWithError(error) {
      return dispatch({
        type: "requestFailed",
        error,
      });
    }

    /**
     * Given an array of requestsToMake and a limit on the number of max parallel requests,
     * queue up those requests and start firing them.
     * - based upon https://stackoverflow.com/a/48007240/761388
     *
     * @param {RequestToMake[]} requestsToMake - An array of functions that make requests.
     * @param {number} maxParallelRequests - The maximum number of requests to make (defaults to 6).
     */
    function queueRequests(requestsToMake, maxParallelRequests = 6) {
      dispatch({
        type: "initialise",
        totalRequests: requestsToMake.length,
      });

      return throttleRequests(requestsToMake, maxParallelRequests);
    }

    return {
      queueRequests,
      requestSucceededWithData,
      requestFailedWithError,
    };
  }, [dispatch]);

  return {
    throttle,
    updateThrottle,
  };
}

/**
 * The state representing the result of an async operation.
 * @typedef {Object} AsyncState
 * @property {Error} error - An error object if the operation failed.
 * @property {any} value - The value resulting from the operation.
 */

/**
 * The available actions for the ThrottleRequests reducer.
 * @typedef {Object} ThrottleActions
 * @property {string} type - The action type.
 */

/**
 * Function to create the initial ThrottledProgress state.
 * @param {number} totalRequests - The total number of requests.
 * @returns {ThrottledProgress} - The initial progress state.
 */
function createThrottledProgress(totalRequests) {
  return {
    totalRequests,
    percentageLoaded: 0,
    loading: false,
    errors: [],
    values: [],
  };
}

export default useThrottleRequests;
