/** Use to load 3rd party scripts */
function loadScript(src, id = null, attributes = {}) {
  return new Promise((onFulfilled, onRejected) => {
    // if script already exists in dom, the promise will be fullfilled
    const existing = document.querySelector(`script#${id}`);
    if (existing) return onFulfilled(existing);

    const script = document.createElement("script");
    let loaded;

    // set source path to load
    script.setAttribute("src", src);
    if (id) script.setAttribute("id", id);

    // setting other attributes
    Object.entries(attributes).forEach(([key, value]) => {
      script.setAttribute(key, value);
    });

    script.onreadystatechange = script.onload = () => {
      if (!loaded) {
        onFulfilled(script);
      }
      loaded = true;
    };

    script.onerror = function (e) {
      onRejected(e);
    };

    // If the script is already in the dom
    if (document.getElementById(id) !== null) return;

    // append the given script
    document.getElementsByTagName("head")[0].appendChild(script);
  });
}

export default loadScript;
