import { authRequest, request } from "src/utils/Axios";

export async function resendVerificationEmail(email) {
  await request({
    url: "/api/members/send-confirmation-email",
    method: "POST",
    data: {
      email,
    },
  });
}

export async function deleteUser() {
  await authRequest({
    url: "/api/members/delete-account/request",
    method: "DELETE",
  });
}

export async function deleteUserConfirm(approvalCode, reason) {
  await authRequest({
    url: "/api/members/delete-account/confirm",
    method: "DELETE",
    data: {
      approval_code: approvalCode,
      reason,
    },
  });
}

export const getAllTeamsOfUserInCMS = async () => {
  const res = await authRequest({
    url: "/api/content-creator-teams/search",
    params: {
      member: true,
    },
  });

  return res.map((team) => ({ id: team.id, name: team.name }));
};

export const getUserAffiliateOverview = async () => {
  return await authRequest({
    url: "/api/members/token-affiliate/overview",
  });
};

export async function getUserAffiliateStats() {
  return await authRequest({
    url: "/api/members/token-affiliate/statistics",
  });
}
