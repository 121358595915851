import axios from "axios";
import { usePostHog } from "posthog-js/react";
import { Suspense, useEffect, useRef, useState } from "react";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import useQuery from "src/hooks/useQuery";
import { BASE_CDN_URL, BASE_URL, request } from "src/utils/Axios";
import stringifyFilters from "src/utils/stringifyFilters";
import { withoutLastPage } from "../../utils/withoutLastPage";
import { PaginationSlider } from "../Article/Slider";
import { LoaderInside } from "../Common";
import GTags from "../GTags";
import "../css/AdultGames.css";
import Filter, { SEARCH_TYPES } from "./Filter";
import Game from "./Game";
import Header from "./Header";

const INITIAL_ERROR = { has: false, body: "" };

const INITIAL_FILTERS = {
  filters: {
    engine: [],
    status: [],
    os: [],
    languages: [],
    genre: [],
    tags: [],
  },
  active: false,
};

const AdultGames = ({ type, param }) => {
  const posthog = usePostHog();

  const { query, page } = useParams();
  const match = useRouteMatch();
  const searchParams = useQuery();
  const sortKey = searchParams.get("sort");

  const ref = useRef(null);
  const sortConfig = useRef({
    sort: sortKey,
    order: sortKey ? "dsc" : undefined,
  });

  const currentPageNumber = parseInt(page) || 1;

  const [filters, setFilters] = useState(() => {
    if (type) INITIAL_FILTERS.filters[type] = [param];

    return INITIAL_FILTERS;
  });

  const history = useHistory();
  const [games, setGames] = useState([]);
  const [error, setError] = useState(INITIAL_ERROR);
  const [isLoading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(query || "");
  const [lastPage, setLastPage] = useState(1);

  const isInitialSearch =
    Object.values(filters.filters).every((filter) => filter.length === 0) &&
    !!!query?.length;

  const toggle = () =>
    setFilters((prev) => ({ ...prev, active: !prev.active }));

  useEffect(() => {
    getGames({});
  }, [query, currentPageNumber, filters.filters]);

  useEffect(() => {
    if (!!query) {
      GTags.search(query);
      posthog?.capture("Game Search", { query });
    }
  }, [query]);

  const sortGames = (params) => {
    getGames({ ...params });
  };

  const getGames = async (params = {}) => {
    setLoading(true);
    setError(INITIAL_ERROR);
    if (window.SEARCH_TYPE === SEARCH_TYPES.best) {
      window.SEARCH_TYPE = SEARCH_TYPES.browse;
      sortConfig.current = { sort: "likes_count", order: "dsc" };
    }

    const option = isInitialSearch
      ? window.SEARCH_TYPE || SEARCH_TYPES.browse
      : undefined;

    // best porn games will be sorted by likes

    const url = isInitialSearch
      ? "/products/api/games/initial-search-page"
      : "/products/api/games/search-games";

    try {
      const res = await request({
        url,
        withCredentials: true,
        baseURL: isInitialSearch ? BASE_CDN_URL : BASE_URL,
        params: {
          ...stringifyFilters(filters.filters),
          ...sortConfig.current,
          ...params,
          page: parseInt(page) || 1,
          option,
          title: searchQuery,
        },
      });

      const last_page = res.find(
        (item) => item.last_page !== undefined
      )?.last_page;

      setLastPage(last_page);
      setGames(withoutLastPage(res));
      setError(INITIAL_ERROR);
      setLoading(false);

      delete window.SEARCH_TYPE; //after searching, we don't need it anymore
    } catch (error) {
      if (axios.isCancel(error)) return; // wait for one to be finished
      setError((prev) => ({
        ...prev,
        has: true,
        body: `Oops! Error Occured `,
      }));
      setLoading(false);
    }
  };
  const searchFunction = () => {
    if (!!searchQuery.length) {
      try {
        history.push(
          generatePath(match.path, { query: searchQuery, type, param, page: 1 })
        );
      } catch {
        history.push(`/porn-games/${searchQuery}/1`);
      }
    } else {
      setError({
        has: true,
        body: "Type query to search",
      });
    }
  };

  return (
    <main id="adult-games" className="adult-games" ref={ref}>
      <div className="inner">
        <Header
          title="Porn Games"
          toggle={toggle}
          searchFunction={searchFunction}
          setSearchQuery={(e) => setSearchQuery(e.target.value)}
          searchQuery={query}
          sort={sortGames}
          sortConfig={sortConfig}
        />

        <div className="body">
          <Filter
            type={type}
            param={param}
            allowedFilters={[
              "tags",
              "os",
              "status",
              "genre",
              "languages",
              "engine",
              "download_type",
            ]}
            filter={filters.active}
            toggle={toggle}
            setGames={setGames}
            callback={(filters) => setFilters({ filters, active: false })}
          />
          <div className="search_results_content">
            <section aria-label="All Found Games">
              <div className="games">
                <h2>Best Matches</h2>
                {error.has && <div className="error">{error.body}</div>}

                {isLoading ? (
                  <LoaderInside />
                ) : !!games.length ? (
                  <>
                    <TransitionGroup component="div" className="all-games">
                      {games?.map((game) => (
                        <CSSTransition
                          timeout={300}
                          in={true}
                          mountOnEnter
                          classNames="popup"
                          key={game.id}
                        >
                          <Suspense fallback={<LoaderInside />}>
                            <Game {...game} />
                          </Suspense>
                        </CSSTransition>
                      ))}
                    </TransitionGroup>
                    <PaginationSlider
                      initial_page_number={currentPageNumber}
                      total_number_of_pages={lastPage}
                    />
                  </>
                ) : (
                  (query || filters.filters) && (
                    <div className="not-found">No Games found :(</div>
                  )
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AdultGames;
