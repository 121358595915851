import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import relativeDate from "relative-date";
import useSWRInfinite from "swr/infinite";
import { Header, LoadMore, sort } from "../../Common";
import { fetcher } from "../Cms";
import "../css/GameCreation.css";
import { MainHeader } from "../Headers";
import { ContentTile } from "../Tile";
import { toSpicyTokensWithEuro } from "../Users/Withdraw";
import { payoutOptions } from "./Options";

const Payouts = ({ toggler, subHeader }) => {
  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) =>
      `${window.domainName}/store/api/payout-requests/list?page=${index + 1}`,
    fetcher,
    { revalidateOnFocus: false }
  );

  const payouts = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];
  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  const sortState = (key) => (order) => {
    mutate(sort(key, payouts, order), false);
  };

  const headers = [
    { text: "TEAM ID", sort: sortState("team") },
    { text: "PAYMENT METHOD", sort: sortState("payment_method_used") },
    { text: "AMOUNT", sort: sortState("amount") },
    { text: "PAID", sort: sortState("paid") },
    { text: "REQUESTED DATE", sort: sortState("request_date") },
  ];
  const options = payoutOptions();

  return (
    <div className="body">
      <MainHeader toggler={toggler} header={subHeader} buttons={false} />
      <div className="content">
        <main id="game-article" className="cms-details">
          <div
            className="header"
            style={{
              gridTemplateColumns: `var(--game-item-frac) 4fr repeat(${
                headers.length - 2
              }, var(--game-item-frac)) 2fr`,
            }}
          >
            {headers.map((header, i) => (
              <Header header={header} key={i} />
            ))}
          </div>
          <p className="instructions list blue-icon">Completed Payout</p>
          <TransitionGroup className="body" component="div">
            {payouts.map((payout, i) => {
              const data = [
                payout.team,
                payout.payment_method_used,
                toSpicyTokensWithEuro(payout.amount),
                payout.paid ? "Paid" : "Processing",
                relativeDate(new Date(payout.request_date)),
              ];

              return (
                <CSSTransition
                  key={i}
                  timeout={300}
                  classNames="popup"
                  mountOnEnter
                >
                  <ContentTile
                    headers={headers.map((header) => header.text)}
                    options={options}
                    data={data}
                    color={payout.paid ? "#00b4f2" : undefined}
                    revalidate={() => mutate(payouts, true)}
                    popupData={{
                      mark: {
                        payout_id: payout.id,
                      },
                    }}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
          {errors && <div className="error">{errors.error}</div>}
          {error && <div className="error">{error.message}</div>}
          <LoadMore onClick={() => setSize((prev) => prev + 1)} />
        </main>
      </div>
    </div>
  );
};

export default Payouts;
