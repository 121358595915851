import { BASE_CDN_URL, request } from "src/utils/Axios";

import Autoplay from "embla-carousel-autoplay";
import { Link } from "react-router-dom";
import { LoaderInside } from "src/components/Common";
import RequestError from "src/errors/RequestError";
import { getClass } from "src/utils/getClass";
import CarouselProvider from "src/v2/components/carousel/Carousel";
import {
  NextButton,
  PrevButton,
} from "src/v2/components/carousel/CarouselArrows";
import CarouselContent from "src/v2/components/carousel/CarouselContent";
import { CarouselDots } from "src/v2/components/carousel/CarouselDots";
import FeaturedGame from "src/v2/components/games/FeaturedGame";
import useSWRImmutable from "swr/immutable";

const buttonStyle =
  "hidden md:block absolute top-0 bottom-0 my-auto aspect-square rounded-full w-16 min-w-0";

const FeaturedGames = ({
  id,
  title,
  getGames,
  sectionLink = "/porn-games/1",
}) => {
  const { data: games, isValidating, error } = useSWRImmutable(id, getGames);

  if (error) return <RequestError error={error.message} />;

  return (
    <section
      aria-labelledby="featured_games_section"
      className="featured-game main my-20"
    >
      <div className="head">
        <Link to={sectionLink}>
          <h2 id="featured_games_section" className="title">
            {title}
          </h2>
        </Link>

        <Link to="/porn-games/1" className="hidden md:inline">
          <div className="more">Browse All Games</div>
        </Link>
      </div>
      <CarouselProvider
        plugins={[
          Autoplay({
            active: true,
            stopOnFocusIn: true,
            stopOnMouseEnter: true,
          }),
        ]}
      >
        <div className="relative">
          <div className="w-full">
            {isValidating ? (
              <LoaderInside />
            ) : (
              <div>
                <CarouselContent flexClassName="items-start">
                  {games?.map((game) => (
                    <FeaturedGame game={game} key={game.id} />
                  ))}
                </CarouselContent>
                <CarouselDots className={"mx-auto"} />
              </div>
            )}

            <PrevButton className={getClass(buttonStyle, "left-0 -ml-5")} />
            <NextButton className={getClass(buttonStyle, "right-0 -mr-5")} />
          </div>
        </div>
      </CarouselProvider>
    </section>
  );
};

export default FeaturedGames;
