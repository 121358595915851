import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTeamID } from "src/components/CMS/RoleContext";
import { LoaderInside } from "src/components/Common";
import { getTeamBundleByBundleId } from "src/v2/services/gameBundlesService";
import { bundleSchema } from "../dataModels";
import AddNewBundle from "./AddNewBundle";

const EditBundle = ({ bundleId = null, defaultPageId = "info" }) => {
  const teamId = useTeamID();

  const [loading, setLoading] = useState(false);
  const [bundle, setBundle] = useState({});

  useEffect(() => {
    if (bundleId) getBundle(bundleId);
  }, [bundleId]);

  const getBundle = async (bundleId) => {
    try {
      setLoading(true);
      const res = await getTeamBundleByBundleId(bundleId, teamId);

      const bundle = bundleSchema.parse(res);

      setBundle(bundle);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoaderInside />;

  return (
    <div>
      <AddNewBundle
        editMode
        defaultBundleId={bundleId}
        defaultValues={bundle}
        defaultPageId={defaultPageId}
      />
    </div>
  );
};

export default EditBundle;
