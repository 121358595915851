import { useEffect, useMemo, useRef, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { PaginationSlider } from "src/components/Article/Slider";
import { SubmitButton } from "src/components/CMS/Common";
import { Chip } from "src/components/Chip";
import ForceLogin from "src/components/ForceLogin";
import { loggedInValidation } from "src/components/Validation";
import { ContentMetaInfo } from "src/components/Video/FullPage/CreatorInfo";
import Popup from "src/components/common/Popup";
import { ShareMenu } from "src/components/common/ShareMenu";
import useQuery from "src/hooks/useQuery";
import { useToggle } from "src/hooks/useToggle";
import { authRequest } from "src/utils/Axios";
import { numberCompact } from "src/utils/number-format";
import createSublists from "src/utils/sub-list";
import { updateViewedImages } from "src/utils/updateViewed";
import Image from "../Image";
import PublicGalleryImageUpload from "./PublicGalleryImageUpload";
import "./style.css";
import downloadFromUrl from "src/utils/downloadFromUrl";
import MasonryImagesGrid from "../MasonryImagesGrid";
import { LikeButton } from "src/components/Atoms/Buttons";

const MAX_IMAGES_PER_PAGE = 21;

function Info({ name, content }) {
  return (
    <div className="cell">
      <div className="name">{name}</div>
      <div className="content">{content}</div>
    </div>
  );
}

const Gallery = ({ gallery, team }) => {
  const coverRef = useRef();
  const history = useHistory();
  const query = useQuery();

  // cover image can be setted using ?id=<image_id> or Internal router state "from" key
  const coverImageId =
    history.location.state?.from ?? parseInt(query.get("id"));

  const coverDefault = gallery.cover ?? gallery.images[0];

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showShareMenu, toggleShareMenu] = useToggle();
  const [showUploadPopup, toggleUploadPopup] = useToggle();
  const [currentImageId, setCurrentImageId] = useState(
    coverImageId ?? coverDefault?.id
  );

  useEffect(() => {
    setLoading(true);
    if (coverImageId) setCurrentImageId(coverImageId);
  }, [coverImageId]);

  const removeLoading = () => {
    setLoading(false);
  };

  const ids = useMemo(() => {
    if (!gallery.images) return [];
    return gallery.images.map((img) => img.id);
  }, [gallery.images]);

  const moveCurrent = (offset) => {
    setLoading(true);

    setCurrentImageId((prev) => {
      const nextId = (ids.indexOf(prev) + offset) % ids.length;
      if (nextId < 0) return ids[ids.length + nextId]; // going back

      return ids[nextId];
    });
  };

  const currentImage = useMemo(() => {
    if (currentImageId)
      return gallery.images.find((img) => img.id === currentImageId);

    return coverDefault;
  }, [coverImageId, currentImageId]);

  useEffect(() => {
    if (currentImage) updateViewedImages([currentImage.id]);
  }, [currentImage]);

  const imagePages = useMemo(() => {
    return createSublists(gallery.images, MAX_IMAGES_PER_PAGE);
  }, [gallery]);

  const shareableLink = useMemo(() => {
    const link = new URL(window.location.href);

    if (currentImageId && !link.searchParams.has("id")) {
      link.searchParams.append("id", currentImageId);
    }
    return link.toString();
  }, [currentImageId]);

  const redirect = (tag) => {
    try {
      history.push(generatePath("/galleries/tag/:tag/1", { tag }));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDownloadImage = async () => {
    await downloadFromUrl(
      currentImage.image,
      `Spicygaming Image-${currentImage.id}.webp`
    );
  };

  const downloadGallery = async () => {
    try {
      const res = await authRequest({
        url: "/products/api/spicyart/gallery/download",
        method: "POST",
        params: {
          gallery_id: gallery.id,
        },
      });
      await toast.promise(downloadFromUrl(res.url), {
        pending: "Gallery is downloading. Please wait...",
        success: "Gallery Succesfully downloaded",
        error: "Gallery Download failed",
      });
    } catch (error) {}
  };

  const likeCurrentImage = async (previouslyLiked) => {
    try {
      const res = await toast.promise(
        authRequest({
          url: "/products/api/spicyart/gallery/image/like",
          method: "POST",
          data: { image_id: currentImage.id },
        }),
        {
          pending: previouslyLiked ? "Disliking..." : "Liking...",
          success: previouslyLiked ? "Disliked" : "Liked",
        }
      );

      const liked = res.liked;

      return liked;
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <article className="Gallery">
      {currentImage && (
        <div className="brief">
          <div className="cover" data-loading={loading}>
            <Image
              noRedirect
              onLoad={removeLoading}
              owner={team.name}
              ref={coverRef}
              loading="eager"
              image={currentImage}
              width={1440}
              maxHeight={600}
            />
            <div className="image-actions">
              <SubmitButton
                rounded
                icon="fa-chevron-left"
                request={() => moveCurrent(-1)}
              />
              <SubmitButton
                rounded
                icon="fa-chevron-right"
                request={() => moveCurrent(1)}
              />
            </div>
          </div>
          <div className="actions">
            <div className="quick">
              <LikeButton
                numOfLikes={currentImage.likes}
                isLiked={currentImage.user_liked}
                onLike={likeCurrentImage}
                rounded
              />

              <SubmitButton
                rounded
                type="secondary"
                icon="fa-share"
                request={toggleShareMenu}
              />
            </div>

            <SubmitButton
              label="Download Image"
              icon="fa-download"
              request={handleDownloadImage}
            />
            <SubmitButton
              disabled={!gallery.allow_downloads}
              label="Download Gallery"
              icon="fa-download"
              request={downloadGallery}
            />
            {gallery.public && (
              <SubmitButton
                type="secondary"
                label="Add your image"
                icon="fa-upload"
                request={toggleUploadPopup}
              />
            )}
          </div>

          <ContentMetaInfo
            creation_date={gallery.creation_date}
            currentImage={currentImage}
            description={gallery.description}
            views={currentImage.views}
            setTagUrl={(param) => `/galleries/tag/${param}/`}
          />

          <div className="info">
            <div className="meta-info">
              <Info
                name={"Tags"}
                content={
                  <div className="tags">
                    {currentImage.tags?.slice(0, 20)?.map((tag) => (
                      <span key={tag} onClick={() => redirect(tag)}>
                        <Chip>{tag?.replace(/_/g, " ")}</Chip>
                      </span>
                    ))}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      )}

      <MasonryImagesGrid
        maxColumnCount={3}
        images={imagePages[page - 1]}
        owner={team.id}
      />

      <PaginationSlider
        initial_page_number={1}
        total_number_of_pages={imagePages.length}
        onClick={setPage}
      />

      <Popup
        open={showUploadPopup}
        maxWidth={1080}
        onClose={toggleUploadPopup}
        showCloseButton
      >
        {!loggedInValidation() ? (
          <ForceLogin url={`/gallery/${gallery.id}`} />
        ) : (
          <PublicGalleryImageUpload
            uploadedImages={gallery.images}
            galleryID={gallery.id}
            galleryName={gallery.name}
          />
        )}
      </Popup>

      <ShareMenu
        show={showShareMenu}
        onClose={toggleShareMenu}
        shareLink={shareableLink}
        shareText={gallery.name}
      />
    </article>
  );
};

export default Gallery;
