import React, { useContext } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import relativeDate from "relative-date";
import useSWRInfinite from "swr/infinite";
import { LoadMore } from "../../Common";
import { authorizedRequest } from "../../Validation";
import { fetcher } from "../Cms";
import { MainHeader } from "../Headers";
import { RoleContext } from "../RoleContext";
import { ContentTile } from "../Tile";
import { gameNewsApprovalOptions } from "./Options";

const GameNewsApproval = ({ toggler, subHeader }) => {
  const { role } = useContext(RoleContext);

  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) => {
      return `${window.domainName}/news/api/news/game-news/unapproved?page=${
        index + 1
      }`;
    },
    fetcher,
    { revalidateOnFocus: false }
  );

  const requests = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];
  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  const options = gameNewsApprovalOptions(role);
  const headers = [
    "ARTICLE TITLE",
    "SHORT DESCRIPTION",
    "GAME TITLE",
    "DEV TEAM",
    "DATE CREATED",
  ];

  const search = async (query) => {
    const res = await authorizedRequest({
      url: `${window.domainName}/news/api/news/game-news/unapproved/cms/search?search_term=${query}`,
    });

    if (res.error) return [];
    return res;
  };
  const searchCallback = (data) => {
    mutate(data, false);
  };

  return (
    <div className="body">
      <MainHeader
        toggler={toggler}
        header={subHeader}
        element={"search"}
        search={{
          func: search,
          callback: searchCallback,
        }}
      />
      <div className="content">
        <main id="approve-content-requests" className="cms-details approve">
          <div
            className="header"
            style={{
              gridTemplateColumns: `var(--game-item-frac) 4fr repeat(${
                headers.length - 2
              }, var(--game-item-frac)) 2fr`,
            }}
          >
            {headers.map((header, i) => (
              <div className="item" key={i}>
                {header}
              </div>
            ))}
          </div>
          <TransitionGroup className="body" component="div">
            {requests.map((request, i) => {
              const data = [
                request.title,
                request.short_description,
                request.game_title,
                request.creator_team,
                relativeDate(new Date(request.date_posted)),
              ];

              const commonPopupData = {
                title: request.title,
                description: request.short_description,
                creator: request.creator_team,
                created: request.date_posted,
                id: request.id,
              };
              return (
                <CSSTransition
                  key={i}
                  timeout={300}
                  classNames="popup"
                  mountOnEnter
                >
                  <ContentTile
                    headers={headers}
                    options={options}
                    revalidate={() => mutate(requests, true)}
                    popupData={{
                      edit: {
                        id: request.id,
                      },
                      delete: {
                        ...commonPopupData,
                        category: "game-news", //this will be in the URL. DO NOT change
                      },
                      approve: {
                        ...commonPopupData,
                      },
                    }}
                    data={data}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
          {errors && <div className="error">{errors.error}</div>}
          {error && <div className="error">{error.message}</div>}

          <LoadMore onClick={() => setSize((prev) => prev + 1)} />
        </main>
      </div>
    </div>
  );
};

export default GameNewsApproval;
