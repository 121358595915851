import { generatePath, Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getClass } from "../../utils/getClass";
import "./css/SideMenu.css";
import { usePermissions } from "./Permission";
import { Roles, useRoleInfo } from "./RoleContext";
import { Chip } from "../Chip";

const Tab = ({ title, icon, links, id, baseURL, toggler }) => {
  const permission = usePermissions();
  const { role, teamCount } = useRoleInfo();
  const history = useHistory();
  const paths = Object.values(permission.paths);

  const activePath = paths.find((path) =>
    window.location.pathname.includes(path.path)
  );

  const isActive = () => {
    if (
      activePath &&
      (links.find((link) => {
        return link.path === window.location.pathname;
      }) ||
        activePath.id === id)
    )
      return true;
    return false;
  };

  const redirect = (e) => {
    toggler();
    if (teamCount <= 0 && role !== Roles.ADMIN) {
      e.preventDefault();
      history.push("/cms/personal/teams/create-team");
      toast.info("You need to create a team first");
    }
  };

  return (
    <div className="tab expand" id={id}>
      <div className={`title active ${isActive() && "active-page"}`}>
        <span className={`fa ${icon}`}></span>
        <span>
          <span className="t">{title}</span>
        </span>
      </div>

      <ul>
        {links.map((link) => {
          const fullPath = baseURL + link.path;
          const generatedPath = generatePath(fullPath);
          const showTeamChoose = !!link.linkProps?.showTeamChoose;

          // hide links which required default team for admins
          if (showTeamChoose && role === Roles.ADMIN) return null;

          return (
            <li
              key={generatedPath}
              onClick={showTeamChoose ? redirect : toggler}
              className={getClass(
                window.location.pathname === generatedPath && "active"
              )}
            >
              <Link to={generatedPath}>
                <span>{link.text}</span>
                {link.linkProps?.isNew && <Chip>New</Chip>}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const SideMenu = ({ toggle, toggler }) => {
  const permission = usePermissions();
  const paths = Object.values(permission.paths);

  return (
    <aside className={getClass("side-menu", toggle && "active")}>
      <div>
        <div className="material-icons" onClick={toggler}>
          close
        </div>

        {paths.map((section) => (
          <Tab
            key={section.id}
            title={section.header}
            links={Object.values(section.paths)}
            icon={section.icon}
            id={section.id}
            baseURL={section.path}
            toggler={toggler}
          />
        ))}
      </div>
    </aside>
  );
};

export default SideMenu;
