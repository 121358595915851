import { useMemo } from "react";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import Error404, { ErrorNotAllowed } from "../Errors";
import SentryRoute from "src/customRoute";
import { getClass } from "src/utils/getClass";

const ByURL = ({ pages, firstPageId, propsForEachPageComponent }) => {
  const match = useRouteMatch();

  const firstPage = useMemo(() => {
    const selected = pages.find((page) => page.id === firstPageId);
    return selected;
  }, [pages, firstPageId]);

  return (
    <>
      <nav>
        <ul>
          {pages.map((page, key) => (
            <li key={key} className={getClass(page.disabled && "disabled")}>
              <NavLink
                activeClassName={page.disabled ? "" : "active"}
                to={page.disabled ? match.url : match.url + page.url}
              >
                {page.displayText}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      <Switch>
        {firstPage && (
          <Redirect exact path={match.url} to={match.url + firstPage?.url} />
        )}
        {pages.map((page) => {
          const Component = page.component ?? (() => null);
          return (
            <SentryRoute
              exact
              key={page.id}
              path={match.url + page.url}
              component={(props) => {
                if (page.disabled) return <ErrorNotAllowed />;
                return <Component {...props} {...propsForEachPageComponent} />;
              }}
            />
          );
        })}

        <SentryRoute path="*">
          <Error404 />
        </SentryRoute>
      </Switch>
    </>
  );
};

export default ByURL;
