import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useParams } from "react-router-dom";
import Game from "src/components/AdultGames/Game";
import { isNeedInHorizontal } from "src/components/ArticleDetails/Elements";
import Banner from "src/components/Banners";
import { AD_IDS } from "src/components/CMS/Ads/Section";
import { Loader } from "src/components/Common";
import { LazyLoaded } from "src/components/LazyLoaded";
import CreatorInfo from "src/components/Video/FullPage/CreatorInfo";
import SuggestVideos from "src/components/Video/FullPage/SuggestedVideos";
import { vastPullzones } from "src/components/VideoPlayer/pullzones";
import { BASE_CDN_URL, request } from "src/utils/Axios";

import { PlaylistPlayer } from "../Player";
import "./style.css";

const PLAYLIST_POSITION = "Playlist_Items";

const PlaylistFullPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState({ main: true });
  const [info, setInfo] = useState({});

  const toggleLoading = (key, state) =>
    setLoading((prev) => ({ ...prev, [key]: state }));

  useEffect(() => {
    getDetails(id);
  }, [id]);

  const getDetails = async (id) => {
    try {
      toggleLoading("main", true);
      const res = await request({
        url: "/community/playlists",
        params: { playlist_id: id },
        baseURL: BASE_CDN_URL,
        withCredentials: true,
      });
      setInfo(res);
    } catch (error) {
    } finally {
      toggleLoading("main", false);
    }
  };

  return (
    <main className="FullPage PlaylistFullPage" data-testid="FullPage">
      <div className="inner">
        {loading.main ? (
          <Loader />
        ) : (
          <div className="content">
            <article>
              <PlaylistPlayer
                // playlistPosition={PLAYLIST_POSITION}
                options={{ aspectRatio: "16:9" }}
                vastZone={vastPullzones.default}
                videos={info.videos.map((video) => ({
                  title: video.title,
                  description: video.description,
                  id: video.file.bunny_id,
                  pullzone: video.file.pullzone,
                  thumbnail: video.file.thumbnail_image,
                  length: video.file.length,
                }))}
              />
              <LazyLoaded>
                <h1>
                  <span>{info.name}</span>
                </h1>
                <CreatorInfo
                  team={info.team}
                  shareLink={window.location.href}
                  shareText={info.name}
                />
                {!!info.games?.length && (
                  <section className="adult-games element-games">
                    <legend>
                      <h2>Games Mentioned</h2>
                    </legend>
                    <OwlCarousel
                      className="owl-theme"
                      dots
                      responsive={{
                        0: { items: 1 },
                        480: {
                          items: isNeedInHorizontal(info.games?.length) ? 1 : 2,
                        },
                        1200: {
                          items: isNeedInHorizontal(info.games?.length) ? 1 : 3,
                        },
                      }}
                    >
                      {info.games?.map((game) => (
                        <Game
                          {...game}
                          key={game.id}
                          isHorizontal={isNeedInHorizontal(info.games?.length)}
                        />
                      ))}
                    </OwlCarousel>
                  </section>
                )}
              </LazyLoaded>
            </article>
            <aside>
              <section className="watch-next" id={PLAYLIST_POSITION}></section>
              <Banner
                id={AD_IDS.front.gallery}
                width={300}
                height={250}
                jsAd="85914"
              />
              <hr />
              <SuggestVideos videoId={info.videos[0]?.video_id} />
            </aside>
          </div>
        )}
      </div>
    </main>
  );
};

export default PlaylistFullPage;
