import currencies from "src/constants/currencies";
import { CONVERSION_RATE } from "src/constants/store";

export const currencyFormatter = (id) => {
  const currency = currencies.find((c) => c.id === id);

  if (!currency) return;

  const formatter = Intl.NumberFormat(currency.locale, {
    currency: currency.id,
    style: "currency",
    maximumFractionDigits: 2,
  });

  return formatter;
};

export const convertToSpicyTokens = (eurAmount = 0) => {
  return Math.round(eurAmount * CONVERSION_RATE);
};
