import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { authRequest } from "src/utils/Axios";
import Input from "src/v2/components/form-inputs/Input";
import Form from "src/v2/components/form/Form";
import { z } from "zod";

import { logoutUser } from "../Logout";
import { PASSWORD_REGEX } from "../Register";
import SubmitButton from "src/v2/components/SubmitButton";

const passwordMsg =
  "Password need to be at least 6 characters long with a Number and a special character";

const passwordsSchema = z
  .object({
    old_password: z.string().regex(PASSWORD_REGEX, passwordMsg),
    new_password: z.string().regex(PASSWORD_REGEX, passwordMsg),
    re_new_password: z.string().regex(PASSWORD_REGEX, passwordMsg),
  })
  .refine((obj) => obj.new_password === obj.re_new_password, {
    message: "Passwords don't match",
    path: ["re_new_password"], // path of error
  });

const MemberPasswordReset = () => {
  const form = useForm({
    resolver: zodResolver(passwordsSchema),
  });

  const submit = async () => {
    try {
      const isValid = await form.trigger();
      if (!isValid) throw new Error("Data not valid");
      const data = form.getValues();

      const res = await authRequest({
        url: "/api/members/change-password",
        method: "POST",
        data: {
          old_password: data.old_password,
          new_password: data.new_password,
        },
      });
      if (res.success) {
        toast.success("Password reset successfull");
        await logoutUser("/login");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <section className="MemberPasswordReset">
      <legend>RESET PASSWORD</legend>

      <Form form={form}>
        <div className="grid md:grid-cols-2 gap-4">
          <Form.Item name="old_password" label="Old Password">
            <Input />
          </Form.Item>
          <Form.Item name="new_password" label={"New Password"}>
            <Input />
          </Form.Item>
          <Form.Item name="re_new_password" label={"Confirm New Password"}>
            <Input />
          </Form.Item>
        </div>
        <SubmitButton onClick={submit}>Reset Password</SubmitButton>
      </Form>
    </section>
  );
};

export default MemberPasswordReset;
