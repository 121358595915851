import dateFormat from "dateformat";
import { useEffect, useState } from "react";
import relativeDate from "relative-date";
import { LoaderInside } from "src/components/Common";
import RequestError from "src/errors/RequestError";
import { authRequest } from "src/utils/Axios";
import { SubmitButton } from "../../Common";

const ArticleVersionControl = ({ urlSegment, articleID }) => {
  const url = `/news/api/news/${urlSegment}/cached-versions`;
  const [autoSaves, setAutoSaves] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getVersions();
  }, []);

  const getVersions = async () => {
    try {
      setError(null);
      setLoading(true);
      const res = await authRequest({
        url,
        params: { article_id: articleID },
      });
      setAutoSaves(res);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const restoreVersion = async (versionID) => {
    try {
      const res = await authRequest({
        url: url + "/restore",
        method: "POST",
        data: {
          article_id: articleID,
          version_id: versionID,
        },
      });
      if (res.success) {
        window.location.reload();
      }
    } catch (error) {}
  };
  return (
    <section className={"VersionControlPopup"}>
      <legend>
        <h2>Select Auto Saved Versions</h2>
      </legend>
      <div className="content">
        <div className="instructions">
          <p>These are automatically saved versions of your article. </p>
          <p>
            Select any of these versions you want to restore by clicking
            "Restore".
          </p>
          <p>That version will be saved as your latest article content.</p>
        </div>
        {loading && <LoaderInside />}
        <RequestError error={error} />
        <ul className="save-list">
          {autoSaves.map((autoSave) => (
            <li key={autoSave.id}>
              <span>
                Auto Saved {relativeDate(new Date(autoSave.created_at))} (
                {dateFormat(autoSave.created_at)})
              </span>
              <SubmitButton
                type="secondary"
                label="Restore"
                request={() => restoreVersion(autoSave.id)}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default ArticleVersionControl;
