// Avatar.js
import React from "react";
import "./style.css";

const Avatar = ({ src, name, width = 40 }) => {
  const onLoad = (e) => {
    e.target.dataset.loaded = true;
  };
  const onError = (e) => {
    e.target.dataset.loaded = false;
  };

  const getInitials = (name) => {
    if (!name) return null;
    const nameParts = name.split(" ");
    return nameParts
      .map((part) => part[0])
      .slice(0, 2) // Display up to 2 initials
      .join("")
      .toUpperCase();
  };

  return (
    <div className="Avatar" style={{ "--width": width }}>
      <div className="initials">{getInitials(name)}</div>
      <img
        loading="lazy"
        width={width}
        height={width}
        src={src}
        alt={name}
        onLoad={onLoad}
        onError={onError}
      />
    </div>
  );
};

export default Avatar;
