import Game from "../AdultGames/Game";
import { idGen } from "../CMS/Common";
import React, { Suspense } from "react";
import { LoaderInside } from "../Common";
import NewsMini from "./NewsMini";
import { generateURL } from "../resizeImages";
import relativeDate from "relative-date";
import AdultGame from "./AdultGame";
import { Player } from "../Video/Player";
import ImageGallery from "../SpicyArt/Collection";

const VideoPlayer = React.lazy(() => import("../VideoPlayer"));

const getElement = (id) => {
  const elements = {
    games: {
      id: "games",
      element: AdultGame,
      computedProps(content) {
        return {
          days: relativeDate(new Date(content.last_updated)),
        };
      },
    },
    game_news: {
      id: "game_news",
      element: NewsMini,

      computedProps(content) {
        return {
          img: generateURL(800, 450, content.landscape_cover),
          description: content.short_description,
          time: relativeDate(new Date(content.last_updated)),
          ...content.metadata,
        };
      },
    },
    staff_reviews: {
      id: "staff_reviews",
      element: NewsMini,

      computedProps(content) {
        return {
          img: generateURL(800, 450, content.landscape_cover),
          description: content.short_description,
          time: relativeDate(new Date(content.last_updated)),
          ...content.metadata,
        };
      },
    },
    blog_post: {
      id: "blog_posts",
      element: NewsMini,
      computedProps(content) {
        return {
          img: generateURL(800, 450, content.landscape_cover),
          description: content.short_description,
          time: relativeDate(new Date(content.last_updated)),
          ...content.metadata,
        };
      },
    },
    video: {
      id: "video",
      element: Player,
      computedProps(content) {
        return {
          video: content,
          maxWidth: 840,
        };
      },
    },
    gallery: {
      id: "gallery",
      element: ImageGallery,
      computedProps(content) {
        return {
          collection: content,
          maxWidth: 840,
        };
      },
    },
  };

  return Object.values(elements).find((elem) => elem.id === id);
};

export const Mini = ({ content }) => {
  if (!content) return null;

  const type = content.metadata?.type;
  const id = idGen(type);
  const elementInfo = getElement(id, content.id);
  const Element = elementInfo?.element || (() => null);
  const computedProps = elementInfo?.computedProps?.(content) ?? {};

  return (
    <div className="mini dont-miss-block DontMissComponent">
      <Element {...content} {...elementInfo?.props} {...computedProps} />
    </div>
  );
};
export const Big = ({ video }) => {
  if (!video) return <div className="mini not-found">Video not found</div>;
  return (
    <div className="mini video ">
      <Suspense fallback={<LoaderInside />}>
        <VideoPlayer
          pullzoneID={video?.pullzone}
          videoID={video?.video_id}
          thumbnailName="thumbnail.webp"
          redirectURL={video.url}
        />
      </Suspense>
    </div>
  );
};
