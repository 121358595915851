export const SuggestionPanelTypes = {
  FEATURED: { name: "Recommended", param: "featured" },
  RECENT: { name: "Recent", param: "recent" },
  RELATED: { name: "Related", param: "related" },
  TEAM: { name: "From #{team}", param: "team" },
};
export const allSuggestionPanelTypes = [
  SuggestionPanelTypes.FEATURED,
  SuggestionPanelTypes.TEAM,
  SuggestionPanelTypes.RELATED,
  SuggestionPanelTypes.RECENT,
];
