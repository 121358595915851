import React, { useContext } from "react";
import relativeDate from "relative-date";
import useSWRInfinite from "swr/infinite";
import { LoadMore, sort } from "../../Common";
import { authorizedRequest } from "../../Validation";
import { fetcher } from "../Cms";
import { REVALIDATE_ON_FOCUS } from "../Common";
import "../css/GameCreation.css";
import { InnerHeader } from "../Headers";
import { RoleContext } from "../RoleContext";
import { ContentTile } from "../Tile";
import { userGameReviewApprovalOptions } from "./Options";

const UserGameReviewsApprovals = ({ toggler, subHeader }) => {
  // menu button togglers
  const { role } = useContext(RoleContext);

  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) => {
      return `${
        window.domainName
      }/products/api/reviews/list?unapproved=true&page=${index + 1}`;
    },
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );

  const reviews = sort(
    "score",
    data ? data.flat().filter((obj) => !(obj.error || obj.last_page)) : [],
    "asc"
  );
  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  const options = userGameReviewApprovalOptions(role);
  const headers = ["GAME TITLE", "REVIEW", "USERNAME", "SCORE", "DATE POSTED"];
  const search = async (query) => {
    const res = await authorizedRequest({
      url: `${window.domainName}/products/api/reviews/cms/search?search_term=${query}`,
    });

    if (res.error) return [];
    return res;
  };
  const searchCallback = (data) => {
    mutate(data, false);
  };
  return (
    <div className="body">
      <InnerHeader
        subHeader={subHeader}
        toggler={toggler}
        header="USER GAME REVIEWS"
        search={{
          func: search,
          callback: searchCallback,
        }}
      />
      <div className="content">
        <main id="game-article">
          <div
            className="header"
            style={{
              gridTemplateColumns: `var(--game-item-frac) 4fr repeat(${
                headers.length - 2
              }, var(--game-item-frac)) 2fr`,
            }}
          >
            {headers.map((header, i) => (
              <div className="item" key={i}>
                {header}
              </div>
            ))}
          </div>
          <div className="body">
            {reviews.map((review, i) => {
              const data = [
                review.game_title,
                review.text,
                review.author,
                review.score,
                relativeDate(new Date(review.date_posted)),
              ];
              const common = {
                text: review.text,
                game_title: review.game_title,
                creator: review.author,
                created: review.date_posted,
                score: review.score,
                id: review.id,
              };
              return (
                <ContentTile
                  headers={headers}
                  options={options}
                  revalidate={() => mutate(reviews, true)}
                  popupData={{
                    delete: { ...common },
                    report: { ...common },
                    approve: { ...common, approve: true },
                    view: {
                      review: review.text,
                      creator: review.author,
                    },
                    edit: {
                      review: review.text,
                      creator: review.author,
                      id: review.id,
                    },
                  }}
                  data={data}
                  key={i}
                  color={
                    review.score < 35
                      ? "#ff4242"
                      : review.score < 75
                      ? "#fff565"
                      : ""
                  }
                />
              );
            })}
          </div>
          {errors && <div className="error">{errors.error}</div>}
          {error && <div className="error">{error.message}</div>}

          <LoadMore onClick={() => setSize((prev) => prev + 1)} />
        </main>
      </div>
    </div>
  );
};

export default UserGameReviewsApprovals;
