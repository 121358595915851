import React from "react";
import {
  NOT_VALID_BORDER_STYLE,
  VALID_BORDER_STYLE,
} from "src/components/CMS/validation";

const Select = ({ id, error, options = [], ...inputProps }, ref) => {
  return (
    <div className="">
      <select
        className="bg-neutral-800 border border-neutral-400 rounded py-4 px-6 w-full focus:ring outline-none focus:ring-neutral-600 text-white"
        id={id}
        style={{
          border: error ? NOT_VALID_BORDER_STYLE : VALID_BORDER_STYLE,
        }}
        {...inputProps}
        ref={ref}
      >
        <option value={""}>- Select from below</option>
        {options?.map((option) => (
          <option
            key={option.value}
            value={option.value}
            selected={inputProps.defaultValue === option.value}
          >
            - {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default React.forwardRef(Select);
