import React from "react";

function DevelopersPage() {
  return (
    <div>
      <section>
        <div className="content">
          <center>
            <h1>Under Development</h1>
          </center>
        </div>
      </section>
    </div>
  );
}

export default DevelopersPage;
