import { useEffect, useState } from "react";
import "./style.css";
import useQuery from "src/hooks/useQuery";
import { Link, useParams } from "react-router-dom";
import { authRequest } from "src/utils/Axios";
import Error404 from "src/components/Errors";
import { Discord, Patreon } from "src/components/common/social";
import { SubmitButton } from "src/components/CMS/Common";
import { Loader } from "src/components/Common";

const PatreonAuth = () => {
  const query = useQuery();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const code = query.get("code");
  const id = query.get("state");

  useEffect(() => {
    sendToken();
  }, []);

  const sendToken = async () => {
    try {
      if (code) {
        const res = await authRequest({
          method: "POST",
          url: "/api/members/patreon-auth",
          data: { code, team_id: id },
        });

        if (res.success) setSuccess(true);
      }
    } catch (error) {
      setError(error.message);
    }
  };
  if (code === null) return <Error404 />;

  if (success)
    return (
      <main className="discord">
        <div className="success">
          <Patreon />
          <h1>You have successfully connected Spicygaming to your Patreon</h1>
          <br />
          <br />
          <Link to={`/cms/personal/teams/edit/${id}/apps`}>
            <SubmitButton label={"Go to Team's page"} />
          </Link>
        </div>
      </main>
    );

  if (error)
    return (
      <main className="discord">
        <div className="error">{error}</div>
      </main>
    );
  return <Loader />;
};

export default PatreonAuth;
