const pullzones = {
  article: "vz-bfa50297-823",
  test: "vz-7a1f9e76-580",
  games: "vz-3ab35257-6b9",
  videos: "vz-649a5156-cc2",
};

export const folderIds = {
  games: 14122,
  article: 14124,
};

export const vastPullzones = {
  default: "102898",
};

export const thumbnailTypes = {
  article: "Article",
  trailer: "Trailer",
};

export default pullzones;
