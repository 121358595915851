import React from "react";
import FeaturedSection from "./HomePage/Featured";
import { Featured } from "./util";

const FeaturedArticles = () => {
  return (
    <FeaturedSection
      title="FEATURED Articles"
      position={Featured.FEATURED_ARTICLES}
    />
  );
};

export default FeaturedArticles;
