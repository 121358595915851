import Autoplay from "embla-carousel-autoplay";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CarouselProvider from "src/v2/components/carousel/Carousel";
import CarouselContent from "src/v2/components/carousel/CarouselContent";
import { CarouselDots } from "src/v2/components/carousel/CarouselDots";
import {
  Featured,
  getPublicFeaturedContent,
} from "../CMS/Ads/FeaturedListPages/util";
import ImageGallery from "../SpicyArt/Collection";
import { Player } from "../Video/Player";
import FeaturedBig from "./FeaturedBig";
import FeaturedNews from "./FeaturedNews";
import "./css/Featured.css";

const carouselPlugins = [
  Autoplay({
    playOnInit: true,
    active: true,
    stopOnFocusIn: true,
    stopOnMouseEnter: true,
  }),
];

const FeaturedNewsWrapper = () => {
  const [featuredSpots, setFeaturedSpots] = useState({});
  useEffect(() => {
    getFeauturedSpots(Featured.HOME_TOP_MAIN);
    getFeauturedSpots(Featured.HOME_TOP_SIDE);
  }, []);

  const getFeauturedSpots = async (position) => {
    try {
      const res = await getPublicFeaturedContent(position);
      setFeaturedSpots((prev) => ({ ...prev, [position]: res }));
    } catch (error) {}
  };

  return (
    <section aria-labelledby="latest_section" className="featured ">
      <div className="head">
        <h1 id="latest_section" className="title">
          <Link to="/articles">Latest In Porn Games</Link>
        </h1>
        <Link to="/articles" className="hidden md:inline">
          <div className="more">view more</div>
        </Link>
      </div>
      <div className="body">
        <div className="container main Home_Featured_Section">
          <CarouselProvider options={{ loop: true }} plugins={carouselPlugins}>
            <CarouselContent>
              {featuredSpots[Featured.HOME_TOP_MAIN]?.map((content, key) => {
                if (content.metadata?.type === "Gallery") {
                  return (
                    <ImageGallery
                      key={key}
                      maxWidth={1440}
                      collection={content}
                    />
                  );
                }
                if (content.metadata?.type === "Video") {
                  return <Player key={key} maxWidth={1440} video={content} />;
                }
                return (
                  <FeaturedBig
                    key={key}
                    img={content.landscape_cover}
                    alt={content.title}
                    title={content.title}
                    metadata={content.metadata}
                  />
                );
              })}
            </CarouselContent>

            <CarouselDots className="m-auto" />
          </CarouselProvider>
        </div>
        <div className="container side Home_Featured_SideSection">
          <CarouselProvider options={{ loop: true }} plugins={carouselPlugins}>
            <CarouselContent>
              {featuredSpots[Featured.HOME_TOP_SIDE]?.map((article, key) => (
                <FeaturedNews
                  key={key}
                  title={article.title}
                  date={article.last_updated}
                  img={article.square_cover}
                  metadata={article.metadata}
                  description={article.short_description}
                />
              ))}
            </CarouselContent>

            <CarouselDots className="m-auto mt-4" />
          </CarouselProvider>
        </div>
      </div>
    </section>
  );
};

export default FeaturedNewsWrapper;
