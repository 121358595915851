import dateFormat from "dateformat";
import { useContext } from "react";
import { authRequest } from "src/utils/Axios";
import useSWRInfinite from "swr/infinite";
import { LoadMore, sort } from "../../Common";
import { fetcher } from "../Cms";
import { MainHeader } from "../Headers";
import { RoleContext } from "../RoleContext";
import Table, { getColumn } from "../Table/Table";
import "../css/GameCreation.css";
import { blogOptions } from "./Options";

const columns = [
  getColumn("TITLE", { sortKey: "title", accessKey: "title" }),
  getColumn("SHORT DESCRIPTION", {
    sortKey: "short_description",
    accessKey: "short_description",
  }),
  getColumn("USERNAME", { sortKey: "creator", accessKey: "creator" }),
  getColumn("DATE CREATED", {
    sortKey: "date_posted",
    accessFn: (game) => dateFormat(new Date(game.date_posted), "longDate"),
  }),
  getColumn("LAST UPDATED", {
    sortKey: "last_updated",
    accessFn: (game) => dateFormat(new Date(game.last_updated), "longDate"),
  }),
];

const BlogArticle = ({ toggler, subHeader }) => {
  const { role } = useContext(RoleContext);

  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) => {
      return `${window.domainName}/news/api/news/blog-posts/list?page=${
        index + 1
      }`;
    },
    fetcher,
    { revalidateOnFocus: false }
  );

  const articles = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];
  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  const search = async (query) => {
    const res = await authRequest({
      url: `/news/api/news/blog-posts/cms/search`,
      params: {
        search_term: query,
      },
    });

    if (res.error) return [];
    return res;
  };
  const searchCallback = (data) => {
    mutate(data, false);
  };

  return (
    <div className="body">
      <MainHeader
        toggler={toggler}
        uploadLink={window.location.pathname + "/upload-article"}
        header={subHeader}
        search={{
          func: search,
          callback: searchCallback,
        }}
      />
      <div className="content">
        <Table
          data={articles}
          columns={columns}
          columnCSSConfig="1fr 2fr 1fr 1fr 1fr"
          getOptions={(article) => blogOptions(role, article)}
          getPopupData={(article) => ({
            edit: { id: article.id },
            view: { id: article.id },
            publish: {
              id: article.id,
              published: article.published,
              title: article.title,
            },
            delete: {
              title: article.title,
              description: article.short_description,
              creator: article.creator,
              created: article.date_posted,
              last_updated: article.last_updated,
              category: "blog-posts", //this will be in the URL. DO NOT change
              id: article.id,
            },
          })}
          indicators={[
            {
              color: "#00b4f2",
              when: (article) => article.published && article.approved,
              name: "Article is Published and Approved",
            },
          ]}
          refreshData={() => mutate(articles, true)}
          onSort={(key, order) => mutate(sort(key, articles, order), false)}
        />
        {errors && <div className="error">{errors.error}</div>}
        {error && <div className="error">{error.message}</div>}
        <LoadMore onClick={() => setSize((prev) => prev + 1)} />
      </div>
    </div>
  );
};

export default BlogArticle;
