import { Link } from "react-router-dom";
import { generateURL } from "../resizeImages";

const TeamMember = ({ name, logo, members_count }) => (
  <div className="member">
    <div className="logo">
      <img
        loading="lazy"
        src={generateURL(100, 100, logo) || "/images/developer-default.svg"}
        alt={name}
      />
    </div>
    <div className="details">
      <div className="data">
        <div className="item">
          <span className="title">Team name</span>
          <span className="content">{name}</span>
        </div>
        <div className="item">
          <span className="title">Team member</span>
          <span className="content">{members_count}</span>
        </div>
      </div>
      <Link to={`/team/@${name}`}>
        <div className="preview">Preview Details</div>
      </Link>
    </div>
  </div>
);

export default TeamMember;
