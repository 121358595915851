import { toast } from "react-toastify";
import { authRequest } from "../../../utils/Axios";
import useNewTab from "../../Previews/hook";
import { SubmitButton } from "../Common";

export const ViewReason = ({ reason, user }) => {
  return (
    <div className="article-view">
      <center>
        <h2>Reason of {user}</h2>
      </center>
      <br />

      <blockquote>{reason}</blockquote>
    </div>
  );
};
export const ViewComment = ({ comment, user }) => {
  return (
    <div className="article-view">
      <center>
        <h2>Comment of {user}</h2>
      </center>
      <br />

      <blockquote>{comment}</blockquote>
    </div>
  );
};
export const FtpDownload = ({ path, link }) => {
  const newtab = useNewTab();

  return (
    <div className="article-view">
      <center>
        <h2>Download {path}?</h2>
      </center>
      <br />

      <SubmitButton
        label="Download"
        request={() => {
          newtab.open();
          newtab.revalidateURL(link);
        }}
      />
    </div>
  );
};

export const ApproveReport = ({ close, approve, user, id, game }) => {
  const approveReport = async () => {
    try {
      const res = await authRequest({
        url: `/products/api/games/mod-reports/approve`,
        method: "POST",
        data: {
          report_id: id,
          approve,
        },
      });
      if (res.success)
        toast.info(
          `You have ${approve ? "Approved" : "Denied"} Report from ${user}`
        );
    } catch (error) {
      toast.error(error.message);
    } finally {
      close?.();
    }
  };

  return (
    <div className="game-approve">
      <h2>
        {approve ? `Unapprove Game ${game}` : `Dismiss Report of ${user}`}?
      </h2>
      <br />
      <div className="buttons">
        <SubmitButton
          label="Close"
          className={`button green`}
          request={close}
        />
        <SubmitButton
          label={approve ? "Unapprove it!" : "Dismiss"}
          className={`button red`}
          request={approveReport}
        />
      </div>
    </div>
  );
};
export const ApproveComment = ({ close, approve, user, id }) => {
  const approveComment = async () => {
    try {
      const res = await authRequest({
        url: `/products/api/comments/unapproved-comments/approve`,
        method: "POST",
        data: {
          comment_id: id,
          approve,
        },
      });
      if (res.success)
        toast.info(
          `You have ${approve ? "Approved" : "Denied"} Comment of ${user}`
        );
    } catch (error) {
      toast.error(error.message);
    } finally {
      close?.();
    }
  };

  return (
    <div className="game-approve">
      <h2>
        {approve ? "Approve" : "Deny"} Comment of {user}?
      </h2>
      <br />
      <div className="buttons">
        <SubmitButton
          label="Close"
          className={`button ${!approve ? "green" : "red"}`}
          request={close}
        />
        <SubmitButton
          label={approve ? "Approve it!" : "Deny"}
          className={`button ${approve ? "green" : "red"}`}
          request={approveComment}
        />
      </div>
    </div>
  );
};
