import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCredentails, loggedInValidation } from "src/components/Validation";
import { Avatar } from "src/components/common/Avatar";
import { generateURL } from "src/components/resizeImages";
import { authRequest } from "src/utils/Axios";
import { getClass } from "src/utils/getClass";

const VideoSearchSidebar = ({ showSidebar, closeSidebar }) => {
  const { username } = getCredentails();

  const [followedTeams, setFollowedTeams] = useState([]);
  const [signin, setSignin] = useState(false);

  useEffect(() => {
    if (loggedInValidation()) {
      getDevelopers();
    } else {
      setSignin(true);
    }
  }, []);

  const getDevelopers = async () => {
    try {
      const res = await authRequest({
        url: `/api/members/${username}/followed-teams`,
      });
      setFollowedTeams(res);
    } catch {}
  };

  return (
    <aside
      className="VideoPageSidebar"
      data-show={showSidebar}
      aria-hidden={!showSidebar}
    >
      <button className="close" onClick={closeSidebar}>
        <i className="fa fa-close"></i>
      </button>
      <section>
        <nav aria-label="videopage navigation">
          <ul>
            <li>
              <LinkWithIcon path="/videos/1" icon="fa-home" text="Home" />
            </li>
            <li>
              <LinkWithIcon
                path="/cms/content/videos/upload"
                icon="fa-upload"
                text="Upload Video"
              />
            </li>
            <li>
              <LinkWithIcon
                path="/library/watch-later"
                icon={"fa-clock-o"}
                text="Watch Later"
              />
            </li>
          </ul>
        </nav>
      </section>
      <hr />
      <section className="Subscriptions">
        <h3>Subscriptions</h3>
        {signin ? (
          <Link to="/login/videos">
            <div className="signin">
              <div className="fa fa-user-circle fa-lg"></div>
              <p>
                <u>Login to see your subscriptions</u>
              </p>
            </div>
          </Link>
        ) : (
          <div className="FullPage">
            <ul>
              {followedTeams.map((team) => (
                <li key={team.id}>
                  <Team team={team} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </section>
    </aside>
  );
};

const LinkWithIcon = ({ icon, text, path }) => {
  return (
    <div className="LinkWithIcon">
      <Link to={path}>
        <i className={getClass("fa", icon)}></i>
        <span>{text}</span>
      </Link>
    </div>
  );
};

const Team = ({ team }) => {
  return (
    <div className="creator">
      <Avatar
        name={team.name}
        width={36}
        src={generateURL(72, 72, team.profile_picture)}
      />

      <div>
        <Link to={`/team/@${team.name}/videos`}>
          <span className="name">
            {team.name}
            {team.approved && (
              <span
                aria-hidden="true"
                className="material-icons"
                title="Verified Team"
              >
                verified
              </span>
            )}
          </span>
        </Link>
      </div>
      <div className="dot" data-new-videos={team.new_videos}></div>
    </div>
  );
};

export default VideoSearchSidebar;
