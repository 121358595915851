import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Chip } from "src/components/Chip";
import { Roles, useRoleInfo, useTeamID } from "src/components/CMS/RoleContext";
import Input from "src/v2/components/form-inputs/Input";
import Form from "src/v2/components/form/Form";
import { addProductsToBundles } from "src/v2/services/gameBundlesService";
import { getTeamStoreProducts } from "src/v2/services/storeService";
import useSWR from "swr";
import { z } from "zod";
import { AddSaleProducts } from "../AddNewSale/AddNewSaleProduct";
import SubmitButton from "src/v2/components/SubmitButton";

const productSchema = z.array(
  z.object({
    product_type: z.string(),
    product_id: z.number(),
    rate: z.union([z.string(), z.number()]).transform((s) => parseFloat(s)),
  })
);

export const BundleProducts = ({ bundleId, defaultValues = {} }) => {
  const teamId = useTeamID();
  const [query, setQuery] = useState("");

  const { role } = useRoleInfo();

  const { data: allProducts } = useSWR("team-products", {
    fetcher: () => getTeamStoreProducts(teamId),
    revalidateOnFocus: false,
  });

  const form = useForm({
    resolver: zodResolver(z.object({ products: productSchema })),
    defaultValues: { products: defaultValues.products },
  });

  const updateQuery = (e) => setQuery(e.target.value);

  const saveProducts = async () => {
    try {
      const valid = await form.trigger();
      if (!valid) throw new Error("Invalid Products");

      const data = form.getValues();

      await addProductsToBundles({
        bundleId,
        products: productSchema.parse(data.products),
      });
      toast.success("Products Updated");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center my-4">
        <h2 className="text-xl">Add Products</h2>
      </div>

      <div className="relative mb-3">
        <Input
          className="pr-16"
          type="search"
          onChange={updateQuery}
          placeholder="Search..."
          value={query}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              // searchButton.current?.click();
            }
          }}
        />
        <button
          className="absolute left-auto px-5 right-0 my-auto inset-0"
          // ref={searchButton}
          // onClick={onUpdate}
        >
          <span className="fa fa-search"></span>
        </button>
      </div>

      {role === Roles.ADMIN && (
        <div className="border-y border-neutral-700 py-5 max-h-48 overflow-y-auto">
          <p>Included Products: </p>
          <ul className="list-disc ml-5">
            {defaultValues.products?.map((p) => (
              <li key={p.product_id}>
                {p.product_type} {p.product_name} <Chip>{p.rate}% off</Chip>
              </li>
            ))}
          </ul>
        </div>
      )}
      <Form form={form} className="grid">
        <AddSaleProducts products={allProducts} />

        <SubmitButton onClick={saveProducts} className="place-self-end">
          Save Products
        </SubmitButton>
      </Form>
    </div>
  );
};
