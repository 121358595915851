import dateFormat from "dateformat";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { numberLongFormat, toEuro } from "src/utils/number-format";
import useSWRInfinite from "swr/infinite";
import { CONVERSION_RATE } from "../../../constants/store";
import RequestError from "../../../errors/RequestError";
import { CurrencyDisclaimer } from "../../../providers/CartProvider";
import { authRequest } from "../../../utils/Axios";
import { Loader, LoadMore, sort } from "../../Common";
import Error404 from "../../Errors";
import { LocalPrice } from "../../LocalPrice";
import ChangePaymentDetails from "../../Payments/ChangeDetails";
import { fetcher } from "../Cms";
import { SubmitButton } from "../Common";
import "../css/Withdraw.css";
import { InnerHeader } from "../Headers";
import { useTeamID } from "../RoleContext";
import { SelectTeam } from "../SelectTeam";
import { Table } from "../Table";
import { getColumn } from "../Table/Table";
import { convertToSpicyTokens } from "src/utils/currency-format";

export const toSpicyTokens = (spicyTokenAmount) =>
  `${numberLongFormat(Math.round(spicyTokenAmount))} SG`;

export const toSpicyTokensWithEuro = (spicyTokenAmount) =>
  `${Math.floor(spicyTokenAmount)} SG (${spicyTokensToEuro(spicyTokenAmount)})`;

export const spicyTokensToEuro = (spicyTokenAmount) =>
  toEuro(spicyTokenAmount / CONVERSION_RATE);

const columns = [
  getColumn("AMOUNT", {
    sortKey: "amount",
    accessFn: (payout) => <div>{toSpicyTokensWithEuro(payout.amount)}</div>,
  }),
  getColumn("PAYMENT METHOD", {
    sortKey: "payment_method_used",
    accessKey: "payment_method_used",
  }),
  getColumn("REQUESTED DATE", {
    sortKey: "request_date",
    accessFn: (payout) => dateFormat(new Date(payout.request_date), "longDate"),
  }),
  getColumn("PAID", {
    sortKey: "paid",
    accessFn: (payout) =>
      payout.paid ? (
        <div title="Paid" className="fa fa-check-circle text-green-500"></div>
      ) : (
        <div
          title="Payout Pending"
          className="fa fa-warning text-yellow-500"
        ></div>
      ),
  }),
];

const WithdrawPage = () => {
  const id = useTeamID();

  const history = useHistory();
  const [error, setError] = useState(null);
  const [earnings, setEarnings] = useState([]);
  const [changeDetails, setChangeDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data, setSize, mutate } = useSWRInfinite(
    (index) => [
      `${window.domainName}/store/api/payout-requests/list?page=${
        index + 1
      }&team_id=${id}`,
      id,
    ],
    fetcher,
    { revalidateOnFocus: false }
  );
  const payouts = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];

  useEffect(() => {
    getInfo();
  }, [id]);

  const getInfo = async () => {
    try {
      setLoading(true);
      const withdrawInfo = await authRequest({
        url: `/store/api/payout-requests/withdraw/info`,
        params: {
          team_id: id,
        },
      });

      if (!withdrawInfo.error)
        setEarnings([
          {
            label: "Total Amount",
            tokens: toSpicyTokens(
              convertToSpicyTokens(withdrawInfo.total_amount)
            ),
            value: <LocalPrice amountInEUR={withdrawInfo.total_amount} />,
          },
          {
            label: "Amount available to withdraw",
            tokens: toSpicyTokens(
              convertToSpicyTokens(withdrawInfo.available_amount)
            ),
            value: <LocalPrice amountInEUR={withdrawInfo.available_amount} />,
          },
          { label: "Payout Method", value: withdrawInfo.payment_method },
        ]);
    } catch (error) {
      setError(404);
    } finally {
      setLoading(false);
    }
  };

  const requestPayout = async () => {
    try {
      const res = await authRequest({
        url: `/store/api/payout-requests/create`,
        method: "POST",
        data: {
          team_id: id,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.success) setError("Payment Request successfully sent");
    } catch (error) {
      setError(error.message);
    }
  };

  const toggleChangeDetailsPopup = () => setChangeDetails((prev) => !prev);
  const redirectToEdit = () =>
    history.push(`/cms/personal/teams/edit/${id}/payout-info`);

  if (loading)
    return (
      <div className="body">
        <Loader />
      </div>
    );

  if (error === 404)
    return (
      <div className="body">
        <Error404 />
      </div>
    );

  return (
    <div className="body withdraw-page">
      <InnerHeader
        header={"Team"}
        subHeader="Withdraw Tokens"
        element="back-button"
      />
      <SelectTeam />

      <div className="content">
        <section id="header">
          <h1>Withdraw Tokens</h1>
          <div className="buttons">
            <SubmitButton label="Request Payout" request={requestPayout} />
            <SubmitButton
              request={redirectToEdit}
              label="Change Payout Details"
            />
          </div>
          {changeDetails && (
            <ChangePaymentDetails
              teamID={id}
              onClose={toggleChangeDetailsPopup}
            />
          )}
        </section>
        <RequestError error={error} />
        <p className="conversion-rate">
          {spicyTokensToEuro(CONVERSION_RATE)} = {CONVERSION_RATE} Spicy Tokens
          <CurrencyDisclaimer showLink={false} />
        </p>
        <section id="earnings">
          {earnings.map((earn, i) => (
            <Earn key={i} {...earn} />
          ))}
        </section>

        <section aria-labelledby="payout-title">
          <h2 id="payout-title">Payouts</h2>
          <div className="content">
            <Table
              onSort={(key, order) => mutate(sort(key, payouts, order), false)}
              refreshData={() => mutate(payouts, true)}
              columns={columns}
              data={payouts}
            />

            <LoadMore onClick={() => setSize((prev) => prev + 1)} />
          </div>
        </section>
      </div>
    </div>
  );
};
export const Earn = ({ label, value, tokens }) => {
  return (
    <div className="earn">
      <p>{label}</p>
      <h2>{value}</h2>
      <p>{tokens}</p>
    </div>
  );
};

export default WithdrawPage;
