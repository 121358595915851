import useDocs from "../../hooks/useDocs";
import Header from "../Header";
import { MultiLang } from "../MultiLang";
import "./css/Contact.css";

function ContactUs() {
  const response = useDocs("contact_us");
  return (
    <main id="about">
      <div className="inner">
        <br />
        <header>
          <h1>
            <MultiLang sl="Kontakt">Contact Us on Spicygaming.net</MultiLang>
          </h1>
        </header>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: response.data }}
        ></div>
        <br />
        <Header searchBar={false} />
      </div>
    </main>
  );
}

export default ContactUs;
