import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Trumbowyg from "react-trumbowyg";
import Editor from "src/components/CMS/Articles/Editor";
import {
  apiObjects,
  chooseApiObjects,
} from "src/components/CMS/Articles/EditorOptions";
import { AddTagsV2, SubmitButton } from "src/components/CMS/Common";
import { DragDropFile } from "src/components/FileUpload/DragDropFile";
import { FileTypes } from "src/components/FileUploadV3";
import TusUpload from "src/components/FileUploadV4";
import { Input, URL_REGEX } from "src/components/Member/General";
import { inputIteratorValidate } from "src/components/Validation";
import VideoPlayer from "src/components/VideoPlayer";
import { vastPullzones } from "src/components/VideoPlayer/pullzones";
import { generateURL } from "src/components/resizeImages";
import { authRequest, isDev, request } from "src/utils/Axios";
import { toFormData } from "src/utils/toFormData";
import { ASPECT_RATIO } from "../Player/Player";
import "./style.css";
import { LoaderInside } from "src/components/Common";
import RequestError from "src/errors/RequestError";

const DEFAULT_INFO = {
  title: "",
  description: "",
  tags: [],
  mentioned_games: [],
};

const LIBRARY_ID = isDev ? 24067 : 85736;

const VideoUpload = ({
  editMode,
  onSuccess,
  defaultSuggestions = DEFAULT_INFO,
  details = {},
}) => {
  const [error, setError] = useState(null);
  const history = useHistory();
  const [info, setInfo] = useState(() => {
    if (editMode)
      return {
        video_id: details.video_id,
        bunny_id: details.file?.bunny_id,
        title: details.title,
        description: details.description,
      };
    return defaultSuggestions; // this will be provide from frontend manually for nice ux (title, description, tags)
  });
  const [persistentInfo, setPersistentInfo] = useState(() => {
    if (editMode)
      return {
        thumbnail: details.thumbnail,
        tags: details.tags?.map((tag) => ({ name: tag, param: tag })),
        videoData: details.file,
      };
    return {
      tags: defaultSuggestions.tags?.map((tag) => ({ name: tag, param: tag })),
    };
  });

  const updateInfo = (info) => setInfo((prev) => ({ ...prev, ...info }));
  const updatePersistentInfo = (info) =>
    setPersistentInfo((prev) => ({ ...prev, ...info }));

  const save = async () => {
    try {
      setError(null);
      const noErrors = inputIteratorValidate(info, "Empty Input fields", [
        "mentioned_games",
      ]);
      if (noErrors !== true) throw new Error(noErrors);
      const res = await toast.promise(
        authRequest({
          url: "/downloads/api/tus-uploads/connect",
          method: editMode ? "PUT" : "POST",
          data: info,
        }),
        {
          pending: editMode ? "Saving Changes..." : "Creating Video...",
          success: editMode ? "Successfully edited" : "Successfully Created",
        }
      );
      if (res.success) {
        if (onSuccess) onSuccess(res.video_id);
        else history.push(`/cms/content/videos`);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="Upload" data-testid="Upload">
      <AddVideo
        info={info}
        editMode={editMode}
        persistentInfo={persistentInfo}
        save={save}
        updateInfo={updateInfo}
        updatePersistentInfo={updatePersistentInfo}
      />
      <Info
        info={info}
        editMode={editMode}
        persistentInfo={persistentInfo}
        save={save}
        updateInfo={updateInfo}
        updatePersistentInfo={updatePersistentInfo}
      />
      <MentionedPlaces
        updateInfo={updateInfo}
        alreadyMentioned={details.games}
        editMode={editMode}
      />
      <RequestError error={error} />
      <div className="file-upload">
        <SubmitButton
          label={editMode ? "Save Changes" : "Create Video"}
          request={save}
          disabled={!info.bunny_id}
        />
      </div>
    </div>
  );
};

const AddVideo = ({
  updateInfo,
  updatePersistentInfo,
  info,
  persistentInfo,
  editMode,
}) => {
  const MAXIMUM_VIDEO_SIZE = 1024 * 1024 * 2000; // mb

  const getVideoID = ({ bunny_id }) => {
    updateInfo({
      bunny_id,
    });
  };

  const uploadThumbnail = async (files) => {
    try {
      const file = files.item(0);
      if (!info.bunny_id) throw new Error("Upload Video first");
      if (!file) throw new Error("Select a video to upload");
      const res = await toast.promise(
        authRequest({
          url: "/products/api/videos/thumbnail/change",
          method: "POST",
          data: toFormData({
            thumbnail: file,
            bunny_id: info.bunny_id,
          }),
        }),
        { pending: "Uploading", success: "Successfully Uploaded" }
      );
      updatePersistentInfo({ thumbnail: res.thumbnail });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getAuthHeaders = async (file) => {
    const res = await authRequest({
      url: "/downloads/api/tus-uploads",
      method: "POST",
      data: { filename: file.name },
    });

    getVideoID({ bunny_id: res.bunny_id });

    return {
      AuthorizationSignature: res.auth_sign, // SHA256 signature
      AuthorizationExpire: res.expiration, // Expiration time as in the signature,
      VideoId: res.bunny_id, // The guid of a previously created video object through the Create Video API call
      LibraryId: LIBRARY_ID,
    };
  };

  const removeVideo = () => {
    updateInfo({ bunny_id: undefined });
  };
  const removeThumbnail = () => {
    updatePersistentInfo({ thumbnail: undefined });
  };

  return (
    <div className="file-upload">
      <section>
        <legend>Video</legend>
        {editMode && info.bunny_id ? (
          <div className="player">
            <SubmitButton
              className="close"
              icon="fa-close"
              request={removeVideo}
            />
            <VideoPlayer
              options={{ aspectRatio: "16:9" }}
              vastZone={vastPullzones.default}
              videoID={persistentInfo.videoData?.bunny_id}
              folderID={persistentInfo.videoData?.library_id}
              pullzoneID={persistentInfo.videoData?.pullzone}
              thumbnailName={generateURL(
                720,
                parseInt(720 / ASPECT_RATIO),
                persistentInfo.thumbnail
              )}
            />
          </div>
        ) : (
          <TusUpload
            endpoint="https://video.bunnycdn.com/tusupload"
            getAuthHeaders={getAuthHeaders}
            type={FileTypes.VIDEO}
            maxFileSize={MAXIMUM_VIDEO_SIZE}
            acceptedFileTypes={["video/*"]}
            onError={(error) => toast.error(error)}
            getMetadata={(file) => ({
              title: file.name,
            })}
          />
        )}
        <div className="instructions" style={{ color: "#c2c2c2" }}>
          Add a video, upload it using the upload tool, you can also add a
          thumbnail to the video
        </div>
        <div
          className="thumbnail"
          data-tooltip={
            !info.bunny_id && !persistentInfo.thumbnail
              ? "Wait for the video to be uploaded"
              : undefined
          }
        >
          <label htmlFor="video-thumbnail">
            <h2>{editMode ? "Edit" : "Upload"} Your thumbnail:</h2>
          </label>
          {persistentInfo.thumbnail ? (
            <div className="image">
              <img
                src={generateURL(300, 170, persistentInfo.thumbnail)}
                alt="Video thumbnail"
                width={300}
                height={170}
              />

              <SubmitButton
                className="close"
                icon="fa-close"
                request={removeThumbnail}
              />
            </div>
          ) : (
            <DragDropFile
              uploadTypeName="thumbnail"
              onFilesUpload={uploadThumbnail}
              acceptedFileTypes={["image/*"]}
              disabled={!info.bunny_id}
            />
          )}
        </div>
      </section>
    </div>
  );
};

const Info = ({ updateInfo, info, persistentInfo, updatePersistentInfo }) => {
  const initialDescription = useRef(info.description ?? "");
  const [tags, setTags] = useState([]);
  useEffect(() => {
    getTags();
  }, []);

  const getTags = async () => {
    try {
      const res = await request({
        url: "/products/api/game-filters",
        params: { title: "video tags" },
      });

      setTags(res.option);
    } catch (error) {}
  };

  if (!tags.length) return <LoaderInside />;
  return (
    <section>
      <legend>Video details</legend>
      <div className="inputs">
        <Input
          label="Title"
          value={info.title}
          callback={(text) => updateInfo({ title: text })}
        />
        {/* <Input
          label="Description"
          callback={(text) => updateInfo({ description: text })}
          value={info.description}
          required
        /> */}
        <AddTagsV2
          label="Tags"
          onChange={(tags) => {
            updateInfo({ tags: tags.map((t) => t.param) });
            updatePersistentInfo({ tags });
          }}
          items={tags}
          instruction="You can add minimum 1 tags and maximum 20"
          selectedItems={persistentInfo.tags}
        />
      </div>
      <h3>Description </h3>
      <Trumbowyg
        buttons={[["bold", "italic", "underline", "strikethrough"], ["link"]]}
        data={initialDescription.current}
        placeholder="This video is about this awesome game. See me play it"
        tagsToRemove={["script"]}
        defaultLinkTarget={"_blank"}
        removeformatPasted
        semantic
        urlProtocol
        minimalLinks
        onChange={(e) => {
          updateInfo({ description: e.target.innerHTML });
        }}
      />
    </section>
  );
};

const MentionedPlaces = ({ updateInfo, editMode, alreadyMentioned }) => {
  const [objects, setObjects] = useState(() => {
    if (!editMode || alreadyMentioned?.length === 0) return [];
    return [
      {
        key: Math.random(),
        type: "Games",
        id: "games",
        content: alreadyMentioned,
      },
    ];
  });

  useEffect(() => {
    updateInfo({
      mentioned_games: objects
        .map((object) => object.content.map((content) => content.id))
        .flat(),
    });
  }, [objects]);

  return (
    <section className="mentioned-places">
      <legend>Mentioned Places</legend>
      <Editor
        objects={objects}
        setObjects={setObjects}
        editorOptions={chooseApiObjects(apiObjects(true), ["games"])}
        customInstructions={<CustomInstructions />}
      />
    </section>
  );
};

const CustomInstructions = () => {
  return (
    <div>
      <p>
        Add the things that's mentioned in this video. Ex: Games this video is
        talking about
      </p>
      <p>You can always add things later by editing this video.</p>
    </div>
  );
};
export default VideoUpload;
