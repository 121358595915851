import { forwardRef, useMemo } from "react";
import { MultiLang } from "src/components/MultiLang";
import { Features } from "src/features/allFeatures";
import { isEnabledFeature } from "src/features/enabledFeatures";
import { Downloads } from "../DownloadButton";
import { EmptyView } from "src/components/UtilityComponents/EmptyView";
import { getDownloadNamePriority } from "src/components/CMS/Games/Content/contentData";

const GameDownloads = (
  { game, gameID, creatorApproved, downloads = [] },
  downloadSectionRef
) => {
  const prioritizedDownloads = useMemo(
    () =>
      downloads.sort((a, b) => {
        const aPriority = getDownloadNamePriority(a.name);
        const bPriority = getDownloadNamePriority(b.name);

        return bPriority - aPriority;
      }),
    [downloads]
  );

  return (
    <>
      {game.status === "In Development" && (
        <details className="section special">
          <summary className="sub title">
            <span className="fa fa-warning"></span>{" "}
            <span>Game In Development</span>
          </summary>
          <p>
            This game is in active development and is not yet completed. Game
            state may or may not change further. By buying the game you are
            supporting the development and are entitled to all future updates
            when they become available on SpicyGaming.
          </p>
        </details>
      )}
      <section className="download-links-wrapper" ref={downloadSectionRef}>
        <div className="download-links section" id="download-links">
          <legend>
            <h2 className="sub title">
              <MultiLang sl="povezave za prenos">
                download {game.title} content
              </MultiLang>
            </h2>
          </legend>

          <div className="content">
            {!!prioritizedDownloads.length ? (
              prioritizedDownloads
                .filter((d) => {
                  // if token feature unavailable, only free downloads will be shown
                  if (!isEnabledFeature(Features.TOKEN_TRANSACTION))
                    return d.price === 0;
                  return true;
                })

                .map((download) => {
                  return (
                    <Downloads
                      // now users can never directly download any game
                      // they must login
                      allowDownload={false}
                      key={download.id}
                      disabled={!creatorApproved && download.price > 0}
                      gameID={gameID}
                      download={download}
                      creator={{
                        name: game.creator,
                        id: game.creator_id,
                        trusted: creatorApproved,
                      }}
                    />
                  );
                })
            ) : (
              <EmptyView text={"No Downloads"} />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default forwardRef(GameDownloads);
