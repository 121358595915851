import React, { useMemo } from "react";
import { useToggle } from "src/hooks/useToggle";
import { getClass } from "src/utils/getClass";
import { useMobile } from "src/components/Context";
import { TableRowActions } from "./TableRowActions";
import { NotAvailableNode } from "./Table";

export function TableRow({
  columns,
  record,
  getOptions,
  getPopupData,
  indicators,
  refreshData,
}) {
  const isMobile = useMobile();
  const [showFull, toggleShowFull] = useToggle();

  const currentIndicatorColor = useMemo(() => {
    return indicators.find((indicator) => indicator.when?.(record))?.color;
  }, [record]);

  const options = getOptions?.(record);

  return (
    <div
      className={"table-row"}
      data-show-full={showFull}
      style={{ borderColor: currentIndicatorColor }}
    >
      <div className="toggler" onClick={toggleShowFull}>
        <i
          className={getClass(
            "fa",
            showFull ? "fa-chevron-down" : "fa-chevron-up"
          )}
        ></i>
      </div>

      {!!options?.length && (
        <TableRowActions
          options={options}
          popupData={getPopupData?.(record)}
          refreshData={refreshData}
        />
      )}
      {columns.map((column, index) => {
        // all content hidden if not full view in mobile
        if (!showFull && index !== 0 && isMobile) return null;

        if (!column) return <NotAvailableNode key={index} />;

        const data = column.accessKey
          ? record?.[column.accessKey]
          : column.accessFn?.(record);

        if (!data) return <NotAvailableNode key={index} />;

        return (
          <div className="cell" key={index}>
            {showFull && <div className="title">{column.title}</div>}
            <div>{data}</div>
          </div>
        );
      })}
    </div>
  );
}
