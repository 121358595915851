import { useCallback, useEffect, useRef } from "react";

function useDebouncedCallback(callback, delay, dependencies) {
  const debouncedCallback = useRef(callback);
  const timerId = useRef(null);

  useEffect(() => {
    debouncedCallback.current = callback;
  }, [callback]);

  return useCallback((...args) => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    timerId.current = setTimeout(() => {
      debouncedCallback.current(...args);
    }, delay);
  }, dependencies);
}

export default useDebouncedCallback;
