import { useState, useEffect, useMemo } from "react";

/**
 * Custom hook that wraps the Intersection Observer API and allows you to easily track when an element comes into view
 * @param {Object} ref - the ref of the element that needs to be tracked
 * @param {IntersectionObserverInit} options - options to be passed to the IntersectionObserver constructor
 * @return {IntersectionObserverEntry | null} - returns the IntersectionObserverEntry object if the element is in view, otherwise returns null
 */
function useIntersection(ref, options) {
  const [intersectionObserverEntry, setIntersectionObserverEntry] =
    useState(null);

  const immutableOptions = useMemo(() => options, []);

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(([entry]) => {
        setIntersectionObserverEntry(entry);
        if (immutableOptions.once && entry.isIntersecting)
          observer.unobserve(entry.target);
      }, immutableOptions);
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [ref, immutableOptions]);

  return intersectionObserverEntry;
}

export default useIntersection;
