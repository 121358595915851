import { sum } from "src/utils/sum";
import {
  getFeesForOrderByAmount,
  getProductPrice,
} from "src/v2/services/storeService";
import useSWR from "swr";
import { SimpleDivider } from "../Atoms/SimpleDivider";
import { toSpicyTokens } from "../CMS/Users/Withdraw";
import { LoaderInside } from "../Common";
import { LocalPrice } from "../LocalPrice";
import { convertToSpicyTokens } from "src/utils/currency-format";

export default function SelectedPackageDetails({
  selectedPkg,
  productId,
  productType,
}) {
  const { data: latestPriceEur } = useSWR(
    ["get-product-price", productId, productType],
    () => getProductPrice(productId, productType, selectedPkg.eur)
  );

  const { data: fees, isValidating } = useSWR(
    ["get-fees", latestPriceEur],
    () => getFeesForOrderByAmount(latestPriceEur)
  );

  const isPricingChanged = !isValidating && latestPriceEur !== selectedPkg.eur;

  const data = [
    { label: "Product Name", data: selectedPkg.title },
    {
      label: "Spicy Token Amount",
      data: toSpicyTokens(convertToSpicyTokens(latestPriceEur)),
    },
    {
      label: "Price",
      data: <LocalPrice amountInEUR={latestPriceEur} />,
    },
    {
      label: "Transaction Fees",
      data: <LocalPrice amountInEUR={fees?.transaction_fee} />,
    },
    {
      label: "VAT",
      data: <LocalPrice amountInEUR={fees?.vat_amount} />,
    },
  ];

  const total = sum([fees?.transaction_fee, fees?.vat_amount, latestPriceEur]);
  return (
    <div className="SelectedPackageDetails">
      <h2>Checkout</h2>
      {isPricingChanged && (
        <div className="bg-yellow-950 border border-yellow-700 rounded-md p-3 my-4">
          <h4 className="text-yellow-300">Warning!</h4>
          <span className="text-yellow-400 text-sm">
            Pricing for one of the products have changed. Please review the new
            pricing and confirm your purchase.
          </span>
        </div>
      )}
      <div className="details">
        {data.map((item, id) => (
          <div className="item" key={id}>
            <span>{item.label}:</span>
            <span>{item.data}</span>
          </div>
        ))}

        <SimpleDivider margin={0} color={"#c2c2c2"} />
        <div className="item total">
          <span>Total :</span>
          <span>
            {isValidating ? (
              <LoaderInside className="inline !min-h-0 !w-7" />
            ) : (
              <LocalPrice amountInEUR={total} />
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
