import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import SubmitButton from "src/v2/components/SubmitButton";

const LinkExchange = () => {
  return (
    <main>
      <Helmet>
        <title>Partners | Spicygaming </title>
      </Helmet>
      <div className="inner grid gap-10 pt-10 !max-w-screen-lg">
        <h1 className="text-center">SPICY PARTNERS</h1>
        <p>
          Discover a handpicked selection of some of the most valuable websites
          in the adult industry, each offering unique content and products that
          set them apart. From creative AI adult platforms to great adult
          products, our Spicy Partners represent the best in the business. Dive
          in, explore their content and products, and find out why these sites
          are a must-visit for those seeking quality and innovation in the adult
          entertainment world.
        </p>

        {sections.map((section, i) => (
          <div key={i}>
            <h2 className="text-2xl mb-5">{section.name}</h2>

            <div className="grid gap-4">
              {section.links.map((link, j) => (
                <div
                  key={j}
                  className="flex flex-col md:flex-row items-stretch md:items-center gap-5 bg-neutral-900 py-5 px-7 rounded-lg"
                >
                  <div className="w-full">
                    <a href={link.link} className="contents">
                      <h3 className="text-xl mb-2">{link.name}</h3>
                    </a>
                    <p className="text-neutral-300 text-sm">
                      {link.description}
                    </p>
                  </div>
                  <a href={link.link} className="contents">
                    <SubmitButton className="w-full md:w-auto" icon="fa-globe">
                      Visit
                    </SubmitButton>
                  </a>
                </div>
              ))}
            </div>
          </div>
        ))}

        <div className="space-y-5">
          <h2 className="text-2xl">Want to Partner Up?</h2>
          <p>
            If you have a product, platform, or service that you believe would
            be a great addition to our Spicy Partners list, we&apos;d love to
            hear from you! We&apos;re always on the lookout for fresh and
            exciting ventures in the adult industry that bring value to our
            audience.
          </p>
          <Link to="/contact" className="contents">
            <SubmitButton type="secondary">Contact Us</SubmitButton>
          </Link>
        </div>
      </div>
    </main>
  );
};

function createSection(name, links = []) {
  return { name, links };
}
function createLink(name, description, link) {
  return { name, description, link };
}

const sections = [
  createSection("Best AI porn sites", [
    createLink(
      "Virtual Girlfriend",
      "Experience a lifelike virtual girlfriend powered by advanced AI technology, offering personalized interactions, deep conversations, and immersive companionship.",
      "https://www.luvy.ai"
    ),
  ]),
];

export default LinkExchange;
