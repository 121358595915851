export const saveGotoRoute = (routePath) => {
  if (!routePath) return;
  localStorage.setItem("@goto", routePath);
};

export const getGotoRoute = (defaultRoute = "/") => {
  const goto = localStorage.getItem("@goto");

  localStorage.removeItem("@goto"); // removed, so only one time use

  if (goto) return goto;

  return defaultRoute;
};
