import React, { Suspense, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Price } from "src/components/BuyTokens/Price";
import { convertToSpicyTokens } from "src/utils/currency-format";
import RequestError from "../../../../errors/RequestError";
import { authRequest } from "../../../../utils/Axios";
import { getCountries } from "../../../../utils/fetchCountries";
import { Loader, LoaderInside } from "../../../Common";
import Popup from "../../../common/Popup";
import Finder from "../../../Finder";
import { Input } from "../../../Member/General";
import ChangePaymentDetails from "../../../Payments/ChangeDetails";
import useNewTab from "../../../Previews/hook";
import { inputIteratorValidate } from "../../../Validation";
import { SubmitButton } from "../../Common";
import { Roles, useRoleInfo } from "../../RoleContext";
import "./css/PaymentInfo.css";
import {
  paymentCompanyInfo,
  paymentMethods,
  paymentPersonalInfo,
  radios,
} from "./data";
import TransactionFees from "./TransactionFees";

const SignContract = React.lazy(() => import("./SignContract"));

const PaymentInfo = React.forwardRef(({ teamID, leaderEmail }, ref) => {
  const url = `/store/api/teams/payment-info/edit`;

  const { role } = useRoleInfo();
  const [loading, setLoading] = useState(true);
  const [changeDetails, setChangeDetails] = useState(false);
  const [data, setData] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({});
  const [paymentMethod, setPaymentMethod] = useState("");
  const [error, setError] = useState(null);
  const [locked, setLocked] = useState(false);
  const [showSignPopup, setShowSignPopup] = useState(false);
  const [countries, setCountries] = useState([]);
  const [signed, setSigned] = useState();
  const newTab = useNewTab();

  useEffect(() => {
    setupCountries();
    getInfo();
  }, []);

  const update = (key) => (value) =>
    setData((prev) => ({ ...prev, [key]: value }));

  const toggleChangeDetailsPopup = () => setChangeDetails((prev) => !prev);
  const toggleSignPopup = () => setShowSignPopup((prev) => !prev);

  const updatePaymentInfo = (key) => (value) =>
    setPaymentInfo((prev) => ({
      ...prev,
      [paymentMethod]: { ...prev[paymentMethod], [key]: value },
    }));

  const activeMethod = useMemo(
    () => paymentMethods.find((method) => method.id === paymentMethod),
    [paymentMethod]
  );

  const updateMethod = (e) => setPaymentMethod(e.target.value);

  const getInfo = async () => {
    try {
      const res = await authRequest({ url, params: { team_id: teamID } });

      setLocked(!res.info?.editable);
      setData(res.info);
      setPaymentMethod(res.payment_method?.selected_method);
      setPaymentInfo(res.payment_method);
      setSigned({ signed: res.signed, signedDoc: res.signed_contract });

      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const showAgreement = async () => {
    newTab.open();
    const res = await authRequest({
      url: "/store/api/contracts/download",
      params: { team_id: teamID },
      responseType: "blob",
    });

    const url = URL.createObjectURL(res);
    newTab.revalidateURL(url);

    window.URL.revokeObjectURL(url);
  };

  const onContractSignup = () => {
    toggleSignPopup();
    setSigned((prev) => ({ ...prev, signed: true }));
  };

  const setupCountries = async () => {
    try {
      setCountries(await getCountries());
    } catch {}
  };

  const submit = async () => {
    try {
      setError(null);

      const errors = [
        inputIteratorValidate(
          paymentInfo?.[paymentMethod],
          "Required fields are empty",
          [
            "selected_method",
            paymentMethods
              .find((item) => item.id === paymentMethod)
              ?.inputs.filter((inp) => !inp.required)
              .map((inp) => inp.id),
            radios[paymentMethod]?.some(
              (key) => !!paymentInfo?.[paymentMethod]?.[key]
            )
              ? radios[paymentMethod]?.filter(
                  (key) => !paymentInfo?.[paymentMethod]?.[key]
                )
              : [],
          ].flat()
        ),
        inputIteratorValidate(
          data,
          "Required fields are empty",
          [
            ...[paymentCompanyInfo, paymentPersonalInfo]
              .flat()
              .filter((input) => !input.required)
              .map((input) => input.id),
            "editable",
            "spicy_fee",
            "foreignkey_conns",
            "archived",
          ] // making these optional
        ),
      ];
      if (!errors.every((error) => error === true))
        throw new Error(
          errors.filter((err) => typeof err === "string").join("\n")
        );
      if (!paymentMethod?.length) throw new Error("No method selected");

      const res = await authRequest({
        url,
        method: "POST",
        data: {
          ...data,
          payment_method: { ...paymentInfo, selected_method: paymentMethod },
          team_id: teamID,
        },
      });
      if (res.success) {
        setLocked(!res.editable);
        toggleSignPopup();
        return setError("Successfully Updated.");
      }
    } catch (error) {
      if (error.message === "LOCKED_PROFILE") {
        toggleChangeDetailsPopup();
        setError("Your Payment Info is locked. Please request an edit.");
      }
      setError(error.message ?? "Error Occurred. Request didn't send");
    }
  };

  const resetAgreement = async () => {
    if (role !== Roles.ADMIN) return;
    try {
      setLoading(true);
      const res = await authRequest({
        url: "/store/api/contracts/delete-team-contract",
        method: "DELETE",
        data: {
          team_id: teamID,
        },
      });

      if (res.success) {
        await getInfo();
        toast.success("Reset Successful. Make changes you need and Save.");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const finderCommon = {
    data: countries,
    className: "input",
    label: "Country",
    disabled: locked,
    required: true,
    update: (value) => {
      const newValue = value.toLowerCase();
      return countries.filter(
        (c) =>
          c.id?.toLowerCase()?.includes(newValue) ||
          c.name?.toLowerCase()?.includes(newValue)
      );
    },
  };

  const checkRadios = (id) => {
    const radioIds = radios[activeMethod?.id];
    if (!radioIds) return false;
    if (!radioIds?.includes(id)) return false;

    const inputs = radioIds?.map((id) => ({
      id,
      value: paymentInfo[activeMethod?.id]?.[id],
    }));

    if (inputs.every((input) => !input.value)) return false;

    const typedInput = inputs.find((input) => !!input.value);

    if (typedInput?.id === id) return false;

    return true; // all expect typed input will be disabled
  };

  if (loading) return <LoaderInside />;

  return (
    <section className="payment-info" id="payment-info" ref={ref}>
      <legend>
        Payout Information
        {locked && (
          <SubmitButton
            className="request-payout-button"
            label="Request for Payout info change"
            request={toggleChangeDetailsPopup}
          />
        )}
      </legend>

      <ChangePaymentDetails
        open={changeDetails}
        teamID={teamID}
        onClose={toggleChangeDetailsPopup}
      />

      <div className="section-content">
        <h2>Personal Information</h2>
        <div className="user-inputs">
          {paymentPersonalInfo.map((input) => (
            <Input
              {...input}
              key={input.id}
              value={data[input.id]}
              disabled={locked}
              callback={update(input.id)}
            />
          ))}
          <Finder
            id="country"
            value={countries.find((c) => c.id === data.country)}
            idExtractor={(elem) => "country" + elem.id}
            onChange={(elem) =>
              setData((prev) => ({ ...prev, country: elem.id }))
            }
            {...finderCommon}
          />
        </div>

        <h2>Company Information</h2>

        <div className="user-inputs">
          {paymentCompanyInfo.map((input) => (
            <Input
              {...input}
              key={input.id}
              value={data[input.id]}
              callback={update(input.id)}
              disabled={locked}
            />
          ))}
          <Finder
            value={countries.find((c) => c.id === data.company_country)}
            idExtractor={(elem) => "company_country" + elem.id}
            id="company_country"
            onChange={(elem) =>
              setData((prev) => ({ ...prev, company_country: elem.id }))
            }
            {...finderCommon}
          />
        </div>

        <TransactionFees />

        <h2>Payout method</h2>

        <div>
          <label htmlFor="payment_method">Selected Method: </label>
          <select
            id="payment_method"
            value={paymentMethod}
            onChange={updateMethod}
            disabled={locked}
          >
            <option>Select from below</option>
            {paymentMethods.map((method) => (
              <option key={method.id} value={method.id}>
                {method.label}
              </option>
            ))}
          </select>
          {!!paymentMethod && (
            <div className="info">
              <p>
                Minimum Payout Amount:{" "}
                <Price
                  priceInTokens={convertToSpicyTokens(activeMethod?.minAmount)}
                />
              </p>
              <br />
              <p>{activeMethod?.description}</p>
              <br />
            </div>
          )}

          <div className="user-inputs">
            {activeMethod?.inputs?.map((input) => {
              if (input.id === "country")
                return (
                  <Finder
                    value={countries.find(
                      (c) => c.id === paymentInfo[activeMethod?.id]?.[input.id]
                    )}
                    idExtractor={(elem) => input.id + elem.id}
                    id={input.id}
                    onChange={(elem) => updatePaymentInfo(input.id)(elem.id)}
                    {...finderCommon}
                  />
                );

              const Component = input.custom ?? Input;
              return (
                <Component
                  {...input}
                  id={`${activeMethod?.id}-${input.id}`}
                  key={`${activeMethod?.id}-${input.id}`}
                  value={paymentInfo[activeMethod?.id]?.[input.id] ?? ""}
                  callback={updatePaymentInfo(input.id)}
                  disabled={locked || checkRadios(input.id)}
                />
              );
            })}
          </div>
          <RequestError error={error} />

          <Popup
            className="agreement-popup"
            maxWidth={1100}
            open={showSignPopup}
            onClose={toggleSignPopup}
            defaultClose={false}
            showCloseButton
          >
            {signed.signed === false && (
              <Suspense fallback={<Loader />}>
                <SignContract
                  onSignup={onContractSignup}
                  data={{ data, leaderEmail, countries }}
                  id={teamID}
                />
              </Suspense>
            )}
          </Popup>
          <div className="buttons">
            {!locked && <SubmitButton label="Save Changes" request={submit} />}
            {locked && (
              <SubmitButton
                label={
                  signed.signed ? "Show Agreement" : "Complete verification"
                }
                request={signed.signed ? showAgreement : toggleSignPopup}
              />
            )}
          </div>
          {locked && role === Roles.ADMIN && (
            <div className="reset" onClick={resetAgreement}>
              Something is wrong in the Agreement? <u>Reset and Change it.</u>
            </div>
          )}
        </div>
      </div>
    </section>
  );
});

export default PaymentInfo;
