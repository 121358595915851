import { useEffect, useState } from "react";
import { authRequest } from "src/utils/Axios";
import { useToggle } from "../../../../hooks/useToggle";
import { withoutLastPage } from "../../../../utils/withoutLastPage";
import { submitByEnter } from "../../../AdultGames/Header";
import FavGameMini from "../../../Member/FavGameMini";
import Popup from "../../../common/Popup";
import { SubmitButton } from "../../Common";

export const FeaturedGames = () => {
  const [showGamesPopup, toggleGamesPopup] = useToggle(false);
  const [searchedGames, setSearchedGames] = useState([]);
  const [featuredGames, setFeaturedGames] = useState([]);

  const [addedGames, setAddedGames] = useState([]);

  const [query, setQuery] = useState(null);
  useEffect(() => {
    getFeatured();
  }, []);
  const getQuery = (e) => setQuery(e.target.value);

  const searchGames = async () => {
    try {
      const res = await authRequest({
        url: `/products/api/games/cms/search`,
        params: {
          search_term: query,
          all: true,
        },
      });
      setSearchedGames(withoutLastPage(res));
    } catch (error) {}
  };

  const toggleFeatured =
    (featured = false) =>
    async (id) => {
      const res = await authRequest({
        url: `/products/api/games/featured-games/mark-as-featured`,
        method: "POST",
        params: {
          mark_as_featured: featured,
          game_id: id,
        },
      });
      if (res.success && res.is_featured) {
        setAddedGames((prev) => [...prev, id]);
      }
      if (!res.is_featured)
        setFeaturedGames((prev) => prev.filter((game) => game.id !== id));
    };

  const getFeatured = async () => {
    const res = await authRequest({
      url: "/products/api/games/cms/featured-games",
    });

    if (!res.error) {
      setFeaturedGames(res);
      setAddedGames((prev) => prev.concat(res?.map((game) => game.id)));
    }
  };
  return (
    <section id="featured-game-list" className="col-2">
      <legend>FEATURED GAME LIST</legend>
      <SubmitButton
        label="+ Add Games"
        request={toggleGamesPopup}
        id="featured-home-add-games"
      />
      <div className="content adult-games">
        <Popup
          open={showGamesPopup}
          showCloseButton
          maxWidth={980}
          onClose={() => {
            toggleGamesPopup();
            if (!(featuredGames.length === addedGames.length)) getFeatured();
          }}
        >
          <section id="add-games" className="active">
            <legend>add games to featured games</legend>
            <div className="body adult-games">
              <div className="search">
                <input
                  type="search"
                  id="add-game-search"
                  placeholder="Search.."
                  onChange={getQuery}
                  onKeyPress={submitByEnter}
                />
                <button className="fa fa-search" onClick={searchGames}></button>
              </div>
              <br />
              <div className="games ">
                {!!searchedGames.length ? (
                  searchedGames.map((game, i) => (
                    <FavGameMini
                      {...game}
                      key={i}
                      button={{
                        label: "Add",
                        onClick: toggleFeatured(true),
                        disabled: addedGames.includes(game.id),
                        disabledText: "Added",
                      }}
                    />
                  ))
                ) : (
                  <p>Search Games</p>
                )}
              </div>
            </div>
          </section>
        </Popup>

        {featuredGames.map((game, i) => (
          <FavGameMini
            {...game}
            key={i}
            button={{
              label: "Remove",
              onClick: toggleFeatured(false),
            }}
          />
        ))}
        {!!!featuredGames.length && <h1>No featured Games found</h1>}
      </div>
    </section>
  );
};
