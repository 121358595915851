import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { fetcher } from "src/components/CMS/Cms";
import { REVALIDATE_ON_FOCUS, SubmitButton } from "src/components/CMS/Common";
import { LoadMore } from "src/components/Common";
import Reviews from "src/components/GameDetails/Reviews";
import { loggedInValidation } from "src/components/Validation";
import RequestError from "src/errors/RequestError";
import { authRequest } from "src/utils/Axios";
import { withoutLastPage } from "src/utils/withoutLastPage";
import useSWRInfinite from "swr/infinite";

const VideoComments = ({ videoId }) => {
  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) =>
      `${
        window.domainName
      }/community/community-videos/video/comments?video_id=${videoId}&page=${
        index + 1
      }`,
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );

  const comments = data ? withoutLastPage(data.flat()) : [];

  const [newComment, setNewComment] = useState("");

  const sendComment = async () => {
    try {
      if (newComment.length <= 0) throw new Error("Write a comment");
      const res = await toast.promise(
        authRequest({
          url: "/community/community-videos/video/comments",
          method: "POST",
          data: { video_id: videoId, comment_text: newComment },
        }),
        { pending: "Sending comment", success: "Successfully uploaded comment" }
      );

      if (res.success) {
        setNewComment("");
        await mutate(comments, true);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <section>
      <legend>
        <h2>Comments</h2>
      </legend>
      <div className="content">
        <Reviews reviews={comments} type="comment" />
      </div>
      <RequestError erorr={error} />
      {comments.length >= 8 && (
        <LoadMore onClick={() => setSize((prev) => prev + 1)} />
      )}
      <div className="add-comment">
        {loggedInValidation() ? (
          <div className="comment">
            <textarea
              id="review-textarea"
              rows="5"
              placeholder={"Write a comment..."}
              onChange={(e) => setNewComment(e.target.value)}
              spellCheck
              value={newComment}
            ></textarea>
            <br />
            <SubmitButton
              className="submit"
              request={sendComment}
              label="Submit Comment"
            />
          </div>
        ) : (
          <Link to={`/login${window.location.pathname}`}>
            <br />
            <div>
              <u>Login</u> to add comments
            </div>
          </Link>
        )}
      </div>
    </section>
  );
};

export default VideoComments;
