import { Tabs } from "src/components/Tabs";
import { MainHeader } from "../../Headers";
import AllPurchaseList from "./AllPurchasesList";
import GameSales from "./GameSales";
import { SelectTeam } from "../../SelectTeam";
import SolarGameboyBold from "src/Icons/SolarGameboyBold";
import GameBundles from "./Bundles/GameBundles";

const SalesList = ({ toggler, subHeader }) => {
  return (
    <div className="body">
      <MainHeader toggler={toggler} header={subHeader} />
      <SelectTeam />
      <div className="content">
        <Tabs
          firstPageId={"sales"}
          pages={[
            {
              component: GameSales,
              displayText: "Sales",
              id: "sales",
              url: "/sales",
            },
            {
              component: GameBundles,
              displayText: "Bundles",
              id: "bundles",
              url: "/bundles",
              isNew: true,
            },
            {
              component: AllPurchaseList,
              displayText: "All Purchases",
              id: "purchases",
              url: "/purchases",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default SalesList;
