import dateFormat from "dateformat";
import { useContext } from "react";
import SolarGameboyBold from "src/Icons/SolarGameboyBold";
import SubmitButton from "src/v2/components/SubmitButton";
import useSWRInfinite from "swr/infinite";
import { withoutLastPage } from "../../../utils/withoutLastPage";
import { LoadMore, sort } from "../../Common";
import { authorizedRequest } from "../../Validation";
import { fetcher } from "../Cms";
import { REVALIDATE_ON_FOCUS } from "../Common";
import { MainHeader } from "../Headers";
import { RoleContext } from "../RoleContext";
import Table, { getColumn } from "../Table/Table";
import "../css/GameCreation.css";
import { gameOptions } from "./Options";
import { useHistory } from "react-router-dom";

const columns = [
  getColumn("TITLE", { sortKey: "title", accessKey: "title" }),
  getColumn("TEAM", { sortKey: "creator", accessKey: "creator" }),
  getColumn("LAST UPDATED", {
    sortKey: "last_updated",
    accessFn: (game) => dateFormat(new Date(game.last_updated), "longDate"),
  }),
  getColumn("DATE CREATED", {
    sortKey: "creation_date",
    accessFn: (game) => dateFormat(new Date(game.creation_date), "longDate"),
  }),
];

const AllGamesList = ({ toggler }) => {
  const { role } = useContext(RoleContext);
  const history = useHistory();

  const { data, error, setSize, size, mutate, isValidating } = useSWRInfinite(
    (index) => {
      return `${window.domainName}/products/api/games/list?page=${index + 1}`;
    },
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );

  const games = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];

  const errors = data && data.flat().find((obj) => obj.hasOwnProperty("error"));

  const search = async (query) => {
    const res = await authorizedRequest({
      url: `${window.domainName}/products/api/games/cms/search?search_term=${query}`,
    });

    return withoutLastPage(res.flat());
  };
  const searchCallback = (data) => {
    mutate(data, false);
  };

  const redirectToUpload = () => {
    history.push("/cms/content/upload-game/info");
  };
  return (
    <div className="body cms-details">
      <MainHeader
        toggler={toggler}
        uploadLink={"./upload-game"}
        header="GAMES"
        search={{
          func: search,
          callback: searchCallback,
        }}
      />
      {games.length === 0 && !isValidating ? (
        <div className="m-auto grid place-items-center gap-5">
          <SolarGameboyBold className="h-28 w-28 mt-5" />
          <p>You don't Have any games yet</p>
          <div>
            <SubmitButton onClick={redirectToUpload}>
              Upload your First Game
            </SubmitButton>
          </div>
        </div>
      ) : (
        <div className="content">
          <Table
            onSort={(key, order) => mutate(sort(key, games, order), false)}
            refreshData={() => mutate(games, true)}
            columnCSSConfig="3fr 1fr 1fr 1fr"
            columns={columns}
            data={games}
            indicators={[
              {
                color: "#fb0400",
                when: (game) => !game.published,
                name: "Game is unpublished",
              },
              {
                color: "#00b4f2",
                when: (game) => !game.approved,
                name: "Game is unapproved",
              },
            ]}
            getOptions={(game) =>
              gameOptions(
                game.team_role && game.team_role !== "Member"
                  ? game.team_role
                  : role,
                game
              )
            }
            getPopupData={(game) => ({
              view_game: { id: game.id },
              edit: { id: game.id },
              edit_download: { id: game.id },
              see_awards: { id: game.id, title: game.title, role },
              add_award: { id: game.id, title: game.title },
              send_warning: { id: game.id, title: game.title },
              delete: {
                id: game.id,
                title: game.title,
                creator: game.creator,
                created: game.creation_date,
              },
              deny: {
                title: game.title,
                creator: game.creator,
                created: game.creation_date,
                id: game.id,
              },
              publish: {
                title: game.title,
                creator: game.creator,
                created: game.creation_date,
                id: game.id,
                publish: !game.published,
              },
            })}
          />

          {errors && <div className="error">{errors.error}</div>}
          {error && <div className="error">{error.message}</div>}
          <LoadMore onClick={() => setSize(size + 1)} />
        </div>
      )}
    </div>
  );
};

export default AllGamesList;
