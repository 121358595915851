import { Children, cloneElement, ReactElement, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { getClass } from "src/utils/getClass";

/**
 * @typedef {Object} FormItemProps
 * @property {ReactElement} children - The child element to be rendered inside the form item.
 * @property {string} label - The label for the form item.
 * @property {string} name - The name of the form field.
 * @property {string} className - Optional CSS class name for the form item.
 * @property {string} helperText - The helper text for the form field.
 *
 */

/**
 * @typedef {Object} FormItemChildrenProps
 * @property {string} id - The ID of the form field.
 * @property {boolean} error - Whether the form field has an error.
 * @property {string} [helperText] - The helper text for the form field.
 */

/**
 * FormItem component for rendering individual form fields with labels and error handling.
 * @param {FormItemProps} props - The properties passed to the form item component.
 * @returns {JSX.Element} The rendered form item component.
 */
const FormItem = ({ children, label, name, helperText, className }) => {
  const methods = useFormContext();

  const id = useMemo(() => btoa(name), []);

  if (Children.count(children) !== 1)
    throw new Error("Form item should have exactly one child");

  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field, fieldState }) => {
        const extraProps = {
          id,
          error: fieldState.invalid,
          helperText: fieldState.error?.message,
        };

        const child = Children.map(children, (child) =>
          cloneElement(child, { ...field, ...extraProps })
        );

        return (
          <div className={getClass("min-h-28", className)}>
            <label
              className={getClass(
                "mb-1 block text-white",
                fieldState.invalid && "text-red-500"
              )}
              htmlFor={id}
            >
              {label}
            </label>
            <div>{child}</div>
            <p
              className={getClass(
                fieldState.invalid && "text-red-500",
                "text-xs opacity-80 pt-1"
              )}
            >
              {fieldState.error?.message ?? helperText}
            </p>
          </div>
        );
      }}
    />
  );
};

export default FormItem;
