import axios from "axios";
import { useEffect, useState } from "react";
import { useLanguage } from "../providers/LanguageProvider";

/**
 * @param {string} key
 * @returns {import("axios").AxiosResponse}
 */
const useDocs = (key) => {
  const language = useLanguage();
  const [response, setResponse] = useState({});

  useEffect(() => {
    getData(language);
  }, [language]);

  const getData = async (language) => {
    try {
      const res = await axios({
        url: `/docs/${key}_${language}.html`,
        method: "get",
      });
      setResponse(res);
    } catch (err) {
      if (err.response.status === 404) getData("en");
    }
  };

  return response;
};

export default useDocs;
