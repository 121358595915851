import { useCallback, useEffect } from "react";
import { useSocket } from "../providers/SocketProvider";

export const useSocketEvent = (event, callback) => {
  const socket = useSocket();

  const cb = useCallback(
    (...args) => {
      callback(...args);
      console.groupCollapsed("Event Occured:", event);
      args.forEach((arg) => console.debug(arg));
      console.groupEnd();
    },
    [event]
  );

  useEffect(() => {
    if (socket) {
      console.debug("Listening to", event);
      socket.on(event, cb);
    }
    return () => {
      console.debug("Removed previous event", event);
      socket?.removeListener(event, cb);
    };
  }, [cb, event, socket]);
};
