import axios from "axios";
import { Component } from "react";
import { SubmitButton } from "../CMS/Common";
import "./style.css";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  async componentDidCatch(error, errorInfo) {
    await axios({
      url: "/report-error",
      method: "POST",
      data: {
        name: error.name,
        message: error.message,
        info: errorInfo.componentStack,
        time: Date.now(),
        url: window.location.href,
      },
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryFallback />;
    }

    return this.props.children;
  }
}

export const ErrorBoundaryFallback = () => {
  return (
    <div className="body cms-details">
      <div className="content">
        <main className="react-error">
          <img
            src="/images/logo-round.png"
            alt="Spicygaming logo"
            width={200}
            height={200}
          />
          <h2>Uugh! Something went wrong</h2>
          <p>You can reload this page. The team has been notified</p>
          <SubmitButton
            request={() => window.location.reload()}
            label={"Reload"}
          />
        </main>
      </div>
    </div>
  );
};
