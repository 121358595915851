import { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { SubmitButton } from "./CMS/Common";
import { getClass } from "src/utils/getClass";

const Loader = () => {
  useEffect(() => {
    window.document.body.inert = true;
    return () => {
      window.document.body.inert = false;
    };
  }, []);

  return (
    <div className="FullPageLoader submit loader">
      <svg className="svg-container" viewBox="0 0 100 100">
        <circle className="loader-svg bg" cx="50" cy="50" r="45"></circle>
        <circle className="loader-svg animate" cx="50" cy="50" r="45"></circle>
      </svg>
    </div>
  );
};

const LoaderInside = ({ className }) => (
  <div className={getClass("svg-loader inline", className)}>
    <svg className="svg-container" viewBox="0 0 100 100">
      <circle className="loader-svg bg" cx="50" cy="50" r="45"></circle>
      <circle className="loader-svg animate" cx="50" cy="50" r="45"></circle>
    </svg>
  </div>
);
const TinyButtonLoader = ({ backgroundColor }) => (
  <div
    className="lds-ellipsis"
    style={{ "--backgroundColor": backgroundColor }}
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

const FBicon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.092"
    height="19.092"
    viewBox="0 0 19.092 19.092"
  >
    <g transform="translate(0 0)">
      <path
        d="M18.037,0H1.054A1.054,1.054,0,0,0,0,1.054V18.037a1.054,1.054,0,0,0,1.054,1.054H10.2V11.7H7.709V8.816H10.2V6.691A3.472,3.472,0,0,1,13.9,2.883,20.635,20.635,0,0,1,16.125,3V5.573H14.6c-1.2,0-1.427.569-1.427,1.4V8.816h2.854L15.653,11.7H13.172v7.394h4.865a1.055,1.055,0,0,0,1.055-1.053V1.053A1.054,1.054,0,0,0,18.037,0Z"
        transform="translate(0 0)"
        fill="#fff"
      />
    </g>
  </svg>
);
const passwordToggle = (e) => {
  let passwordInput = e.target.previousSibling.type;

  switch (passwordInput) {
    case "text":
      passwordInput = "password";
      break;
    case "password":
      passwordInput = "text";
      break;
    default:
      break;
  }

  e.target.previousSibling.type = passwordInput;
  e.target.classList.toggle("fa-eye");
  e.target.classList.toggle("fa-eye-slash");
};

/**Extract days from date
 * @param {String} date - date string `2021-08-19T16:41:35Z`
 * @returns {number} days
 */
const daysExtract = (date) => {
  const days = Math.round((Date.now() - Date.parse(date)) / (1000 * 3600 * 24));
  return days;
};
/**Load more button for paginations
 *@param {Object} props
 * @param {Function} props.onClick - event trigger when the button pressed
 * @param {String|JSX.Element} props.label - Button text. default is ```Load More```
 * @returns {JSX.Element}
 */
const LoadMore = ({ onClick, label }) => {
  return (
    <div className="load-more">
      <SubmitButton label={label || "Load More"} request={onClick} />
    </div>
  );
};

export class FileNotFoundError {
  name = "FileNotFoundError";
  constructor(filename, msg) {
    this.msg = msg;
    this.filename = filename;
  }
}

/**Convert Image into actual file
 * @param {String} image - URL for get the image
 */
const urlToObject = async (image) => {
  const filename = image.split("/").at(-1);

  try {
    const response = await fetch(image, {
      mode: "cors",
      cache: "no-cache",
    });

    if (response.status !== 200)
      return new FileNotFoundError(
        filename,
        `Couldn't load images ${filename}`
      );

    // here image is url/location of image
    const blob = await response.blob();

    const file = new File([blob], filename, { type: "image/webp" });

    return file;
  } catch {
    return new FileNotFoundError(filename, `Couldn't load images ${filename}`);
  }
};
const addImageFiles = async (key, setState, urlList = [], setConnector) => {
  const files = await Promise.all(
    urlList.map(async (url) => {
      const file = await urlToObject(url);

      return { file, url };
    })
  );

  if (typeof setConnector === "function") setConnector(files);

  setState((prev) => ({
    ...prev,
    [key]: files.map((obj) => obj.file),
  }));
};

/**Used to sort array of Objects by key
 * @param {String} key - Key of Object
 * @param {Array} data - the data you need to sort
 * @param {"asc"|"dsc"} order - order of how you need to sort `asc` - Ascending, `dsc` - Descending
 */
const sort = (key, data = [], order = "asc") => {
  const copy = [...data];
  const sorted = copy.sort((a, b) =>
    order === "asc"
      ? a[key] < b[key]
        ? -1
        : 1
      : order === "dsc"
      ? a[key] < b[key]
        ? 1
        : -1
      : 0
  );
  return sorted;
};

const Header = ({ header, className }) => {
  const [order, setOrder] = useState("asc");

  const sort = () => {
    header.sort(order);
    setOrder((prev) => (prev === "asc" ? "dsc" : "asc"));
  };
  return (
    <div
      className={`item ${className}`}
      onClick={sort}
      data-tooltip="Click to sort"
    >
      {header.text}{" "}
      <span
        className={`fa ${order === "asc" ? "fa-sort-down" : "fa-sort-up"}`}
      ></span>
    </div>
  );
};

/**
 * @deprecated
 */
const Popup = ({ show, children, close }) => {
  return (
    <CSSTransition in={show} timeout={300} classNames={"popup"} unmountOnExit>
      <aside className="popup-container">
        <div className="children">
          {children}
          <div className="fa fa-close" onClick={close}></div>
        </div>
      </aside>
    </CSSTransition>
  );
};

export {
  FBicon,
  Header,
  LoadMore,
  Loader,
  LoaderInside,
  Popup,
  TinyButtonLoader,
  addImageFiles,
  daysExtract,
  passwordToggle,
  sort,
  urlToObject,
};
