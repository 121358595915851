import { request } from "./Axios";

/**
 * @param {number} id
 * @param {"Blog Posts"|"Game News"|"Staff Reviews"|"Game"|"Videos"|"Gallery"} type
 */
const updateViewed = async (id, type) => {
  try {
    await request({
      url: "/news/api/update-page-views",
      method: "POST",
      withCredentials: true,
      data: {
        id,
        type,
      },
    });
  } finally {
    return;
  }
};
export const updateViewedImages = async (imageIds) => {
  try {
    await request({
      url: "/products/api/spicyart/gallery/images/track-view",
      method: "POST",
      withCredentials: true,
      data: {
        image_ids: imageIds,
      },
    });
  } finally {
    return;
  }
};
export default updateViewed;
