import { useEffect, useState } from "react";
import { useCarousel } from "./Carousel";
import { getClass } from "src/utils/getClass";

export const CarouselDots = ({ className }) => {
  const { api } = useCarousel();
  const [selected, setSelected] = useState(0);

  const onDotClick = (index) => {
    api?.scrollTo(index);
    onSelect();
  };

  const onSelect = () => {
    const selected = api?.selectedScrollSnap();
    if (selected !== undefined) setSelected(selected);
  };

  useEffect(() => {
    api?.on("reInit", onSelect);
    api?.on("select", onSelect);

    return () => {
      api?.off("reInit", onSelect);
      api?.off("select", onSelect);
    };
  }, [api]);

  return (
    <div
      className={getClass(
        "w-fit flex-wrap justify-center flex gap-3 my-4",
        className
      )}
    >
      {api?.scrollSnapList().map((_, id) => (
        <button
          key={id}
          onClick={() => onDotClick(id)}
          className={getClass(
            "rounded-full w-4 h-4 bg-black border border-neutral-400",
            selected === id && "bg-primary border border-primary"
          )}
        />
      ))}
    </div>
  );
};
