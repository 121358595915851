import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSpicyStore } from "src/providers/SpicyStoreProvider";
import { authRequest } from "src/utils/Axios";
import RequestError from "../../errors/RequestError";
import { useToggle } from "../../hooks/useToggle";
import { SubmitButton } from "../CMS/Common";
import { Loader } from "../Common";
import SpicyTokensPrice from "../LocalPrice/SpicyTokensPrice";
import { Bankart } from "../Payments/BankartPopup";
import { PaymentTOSLabel } from "../Payments/Popup";
import { Checkbox } from "../Register";
import BillingForm, { billingSchema } from "./BillingForm";
import { usePostHog } from "posthog-js/react";

export const Agreement = ({ checked, onChecked }) => {
  return (
    <Checkbox
      id="agreement"
      value={checked}
      label={<PaymentTOSLabel />}
      callback={onChecked}
    />
  );
};

const Crypto = ({ onProceed }) => {
  const posthog = usePostHog();
  const [error, setError] = useState(null);
  const [checked, toggleChecked] = useToggle();

  const [shouldSaveBilling, toggleShouldSaveBilling] = useToggle(false);

  const billingForm = useForm({
    resolver: zodResolver(billingSchema),
  });

  const updateProfile = async (data) => {
    try {
      await authRequest({
        url: "/api/members/profile",
        method: "POST",
        data,
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const proceed = async () => {
    const isBillingValid = await billingForm.trigger();
    if (!isBillingValid) return toast.error("Please Fill Billing info");

    const billingInfo = billingForm.getValues();

    try {
      if (shouldSaveBilling) {
        await updateProfile(billingInfo);
      }
      const res = await onProceed?.({ billing: billingInfo });
      window.location.replace(res.payment_url);
    } catch (error) {
      posthog.capture("Payment Error", {
        type: "Crypto",
        error: error.message,
      });
      setError(error.message);
    }
  };
  return (
    <div className="container">
      <h3>Pay with Crypto</h3>

      <BillingForm
        form={billingForm}
        shouldSaveBilling={shouldSaveBilling}
        toggleShouldSaveBilling={toggleShouldSaveBilling}
      />

      <RequestError error={error} />
      <Agreement checked={checked} onChecked={toggleChecked} />
      <SubmitButton request={proceed} label="Pay now" disabled={!checked} />
    </div>
  );
};

const SGTokens = ({ onProceed, totalPrice }) => {
  const [error, setError] = useState(null);
  const [checked, toggleChecked] = useToggle(
    true // only temporarily
  );
  const [showBuyTokens, toggleBuyTokens] = useToggle();
  const { tokens } = useSpicyStore();
  const history = useHistory();

  const proceed = async () => {
    try {
      const res = await toast.promise(onProceed?.(), {
        error: "Error Occured",
        pending: "Paying...",
        success: "You've successfully paid",
      });

      if (res.success) history.push("/library/purchased-games");
    } catch (error) {
      setError(error.message);
    }
  };
  return (
    <div className="container">
      <h3>Pay with Spicygaming Tokens</h3>
      <p>
        You need <SpicyTokensPrice tokens={totalPrice?.tokens} /> to pay
      </p>
      <RequestError error={error} />
      <Agreement checked={checked} onChecked={toggleChecked} />
      <SubmitButton
        request={proceed}
        label="Pay now"
        disabled={!checked || tokens < totalPrice?.tokens}
      />

      {/* {tokens < totalPrice?.tokens && (
        <>
          <SubmitButton request={toggleBuyTokens} label="Buy Tokens" />
          <Suspense fallback={<Loader />}>
            <BuyTokens onClose={toggleBuyTokens} show={showBuyTokens} />
          </Suspense>
        </>
      )} */}
    </div>
  );
};
export const paymentMethods = /** @type {const} */ ([
  { id: "tokens", name: "Spicy Tokens", component: SGTokens },
  {
    id: "credit_card",
    name: "Credit Card",
    component: Bankart,
    icon: "fa-credit-card",
  },
  {
    id: "coingate",
    name: "Crypto",
    icon: "fa-bitcoin",
    component: Crypto,
  },
]);

/** filter methods from all
 * @param  {...(paymentMethods)[number]['id']} items
 * @returns
 */
export const filterPaymentMethods = (...items) =>
  items.length > 0
    ? paymentMethods.filter((method) => items.includes(method.id))
    : paymentMethods;
