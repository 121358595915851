import { numberLongFormat } from "src/utils/number-format";

const SpicyTokensPrice = ({ tokens = 0, width = 16 }) => {
  return (
    <div className="SpicyTokensPrice">
      <div>{numberLongFormat(tokens)}</div>

      <SpicyTokensLogo width={width} />
    </div>
  );
};

export const SpicyTokensLogo = ({ width = 16 }) => {
  return (
    <img
      className="SpicyTokensLogo"
      src="/favicon-32x32.png"
      alt="Spicy Tokens"
      width={width}
      height={width}
      data-tooltip="Spicygaming Tokens"
    />
  );
};

export default SpicyTokensPrice;
