import { useRef, useState } from "react";
import "./style.css";
import { SubmitButton } from "src/components/CMS/Common";
import { toast } from "react-toastify";
import { numberCompactData } from "src/utils/number-format";
import { getClass } from "src/utils/getClass";

function DragDropFile({
  onFilesUpload,
  maxFileSize,
  uploadTypeName = "file",
  acceptedFileTypes = [],
  allowMultiple = false,
  maxFiles = 3,
  disabled = false,
}) {
  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      updateFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      updateFiles(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  const updateFiles = (files) => {
    const error = checkFiles(files);
    if (error === null) onFilesUpload?.(files);
    else toast.error(error);
  };

  const checkFiles = (files = []) => {
    if (files.length > maxFiles) return "Too much files";
    for (const file of files) {
      if (file.size > maxFileSize)
        return `Max size is ${numberCompactData(maxFileSize)}`;
    }
    return null;
  };
  return (
    <form
      className={getClass("DragDropFile", disabled && "disabled")}
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={allowMultiple}
        onChange={handleChange}
        accept={acceptedFileTypes}
        disabled={disabled}
      />
      <label id="label-file-upload" htmlFor="input-file-upload">
        <p>Drag and drop your {uploadTypeName} here or</p>
        <SubmitButton
          label={`Upload a ${uploadTypeName}`}
          className="upload-button"
          request={onButtonClick}
        />
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
}

export default DragDropFile;
