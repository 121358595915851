import React, { useEffect, useState } from "react";
import { getClass } from "src/utils/getClass";

export function TableHeader({ column, onSort }) {
  const [order, setOrder] = useState(undefined);

  useEffect(() => {
    if (column.sortKey && order !== undefined) onSort?.(column.sortKey, order);
  }, [order]);
  return (
    <div
      className={"item"}
      onClick={() => {
        setOrder((prev) => {
          if (prev === undefined) return "dsc";
          if (prev === "asc") return "dsc";
          if (prev === "dsc") return "asc";
          return undefined;
        });
      }}
      data-tooltip={column.sortKey ? "Click to sort" : undefined}
    >
      {column.title}

      <span
        className={getClass(
          "fa",
          order ? (order === "asc" ? "fa-sort-up" : "fa-sort-down") : ""
        )}
      ></span>
    </div>
  );
}
