import { useEffect, useMemo, useRef, useState } from "react";
import { Link, generatePath } from "react-router-dom";
import { toast } from "react-toastify";
import relativeDate from "relative-date";
import EmbedIcon from "src/Icons/IcBaselineCode";
import { follow } from "src/components/AdultGames/Game";
import { useGamesMetaData } from "src/components/AdultGames/MetaDataProvider";
import { LikeButton } from "src/components/Atoms/Buttons";
import { SubmitButton } from "src/components/CMS/Common";
import { Chip } from "src/components/Chip";
import { Input } from "src/components/Member/General";
import { Avatar } from "src/components/common/Avatar";
import Popup from "src/components/common/Popup";
import { ShareMenu } from "src/components/common/ShareMenu";
import { generateURL } from "src/components/resizeImages";
import { useToggle } from "src/hooks/useToggle";
import { numberCompact } from "src/utils/number-format";

const CreatorInfo = ({
  team,
  numOfLikes,
  likeContent,
  shareLink,
  shareText,
  videoID,
  isLiked: previouslyLiked = false,
  actionButtons = [],
}) => {
  const [showShareMenu, toggleShareMenu] = useToggle();
  const [showEmbedMenu, toggleEmbedMenu] = useToggle();

  const mainAction = actionButtons[0];
  const restActions = actionButtons.slice(1);

  return (
    <div className="CreatorInfo">
      <div className="creator">
        <Link to={`/team/@${team.name}`}>
          <Avatar
            name={team.name}
            src={generateURL(72, 72, team.profile_picture)}
            width={36}
          />
        </Link>
        <div>
          <Link to={`/team/@${team.name}`} className="link-text">
            <span className="name">
              {team.name}
              {team.approved && (
                <span
                  aria-hidden="true"
                  className="material-icons"
                  title="Verified Team"
                >
                  verified
                </span>
              )}
            </span>
          </Link>
          <p className="subscribers">
            {numberCompact(team.followers)} subscribers
          </p>
        </div>
        {mainAction ?? <FollowTeamButton id={team.id} name={team.name} />}
      </div>
      <div className="actions">
        {restActions}
        {likeContent && (
          <LikeButton
            isLiked={previouslyLiked}
            numOfLikes={numOfLikes}
            onLike={likeContent}
          />
        )}

        <SubmitButton
          type="secondary"
          icon="fa-share"
          label="Share"
          request={toggleShareMenu}
        />
      </div>

      <ShareMenu
        show={showShareMenu}
        onClose={toggleShareMenu}
        shareLink={shareLink}
        shareText={shareText}
        customOptions={
          videoID && (
            <button onClick={toggleEmbedMenu} className="icon border">
              <EmbedIcon />
              <span>Embed</span>
            </button>
          )
        }
      />

      <Popup
        open={showEmbedMenu}
        onClose={toggleEmbedMenu}
        showCloseButton
        className="VideoEmbedMenu object-selector"
      >
        <EmbedMenu
          shareText={shareText}
          videoID={videoID}
          onCopy={() => {
            toggleEmbedMenu(false);
            toggleShareMenu(false);
          }}
        />
      </Popup>
    </div>
  );
};

export const FollowTeamButton = ({ id, name }) => {
  const { updateMetaData, followed } = useGamesMetaData();

  const followUser = async () => {
    const res = await follow(id);
    if (!res) return;

    if (res.is_followed) toast.success(`Successfully subscribed to ${name}`);
    else toast.warn(`Successfully unsubscribed to ${name}`);
    await updateMetaData();
  };
  const isFollowed = useMemo(() => {
    return followed.includes(id);
  }, [followed, id]);

  return (
    <SubmitButton
      icon={isFollowed ? "fa-user" : "fa-user-plus"}
      label={isFollowed ? "Unsubscribe" : "Subscribe"}
      type={isFollowed ? "secondary" : "primary"}
      request={followUser}
    />
  );
};

const EmbedMenu = ({ videoID, shareText, onCopy }) => {
  const iframe = useRef(null);
  const [text, setText] = useState("");
  useEffect(() => {
    const ifr = iframe.current;
    ifr.addEventListener("load", onLoad);
    return () => {
      ifr.removeEventListener("load", onLoad);
    };
  }, []);

  const onLoad = () => {
    setText(iframe.current.outerHTML);
  };

  const link = useMemo(() => {
    const base = new URL(
      generatePath("/video/embed/:id", { id: videoID }),
      window.location.origin
    );

    return base.toString();
  }, [videoID]);

  const copy = () => {
    navigator.clipboard.writeText(text);
    toast.success("Link copied to clipboard");
    onCopy?.();
  };

  return (
    <section>
      <legend>Embed video</legend>
      <aside>
        <iframe
          ref={iframe}
          width="560"
          height="315"
          src={link}
          title={shareText}
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </aside>
      <aside>
        <Input
          Component={(props) => <textarea rows={5} {...props}></textarea>}
          controlledValue={text}
        />
        <SubmitButton
          disabled={text.length <= 0}
          label="copy"
          icon="fa-copy"
          request={copy}
        />
      </aside>
    </section>
  );
};

export function ContentMetaInfo({
  views,
  creation_date,
  tags,
  setTagUrl = (_param) => "",
  description,
  currentImage,
  dangerouslySetInnerHTML = false,
  extraLittleInfo,
}) {
  return (
    <div className="ContentMetaInfo">
      <p className="meta">
        <span>{numberCompact(views)} Views</span>
        <span>{relativeDate(new Date(creation_date))}</span>
        {tags && (
          <div className="tags">
            {tags?.map((tag) => {
              const tagsURL = setTagUrl(tag.param);
              return (
                <Chip className="tag" key={tag.param}>
                  <Link to={tagsURL}>#{tag.name}</Link>
                </Chip>
              );
            })}
          </div>
        )}
        {extraLittleInfo}
      </p>
      {dangerouslySetInnerHTML ? (
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      ) : (
        <p className="description">{description}</p>
      )}
      {currentImage?.game?.id && (
        <p className="game-credit">
          IMAGE FROM ADULT GAME:{" "}
          <Link to={`/game/${currentImage?.game.id}`}>
            {currentImage?.game.title}
          </Link>
        </p>
      )}
    </div>
  );
}
export default CreatorInfo;
