import { generatePath } from "react-router-dom";
import { RedirectTo } from "../Common";
import permissions from "../Permission";
import { Roles } from "../RoleContext";
import { roleFilter } from "../RoleFilter";
import {
  AddGameAward,
  ApproveGame,
  Delete,
  DeleteReportedLink,
  DenyGame,
  FullTransaction,
  GameAwards,
  PublishGame,
  SendWarning,
} from "./OptionPopups";

// adminOptions
// modOptions
// creatorOptions
// leaderOptions
// coLeaderOptions
// leaderOptions
// modOptions

const approvalOptions = (role) => {
  const allPermissions = [
    {
      icon: "fa-eye",
      text: "Preview Game",
      id: "preview",
      popup: ({ id }) => <RedirectTo to={`/game/preview/${id}`} />,
    },
    {
      icon: "fa-edit",
      text: "Edit",
      id: "edit",
      popup: ({ id }) => {
        const path = generatePath("/cms/content/games/edit/:id/:uuid?", { id });
        return <RedirectTo to={path} />;
      },
    },
    { icon: "fa-check", text: "Approve", id: "approve", popup: ApproveGame },
    { icon: "fa-ban", text: "Deny", id: "deny", popup: DenyGame },
    {
      icon: "fa-trash",
      text: "Delete",
      popup: Delete,
      id: "delete",
      danger: true,
    },
  ];
  return roleFilter(role, allPermissions, {
    [Roles.ADMIN]: ["preview", "edit", "approve", "deny", "delete"],
    [Roles.STAFF]: ["preview", "edit", "approve", "deny", "delete"],
    [Roles.CONTENT_CREATOR]: ["preview", "edit"],
    [Roles.TRUSTED_CONTENT_CREATOR]: ["preview", "edit"],
  });
};

const gameOptions = (role, details) => {
  const allPermissions = [
    {
      icon: "fa-eye",
      text: details.published ? "View Game" : "Preview Game",
      id: "view_game",
      popup: ({ id }) => (
        <RedirectTo
          to={details.published ? `/game/${id}` : `/game/preview/${id}`}
          from={permissions(role)?.paths?.games?.paths?.game_list?.path}
        />
      ),
    },
    {
      icon: "fa-edit",
      text: "Edit",
      id: "edit",
      popup: ({ id }) => (
        <RedirectTo
          to={`${window.location.pathname}/edit/${id}`}
          from={window.location.pathname}
        />
      ),
    },

    {
      icon: "fa-money",
      text: "Create a Sale",
      id: "sale",
      popup: () => (
        <RedirectTo
          to={`/cms/financial/game-sales/`}
          from={window.location.pathname}
        />
      ),
    },
    {
      icon: details.published ? "fa-ban" : "fa-check",
      popup: PublishGame,
      text: details.published ? "Unpublish Game" : "Publish Game",
      id: "publish",
    },
    { icon: "fa-ban", text: "Unapprove Game", id: "deny", popup: DenyGame },
    {
      icon: "fa-trophy",
      text: "See Awards",
      id: "see_awards",
      popup: GameAwards,
    },
    {
      icon: "fa-trophy",
      text: "Add New Award",
      id: "add_award",
      popup: AddGameAward,
    },
    {
      icon: "fa-warning",
      text: "Send Warning Email",
      id: "send_warning",
      popup: SendWarning,
    },
    {
      icon: "fa-trash",
      popup: Delete,
      text: "Delete",
      id: "delete",
      danger: true,
    },
  ];

  return roleFilter(role, allPermissions, {
    [Roles.ADMIN]: [
      "view_game",
      "edit",
      "delete",
      "publish",
      "deny",
      "see_awards",
      "add_award",
      "remove_award",
      "send_warning",
      "sale",
    ],
    [Roles.STAFF]: [
      "view_game",
      "edit",
      "delete",
      "publish",
      "deny",
      "sale",
      "see_awards",
    ],
    [Roles.CONTENT_CREATOR]: ["view_game", "see_awards", "sale"],
    [Roles.TRUSTED_CONTENT_CREATOR]: ["view_game", "see_awards", "sale"],
    [Roles.LEADER]: ["view_game", "edit", "publish", "see_awards", "sale"],
    [Roles.CO_LEADER]: ["view_game", "edit", "publish", "see_awards", "sale"],
  });
};

const reportedLinkOptions = (role) => {
  const allPermissions = [
    {
      icon: "fa-edit",
      text: "Edit Game",
      id: "edit",
      popup: ({ id, uuid }) => {
        const path = `/cms/content/games/edit/${id}/downloads?uuid=${uuid}`;
        return <RedirectTo to={path} />;
      },
    },
    {
      icon: "fa-check",
      text: "Link Resolved",
      id: "delete",
      popup: DeleteReportedLink,
    },
  ];
  return roleFilter(role, allPermissions, {
    [Roles.ADMIN]: ["edit", "delete"],
    [Roles.STAFF]: ["edit", "delete"],
    [Roles.CONTENT_CREATOR]: ["edit", "delete"],
    [Roles.TRUSTED_CONTENT_CREATOR]: ["edit", "delete"],
    leader: ["edit", "delete"],
  });
};

const salesOptions = (role) => {
  const allPermissions = [
    {
      icon: "fa-exchange",
      text: "Show Transaction",
      id: "show_full",
      popup: FullTransaction,
    },
  ];
  return roleFilter(role, allPermissions, {
    [Roles.ADMIN]: ["show_full"],
    [Roles.STAFF]: ["show_full"],
    [Roles.CONTENT_CREATOR]: ["show_full"],
  });
};

export { salesOptions };
export { reportedLinkOptions };
export { gameOptions };
export { approvalOptions };
