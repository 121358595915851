import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { LoaderInside } from "src/components/Common";
import Error404 from "src/components/Errors";
import { authRequest } from "src/utils/Axios";
import CreatePlaylist from "./CreatePlaylist";

export const EditPlaylist = (props) => {
  const params = useParams();
  const [error, setError] = useState();
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: "/community/playlists",
        params: {
          playlist_id: params.id,
          cms: true,
        },
      });
      setDetails(res);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="body">
      {loading ? (
        <LoaderInside />
      ) : error ? (
        <Error404 />
      ) : (
        <CreatePlaylist
          {...props}
          editMode
          details={{
            id: details.id,
            name: details.name,
            videos: details.videos?.map((video) => video.id),
            team: details.team,
          }}
        />
      )}
    </div>
  );
};
