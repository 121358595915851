import { useState } from "react";
import { useHistory } from "react-router-dom";
import { authRequest } from "src/utils/Axios";
import "./css/Logout.css";
import { Helmet } from "react-helmet";
import { usePostHog } from "posthog-js/react";

const removeCredentials = async () => {
  try {
    await authRequest({
      url: "/api/members/logout",
      method: "POST",
    });
  } catch (error) {}
};

export const logoutUser = async (redirectTo = "/", onLogout) => {
  await removeCredentials();
  localStorage.clear();
  onLogout?.();
  window.location.replace(redirectTo);
};

function Logout() {
  const posthog = usePostHog();
  const history = useHistory();
  const [out, setOut] = useState(false);

  const back = () => {
    history.goBack();
  };

  const logout = async () => {
    setOut(true);
    await logoutUser("/", () => {
      posthog.reset();
    });
  };
  return (
    <main id="logout">
      <Helmet>
        <title>Logout - Spicygaming</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="inner">
        <div className="image">
          <img loading="lazy" src="/images/logo.png" alt="Spicy gaming" />
        </div>
        {!out ? (
          <>
            <h2>Do you want to Logout ?</h2>

            <div className="buttons">
              <div className="yes" onClick={logout}>
                Yes
              </div>
              <div className="no" onClick={back}>
                No
              </div>
            </div>
          </>
        ) : (
          <h1>Logging out ...</h1>
        )}
      </div>
    </main>
  );
}

export default Logout;
