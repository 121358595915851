import React from "react";
import "./style.css";

const SideMenu = ({ show, toggleMenu, children }) => {
  return (
    <div className="SideMenu" data-open={show} data-testid="SideMenu">
      <button onClick={toggleMenu} className="toggle-button">
        <i className="fa fa-close"></i>
      </button>
      <div className={`side-menu`}>{children}</div>
    </div>
  );
};

export default SideMenu;
