import React, { useMemo, useState } from "react";
import { useToggle } from "src/hooks/useToggle";
import Popup from "src/components/common/Popup";
import { getClass } from "src/utils/getClass";

export const TableRowActions = ({
  options = [],
  refreshData,
  popupData = {},
}) => {
  const unFocus = (e) => e.target.parentElement.parentElement.blur();
  const [open, toggleOpen] = useToggle();

  const [clickedID, setClickedID] = useState();

  const ClickedOption = useMemo(
    () =>
      options.find((option) => option.id === clickedID) || {
        popup: () => <></>,
      },
    [clickedID]
  );

  const onClose = () => {
    toggleOpen(false);
    refreshData?.();
  };
  return (
    <button
      className="option-dots"
      data-tooltip="Click to see All Available options"
    >
      <span className="material-icons dot" onClick={unFocus}>
        more_horiz
      </span>

      <div className="menu">
        <span className="material-icons" onClick={unFocus}>
          more_horiz
        </span>
        {options.map((option, i) => {
          return (
            <div
              role="button"
              className={getClass(
                "opt",
                option.danger === true &&
                  "text-red-600 hover:!bg-red-500 hover:text-red-100"
              )}
              key={i}
              onClick={() => {
                setClickedID(option.id);
                toggleOpen();
              }}
            >
              <span className={`fa ${option.icon}`}></span>
              <span>{option.text}</span>
            </div>
          );
        })}
      </div>

      {ClickedOption.popup && (
        <Popup
          open={open}
          onClose={onClose}
          giveCloseCallbackToChildren
          {...ClickedOption.popupProps}
        >
          {/* eslint-disable-next-line */}
          <ClickedOption.popup
            {...ClickedOption.props}
            {...popupData[ClickedOption.id]}
          />
        </Popup>
      )}
    </button>
  );
};
