import axios from "axios";
import React, { useCallback, useContext, useEffect } from "react";
import { useLayoutEffect } from "react";
import { createContext } from "react";
import useLocalStorage from "../hooks/useLocalstorage";
import useQuery from "../hooks/useQuery";
import { backend } from "../utils/Axios";

const INITIAL_STATE = "en";

const LanguageContext = createContext({
  language: INITIAL_STATE,
  changeLanguage: (_code) => {},
});

export const LanguageProvider = ({ children }) => {
  const params = useQuery();

  const code = params.get("lang");

  const [language, setLanguage] = useLocalStorage(
    "lang",
    code ?? INITIAL_STATE
  );
  const changeLanguage = (code) => {
    setLanguage(code);
  };
  useEffect(() => {
    if (code) return setLanguage(code);
    // if no language selected & navigator has a language with are support,
    // we make that as default language
    const [lang] = navigator.language.split("-");
    if (
      availableLanguages.find(({ code }) => code === lang) !== null &&
      !language
    ) {
      setLanguage(lang);
    }
  }, [code]);

  const addLanguageParam = useCallback(
    (config) => {
      if (language === "en") return config;

      const params = config.params ?? {};
      params["user_language"] = language;
      config.params = params;

      return config;
    },
    [language]
  );

  useLayoutEffect(() => {
    document.documentElement.setAttribute("lang", language);

    const interceptorGlobal = axios.interceptors.request.use(
      addLanguageParam,
      (error) => error
    );
    const interceptorBackend = backend.interceptors.request.use(
      addLanguageParam,
      (error) => error
    );

    return () => {
      axios.interceptors.request.eject(interceptorGlobal);
      backend.interceptors.request.eject(interceptorBackend);
    };
  }, [language, addLanguageParam]);

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const state = useContext(LanguageContext);
  return state.language;
};

export const useChangeLanguage = () => {
  const state = useContext(LanguageContext);
  return state.changeLanguage;
};

export const getLanguageInfo = (code) =>
  availableLanguages.find((lang) => lang.code === code);

export const availableLanguages = [
  {
    code: "en",
    name: "English",
    flag: `https://flagcdn.com/w40/us.png`,
  },
  {
    code: "sl",
    name: "Slovenščina",
    flag: `https://flagcdn.com/w40/si.png`,
  },
];
