import React, { useContext } from "react";
import OwlCarousel from "react-owl-carousel";
import NewsMini from "src/components/Article/NewsMini";
import { SimpleDivider } from "src/components/Atoms/SimpleDivider";
import { PreviewContext } from "src/components/Previews/Preview";

export const SliderContainer = ({ children, isOne = false }) => (
  <OwlCarousel
    className="owl-theme"
    dots
    margin={20}
    responsive={{
      0: { items: 1 },
      480: { items: isOne ? 1 : 2 },
      840: { items: isOne ? 1 : 3 },
    }}
  >
    {children}
  </OwlCarousel>
);

const ArticleSlider = ({ title, type, articles = [] }) => {
  const previewMode = useContext(PreviewContext);
  const getLink = (id) => {
    switch (type) {
      case "blog":
        return `/blog-article/${id}`;
      case "staff":
        return `/staff-review/${id}`;
      case "news":
        return `/news-article/${id}`;

      default:
        return "";
    }
  };
  if (!!articles.length)
    return (
      <div className="article news section">
        <SimpleDivider />
        <h2 className="sub title">{title}</h2>
        <div className="content news" data-disable={!!previewMode}>
          <SliderContainer isOne={articles.length === 1}>
            {articles.map((article) => {
              return (
                <NewsMini
                  {...article}
                  key={article.id}
                  url={getLink(article.id)}
                  square={true}
                  type={type}
                  isHorizontal={articles.length === 1}
                />
              );
            })}
          </SliderContainer>
        </div>
      </div>
    );

  return null;
};
export default ArticleSlider;
