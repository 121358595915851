import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { authRequest } from "src/utils/Axios";
import useQuery from "../../hooks/useQuery";
import { SubmitButton } from "../CMS/Common";
import { Loader } from "../Common";
import Error404 from "../Errors";
import { Discord } from "../common/social";
import "./discordAuth.css";

const DiscordAuth = () => {
  const query = useQuery();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const code = query.get("code");

  useEffect(() => {
    sendToken();
  }, []);

  const sendToken = async () => {
    try {
      if (code) {
        const res = await authRequest({
          method: "POST",
          url: `/api/members/discord-auth`,
          data: { code },
        });

        if (res.success) setSuccess(true);
      }
    } catch (error) {
      setError(error.message);
    }
  };
  if (code === null) return <Error404 />;

  if (success)
    return (
      <main className="discord">
        <div className="success">
          <Discord />
          <h1>You have successfully connected to Spicygaming discord server</h1>
          <br />
          <br />
          <Link to={"/member"}>
            <SubmitButton label={"Go to Profile page"} />
          </Link>
        </div>
      </main>
    );

  if (error)
    return (
      <main className="discord">
        <div className="error">{error}</div>
      </main>
    );
  return <Loader />;
};

export default DiscordAuth;
