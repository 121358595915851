import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Trumbowyg from "react-trumbowyg";
import useNewTab from "src/components/Previews/hook";
import { BottomStickyButtons } from "src/components/UtilityComponents/BottomStickyButtons";
import Input from "src/v2/components/form-inputs/Input";
import SearchSelect from "src/v2/components/form-inputs/SearchSelect";
import Form from "src/v2/components/form/Form";
import { getAllTeamsOfUserInCMS } from "src/v2/services/userService";
import useSWR from "swr";
import { authRequest } from "../../../utils/Axios";
import { inputIteratorValidate } from "../../Validation";
import { Required, SubmitButton } from "../Common";
import { RoleContext, Roles, useRoleInfo, useTeamID } from "../RoleContext";
import "../css/Common.css";
import "../css/ldbtn.min.css";
import FormDebug from "src/v2/components/form/FormDebug";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import RichTextInput from "src/v2/components/form-inputs/RichTextInput";

export const OS = [
  {
    name: "Windows",
    param: "Windows",
    icon: "fa-windows",
  },
  {
    name: "Linux",
    param: "Linux",
    icon: "fa-linux",
  },
  {
    name: "MacOS",
    param: "MacOS",
    icon: "fa-apple",
  },
  {
    name: "iOS",
    param: "iOS",
    icon: "fa-apple",
  },
  {
    name: "Android",
    param: "Android",
    icon: "fa-android",
  },
];

const uploadGameValidationSchema = z.object({
  tags: z.array(z.string()).min(5).max(20),
  languages: z.array(z.string()).min(1),
  operating_systems: z.array(z.string()).min(1),
  title: z.string().min(1),
  version: z.string().min(1),
  status: z.string().min(1),
  genre: z.string().min(1),
  engine: z.string().min(1),
  creator_team: z.number(),
  short_description: z.string().min(1),
  long_description: z.string().min(1),
});

const UploadGame = ({ defaults = {}, editMode = false }) => {
  const history = useHistory();
  const { role, teamCount } = useContext(RoleContext);
  const [filters, setFilters] = useState([]);

  const newtab = useNewTab();

  const defaultTeamId = useTeamID();

  const creator_team = defaults.formData?.creator_team?.id ?? defaultTeamId;

  const form = useForm({
    resolver: zodResolver(uploadGameValidationSchema),
    defaultValues: {
      tags: defaults.formData?.tags ?? [],
      languages: defaults.formData?.languages ?? [],
      operating_systems: defaults.formData?.operating_systems ?? [],
      title: defaults.formData?.title,
      version: defaults.formData?.version,
      status: defaults.formData?.status,
      genre: defaults.formData?.genre,
      engine: defaults.formData?.engine,
      creator_team: creator_team,
      short_description: defaults.formData?.short_description,
      long_description: defaults.formData?.description,
    },
  });

  const { data: teams } = useSWR(["creator-teams"], () =>
    getAllTeamsOfUserInCMS()
  );

  const getFiltersById = (id) => {
    return filters.find((filter) => filter.title === id)?.option ?? [];
  };

  const request = async () => {
    const res = await authRequest({
      method: "GET",
      url: "/products/api/game-filters",
    });

    setFilters(res);
  };
  useEffect(() => {
    request();
  }, []);

  const backendReq = (previewMode) => async () => {
    try {
      const isValid = await form.trigger();
      if (!isValid) throw new Error("Invalid Inputs");

      const data = form.getValues();

      if (previewMode) {
        newtab.open();
        newtab.revalidateURL(`/game/preview/${defaults?.ID}`);
      }

      const BACKEND_URL = editMode
        ? `/products/api/games/edit`
        : `/products/api/games/create`;

      const fd = new FormData();

      for (const item in data) {
        fd.append(
          item,
          typeof data[item] === "object"
            ? JSON.stringify(data[item])
            : data[item]
        );
      }

      const res = await toast.promise(
        authRequest({
          method: "POST",
          data: fd,
          url: BACKEND_URL,
          params: {
            game_id: defaults?.ID,
            step: 1,
          },
        }),
        {
          pending: "Sending Request",
          success: editMode
            ? "Game successfully edited"
            : "Game successfully created",
        }
      );

      if (res.success) {
        if (!editMode) {
          if (!previewMode) gotoNext(res.game_id);
        } else {
          defaults.refreshDefaults?.();
        }
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const gotoNext = (id) => {
    history.push(
      generatePath("/cms/content/games/edit/:id/media", {
        id: id ?? defaults.ID,
      })
    );
  };
  if (!([Roles.STAFF, Roles.ADMIN].includes(role) || teamCount > 0)) {
    return <Forbidden />;
  }
  return (
    <main id="creation" className="cms-details">
      <Form form={form}>
        <section id="description">
          <legend>GAME DESCRIPTION</legend>

          <div className="grid md:grid-cols-2 gap-5">
            <Form.Item label="Game Name" name="title">
              <Input />
            </Form.Item>

            {/* keep this as a grid cell skipper  */}
            <div></div>

            <Form.Item label="Select Developer Team" name="team">
              <SearchSelect
                defaultSelectedLabel={defaults.formData?.creator_team?.name}
              >
                {teams?.map((team) => (
                  <SearchSelect.Option
                    label={team.name}
                    value={team.id}
                    key={team.id}
                  />
                ))}
              </SearchSelect>
            </Form.Item>

            <Form.Item
              label="Short Game Description"
              name="short_description"
              helperText="For the search page and social share"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Game Description"
              name="long_description"
              helperText="What is the game about, story, main features"
              className="col-span-full"
            >
              <RichTextInput
                data={defaults.formData?.description}
                placeholder="This is my awesome description of my awesome game.It tells players about the story, graphics, sound and gameplay. And more...."
              />
            </Form.Item>
          </div>
        </section>

        <section id="other-info">
          <legend>
            GAME INFO
            <Required />
            <div
              className="fa fa-info cms"
              title="All fields in this section are Required!"
            ></div>
          </legend>
          <div className="content">
            <div className="info">
              <Form.Item label="Game Status" name="status">
                <SearchSelect defaultSelectedLabel={defaults.formData?.status}>
                  {getFiltersById("status")?.map((filter) => (
                    <SearchSelect.Option
                      label={filter.name}
                      value={filter.param}
                      key={filter.param}
                    />
                  ))}
                </SearchSelect>
              </Form.Item>

              <Form.Item label="Game Version" name="version">
                <Input placeholder="1.0.0" />
              </Form.Item>

              <Form.Item label="Game Engine" name="engine">
                <SearchSelect defaultSelectedLabel={defaults.formData?.engine}>
                  {getFiltersById("engine")?.map((filter) => (
                    <SearchSelect.Option
                      label={filter.name}
                      value={filter.param}
                      key={filter.param}
                    />
                  ))}
                </SearchSelect>
              </Form.Item>
            </div>
            <div className="tag-selector">
              <Form.Item label="Category" name="genre">
                <SearchSelect defaultSelectedLabel={defaults.formData?.genre}>
                  {getFiltersById("genre")?.map((filter) => (
                    <SearchSelect.Option
                      label={filter.name}
                      value={filter.param}
                      key={filter.param}
                    />
                  ))}
                </SearchSelect>
              </Form.Item>

              <Form.Item
                label="Tags"
                name="tags"
                helperText="You can add minimum 5 tags and maximum 20"
              >
                <SearchSelect
                  selectMultiple
                  defaultSelectedLabel={defaults.formData?.tags?.join(", ")}
                >
                  {getFiltersById("tags")?.map((filter) => (
                    <SearchSelect.Option
                      label={filter.name}
                      value={filter.param}
                      key={filter.param}
                    />
                  ))}
                </SearchSelect>
              </Form.Item>

              <Form.Item label="Languages" name="languages">
                <SearchSelect
                  selectMultiple
                  defaultSelectedLabel={defaults.formData?.languages?.join(
                    ", "
                  )}
                >
                  {getFiltersById("languages")?.map((filter) => (
                    <SearchSelect.Option
                      label={filter.name}
                      value={filter.param}
                      key={filter.param}
                    />
                  ))}
                </SearchSelect>
              </Form.Item>

              <Form.Item label="OS" name="operating_systems">
                <SearchSelect
                  selectMultiple
                  defaultSelectedLabel={defaults.formData?.operating_systems?.join(
                    ", "
                  )}
                >
                  {getFiltersById("os")?.map((filter) => (
                    <SearchSelect.Option
                      label={filter.name}
                      value={filter.param}
                      key={filter.param}
                    />
                  ))}
                </SearchSelect>
              </Form.Item>
            </div>
          </div>
        </section>
      </Form>
      <BottomStickyButtons>
        {editMode && (
          <SubmitButton label={"Preview"} request={backendReq(true)} />
        )}
        <SubmitButton label={"Save & Publish"} request={backendReq(false)} />
      </BottomStickyButtons>
    </main>
  );
};

const Forbidden = () => {
  const history = useHistory();
  const redirect = () => {
    history.push("/cms/personal/teams/create-team");
  };
  return (
    <div className="forbidden">
      <div className="image">
        <img src="/images/game.svg" alt="upload forbidden" />
      </div>
      <h2>
        You have to be in at least one team to upload a Game. Try creating your
        own team!
      </h2>
      <SubmitButton
        className="mt-5"
        label="Create Team"
        icon="fa-users"
        request={redirect}
      />
    </div>
  );
};
export default UploadGame;
