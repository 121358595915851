import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Popup from "src/components/common/Popup";
import { useToggle } from "src/hooks/useToggle";
import { authRequest } from "src/utils/Axios";
import { sort } from "../../Common";
import { SubmitButton } from "../Common";
import "../css/Editor.css";
import { ObjectItem, TypeSelector } from "./EditorCommon";
import options from "./EditorOptions";

/**
 *  galleryID This is a must if "image" type is allowed as a block
 *
 */
function Editor({
  customData,
  objects,
  setObjects,
  editorOptions = options,
  onCloseObjectItem,
  customInstructions = null,
  galleryID,
  showFullContent = false,
}) {
  const [newSetPosition, setNewSetPosition] = useState(null);
  const [showTypeSelector, toggleTypeSelector] = useToggle();
  const [images, setImages] = useState([]);

  useEffect(() => {
    setObjects((prev) => sort("order", prev, "asc"));
    getImagesFromGallery();
  }, []);

  // draggable configuration
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = reorder(
      objects,
      result.source.index,
      result.destination.index
    );
    setObjects(reorderedItems);
  };

  const addNewObjectTo = (index) => () => {
    setNewSetPosition(index);
    toggleTypeSelector();
  };

  const getImagesFromGallery = async () => {
    if (!galleryID) return;
    try {
      const res = await authRequest({
        url: "/products/api/spicyart/cms/gallery-detail",
        params: {
          gallery_id: galleryID,
        },
      });

      setImages(res.images);
    } catch (error) {}
  };

  return (
    <div id="object-editor">
      <div className="view-container">
        {customInstructions ?? (
          <>
            <h2>Configure the View</h2>
            <div className="instructions">
              <br />
              <p>
                Use the drop-down menu to add objects (Image, Video, Text...) to
                the article. You can organize and reorder the article by using
                drag an drop system
                <br />
              </p>
            </div>
          </>
        )}

        <div className="content-type">
          <Popup
            open={showTypeSelector}
            onClose={toggleTypeSelector}
            showCloseButton
          >
            <div className="add-object">
              <TypeSelector
                images={images}
                refreshImages={getImagesFromGallery}
                options={Object.values(editorOptions)}
                customData={customData}
                setObjects={setObjects}
                newSetPosition={newSetPosition}
                onClose={() => {
                  toggleTypeSelector();
                  setNewSetPosition(null);
                }}
              />
            </div>
          </Popup>

          <div className="content">
            {objects.length > 0 ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div
                      id="objects-container"
                      data-show-full-content={showFullContent}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {objects.map((object, i) => {
                        return (
                          <Draggable
                            key={object.key}
                            draggableId={"object_item" + object.key}
                            index={i}
                          >
                            {(provided) => (
                              <div
                                className="object-holder"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ObjectItem
                                  refreshImages={getImagesFromGallery}
                                  images={images}
                                  index={i}
                                  label={object.type}
                                  setObjects={setObjects}
                                  object={object}
                                  customData={customData}
                                  onClose={onCloseObjectItem}
                                />
                                <div className="add-item">
                                  <SubmitButton
                                    rounded
                                    icon="fa-plus"
                                    request={addNewObjectTo(i + 1)}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <div className="instructions" style={{ padding: "1rem" }}>
                <SubmitButton
                  label="Add your first block"
                  request={toggleTypeSelector}
                  icon="fa-plus"
                  type="secondary"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Editor;
