import { NavLink } from "react-router-dom";
import "./css/SideMenu.css";
import { Chip } from "../Chip";

const Tab = ({ link, title, icon, toggleShow, isNew = false }) => (
  <div className="tab group">
    <NavLink activeClassName="active" to={link}>
      <div className="title group-hover:!text-white" onClick={toggleShow}>
        <span className={`fa ${icon}`}></span>
        <span>{title}</span>
        {isNew && <Chip>New</Chip>}
      </div>
    </NavLink>
  </div>
);

const SideMenu = ({ show, toggleShow, links }) => {
  return (
    <aside className={`side-menu ${show ? "active" : ""}`}>
      <div>
        <div className="material-icons" onClick={toggleShow}>
          close
        </div>
        {links.map((link) => (
          <Tab
            toggleShow={toggleShow}
            key={link.link}
            link={link.link}
            title={link.header}
            icon={link.icon}
            isNew={link.isNew}
          />
        ))}
      </div>
    </aside>
  );
};

export default SideMenu;
