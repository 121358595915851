import { Suspense, useContext } from "react";
import { MultiLang } from "src/components/MultiLang";
import Trophy from "../Trophy";
import { LoaderInside } from "src/components/Common";
import { PreviewContext } from "src/components/Previews/Preview";

const GameAwards = ({ awards = [] }) => {
  const previewMode = useContext(PreviewContext);

  return (
    <section className="awards section" data-disable={!!previewMode}>
      <legend>
        <h2 className="sub title">
          <MultiLang sl="Nagrade">Awards</MultiLang>
        </h2>
      </legend>
      <div className="content">
        {awards?.map((award, i) => (
          <Suspense fallback={<LoaderInside />} key={i}>
            <Trophy text={award.name} icon={award.icon} />
          </Suspense>
        ))}
      </div>
    </section>
  );
};

export default GameAwards;
