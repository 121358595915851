export const numberCompact = Intl.NumberFormat(undefined, {
  notation: "compact",
}).format;

export const numberLongFormat = Intl.NumberFormat(undefined, {
  notation: "standard",
}).format;

export const addSign = (number) => (number > 0 ? `+${number}` : number);

export const toEuro = Intl.NumberFormat(undefined, {
  currency: "EUR",
  style: "currency",
  maximumFractionDigits: 2,
}).format;

export const numberCompactData = Intl.NumberFormat(undefined, {
  notation: "compact",
  style: "unit",
  unit: "byte",
  unitDisplay: "narrow",
}).format;

export function formatDuration(seconds) {
  var minutes = Math.floor(seconds / 60); // Extract whole minutes
  var remainingSeconds = seconds % 60; // Calculate remaining seconds

  // Add leading zeros if necessary
  var formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  var formattedSeconds =
    remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

  return formattedMinutes + ":" + formattedSeconds;
}
