import { useEffect, useState } from "react";
import { request } from "src/utils/Axios";
import { shapeAs } from "src/utils/validatedBackendRequest";
import { z } from "zod";
import { SimpleDivider } from "../Atoms/SimpleDivider";
import { Chip } from "../Chip";
import { LoaderInside } from "../Common";
import LatestGame from "../Home/LatestGame";
import SuggestionPanel from "../Video/FullPage/SuggestionChips";
import { SuggestionPanelTypes, allSuggestionPanelTypes } from "../Video/utils";
import relativeDate from "relative-date";

const getSuggestionsPropsSchema = z.object({
  game_id: z.number(),
  type: z.string().default(SuggestionPanelTypes.FEATURED.param),
});

const SuggestGames = ({ gameId, teamName }) => {
  const [loading, setLoading] = useState(true);
  const [suggestions, setRecommendations] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(
    SuggestionPanelTypes.FEATURED.param
  );

  useEffect(() => {
    if (selectedSuggestion) getWatchNext(gameId, selectedSuggestion);
  }, [selectedSuggestion, gameId]);

  const getWatchNext = async (id, type) => {
    try {
      setLoading(true);
      const res = await request({
        url: "/products/api/games/recommendations",
        params: {
          game_id: id,
          type,
        },
      });
      setRecommendations(res);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="SugguestCollections">
      <section className="suggestions">
        <SuggestionPanel suggestionData={allSuggestionPanelTypes}>
          {(type) => (
            <div
              key={type.param}
              onClick={() => setSelectedSuggestion(type.param)}
            >
              <Chip
                selected={selectedSuggestion === type.param}
                className="tag"
              >
                {type.name?.replace("#{team}", teamName)}
              </Chip>
            </div>
          )}
        </SuggestionPanel>
      </section>
      <section className="watch-next">
        <legend>
          <h2>Explore Next</h2>
        </legend>
        <div className="content">
          {loading ? (
            <LoaderInside />
          ) : (
            suggestions.map((suggestion) => (
              <div key={suggestion.id}>
                <LatestGame
                  {...suggestion}
                  days={relativeDate(new Date(suggestion.creation_date))}
                />
                <SimpleDivider margin={"1rem"} />
              </div>
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default SuggestGames;
