import React, { useEffect } from "react";
import { useToggle } from "../../hooks/useToggle";
import { useLanguage } from "../../providers/LanguageProvider";
import { SubmitButton } from "../CMS/Common";
import Header from "../Header";
import { MultiLang } from "../MultiLang";
import { Checkbox } from "../Register";
import { ANALYTICS_ACCEPTED } from "../Warnings/CookieWarning";
import "./css/Cookies.css";
import { useIndexedDBGlobal } from "src/providers/IndexedDBProvider";

const lastMod = "Sat, 21 May 2022 06:53:38 GMT.";

const cookies = [
  {
    name: "username",
    purpose: {
      en: "Your session on the site, including what account you are logged into",
      sl: "Vaša seja na strani, vključno v kateri uporabniški račun ste prijavljeni",
    },
  },
  {
    name: "csrf",
    purpose: {
      en: "A random string used for Cross Site Request Forgery protection",
      sl: "Naključna koda, za zaščito pred prevarami",
    },
  },
  {
    name: "Age accepted",
    purpose: {
      en: "A set of cookies to remember your veirfy age choice",
      sl: "Piškotki, ki si zapomnejo vašo izbiro o potrjeni starosti",
    },
  },
  {
    name: "af",
    purpose: {
      en: "A set of cookies that tell us who reffered you to the page",
      sl: "Ime piškotka, ki nam pove, kdo vas je usmeril na našo spletno stran",
    },
  },
];

const additionalServices = [
  {
    name: "SecurionPay",
    purpose: {
      en: "Accept credit card payments and provide fraud risk analysis",
      sl: "Sprejem ter obdelava plačil preko kreditnih kartic",
    },
    link: "https://securionpay.com/docs/privacy",
  },
  {
    name: "Google Analytics",
    purpose: {
      en: "Measure site traffic and performance",
      sl: "Merjenje obiska spletne strani ter analitika",
    },
    link: "https://tools.google.com/dlpage/gaoptout",
  },
  {
    name: "Coingate",
    purpose: {
      en: "Accept Crypto payments and provide fraud risk analysis",
      sl: "Coingate	Sprejem ter obdelava crypto plačil",
    },
    link: "https://coingate.com/privacy",
  },
];

const CookiesPolicy = () => {
  const language = useLanguage();
  const db = useIndexedDBGlobal();
  const [sendAnalytics, toggleSendAnalytics] = useToggle(false);

  useEffect(() => {
    db.get(ANALYTICS_ACCEPTED, (data) => {
      toggleSendAnalytics(!!data);
    });
  }, [db]);

  const accept = () => {
    db.set(ANALYTICS_ACCEPTED, sendAnalytics);
  };

  return (
    <main id="cookies-policy">
      <div className="inner">
        <header>
          <h1>
            <strong>
              <MultiLang sl="Spicygaming.net Piškotki">
                Spicygaming.net Cookies Policy
              </MultiLang>
            </strong>
          </h1>
          <p>
            <MultiLang sl="">
              <em>This Cookies Policy was last modified on {lastMod}.</em>
            </MultiLang>
          </p>
        </header>

        <div className="content">
          <MultiLang sl="Zakon o elektronskih komunikacijah (Uradni list št. 109/2012), ZEKom-1 je v pravni red prinesel pravila glede uporabe piškotkov in podobnih tehnologij za shranjevanje informacij ali dostop do informacij, shranjenih na računalnikih, tablicah ali mobilnih napravah uporabnikov." />
          <p>
            <MultiLang sl="Zasebnost uporabnikov je za nas zelo pomembna, zato si prizadevamo, da uporabnikom ponudimo vse informacije o piškotkih, ki jih uporabljamo na spletni strani Spicygaming.net">
              A cookie is a small piece of data that allows your browser to
              maintain a session when visiting different pages on our website.
              If you use Spicygaming.net or any sites contained within our
              domain, spicygaming.net, then both first and third party cookies
              may be set when you access the site.
            </MultiLang>
          </p>
          <p>
            <MultiLang
              sl="S pomočjo piškotkov se na uporabnikovi napravi hranijo unikatni podatki o uporabniku oziroma nastavitve, ki si jih je na posameznem spletnem mestu izbral.
Pridobljene informacije in podatke bomo skrbno varovali ter jih uporabljali izključno za potrebe zagotavljanja spletne storitve, analizo uporabe, oglasnih sistemov in funkcionalnosti, ki jih brez piškotkov ne bi mogli nuditi.
"
            >
              This policy describes how spicygaming.net, and any third parties
              it embeds, use cookies, and what data those cookies may collect.
            </MultiLang>
          </p>
          <h2>
            <strong>
              <MultiLang sl="Uporaba Piškotkov">Cookie Usage</MultiLang>
            </strong>
          </h2>
          <p>
            <MultiLang sl="Uporaba piškotkov se deli na 2 kategoriji.">
              Our cookie usage falls into two categories:
            </MultiLang>
          </p>
          <p>
            <MultiLang
              sl={
                <>
                  <strong>Seja računa</strong>: Piškotki se uporabljajo za
                  prijavo v vaš uporabniški račun ter shranjevanje podatkov o
                  vaših nastavitvah računa. Dodatno se ti piškoti uporabljajo za
                  namene zagotavljanja varnosti kot npr. zaščita pred lažnimi
                  stranmi{" "}
                  <a href="https://www.owasp.org/index.php/Cross-Site_Request_Forgery_%28CSRF">
                    Cross Site Request Forgery
                  </a>
                </>
              }
            >
              <strong>Account Sessions</strong>: Cookies are used for logging
              into user accounts, and storing data about your preferences for
              browsing. Additionally, these cookies are used for security
              measures like (
              <a href="https://www.owasp.org/index.php/Cross-Site_Request_Forgery_%28CSRF">
                Cross Site Request Forgery
              </a>
              ) protection.
            </MultiLang>
          </p>
          <p>
            <MultiLang
              sl={
                <>
                  <strong>Analitični</strong>: Uporaba piškotkov v analitične
                  namene nam omogoča spremljanje uporabniških dejavnosti na
                  strani ter s tem lajša poslovne odločitve pri izdelevi spletne
                  strani ter produktov. Dodatno, del zgoščene statistike je
                  navoljo našim uporabnikom ter razvijalcem kot prikaz
                  dostopnosti strani ter produktov. To vključuje npr. število
                  obiskov strani, število prenesenih iger itd.
                </>
              }
            >
              <strong>Analytics</strong>: We use cookies to track usage activity
              in order to make business decisions and build a better product.
              Additionally, we make aggregate data available to the Publishers
              on our platform with information on how their products are
              accessed. This includes things like: referring pages, how many
              times their page was viewed, files that were downloaded, etc.
            </MultiLang>
          </p>
          <h2>
            <strong>
              <MultiLang sl="Kakšne informacije se hranijo o meni?">
                What Information is Collected About Me
              </MultiLang>
            </strong>
          </h2>
          <p>
            <MultiLang sl="Na splošno večino podatkov na strani zbiramo s pomočjo obrazcev. Nekaj podatkov pa se zbere tudi na podlagi vaših dejanj na strani. To vključuje podatke kot so vaše uporabniške nastavitve ter identifikacijski piškotki za posamezno sejo.">
              Generally, the data we collect from you is via form submission.
              Some data, though, is determined from your browsing history. This
              can include things like user preferences, and sessions
              identification tokens.
            </MultiLang>
          </p>
          <p>
            <MultiLang sl="Tukaj je seznam piškotkov nastavljenih iz strani spicygaming.net in njihov namen">
              Here is a list of the first-party cookies may be set by
              spicygaming.net, and what their purpose is.
            </MultiLang>
          </p>
          <table>
            <thead>
              <tr>
                <td>
                  <p>
                    <MultiLang sl="Ime piškotka">Cookie Name</MultiLang>
                  </p>
                </td>
                <td>
                  <p>
                    <MultiLang sl="Namen">Purpose</MultiLang>
                  </p>
                </td>
              </tr>
            </thead>
            <tbody>
              {cookies.map((cookie) => (
                <tr key={cookie.name}>
                  <td>
                    <p>{cookie.name}</p>
                  </td>
                  <td>
                    <p>{cookie.purpose[language]}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <p>
            <MultiLang sl="Dodatno, se lahko pojavijo piškotki naših partnejrev:">
              Additionally, the following third-party services may appear on
              some pages:
            </MultiLang>
          </p>
          <table>
            <thead>
              <tr>
                <td>
                  <p>
                    <MultiLang sl="Partner">Service</MultiLang>
                  </p>
                </td>
                <td>
                  <p>
                    <MultiLang sl="Namen">Purpose</MultiLang>
                  </p>
                </td>
                <td>
                  <p>
                    <MultiLang sl="Več">Learn More</MultiLang>
                  </p>
                </td>
              </tr>
            </thead>
            <tbody>
              {additionalServices.map((service) => (
                <tr>
                  <td>
                    <p>{service.name}</p>
                  </td>
                  <td>
                    <p>{service.purpose[language]}</p>
                  </td>
                  <td>
                    <a
                      href={service.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {service.link}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <h2>
            <strong>
              <MultiLang sl="Kako omejim uporabo piškotkov?">
                How Do I Restrict Cookies?
              </MultiLang>
            </strong>
          </h2>
          <p>
            <MultiLang sl="V kolikor ne želite sprejemati piškotkov naših partnerjev, lahko spremenite nastavitve vašega brskalnika. V nastavitvah določite, katere piškotke želite sprejemeti in katerih ne. Za pomoč se obrnite na navodila brskalnika.">
              If you do not wish to have cookies set by a third-party, you can
              adjust the settings on your internet browser to choose what
              cookies can be set. For more information, refer to the help
              section of your browser.
            </MultiLang>
          </p>
          <p>
            <MultiLang sl="Piškotki nastavljeni preko vašega brskalnika imajo omejen rok trajanja. V vsakem trenutku lahko izbrišete vse piškotke, ki so nastavljeni v vašem brskalniku vključno s podatki o vaši zgodovini brskanja po spletu. Pomembno to lahko vpliva na vašo uporabniško izkušnjo na strani.">
              Cookies set by your browser have an expiration date assigned to
              them. At any time you can explicitly remove these cookies from
              your browser to remove the data they contain about you. Keep in
              mind this may effect how your session functions.
            </MultiLang>
          </p>
          <div className="selections">
            <Checkbox
              label={
                <MultiLang sl="Pošlji analitične podatke">
                  Send data for analytics
                </MultiLang>
              }
              value={sendAnalytics}
              callback={toggleSendAnalytics}
              id="send-analytics"
            />
            <SubmitButton
              label={<MultiLang sl="Shrani">Save</MultiLang>}
              request={accept}
            />
          </div>

          <p>
            <MultiLang sl="V kolikor potrebujete več informacij nam prosim pišite na ">
              If you have any questions, please send us an email at
            </MultiLang>{" "}
            <a href="mailto:support@spicygaming.net">support@spicygaming.net</a>
          </p>
        </div>
        <Header searchBar={false} />
      </div>
    </main>
  );
};

export default CookiesPolicy;
