import React from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect } from "react-router-dom";
import { SubmitButton } from "../CMS/Common";
import useQuery from "../../hooks/useQuery";
import { CancelIcon } from "../common/social";
import "./callback-pages.css";
import Actions from "./Actions";

const PaymentCancel = () => {
  const query = useQuery();
  const orderID = query.get("order_id");

  if (!orderID) return <Redirect to="/" />;

  return (
    <main className="payment-cancel">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <h1>
        Your Order #{orderID} was Successfully
        <span className="red">Canceled</span>
      </h1>
      <div className="icon">
        <CancelIcon />
      </div>

      <Actions />
    </main>
  );
};

export default PaymentCancel;
