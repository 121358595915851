import { useContext } from "react";
import relativeDate from "relative-date";
import useSWRInfinite from "swr/infinite";
import { LoadMore, sort } from "../../Common";
import { fetcher } from "../Cms";
import { REVALIDATE_ON_FOCUS } from "../Common";
import { MainHeader } from "../Headers";
import { RoleContext } from "../RoleContext";
import Table, { getColumn } from "../Table/Table";
import { developeTeamsOptions } from "./Options";
import { useHistory } from "react-router-dom";
import SubmitButton from "src/v2/components/SubmitButton";

const DeveloperTeams = ({ subHeader, toggler }) => {
  const history = useHistory();
  const { role } = useContext(RoleContext);

  const { data, error, setSize, mutate, isValidating } = useSWRInfinite(
    (index) => {
      return `${window.domainName}/api/content-creator-teams/list?page=${
        index + 1
      }`;
    },
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );

  const teams = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];

  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  const columns = [
    getColumn("TEAM NAME", { sortKey: "name", accessKey: "name" }),
    getColumn("TEAM LEADER", { sortKey: "leader", accessKey: "leader" }),
    getColumn("MEMBERS", {
      sortKey: "no_of_members",
      accessKey: "no_of_members",
    }),
    getColumn("GAMES", { sortKey: "no_of_games", accessKey: "no_of_games" }),
    getColumn("DATE CREATED", {
      sortKey: "creation_date",
      accessFn: (team) => relativeDate(new Date(team.creation_date)),
    }),
  ];
  const search = async (query) => {
    const res = await fetcher(
      `${window.domainName}/api/content-creator-teams/cms/search?search_term=${query}`
    );

    // const res = await req.data;
    return res.flat().filter((obj) => !(obj.error || obj.last_page));
  };
  const searchCallback = (data) => {
    mutate(data, false);
  };

  const redirectToCreateTeam = () => {
    history.push("/cms/personal/teams/create-team");
  };

  return (
    <div className="body">
      <MainHeader
        toggler={toggler}
        header={subHeader}
        element={"search"}
        uploadText="Create a team"
        uploadLink={window.location.pathname + "/create-team"}
        search={{
          func: search,
          callback: searchCallback,
        }}
      />

      {teams.length === 0 && !isValidating ? (
        <div className="m-auto grid place-items-center gap-5">
          <div className="fa fa-users text-6xl mt-5" />
          <p>You don't Have any teams yet</p>
          <div>
            <SubmitButton onClick={redirectToCreateTeam}>
              Create your First Team
            </SubmitButton>
          </div>
        </div>
      ) : (
        <div className="content">
          <Table
            columns={columns}
            data={teams}
            getOptions={(team) => {
              const team_role =
                team.team_role === "Member" ? undefined : team.team_role;
              return developeTeamsOptions(team_role || role);
            }}
            getPopupData={(team) => {
              const team_role =
                team.team_role === "Member" ? undefined : team.team_role;
              return {
                default_team: { id: team.id, name: team.name },
                members: {
                  team: team.name,
                  id: team.id,
                  team_role,
                  logged_in_user_role: role,
                },
                games: {
                  team: team.name,
                  id: team.id,
                  role: team.team_role,
                  user_role: role,
                },
                delete: {
                  name: team.name,
                  members: team.no_of_members,
                  games: team.no_of_games,
                  created: team.creation_date,
                  id: team.id,
                },
                leave_team: {
                  name: team.name,
                  id: team.id,
                },
                edit: { id: team.id },
                invite_creator: { id: team.id },
                all_invites: { id: team.id },
                withdraw: { id: team.id },
                sign: { id: team.id },
                affiliate: { id: team.id },
                affiliate_links: { id: team.id },
                transactions: { id: team.id, name: team.name },
                see_team: {
                  username: team.name,
                },
                approve: {
                  id: team.id,
                  approve: !team.approved,
                  name: team.name,
                },
                ad_zone: {
                  name: team.name,
                  id: team.id,
                },
              };
            }}
            refreshData={() => mutate(teams, true)}
            onSort={(key, order) => mutate(sort(key, teams, order), false)}
            indicators={[
              {
                color: "#01be01",
                when: (team) => team.approved,
                name: "Team Verified",
              },
              {
                color: "#00b4f2",
                when: (team) => team.team_role === "Leader",
                name: "You're Leader of the team",
              },
            ]}
          />
          {errors && <div className="error">{errors.error}</div>}
          {error && <div className="error">{error.message}</div>}
          <LoadMore onClick={() => setSize((prev) => prev + 1)} />
        </div>
      )}
    </div>
  );
};

export default DeveloperTeams;
