import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_CDN_URL, BASE_URL } from "src/utils/Axios";

import Error404 from "../Errors";
import ArticleDetails from "./ArticleDetail";
import { LoaderLayout } from "./BlogArticle";
import updateViewed from "src/utils/updateViewed";

function NewsArticle({ match, preview = false }) {
  const articleID = match.params.article;
  const [article, setArticle] = useState({});
  const [comments, setComments] = useState([]);
  const [author, setAuthor] = useState({});

  useEffect(() => {
    request(`/news/api/news/game-news?article_id=${articleID}`, setArticle);
    request(`/news/api/news/game-news/${articleID}/comments`, setComments);
    updateViewed(articleID, "Game News");
  }, [articleID]);

  useEffect(() => {
    if (article.author && !article.error) {
      request(
        `/api/members/public-profile?username=${article.author}`,
        setAuthor
      );
    }
  }, [article]);

  const request = async (url, state) => {
    try {
      state({});
      const req = await axios({
        method: "get",
        url: url,
        baseURL: preview ? BASE_URL : BASE_CDN_URL,
        params: { preview },
      });
      const res = await req.data;
      state(res);
    } catch {}
  };

  if (article.error) return <Error404 customMessage={article.error} />;
  if (!article.id) {
    return <LoaderLayout />;
  }

  return (
    <ArticleDetails
      article={article}
      comments={comments}
      author={author}
      type="news"
    />
  );
}

export default NewsArticle;
