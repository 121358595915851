import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useGamesMetaData } from "src/components/AdultGames/MetaDataProvider";
import { SubmitButton } from "src/components/CMS/Common";
import { MultiLang } from "src/components/MultiLang";
import PaymentPopup from "src/components/Payments/Popup";
import { generateURL } from "src/components/resizeImages";
import { CONVERSION_RATE } from "src/constants/store";
import { authRequest } from "src/utils/Axios";
import { Avatar } from "src/components/common/Avatar";
import { Discord, Itchio, Patreon, Social, Subscribestar } from "../social";
import "./style.css";
import { useSpicyStore } from "src/providers/SpicyStoreProvider";
import FeatureFlag from "src/components/FeatureFlag";
import { Features } from "src/features/allFeatures";

const SideCreatorInfo = ({ team, extraInfo }) => {
  const [showTipPopup, setShowTipPopup] = useState(false);
  const toggleTipPopup = () => setShowTipPopup((prev) => !prev);
  const { refresh } = useSpicyStore();

  const { followed, updateMetaData } = useGamesMetaData();

  const isFollowed = followed?.includes(team.id);

  const onPayTokens = async (amount) => {
    const res = await authRequest({
      url: "/store/api/teams/donate",
      method: "POST",
      data: {
        team_id: team.id,
        token_amt: amount,
      },
    });
    await refresh();

    if (res.success) return `You've Successfully donated to ${team.name}`;
  };

  const followTeam = async () => {
    try {
      await authRequest({
        url: "/api/members/follow-team",
        method: "POST",
        params: {
          team_id: team.id,
        },
      });

      await updateMetaData();
    } catch {
      toast.error("Couldn't subscribe. Try again later.");
    }
  };

  return (
    <section
      id="game-detail"
      className="game-detail-game SideCreatorInfo"
      data-testid="SideCreatorInfo"
    >
      <div className="dev-info">
        <Link to={`/team/@${team.name}`}>
          <Avatar
            src={generateURL(100, 100, team?.profile_picture)}
            name={team.name}
            width={100}
          />
        </Link>

        <div className="details">
          <Link to={`/team/@${team.name}`}>
            <h3 className="name line" title={team.name}>
              {team.name}
              {team.approved && (
                <span className="material-icons" title="Verified Team">
                  verified
                </span>
              )}
            </h3>
          </Link>
          <div className="row">
            <div className="title">
              <MultiLang sl="Družabne povezave:">Social Links:</MultiLang>
            </div>
            <div className="links">
              <Social path={team.facebook_link} fa_name="fa-facebook" />
              <Social path={team.twitter_link} fa_name="fa-twitter" />
              <Social path={team.instagram_link} fa_name="fa-instagram" />
              <Social path={team.website_link} fa_name="fa-globe" />
              <Social path={team.discord_link}>
                <Discord />
              </Social>
            </div>
          </div>
          <div className="row">
            <div className="title">
              <MultiLang sl="Povezave za podporo:">Support Links:</MultiLang>
            </div>
            <div className="links">
              <Social path={team.patreon_link} fa_name="">
                <Patreon />
              </Social>
              <Social path={team.steam_link} fa_name="fa-steam" />
              <Social path={team.itchio_link}>
                <Itchio />
              </Social>
              <Social path={team.subscribestar_link}>
                <Subscribestar />
              </Social>
            </div>
          </div>
        </div>
      </div>
      {extraInfo}
      <div className="buttons">
        <SubmitButton
          icon={isFollowed ? "fa-user" : "fa-user-plus"}
          label={isFollowed ? "Unsubscribe" : "Subscribe"}
          type={isFollowed ? "secondary" : "primary"}
          request={followTeam}
        />
        {team.approved && (
          <FeatureFlag feature={Features.TOKEN_TRANSACTION}>
            <SubmitButton
              request={toggleTipPopup}
              label={"Support the Author"}
              icon="fa-user"
              type="secondary"
            />
          </FeatureFlag>
        )}
      </div>

      <PaymentPopup
        initialPrice={100 / CONVERSION_RATE}
        minimumPrice={100 / CONVERSION_RATE}
        show={showTipPopup}
        onClose={toggleTipPopup}
        title={`Support ${team.name}`}
        description="Tip"
        supportMsg="Support the developer by tipping"
        onPayTokens={onPayTokens}
      />
    </section>
  );
};

export default SideCreatorInfo;
