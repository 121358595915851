import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useCheckForURLBlock from "src/hooks/useCheckForURLBlock";
import { useIndexedDBGlobal } from "src/providers/IndexedDBProvider";
import { SubmitButton } from "../CMS/Common";

const KEY = "cookie_accepted";
export const ANALYTICS_ACCEPTED = "analytics_accepted";

function CookieWarning() {
  const renderedInEmbed = useCheckForURLBlock("embed");

  const db = useIndexedDBGlobal();

  const [accepted, setAccepted] = useState(true);
  useEffect(() => {
    db.get(KEY, (data) => {
      setAccepted(!!data);
    });

    db.get(ANALYTICS_ACCEPTED, (data) => {
      window.ANALYTICS_ACCEPTED = !!data;
    });
  }, [db]);
  const accept = () => {
    db.set(KEY, true);
    db.set(ANALYTICS_ACCEPTED, true);
    setAccepted(true);
  };

  if (renderedInEmbed) return null;
  if (accepted) return null;
  return (
    <div className="cookie-warning" data-nosnippet="data-nosnippet">
      <div className="text">
        <h2>We use cookies</h2>
        <p>
          Cookies help us deliver the best experience on our website. By using
          our website, you agree to the use of cookies.
        </p>
      </div>
      <p>
        <Link to="/cookies-policy">Cookie policy and Settings</Link>
      </p>

      <SubmitButton id="cookie-warning" label="Accept" request={accept} />
    </div>
  );
}

export default CookieWarning;
