import { Suspense, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Header from "src/components/AdultGames/Header";
import { PaginationSlider } from "src/components/Article/Slider";
import Banner from "src/components/Banners";
import { Chip } from "src/components/Chip";
import { LoaderInside } from "src/components/Common";
import { MiniPlaylist } from "src/components/Playlists/MiniPlaylist";
import { useToggle } from "src/hooks/useToggle";
import { BASE_CDN_URL, request } from "src/utils/Axios";
import { withoutLastPage } from "src/utils/withoutLastPage";
import { Player } from "../Player";
import VideoSearchSidebar from "./Sidebar";
import "./style.css";
import SuggestionPanel from "../FullPage/SuggestionChips";

const VideoSearchPage = () => {
  const { query, page, tag: currentTag } = useParams();
  const [searchQuery, setSearchQuery] = useState(query || "");
  const sortConfig = useRef({ key: undefined, order: undefined });
  const [lastPage, setLastPage] = useState(1);
  const [result, setVideos] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const history = useHistory();
  const username = localStorage.getItem("username");
  const [tags, setTags] = useState([]);
  const [showSidebar, toggleSidebar] = useToggle();

  const currentPageNumber = parseInt(page) || 1;

  useEffect(() => {
    searchVideos(query);
  }, [query, currentTag, currentPageNumber]);

  useEffect(() => {
    getTags();
  }, []);

  const getActions = (video) => {
    let act = ["add_to_watch_later"];
    if (username === video.uploader) act.push("edit");

    return act;
  };
  const sortVideos = (params) => {
    searchVideos(query, params);
  };

  const searchVideos = async (query, params) => {
    try {
      setLoading(true);
      const res = await request({
        url: "/community/community-videos/list",
        params: {
          page: currentPageNumber,
          search_term: query,
          ...params,
          tags: currentTag,
        },
      });
      setVideos(withoutLastPage(res));
      setLastPage(res.find((a) => !!a.last_page)?.last_page);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const searchFunction = () => {
    if (!!searchQuery.length) {
      history.push(`/videos/${searchQuery}/1`);
    } else {
      toast.error("Type query to search");
    }
  };

  const getTags = async () => {
    try {
      const res = await request({
        url: "/products/api/game-filters",
        // baseURL: BASE_CDN_URL,
        params: { title: "video tags" },
      });

      setTags(res.option);
    } catch (error) {}
  };

  return (
    <main
      id="adult-games"
      className="adult-games SearchPage"
      data-testid="SearchPage"
    >
      <Helmet>
        <title>Porn Game Videos</title>
        <meta
          name="description"
          content="Porn Game Videos: Gameplays, trailers, walkthroughs, guides, reviews"
        />
        <link rel="canonical" href="https://www.spicygaming.net/videos/" />
      </Helmet>
      <div className="inner">
        <Header
          title="Porn Game Videos"
          searchFunction={searchFunction}
          setSearchQuery={(e) => setSearchQuery(e.target.value)}
          searchQuery={query}
          sort={sortVideos}
          sortConfig={sortConfig}
          customButton={
            <button className="btn toggle-sidebar" onClick={toggleSidebar}>
              <i className="fa fa-bars fa-lg"></i>
            </button>
          }
        />

        <SuggestionPanel suggestionData={tags} className="tags">
          {(tag) => {
            const tagsURL = `/videos/tag/${tag.name}/`;
            return (
              <Link to={tagsURL}>
                <Chip
                  selected={tag.param === currentTag}
                  className="tag"
                  key={tag.param}
                >
                  {tag.name}
                </Chip>
              </Link>
            );
          }}
        </SuggestionPanel>
        <div className="body">
          <VideoSearchSidebar
            showSidebar={showSidebar}
            closeSidebar={toggleSidebar}
          />
          <div className="results">
            {isLoading ? (
              <LoaderInside />
            ) : !!result.length ? (
              <>
                <TransitionGroup
                  component="div"
                  className="search_results_content"
                >
                  {result?.map((item, i) => (
                    <CSSTransition
                      timeout={300}
                      in={true}
                      mountOnEnter
                      classNames="popup"
                      key={item.id}
                    >
                      <Suspense fallback={<LoaderInside />}>
                        {item.type === "Playlist" ? (
                          <MiniPlaylist playlist={item} />
                        ) : (
                          <Player
                            actions={getActions(item)}
                            video={item}
                            lazyLoadImage={i >= 5}
                          />
                        )}
                      </Suspense>
                    </CSSTransition>
                  ))}
                  <Banner
                    jsAd="95324"
                    id="video_search"
                    width={300}
                    height={250}
                  />
                </TransitionGroup>
                <PaginationSlider
                  initial_page_number={currentPageNumber}
                  total_number_of_pages={lastPage}
                />
              </>
            ) : (
              query && <div className="not-found">No Videos found :(</div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default VideoSearchPage;
