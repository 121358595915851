import React from "react";
import { getClass } from "src/utils/getClass";
 
const Input = React.forwardRef(
  ({ label, error, id, className, ...inputProps }, ref) => {
    return (
      <input
        className={getClass(
          "bg-neutral-800 border border-neutral-400 rounded py-4 px-6 w-full focus:ring outline-none focus:ring-neutral-600 text-white",
          error && "border-red-600 focus:ring-red-600/60",
          className
        )}
        placeholder="Type here ..."
        {...inputProps}
        id={id}
        ref={ref}
      />
    );
  }
); 

export default Input;
