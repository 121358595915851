import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import { getPublicFeaturedContent } from "../CMS/Ads/FeaturedListPages/util";
import { LoaderInside } from "../Common";
import { Mini } from "./DontMissComponents";
import "./css/DontMiss.css";

const DontMissSection = ({ position, title, sectionRedirectLink }) => {
  const [objects, setObjects] = useState([]);

  useEffect(() => {
    getObjects();
  }, []);
  const getObjects = async () => {
    try {
      const res = await getPublicFeaturedContent(position);
      setObjects(res);
    } catch {}
  };

  return (
    <section
      aria-labelledby="dont_miss_section"
      className="dont-miss adult-games news"
    >
      <div className="new">
        <div className="head">
          <h2 id="dont_miss_section" className="title">
            {sectionRedirectLink ? (
              <Link to={sectionRedirectLink}>{title}</Link>
            ) : (
              title
            )}
          </h2>
        </div>

        <div className="outer article">
          <div className="body">
            {!!objects.length ? (
              <OwlCarousel
                className="owl-theme"
                dots
                loop
                autoplay
                autoplayHoverPause
                autoplaySpeed={1500}
                autoplayTimeout={5000}
                lazyLoad={true}
                responsive={{
                  0: { items: 1 },
                  840: { items: 3 },
                  1440: { items: 4 },
                }}
                margin={30}
              >
                {objects.map((object) => (
                  <Mini
                    content={object}
                    key={object.metadata.type + object.metadata.id}
                  />
                ))}
              </OwlCarousel>
            ) : (
              <LoaderInside />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DontMissSection;
