import React, { useEffect, useRef } from "react";
import "./style.css";
import loadScript from "src/utils/loadScript";
import { formatDuration } from "src/utils/number-format";

const PlaylistPlayer = ({
  thumbnailName,
  vastZone,
  playlistPosition,
  playADs = true,
  videos = [],
  options = {},
}) => {
  const ref = useRef();
  let player = useRef(null);

  useEffect(() => {
    loadScript("/videojs/video.min.js", "videojs")
      .then(() => loadScript("/videojs/nuevo.min.js", "nuevo"))
      .then(() => loadScript("/videojs/playlist.js", "playlist"))
      .then(() => {
        player.current = window.videojs(
          ref.current,
          {
            debug: true,
            poster: thumbnailName,
            ...options,
          },
          function () {
            // Load Vast/Vpaid plugin and initialize ad
            if (!vastZone) return;
            if (!playADs) return;
            loadScript("/videojs/plugins/vastpro.js", "vast_ad").then(() => {
              player.current.vastAds({
                map: [
                  {
                    tagURL: `https://srv.aso1.net/vast?z=${vastZone}`,
                    timeOffset: "start",
                    id: 102898,
                  },
                  {
                    tagURL: "https://srv.aso1.net/vast?z=102902",
                    timeOffset: "00:04:00",
                    id: 102902,
                  },
                  {
                    tagURL: "https://srv.aso1.net/vast?z=102903",
                    timeOffset: "00:08:00",
                    id: 102903,
                  },
                  {
                    tagURL: "https://srv.aso1.net/vast?z=102904",
                    timeOffset: "00:12:00",
                    id: 102904,
                  },
                ],
              });
            });
          }
        );
        player.current.nuevo({
          playlistID: playlistPosition,
          playlistMaxH: 350,
          playlistUI: true, // set to disable playlist UI completely
          playlistShow: true, // set to hide playlist UI on start
          playlistAutoHide: true, // Disable playlist UI autohide on video play event
          playlistNavigation: true, // set to show playlist navigation arrows
          playlistRepeat: false, // set to repeat playlist playback
        });

        player.current.playlist(
          videos.map((video) => ({
            sources: [
              {
                src: `https://${video.pullzone}.b-cdn.net/${video.id}/playlist.m3u8`,
                type: "application/x-mpegURL",
              },
            ],
            duration: formatDuration(video.length),
            thumb: video.thumbnail,
            title: video.title,
            description: video.description,
          }))
        );
      });

    return () => {
      player.current.dispose();
    };
  }, [videos]);

  return (
    <div className="video-player">
      <div data-vjs-player>
        <video ref={ref} preload="auto" className="video-js vjs-fluid"></video>
      </div>
    </div>
  );
};

export default PlaylistPlayer;
