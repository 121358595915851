import React, { Suspense, useState } from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { toast } from "react-toastify";
import { useToggle } from "../../hooks/useToggle";
import { useLanguage } from "../../providers/LanguageProvider";
import { authRequest } from "../../utils/Axios";
import { SubmitButton } from "../CMS/Common";
import { LoaderInside } from "../Common";
import { MultiLang } from "../MultiLang";
import "./css/FavGames.css";
import FavGamePlayed from "./FavGamePlayed";
import FavGamePlaying from "./FavGamePlaying";
import FavGameWishlisted from "./FavGameWishlisted";
import SentryRoute from "src/customRoute";

const Error404 = React.lazy(() => import("../Errors"));

export const MAX_ELEMS_IN_SLIDE = 6;

export const SECTIONS = {
  playing: "Playing",
  played: "Played",
  wishlisted: "Wishlisted",
};

export const removeFromState = (id, setState) => () =>
  setState((prev) => prev.filter((game) => game.id !== id));

export const removeGame = async (id, section, callBack = () => null) => {
  try {
    const res = await toast.promise(
      authRequest({
        url: `/api/members/gamer-profile/remove-game`,
        params: {
          game_id: id,
          game_list: section,
        },
        method: "post",
      }),
      {
        pending: "Removing Game...",
        success: "Successfully removed game",
        error: "Error occured while removing game",
      }
    );

    if (res.success) callBack();
  } catch (error) {}
};
export const moveGame = async (
  id,
  { from = "", to = "" },
  callBack = () => null
) => {
  try {
    const res = await toast.promise(
      authRequest({
        url: `/api/members/gamer-profile/move-game`,
        params: {
          game_id: id,
          from,
          to,
        },
        method: "post",
      }),
      {
        error: "Error occured while moving game",
        success: `Successfully moved game from ${from} to ${to}`,
        pending: "Moving game...",
      }
    );

    if (res.success) callBack();
  } catch (error) {}
};

const FavGames = ({ match }) => {
  const [open, toggleOpen] = useToggle();

  const navLinks = [
    {
      link: match.path + "/playing",
      Component: FavGamePlaying,
      label: { en: "PLAYING", sl: "Igram" },
    },
    {
      link: match.path + "/played",
      Component: FavGamePlayed,
      label: { en: "PLAYED", sl: "Preigrano" },
    },
    {
      link: match.path + "/wishlisted",
      Component: FavGameWishlisted,
      label: { en: "WISHLISTED", sl: "Zaželjeno" },
    },
  ];

  return (
    <div className="favourite-games">
      <NavBar navLinks={navLinks} toggleOpen={toggleOpen} />

      <div className="body">
        <Switch>
          <Redirect exact path={match.url} to={navLinks[0].link} />
          {navLinks.map((link) => (
            <SentryRoute path={link.link} exact key={link.link}>
              <link.Component open={open} onClose={toggleOpen} />
            </SentryRoute>
          ))}

          <SentryRoute path={match.url + "*"}>
            <Suspense fallback={<LoaderInside />}>
              <Error404 />
            </Suspense>
          </SentryRoute>
        </Switch>
      </div>
    </div>
  );
};

const NavBar = ({ navLinks, toggleOpen }) => {
  const language = useLanguage();
  const [btnText, setBtnText] = useState(navLinks[0]?.label[language]);
  return (
    <nav>
      <div className="links">
        {navLinks.map((link) => (
          <NavLink activeClassName="active" key={link.link} to={link.link}>
            <div className="section">
              <div className="title">
                <MultiLang {...link.label} />
              </div>
            </div>
          </NavLink>
        ))}
      </div>
      <div className="dropdown">
        <div className="dropbtn">{btnText}</div>
        <div className="content">
          {navLinks.map((link) => (
            <NavLink
              activeClassName="active"
              to={link.link}
              onClick={() => setBtnText(link.label[language])}
            >
              <MultiLang {...link.label} />
            </NavLink>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default FavGames;
