import React, { createContext, useContext, useEffect, useState } from "react";

export const WidthContext = createContext();

const WIDTH_THRESHOLD = 840;

export const WidthProvider = (props) => {
  const [isMobile, setIsMobile] = useState(
    () => window.innerWidth <= WIDTH_THRESHOLD
  );

  useEffect(() => {
    window.addEventListener("resize", onWidthChange);

    return () => {
      window.removeEventListener("resize", onWidthChange);
    };
  }, []);

  const onWidthChange = () => {
    const width = window.outerWidth;

    if (width <= WIDTH_THRESHOLD) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  return (
    <WidthContext.Provider value={isMobile}>
      {props.children}
    </WidthContext.Provider>
  );
};

export const useMobile = () => useContext(WidthContext);
