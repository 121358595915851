import React, { useState } from "react";
import relativeDate from "relative-date";
import { LoaderInside } from "src/components/Common";
import { authorizedRequest } from "src/components/Validation";
import useSWR from "swr";
import dateFormat from "dateformat";
import { teamGamesOptions } from "../Options";
import { ApproveTile } from "../../Tile";
import { REVALIDATE_ON_FOCUS, SubmitButton } from "../../Common";
import { useHistory } from "react-router";
import { EmptyView } from "src/components/UtilityComponents/EmptyView";

const TeamGames = ({ teamRole, userRole, id }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const headers = ["TITLE", "CREATOR", "LAST UPDATED", "CREATION DATE"];

  const fetcher = async (url) => {
    setLoading(true);
    const res = await authorizedRequest({ url, method: "GET" }, []);
    setLoading(false);

    return res;
  };

  const { data, mutate } = useSWR(
    `${window.domainName}/api/content-creator-teams/games?team_id=${id}`,
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );

  const games = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];

  const gotoCreateGame = () => {
    history.push("/cms/content/upload-game/info");
  };

  if (loading) return <LoaderInside />;

  return (
    <div id="content-creator" className="team-view TeamGames">
      <main className="popup-tile">
        <SubmitButton
          className="action-button"
          label={"Create Game"}
          request={gotoCreateGame}
        />
        <br />
        <div
          className="header"
          style={{
            gridTemplateColumns: `repeat(${headers.length}, var(--game-item-frac)) 2fr`,
          }}
        >
          {headers.map((header, i) => (
            <div className="item" key={i}>
              {header}
            </div>
          ))}
        </div>
        <div className="body ">
          {games.length <= 0 && <EmptyView text={"No Games yet."} />}
          {games.map((game, i) => {
            const data = [
              game.title,
              game.creator,
              relativeDate(new Date(game.last_updated)),
              dateFormat(new Date(game.creation_date), "longDate"),
            ];
            const commonProps = {
              title: game.title,
              creator: game.creator,
              created: game.creation_date,
              id: game.id,
            };

            const options = teamGamesOptions(teamRole, userRole, game);
            return (
              <ApproveTile
                headers={headers}
                key={i}
                data={data}
                revalidate={() => mutate(games, true)}
                options={options}
                isBanned={!game.published}
                popupData={{
                  edit: { id: game.id },
                  publish: commonProps,
                  delete: commonProps,
                  approve: commonProps,
                }}
              />
            );
          })}
        </div>
      </main>
    </div>
  );
};
export default TeamGames;
