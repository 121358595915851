import { z } from "zod";
import { authRequest, request } from "./Axios";

/**
 * @template {z.Schema} T
 * @param {T} zSchema
 * @param {z.infer<T>} obj
 * @returns {z.infer<T>}
 */
export function validateWith(zSchema, obj) {
  try {
    const parsedObject = zSchema.parse(obj);
    return parsedObject;
  } catch (error) {
    if (error instanceof z.ZodError) {
      throw new Error(error.errors[0].message);
    } else {
      throw error;
    }
  }
}

/**
 * This will return reshaped object according to the schema provided. no errors will be made.
 * @template {z.Schema} T
 * @param {T} zSchema
 * @param {z.infer<T>} obj
 * @returns {z.infer<T>}
 */
export function shapeAs(zSchema, obj) {
  const shapedObject = {};

  // Iterate through each field in the schema
  for (const [key, field] of Object.entries(zSchema.shape)) {
    // If the field exists in the provided object, use its value
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      // If the field is an object, recursively shape it
      if (field._def.typeName === z.ZodObject.name) {
        shapedObject[key] = shapeAs(field, value);
      } else {
        shapedObject[key] = value;
      }
    }
    // If the field is missing in the provided object, set default value if available
    else if ("defaultValue" in field._def) {
      shapedObject[key] = field._def.defaultValue();
    }
    // If no default value is set in the schema, set default based on type
    else {
      switch (field._def.typeName) {
        case z.ZodString.name:
          shapedObject[key] = "";
          break;
        case z.ZodNumber.name:
          shapedObject[key] = 0;
          break;
        case z.ZodBoolean.name:
          shapedObject[key] = false;
          break;
        case z.ZodArray.name:
          shapedObject[key] = [];
          break;
        case z.ZodNullable.name:
          shapedObject[key] = null;
          break;
        case z.ZodObject.name:
          shapedObject[key] = shapeAs(field, {});
          break;
        default:
          shapedObject[key] = null;
      }
    }
  }

  return shapedObject;
}

/**
 * @template {z.Schema} T
 * @param {string} url
 * @param {T} responseSchema
 * @param {import("axios").AxiosRequestConfig} options
 * @returns {z.infer<T>}
 */
export const safeRequest = async (url, responseSchema, options = {}) => {
  const res = await request({
    url,
    ...options,
  });
  return shapeAs(responseSchema, res);
};

/**
 * @template {z.Schema} T
 * @param {string} url
 * @param {T} responseSchema
 * @param {import("axios").AxiosRequestConfig} options
 * @returns {z.infer<T>}
 */
export const safeAuthRequest = async (url, responseSchema, options = {}) => {
  const res = await authRequest({
    url,
    ...options,
  });
  return shapeAs(responseSchema, res);
};
