import { isDev } from "../utils/Axios";

const DOMAIN = isDev
  ? "https://d31yborzko8wvf.cloudfront.net"
  : "https://media.spicygaming.net";

export const generateURL = (width, height, path) => {
  if (!path) return "";

  const ASPECT_RATIO = width / height;
  const maxWidth = window.innerWidth * 1;

  // only function argument can be used to define minimum width
  // smaller than 480px
  const minWidth = width < 480 ? width : 480;
  const finalWidth = parseInt(
    maxWidth < minWidth
      ? minWidth
      : maxWidth > width || minWidth > width
      ? width
      : maxWidth
  );
  const finalHeight = parseInt(finalWidth / ASPECT_RATIO);

  return `${DOMAIN}/fit-in/${finalWidth}x${finalHeight}/filters:no_upscale()/${path}`;
};

export const generateOriginalURL = (path) => `${DOMAIN}/${path}`;
