import { createContext, useContext, useEffect, useState } from "react";

import { usePostHog } from "posthog-js/react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { SubmitButton } from "src/components/CMS/Common";
import GTags from "src/components/GTags";
import { Input } from "src/components/Member/General";
import Popup from "src/components/common/Popup";
import currencies from "src/constants/currencies";
import { CONVERSION_RATE } from "src/constants/store";
import RequestError from "src/errors/RequestError";
import { useToggle } from "src/hooks/useToggle";
import { authRequest, request } from "src/utils/Axios";
import round2Decimal from "src/utils/to2Decimal";
import { useSWRConfig } from "swr";

const SpicyStoreContext = createContext({
  tokens: 0,
  loading: false,
  tokenPackages: [],
  localizePrice(_priceInEUR) {},
  localPriceToEuro(_price) {},
  async payWithSpicyTokens(_amountSG, _type, _productID) {},
  async refresh() {},
  async getOrderInfo(_orderId) {},
  savePurchaseRecord(_orderInfo) {},
});

export class SpicyStoreError extends Error {
  constructor(message, retryFn) {
    super();
    this.message = message;
    this.retry = retryFn;
  }
}

const SpicyStoreProvider = ({ children }) => {
  const history = useHistory();
  const posthog = usePostHog();

  const [tokenPackages, setTokenPackages] = useState([]);
  const [tokens, setTokens] = useState(0);

  const [loading, toggleLoading] = useToggle();
  const [localCurrency, setLocalCurrency] = useState({ id: "EUR", rate: 1 });

  // mfa validation
  const [orderID, setOrderID] = useState(null);
  const [showMFAPopup, toggleMFAPopup] = useToggle();
  const [mfaCode, setMfaCode] = useState(null);
  const [error, setError] = useState(null);
  const swr = useSWRConfig();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      await Promise.all([
        getTokenPackages(),
        getCurrencyRates(),
        getTokensOfUser(),
        swr.mutate("brought-products", [], true),
      ]);
    } catch (error) {}
  };

  const getTokenPackages = async () => {
    try {
      toggleLoading();
      const res = await request({
        url: `/store/api/packages/list`,
        withCredentials: true,
      });
      setTokenPackages(res);
    } catch {
    } finally {
      toggleLoading();
    }
  };

  const getCurrencyRates = async () => {
    try {
      const res = await authRequest({
        url: "/store/api/members/local-rates",
        params: {
          metadata: true,
        },
      });
      setLocalCurrency({
        rate: round2Decimal(res.local_rate),
        id: res.local_currency,
      });
    } catch (error) {}
  };

  const getTokensOfUser = async () => {
    try {
      const res = await authRequest({
        url: `/store/api/members/tokens`,
      });
      setTokens(res.tokens);
    } catch {}
  };

  const localizePrice = (amountInEUR) => {
    const currency = currencies.find((c) => c.id === localCurrency.id);
    const localPrice = round2Decimal(amountInEUR * localCurrency.rate);

    return {
      price: localPrice,
      symbol: currency?.symbol,
      id: localCurrency.id,
    };
  };
  const localPriceToEuro = (amount) => {
    return parseFloat(amount) / localCurrency.rate;
  };

  const payWithSpicyTokens = async (amountSG, type, productID) => {
    try {
      const res = await authRequest(
        {
          url: "/store/api/members/cart/individual-checkout",
          method: "POST",
          data: {
            checkout_method: "tokens",
            data: {
              product_type: type,
              product_id: productID,
              price: amountSG / CONVERSION_RATE,
            },
          },
        },
        true
      );

      if (res.mfa_sent) {
        validateWithMFA(res.order_id);
        // throwing error to prevent further execution. this error is handled in the <PaymentPopup />
        throw new SpicyStoreError(
          "Order is pending. Enter Approval Code.",
          toggleMFAPopup
        );
      }

      await init();
      return { ...res, success: true };
    } catch (error) {
      if (
        error.response?.data?.code === "INVALID_DISCOUNT" ||
        error.response?.data?.code === "PRICE_CHANGED"
      ) {
        window.alert(
          "This Sale or Offer has expired. We will refresh the page to get updated pricing."
        );
        window.location.reload();
      }
      throw new Error(error.response?.data?.error ?? error.error);
    }
  };

  const validateWithMFA = (orderID) => {
    setOrderID(orderID);
    toggleMFAPopup();
  };

  const sendMFACode = async () => {
    try {
      if (!orderID) throw new Error("No Order Id defined");
      if (!mfaCode) throw new Error("Please Enter the code before proceed");
      const res = await authRequest({
        url: "/store/api/token-order/individual-checkout",
        method: "POST",
        data: {
          order_id: orderID,
          approval_code: mfaCode,
        },
      });

      if (res.success) {
        toggleMFAPopup(false);
        setOrderID(null);
        setMfaCode(null);
        await init();
        toast.success("Your Purchase was successfull");
        history.push("/library/purchased-games");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const resendCode = async () => {
    try {
      if (!orderID) return;
      const res = await authRequest({
        url: "/store/api/token-order/resend-2fa",
        method: "POST",
        data: {
          order_id: orderID,
        },
      });
      if (res.success) setError("Code resent.");
    } catch (error) {
      setError(error.message);
    }
  };

  const getOrderInfo = async (orderID) => {
    const info = await authRequest({
      url: `/store/api/orders/status`,
      params: {
        order_id: orderID,
      },
    });
    return info;
  };

  const savePurchaseRecord = (orderInfo) => {
    const record = {
      transaction_id: orderInfo.order_id,
      value: parseFloat(orderInfo.amount),
      tax: parseFloat(orderInfo.vat_amount),
      items: orderInfo.transactions?.map((t) => ({
        item_id: t.product_id,
        item_name: t.product,
        item_brand: t.product_type,
        item_variant: t.product,
        location_id: orderInfo.country,
        quantity: 1,
      })),
    };
    posthog.capture("Purchase", record);
    GTags.recordPurchase(record);
    window._ASO.Postback?.send?.(orderInfo.amount);
  };
  return (
    <SpicyStoreContext.Provider
      value={{
        payWithSpicyTokens,
        tokenPackages,
        localizePrice,
        localPriceToEuro,
        loading,
        tokens,
        refresh: init,
        getOrderInfo,
        savePurchaseRecord,
        localCurrencyId: localCurrency.id,
      }}
    >
      <Popup
        open={showMFAPopup}
        onClose={toggleMFAPopup}
        showCloseButton
        defaultClose={false}
      >
        <h1>Verify Your Purchase</h1>

        <p>Enter the code that has been sent to your email.</p>

        <button onClick={resendCode}>
          <u>Resend code</u>
        </button>

        <RequestError error={error} />

        <div className="inputs">
          <Input callback={setMfaCode} placeholder="******" />
        </div>

        <div className="buttons">
          <SubmitButton label={"Proceed"} request={sendMFACode} />
        </div>
      </Popup>
      {children}
    </SpicyStoreContext.Provider>
  );
};

export const useSpicyStore = () => useContext(SpicyStoreContext);

export default SpicyStoreProvider;
