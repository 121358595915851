import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Editor from "src/components/CMS/Articles/Editor";
import {
  apiObjects,
  chooseApiObjects,
} from "src/components/CMS/Articles/EditorOptions";
import { SubmitButton, idGen } from "src/components/CMS/Common";
import { LoaderInside, sort } from "src/components/Common";
import { getCMSFeaturedContent, saveChanges } from "../util";

const FeaturedSection = ({
  title,
  position,
  allowedObjects = ["game_news", "staff_reviews", "blog_posts"],
}) => {
  const [objects, setObjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getObjects();
  }, []);

  const save = async () => {
    await saveChanges(
      position,
      objects.map((object, i) => {
        // The Video is not a array type like rest of games, news, blog articles,
        // so the video contains different type of object in "content": { video_id:  212}
        // other object.types would have the "content": [{id: 23}, {id: 24}]
        const id =
          object.type === "Video"
            ? object.content.video_id
            : object.content[0]?.id;

        return {
          type: object.type,
          object_id: id,
          order: i + 1,
        };
      })
    );
  };

  const getObjects = async () => {
    try {
      const res = await getCMSFeaturedContent(position);
      setLoading(false);
      const objects = sort("order", res, "asc")?.map((object) => {
        let content;
        // see the save() function for the reason of this

        if (object.metadata.type === "Video") {
          object.video_id = object.metadata.id;
          content = object;
        } else {
          content = [object];
        }
        return {
          content,
          key: Math.random(),
          id: idGen(object.metadata.type),
          type: object.metadata.type,
        };
      });

      setObjects(objects);
    } catch (error) {
      console.log(error);

      toast.error(
        <div>
          <p>Couldn't load items of {position}.</p>
          <SubmitButton icon="fa-refresh" label="Reload" request={getObjects} />
        </div>
      );
    }
  };

  return (
    <section>
      <legend>{title}</legend>
      {loading ? (
        <LoaderInside />
      ) : (
        <>
          <div className="content">
            <Editor
              objects={objects}
              setObjects={setObjects}
              editorOptions={chooseApiObjects(
                apiObjects(false),
                allowedObjects
              )}
            />
          </div>
          {/* <div className="instructions">
            * The first four elements will be only visible. others will be
            ignored
          </div> */}
          <br />
          <SubmitButton label="Save Changes" request={save} />
        </>
      )}
    </section>
  );
};

export default FeaturedSection;
