import React from "react";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Roles, useRoleInfo } from "../RoleContext";
import "./style.css";

const SelectTeam = () => {
  const { path } = useRouteMatch();
  const { id } = useParams();
  const { teams, role, defaultTeam } = useRoleInfo();
  const history = useHistory();

  const navigate = (e) => {
    const id = e.target.value;
    const redirectTo = generatePath(path, { id });
    history.push(redirectTo);
  };

  if (role !== Roles.CONTENT_CREATOR) return null;

  return (
    <div className="SelectTeam">
      <label htmlFor="team">Select Team: </label>
      <select
        defaultValue={id ?? defaultTeam}
        onChange={navigate}
        name="team"
        id="team"
      >
        {teams?.map?.((team) => {
          const selected = team.id === defaultTeam;
          return (
            <option key={team.id} value={team.id}>
              {team.name} {selected && "[Default]"}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectTeam;
