import { useState } from "react";
import { useParams } from "react-router-dom";
import { useLanguage } from "../../providers/LanguageProvider";
import { MultiLang } from "../MultiLang";
import "./css/Header.css";

export const submitByEnter = (e) =>
  e.key === "Enter" && e.target.nextElementSibling.click();

const Header = ({
  title,
  toggle,
  searchFunction,
  setSearchQuery,
  searchQuery,
  customButton,
  sort = () => null,
  sortConfig = {},
}) => {
  const { query } = useParams();

  const language = useLanguage();

  return (
    <header>
      <div className="search-bar">
        <div className="head">
          <h1 className="title" title={`${query} ${title}`}>
            {query} {title}
          </h1>
        </div>
        <div className="search-items">
          <div className="search">
            <input
              autoFocus
              type="text"
              id="search-input"
              placeholder={{ en: `Search ${title}...` }[language]}
              onChange={setSearchQuery}
              onKeyPress={submitByEnter}
              defaultValue={searchQuery}
            />

            <span className="fa fa-search" onClick={searchFunction}></span>
          </div>
          <div className="search-buttons">
            {toggle && (
              <div className="btn filter" onClick={toggle}>
                filter <i className="fa fa-filter"></i>
              </div>
            )}

            {customButton}
            <SortItems sort={sort} sortConfig={sortConfig} />
          </div>
        </div>
      </div>
    </header>
  );
};
export const SortItems = ({ sort, sortConfig }) => {
  const [sortMenu, setSortMenu] = useState(false);
  const sorters = [
    { key: "title", label: "by A-Z" },
    { key: "views", label: "by views" },
    { key: "likes_count", label: "by likes" },
    { key: "last_updated", label: "by date" },
  ];

  return (
    <div className="SortItems">
      <div className="btn sort" onClick={() => setSortMenu((prev) => !prev)}>
        <MultiLang sl="Sortiraj">sort</MultiLang>{" "}
        <i className="fa fa-sort-amount-asc"></i>
      </div>
      {sortMenu && (
        <div className="menu">
          {sorters.map((sorter, key) => (
            <Sort
              label={sorter.label}
              sortKey={sorter.key}
              sort={sort}
              sortConfig={sortConfig}
              key={key}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const Sort = ({
  sort = () => null,
  label,
  sortKey,
  sortConfig = {},
}) => {
  const [isAscending, setIsAscending] = useState(false);

  const sortGames = () => {
    const config = { sort: sortKey, order: isAscending ? "asc" : "dsc" };
    setIsAscending((prev) => !prev);
    sort(config);
    sortConfig.current = config;
  };
  return (
    <div
      className="item"
      onClick={sortGames}
      data-selected={sortConfig.current?.sort === sortKey}
    >
      <span>{label}</span>
      <span>
        <i className={`fa fa-sort-${isAscending ? "down" : "up"}`}></i>
      </span>
    </div>
  );
};
export default Header;
