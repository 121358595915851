import PropTypes from "prop-types";
import React from "react";
import { getClass } from "src/utils/getClass";
import "./style.css";
import { Link } from "react-router-dom";

const Breadcrumb = ({ children, divider }) => {
  const items = React.Children.toArray(children);

  return (
    <nav aria-label="Breadcrumb" className="Breadcrumb">
      <ol className="breadcrumb">
        {items.map((child, index) => {
          const isLast = index === items.length - 1;

          return (
            <li
              key={index}
              className={getClass("breadcrumb-item")}
              aria-current={isLast ? "page" : null}
              data-divider={divider}
            >
              {child}
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

Breadcrumb.propTypes = {
  children: PropTypes.node.isRequired,
  divider: PropTypes.string,
};

Breadcrumb.defaultProps = {
  divider: "/",
};

const BreadcrumbItem = ({ children, href }) => {
  const ItemComponent = href ? Link : "span";

  return (
    <ItemComponent to={href} className="breadcrumb-link">
      {children}
    </ItemComponent>
  );
};

BreadcrumbItem.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
};

BreadcrumbItem.defaultProps = {
  href: null,
};

Breadcrumb.Item = BreadcrumbItem;

export default Breadcrumb;
