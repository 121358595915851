import React, { useMemo } from "react";
import { Tabs } from "src/components/Tabs";
import ArticleMedia from "./ArticleComponents/ArticleMedia";
import ArticleContent from "./ArticleComponents/ArticleContent";
import "./ArticleComponents/style.css";

const UploadArticleTabs = ({ Info, propsForEachPageComponent, articleID }) => {
  const pages = useMemo(
    () => [
      {
        component: Info,
        displayText: "Info",
        id: "info",
        url: "/info",
        // disabled: true,
      },
      {
        component: ArticleContent,
        displayText: "Content",
        id: "content",
        url: "/content",
        disabled: !articleID,
      },
      {
        component: ArticleMedia,
        displayText: "Media",
        id: "media",
        url: "/media",
        disabled: !articleID,
      },
    ],
    [articleID, Info]
  );

  return (
    <Tabs
      pages={pages}
      firstPageId={"info"}
      withURLChange
      propsForEachPageComponent={propsForEachPageComponent}
    />
  );
};

export default UploadArticleTabs;
