import React, { useCallback } from "react";

import { useDropzone } from "react-dropzone";
import "./style.css";
import { getClass } from "src/utils/getClass";

/**
 *
 * @param {import("react-dropzone").DropzoneOptions} options
 * @returns
 */
const DropDownZone = (options) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone(options);

  return (
    <div
      {...getRootProps()}
      className={getClass(
        "DropDownZone",
        isDragActive && "active",
        options.disabled && "blocked"
      )}
    >
      <input {...getInputProps()} disabled={options.disabled} />
      {options.disabled ? (
        <p>Currently Not Available</p>
      ) : isDragActive ? (
        <p>Drop the files here...</p>
      ) : (
        <p>Drag 'n' drop some images here, or click to select images</p>
      )}
    </div>
  );
};

export default DropDownZone;
