import axios from "axios";
import { useEffect, useState } from "react";
import { Link, generatePath } from "react-router-dom";
import { toast } from "react-toastify";
import Finder from "src/components/Finder";
import { PRODUCT_TYPES } from "src/providers/CartProvider";
import { toEuro } from "src/utils/number-format";
import { NUMBERS_ONLY_REGEX } from "src/utils/regex";
import { validateWith } from "src/utils/validatedBackendRequest";
import { z } from "zod";
import { authRequest } from "../../../../utils/Axios";
import { Input } from "../../../Member/General";
import { SubmitButton } from "../../Common";
import { downloadCategories, getDefaultCategoryName } from "./contentData";

const newDownloadSchema = z.object({
  name: z.enum(
    downloadCategories.map((c) => c.label),
    { required_error: "Name is Required" }
  ),
  price: z.number().min(0).default(0),
  game_id: z.number(),
});

const AddDownload = ({
  refresh,
  onClose,
  gameId,
  creatorApproved,
  creatorTeamId,
  editMode,
  download,
}) => {
  const [info, setInfo] = useState(() => {
    if (editMode) {
      return {
        name: download.name,
        price: download.price,
      };
    }
    return {
      name: getDefaultCategoryName(creatorApproved),
      price: 0,
    };
  });

  const source = axios.CancelToken.source();

  const [developerShare, setDeveloperShare] = useState(0);

  useEffect(() => {
    calculateDeveloperShare(info.price, source.token);

    return () => {
      source.cancel();
    };
  }, [info.price]);

  const selectedDownloadCategory =
    downloadCategories.find((c) => c.label === info.name) ?? {};

  const submit = async () => {
    try {
      if (selectedDownloadCategory.price === true && info.price <= 0) {
        throw new Error(
          `Price is required with ${selectedDownloadCategory.label}`
        );
      }

      const price = parseFloat(info.price) || 0;
      const res = await authRequest({
        url: editMode
          ? "/downloads/api/games/downloads/edit"
          : "/downloads/api/games/downloads/create",
        method: editMode ? "PUT" : "POST",
        data: editMode
          ? {
              download_id: download.id,
              name: info.name,
              price,
            }
          : validateWith(newDownloadSchema, {
              game_id: parseInt(gameId),
              name: info.name,
              price,
            }),
      });

      if (!editMode) {
        return toast.success(res.message);
      } else {
        toast.success("Successfully edited");
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      onClose?.();
      refresh?.();
    }
  };

  const onChange = (key) => (value) => {
    setInfo((prev) => ({ ...prev, [key]: value }));
  };

  const calculateDeveloperShare = async (priceEUR, cancelToken) => {
    try {
      const res = await authRequest({
        url: "/store/api/teams/calculate-profit",
        params: {
          team_id: creatorTeamId,
          price_eur: priceEUR,
          product_type: PRODUCT_TYPES.Game,
        },
        cancelToken,
      });

      setDeveloperShare(res.profit_eur);
    } catch (error) {}
  };

  return (
    <div className="AddDownload">
      <h2>{editMode ? `Edit ${download.name}` : "Add new Download"}</h2>
      {editMode ? (
        <div className="info">
          <p>
            You can update the names and prices of "Downloads", but you cannot
            delete them
          </p>
        </div>
      ) : (
        <div className="info">
          <p>
            "Downloads" are your packages that you set a price or make free.
          </p>
          <p>
            Select from the list of names like Demo or Full Game or make up your
            own like Gold Version, Ultimate edition, etc.
          </p>
          <p>
            You can create as many "Downloads" as you want and price them as you
            like.
          </p>
        </div>
      )}

      <div className="inputs">
        <Finder
          data={downloadCategories.map((c) => ({ id: c.label, name: c.label }))}
          idExtractor={(c) => c.id}
          required
          label="Select Download Type"
          showSearch={false}
          onChange={(c) => onChange("name")(c.id)}
          value={{
            id: selectedDownloadCategory.label,
            name: selectedDownloadCategory.label,
          }}
        />
        {selectedDownloadCategory.price !== false && (
          <Input
            callback={onChange("price")}
            controlledValue={info.price ?? 0}
            label={"Price (in Euros)"}
            required={selectedDownloadCategory.price}
            example={`You will earn ${toEuro(developerShare)} from each sale.`}
            regex={NUMBERS_ONLY_REGEX}
          />
        )}

        {selectedDownloadCategory.price !== false && !creatorApproved && (
          <span>
            To set up pricing you need to get approved first.{" "}
            <Link
              to={generatePath("/cms/personal/teams/edit/:id/payout-info", {
                id: creatorTeamId,
              })}
            >
              Please insert your details here.
            </Link>
          </span>
        )}
      </div>

      <SubmitButton
        request={submit}
        disabled={info.name?.length <= 0}
        label="Submit"
      />
    </div>
  );
};
export default AddDownload;
