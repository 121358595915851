import dateFormat from "dateformat";
import { useContext } from "react";
import { authRequest } from "src/utils/Axios";
import useSWRInfinite from "swr/infinite";
import { LoadMore, sort } from "../../Common";
import { fetcher } from "../Cms";
import { MainHeader } from "../Headers";
import { RoleContext } from "../RoleContext";
import Table, { getColumn } from "../Table/Table";
import "../css/GameCreation.css";
import { gameNewsOptions } from "./Options";

const columns = [
  getColumn("ARTICLE Title", { sortKey: "title", accessKey: "title" }),
  getColumn("SHORT DESCRIPTION", {
    sortKey: "short_description",
    accessKey: "short_description",
  }),
  getColumn("Game Title", { sortKey: "game_title", accessKey: "game_title" }),
  getColumn("DEV TEAM", { sortKey: "creator_team", accessKey: "creator_team" }),
  getColumn("Date Created", {
    sortKey: "date_posted",
    accessFn: (game) => dateFormat(new Date(game.date_posted), "longDate"),
  }),
];

const GameNews = ({ toggler, subHeader }) => {
  // menu button togglers
  const { role } = useContext(RoleContext);

  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) => {
      return `${window.domainName}/news/api/news/game-news/list?page=${
        index + 1
      }`;
    },
    fetcher,
    { revalidateOnFocus: false }
  );

  const articles = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];
  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  const search = async (query) => {
    const res = await authRequest({
      url: `/news/api/news/game-news/cms/search?search_term=${query}`,
    });

    if (res.error) return [];
    return res;
  };
  const searchCallback = (data) => {
    mutate(data, false);
  };

  return (
    <div className="body">
      <MainHeader
        toggler={toggler}
        uploadLink={window.location.pathname + "/upload-review"}
        header={subHeader}
        search={{
          func: search,
          callback: searchCallback,
        }}
      />
      <div className="content">
        <Table
          columns={columns}
          data={articles}
          getOptions={(article) => gameNewsOptions(role, article)}
          getPopupData={(article) => {
            const commonPopupData = {
              title: article.title,
              description: article.short_description,
              creator: article.creator_team,
              created: article.date_posted,
              id: article.id,
            };
            return {
              edit: {
                id: article.id,
              },
              view_news: {
                id: article.id,
              },
              delete: {
                ...commonPopupData,
                category: "game-news", //this will be in the URL. DO NOT change
              },
              publish: {
                id: article.id,
                published: article.published,
                title: article.title,
              },
              deny: {
                ...commonPopupData,
              },
            };
          }}
          refreshData={() => mutate(articles, true)}
          onSort={(key, order) => mutate(sort(key, articles, order), false)}
          indicators={[
            {
              color: "#fb0400",
              when: (article) => !article.published,
              name: "News Unpublished",
            },
          ]}
        />

        {errors && <div className="error">{errors.error}</div>}
        {error && <div className="error">{error.message}</div>}
        <LoadMore onClick={() => setSize((prev) => prev + 1)} />
      </div>
    </div>
  );
};

export default GameNews;
