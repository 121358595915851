import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./css/PrivacyPolicy.css";
import Header from "../Header";
import useDocs from "../../hooks/useDocs";
import { MultiLang } from "../MultiLang";

const PrivacyPolicy = () => {
  const response = useDocs("privacy_policy");
  return (
    <main id="privacy-policy">
      <div className="inner">
        <header>
          <h1>
            <strong>
              <MultiLang sl="Spicygaming.net Politika Zasebnosti">
                Spicygaming.net Privacy Policy
              </MultiLang>
            </strong>
          </h1>
          <p>
            <em>
              <MultiLang sl="Nazadnje posodobljeno:">
                This Privacy Policy was last modified on
              </MultiLang>{" "}
              {response.headers?.["last-modified"]}.
            </em>
          </p>
        </header>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: response.data }}
        ></div>
        <br />
        <Header searchBar={false} />
      </div>
    </main>
  );
};

export default PrivacyPolicy;
