import React, { useContext, useEffect, useState } from "react";
import { SuggestionPanelTypes, allSuggestionPanelTypes } from "../utils";
import { Chip } from "src/components/Chip";
import { LoaderInside } from "src/components/Common";
import { WidthContext } from "src/components/Context";
import { request } from "src/utils/Axios";
import { Player } from "../Player";
import SuggestionPanel from "./SuggestionChips";

const SuggestVideos = ({ videoId, teamName }) => {
  const mobile = useContext(WidthContext);
  const [loading, setLoading] = useState(true);
  const [recommendations, setRecommendations] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(
    SuggestionPanelTypes.FEATURED.param
  );

  useEffect(() => {
    if (selectedSuggestion) getWatchNext(videoId, selectedSuggestion);
  }, [selectedSuggestion, videoId]);

  const getWatchNext = async (id, type) => {
    try {
      setLoading(true);
      const res = await request({
        url: "/community/community-videos/recommendations",
        params: { video_id: id, type },
      });
      setRecommendations(res);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="SugguestVideos">
      <section className="suggestions">
        <SuggestionPanel suggestionData={allSuggestionPanelTypes}>
          {(type) => (
            <div onClick={() => setSelectedSuggestion(type.param)}>
              <Chip
                selected={selectedSuggestion === type.param}
                className="tag"
              >
                {type.name?.replace("#{team}", teamName)}
              </Chip>
            </div>
          )}
        </SuggestionPanel>
      </section>
      <section className="watch-next">
        <legend>
          <h2>Watch Next</h2>
        </legend>
        <div className="content">
          {loading ? (
            <LoaderInside />
          ) : (
            recommendations.map((recommendation, i) => (
              <Player
                horizontal={!mobile}
                video={recommendation}
                key={i} // recommendation.id won't work here
              />
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default SuggestVideos;
