import React, { useEffect, useState } from "react";
import { addImageFiles, Loader } from "../../../Common";

import { idGen } from "../../Common";
import { getURLs } from "../../Games/EditGame";
import UploadBlogArticle from "../UploadBlogArticle";
import { ErrorPage } from "./EditStaffReviews";
import { imageLengthsCheck } from "./imagesCheck";
import { authRequest } from "src/utils/Axios";

function EditBlogArticle({ match, subHeader, header }) {
  const ID = match.params.id;

  const [article, setArticle] = useState({});
  const [images, setImages] = useState({
    landscape_banner: [],
    square_banner: [],
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getArticleData();
  }, [ID]);

  useEffect(() => {
    if (article.landscape_cover)
      addImageFiles(
        "landscape_banner",
        setImages,
        getURLs([article.landscape_cover])
      );
    if (article.square_cover)
      addImageFiles(
        "square_banner",
        setImages,
        getURLs([article.square_cover])
      );
  }, [article.landscape_banner, article.square_cover]);

  const getArticleData = async () => {
    setLoading(true);
    try {
      const res = await authRequest({
        url: `/news/api/news/blog-posts/edit`,
        params: {
          article_id: ID,
        },
      });

      if (!res.error) {
        setArticle(res);
      } else {
        setError(true);
      }
    } catch (error) {}
    setLoading(false);
  };
  const formData = {
    short_description: article.short_description,
    title: article.title,
    game_id: article.game?.id,
    game_name: article.game?.title,
    creator_id: article.team?.id,
    creator_name: article.team?.name,
    category: article.category,
    media_gallery: article.media_gallery,
  };

  const objects =
    article.content?.elements?.map((object) => ({
      type: object.type,
      content: object.content,
      id: idGen(object.type),
      key: Math.random(),
      order: object.order,
    })) || [];

  if (error) return <ErrorPage />;
  if (loading) return <Load />;

  if (
    !imageLengthsCheck({
      inputRange: [[article.landscape_cover], [article.square_cover]],
      outputRange: [images.landscape_banner, images.square_banner],
    })
  )
    return <Load />;

  return (
    <UploadBlogArticle
      header={header}
      subHeader={subHeader}
      editMode={true}
      defaults={{
        formData,
        objects,
        images,
        id: ID,
        revalidateArticleDate: getArticleData,
      }}
    />
  );
}
const Load = () => (
  <div className="body">
    <div className="content">
      <Loader />
    </div>
  </div>
);

export default EditBlogArticle;
