import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoaderInside } from "src/components/Common";
import VideoPlayer from "src/components/VideoPlayer";
import { vastPullzones } from "src/components/VideoPlayer/pullzones";
import { generateURL } from "src/components/resizeImages";
import { BASE_CDN_URL, request } from "src/utils/Axios";
import { ASPECT_RATIO } from "../Player/Player";
import "./style.css";
import { Helmet } from "react-helmet";

const EmbedVideo = () => {
  const { id } = useParams();
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(true);

  const url = `https://www.spicygaming.net/video/${id}`;
  useEffect(() => {
    getDetails(id);
  }, [id]);

  const getDetails = async (id) => {
    try {
      setLoading(true);
      const res = await request({
        url: "/community/community-videos/video",
        baseURL: BASE_CDN_URL,
        params: { video_id: id },
      });
      setInfo(res);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="EmbedVideo" data-testid="EmbedVideo">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {loading ? (
        <LoaderInside />
      ) : (
        <VideoPlayer
          nuevoOptions={{
            title: info.title,
            logocontrolbar: "https://www.spicygaming.net/images/logo.png",
            logourl: url,
            url: url,
            shareMenu: true,
            videoInfo: true,
          }}
          options={{ aspectRatio: "16:9" }}
          vastZone={vastPullzones.default}
          videoID={info.file?.bunny_id}
          folderID={info.file?.library_id}
          pullzoneID={info.file?.pullzone}
          thumbnailName={generateURL(
            720,
            parseInt(720 / ASPECT_RATIO),
            info.thumbnail
          )}
        />
      )}
    </main>
  );
};

export default EmbedVideo;
