import LocalPrice from "./LocalPrice";

const DiscountedPrice = ({ discountedPriceEUR, priceEUR }) => {
  return (
    <div className="leading-none">
      <div className="w-fit opacity-80 ml-auto mr-0">
        <strike>
          <LocalPrice
            hideDisclaimer
            amountInEUR={priceEUR}
            showOnlyLocalPrice
          />
        </strike>
      </div>
      <div className="text-3xl text-[#ffc107] font-medium">
        <LocalPrice
          hideDisclaimer
          amountInEUR={discountedPriceEUR}
          showOnlyLocalPrice
        />
      </div>
    </div>
  );
};

export default DiscountedPrice;
