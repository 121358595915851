export const PRODUCT_TYPES = {
  Game: "Game",
  GameBundle: "Bundle",
  TokenPurchase: "Token Purchase",
};

export const CurrencyDisclaimer = ({ showLink = true }) => {
  return (
    <span id="currency-disclaimer">
      *Amounts shown in currencies other than € are approximate and are
      informative only. <br />
      {showLink && (
        <a
          style={{ textDecoration: "underline" }}
          href="/member/general"
          target="_blank"
          rel="noopener noreferrer"
        >
          You can change your currency on your profile page here
        </a>
      )}
    </span>
  );
};
