import { useParams } from "react-router-dom";
import Login from "./Login";

const LoginRedirect = () => {
  const { link } = useParams();

  return <Login link={link} customLink={true} />;
};

export default LoginRedirect;
