import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Filter from "src/components/AdultGames/Filter";
import Header from "src/components/AdultGames/Header";
import { SubmitButton } from "src/components/CMS/Common";
import { Chip } from "src/components/Chip";
import { LoaderInside, sort } from "src/components/Common";
import { SideMenu } from "src/components/SideMenu";
import SuggestionPanel from "src/components/Video/FullPage/SuggestionChips";
import useInfiniteScroll from "src/hooks/useInfinityScroll";
import useIntersection from "src/hooks/useIntersection";
import { useToggle } from "src/hooks/useToggle";
import { BASE_CDN_URL, BASE_URL, request } from "src/utils/Axios";
import stringifyFilters from "src/utils/stringifyFilters";
import { updateViewedImages } from "src/utils/updateViewed";
import { withoutLastPage } from "src/utils/withoutLastPage";
import MasonryImagesGrid from "../MasonryImagesGrid";
import "./style.css";

const fetcher = (params) => async (page) => {
  const isInitial = !params.name && !params.tag;

  const url = isInitial
    ? "/products/api/spicyart/initial-search"
    : "/products/api/spicyart/search-gallery-images";
  try {
    const res = await request({
      url,
      method: "GET",
      baseURL: isInitial ? BASE_CDN_URL : BASE_URL,
      params: { ...params, page },
      withCredentials: true,
    });
    return withoutLastPage(res);
  } catch (error) {}
};

const GallerySearchPage = () => {
  const params = useParams();
  const history = useHistory();
  const loaderRef = useRef();
  const [searchQuery, setSearchQuery] = useState(params.query || "");
  const [showFilter, toggleFilter] = useToggle(false);
  const [filters, setFilters] = useState({});
  const [popularTags, setPopularTags] = useState([]);

  const intersection = useIntersection(loaderRef, {
    root: null, // Use 'null' for the viewport
    rootMargin: "300px", // Customize the margin
    threshold: 0.5, // Set a value greater than 0 to trigger on scroll down
  });

  const sentForViewed = useRef(new Set());

  const {
    data: images,
    isLoading,
    fetchMoreData,
  } = useInfiniteScroll(
    fetcher({
      name: params.query ?? "",
      tag: params.tag,
      ...stringifyFilters(filters),
    }),
    [params.query, params.tag, filters]
  );

  useEffect(() => {
    if (intersection?.isIntersecting) {
      fetchMoreData();
      updateViews();
    }
  }, [intersection?.isIntersecting]);

  const updateViews = async () => {
    await updateViewedImages(
      images.map((img) => img.id).filter((id) => !sentForViewed.current.has(id))
    );

    images.forEach((img) => sentForViewed.current.add(img.id));
  };

  const searchFunction = () => {
    if (!!searchQuery.length) {
      history.push(`/galleries/${searchQuery}/1`);
    } else {
      toast.warn("Type query to search");
    }
  };

  useEffect(() => {
    getTags();
  }, []);
  const getTags = async () => {
    try {
      const res = await request({
        url: "/products/api/spicyart/most-popular-tags",
        baseURL: BASE_CDN_URL,
      });
      setPopularTags(sort("frequency", res, "dsc"));
    } catch {}
  };
  const redirectToUpload = () => {
    history.push("/cms/content/spicy-art/upload");
  };
  return (
    <div>
      <main
        id="adult-games"
        className="adult-games GallerySearchPage"
        data-testid="GallerySearchPage"
      >
        <Helmet>
          <title>Browse 1000s of Porn Images | Spicygaming</title>
          <link rel="canonical" href="https://www.spicygaming.net/galleries/" />
          <meta
            name="description"
            content="Download free Images. Browse and Search 100s of sexy Images , Illustrations and GIFs from porn games, comics and art."
          />
        </Helmet>
        <div className="inner">
          <Header
            title="SPICY ART"
            searchFunction={searchFunction}
            setSearchQuery={(e) => setSearchQuery(e.target.value)}
            searchQuery={params.query}
            customButton={
              <>
                <button className="btn toggle-sidebar" onClick={toggleFilter}>
                  <i className="fa fa-filter fa-lg"></i>
                </button>
                <SubmitButton
                  request={redirectToUpload}
                  className="btn"
                  icon="fa-upload"
                  label="Upload Images"
                />
              </>
            }
          />
          <SuggestionPanel suggestionData={popularTags}>
            {(item) => {
              const tagsURL = `/galleries/tag/${item.tag}/`;
              return (
                <Link to={tagsURL}>
                  <Chip selected={item === params.tag} key={item.tag}>
                    {item.tag?.replace(/_/g, " ")}
                  </Chip>
                </Link>
              );
            }}
          </SuggestionPanel>
          <div className="results">
            {!!images.length ? (
              <MasonryImagesGrid images={images} />
            ) : (
              params.query &&
              !isLoading && <div className="not-found">No Images found :(</div>
            )}
            {isLoading && <LoaderInside />}

            <div
              style={{
                display: isLoading || !images.length ? "none" : "block",
              }}
              className="loader"
              ref={loaderRef}
            ></div>
          </div>
        </div>

        <SideMenu show={showFilter} toggleMenu={toggleFilter}>
          <Filter
            allowedFilters={["gallery_types"]}
            callback={(filters) => setFilters(filters)}
            toggle={toggleFilter}
            filter={showFilter}
          />
        </SideMenu>
      </main>
    </div>
  );
};

export default GallerySearchPage;
