import useSWRInfinite from "swr/infinite";
import { LoadMore } from "../../Common";
import { generateURL } from "../../resizeImages";
import { fetcher } from "../Cms";
import { REVALIDATE_ON_FOCUS } from "../Common";
import { MainHeader } from "../Headers";
import { Table } from "../Table";
import { getColumn } from "../Table/Table";

// /products/api/games/unapproved
const AwardsList = ({ toggler, subHeader }) => {
  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) => {
      return `${window.domainName}/products/api/game-awards/list?page=${
        index + 1
      }`;
    },
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );

  const awards = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];
  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  return (
    <div className="body">
      <MainHeader
        toggler={toggler}
        header={subHeader}
        buttons={false}
        // element={"back-button"}
      />
      <div className="content">
        <Table
          data={awards}
          refreshData={() => mutate(awards, true)}
          columns={[
            getColumn("Award Name", { accessKey: "name" }),
            getColumn("Award Icon", {
              accessFn: (award) => (
                <img src={generateURL(50, 50, award.icon)} alt={award.name} />
              ),
            }),
          ]}
        />

        {errors && <div className="error">{errors.error}</div>}
        {error && <div className="error">{error.message}</div>}
        <LoadMore onClick={() => setSize((prev) => prev + 1)} />
      </div>
    </div>
  );
};

export default AwardsList;
