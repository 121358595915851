// priority is used to stack them in the game page

export const downloadCategories = [
  { label: "Premium (Full Version)", os: true, price: true, priority: 9 },
  {
    label: "Free Public Version",
    os: true,
    price: false,
    default: true,
    priority: 8,
  },
  { label: "DLC", os: true, priority: 7 },
  { label: "Walkthrough", os: false, priority: 6 },
  { label: "Mod", os: true, priority: 5 },
];
export const preSelectResources = [
  { label: "Game" },
  { label: "Document" },
  { label: "Images" },
  { label: "DLC" },
  { label: "Walkthrough" },
  { label: "Video" },
  { label: "Art" },
];
export const getDefaultCategoryName = (approved) => {
  if (!approved) return "Free Public Version";
  return "Premium (Full Version)";
};

export const getDownloadNamePriority = (name) => {
  const download = downloadCategories.find((d) => d.label === name);

  if (download) return download.priority;

  return 0;
};
