import { z } from "zod";

export const productSchema = z.object({
  product_name: z.string(),
  product_id: z.number(),
  product_type: z.string(),
  rate: z.number().nullable().default(0),
});

export const saleSchema = z.object({
  id: z.number(),
  products: z.array(productSchema),
  name: z.string(),
  expiry: z.string().transform((s) => new Date(s)),
  start_date: z.string().transform((s) => new Date(s)),
  is_expired: z.boolean(),
  team: z.number(),
  public: z.boolean(),
});

export const bundleSchema = z.object({
  id: z.number(),
  products: z.array(productSchema),
  name: z.string(),
  price: z.object({
    total: z.number(),
    discounted: z.number(),
  }),
  expiry: z
    .string()
    .transform((s) => new Date(s))
    .optional()
    .nullable(),
  start_date: z
    .string()
    .transform((s) => new Date(s))
    .optional()
    .nullable(),
  is_expired: z.boolean(),
  team: z.number(),
});
