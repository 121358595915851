import Image from "./Image";
import generateImageObjectFromPath from "./generateImageObject";

const BasicImage = ({ src, alt, width, height, loading }) => {
  return (
    <div className="BasicImage">
      <Image
        image={generateImageObjectFromPath(src, {
          name: alt,
          aspect_ratio: width / height,
        })}
        width={width}
        maxHeight={height}
        noRedirect
        loading={loading}
      />
    </div>
  );
};

export default BasicImage;
