import { useEffect, useMemo, useState } from "react";
import { request } from "src/utils/Axios";
import { shapeAs } from "src/utils/validatedBackendRequest";
import { z } from "zod";
import { Chip } from "../Chip";
import { LoaderInside } from "../Common";
import GameNewsMini, { TYPES } from "../Home/ReviewMini";
import SuggestionPanel from "../Video/FullPage/SuggestionChips";
import { SuggestionPanelTypes, allSuggestionPanelTypes } from "../Video/utils";
import { SimpleDivider } from "../Atoms/SimpleDivider";

const getSuggestionsPropsSchema = z.object({
  article_id: z.number(),
  type: z.string().default(SuggestionPanelTypes.FEATURED.param),
  article_type: z.string(),
});

const SuggestArticles = ({ articleId, teamName, type }) => {
  const [loading, setLoading] = useState(true);
  const [suggestions, setRecommendations] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(
    SuggestionPanelTypes.FEATURED.param
  );

  useEffect(() => {
    if (selectedSuggestion) getWatchNext(articleId, selectedSuggestion);
  }, [selectedSuggestion, articleId]);

  const articleType = useMemo(() => {
    const typeEntry = Object.entries(TYPES).find(
      ([, value]) => value.type === type
    );
    return typeEntry?.[0];
  }, []);

  const getWatchNext = async (id, type) => {
    try {
      setLoading(true);
      const res = await request({
        url: "/news/api/news/articles/recommend",
        // baseURL: BASE_CDN_URL,
        params: shapeAs(getSuggestionsPropsSchema, {
          article_id: id,
          type,
          article_type: articleType,
        }),
      });
      setRecommendations(res);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="SugguestCollections">
      <section className="suggestions">
        <SuggestionPanel suggestionData={allSuggestionPanelTypes}>
          {(type) => (
            <div
              key={type.param}
              onClick={() => setSelectedSuggestion(type.param)}
            >
              <Chip
                selected={selectedSuggestion === type.param}
                className="tag"
              >
                {type.name?.replace("#{team}", teamName)}
              </Chip>
            </div>
          )}
        </SuggestionPanel>
      </section>
      <section className="watch-next">
        <legend>
          <h2>Explore Next</h2>
        </legend>
        <div className="content">
          {loading ? (
            <LoaderInside />
          ) : (
            suggestions.map((suggestion) => (
              <div>
                <GameNewsMini
                  {...suggestion}
                  key={suggestion.id}
                  type={suggestion.article_type}
                />
                <SimpleDivider margin={"1rem"} />
              </div>
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default SuggestArticles;
