import {
  CRYPTO_COINS,
  CRYPTO_NETWORKS,
  genSelectables,
} from "../../../../constants/crypto-networks";
import Finder from "../../../Finder";
import { URL_REGEX } from "../../../Member/General";

export const socialLinks = [
  {
    label: "Subscribe Star URL",
    regex: URL_REGEX,
    id: "subscribestar",
  },
  {
    label: "Facebook URL",
    regex: URL_REGEX,
    id: "facebook",
  },
  {
    label: "Twitter URL",
    regex: URL_REGEX,
    id: "twitter",
  },
  {
    label: "Steam URL",
    regex: URL_REGEX,
    id: "steam",
  },
  {
    label: "Instagram URL",
    regex: URL_REGEX,
    id: "instagram",
  },
  {
    label: "Patreon URL",
    regex: URL_REGEX,
    id: "patreon",
  },
  {
    label: "Itchio",
    regex: URL_REGEX,
    id: "itchio",
  },
  {
    label: "Website URL",
    regex: URL_REGEX,
    id: "website_link",
  },
  {
    label: "Discord URL",
    regex: URL_REGEX,
    id: "discord",
  },
];

export const paymentPersonalInfo = [
  {
    label: "Name",
    id: "name",
    required: true,
  },
  {
    label: "Surname",
    id: "surname",
    required: true,
  },
  {
    label: "Street Address",
    id: "street_address",
  },
  {
    label: "City",
    id: "city",
  },
  {
    label: "Postal Code",
    id: "postal_code",
  },
  {
    label: "State",
    id: "state",
  },
];

export const paymentCompanyInfo = [
  {
    label: "Name of the Company",
    id: "company_name",
    required: true,
  },
  {
    label: "Company Phone number",
    id: "company_phone",
    type: "tel",
  },
  {
    label: "Street Address",
    id: "company_address",
    required: true,
  },
  {
    label: "City",
    id: "company_city",
    required: true,
  },
  {
    label: "Postal Code",
    id: "company_postal_code",
    required: true,
  },
  {
    label: "State",
    id: "company_state",
  },
  {
    label: "EU VAT",
    id: "company_vat",
  },
];

export const paymentMethods = [
  {
    id: "paypal",
    label: "PayPal",
    minAmount: 50,
    description:
      "There is a 5% fee for all Paypal Transfer. PayPal might also charge additional 3-5% fee, depending on the country of origin, PayPal account, and amount.",
    inputs: [{ id: "email", label: "Email" }],
  },
  {
    id: "crypto",
    label: "Crypto",
    minAmount: 50,
    description:
      "There is a fix 5€ fee for all Crypto Transfers. There might also be some transactional fees depending on the coin and network used. We recommend using Binance Exchange and USDT.",
    inputs: [
      {
        id: "coin",
        required: true,
        label: "Coin",
        custom: (props) => {
          const data = CRYPTO_COINS.map(genSelectables);

          return (
            <Finder
              {...props}
              data={data}
              idExtractor={(coin) => coin.id}
              onChange={(coin) => props.callback?.(coin.id)}
              value={data.find((coin) => coin.id === props.value)}
              update={(query) =>
                data.filter((coin) =>
                  coin.name?.toLowerCase().includes(query.toLowerCase())
                )
              }
            />
          );
        },
      },
      {
        id: "network",
        required: true,
        label: "Network",
        custom: (props) => {
          const data = CRYPTO_NETWORKS.map(genSelectables);
          return (
            <Finder
              {...props}
              data={data}
              idExtractor={(net) => net.id}
              onChange={(net) => props.callback?.(net.id)}
              value={data.find((net) => net.id === props.value)}
              update={(query) =>
                data.filter((net) =>
                  net.name?.toLowerCase().includes(query.toLowerCase())
                )
              }
            />
          );
        },
      },
      {
        id: "address",
        required: true,
        label: "Address",
        placeholder: "0x248A7F6D248....",
      },
      { id: "memo", label: "Memo" },
    ],
  },
  {
    id: "wire",
    label: "Wire Transfer",
    minAmount: 250,
    description:
      "There is a fix bank fee of 10€ per wire transfer. There also might be extra fees charged by your or intermediary banks during the wire process.",
    inputs: [
      { id: "beneficiary_name", required: true, label: "Beneficiary name" },
      {
        id: "beneficiary_address",
        required: true,
        label: "Beneficiary address",
      },
      { id: "bank_name", required: true, label: "Bank name" },
      { id: "bank_address", required: true, label: "Bank address" },
      { id: "swift_code", label: "Swift code / BIC " },
      { id: "iban", required: true, label: "IBAN Number" },
      { id: "account_no", required: true, label: "Account Number" },
      { id: "routing_no", label: "Sort / Routing number" },
      { id: "country", required: true, label: "Country" },
    ],
  },
  {
    id: "patreon",
    label: "Patreon",
    minAmount: 50,
    description:
      "Get paid with a pledge to your patreon account. There is a 22% Tax fee! Use this payout only if nothing else is available for you",
    inputs: [
      { id: "campaign_url", required: true, label: "Patreon Campaign URL" },
    ],
  },
];

// if one is used, others will be disabled
export const radios = {
  wire: ["iban", "account_no"],
};
