const stringifyFilters = (filters) => {
  let strigifiedFilters = {};
  Object.entries(filters)?.forEach(([key, value]) => {
    if ((key, value, !Array.isArray(value) && isNaN(value))) return;

    if (value.length === 0) return;

    strigifiedFilters[key] = !!value ? value.toString() : "";
  });

  return strigifiedFilters;
};

export default stringifyFilters;
