import React from "react";
import "./style.css";

const Divider = ({ children }) => {
  return (
    <div className="Divider">
      <span>{children}</span>
    </div>
  );
};

export default Divider;
