import React, { useMemo } from "react";
import useId from "../../hooks/useId";
import { getClass } from "../../utils/getClass";
import { paymentMethods, filterPaymentMethods } from "./data";

const PaymentOptions = ({
  selectedPaymentMethod,
  onChangePaymentMethod,
  onProceed,
  onSuccessfullBuy,
  title,
  totalPrice = {},

  selectablePaymentMethods = /** @type {Parameters<typeof filterPaymentMethods>} */ ([]),
}) => {
  const id = useId();
  const PaymentMethod = useMemo(() => {
    const fallback = () => null;
    const selected = paymentMethods.find(
      (method) => method.id === selectedPaymentMethod
    );
    return selected?.component ?? fallback;
  }, [selectedPaymentMethod]);

  return (
    <aside className="checkout-payment-info">
      <h2>
        {title ? <span>Checkout - {title}</span> : <span>Payment Info</span>}
      </h2>
      <div className="methods">
        {filterPaymentMethods(...selectablePaymentMethods).map((method) => (
          <div className="method" key={method.id}>
            <input
              type="radio"
              name={id + "method"}
              id={id + method.id}
              value={method.id}
              checked={method.id === selectedPaymentMethod}
              onChange={onChangePaymentMethod}
            />
            {method.icon && (
              <span className={getClass("fa", method.icon)}></span>
            )}
            <label htmlFor={id + method.id}>{method.name}</label>
          </div>
        ))}
      </div>
      <PaymentMethod
        onSuccessfullBuy={onSuccessfullBuy}
        onProceed={onProceed}
        totalPrice={totalPrice}
      />
    </aside>
  );
};

export default PaymentOptions;
