import React, { useState } from "react";
import { toast } from "react-toastify";
import RequestError from "../../../../errors/RequestError";
import { authRequest } from "../../../../utils/Axios";
import { SubmitButton } from "../../Common";

export const HideDownload = ({ download, refresh, onClose }) => {
  const [error, setError] = useState();

  const sendLink = async () => {
    try {
      const res = await authRequest({
        url: "/downloads/api/games/downloads/hide",
        method: "POST",
        data: {
          download_id: download.id,
          hidden: !download.hidden,
        },
      });
      if (res.success) refresh?.();
      onClose?.();
      toast.success(
        download.hidden
          ? `Download ${download.name} have been shown successfully`
          : `Download ${download.name} have been hidden successfully`
      );
    } catch (error) {
      setError(error.message);
    }
  };

  if (download.hidden)
    return (
      <div className="AddDownload">
        <h2>
          Show <strong>{download.name}</strong>?
        </h2>

        <div className="info">
          <p>
            You are about to show this download. Showing a download will make it
            visible in the downloads section of the game page.
          </p>
        </div>

        <RequestError error={error} />
        <SubmitButton label="Show" request={sendLink} />
      </div>
    );
  return (
    <div className="AddDownload">
      <h2>
        Hide <strong>{download.name}?</strong>
      </h2>

      <div className="info">
        <p>
          You are about to hide this download. Hiding a download will remove it
          from the downloads section of the game page.
        </p>
        <p>
          New users who is going to buy this game will not have this download.
        </p>
        <p>
          But users who bought this download already will have access to this
        </p>
      </div>

      <RequestError error={error} />
      <SubmitButton label="Hide" request={sendLink} />
    </div>
  );
};
