import React from "react";

import "./style.css";
import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";
import { EmptyView } from "src/components/UtilityComponents/EmptyView";

export function getColumn(title, { sortKey, accessFn, accessKey }) {
  return {
    title,
    sortKey,
    accessFn,
    accessKey,
  };
}

/**
 *
 * @param {Object} props
 * @param {ReturnType<typeof getColumn>[]} props.columns
 * @param {Object[]} props.data
 * @param {() => void} props.refreshData refresh the data array
 * @param {string} props.columnCSSConfig
 * @param {(record:any) => any[]} props.getOptions
 * @param {(record:any) => Object} props.getPopupData
 * @param {any[]} props.indicators If multiple indicators on, Color of first one will be shown
 * @param {(key:string, order: "dsc"|"asc") => void} props.onSort
 * @returns
 */
export default function Table({
  columns = [],
  data = [],
  getOptions,
  getPopupData,
  onSort,
  indicators = [],
  columnCSSConfig,
  refreshData,
}) {
  return (
    <div
      className="Table"
      style={{ "--col-count": columns.length, "--columns": columnCSSConfig }}
    >
      <div className="header">
        {columns.map((column, id) => (
          <TableHeader key={id} column={column} onSort={onSort} />
        ))}
      </div>
      <div className="indicators">
        {indicators.map((indicator, id) => (
          <div className="indicator" key={id}>
            <span
              className="color"
              style={{ backgroundColor: indicator.color }}
            ></span>
            <span>{indicator.name}</span>
          </div>
        ))}
      </div>

      <div className="content">
        {data?.length > 0 ? (
          data.map((record, id) => (
            <TableRow
              refreshData={refreshData}
              key={id}
              columns={columns}
              record={record}
              getOptions={getOptions}
              getPopupData={getPopupData}
              indicators={indicators}
            />
          ))
        ) : (
          <EmptyView text={"No Data Yet"} />
        )}
      </div>
    </div>
  );
}
export const NotAvailableNode = () => (
  <div className="not-available">Not Available</div>
);
