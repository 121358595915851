import { useEffect, useRef, useState } from "react";
import { ImagePermissions } from "src/components/CMS/SpicyArt/UploadCollection/Image";
import { InfoContext } from "src/components/CMS/SpicyArt/UploadCollection/UploadCollection";
import UploadImages from "src/components/CMS/SpicyArt/UploadCollection/UploadImages";
import { getCredentails } from "src/components/Validation";
import useThrottleRequests from "src/hooks/useThrottleRequests";

const PublicGalleryImageUpload = ({
  galleryName,
  galleryID,
  uploadedImages = [],
}) => {
  const { throttle, updateThrottle } = useThrottleRequests();
  const [images, setImages] = useState([]);
  const [metaInfo, setMetaInfo] = useState({});

  const previouslyUploaded = useRef(new Set());
  const newlyUploaded = useRef(new Map()); // hash -> ID

  const { username } = getCredentails();

  useEffect(() => {
    const imagesUploadedByUser = uploadedImages.filter(
      (img) => img.uploader === username
    );
    setImages(
      imagesUploadedByUser.map((img) => ({
        id: img.id,
        hash: img.hash,
        online: true, // indicates these are not blobs
        url: img.image,
      }))
    );

    const metaEntries = imagesUploadedByUser.map((img) => [
      img.id,
      {
        description: img.description,
        tags: img.tags,
        id: img.id,
        aspect_ratio: img.aspect_ratio,
        game_id: img.game?.id,
        game_name: img.game?.title,
      },
    ]);

    setMetaInfo(Object.fromEntries(metaEntries));

    previouslyUploaded.current = new Set(
      imagesUploadedByUser.map((img) => img.hash)
    );
  }, [uploadedImages]);

  return (
    <div className="UploadCollection PublicGalleryImageUpload">
      <InfoContext.Provider
        value={{
          images,
          premium: [],
          metaInfo,
          setImages,
          setMetaInfo,
          previouslyUploaded,
          throttle,
          updateThrottle,
          newlyUploaded,
        }}
      >
        <UploadImages
          id={galleryID}
          permissions={[ImagePermissions.EDIT_METADATA]}
          onSave={() => {
            window.location.reload(true);
          }}
          customInstructions={
            <CustomPublicImageUploadInstructions galleryName={galleryName} />
          }
        />
      </InfoContext.Provider>
    </div>
  );
};

const CustomPublicImageUploadInstructions = ({ galleryName }) => {
  return (
    <>
      <p>Add your image to the {galleryName}!</p>
      <br />
      <p>
        By uploading an image, you agree to comply with our Terms of Service and
        Privacy Policy (link both). Any unlawful content posted will be promptly
        removed, and your account may be subject to suspension or termination.
      </p>
      <br />
      <p>
        We highly recommend adding metadata to your images as it significantly
        boosts their visibility in search results. Easily add metadata by
        clicking the edit button on the image.
      </p>
    </>
  );
};

export default PublicGalleryImageUpload;
