import { ErrorBoundary } from "@sentry/react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Redirect, Switch, useRouteMatch } from "react-router-dom";
import SentryRoute from "src/customRoute";
import { authRequest } from "src/utils/Axios";
import { ErrorBoundaryFallback } from "../ErrorBoundary";
import Error404 from "../Errors";
import SectionHome from "./SectionHome";
import SideMenu from "./SideMenu";
import "./css/Cms.css";

export const fetcher = async (url) => {
  try {
    const res = await authRequest({
      url,
      baseURL: "",
      method: "GET",
      withCredentials: true,
    });
    return res;
  } catch (error) {
    return [];
  }
};

const CMSContent = ({ paths }) => {
  const match = useRouteMatch();
  const [toggle, setToggle] = useState(false);
  const toggler = () => setToggle((prev) => !prev);

  return (
    <main id="cms">
      <div className="inner">
        <Helmet>
          <title>CMS - Spicygaming</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <SideMenu toggle={toggle} toggler={toggler} />

        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("location", "CMS");
          }}
          fallback={ErrorBoundaryFallback}
        >
          <Switch>
            <Redirect exact path={match.url} to={paths[0]?.path} />
            {paths.map((path) => (
              <SentryRoute path={path.path} key={path.path}>
                {(props) => (
                  <SectionHome
                    {...props}
                    toggler={toggler}
                    id={path.id}
                    props={path.routeProps}
                  />
                )}
              </SentryRoute>
            ))}
            <SentryRoute path="*" exact>
              <div className="body">
                <div className="content">
                  <Error404 />
                </div>
              </div>
            </SentryRoute>
          </Switch>
        </ErrorBoundary>
      </div>
    </main>
  );
};

export default CMSContent;
