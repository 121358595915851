import { usePostHog } from "posthog-js/react";
import React, { Suspense, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import PaymentOptions from "src/components/BuyTokens/PaymentOptions";
import SelectedPackageDetails from "src/components/BuyTokens/SelectedPackageDetails";
import { SubmitButton } from "src/components/CMS/Common";
import { LoaderInside } from "src/components/Common";
import Popup from "src/components/common/Popup";
import { ShareMenu } from "src/components/common/ShareMenu";
import ForceLogin from "src/components/ForceLogin";
import BundleProductUnavailableOverlay from "src/components/GameBundleDetails/BundleProductUnavailableOverlay";
import { LocalPrice } from "src/components/LocalPrice";
import { MultiLang } from "src/components/MultiLang";
import { MINIMUM_DIRECT_CHECKOUT_AMOUNT_SG } from "src/components/Payments/Popup";
import { generateURL } from "src/components/resizeImages";
import SpicyGuarantee from "src/components/SpicyGuarantee";
import { loggedInValidation } from "src/components/Validation";
import useBundleFinalPrice from "src/hooks/useBundleFinalPrice";
import { useToggle } from "src/hooks/useToggle";
import { useBroughtProducts } from "src/providers/BroughtProductsProvider";
import { PRODUCT_TYPES } from "src/providers/CartProvider";
import { useSpicyStore } from "src/providers/SpicyStoreProvider";
import { authRequest } from "src/utils/Axios";
import { convertToSpicyTokens } from "src/utils/currency-format";
import { getClass } from "src/utils/getClass";
import IconButton from "src/v2/components/IconButton";
import { getProductPrice } from "src/v2/services/storeService";
import useSWR, { useSWRConfig } from "swr";

const PaymentPopup = React.lazy(() => import("src/components/Payments/Popup"));

const GameBundle = ({ bundle = {}, minimalLayout }) => {
  const store = useSpicyStore();
  const history = useHistory();
  const swr = useSWRConfig();
  const posthog = usePostHog();

  const broughtProducts = useBroughtProducts();

  const bundlePrice = useBundleFinalPrice(bundle.products);

  const [showLoginPopup, toggleLoginPopup] = useToggle();
  const [showBuyPopup, toggleBuyPopup] = useToggle(false);
  const [showBundleShare, toggleBundleShare] = useToggle();
  const [showCCPopup, toggleCCPopup] = useToggle(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("credit_card");

  const findBroughtProduct = (productId, productType) => {
    const found = broughtProducts.find(
      (broughtProduct) =>
        productId === broughtProduct.product_id &&
        productType === broughtProduct.product_type
    );
    return !!found;
  };

  const latestPriceKey = [
    "get-product-price",
    bundle.id,
    PRODUCT_TYPES.GameBundle,
  ];

  const { data: latestPriceEur } = useSWR(latestPriceKey, () =>
    getProductPrice(
      bundle.id,
      PRODUCT_TYPES.GameBundle,
      bundlePrice.discountedPrice
    )
  );

  const buyBundle = async () => {
    posthog.capture("Tokens Pay Button Clicked", {
      tokens_amount: latestPriceEur,
      productId: bundle.id,
      productType: PRODUCT_TYPES.GameBundle,
    });

    if (!loggedInValidation()) return toggleLoginPopup();
    if (
      bundlePrice.discountedPrice > store.tokens &&
      bundlePrice.discountedPrice > MINIMUM_DIRECT_CHECKOUT_AMOUNT_SG
    ) {
      return toggleCCPopup();
    }

    toggleBuyPopup();
  };

  const onPayInstantlyWithTokens = async (amountSG) => {
    const res = await store.payWithSpicyTokens(
      amountSG,
      PRODUCT_TYPES.GameBundle,
      bundle.id
    );
    if (res.success) {
      toast.success(`You have successfully purchased bundle ${bundle.name}`);
      history.push("/library/purchased-games");
      await swr.mutate("brought-products", [], true);
    }
  };

  const redirectToBundle = () => {
    history.push(`/game-bundle/${bundle.id}`);
  };

  const onChangePaymentMethod = (e) => {
    setSelectedPaymentMethod(e.target.value);
  };

  const onProceed = async (data) => {
    if (!selectedPaymentMethod) throw new Error("Payment method not selected");

    try {
      return await authRequest(
        {
          url: "/store/api/members/cart/individual-checkout",
          method: "POST",
          data: {
            data: {
              product_type: PRODUCT_TYPES.GameBundle,
              product_id: bundle.id,
              price: latestPriceEur,
            },
            billing: data.billing,
            checkout_method: selectedPaymentMethod,
            transaction_token: data?.token,
          },
        },
        true
      );
    } catch (error) {
      if (error.response?.data?.code === "PRICE_CHANGED") {
        swr.mutate(latestPriceKey, latestPriceEur, true);
      }
      if (error.response?.data?.code === "INVALID_DISCOUNT") {
        window.alert(
          "This Sale or Offer has expired. We will refresh the page to get updated pricing."
        );
        window.location.reload();
      }
      throw new Error(error.response?.data?.error ?? error.error);
    }
  };

  const onSuccessfullBuy = async (orderId) => {
    if (orderId) {
      const info = await store.getOrderInfo(orderId);
      store.savePurchaseRecord(info);
    }
    await store.refresh();

    history.push("/library/purchased-games");
  };

  return (
    <>
      <Popup open={showLoginPopup} onClose={toggleLoginPopup}>
        <ForceLogin url={window.location.href} />
      </Popup>
      <ShareMenu
        onClose={toggleBundleShare}
        show={showBundleShare}
        shareLink={`${window.location.origin}/game-bundle/${bundle.id}`}
        shareText={`Buy Bundle ${bundle?.name} and Save More!`}
      />
      <div className="bg-neutral-800 p-8 relative rounded-xl">
        {!minimalLayout && (
          <IconButton
            className="text-white absolute right-0 top-0 m-6"
            onClick={toggleBundleShare}
            type="secondary"
            icon={<span className="fa fa-share-alt"></span>}
          />
        )}
        <div className="">
          <div className="flex gap-4 items-center text-xl text-white mb-2">
            <span className="fa fa-gamepad"></span>
            <div className="max-w-[60ch] inline-block line-clamp-1 font-normal">
              Buy {bundle.name} Bundle
            </div>
          </div>
          <div title={bundle.name} className="mb-2">
            {!!bundle.expiry && (
              <div className="text-yellow-500 text-base">
                Too Hot to Last! offer ends on{" "}
                <span className="font-semibold">
                  {new Date(bundle.expiry).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "long",
                  })}
                </span>
              </div>
            )}
          </div>
        </div>
        {!minimalLayout && (
          <div className="flex gap-2 mb-5 flex-wrap ">
            {bundle.products?.map((p) => (
              <div
                key={`${p.product_id}-${p.product_type}`}
                className={getClass(
                  " min-w-16 relative",
                  !p.can_buy && "grayscale cursor-not-allowed"
                )}
              >
                {!p.can_buy && (
                  <BundleProductUnavailableOverlay>
                    <span className="text-xs">Unavailable</span>
                  </BundleProductUnavailableOverlay>
                )}
                {findBroughtProduct(p.product_id, p.product_type) && (
                  <BundleProductUnavailableOverlay>
                    <Link
                      to="/library"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-xs"
                    >
                      Already In Library{" "}
                      <span className="fa fa-external-link"></span>
                    </Link>
                  </BundleProductUnavailableOverlay>
                )}
                <Link
                  to={`/game/${p.product_data?.game_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contents"
                >
                  <img
                    className="object-contain"
                    src={generateURL(150, 75, p.image)}
                    alt={p.product_name}
                    width={150}
                    height={75}
                  />
                </Link>
              </div>
            ))}
          </div>
        )}

        <div className="md:absolute md:top-full md:right-0 md:mr-8 md:-translate-y-1/2">
          <SpicyGuarantee hideBanner={bundlePrice.total === 0}>
            <div className="grid gap-y-3 place-items-end grid-cols-2 md:flex">
              {!minimalLayout && (
                <SubmitButton
                  type="secondary"
                  request={redirectToBundle}
                  label={"Bundle Info"}
                />
              )}
              <div className="flex col-span-full row-start-1">
                <div className="price text-4xl grid place-items-center text-[#ffc107] !leading-none !font-semibold px-5 border-r-2 border-r-yellow-500">
                  -{bundlePrice.rate}%
                </div>

                <div className="px-5 flex">
                  {bundle.price?.discounted > 0 ? (
                    <div className="leading-none">
                      <div className="w-fit ml-auto mr-0 text-xs leading-none">
                        <strike>
                          <LocalPrice
                            hideDisclaimer
                            amountInEUR={bundlePrice.total}
                            showOnlyLocalPrice
                          />
                        </strike>
                      </div>
                      <div className="text-3xl text-[#ffc107] font-medium">
                        <LocalPrice
                          hideDisclaimer
                          amountInEUR={bundlePrice.discountedPrice}
                          showOnlyLocalPrice
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="price free">Free</div>
                  )}
                </div>
              </div>
              <SubmitButton
                request={buyBundle}
                label={"Buy"}
                disabled={bundlePrice.discountedPrice === 0}
              />
            </div>
          </SpicyGuarantee>
        </div>
      </div>
      <Popup
        defaultClose={false}
        showCloseButton
        open={showCCPopup}
        maxWidth={1080}
        onClose={toggleCCPopup}
      >
        <div className="TokenBuyCheckout">
          <SelectedPackageDetails
            productId={bundle.id}
            productType={PRODUCT_TYPES.GameBundle}
            selectedPkg={{
              title: `Buy Bundle ${bundle?.name}`,
              spicy_tokens: convertToSpicyTokens(bundlePrice.discountedPrice),
              eur: bundlePrice.discountedPrice,
            }}
          />

          <PaymentOptions
            onChangePaymentMethod={onChangePaymentMethod}
            selectedPaymentMethod={selectedPaymentMethod}
            onProceed={onProceed}
            onSuccessfullBuy={onSuccessfullBuy}
            selectablePaymentMethods={["coingate", "credit_card"]}
          />
        </div>
      </Popup>

      <Suspense fallback={<LoaderInside />}>
        <PaymentPopup
          productId={bundle.id}
          minimumPrice={bundlePrice.discountedPrice}
          productType={PRODUCT_TYPES.GameBundle}
          initialPrice={bundlePrice.discountedPrice}
          show={!!bundle && showBuyPopup}
          onClose={() => {
            toggleBuyPopup();
          }}
          onPayTokens={onPayInstantlyWithTokens}
          title={`Buy Bundle ${bundle?.name}`}
          supportMsg={
            <MultiLang sl="Podpri razvijalca igre ter dodaj napitnino">
              Support the developer by paying above the minimum price
            </MultiLang>
          }
        />
      </Suspense>
    </>
  );
};

export default GameBundle;
