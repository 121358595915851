import React from "react";
import ContentCreateTeamMembers from "../ContentCreateTeam/ContentCreateTeamMembers";

const TeamMembers = ({ id, members }) => {
  return (
    <div id="content-creator" className="team-view">
      <section>
        <div className="create-team general" id="create-team">
          <ContentCreateTeamMembers defaults={members} team_id={id} />
        </div>
      </section>
    </div>
  );
};

export default TeamMembers;
