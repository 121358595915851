const { useMemo } = require("react");
const { useHistory } = require("react-router-dom");

/**
 * this checks if user is in a certain path.
 * ex => if he is in cms, check for "cms" url path block
 */
const useCheckForURLBlock = (block) => {
  const history = useHistory();

  const isInRoute = useMemo(() => {
    if (history.location.pathname.includes(block)) return true;
    return false;
  }, [window.location]);

  return isInRoute;
};

export default useCheckForURLBlock;
