import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../providers/LanguageProvider";
import { SEARCH_TYPES } from "./AdultGames/Filter";
import { Discord, Patreon, Social } from "./common/social";
import "./css/Header.css";
import GTags from "./GTags";
import { MultiLang } from "./MultiLang";
import { usePostHog } from "posthog-js/react";

/**
 * @param {Object} props
 * @param {({search, category})=> void} props.searchCallback
 * @param {boolean} props.searchBar
 * @param {boolean} props.gameManu
 * @param {string} props.defaultQuery
 * @returns
 */
const Header = ({
  gameManu,
  searchBar = true,
  defaultQuery = "",
  extra = null,
  searchCallback = (_query) => null,
}) => {
  const language = useLanguage();
  const submitRef = useRef(null);
  const posthog = usePostHog();
  const [query, setQuery] = useState({
    search: defaultQuery,
    category: "Games",
  });
  const links = [
    {
      text: { en: "Games", sl: "Igre" },
      link: "/porn-games",
      id: "Games",
    },
    {
      text: { en: "News", sl: "Novice" },
      link: "/articles",
      id: "News",
    },
  ];
  const getQuery = (e) =>
    setQuery((prev) => ({ ...prev, search: e.target.value }));
  const getCategory = (category) => () =>
    setQuery((prev) => ({ ...prev, category }));

  const giveQuery = () => {
    if (!!query.search?.length) {
      GTags.search(query.search);
      posthog?.capture("Home Header Search", {
        query: query.search,
        category: query.category,
      });
    }
    searchCallback(query);
  };

  const setSearchKey = (key) => () => {
    window.SEARCH_TYPE = key;
  };

  return (
    <header aria-label="Quick Access with search">
      <div className="social">
        <Social
          path="https://www.facebook.com/spicygamingnet"
          fa_name="fa-facebook"
          title="Spicygaming Facebook"
        />
        <Social
          path="https://www.twitter.com/spicygamingnet"
          fa_name="fa-twitter"
          title="Spicygaming Twitter"
        />
        <Social
          path="https://www.instagram.com/spicygamingnet"
          fa_name="fa-instagram"
          title="Spicygaming Instagram"
        />
        <Social
          path="https://www.youtube.com/spicygamingnet"
          fa_name="fa-youtube"
          title="Spicygaming Youtube"
        />
        <Social
          path="https://www.patreon.com/spicygaming"
          title="Spicygaming Patreon"
        >
          <Patreon />
        </Social>
        <Social
          path="https://discord.gg/Ajg3jMshds"
          title="Spicygaming Discord"
        >
          <Discord />
        </Social>
      </div>

      <div className="search-bar">
        <div className="tags">
          <Link
            to="/porn-games/1?sort=likes_count"
            onClick={setSearchKey(SEARCH_TYPES.best)}
          >
            <div className="tag">Best Porn Games</div>
          </Link>
          <Link to="/porn-games/custom/os/Android/1">
            <div className="tag">Best Android Porn Games</div>
          </Link>
          <Link to="/porn-games/custom/tags/3dcg/1">
            <div className="tag">Best 3D Porn Games</div>
          </Link>
          <Link to="/porn-games/custom/tags/2dcg/1">
            <div className="tag">Best Hentai Games</div>
          </Link>
        </div>
        {searchBar && (
          <div className="search">
            <input
              type="search"
              name=""
              id="search-input"
              onChange={getQuery}
              defaultValue={defaultQuery}
              onKeyPress={(e) =>
                e.key === "Enter" &&
                submitRef.current &&
                submitRef.current.click()
              }
              placeholder={{ en: "Search...", sl: "Išči" }[language]}
            />
            {gameManu && (
              <div className="dropdown">
                <div className="show">
                  <span>
                    {
                      links.find((l) => l.id === query.category)?.text?.[
                        language
                      ]
                    }
                  </span>
                  <div aria-hidden className="fa fa-chevron-down"></div>
                </div>
                <div className="hide">
                  {links.map((link, i) => (
                    <div onClick={getCategory(link.id)} key={i}>
                      {link.text?.[language]}
                    </div>
                  ))}
                </div>
              </div>
            )}

            <button
              ref={submitRef}
              className="fa fa-search"
              onClick={giveQuery}
              aria-label="Search"
            ></button>
          </div>
        )}
        {extra}
      </div>
    </header>
  );
};

export default Header;
