import React from "react";
import { authorizedRequest } from "../../Validation";
import { InnerHeader } from "../Headers";
import Tile from "../Tile";
import { approvalOptions } from "./Options";
import useSWRInfinite from "swr/infinite";
import { LoadMore } from "../../Common";
import { useContext } from "react";
import { RoleContext } from "../RoleContext";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { REVALIDATE_ON_FOCUS } from "../Common";
import { fetcher } from "../Cms";
// /products/api/games/unapproved

const GameList = ({ toggler, subHeader }) => {
  const { role } = useContext(RoleContext);

  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) => {
      return `${window.domainName}/products/api/games/unapproved?page=${
        index + 1
      }`;
    },
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );

  const games = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];

  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  const options = approvalOptions(role);
  const headers = ["TEAM", "LAST UPDATED", "DATE CREATED"];

  const search = async (query) => {
    const res = await authorizedRequest({
      url: `${window.domainName}/products/api/games/unapproved/cms/search?search_term=${query}`,
    });

    return res.flat().filter((obj) => !(obj.error || obj.last_page));
  };
  const searchCallback = (data) => {
    mutate(data, false);
  };

  return (
    <div className="body">
      <InnerHeader
        header="GAMES"
        toggler={toggler}
        subHeader={subHeader}
        element={"search"}
        search={{
          func: search,
          callback: searchCallback,
        }}
      />
      <div className="content">
        <main id="approve-game-list" className="cms-details">
          <div className="header">
            <div className="item title">game title</div>
            {headers.map((header, i) => (
              <div key={i} className="item">
                {header}
              </div>
            ))}
          </div>
          <p className="instructions list"> Game is unpublished</p>

          <TransitionGroup className="body" component="div">
            {games.map((game, i) => {
              const popupOptions = {
                title: game.title,
                creator: game.creator,
                created: game.creation_date,
                id: game.id,
              };
              return (
                <CSSTransition key={i} timeout={300} classNames="popup">
                  <Tile
                    headers={headers}
                    options={options}
                    data={game}
                    revalidate={() => mutate(games, true)}
                    banned={!game.published}
                    popupData={{
                      delete: { ...popupOptions },
                      approve: { ...popupOptions },
                      deny: { ...popupOptions },
                      edit: { id: game.id },
                      preview: { id: game.id },
                    }}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
          {errors && <div className="error">{errors.error}</div>}
          {error && <div className="error">{error.message}</div>}
          <LoadMore onClick={() => setSize((prev) => prev + 1)} />
        </main>
      </div>
    </div>
  );
};

export default GameList;
