// development urls
export const DEV_STORAGE_URL = "https://betastorage.spicygaming.net";
export const DEV_CDN_URL = "https://apibt.spicygaming.net";
export const DEV_URL = "https://apibeta.spicygaming.net";
export const DEV_TUS_URL = "https://betacontabo.spicygaming.net";

// production urls
export const STORAGE_URL = "https://storage.spicygaming.net";
export const PROD_CDN_URL = "https://spcyapi.spicygaming.net";
export const PROD_URL = "https://api.spicygaming.net";
export const PROD_TUS_URL = "https://contabo.spicygaming.net";
