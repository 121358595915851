import React, { Suspense, useEffect } from "react";
import ReactGa from "react-ga";
import { Redirect, Switch, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdultGames from "./components/AdultGames/AdultGames";
import CustomGameSearch from "./components/AdultGames/CustomSearch";
import Article from "./components/Article";
import ArticleSearch from "./components/Article/ArticleSearch";
import {
  AdultIndustrySearch,
  BestOfBlogSearch,
  GameNewsSearch,
  InterviewsArticlesSearch,
  ReviewArticlesSearch,
} from "./components/Article/PredefinedSearches";
import BlogArticle from "./components/ArticleDetails/BlogArticle";
import NewsArticle from "./components/ArticleDetails/NewsArticle";
import StaffArticle from "./components/ArticleDetails/StaffArticle";
import CMS from "./components/CMS/CmsWrapper";
import { LoaderInside } from "./components/Common";
import DiscordAuth from "./components/DiscordAuth";
import EmailVerification from "./components/EmailVerification";
import Error404 from "./components/Errors";
import { FAQ } from "./components/FAQ";
import ForgotPassword1 from "./components/ForgotPassword1";
import ForgotPassword2 from "./components/ForgotPassword2";
import GameDetail from "./components/GameDetails/GameDetail";
import GameDevelopers from "./components/GameDevelopers";
import Home from "./components/Home";
import { Library } from "./components/Library";
import Login from "./components/Login";
import Login2FA from "./components/Login2FA";
import LoginRedirect from "./components/LoginRedirect";
import Logout from "./components/Logout";
import Member from "./components/Member/Member";
import MemberPublicProfile from "./components/Member/PublicProfile";
import Nav from "./components/Nav";
import { PatreonAuth } from "./components/PatreonAuth/Success";
import PaymentCancel from "./components/Payments/CancelPage";
import PaymentError from "./components/Payments/ErrorPage";
import PaymentSuccess from "./components/Payments/SuccessPage";
import { PlaylistsFullPage } from "./components/Playlists/FullPage";
import {
  BlogArticlePreview,
  GamePreview,
  NewsArticlePreview,
  StaffArticlePreview,
} from "./components/Previews/PreviewPages";
import Register from "./components/Register";
import { FullCollectionPage } from "./components/SpicyArt/FullCollectionPage";
import { GallerySearchPage } from "./components/SpicyArt/GallerySearchPage";
import About from "./components/Static/About";
import Advertise from "./components/Static/Advertise";
import ContactUs from "./components/Static/ContactUs";
import CookiesPolicy from "./components/Static/CookiesPolicy";
import PrivacyPolicy from "./components/Static/PrivacyPolicy";
import TOS from "./components/Static/TOS";
import TeamPublicProfile from "./components/TeamDeveloper/PublicProfile";
import { EmbedVideo } from "./components/Video/EmbedVideo";
import VideoFullPage from "./components/Video/FullPage/FullPage";
import { VideoSearchPage } from "./components/Video/SearchPage";
import AdultWarn from "./components/Warnings/AdultWarn";
import CookieWarning from "./components/Warnings/CookieWarning";
import SentryRoute from "./customRoute";
import Providers from "./providers";
import { BASE_URL } from "./utils/Axios";
import loadScript from "./utils/loadScript";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-toastify/dist/ReactToastify.css";
import "react-trumbowyg/dist/trumbowyg.min.css";
import "./components/css/Common.css";
import "./tailwind.css";
import "./App.css";
import useQuery from "./hooks/useQuery";
import setCookie from "./utils/setCookie";
import { usePostHog } from "posthog-js/react";
import GameBundleDetail from "./components/GameBundleDetails/GameBundleDetail";
import ExternalLinkRedirect from "./components/ExternalLinkRedirect";
import LinkExchange from "./components/Static/LinkExchange";

const Footer = React.lazy(() => import("./components/Footer"));

export const BUILD_STATUS = process.env.REACT_APP_NODE_ENV;
export const isDev = BUILD_STATUS === "development";

// google anylitics
ReactGa.initialize(process.env.REACT_APP_GTAG_ID);

function App() {
  //backend domain  [MUST NOT INCLUDE '/' AT END]
  window.domainName = BASE_URL;

  const history = useHistory();
  const query = useQuery();
  const posthog = usePostHog();

  useEffect(() => {
    history.listen(() => {
      document.getElementById("SpicyGaming").scrollIntoView();
    });
  }, [history]);

  useEffect(() => {
    const affiliateId = query.get("af");
    if (affiliateId) {
      setCookie("affiliate", affiliateId, 1, ".spicygaming.net");
      posthog.capture("Affiliated User", { affiliateId });
    }

    if (window._ASO) {
      window._ASO.PostbackOptions = {
        endpointUrl: "https://aff.spicygaming.net/postback?pid=6XxtjG98AW",
      };
      loadScript("https://aff.spicygaming.net/postback.js", "ad-postback", {
        "data-cfasync": false,
      });
    }
  }, []);
  return (
    <Providers>
      <AdultWarn />

      <Nav />

      <Switch>
        {/* redirects */}
        <Redirect path="/images" to="/" />
        <Redirect path="/article" exact to="/articles" />
        <Redirect path="/game" exact to="/porn-games/1" />
        <Redirect path="/porn-games" exact to={"/porn-games/1"} />
        <Redirect path="/adult-games/*" exact to={"/porn-games/1"} />
        <Redirect path="/game-developers" exact to={"/game-developers/1"} />
        <Redirect path="/videos" exact to={"/videos/1"} />
        <Redirect path="/video" exact to={"/videos/1"} />
        <Redirect path="/gallery" exact to={"/galleries/1"} />
        <Redirect path="/galleries" exact to={"/galleries/1"} />

        <SentryRoute exact path="/" component={Home} />

        <SentryRoute
          exact
          path="/articles/porn-game-news/:page?"
          component={GameNewsSearch}
        />
        <SentryRoute
          exact
          path="/articles/news-from-adult-industry/:page?"
          component={AdultIndustrySearch}
        />

        <SentryRoute
          exact
          path="/articles/best-of-porn/:page?"
          component={BestOfBlogSearch}
        />
        <SentryRoute
          exact
          path="/articles/porn-game-reviews/:page?"
          component={ReviewArticlesSearch}
        />
        <SentryRoute
          exact
          path="/articles/interviews-adult-content-creators/:page?"
          component={InterviewsArticlesSearch}
        />
        <SentryRoute exact path="/articles" component={Article} />
        <SentryRoute
          exact
          path="/articles/:query?/:page"
          component={ArticleSearch}
        />

        <SentryRoute
          exact
          path="/porn-games/custom/:type/:param/:query?/:page"
          component={CustomGameSearch}
        />
        <SentryRoute
          exact
          path="/porn-games/:query?/:page"
          component={AdultGames}
        />
        <SentryRoute
          exact
          path="/videos/tag/:tag/:page?"
          component={VideoSearchPage}
        />
        <SentryRoute
          exact
          path="/videos/:query?/:page/"
          component={VideoSearchPage}
        />
        <SentryRoute
          exact
          path="/galleries/tag/:tag/:page?"
          component={GallerySearchPage}
        />
        <SentryRoute
          exact
          path="/galleries/:query?/:page/"
          component={GallerySearchPage}
        />
        <SentryRoute
          exact
          path="/game-developers/:query?/:page"
          component={GameDevelopers}
        />
        {/* Authentication */}
        <SentryRoute exact path="/login" component={Login} />
        <SentryRoute exact path="/login/2fa" component={Login2FA} />
        <SentryRoute exact path="/register" component={Register} />
        <SentryRoute
          exact
          path="/forgot-password"
          component={ForgotPassword1}
        />
        <SentryRoute exact path="/logout" component={Logout} />
        <SentryRoute exact path="/login/:link+" component={LoginRedirect} />
        <SentryRoute exact path="/email/:code" component={EmailVerification} />
        <SentryRoute
          exact
          path="/discord-auth-success"
          component={DiscordAuth}
        />
        <SentryRoute
          exact
          path="/patreon-auth-success"
          component={PatreonAuth}
        />
        {/* payments */}
        <SentryRoute exact path="/orders/success" component={PaymentSuccess} />
        <SentryRoute exact path="/orders/error" component={PaymentError} />
        <SentryRoute exact path="/orders/cancel" component={PaymentCancel} />

        {/* Main Content */}
        {/* removed exact due to inner routes */}
        <SentryRoute path="/team/@:user" component={TeamPublicProfile} />
        <SentryRoute exact path="/@:user" component={MemberPublicProfile} />
        <SentryRoute exact path="/video/:id" component={VideoFullPage} />
        <SentryRoute exact path="/gallery/:id" component={FullCollectionPage} />
        <SentryRoute exact path="/video/embed/:id" component={EmbedVideo} />
        <SentryRoute exact path="/playlist/:id" component={PlaylistsFullPage} />
        <SentryRoute exact path="/game/:game" component={GameDetail} />
        <SentryRoute exact path="/redirect" component={ExternalLinkRedirect} />
        <SentryRoute
          exact
          path="/game-bundle/:id"
          component={GameBundleDetail}
        />
        <SentryRoute
          exact
          path="/news-article/:article"
          component={NewsArticle}
        />
        <SentryRoute
          exact
          path="/blog-article/:article"
          component={BlogArticle}
        />
        <SentryRoute
          exact
          path="/staff-review/:article"
          component={StaffArticle}
        />
        {/* previews */}
        <SentryRoute exact path="/game/preview/:game" component={GamePreview} />
        <SentryRoute
          exact
          path="/news-article/preview/:article"
          component={NewsArticlePreview}
        />
        <SentryRoute
          exact
          path="/blog-article/preview/:article"
          component={BlogArticlePreview}
        />
        <SentryRoute
          exact
          path="/staff-review/preview/:article"
          component={StaffArticlePreview}
        />
        <SentryRoute
          exact
          path="/forgot-password/:reset_code"
          component={ForgotPassword2}
        />
        {/* must NOT contain 'extact' because it has its own routing inside */}
        <SentryRoute path="/member" component={Member} />
        <SentryRoute path="/library" component={Library} />
        <SentryRoute path="/cms" component={CMS} />
        {/* <SentryRoute path="/developer-team" component={Team} /> */}
        {/* Static pages */}
        <SentryRoute path="/terms-of-service" exact component={TOS} />
        <SentryRoute path="/privacy-policy" exact component={PrivacyPolicy} />
        <SentryRoute path="/cookies-policy" exact component={CookiesPolicy} />
        <SentryRoute path="/about" exact component={About} />
        <SentryRoute path="/advertise" exact component={Advertise} />
        <SentryRoute path="/contact" exact component={ContactUs} />
        <SentryRoute path="/frequently-asked-questions" exact component={FAQ} />
        <SentryRoute path="/spicy-partners" exact component={LinkExchange} />

        {/* Error 404 */}
        <SentryRoute path="*">
          <Error404 />
        </SentryRoute>
      </Switch>
      <CookieWarning />

      <Suspense fallback={<LoaderInside />}>
        <Footer />
      </Suspense>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        limit={5}
        pauseOnHover
        theme="dark"
        closeButton
        toastStyle={{
          fontFamily: "Rubik, sans-serif",
          backgroundColor: "#222",
        }}
      />
    </Providers>
  );
}

export default App;
