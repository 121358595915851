import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { authRequest, request } from "src/utils/Axios";
import Input from "src/v2/components/form-inputs/Input";
import SearchSelect from "src/v2/components/form-inputs/SearchSelect";
import Form from "src/v2/components/form/Form";
import { getAllTeamsOfUserInCMS } from "src/v2/services/userService";
import useSWR from "swr";
import { z } from "zod";
import { MediaUpload, SubmitButton } from "../../Common";
import { useTeamID } from "../../RoleContext";
import { searchGamesForFinder } from "./utils";

const DEFAULT_IMAGES = {
  landscape_banner: [],
  square_banner: [],
};

const getCategoryFilters = async () => {
  const res = await request({
    method: "GET",
    url: "/products/api/game-filters",
    params: {
      title: "post_category",
    },
  });
  return res.option;
};

const BlogArticleInfo = ({
  defaults,
  editMode,
  postID,
  setPostID,
  setGalleryID,
}) => {
  const history = useHistory();

  const [images, setImages] = useState(defaults?.images || DEFAULT_IMAGES);

  const teamID = useTeamID();

  const { data: games } = useSWR(["creator-games"], () =>
    searchGamesForFinder()
  );
  const { data: teams } = useSWR(["creator-teams"], () =>
    getAllTeamsOfUserInCMS()
  );

  const { data: categories } = useSWR(["article-categories"], () =>
    getCategoryFilters()
  );

  const form = useForm({
    defaultValues: {
      category: defaults?.formData.category,
      short_description: defaults?.formData.short_description,
      title: defaults?.formData.title,
      game_id: defaults?.formData.game_id,
      team: defaults?.formData.creator_id ?? teamID,
    },
    resolver: zodResolver(
      z.object({
        game_id: z.number().optional(),
        team: z.number().optional(),
        category: z.string(),
        short_description: z.string().max(250),
        title: z.string(),
      })
    ),
  });

  useEffect(() => {
    if (editMode) setImages(defaults?.images);
  }, [defaults?.images]);

  const onSubmit = async () => {
    try {
      const isValid = await form.trigger();
      if (!isValid) throw new Error("Invalid Inputs");

      const data = form.getValues();

      if (!(images?.landscape_banner?.length > 0))
        throw new Error("Add Landscape Banner");
      if (!(images?.square_banner?.length > 0))
        throw new Error("Add Square Banner");

      const fd = new FormData();

      fd.append("preview", true);

      for (const key in data) {
        if (data[key]) fd.append(key, data[key]);
      }

      // adding images
      for (const image in images) {
        images[image].forEach((img) => fd.append(`${image}[]`, img));
      }

      const BACKEND_URL = editMode
        ? `/news/api/news/blog-posts/edit`
        : `/news/api/news/blog-posts`;

      const res = await toast.promise(
        authRequest({
          url: BACKEND_URL,
          data: fd,
          method: "POST",
          params: {
            article_id: postID,
          },
        }),
        { pending: "Sending Request", success: "Successfully saved" }
      );

      if (res.success && res.post_id) {
        setPostID(res.post_id);
        setGalleryID(res.media_gallery);
        history.push(
          generatePath("/cms/content/blog-articles/edit/:id/content", {
            id: res.post_id,
          })
        );
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div>
      <section className="ArticleBasicInfo">
        <legend>Site news article DESCRIPTION</legend>

        <Form form={form}>
          <div className="content">
            <Form.Item label="Article Title" name="title">
              <Input />
            </Form.Item>

            <Form.Item label="Select Category" name="category">
              <SearchSelect defaultSelectedLabel={defaults?.formData.category}>
                {categories?.map((category) => (
                  <SearchSelect.Option
                    label={category.name}
                    value={category.param}
                    key={category.param}
                  />
                ))}
              </SearchSelect>
            </Form.Item>

            <Form.Item label="Select Game" name="game_id">
              <SearchSelect defaultSelectedLabel={defaults?.formData.game_name}>
                {games?.map((game) => (
                  <SearchSelect.Option
                    label={game.name}
                    value={game.id}
                    key={game.id}
                  />
                ))}
              </SearchSelect>
            </Form.Item>

            <Form.Item label="Select Developer Team" name="team">
              <SearchSelect
                defaultSelectedLabel={defaults?.formData.creator_name}
              >
                {teams?.map((team) => (
                  <SearchSelect.Option
                    label={team.name}
                    value={team.id}
                    key={team.id}
                  />
                ))}
              </SearchSelect>
            </Form.Item>

            <Form.Item label="Short Description" name="short_description">
              <Input />
            </Form.Item>
          </div>
        </Form>
      </section>
      <section id="banners">
        <legend>BANNER FIELD</legend>
        <div className="content">
          <MediaUpload
            size="1920x1080"
            aspect={{ width: 1920, height: 1080 }}
            text="Add Landscape banner"
            onClick={setImages}
            id="landscape_banner"
            defaults={{
              imagefiles: defaults?.images?.landscape_banner,
            }}
          />
          <MediaUpload
            size="500x500"
            text="Add Square banner"
            aspect={{ width: 500, height: 500 }}
            onClick={setImages}
            id="square_banner"
            defaults={{
              imagefiles: defaults?.images?.square_banner,
            }}
          />
        </div>
      </section>
      <SubmitButton label="Submit" request={onSubmit} />
    </div>
  );
};

export default BlogArticleInfo;
