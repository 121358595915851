import { Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useQuery from "src/hooks/useQuery";
import SubmitButton from "src/v2/components/SubmitButton";
import {
  decodeRedirectData,
  reportGameDownload,
} from "src/v2/services/gamesService";

const ExternalLinkRedirect = () => {
  const query = useQuery();
  const history = useHistory();

  const to = query.get("to");

  if (!to) return <Redirect to="/porn-games/1" />;

  const data = decodeRedirectData(decodeURIComponent(to));

  const report = async () => {
    try {
      await reportGameDownload(data.gameId, data.uuid);
      toast.warn("Successfully Reported Link. Thank you.");
      history.push("/porn-games/1");
    } catch {
      toast.error("Error Occured. Couldn't report the game");
    }
  };

  return (
    <main>
      <div className="inner">
        <div className="grid gap-5 place-items-center py-20  ">
          <img
            src="/images/logo.png"
            alt="Spicygaming Logo"
            width={300}
            height={160}
          />

          <h1>You're leaving Spicygaming</h1>
          <p className="max-w-screen-md">
            You are about to access a file outside of SpicyGaming. While we make
            efforts to verify these files, please remain cautious. If you
            encounter any issues, such as a broken link, missing file, or any
            corruption,{" "}
            <button className="underline" onClick={report}>
              click here to report it
            </button>
            .
          </p>
          <a
            href={data.redirectLink}
            className="contents"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SubmitButton icon="fa-file">Continue to File</SubmitButton>
          </a>
        </div>
      </div>
    </main>
  );
};

export default ExternalLinkRedirect;
