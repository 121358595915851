import dateFormat from "dateformat";
import { useContext } from "react";
import { authRequest } from "src/utils/Axios";
import useSWRInfinite from "swr/infinite";
import { LoadMore, sort } from "../../../Common";
import { fetcher } from "../../Cms";
import { RoleContext } from "../../RoleContext";
import Table, { getColumn } from "../../Table/Table";
import { toSpicyTokensWithEuro } from "../../Users/Withdraw";
import { salesOptions } from "../Options";

const columns = [
  getColumn("PRODUCT", { sortKey: "product", accessKey: "product" }),
  getColumn("TEAM", { sortKey: "team", accessKey: "team" }),
  getColumn("TRANSACTION ID", {
    sortKey: "transaction_id",
    accessKey: "transaction_id",
  }),
  getColumn("CREATED ON", {
    sortKey: "creation_date",
    accessFn: (sale) => dateFormat(new Date(sale.creation_date), "longDate"),
  }),
  getColumn("AMOUNT", {
    sortKey: "amount",
    accessFn: (sale) => toSpicyTokensWithEuro(sale.amount - sale.fee),
  }),
];

const AllPurchaseList = () => {
  const { role } = useContext(RoleContext);

  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) =>
      `${window.domainName}/store/api/teams/sales/list?page=${index + 1}`,
    fetcher,
    { revalidateOnFocus: false }
  );

  const sales = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];
  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  const search = async (query) => {
    const res = await authRequest({
      url: `/store/api/teams/sales/list?search_term=${query}`,
    });

    if (res.error) return [];
    return res;
  };
  const searchCallback = (data) => {
    mutate(data, false);
  };

  return (
    <div className="content">
      <Table
        columns={columns}
        data={sales}
        getOptions={(sale) => salesOptions(role, sale)}
        getPopupData={(sale) => ({ show_full: { sale } })}
        refreshData={() => mutate(sales, true)}
        onSort={(key, order) => mutate(sort(key, sales, order), false)}
        indicators={[
          {
            color: "#00b4f2",
            when: (sale) => sale.can_be_refunded,
            name: "Transaction can be Refunded",
          },
          {
            color: "#fb0400",
            when: (sale) => sale.is_refunded,
            name: "Transaction Refunded",
          },
        ]}
      />
      {errors && <div className="error">{errors.error}</div>}
      {error && <div className="error">{error.message}</div>}
      <LoadMore onClick={() => setSize((prev) => prev + 1)} />
    </div>
  );
};

export default AllPurchaseList;
