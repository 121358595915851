import React, { useMemo } from "react";
import { useCallback } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Error404 from "../Errors";
import { usePermissions } from "./Permission";
import { Roles, useRoleInfo } from "./RoleContext";
import SentryRoute from "src/customRoute";
import { ErrorBoundary } from "@sentry/react";
import { ErrorBoundaryFallback } from "../ErrorBoundary";
import VerifyTeamNotification from "./Users/ContentCreateTeam/VerifyTeamNotification";

const SectionHome = ({ match, toggler, id, props }) => {
  const { paths } = usePermissions();
  const { defaultTeam, role } = useRoleInfo();

  const routes = paths[id];

  const allRoutes = useMemo(() => Object.values(routes.paths), [routes]);

  const otherRoutes = useMemo(() => {
    if (!routes.otherPaths) return [];
    return Object.values(routes.otherPaths);
  }, [routes]);

  const mapper = useCallback(
    (route) => {
      const path = routes.path + route.path;
      if (
        !defaultTeam &&
        route.linkProps?.showTeamChoose &&
        role !== Roles.ADMIN
      )
        return (
          <Redirect
            key={route.path}
            from={path}
            to="/cms/personal/teams/create-team#NO_DEFAULT_TEAM"
          />
        );

      return (
        <SentryRoute
          exact
          key={route.path}
          path={path}
          {...props}
          {...route.routeProps}
        >
          {(props) => (
            <ErrorBoundary
              fallback={ErrorBoundaryFallback}
              beforeCapture={(scope) => {
                scope.setTag("location", "CMS");
                scope.setTag("Sub section", route.text);
                scope.setTag("Path", route.path);
              }}
            >
              <route.component
                toggler={toggler}
                header={routes.header}
                subHeader={route.text}
                link={routes.path + route.path}
                {...props}
              />
            </ErrorBoundary>
          )}
        </SentryRoute>
      );
    },
    [routes]
  );
  return (
    <div className="body">
      <VerifyTeamNotification />
      <Switch>
        <Redirect exact path={match.url} to={match.url + allRoutes[0]?.path} />

        {allRoutes.map(mapper)}
        {otherRoutes.map(mapper)}

        <SentryRoute path="*" exact>
          <div className="body">
            <div className="content">
              <Error404 />
            </div>
          </div>
        </SentryRoute>
      </Switch>
    </div>
  );
};

export default SectionHome;
