import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import AdultGames from "./AdultGames";
import { Helmet } from "react-helmet";

const CustomGameSearch = () => {
  const { params } = useRouteMatch();

  const metadata = {
    title: `Best ${
      params.param
    } Porn games of ${new Date().getFullYear()} | Browse, Download & Play`,
    description: `Download 100s of free ${
      params.param
    } porn games. Get latest ${
      params.param
    } adult games, sex games, hentai games for ${
      params.type === "os" ? params.param : "pc, mac and android"
    }`,
  };

  return (
    <>
      <Helmet>
        <title>{`${metadata.title}`}</title>
        <meta name="description" content={metadata.description} />
      </Helmet>
      <AdultGames param={params.param} type={params.type} />
    </>
  );
};

export const CustomLink = ({ children, type, param }) => {
  return <Link to={`/porn-games/custom/${type}/${param}/1`}>{children}</Link>;
};

export default CustomGameSearch;
