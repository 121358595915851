import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import relativeDate from "relative-date";
import useSWRInfinite from "swr/infinite";
import { Header, LoadMore, sort } from "../../Common";
import { fetcher } from "../Cms";
import "../css/GameCreation.css";
import { MainHeader } from "../Headers";
import { ContentTile } from "../Tile";
import { changeRequestsOptions } from "./Options";

const ChangeRequests = ({ toggler, subHeader }) => {
  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) =>
      `${
        window.domainName
      }/store/api/teams/payment-info/change-requests/list?page=${index + 1}`,
    fetcher,
    { revalidateOnFocus: false }
  );

  const requests = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];
  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  const sortState = (key) => (order) => {
    mutate(sort(key, requests, order), false);
  };

  const headers = [
    { text: "TEAM", sort: sortState("team") },
    { text: "REASON", sort: sortState("reason") },
    { text: "DATE", sort: sortState("creation_date") },
  ];

  const options = changeRequestsOptions();
  return (
    <div className="body">
      <MainHeader toggler={toggler} header={subHeader} buttons={false} />
      <div className="content">
        <main className="cms-details">
          <div
            className="header"
            style={{
              gridTemplateColumns: `1fr 1fr 1fr 1fr`,
            }}
          >
            {headers.map((header, i) => (
              <Header header={header} key={i} />
            ))}
          </div>
          <TransitionGroup className="body" component="div">
            {requests.map((request, i) => {
              const data = [
                request.team,
                request.reason,
                relativeDate(new Date(request.creation_date)),
              ];

              return (
                <CSSTransition
                  key={i}
                  timeout={300}
                  classNames="popup"
                  mountOnEnter
                >
                  <ContentTile
                    columns={headers.length + 1}
                    headers={headers.map((header) => header.text)}
                    data={data}
                    revalidate={() => mutate(requests, true)}
                    popupData={{
                      approve: {
                        ...request,
                        approve: true,
                      },
                      deny: {
                        ...request,
                        approve: false,
                      },
                    }}
                    options={options}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
          {errors && <div className="error">{errors.error}</div>}
          {error && <div className="error">{error.message}</div>}
          <LoadMore onClick={() => setSize((prev) => prev + 1)} />
        </main>
      </div>
    </div>
  );
};

export default ChangeRequests;
