import { useState } from "react";
import { TinyButtonLoader } from "src/components/Common";
import { getClass } from "src/utils/getClass";

/**
 *
 * @param {Object} props
 * @param {String | JSX.Element} props.children - The label of the button. User will see this
 * @param {String} props.id - element ID can be used for styling purposes
 * @param {Function} props.onClick - button onClick function
 * @param {boolean} props.disabled - button disabled
 * @param {String} props.className
 * @param {JSX.Element} props.icon
 * @param {"primary"|"secondary"} props.type
 */
const SubmitButton = (props) => {
  const {
    children,
    onClick,
    id,
    className,
    icon,
    title,
    type = "primary",
    disabled = false,
  } = props;
  const [loading, setLoading] = useState(false);
  const onButtonClick = async (e) => {
    if (disabled || loading) {
      e.preventDefault();
      return;
    }

    setLoading(true);
    await onClick?.(e);
    setLoading(false);
  };
  return (
    <button
      className={getClass(
        "loader ld-over-full-inverse bg-primary text-white py-3 px-4 rounded shadow w-fit font-normal mt-2 min-w-40 hover:bg-primary-contrast outline-none focus:ring focus:ring-primary-900",
        disabled && "disabled",
        type === "secondary" &&
          "bg-primary-900 hover:bg-primary-700 text-neutral-300 hover:text-white",
        className
      )}
      id={id || "game-creation-submit"}
      onClick={onButtonClick}
      data-disable={disabled}
      data-type={type}
      data-loading={loading}
      data-tooltip={title}
    >
      {typeof icon === "string" ? (
        <span className={getClass("fa", "mr-2", icon)}></span>
      ) : (
        icon
      )}

      {loading ? (
        <TinyButtonLoader backgroundColor="#fff" />
      ) : (
        <span className="label">{children}</span>
      )}
      <div className="ld">
        <div className="svg-loader">
          <svg className="svg-container" viewBox="0 0 100 100">
            <circle className="loader-svg bg" cx="50" cy="50" r="45"></circle>
            <circle
              className="loader-svg animate"
              cx="50"
              cy="50"
              r="45"
            ></circle>
          </svg>
        </div>
      </div>
    </button>
  );
};

export default SubmitButton;
