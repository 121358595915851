import { generateURL } from "../resizeImages";
const Trophy = ({ text, icon }) => (
  <div className="trophy">
    <div className="image">
      <img loading="lazy" src={generateURL(200, 200, icon)} alt={text} />
    </div>
    <div className="text">{text}</div>
  </div>
);
export default Trophy;
