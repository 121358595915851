import React, { useEffect, useState } from "react";
import { addImageFiles, Loader } from "../../../Common";
import Error404 from "../../../Errors";

import { authorizedRequest } from "../../../Validation";
import { idGen } from "../../Common";
import { getURLs } from "../../Games/EditGame";
import UploadStaffReviews from "../UploadStaffReviews";
import { imageLengthsCheck } from "./imagesCheck";

function EditStaffReviews({ match }) {
  const ID = match.params.id;
  const [article, setArticle] = useState({});
  const [images, setImages] = useState({
    gallery: [],
    landscape_banner: [],
    square_banner: [],
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getArticleData();
  }, [ID]);

  useEffect(() => {
    if (article.landscape_cover)
      addImageFiles(
        "landscape_banner",
        setImages,
        getURLs([article.landscape_cover])
      );
    if (article.square_cover)
      addImageFiles(
        "square_banner",
        setImages,
        getURLs([article.square_cover])
      );
  }, [article.landscape_banner, article.square_cover]);

  const getArticleData = async () => {
    setLoading(true);
    try {
      const res = await authorizedRequest({
        url: `${window.domainName}/news/api/news/staff-reviews/edit?article_id=${ID}`,
      });

      if (!res.error) {
        setArticle(res);
      } else {
        setError(true);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const formData = {
    short_description: article.short_description,
    title: article.title,
    game_id: article.game?.id,
    game_name: article.game?.title,
    score: article.score,
    creator_id: article.team?.id,
    creator_name: article.team?.name,
    media_gallery: article.media_gallery,
  };

  const objects =
    article.content?.elements?.map((object) => ({
      type: object.type,
      content: object.content,
      id: idGen(object.type),
      key: Math.random(),
      order: object.order,
    })) || [];

  if (error) return <ErrorPage />;
  if (loading) return <Load />;

  if (
    !imageLengthsCheck({
      inputRange: [[article.landscape_cover], [article.square_cover]],
      outputRange: [images.landscape_banner, images.square_banner],
    })
  )
    return <Load />;

  return (
    <UploadStaffReviews
      header={"GAME REVIEWS"}
      subHeader={"EDIT REVIEW"}
      editMode={true}
      defaults={{
        formData,
        objects,
        images,
        id: ID,
        revalidateArticleDate: getArticleData,
      }}
    />
  );
}
const Load = () => (
  <div className="body">
    <div className="content">
      <Loader />
    </div>
  </div>
);
export const ErrorPage = () => (
  <div className="body">
    <div className="content">
      <Error404 />
    </div>
  </div>
);

export default EditStaffReviews;
