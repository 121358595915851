const checkAspectRatio = (imageURL, aspectRatio, maxWidth) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      const { width, height } = image;
      const imageAspectRatio = width / height;

      if (imageAspectRatio === aspectRatio) {
        resolve({ width, height });
      } else {
        reject();
      }
      return image.remove();
    };
    image.src = imageURL;
  });
};
export const getGreatestCommonDivider = (width, height) => {
  return height === 0
    ? width
    : getGreatestCommonDivider(height, width % height);
};

export const extractIDsFromVideo = (videoURL) => {
  const pullzone = videoURL
    ?.match(/vz-.*\.b-cdn\.net/)?.[0]
    ?.replace(/\.b-cdn\.net/g, "");

  const video_id = videoURL?.match(/\/[\w-]+\//g)?.[0]?.replace(/\//g, "");

  return { pullzone, video_id };
};

export default checkAspectRatio;
