import React, { Suspense, useEffect, useState } from "react";
import Slider from "../Article/Slider";
import FavGameMini from "./FavGameMini";
import { getCredentails } from "../Validation";
import {
  MAX_ELEMS_IN_SLIDE,
  moveGame,
  removeFromState,
  removeGame,
  SECTIONS,
} from "./FavGames";
import { LoaderInside } from "../Common";
import { authRequest } from "../../utils/Axios";
import { useToggle } from "../../hooks/useToggle";
import { SubmitButton } from "../CMS/Common";
import { MultiLang } from "../MultiLang";
import Game from "../AdultGames/Game";
const AddFavGames = React.lazy(() => import("./AddFavGames"));

const FavGameWishlisted = () => {
  const { username } = getCredentails();
  const [gamesValue, setGamesValue] = useState(1);
  const [displayArray, setDisplayArray] = useState([]);
  const [games, setGames] = useState([]);
  const [open, toggleOpen] = useToggle();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getGames();
  }, []);

  useEffect(() => {
    setDisplayArray(games.slice(0, MAX_ELEMS_IN_SLIDE));
  }, [games]);

  const getGames = async () => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: `/api/members/${username}/gamer-profile/games`,
        params: {
          game_list: SECTIONS.wishlisted,
        },
      });
      setGames(res.games);
    } catch {
    } finally {
      setLoading(false);
    }
  };
  const options = [
    {
      icon: "fa-trash",
      text: "Remove Game",
      onClick: (id) =>
        removeGame(id, SECTIONS.wishlisted, removeFromState(id, setGames)),
    },
    // {
    //   icon: "fa-list-alt",
    //   text: "Move to Playing",
    //   onClick: (id) =>
    //     moveGame(
    //       id,
    //       { from: SECTIONS.wishlisted, to: SECTIONS.playing },
    //       removeFromState(id, setGames)
    //     ),
    // },
    // {
    //   icon: "fa-list-alt",
    //   text: "Move to Played",
    //   onClick: (id) =>
    //     moveGame(
    //       id,
    //       { from: SECTIONS.wishlisted, to: SECTIONS.played },
    //       removeFromState(id, setGames)
    //     ),
    // },
  ];

  return (
    <div className="adult-games">
      <SubmitButton
        className="add-game"
        request={toggleOpen}
        label={
          <>
            + <MultiLang sl="Dodaj igro">Add Games</MultiLang>
          </>
        }
      ></SubmitButton>
      <Suspense fallback={<LoaderInside />}>
        <AddFavGames
          open={open}
          onClose={toggleOpen}
          label={"add in wishlisted list"}
          section={SECTIONS.wishlisted}
        />
      </Suspense>
      {loading && <LoaderInside />}
      <div id="fav-game-playing">
        {/*   we map the filtered array like [1,2,3] */}
        {displayArray.map((game) => (
          <FavGameMini key={game.id} {...game} options={options} />
        ))}
      </div>
      <Slider
        value_of_section={gamesValue}
        func_of_section={setGamesValue}
        display_array_func={setDisplayArray}
        slides_array={games}
        num_of_elements_display={MAX_ELEMS_IN_SLIDE}
      />
    </div>
  );
};

export default FavGameWishlisted;
