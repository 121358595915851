import { toast } from "react-toastify";
import SubmitButton from "src/v2/components/SubmitButton";
import { deleteBundle } from "src/v2/services/gameBundlesService";

const DeleteBundle = ({ bundleId, bundleName, close }) => {
  const onDelete = async () => {
    try {
      await deleteBundle(bundleId);
      toast.warn(`Bundle ${bundleName} was deleted`);
      close?.();
    } catch (error) {
      toast.error(`Couldn't delete bundle. ${error.message}`);
    }
  };

  return (
    <section className="space-y-5">
      <legend className="text-xl uppercase space-x-3">
        <span className="fa fa-warning text-yellow-500"></span>
        <span>Delete {bundleName} bundle?</span>
      </legend>
      <p>
        Deleting the bundle will disband the associated offer group. If you'd
        like to create a new bundle, consider editing the existing one instead.
      </p>
      <div className="space-x-5 w-fit ml-auto mr-0">
        <SubmitButton type="secondary" onClick={close}>
          Keep the bundle
        </SubmitButton>
        <SubmitButton icon="fa-trash" onClick={onDelete}>
          Delete
        </SubmitButton>
      </div>
    </section>
  );
};

export default DeleteBundle;
