import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Loader, LoaderInside } from "src/components/Common";
import { Player } from "src/components/Video/Player";
import { authRequest } from "src/utils/Axios";

const MyLibrary = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getVideos();
  }, []);
  const getVideos = async () => {
    try {
      const res = await authRequest({
        url: `/community/playlists/user-library`,
      });
      setVideos(res);
    } catch (error) {
      toast.error("Failed");
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <LoaderInside />;
  return (
    <div className="MyPlaylists SearchPage">
      <div className="search_results_content">
        {videos.map((video) => (
          <Player key={video.id} video={video} />
        ))}
      </div>

      {videos.length === 0 && <h2>No Videos marked as watch later</h2>}
    </div>
  );
};

export default MyLibrary;
