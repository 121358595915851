import React, { createContext, useContext, useEffect, useState } from "react";
import { authRequest } from "../../utils/Axios";
import { loggedInValidation } from "../Validation";

const INITIAL_STATE = {
  liked_videos: [],
  updateMetaData: async () => {},
  ifLiked: () => false,
};
export const VideoMetaDataContext = createContext(INITIAL_STATE);

export const VideoMetaDataProvider = ({ children }) => {
  const [metaData, setMetaData] = useState(INITIAL_STATE);

  useEffect(() => {
    getMetaData();
  }, []);

  const getMetaData = async () => {
    try {
      const res = await authRequest({
        url: "/api/members/get-liked-videos",
      });
      if (loggedInValidation()) setMetaData(res);
    } catch {}
  };
  const ifLiked = (id) => metaData.liked_videos.includes(id);

  return (
    <VideoMetaDataContext.Provider
      value={{ ...metaData, updateMetaData: getMetaData, ifLiked }}
    >
      {children}
    </VideoMetaDataContext.Provider>
  );
};

export const useVideosMetaData = () => useContext(VideoMetaDataContext);
