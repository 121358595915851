import axios from "axios";
import { useContext, useEffect, useState } from "react";
import relativeDate from "relative-date";
import { authRequest } from "src/utils/Axios";
import { withoutLastPage } from "src/utils/withoutLastPage";
import useSWRInfinite from "swr/infinite";
import { LoadMore, sort } from "../../Common";
import { fetcher } from "../Cms";
import { REVALIDATE_ON_FOCUS } from "../Common";
import { InnerHeader } from "../Headers";
import { RoleContext } from "../RoleContext";
import Table, { getColumn } from "../Table/Table";
import { userListOptions } from "./Options";

const columns = [
  getColumn("USERNAME", { sortKey: "username", accessKey: "username" }),
  getColumn("USER ROLE", { sortKey: "role", accessKey: "role" }),
  getColumn("FOLLOWED GAMES", {
    sortKey: "followed_games",
    accessKey: "followed_games",
  }),
  getColumn("LAST ACTIVITY", {
    sortKey: "last_sign_in",
    accessFn: (user) => relativeDate(new Date(user.last_sign_in)),
  }),
  getColumn("EMAIL", { sortKey: "email", accessKey: "email" }),
];

const UserList = ({ header, subHeader, toggler }) => {
  const { role } = useContext(RoleContext);

  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) => `${window.domainName}/api/members/list?page=${index + 1}`,
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );

  const users = data ? withoutLastPage(data.flat()) : [];

  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  const options = userListOptions(role);

  const search = async (query) => {
    const res = await authRequest({
      url: `/api/members/cms/search?search_term=${query}`,
    });

    if (res.error) return [];
    return res;
  };
  const searchCallback = (data) => {
    mutate(data, false);
  };
  return (
    <div className="body">
      <InnerHeader
        toggler={toggler}
        header={header}
        subHeader={subHeader}
        element={"search"}
        search={{
          func: search,
          callback: searchCallback,
        }}
      />
      <div className="content">
        <Table
          columnCSSConfig="1fr 1fr 1fr 1fr 1.5fr"
          columns={columns}
          data={users}
          getOptions={() => options}
          getPopupData={(user) => ({
            send_tokens: {
              id: user.id,
              name: user.username,
            },
            ban: {
              banned: user.banned,
              username: user.username,
            },
            reward_game: {
              user_id: user.id,
            },
          })}
          refreshData={() => mutate(users, true)}
          onSort={(key, order) => mutate(sort(key, users, order), false)}
          indicators={[
            {
              color: "#fb0400",
              when: (user) => user.banned,
              name: "User banned",
            },
          ]}
        />
        {errors && <div className="error">{errors.error}</div>}
        {error && <div className="error">{error.message}</div>}
        <LoadMore onClick={() => setSize((prev) => prev + 1)} />
      </div>
    </div>
  );
};

export default UserList;
