import { ReactNode } from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import FormItem from "./FormItem";

/**
 * @typedef {Object} FormProps
 * @property {ReactNode} children - The child elements to be rendered inside the form.
 * @property {UseFormReturn} form - The react-hook-form instance for managing the form state.
 * @property {Function} [onFinish] - Optional callback function to be called when the form is submitted successfully.
 * @property {string} [id] - Optional ID for the form element.
 * @property {string} [className] - Optional CSS class name for the form element.
 */

/**
 * Form component for handling form submission using react-hook-form.
 * @param {FormProps} props - The properties passed to the form component.
 * @returns {JSX.Element} The rendered form component.
 */
function Form({ children, form, onFinish, id, className }) {
  /**
   * Handles form submission.
   */
  const onSubmit = (data) => {
    onFinish?.(data);
  };

  return (
    <FormProvider {...form}>
      <form
        className={className}
        id={id}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {children}
      </form>
    </FormProvider>
  );
}

Form.Item = FormItem;

export default Form;
