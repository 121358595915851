import React, { useEffect, useState } from "react";
import { Input } from "src/components/Member/General";
import { Checkbox } from "src/components/Register";
import { getClass } from "src/utils/getClass";
import { SubmitButton } from "../../Common";
import { toast } from "react-toastify";
import { authRequest } from "src/utils/Axios";
import { sleep } from "src/utils/sleep";
import { useCollectionInfo } from "./UploadCollection";
import { inputIteratorValidate } from "src/components/Validation";

const types = {
  FREE: "free",
  PREMIUM: "premium",
};

const CollectionPreference = ({ editMode, moveTab, id, setId }) => {
  const { collectionInfo, setCollectionInfo } = useCollectionInfo();
  const [type, setType] = useState(types.FREE);

  useEffect(() => {
    if (editMode) {
      setType(() => {
        if (collectionInfo.paid) return types.PREMIUM;
        return types.FREE;
      });
    }
  }, []);

  const onTypeChange = (e) => {
    setType(e.target.value);
  };

  const updateInfo = (data) => {
    setCollectionInfo((prev) => ({ ...prev, ...data }));
  };

  const submitPreference = async () => {
    try {
      if (type === types.PREMIUM && !collectionInfo.price)
        throw new Error("Premium Galleries must have a price");

      const noErrors = inputIteratorValidate(
        collectionInfo,
        "Empty text fields",
        ["price", "paid", "game_id", "game_name", "public", "allow_downloads"]
      );
      if (noErrors !== true) throw new Error(noErrors);

      const res = await toast.promise(
        authRequest({
          url: editMode
            ? "/products/api/spicyart/gallery/edit"
            : "/products/api/spicyart/gallery",
          method: editMode ? "PUT" : "POST",
          data: {
            ...collectionInfo,
            gallery_id: id,
            price: type === types.FREE ? 0 : collectionInfo.price,
            public: Boolean(
              type === types.PREMIUM ? false : collectionInfo.public
            ),
            allow_downloads: Boolean(
              type === types.PREMIUM ? false : collectionInfo.allow_downloads
            ),
          },
        }),
        {
          pending: editMode ? "Saving Changes..." : "Creating Collection...",
          success: editMode ? "Successfully edited" : "Successfully Created",
        }
      );

      if (res.success) {
        setId(res.id);
        sleep(200); // id has to be set to enable "images" section
        moveTab("images");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="CollectionPreference">
      <section>
        <legend>Set Gallery Type</legend>
        <div className="type-select">
          <Type
            name="type"
            id={types.FREE}
            icon="fa-gift"
            text="Free"
            onChange={onTypeChange}
            current={type}
          />
          <Type
            name="type"
            id={types.PREMIUM}
            icon="fa-money"
            text="Premium"
            onChange={onTypeChange}
            current={type}
          />
        </div>
      </section>

      <section>
        <legend>Set Additional Preferences</legend>
        <div className="preference">
          {type === types.FREE && (
            <>
              <Checkbox
                label={"Make this collection Public"}
                id={"public"}
                value={collectionInfo.public}
                callback={(_id, checked) => updateInfo({ public: checked })}
              />
              <Checkbox
                label={"Allow users to download this gallery"}
                id={"downloads"}
                value={collectionInfo.allow_downloads}
                callback={(_id, checked) =>
                  updateInfo({ allow_downloads: checked })
                }
              />
            </>
          )}

          {type === types.PREMIUM && (
            <Input
              label="Price (USD)"
              type="number"
              placeholder={"10.00"}
              value={collectionInfo.price ?? 0}
              callback={(price) =>
                updateInfo({ price: parseFloat(price) ?? 0 })
              }
            />
          )}
        </div>
      </section>
      <SubmitButton
        label={editMode ? "Save Changes" : "Create Gallery"}
        request={submitPreference}
      />
    </div>
  );
};

const Type = ({ id, name, icon, text, onChange, current }) => {
  return (
    <div className="CollectionType">
      <input
        onChange={onChange}
        type="radio"
        name={name}
        id={id}
        value={id}
        checked={id === current}
      />
      <label htmlFor={id}>
        <span className={getClass("fa", icon)}></span>
        <span>{text}</span>
      </label>
    </div>
  );
};

export default CollectionPreference;
