import { authRequest, BASE_CDN_URL, request } from "src/utils/Axios";
import { dateFormatForBackend } from "src/utils/date-format";

export async function createSale({
  name,
  teamId,
  startDate,
  expirationDate,
  isPublicSale,
}) {
  return await authRequest({
    url: "/store/api/teams/manage-sales",
    data: {
      name,
      public: isPublicSale,
      team_id: teamId,
      expiry: dateFormatForBackend(expirationDate),
      start_date: dateFormatForBackend(startDate),
    },
    method: "POST",
  });
}

export async function editSale({
  saleId,
  name,
  startDate,
  expirationDate,
  isPublicSale,
}) {
  await authRequest({
    url: "/store/api/teams/manage-sales",
    data: {
      name,
      public: isPublicSale,
      sale_id: saleId,
      expiry: dateFormatForBackend(expirationDate),
      start_date: dateFormatForBackend(startDate),
    },
    method: "PUT",
  });
}

export async function getAllSalesByTeamId(teamId) {
  return await authRequest({
    url: "/store/api/teams/manage-sales",
    method: "GET",
    params: { team_id: teamId },
  });
}

export async function getTeamSaleBySaleId(saleId, teamId) {
  return await authRequest({
    url: "/store/api/teams/manage-sales",
    method: "GET",
    params: { sale_id: saleId, team_id: teamId },
  });
}

export async function addProductsToSale({ saleId, products = [] }) {
  return await authRequest({
    url: "/store/api/teams/manage-sales/sale-products",
    method: "POST",
    data: {
      products,
      sale_id: saleId,
    },
  });
}

export async function deleteProductFromSale({
  saleId,
  productId,
  productType,
}) {
  return await authRequest({
    url: "/store/api/teams/manage-sales/sale-products",
    method: "DELETE",
    data: {
      sale_id: saleId,
      product_id: productId,
      product_type: productType,
    },
  });
}

export async function getOngoingSaleGames() {
  return await request({
    url: "/products/api/games/on-sale",
    method: "GET",
    baseURL: BASE_CDN_URL,
  });
}
