import { Roles } from "../CMS/RoleContext";

const exceptCreators = [Roles.MEMBER, Roles.STAFF, Roles.ADMIN, Roles.WRITER];

const notifications = [
  {
    title: { en: "Creator Updates and News", sl: "Novice razvijalcev" },
    items: [
      {
        name: {
          en: "Receive game news and updates",
          sl: "Sprejem novic o igrah ter posodobitvah",
        },
        key: "receive_creator_updates",
        types: ["email", "push"],
      },
    ],
  },
  {
    title: { en: "News & Updates", sl: "Novice" },
    items: [
      {
        name: { en: "Site News and updates", sl: "Novice ter novosti" },
        key: "receive_site_news",
        types: ["email", "push"],
      },
      {
        name: { en: "Promotions", sl: "Promocije" },
        key: "receive_promotions",
        types: ["email", "push"],
      },
      {
        name: { en: "Weekly Newsletter", sl: "Tedenske novice" },
        key: "receive_creator_newsletter",
        types: ["email", "push"],
        hideFor: exceptCreators,
      },
    ],
  },
  {
    title: { en: "Content creator notifications", sl: "Obvestila Razvijalcev" },
    hideFor: exceptCreators,
    items: [
      {
        name: { en: "Game Approved", sl: "Igra odobrena" },
        key: "receive_game_approval_msg",
        types: ["email", "push"],
      },
      {
        name: { en: "Game Deleted", sl: "Igra izbrisana" },
        key: "receive_game_deletion_msg",
        types: ["email", "push"],
      },
      {
        name: { en: "Article Approved", sl: "Članek odobren" },
        key: "receive_article_approval_msg",
        types: ["email", "push"],
      },

      {
        name: { en: "Team Member Added", sl: "Član dodan" },
        key: "receive_team_member_added",
        types: ["email", "push"],
      },
      {
        name: { en: "Team Member Removed", sl: "Član odstranjen" },
        key: "receive_team_member_removed",
        types: ["email", "push"],
      },
      {
        name: { en: "Team Member Role Changed", sl: "Sprememba statusa člana" },
        key: "receive_team_member_role_changed",
        types: ["email", "push"],
      },
      {
        name: { en: "Review Recieved", sl: "Prejet opis igre" },
        key: "receive_new_review",
        types: ["email", "push"],
      },
    ],
  },
  {
    title: { en: "Store", sl: "Trgovina" },
    hideFor: [Roles.MEMBER],
    items: [
      {
        name: { en: "Games sold", sl: "Prodane igre" },
        key: "receive_game_sale",
        types: ["email", "push"],
      },
    ],
  },
];

export default notifications;
