import { createContext, useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Loader } from "src/components/Common";
import { generateOriginalURL } from "src/components/resizeImages";
import RequestError from "src/errors/RequestError";
import { authRequest } from "src/utils/Axios";
import CreateGame from "./CreateGame";
import { GameEditDataResponse } from "./util";
import useQuery from "src/hooks/useQuery";

export const getURLs = (list = []) =>
  list?.map((url) => generateOriginalURL(url));

export const UUIDContext = createContext(null);

function EditGame({ header, subHeader }) {
  const { id: ID } = useParams();
  const searchParms = useQuery();

  const uuid = searchParms.get("uuid");

  const [game, setGame] = useState({});

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getGameData();
  }, [ID]);

  const getGameData = async () => {
    setLoading(true);
    try {
      const res = await authRequest({
        url: `/products/api/games/edit`,
        params: {
          game_id: ID,
        },
      });

      const game = GameEditDataResponse.parse(res);

      setGame(game);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (error)
    return (
      <Layout>
        <RequestError error={error} />
      </Layout>
    );
  if (loading)
    return (
      <Layout>
        <Loader />
      </Layout>
    );

  return (
    <UUIDContext.Provider value={uuid}>
      <CreateGame
        header={header}
        subHeader={subHeader}
        editMode={true}
        defaults={{
          refreshDefaults: getGameData,
          formData: game,
          ID,
        }}
      />
    </UUIDContext.Provider>
  );
}

export const useUUID = () => useContext(UUIDContext);

const Layout = ({ children }) => (
  <div className="body">
    <div className="content">{children}</div>
  </div>
);

export default EditGame;
