import React from "react";
import Popup from ".";
import { getClass } from "../../../utils/getClass";

const MenuPopup = ({
  children,
  onClose,
  open,
  anchorElement,
  className,
  ...popupProps
}) => {
  const position = anchorElement.current?.getBoundingClientRect();

  return (
    <Popup
      onClose={onClose}
      open={open}
      maxWidth={480}
      className={getClass("menu-popup", className)}
      {...popupProps}
      style={{
        "--left": position?.x,
        "--top": position?.y,
      }}
    >
      {children}
    </Popup>
  );
};
export default MenuPopup;
