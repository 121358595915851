import {
  ApproveComment,
  ApproveReport,
  FtpDownload,
  ViewComment,
  ViewReason,
} from "./OptionPopups";

export const gameModOptions = () => {
  const allPermissions = [
    {
      icon: "fa-eye",
      text: "View Full Reason",
      id: "view",
      popup: ViewReason,
    },
    {
      icon: "fa-check",
      text: "Unnapprove Game",
      id: "approve",
      popup: ApproveReport,
    },
    {
      icon: "fa-trash",
      text: "Dismiss Report",
      id: "deny",
      popup: ApproveReport,
    },
  ];
  return allPermissions;
};

export const commentsModOptions = () => {
  const allPermissions = [
    {
      icon: "fa-eye",
      text: "View Full Comment",
      id: "view",
      popup: ViewComment,
    },
    {
      icon: "fa-check",
      text: "Approve Comment",
      id: "approve",
      popup: ApproveComment,
    },
    {
      icon: "fa-ban",
      text: "Deny Comment",
      id: "deny",
      popup: ApproveComment,
    },
  ];
  return allPermissions;
};
export const ftpOptions = () => {
  const allPermissions = [
    {
      icon: "fa-download",
      text: "Download file",
      id: "download",
      popup: FtpDownload,
    },
  ];
  return allPermissions;
};
