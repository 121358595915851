import { useContext } from "react";
import { Link } from "react-router-dom";
import { WidthContext } from "../Context";
import { BasicImage } from "../SpicyArt/Image";

const LatestGame = ({ id, days, short_description, square_banner, title }) => {
  const mobile = useContext(WidthContext);

  return (
    <div role={"group"} aria-label={title} className="game GameNewsMini">
      <Link to={"/game/" + id}>
        <div className="image flex-shrink-0">
          <BasicImage
            src={square_banner}
            alt={title}
            width={mobile ? 400 : 100}
            height={mobile ? 400 : 100}
          />
        </div>
      </Link>
      <div className="text">
        <div className="title">
          <Link to={"/game/" + id}>{title}</Link>
        </div>
        <div className="info !items-center">
          <span aria-hidden className="fa fa-clock-o"></span>
          <span>{days}</span>
        </div>
        <p className="description">{short_description} </p>
      </div>
    </div>
  );
};

export default LatestGame;
