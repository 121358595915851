import React, { useState } from "react";
import { Link } from "react-router-dom";
import { authRequest } from "../../../../utils/Axios";
import { Loader } from "../../../Common";
import AddComponent from "../../../Member/AddComponent";
import { generateURL } from "../../../resizeImages";
import { inviteCreators } from "../OptionPopups";
import { Avatar } from "src/components/common/Avatar";

const ContentCreateTeamMembers = ({ team_id, defaults = [] }) => {
  const [members, setMembers] = useState(defaults);
  const [removedMembers, setRemovedMembers] = useState([]);

  const [popupPosition, setPopupPosition] = useState(0);

  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const [showPopup, setShowPopup] = useState(false);

  const saveChanges = async () => {
    setLoading(true);
    try {
      const res = await authRequest({
        url: `/api/content-creator-teams/members/remove`,
        method: "POST",
        data: {
          team_id,
          creator_ids: removedMembers.map((member) => member.id),
        },
      });

      setError(res.success);
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };

  if (loading) return <Loader />;
  return (
    <section className="team-members">
      <legend>Team Content creators</legend>
      <div className="section-content">
        <div className="search-content">
          <div
            className="add-member"
            onClick={(e) => {
              setPopupPosition(e.pageY);
              setShowPopup((prev) => !prev);
            }}
          >
            + Send Invitations
          </div>
        </div>

        <div className="content">
          {!!members.length ? (
            members.map((member, index) => (
              <Member
                key={index}
                member={member}
                addMember={setMembers}
                isAdded={true}
                removedMembers={setRemovedMembers}
              />
            ))
          ) : (
            <p>Add Creators to your Team</p>
          )}
        </div>
        <br />
        {/* <Slider
          slides_array={members}
          display_array_func={setDisplayMembers}
          num_of_elements_display={NUM_OF_ELEMENTS_DISPLAY}
          func_of_section={setMemberValue}
          value_of_section={memberValue}
        /> */}

        <AddComponent
          searchURL={`/api/content-creator-teams/inviteable-creators/search?team_id=${team_id}&search_term=<query>`}
          // searchURL="/api/content-creators/search?search_term=<query>"
          topY={popupPosition}
          show={showPopup}
          close={() => setShowPopup(false)}
          title={"SEND INVITATIONS TO CREATORS"}
          Component={AddMemberComponent}
          props={{
            team_id,
            inviteMode: true,
          }}
        />
        {error && <div className="error">{error}</div>}
        <div className="submit" onClick={saveChanges}>
          Save Changes
        </div>
      </div>
    </section>
  );
};

const AddMemberComponent = ({ addItems, result = [], team_id, inviteMode }) => (
  <div id="create-team">
    <div className="team-members">
      <div className="section-content">
        <div className="content">
          {result?.map((member, i) => (
            <Member
              key={i}
              member={member}
              team_id={team_id}
              inviteMode={inviteMode}
            />
          ))}
        </div>
      </div>
    </div>
  </div>
);

const Member = ({
  addMember,
  member,
  team_id,
  inviteMode = false,
  removedMembers,
}) => {
  const [isInvited, setIsInvited] = useState(false);
  const {
    id,
    username,
    role,
    subrole,
    profile_picture,
    facebook_link,
    instagram_link,
    twitter_link,
  } = member;
  const loggedInUsername = localStorage.getItem("username");
  return (
    <div className="info">
      <Avatar
        src={generateURL(100, 100, profile_picture)}
        name={username}
        width={80}
      />
      <div className="details">
        <div className="name" title={username}>
          {username}
        </div>
        <div className="title">
          <div className="cat">
            <span className="name">role</span>
            <span className="value">{role}</span>
          </div>
          <div className="cat">
            <span className="name">sub-role</span>
            <span className="value">{subrole}</span>
          </div>
        </div>
        <div className="social">
          {facebook_link && (
            <Link to={facebook_link}>
              <span className="fa fa-facebook"></span>
            </Link>
          )}
          {twitter_link && (
            <Link to={twitter_link}>
              <span className="fa fa-twitter"></span>
            </Link>
          )}
          {instagram_link && (
            <Link to={instagram_link}>
              <span className="fa fa-instagram"></span>
            </Link>
          )}
        </div>
        {loggedInUsername !== username && (
          <div
            className={!inviteMode ? "remove-btn" : "add-btn"}
            onClick={() => {
              if (!inviteMode) {
                addMember((prev) => prev.filter((member) => member.id !== id));
                removedMembers((prev) => [...prev, member]);
              } else {
                if (!isInvited) inviteCreators(id, team_id);
                setIsInvited(true);
              }
            }}
          >
            {inviteMode ? (isInvited ? "Invited" : "+ Invite") : "Remove"}
          </div>
        )}
      </div>
    </div>
  );
};

export { Member };
export default ContentCreateTeamMembers;
