import React from "react";
import PatreonIcon from "src/Icons/MdiPatreon";
import { SubmitButton } from "../../Common";
import PatreonConnect from "./PatreonConnect";
import { authRequest, isDev } from "src/utils/Axios";
import { toast } from "react-toastify";
import { useToggle } from "src/hooks/useToggle";
import Popup from "src/components/common/Popup";

const TeamApps = ({ id, team, games }) => {
  return (
    <div className="TeamApps">
      <section>
        <legend>Connect 3rd party apps</legend>
        <div className="description">
          <p>
            On this page you can easily connect some of the popular platforms to
            your account. Connecting the apps will enable us to:
          </p>
          <ul>
            <li>
              Auto post game news. For Instance all public posts on your Patreon
              will be automatically added to Spicygaming as game news. They will
              also automatically update if that post is updated.
            </li>
            <li>
              Enable certain content on Spicygaming only to your paid
              supporters. For instance a way for them do download the full
              version of the game (coming soon)
            </li>
            <li> More Features soon</li>
          </ul>

          <h3>DEFAULTS</h3>
          <p>
            Please select one of your games as a default. All auto added game
            news will be added to that game (Can be edited later). If you don't
            have any games yet please add one now.
          </p>
          <p>
            Please add default banners that will be used for your auto added
            game news. (Can be edited later){" "}
          </p>
        </div>

        <ul className="apps">
          {apps.map((app) => (
            <TeamApp
              app={app}
              team={team}
              id={id}
              games={games}
              key={app.name}
            />
          ))}
        </ul>
      </section>
    </div>
  );
};

const TeamApp = ({ app, team, id, games }) => {
  const Content = app.Content ?? (() => null);
  const [disconnectConfirm, toggleDisconnectConfirm] = useToggle();
  return (
    <li className="app">
      {app.isEnabled(team) && <hr />}
      <div className="brief">
        <h2>
          {app.icon} {app.name}
        </h2>
        {app.isEnabled(team) ? (
          <SubmitButton
            label="Disconnect"
            disabled={!app.isEnabled(team)}
            request={toggleDisconnectConfirm}
          />
        ) : (
          <SubmitButton
            disabled={app.isEnabled(team)}
            label={app.isEnabled(team) ? "Connected" : "Connect"}
            request={() => app.onClick?.(id)}
          />
        )}
      </div>

      {app.isEnabled(team) && <Content team={team} id={id} games={games} />}

      <Popup
        open={disconnectConfirm}
        onClose={toggleDisconnectConfirm}
        showCloseButton
        className={"TeamApp_Disconnect"}
      >
        <h2>Do you want to disconnect {app.name} Account?</h2>
        <br />
        <p>
          This action will disconnect your Spicygaming account from your{" "}
          {app.name} account please confirm or cancel.
        </p>
        <br />
        <div className="buttons">
          <SubmitButton label={"Cancel"} request={toggleDisconnectConfirm} />
          <SubmitButton
            type="secondary"
            label={"Confirm"}
            request={async () => {
              await app.onDisconnect?.(id);
              toggleDisconnectConfirm(false);
            }}
          />
        </div>
      </Popup>
    </li>
  );
};

const apps = [
  {
    icon: <PatreonIcon />,
    name: "Patreon",
    isEnabled: (team) => team.patreon_connected,
    onClick: (id) => {
      window.location.href = `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=rcGjkONfgX4j2C6oe9e_-cwui5MPe5ts7iXkTcqhCCDd4L27xTUnZ01vpd1EwEQm&redirect_uri=https://${
        isDev ? "beta" : "www"
      }.spicygaming.net/patreon-auth-success&state=${id}&scope=identity campaigns campaigns.posts w:campaigns.webhook`;
    },

    onDisconnect: async (id) => {
      try {
        const res = await authRequest({
          method: "POST",
          url: "/api/members/patreon-auth",
          data: { team_id: id, disconnect: true },
        });

        if (res.success) toast.warn("Patreon successfully disconnected.");
      } catch (error) {
        toast.error(error.message);
      }
    },
    Content: PatreonConnect,
  },
];

export default TeamApps;
