import { authRequest } from "src/utils/Axios";

export async function searchStoreProduct(query) {
  return await authRequest({
    url: "/store/api/store-products/search",
    params: {
      search_term: query,
    },
  });
}

export async function getTeamStoreProducts(teamId) {
  return await authRequest({
    url: "/store/api/store-products/search",
    params: {
      team_id: teamId,
    },
  });
}
export async function getUserBroughtProducts() {
  return await authRequest({
    url: "/store/api/members/already-bought-products",
  });
}

export async function getProductPrice(productId, productType, initialPrice) {
  if (productId === undefined || productType === undefined) return initialPrice;
  const res = await authRequest({
    url: "/store/api/store-products/calculate-product-price",
    params: {
      product_id: productId,
      product_type: productType,
    },
  });

  return res.price;
}

export async function getFeesForOrderByAmount(eurAmount) {
  if (!eurAmount) return { transaction_fee: 0, vat_amount: 0 };
  const res = await authRequest({
    url: "/store/api/token-order/calculate-order-fees",
    method: "POST",
    data: {
      eur_amount: eurAmount,
    },
  });
  return {
    transaction_fee: res.transaction_fee,
    vat_amount: res.vat_amount,
  };
}

export async function getUserBillingInfo() {
  const res = await authRequest({
    url: "/api/members/profile",
  });
  return {
    post_code: res.post_code,
    country: res.country,
    city: res.city,
    address: res.address,
  };
}
