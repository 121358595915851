import { useState, useEffect } from "react";

const useInfiniteScroll = (fetchFunction, revalidationDependencies = []) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = async (newReq = false) => {
    if (isLoading) return;
    if (!newReq && !hasMore) return;
    setIsLoading(true);

    try {
      const newData = await fetchFunction(newReq ? 1 : page);
      if (newData.length === 0) {
        setHasMore(false);
      } else {
        setData((prev) => [...prev, ...newData]);
        setPage((p) => p + 1);
      }
    } catch (error) {
      setHasMore(false);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    // reset all
    setIsLoading(true);
    setHasMore(true);
    setPage(1);
    setData([]);
    fetchMoreData(true);
    console.log(revalidationDependencies);
  }, [...revalidationDependencies]);

  return { data, fetchMoreData, isLoading, hasMore };
};

export default useInfiniteScroll;
