import useSWRInfinite from "swr/infinite";
import { LoadMore, sort } from "../../Common";
import { fetcher } from "../Cms";
import { REVALIDATE_ON_FOCUS } from "../Common";
import { MainHeader } from "../Headers";
import Table, { getColumn } from "../Table/Table";
import "../css/GameCreation.css";
import { staffReviewsOptions } from "./Options";

import dateFormat from "dateformat";
import { authRequest } from "src/utils/Axios";

const columns = [
  getColumn("Title", { sortKey: "title", accessKey: "title" }),
  getColumn("Username", { sortKey: "creator", accessKey: "creator" }),
  getColumn("Game Title", { sortKey: "game_title", accessKey: "game_title" }),
  getColumn("Date Created", {
    sortKey: "date_posted",
    accessFn: (game) => dateFormat(new Date(game.date_posted), "longDate"),
  }),
  getColumn("score", { sortKey: "score", accessKey: "score" }),
];

const StaffReviews = ({ toggler, link, subHeader }) => {
  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) => {
      return `${window.domainName}/news/api/news/staff-reviews/list?page=${
        index + 1
      }`;
    },
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );

  const articles = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];
  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  const search = async (query) => {
    const res = await authRequest({
      url: `/news/api/news/staff-reviews/cms/search?search_term=${query}`,
    });

    if (res.error) return [];
    return res;
  };
  const searchCallback = (data) => {
    mutate(data, false);
  };

  return (
    <div className="body">
      <MainHeader
        toggler={toggler}
        uploadLink={link + "/upload-review"}
        header={subHeader}
        search={{
          func: search,
          callback: searchCallback,
        }}
      />
      <div className="content">
        <Table
          columns={columns}
          data={articles}
          getOptions={(article) => staffReviewsOptions(article)}
          getPopupData={(article) => ({
            edit: { id: article.id },
            view: { id: article.id },
            publish: {
              id: article.id,
              published: article.published,
              title: article.title,
            },
            delete: {
              title: article.title,
              game_title: article.game_title,
              creator: article.creator,
              created: article.date_posted,
              id: article.id,
              score: article.score,
            },
          })}
          indicators={[
            {
              color: "#00b4f2",
              when: (article) => article.published && article.approved,
              name: "Review is Published and Approved",
            },
          ]}
          refreshData={() => mutate(articles, true)}
          onSort={(key, order) => mutate(sort(key, articles, order), false)}
        />
        {errors && <div className="error">{errors.error}</div>}
        {error && <div className="error">{error.message}</div>}
        <LoadMore onClick={() => setSize((prev) => prev + 1)} />
      </div>
    </div>
  );
};

export default StaffReviews;
