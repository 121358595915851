import { getClass } from "src/utils/getClass";
import { useCarousel } from "./Carousel";

const CarouselContent = ({ children, className, flexClassName }) => {
  const { ref } = useCarousel();
  return (
    <div className={getClass("overflow-hidden min-w-0", className)} ref={ref}>
      <div
        className={getClass("flex gap-5 [&>*]:flex-shrink-0 ", flexClassName)}
      >
        {children}
      </div>
    </div>
  );
};

export default CarouselContent;
