import React from "react";
import { useLanguage } from "../../providers/LanguageProvider";
import Header from "../Header";
import { MultiLang } from "../MultiLang";

const Advertise = () => {
  const language = useLanguage();
  const Page =
    translations.find((r) => r.code === language)?.Component ??
    AdvertiseEnglish;
  return (
    <main id="advertise">
      <div className="inner">
        <header>
          <h1>
            <MultiLang sl="Oglaševanje na Spicygaming.net">
              Advertise on Spicygaming.net
            </MultiLang>
          </h1>
        </header>
        <br />
        <div className="content">
          <Page />
        </div>
        <br />
        <Header searchBar={false} />
      </div>
    </main>
  );
};

const AdvertiseEnglish = () => {
  return (
    <>
      <p>
        Spicygaming.net is the first advertising network specialized in the
        adult gaming industry. Besides advertising, we help provide several
        other services tailored for the adult gaming industry; game developers,
        content creators, bloggers, vtubers, and others.
      </p>
      <h2>
        <strong>ADULT GAME DEVELOPERS</strong>
      </h2>
      <p>
        Driving force of the adult gaming industry! Let us help you build up
        your player base, grow your financial support, boost sales and game
        recognition. What we offer is an advertising and several other services
        tailored especially for you! If you do not have your dedicated game page
        yet.
        <strong>Sign up and upload it now! It's free!</strong>
      </p>
      <h2>
        <strong>CONTENT CREATORS</strong>
      </h2>
      <p>
        We understand that game development is not the only thing driving the
        adult gaming industry. Artists, Voice actors, programmers, Writers, and
        several others are a crucial part of the industry. Let us help you
        connect with the game developers and all others that seek your services.
      </p>
      <p>
        <strong>
          <a href="/login/member/content-creator">
            Sign up here and apply for a content creator role.
          </a>
        </strong>
      </p>
      <h2>
        <strong>ADVERTISERS</strong>
      </h2>
      <p>
        Do you have a product specialized for gamers and a mature audience? Let
        us help you reach an audience in the adult gaming sector. We can provide
        HQ traffic sources with a strong presence in the adult gaming industry.
        If you have a product that would appeal to such an audience, let us
        know!
      </p>
      <p>
        <strong>
          <a href="/contact">Contact us to set up a meeting.</a>{" "}
        </strong>
      </p>
      <h4>ADVERTISING OPTIONS:</h4>
      <ol>
        <li>
          <strong>net</strong>
          <ol>
            <li>Your dedicated game page (sign up here for free)</li>
            <li>Several website banners positions</li>
            <li>Featured positions on-page and in search results</li>
            <li>Paid Promotional Articles</li>
            <li>In-house video network with feature and ad system</li>
            <li>
              Social channels (Youtube, Instagram, Twitter, Facebook, and more)
            </li>
            <li>Cross-promotion</li>
          </ol>
        </li>
        <li>
          <strong>Spicygaming partner network</strong>
          <ol>
            <li>
              Influencers (Vtubers, Instagramers, Twitchers, Cosplayers, and
              more)
            </li>
            <li>Partner websites</li>
            <li>Partner AD networks with global reach</li>
          </ol>
        </li>
      </ol>
      <p>OTHER SERVICES:</p>
      <ol>
        <li>Video production (trailer creation, ad spots, video news)</li>
        <li>VA (voice-acting) services</li>
        <li>Designer services (banners, logos, ads, website design)</li>
        <li>Programming (Python, JS, React, Php...)</li>
        <li>Writing</li>
        <li>Artists</li>
        <li>...</li>
      </ol>
      <p>
        As each ad campaign is unique, we invite you to
        <a href="/contact">contact us</a>
        and set up a meeting to discuss the details and needs.
      </p>
    </>
  );
};

const AdvertiseSlovinean = () => {
  return (
    <>
      <p>
        <span lang="sl">
          Spicygaming.net je prva oglaševalska mreža, specializirana za
          industrijo iger za odrasle. Poleg oglaševanja pomagamo zagotoviti več
          drugih storitev, prilagojenih industriji iger za odrasle;
        </span>
      </p>
      <p>
        <h2 lang="sl">
          <strong>RAZVIJALCI VSEBIN</strong>
        </h2>
      </p>
      <p>
        <span lang="sl">
          Gonilna sila industrije iger za odrasle! Dovolite nam, da vam pomagamo
          zgraditi vašo bazo igralcev, povečati finančno podporo, povečati
          prodajo in prepoznavnost iger. Ponujamo vam oglaševanje in številne
          druge storitve, prilagojene posebej za vas! Če še nimate svoje
          namenske strani za igro. Prijavite se in jo naložite zdaj! Brezplačno
          je!
        </span>
      </p>
      <p>
        <h2 lang="sl">
          <strong>OGLAŠEVALCI</strong>
        </h2>
      </p>
      <p>
        <span lang="sl">
          Ali imate izdelek, specializiran za igralce iger in zrelo občinstvo?
          Naj vam pomagamo doseči občinstvo v sektorju iger za odrasle. Virom
          prometa HQ lahko zagotovimo močno prisotnost v industriji iger za
          odrasle. Če imate izdelek, ki bi pritegnil takšno občinstvo, nam to
          sporočite!
        </span>
      </p>
      <p>
        <span lang="sl">Kontaktirajte nas.</span>
      </p>
    </>
  );
};

const translations = [
  { code: "en", Component: AdvertiseEnglish },
  { code: "sl", Component: AdvertiseSlovinean },
];

export default Advertise;
