import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useToggle } from "../../hooks/useToggle";
import { Loader } from "../Common";

/**
 *
 * @param {Object} props
 * @param {Object} props.search
 * @param {Function} props.search.func
 * @param {Function} props.search.callback
 * @param {String} props.header
 * @param {String} props.uploadLink
 * @param {String} props.uploadText
 * @param {Function} props.toggler
 * @returns
 */
const MainHeader = ({
  toggler,
  header,
  uploadLink,
  buttons = true,
  uploadText = "Upload",
  search = {
    func: () => null,
    callback: () => null,
  },
}) => {
  const [show, toggleShow] = useToggle(false);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const getInput = (e) => setQuery(e.target.value);

  const searchItems = async () => {
    toggleShow();
    if (!!query.length) {
      setLoading(true);
      const data = await search.func(query);
      search.callback(data);
      setLoading(false);
    }
  };
  return (
    <header>
      {loading && <Loader />}
      <span onClick={toggler} className="main-header">
        {header}
      </span>
      {buttons && (
        <div>
          <div className={`search ${show && "show"}`}>
            <input
              type="search"
              id="search-input"
              placeholder="Search..."
              onKeyPress={(e) =>
                e.key === "Enter" && e.target.nextElementSibling.click()
              }
              onChange={getInput}
            />

            <span className="fa fa-search" onClick={searchItems}></span>
            {show && <span className="fa fa-close" onClick={toggleShow}></span>}
          </div>
          {uploadLink && (
            <Link to={uploadLink}>
              <div className="add-btn">
                <span>{uploadText}</span>
              </div>
            </Link>
          )}
        </div>
      )}
    </header>
  );
};
const InnerHeader = ({
  header,
  subHeader,
  element,
  toggler,
  search = {
    func: () => null,
    callback: () => null,
  },
}) => {
  const history = useHistory();
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    !!query.length && inputRef.current?.focus();
  }, [query]);

  const getInput = (e) => setQuery(e.target.value);

  const searchItems = async () => {
    setShow((prev) => !prev);
    if (!!query.length) {
      setLoading(true);
      const data = await search.func(query);
      search.callback(data);
      setLoading(false);
    }
  };

  const buttons = {
    "back-button": () => (
      <div className="button" onClick={history.goBack}>
        <span className="fa fa-arrow-left"></span>
        <span>Back</span>
      </div>
    ),
    search: () => (
      <div className={`search ${show && "show"}`}>
        <input
          type="search"
          name=""
          id="search-input"
          placeholder="Search..."
          onKeyPress={(e) =>
            e.key === "Enter" && e.target.nextElementSibling.click()
          }
          value={query}
          onChange={getInput}
          ref={inputRef}
        />

        <span className="fa fa-search" onClick={searchItems}></span>
      </div>
    ),
  };

  const Element = buttons[element] || (() => null);
  return (
    <header>
      {loading && <Loader />}
      <div className="header">
        <span>{header}</span>
        <span className="sub" onClick={toggler}>
          {subHeader}
        </span>
      </div>
      <div>
        <Element />
      </div>
    </header>
  );
};
export { MainHeader };
export { InnerHeader };
