import {
  ImageSelector,
  VideoSelector,
  TextEditor,
  GameSelector,
  TeamSelector,
  ArticleSelector,
  CallToAction,
  Ad,
  EmbedURL,
  GallerySelector,
} from "./EditorPopups";

export const apiObjects = (needsInArray = true) => ({
  video: {
    name: "add Video",
    icon: "fa-file-video-o",
    id: "video",
    popup: VideoSelector,
    defaultClose: false,
    props: {
      type: "Video",
    },
  },
  game: {
    name: "add Games",
    icon: "fa-gamepad",
    id: "games",
    popup: GameSelector,
    array: needsInArray,
    props: {
      type: "Games",
    },
  },
  gallery: {
    name: "Add Gallery",
    icon: "fa-newspaper-o",
    id: "gallery",
    popup: GallerySelector,
    array: needsInArray,
    props: {
      type: "Gallery",
    },
  },

  news_articles: {
    name: "Add Game News",
    icon: "fa-newspaper-o",
    id: "game_news",
    popup: ArticleSelector,
    array: needsInArray,
    props: {
      name: "News Articles",
      url: "/news/api/news/game-news/search/",
      type: "Game News",
      instructions:
        "This allows you to connect any other Game news on the site",
    },
  },
  blog_articles: {
    name: "Add Site News",
    icon: "fa-newspaper-o",
    id: "blog_posts",
    popup: ArticleSelector,
    array: needsInArray,
    props: {
      name: "Blog Articles",
      url: "/news/api/news/blog-posts/search/",
      type: "Blog Posts",
      instructions:
        "This allows you to connect any other Site news articles on the site",
    },
  },
  staff_reviews: {
    name: "Add Game Reviews",
    icon: "fa-newspaper-o",
    id: "staff_reviews",
    popup: ArticleSelector,
    array: needsInArray,
    props: {
      name: "Staff Articles",
      url: "/news/api/news/review-news/search/",
      type: "Staff Reviews",
      instructions:
        "This allows you to connect any other Game Reviews on the site",
    },
  },
});

const editorOptions = () => ({
  image: {
    name: "add Image",
    id: "image",
    icon: "fa-image",
    popup: ImageSelector,
    props: {
      type: "Image",
    },
  },

  editor: {
    name: "add Text",
    icon: "fa-file-text",
    id: "html",
    popup: TextEditor,
    props: {
      type: "HTML",
    },
  },
  team: {
    name: "add Teams",
    icon: "fa-users",
    id: "teams",
    popup: TeamSelector,
    array: true,
    props: {
      type: "Teams",
    },
  },
  ...apiObjects(true),

  call_to_action: {
    name: "add Call to Action",
    icon: "fa-tasks",
    id: "call_to_action",
    popup: CallToAction,
    props: {
      type: "CTA",
    },
  },
  embed: {
    name: "add Embed Item",
    icon: "fa-code",
    id: "embed",
    popup: EmbedURL,
    props: {
      type: "Embed",
    },
  },
  ad: {
    name: "add Advertisment",
    icon: "fa-tag",
    id: "ad",
    popup: Ad,
    props: {
      type: "Ad",
    },
  },
});
const options = editorOptions();
const arrayObjects = Object.values(options)
  .filter((obj) => obj.array === true)
  .map((obj) => obj.id);

const chooseApiObjects = (objects, ids = []) => {
  const entries = Object.entries(objects);
  const filtered = entries.filter(([, value]) => ids.includes(value.id));
  return Object.fromEntries(filtered);
};

export { arrayObjects, chooseApiObjects };
export default options;
