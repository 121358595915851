import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import useDocs from "../../hooks/useDocs";
import Header from "../Header";

import "./css/About.css";
import { Helmet } from "react-helmet";

const About = () => {
  const response = useDocs("about_us");
  return (
    <main id="about">
      <Helmet>
        <title>About - Spicygaming.net</title>
        <meta
          name="description"
          content="History of spicygaming.net, who, what, why, how."
        />
      </Helmet>
      <div className="inner">
        <br />
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: response.data }}
        ></div>
        <br />
        <Header searchBar={false} />
      </div>
    </main>
  );
};

export default About;
