import React from "react";
import { CONVERSION_RATE } from "../../../constants/store";
import { LocalPrice } from "../../LocalPrice";
import { getClass } from "../../../utils/getClass";
import "./style.css";
import { SpicyTokensLogo } from "src/components/LocalPrice/SpicyTokensPrice";

const Price = ({
  priceInTokens = 0,
  vat,
  className,
  showSGOnly,
  showLocalCurrencyOnly,
}) => {
  const priceInEur = priceInTokens / CONVERSION_RATE;

  // we round priceInTokens to obtain a integer always
  return (
    <span className={getClass(className)} data-testid="Price">
      <span data-tooltip="Spicygaming Tokens" className="sg-price">
        {Math.round(priceInTokens)} <SpicyTokensLogo /> {!showSGOnly && " / "}
      </span>
      {!showSGOnly && (
        <LocalPrice
          showOnlyLocalPrice={showLocalCurrencyOnly}
          vat={vat}
          amountInEUR={priceInEur}
        />
      )}
    </span>
  );
};

export default Price;
