import React, { Suspense, useEffect, useState } from "react";
import { Loader, LoaderInside } from "../Common";
import ForceLogin from "../ForceLogin";
import { usePermissions } from "./Permission";
import { useRoleInfo } from "./RoleContext";

const CMSContent = React.lazy(() => import("./Cms"));

const CMS = () => {
  const { role, loading, loggedIn } = useRoleInfo();
  const [paths, setPaths] = useState(undefined);

  const { paths: allPaths } = usePermissions();

  useEffect(() => {
    if (allPaths) setPaths(Object.values(allPaths));
    else if (allPaths === null) setPaths("NO_ACCESS");
    else if (allPaths === undefined) setPaths("NO_ACCESS");
  }, [role, loggedIn]);

  if (paths === undefined || loading) return <Loader />;

  // role is not accessed any paths
  if (paths === "NO_ACCESS")
    return (
      <main id="cms">
        <div className="inner">
          <ForceLogin url={window.location.pathname} />
        </div>
      </main>
    );

  if (loggedIn && role)
    return (
      <Suspense fallback={<LoaderInside />}>
        <CMSContent paths={paths} />
      </Suspense>
    );

  return <Loader />;
};

export default CMS;
