import dateFormat from "dateformat";
import { useState } from "react";
import relativeDate from "relative-date";
import { authRequest } from "../../../utils/Axios";
import { LoaderInside } from "../../Common";
import { authorizedRequest } from "../../Validation";

const Delete = ({
  close,
  title,
  description,
  creator,
  created,
  last_updated,
  id,
  category,
}) => {
  // /news/api/news/blog-posts/delete?article_id=1
  // /news/api/news/game-news/delete?article_id=1
  // /news/api/news/<category>/delete?article_id=1

  if (!category) throw new Error("category not found");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const deleteArticle = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url: `${window.domainName}/news/api/news/${category}/delete?article_id=${id}`,
        method: "DELETE",
      });

      setError(res.success || res.error);
      setLoading(false);
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;
  return (
    <div className="article-delete">
      <h2 className="header">Delete {title}?</h2>
      <p>{description}</p>

      <div className="info">
        <p>
          Article by <strong>{creator}</strong>
        </p>
        <p>Created on {dateFormat(created, "default")}</p>
        {last_updated && <p>Last Updated {relativeDate(last_updated)}</p>}
      </div>

      <p>
        This action cannot be undone. Once you remove a article, It will be
        removed completely from the system
      </p>
      <div className="buttons">
        <div className="button green" onClick={close}>
          No
        </div>
        <div className="button  red" onClick={deleteArticle}>
          Delete it.
        </div>
      </div>
    </div>
  );
};

const DeleteReview = ({
  close,
  title,
  game_title,
  creator,
  created,
  score,
  id,
}) => {
  // /news/api/news/staff-reviews/delete?article_id=1

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const deleteReview = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url:
          window.domainName +
          `/news/api/news/staff-reviews/delete?article_id=${id}`,
        method: "DELETE",
      });

      setError(res.success || res.error);
      setLoading(false);
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;
  return (
    <div className="article-delete">
      <h2 className="header">Delete {title}?</h2>

      <div className="info">
        <p>
          Article by <strong>{creator}</strong>
        </p>
        <p>This article was written for game - {game_title}</p>
        <p>Created on {dateFormat(created, "default")}</p>

        <p>Score - {score}</p>
      </div>

      <p>
        This action cannot be undone. Once you remove a article, It will be
        removed completely from the system
      </p>
      <div className="buttons">
        <div className="button green" onClick={close}>
          No
        </div>
        <div className="button  red" onClick={deleteReview}>
          Delete it.
        </div>
      </div>
    </div>
  );
};
const ViewReview = ({ review, creator }) => {
  return (
    <div className="article-view">
      <center>
        <h2>Review by {creator}</h2>
      </center>
      <br />

      <blockquote>{review}</blockquote>
    </div>
  );
};
const EditReview = ({ creator, review, id, close }) => {
  const [newReview, setReview] = useState(review);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const getReview = (e) => setReview(e.target.value);

  const saveChanges = async () => {
    setLoading(true);
    try {
      const res = await authorizedRequest({
        url: `${window.domainName}/products/api/reviews/edit`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          review_id: id,
          text: newReview,
        },
      });
      if (res.error) {
        setError(error);
        return;
      }
      if (res.success) {
        setError(res.success);
        setTimeout(() => {
          close();
        }, 1000);
      }
    } catch (error) {
      setError("Error occured, try again..");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="article-edit">
      <center>
        <h2>Review by {creator}</h2>
      </center>
      <br />
      {loading && <LoaderInside />}
      {error && <div className="error">{error}</div>}
      <textarea
        defaultValue={review}
        cols="30"
        rows="10"
        onChange={getReview}
      ></textarea>
      <br />
      <div className="edit-review load-more">
        <button onClick={saveChanges}>Save Changes</button>
      </div>
    </div>
  );
};
const DeleteUserReview = ({
  close,
  text,
  game_title,
  creator,
  created,
  score,
  id,
}) => {
  // /news/api/news/staff-reviews/delete?article_id=1

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const deleteReview = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url: window.domainName + `/products/api/reviews/delete?review_id=${id}`,
        method: "DELETE",
      });

      setError(res.success || res.error);
      setLoading(false);
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;
  return (
    <div className="article-delete">
      <h2 className="header">Delete Review of {game_title}?</h2>

      <ul className="info">
        <li>
          Article by <strong>{creator}</strong>
        </li>
        <li>This Review was written for game - {game_title}</li>
        <li>Created on {dateFormat(created, "default")}</li>

        <li>
          Score - <strong>{score}</strong>
        </li>
        <li>
          Review - <br /> <blockquote>{text}</blockquote>
        </li>
      </ul>

      <p>
        This action cannot be undone. Once you remove a review, It will be
        removed completely from the system
      </p>
      <div className="buttons">
        <div className="button green" onClick={close}>
          No
        </div>
        <div className="button  red" onClick={deleteReview}>
          Delete it.
        </div>
      </div>
    </div>
  );
};
const ApproveUserReview = ({
  close,
  text,
  game_title,
  creator,
  created,
  score,
  id,
  approve = true,
}) => {
  // /news/api/news/staff-reviews/delete?article_id=1

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const approveReview = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url:
          window.domainName +
          `/products/api/reviews/approve?review_id=${id}&approve=${approve}`,
        method: "POST",
      });

      setError(res.success || res.error);
      setLoading(false);
      if (res.success)
        setTimeout(() => {
          close();
        }, 5000);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;
  return (
    <div className="article-delete">
      <h2 className="header">
        {approve ? "Approve" : "Deny"} Review of {game_title}?
      </h2>

      <ul className="info">
        <li>
          Article by <strong>{creator}</strong>
        </li>
        <li>This Review was written for game - {game_title}</li>
        <li>Created on {dateFormat(created, "default")}</li>

        <li>
          Score - <strong>{score}</strong>
        </li>
        <li>
          Review - <br /> <blockquote>{text}</blockquote>
        </li>
      </ul>

      {approve ? (
        <p>This review will appear in game pages, after approving</p>
      ) : (
        <p>This will be hidden from search results</p>
      )}
      <div className="buttons">
        <div className={`button  ${approve ? "red" : "green"}`} onClick={close}>
          No
        </div>
        <div
          className={`button ${approve ? "green" : "red"}`}
          onClick={approveReview}
        >
          {approve ? "Approve it." : "Deny it"}
        </div>
      </div>
    </div>
  );
};

const ApproveNews = ({
  close,
  title,
  description,
  creator,
  created,
  last_updated,
  id,
  approve = true,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const approveArticle = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url: `${window.domainName}/news/api/news/game-news/approve`,
        method: "POST",
        params: {
          approve,
          article_id: id,
        },
      });

      setError(res.success || res.error);
      setLoading(false);
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError(`Error Occured! Request didn't sent `);
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;
  return (
    <div className="article-delete">
      <h2 className="header">
        {approve ? "Approve" : "Deny"} {title}?
      </h2>
      <p>{description}</p>

      <div className="info">
        <p>
          Article by <strong>{creator}</strong>
        </p>
        <p>Created on {dateFormat(created, "default")}</p>
        {last_updated && <p>Last Updated {relativeDate(last_updated)}</p>}
      </div>

      {approve ? (
        <p>Once you approve article, it will be available in search results</p>
      ) : (
        <p>Once you deny article, it will be disappeared in search results</p>
      )}
      <div className="buttons">
        <div className={`button ${approve ? "red" : "green"}`} onClick={close}>
          No
        </div>
        <div
          className={`button  ${approve ? "green" : "red"}`}
          onClick={approveArticle}
        >
          {approve ? "Approve" : "Deny"} it.
        </div>
      </div>
    </div>
  );
};

const ReportReview = ({
  close,
  title,
  game_title,
  creator,
  created,
  score,
  text,
  id,
}) => {
  // /news/api/news/staff-reviews/delete?article_id=1

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const deleteReview = async () => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: `/products/api/reviews/report-review`,
        method: "POST",
        data: { review_id: id },
      });

      setError(res.success);
      setLoading(false);
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;

  return (
    <div className="article-delete">
      <h2 className="header">Report Review of {game_title}?</h2>

      <ul className="info">
        <li>
          Review by <strong>{creator}</strong>
        </li>
        <li>Created on {dateFormat(created, "default")}</li>

        <li>
          Score - <strong>{score}</strong>
        </li>
        <li>
          Review - <br /> <blockquote>{text}</blockquote>
        </li>
      </ul>

      <p>You can report reviews to the Spicygame Crew</p>
      <div className="buttons">
        <div className="button green" onClick={close}>
          No
        </div>
        <div className="button  red" onClick={deleteReview}>
          Report it.
        </div>
      </div>
    </div>
  );
};
const PublishArticle = ({ title, close, id, published, url }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const publishArticle = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url: window.domainName + url,
        method: "POST",
        params: {
          article_id: id,
          publish: !published,
        },
      });

      if (res.success) setError("Request Sent.");
      else setError(res.error);
      setLoading(false);
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;

  return (
    <div className="game-approve">
      <h2>
        {published && "Un"}Publish {title} ?
      </h2>
      <br />
      {!published ? (
        <p>
          Publishing this article, will appear in the search results and users
          can download this article.
        </p>
      ) : (
        <p>
          If you unpublish this article, this will disappear from search results
          and users cannot download this article anymore. This will not be
          removed. You can publish this article in anytime
        </p>
      )}
      <br />
      <div className="buttons">
        <div
          className={`button ${!published ? "red" : "green"}`}
          onClick={close}
        >
          No
        </div>
        <div
          className={`button  ${!published ? "green" : "red"}`}
          onClick={publishArticle}
        >
          {published && "Un"}Publish it.
        </div>
      </div>
    </div>
  );
};
export { PublishArticle };
export { ReportReview };
export { DeleteUserReview };
export { DeleteReview };
export { Delete };
export { ViewReview };
export { EditReview };
export { ApproveUserReview };
export { ApproveNews };
