import { PASSWORD_REGEX } from "../Register";
export const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%+.~#?&//=]*)/;

export const basicInputs = [
  {
    label: "Username",
    key: "username",
  },
  {
    label: "Email",
    key: "email",
    type: "email",
    regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,
  },
];

export const financialInputs = [
  {
    label: "Address",
    key: "address",
  },
  {
    label: "City",
    key: "city",
  },
  {
    label: "Post Code",
    key: "post_code",
  },
];

export const personalInputs = [
  {
    label: "About You",
    key: "biography",
    Component: (props) => <textarea rows={7} {...props}></textarea>,
  },
  {
    label: "Website Name",
    key: "website_name",
  },
  {
    label: "Website URL",
    key: "website_link",
    example: "Example: http://www.abc.com",
    regex: URL_REGEX,
  },
  {
    label: "Facebook URL",
    key: "facebook_link",
    example: "Example: http://www.abc.com",
    regex: URL_REGEX,
  },
  {
    label: "Twitter URL",
    key: "twitter_link",
    example: "Example: http://www.abc.com",
    regex: URL_REGEX,
  },
];
