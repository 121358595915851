import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSpicyStore } from "src/providers/SpicyStoreProvider";
import currencies from "../../constants/currencies";
import { customEvents } from "../../constants/socket-events";
import { CurrencyDisclaimer } from "../../providers/CartProvider";
import { authRequest, isDev } from "../../utils/Axios";
import { Required, SubmitButton } from "../CMS/Common";
import { NOT_VALID_BORDER_STYLE, VALID_BORDER_STYLE } from "../CMS/validation";
import { LoaderInside } from "../Common";
import Finder from "../Finder";
import { MultiLang } from "../MultiLang";
import { inputIteratorValidate } from "../Validation";
import { Discord } from "../common/social";
import inputFocuser from "../input_focus";
import { generateURL } from "../resizeImages";
import { basicInputs, financialInputs, personalInputs } from "./General.data";
import SelectCountry from "./SelectCountry";
import "./css/General.css";
import "./css/Member.css";
import { Avatar } from "../common/Avatar";

export const URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;
const General = () => {
  inputFocuser(
    '#member-general section:not(.gamer) input:not([type="file"])',
    "#personal-submit"
  );

  inputFocuser(
    '#member-general section.gamer input:not([type="file"])',
    "#gamer-submit"
  );

  const DISCORD_AUTH_URL = `https://discord.com/api/oauth2/authorize?client_id=915542401207123978&redirect_uri=https%3A%2F%2F${
    isDev ? "beta" : "www"
  }.spicygaming.net%2Fdiscord-auth-success&response_type=code&scope=identify%20email`;

  const spicyStore = useSpicyStore();
  const [loading, setLoading] = useState(true);

  const [profilePic, setProfilePic] = useState({
    url: undefined,
    file: undefined,
  });
  const [basicDetails, setBasicDetails] = useState({
    username: "",

    email: "",
  });
  const [personalDetails, setPersonalDetails] = useState({
    biography: "",
    website_name: "",
    website_link: "",
    twitter_link: "",
    facebook_link: "",
  });

  useEffect(() => {
    getDefaultInfo(`/api/members/profile`, (res) => {
      setBasicDetails({
        email: res.email,
        username: res.username,
      });
      setPersonalDetails({
        biography: res.biography,
        website_name: res.website_name,
        website_link: res.website_link,
        twitter_link: res.twitter_link,
        facebook_link: res.facebook_link,
        city: res.city,
        address: res.address,
        country: res.country,
        currency: res.currency,
        post_code: res.post_code,
      });
      setProfilePic({
        url: res.profile_picture
          ? generateURL(250, 250, res.profile_picture)
          : null,
      });
    });
  }, []);

  const getDefaultInfo = async (url, callback) => {
    try {
      const res = await authRequest({
        url,
      });
      callback?.(res);
    } catch (error) {
      toast.error(
        "Couldn't fetch some info. Please try refreshing your browser"
      );
    } finally {
      setLoading(false);
    }
  };

  /**Update State
   * @param {String} key - State Object key
   * @param {Function} setState - State update function
   *
   *@returns {(value: string| number) => any}
   */
  const updateState = (key, setState) => (value) =>
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  const getProfilePic = (e) => {
    // setProfilePic(e.f)
    const file = e.target.files[0];
    const blob = URL.createObjectURL(file);
    setProfilePic({
      url: blob,
      file,
    });
  };

  const personalDetailsUpdate = async () => {
    const fd = new FormData();
    const data = { ...basicDetails, ...personalDetails };
    const noErrors = inputIteratorValidate(data, "Missing Required Fields", [
      ...Object.keys(personalDetails),
      "password",
    ]);

    if (noErrors === true) {
      for (const key in data) {
        fd.append(key, data[key] ? data[key] : "");
      }
      fd.append(
        "profile_picture",
        profilePic.file ? profilePic.file : "current"
      );
      try {
        const res = await authRequest({
          url: `/api/members/profile`,
          method: "POST",
          data: fd,
        });
        if (res.success) {
          toast.success("Successfully updated");
          localStorage.setItem("username", basicDetails.username);
          spicyStore.refresh?.();
          document.dispatchEvent(new CustomEvent(customEvents.PROFILE_CHANGED));
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const selectedCurrency = currencies.find(
    (c) => c.id === personalDetails["currency"]
  );

  const updateCurrencySearch = (value) => {
    const newValue = value.toLowerCase();
    return currencies.filter(
      (c) =>
        c.id?.toLowerCase()?.includes(newValue) ||
        c.name?.toLowerCase()?.includes(newValue)
    );
  };

  if (loading) return <LoaderInside />;
  return (
    <div className="general member-pages" id="member-general">
      <section className="basic">
        <legend data-cy="authenticated-info">
          <MultiLang sl="Infomacije o računu">Account information</MultiLang>
        </legend>

        <div className="section-content">
          <div className="pic">
            <div className="avatar picture">
              <Avatar
                src={profilePic.url}
                name={basicDetails.username}
                width={250}
              />
              <div className="change-pic">
                <div className="fa fa-camera"></div>
                <label htmlFor="general-pic">Change Profile Picture</label>
              </div>
            </div>

            <input type="file" id="general-pic" onChange={getProfilePic} />
          </div>
          <div className="user-inputs">
            {basicInputs.map((input, i) => (
              <InputBasic
                key={i}
                id={input.key}
                value={basicDetails[input.key]}
                callback={updateState(input.key, setBasicDetails)}
                {...input}
              />
            ))}
          </div>
        </div>
      </section>

      <section>
        <legend>
          <MultiLang sl="Poveži Discord">Connect Discord</MultiLang>
        </legend>
        <div className="section-content">
          <p className="discord-instructions">
            <MultiLang sl="Poveži discord in pridobi dodatne dostope na našem discord strežniku">
              By connecting your discord username to spicygaming you will be
              granted extra access on our discord server
            </MultiLang>
          </p>
          <SubmitButton
            id="discord-auth-button"
            request={() => {
              window.location.href = DISCORD_AUTH_URL;
            }}
            label={
              <>
                <Discord />
                <MultiLang sl="Poveži Discord">Connect Discord</MultiLang>
              </>
            }
          />
        </div>
      </section>

      <section className="personal">
        <legend>
          <MultiLang sl="O meni">personal information</MultiLang>
        </legend>
        <div className="section-content">
          <div className="user-inputs">
            {personalInputs.map((input) => (
              <Input
                key={input.key}
                id={input.key}
                {...input}
                callback={updateState(input.key, setPersonalDetails)}
                value={personalDetails[input.key]}
              />
            ))}
          </div>
          <br />

          <SubmitButton
            label="Save Changes"
            request={personalDetailsUpdate}
            id="personal-submit"
          />
        </div>
      </section>
      <section>
        <legend>
          <MultiLang sl="Podatki za plačilo">Billing Information</MultiLang>
        </legend>
        <div className="section-content">
          <div className="user-inputs">
            <div>
              <div className="input text-input">
                <Finder
                  label="Local Currency"
                  value={selectedCurrency}
                  idExtractor={(elem) => "currency" + elem.id}
                  id="currency"
                  data={currencies.map((c) => ({
                    id: c.id,
                    name: `${c.name} (${c.symbol})`,
                  }))}
                  update={updateCurrencySearch}
                  onChange={(elem) =>
                    updateState("currency", setPersonalDetails)(elem.id)
                  }
                />
                <CurrencyDisclaimer showLink={false} />
              </div>
            </div>
            {financialInputs.map((input, i) => (
              <Input
                key={i}
                id={input.key}
                {...input}
                callback={updateState(input.key, setPersonalDetails)}
                value={personalDetails[input.key]}
              />
            ))}

            <div className="input text-input">
              <SelectCountry
                value={personalDetails.country}
                onChange={(id) =>
                  setPersonalDetails((prev) => ({ ...prev, country: id }))
                }
              />
            </div>
          </div>
          <br />

          <SubmitButton
            label="Save Changes"
            request={personalDetailsUpdate}
            id="personal-submit"
          />
        </div>
      </section>
    </div>
  );
};

export const Input = ({
  label,
  id,
  value,
  controlledValue,
  placeholder,
  example,
  Component,
  name,
  regex = /(.|\r\n|\n)*/g,
  disabled = false,
  callback = () => {},
  required = false,
  containerStyles,
  ...inputProps
}) => {
  const [input, setInput] = useState(value ?? "");

  const updateInput = (e) => {
    let value = !!controlledValue ? e.target.value : e.target.value.trim();

    //allowed to be NULL
    if (value.length > 0) {
      if (value.match(regex) !== null && value.match(regex)[0] === value) {
        e.target?.style?.setProperty("border", VALID_BORDER_STYLE);
        setInput(value);
      } else {
        setInput("");
        e.target?.style?.setProperty("border", NOT_VALID_BORDER_STYLE);
      }
    } else {
      setInput(value);
    }
  };
  useEffect(() => {
    callback(input);
  }, [input]);

  const common = {
    onChange: updateInput,
    placeholder: placeholder || "Type Here...",
    type: "text",
    defaultValue: value,
    value: controlledValue,
    id,
    name,
    disabled,
    required,
  };
  return (
    <div
      className="input text-input"
      style={containerStyles}
      data-disable={disabled}
    >
      <div className="title">
        {label}
        {required && <Required />}
      </div>
      <div className="input-container">
        {Component ? (
          <Component {...common} {...inputProps} />
        ) : (
          <input {...common} {...inputProps} />
        )}
      </div>
      {example && <div className="ex">{example}</div>}
    </div>
  );
};
const InputBasic = ({
  label,
  id,
  value,
  type,
  placeholder,
  regex = /.*/g,
  callback = () => {},
}) => {
  const [input, setInput] = useState(value || "");

  const updateInput = (e) => {
    let value = e.target.value.trim();
    if (value.match(regex) !== null && value.match(regex)[0] === value) {
      e.target.style.border = "1px #c2c2c2 solid";
      setInput(value);
    } else {
      setInput("");
      e.target.style.border = "2px #fb0400 solid";
    }
  };

  useEffect(() => {
    callback(input);
  }, [input]);

  const toggleInputEditable = (e) => {
    e.target.previousElementSibling?.toggleAttribute("disabled");
  };

  return (
    <div className="input">
      <div className="title">{label}</div>
      <div className="input-container">
        <input
          onChange={updateInput}
          type={type || "text"}
          defaultValue={value}
          id={id}
          disabled={true}
          placeholder={placeholder}
        />
        <label
          data-tooltip={`Edit ${label}`}
          htmlFor={id}
          onClick={toggleInputEditable}
        >
          <span className="fa fa-pencil"></span>
        </label>
      </div>
    </div>
  );
};
export default General;
