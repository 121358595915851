import { generatePath } from "react-router-dom";
import { authRequest } from "src/utils/Axios";

export const formattedGameForFinder = (game) => ({
  id: game.id,
  name: game.title,
});

export const searchGamesForFinder = async (query) => {
  try {
    const res = await authRequest({
      url: "/api/content-creators/games/search",
      params: { title: query },
    });
    return res.map(formattedGameForFinder);
  } catch (error) {
    return [];
  }
};

export const searchCreatorsForFinder = async (query) => {
  try {
    const res = await authRequest({
      url: "/api/content-creator-teams/search",
      params: {
        member: false,
        name: query,
      },
    });

    return res.map((creator) => ({ id: creator.id, name: creator.name }));
  } catch (error) {
    return [];
  }
};
