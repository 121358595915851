import AddDownload from "./AddDownload";

export const EditDownload = ({
  download,
  onClose,
  refresh,
  creatorApproved,
  creatorTeamId,
}) => {
  return (
    <AddDownload
      creatorApproved={creatorApproved}
      creatorTeamId={creatorTeamId}
      download={download}
      editMode
      onClose={onClose}
      refresh={refresh}
    />
  );
};
