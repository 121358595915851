import React, { useRef } from "react";
import PaymentInfo from "../ContentCreateTeam/PaymentInfo";

const TeamPayout = ({ id, leaderEmail }) => {
  const payoutInfoRef = useRef(null);

  return (
    <PaymentInfo leaderEmail={leaderEmail} teamID={id} ref={payoutInfoRef} />
  );
};

export default TeamPayout;
