import { useEffect, useRef, useState } from "react";
import useIntersection from "src/hooks/useIntersection";
import useQuery from "src/hooks/useQuery";
import "./Banners.css";

const Banner = ({ id, width, height, jsAd, revalidateDependencies = [] }) => {
  const banner = useRef();
  const intersection = useIntersection(banner, {
    rootMargin: "200px",
    once: true,
  });
  const query = useQuery();
  const noAds = query.get("no_ads");

  useEffect(() => {
    if (noAds) {
      return;
    }

    if (intersection?.isIntersecting) {
      // no ads for the crawler
      const ua = window.navigator.userAgent;
      if (jsAd && !ua.toLowerCase().includes("googlebot"))
        window._ASO?.loadAd(id, jsAd, true);
    }
  }, [
    id,
    intersection?.isIntersecting,
    jsAd,
    noAds,
    ...revalidateDependencies,
  ]);

  const aspect = width / height;

  return (
    <div
      style={{ "--aspect": aspect }}
      className={`banner banner-${width}-${height}`}
      ref={banner}
    >
      <div id={id}></div>
    </div>
  );
};

export default Banner;
