import axios from "axios";
import React, { useEffect, useState } from "react";
import Game from "../AdultGames/Game";
import Slider from "../Article/Slider";
import { Social } from "../common/social";
import Error404 from "../Errors";
import { generateURL } from "../resizeImages";
import "./css/PublicProfile.css";

const PublicProfile = ({ match }) => {
  const [publicGames, setPublicGames] = useState(1);
  const [userData, setUserData] = useState({
    id: 1,
    username: "adminHassan",
    role: "Developer",
    profile_pic: null,
    biography: null,
    facebook_link: null,
    twitter_link: null,
    website_name: null,
    website_link: null,
    user: 1,
    gamer_profile: 1,
  });
  const [notFound, setNotFound] = useState(false);
  const user = match.params.user;
  // http://naughtygamez.com/api/members/adminHassan/profile

  const [displayArray, setDisplayArray] = useState([]);
  const games = [23.23, 355, 56, 3];
  const request = async () => {
    const req = await axios.get(
      window.domainName + "/api/content-creators/" + user + "/profile"
    );
    const res = await req.data;
    if (res.error) setNotFound(true);
    else setUserData(res);
  };
  useEffect(() => {
    request();
  }, [user]);

  if (notFound) return <Error404 />;
  return (
    <main id="member" className="member-public">
      <div className="inner">
        {userData ? (
          <>
            <header>
              <div className="info">
                <div className="logo">
                  {userData.profile_pic ? (
                    <div className="image">
                      <img
                        loading="lazy"
                        src={generateURL(300, 300, userData.profile_pic)}
                        alt={userData.username}
                      />
                    </div>
                  ) : (
                    <div className="avatar">{userData.username[0]}</div>
                  )}
                </div>
                <div className="details">
                  <div className="name">{userData.username}</div>
                  <div className="title">
                    <div className="cat">
                      <span className="name">role</span>
                      <span className="content">{userData.role}</span>
                    </div>
                    <div className="cat">
                      <span className="name">sub-role</span>
                      <span className="content">{userData.subrole}</span>
                    </div>
                  </div>
                  <div className="social">
                    <Social fa_name="fa-twitter" path={userData.twitter_link} />
                    <Social
                      fa_name="fa-facebook"
                      path={userData.facebook_link}
                    />
                    <Social fa_name="fa-globe" path={userData.website_link} />
                  </div>
                </div>
              </div>
              {/* <div className="buttons"> 
                <div className="btn looking">
                  <span className="fa fa-user"></span>
                  <span>looking for people</span>
                </div>
              
                <div className="btn available">
                  <span className="fa fa-briefcase"></span>
                  <span>available for job</span>
                </div>
              </div> */}
            </header>
            <div className="body">
              <div className="header">games created</div>
              <div className="games adult-games">
                {displayArray.map((game, i) => (
                  <Game followers={[]} key={i} />
                ))}
              </div>
            </div>
            <Slider
              display_array_func={setDisplayArray}
              slides_array={games}
              num_of_elements_display={4}
              value_of_section={publicGames}
              func_of_section={setPublicGames}
            />
          </>
        ) : (
          <center>
            <h1>No user with this name</h1>
          </center>
        )}
      </div>
    </main>
  );
};

export default PublicProfile;
