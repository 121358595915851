import { Tabs } from "src/components/Tabs";
import { GameMedia } from "../Media";
import UploadGame from "../UploadGame";
import { useMemo } from "react";
import { GameDownloads } from "../Content";
import { GameArcade } from "../Arcade";

const CreateGamesTabs = ({ propsForEachPageComponent, gameID }) => {
  const notAllowedIfNoBanners = [
    propsForEachPageComponent.defaults?.formData?.square_banner,
    propsForEachPageComponent.defaults?.formData?.landscape_banner,
  ].some((b) => !b);

  const pages = useMemo(
    () => [
      {
        component: UploadGame,
        displayText: "Info",
        id: "info",
        url: "/info",
        // disabled: true,
      },
      {
        component: GameMedia,
        displayText: "Media",
        id: "media",
        url: "/media",
        disabled: !gameID,
      },
      {
        component: GameDownloads,
        displayText: "Downloads",
        id: "downloads",
        url: "/downloads",
        disabled: !gameID || notAllowedIfNoBanners,
      },
      {
        component: GameArcade,
        displayText: "Arcade",
        id: "arcade",
        url: "/arcade",
        disabled: !gameID || notAllowedIfNoBanners,
      },
    ],
    [gameID]
  );

  return (
    <div>
      <Tabs
        pages={pages}
        firstPageId={"info"}
        withURLChange
        propsForEachPageComponent={propsForEachPageComponent}
      />
    </div>
  );
};

export default CreateGamesTabs;
