import { Tabs } from "src/components/Tabs";
import Section from "./Sections";
import Geographical from "./Geographical";

const AllOrders = ({ hiddenSections = [] }) => {
  return (
    <div className="AllOrders">
      <Tabs
        firstPageId="tokens"
        withURLChange
        pages={pages.filter((p) => !hiddenSections.includes(p.id))}
      />
    </div>
  );
};

const pages = [
  {
    id: "tokens",
    url: "/tokens",
    displayText: "Tokens",
    component: Section("tokens"),
  },
  {
    id: "crypto",
    url: "/crypto",
    displayText: "Crypto",
    component: Section("coingate"),
  },
  {
    id: "cc",
    url: "/cc",
    displayText: "Credit Card",
    component: Section("credit_card"),
  },
  {
    id: "geo",
    url: "/geo",
    displayText: "Geographical",
    component: Geographical,
  },
];
export default AllOrders;
