import { useEffect, useState } from "react";
import { LoaderInside } from "src/components/Common";
import { ASPECT_RATIO } from "src/components/Video/Player/Player";
import VideoPlayer from "src/components/VideoPlayer";
import { vastPullzones } from "src/components/VideoPlayer/pullzones";
import { generateURL } from "src/components/resizeImages";
import RequestError from "src/errors/RequestError";
import { authRequest } from "src/utils/Axios";

const VideoPlayerEditMode = ({ videoId }) => {
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    getDetails(videoId);
  }, [videoId]);

  const getDetails = async (id) => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: "/community/community-videos/video/edit",
        params: { video_id: id },
        withCredentials: true,
      });
      setInfo(res);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <LoaderInside />;
  if (error) return <RequestError error={error} />;
  return (
    <VideoPlayer
      vastZone={info.team.zone_id ?? vastPullzones.default}
      videoID={info.file?.bunny_id}
      folderID={info.file?.library_id}
      pullzoneID={info.file?.pullzone}
      thumbnailName={generateURL(
        720,
        parseInt(720 / ASPECT_RATIO),
        info.thumbnail
      )}
    />
  );
};

export default VideoPlayerEditMode;
