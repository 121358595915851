import { useImperativeHandle } from "react";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import RequestError from "../../../../errors/RequestError";
import useId from "../../../../hooks/useId";
import { useToggle } from "../../../../hooks/useToggle";
import { authRequest } from "../../../../utils/Axios";
import { fallbackFunc } from "../../../../utils/fallbacks";
import { STEAM_REGEX } from "../../../../utils/regex";
import { toFormData } from "../../../../utils/toFormData";
import { sort } from "../../../Common";
import Popup from "../../../common/Popup";
import { Divider } from "../../../Divider";
import { Input } from "../../../Member/General";
import { SubmitButton } from "../../Common";
import { CommonEditables } from "./Options";

const ALLOWED_STEAMKEY_FILE_TYPES = ["text/csv", "text/plain"];

export function SteamKey({ steamKey, onDelete, onCopy, download = false }) {
  return (
    <div
      className="key"
      data-used={!download && steamKey.used}
      data-tooltip={
        !download && steamKey.used ? "This key is already used" : undefined
      }
    >
      <span>{steamKey.key}</span>
      <div className="buttons">
        {download ? (
          <SubmitButton
            icon="fa-copy"
            request={() => {
              navigator.clipboard.writeText(steamKey.key);
              onCopy?.();
            }}
          />
        ) : (
          !steamKey.used && (
            <SubmitButton
              icon="fa-trash"
              request={() => onDelete?.(steamKey.key, steamKey.id)}
            />
          )
        )}
      </div>
    </div>
  );
}

export const AddSteamKeys = ({ data, onSubmit }, ref) => {
  const [showPopup, togglePopup] = useToggle();
  const [key, setKey] = useState("");
  const [file, setFile] = useState();
  const [error, setError] = useState();

  /**
   * Uses to upload steam key file after the resource creation
   */
  useImperativeHandle(
    ref,
    () => ({
      async onResourceCreation(res) {
        if (file)
          await authRequest({
            url: "/downloads/api/games/downloads/resources/steam-keys/bulk-upload",
            method: "POST",
            data: toFormData({
              resource_id: res.resource_id,
              file,
            }),
          });
      },
    }),
    [file]
  );

  const onChangeKey = (key) => setKey(key.trim());

  const submit = () => {
    if (key.length > 0 || file) {
      const keys = data?.keys || [];
      if (key.length > 0) keys.push({ key });
      onSubmit({ keys, uploadedFile: !!file });
      togglePopup();
    }
  };

  const deleteKey = (currentKey) => {
    onSubmit({
      keys: data?.keys?.filter((key) => key.key !== currentKey),
    });
  };

  const getFile = (e) => {
    const file = e.target.files.item(0);
    if (ALLOWED_STEAMKEY_FILE_TYPES.includes(file.type)) {
      setError(null);
      setFile(file);
    } else {
      setError("Only .txt and .csv files are allowed");
    }
  };
  return (
    <div className="addSteamKeys">
      <h3>
        Upload your steam keys
        <SubmitButton request={togglePopup} label="Upload" />
      </h3>

      <div className="keys">
        {data?.uploadedFile && <p>Uploaded file</p>}
        {data?.keys?.map?.((key, i) => (
          <SteamKey steamKey={key} key={i} onDelete={deleteKey} />
        ))}
      </div>

      <Popup open={showPopup} className="addSteamKeys" onClose={togglePopup}>
        <h2>Upload steam key</h2>

        <Input
          placeholder="XXXXX-XXXXX-XXXXX"
          callback={onChangeKey}
          id="steamkey"
          name="Steam Key"
          regex={STEAM_REGEX}
        />

        {!data?.uploadedFile && (
          <>
            <Divider>Or</Divider>
            <div className="file-upload">
              <label htmlFor="file">Upload all keys in One file</label>
              <input
                type="file"
                id="file"
                accept={ALLOWED_STEAMKEY_FILE_TYPES.join(",")}
                onChange={getFile}
              />
            </div>
          </>
        )}
        <RequestError error={error} />

        <SubmitButton
          disabled={key.length <= 0 && !file}
          request={submit}
          label="Upload"
        />
      </Popup>
    </div>
  );
};

export const EditSteamKeys = ({ resource, refresh, onClose }) => {
  const [selectedMethod, setSelectedMethod] = useState("key");
  const [showPopup, togglePopup] = useToggle();
  const [edited, setEdited] = useState(() => ({ name: resource.name }));
  const [error, setError] = useState();

  const id = useId();

  const SelectedMethod = useMemo(
    () =>
      methods.find((method) => method.id === selectedMethod)?.Component ??
      fallbackFunc,
    [selectedMethod]
  );
  const onChangeMethod = (e) => {
    setSelectedMethod(e.target.value);
  };

  const onDeleteKey = async (_key, id) => {
    try {
      await authRequest({
        url: "/downloads/api/games/downloads/resources/steam-keys",
        method: "DELETE",
        data: {
          steam_key_id: id,
        },
      });

      refresh?.();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const editResource = async () => {
    try {
      const res = await authRequest({
        url: "/downloads/api/games/downloads/resources/create",
        method: "PUT",
        data: {
          resource_id: resource.id,
          ...edited,
          data: {
            keys: resource.resource,
          },
        },
      });
      if (res.success) refresh?.();
      onClose?.();
      toast.success("Successfully edited");
    } catch (error) {
      setError(error.message);
    }
  };
  return (
    <div className="addSteamKeys">
      <h2>Edit Steam Keys</h2>
      <div className="info">
        <p>
          You can edit the names of the "Resources", add steam keys, update /
          upload new files (like with a new version) or change the links. You
          cannot delete resources.
        </p>
      </div>
      <CommonEditables resource={resource} setEdited={setEdited} />
      <br />
      <RequestError error={error} />
      <SubmitButton request={editResource} label="Save" />
      <div className="info"></div>
      <SubmitButton
        className="upload-key"
        request={togglePopup}
        label="Upload"
        icon="fa-upload"
      />
      <div className="keys">
        {sort("used", resource.resource, "asc")?.map?.((key) => (
          <SteamKey onDelete={onDeleteKey} steamKey={key} key={key.id} />
        ))}
      </div>
      <Popup className={"addSteamKeys"} open={showPopup} onClose={togglePopup}>
        <h2>Upload steam key</h2>
        <div className="methods">
          {methods.map((method) => (
            <div className="method" key={method.id}>
              <input
                type="radio"
                name={id + "method"}
                id={id + method.id}
                value={method.id}
                checked={method.id === selectedMethod}
                onChange={onChangeMethod}
              />

              <label htmlFor={id + method.id}>{method.name}</label>
            </div>
          ))}
        </div>

        <SelectedMethod
          onClose={togglePopup}
          refresh={refresh}
          resource={resource}
        />
      </Popup>
    </div>
  );
};
export const methods = [
  {
    id: "key",
    name: "Upload One by one",
    Component({ resource, refresh, onClose }) {
      const [key, setKey] = useState("");
      const [error, setError] = useState();

      const onChangeKey = (key) => setKey(key.trim());

      const submit = async () => {
        try {
          await authRequest({
            url: "/downloads/api/games/downloads/resources/steam-keys",
            method: "POST",
            data: {
              keys: [{ key }],
              resource_id: resource.id,
            },
          });
          refresh?.();
          onClose?.();
          setError(null);
        } catch (error) {
          setError(error.message);
        }
      };
      return (
        <div className="">
          <Input
            placeholder="XXXXX-XXXXX-XXXXX"
            callback={onChangeKey}
            id="steamkey"
            name="Steam Key"
            regex={STEAM_REGEX}
          />
          <RequestError error={error} />
          <SubmitButton
            request={submit}
            label="Upload"
            className="upload-key"
          />
        </div>
      );
    },
  },
  {
    name: "Upload All in a file",
    id: "file",
    Component({ resource, onClose, refresh }) {
      const [file, setFile] = useState();
      const [error, setError] = useState();
      const getFile = (e) => {
        const file = e.target.files.item(0);
        if (ALLOWED_STEAMKEY_FILE_TYPES.includes(file.type)) {
          setError(null);
          setFile(file);
        } else {
          setError("Only .txt and .csv files are allowed");
        }
      };
      const submit = async () => {
        try {
          await authRequest({
            url: "/downloads/api/games/downloads/resources/steam-keys/bulk-upload",
            method: "POST",
            data: toFormData({
              resource_id: resource.id,
              file,
            }),
          });
        } catch (error) {
          setError(error.message);
        } finally {
          onClose?.();
          refresh?.();
        }
      };
      return (
        <div className="file-upload">
          <label htmlFor="file">
            Select File: <br />
          </label>
          <input
            type="file"
            id="file"
            accept={ALLOWED_STEAMKEY_FILE_TYPES.join(",")}
            onChange={getFile}
          />

          <RequestError error={error} />
          <SubmitButton
            className="upload-key"
            label="Upload"
            request={submit}
          />
        </div>
      );
    },
  },
];
