import { FullTransaction } from "../Games/OptionPopups";
import {
  ApproveChangeRequest,
  ApproveRefund,
  EditVatPercentage,
  MarkCompleted,
  RegenInvoice,
  Transactions,
} from "./OptionPopups";

export const payoutOptions = () => {
  const allUserOptions = [
    {
      icon: "fa-check",
      text: "Mark as Completed",
      id: "mark",
      popup: MarkCompleted,
    },
  ];

  return allUserOptions;
};
export const changeRequestsOptions = () => {
  const allUserOptions = [
    {
      icon: "fa-check",
      text: "Approve",
      id: "approve",
      popup: ApproveChangeRequest,
    },
    {
      icon: "fa-ban",
      text: "Deny",
      id: "deny",
      popup: ApproveChangeRequest,
    },
  ];

  return allUserOptions;
};
export const refundsOptions = () => {
  const allUserOptions = [
    {
      icon: "fa-check",
      text: "Approve",
      id: "approve",
      popup: ApproveRefund,
    },
    {
      icon: "fa-ban",
      text: "Deny",
      id: "deny",
      popup: ApproveRefund,
    },
    {
      icon: "fa-ban",
      text: "Transaction Details",
      id: "show_full",
      popup: FullTransaction,
    },
  ];

  return allUserOptions;
};

export const ordersOptions = (type) => {
  const allUserOptions = [
    {
      icon: "fa-ban",
      text: "Show Transactions",
      id: "show_transactions",
      popup: Transactions,
      popupProps: {
        maxWidth: 1080,
      },
    },
  ];

  if (type !== "tokens") {
    allUserOptions.push({
      icon: "fa-refresh",
      text: "Regenerate Invoice",
      id: "invoice_regen",
      popup: RegenInvoice,
    });
  }
  return allUserOptions;
};
export const geoOptions = () => {
  const allUserOptions = [
    {
      icon: "fa-money",
      text: "Edit Vat Percentage",
      id: "edit_vat",
      popup: EditVatPercentage,
    },
  ];

  return allUserOptions;
};
