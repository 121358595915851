import BoxIcon from "src/Icons/MaterialSymbolsBoxOutline";
import "./style.css";

const EmptyView = ({ text }) => {
  return (
    <div className="EmptyView" data-testid="EmptyView">
      <BoxIcon className="icon" />
      <div>{text}</div>
    </div>
  );
};

export default EmptyView;
