import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import relativeDate from "relative-date";
import RequestError from "src/errors/RequestError";
import { withoutLastPage } from "src/utils/withoutLastPage";
import { Loader } from "../Common";
import Header from "../Header";
import NewsMini from "../Home/NewsMini";
import { generateURL } from "../resizeImages";
import { PaginationSlider } from "./Slider";
import { SortItems } from "../AdultGames/Header";
import useQuery from "src/hooks/useQuery";
import { TYPES } from "../Home/ReviewMini";
import SuggestionPanel from "../Video/FullPage/SuggestionChips";
import { Chip } from "../Chip";

const articleTypes = Object.keys(TYPES);

const ArticleSearchResults = ({
  title,
  getArticles,
  getOnlySelectedTypeArticles,
}) => {
  const history = useHistory();
  const { query = "", page } = useParams();

  const searchParams = useQuery();
  const sortKey = searchParams.get("sort");
  const sortConfig = useRef({
    sort: sortKey,
    order: sortKey ? "dsc" : undefined,
  });

  const currentPageNumber = parseInt(page) || 1;
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [lastPage, setLastPage] = useState(1);
  const [selectedArticleType, setSelectedArticleType] = useState();

  const canceller = axios.CancelToken.source();

  const search = ({ search }) => {
    if (search?.length <= 0) return;
    history.push(`/articles/${search}/1`);
  };
  useEffect(() => {
    searchArticles(query, currentPageNumber, sortConfig.current);
    return () => {
      canceller.cancel();
    };
  }, [query, page, selectedArticleType]);

  const searchArticles = async (query, page, sortConfig) => {
    try {
      const fetcher =
        selectedArticleType !== undefined
          ? getOnlySelectedTypeArticles
          : getArticles;

      if (!fetcher) return;

      setLoading(true);
      const res = await fetcher({
        query,
        page,
        sortKey: sortConfig.sort,
        sortOrder: sortConfig.order,
        cancelToken: canceller.token,
        selectedArticleType,
      });
      const last_page = res.find(
        (item) => item.last_page !== undefined
      )?.last_page;

      setLastPage(last_page);
      setArticles(withoutLastPage(res));
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onSortChange = (sort) => searchArticles(query, page, sort);

  return (
    <main className="ArticleSearch">
      <div className="inner">
        <Header gameManu={false} searchCallback={search} defaultQuery={query} />

        {!!getOnlySelectedTypeArticles && (
          <div className="article-suggest SuggestionPanel">
            <span>Only Search for</span>
            {articleTypes.map((articleType) => (
              <div
                key={articleType}
                onClick={() => setSelectedArticleType(articleType)}
              >
                <Chip
                  selected={articleType === selectedArticleType}
                  className="tag"
                >
                  {articleType}
                </Chip>
              </div>
            ))}
          </div>
        )}
        {loading && <Loader />}
        <section className="search_results_content">
          <div className="header">
            <h1>{title}</h1>
            <SortItems sort={onSortChange} sortConfig={sortConfig} />
          </div>
          <RequestError error={error} />
          <div className="results">
            {articles.map((article) => (
              <NewsMini
                type={article.metadata.type}
                id={article.id}
                key={article.metadata.type + article.id}
                title={article.title}
                time={relativeDate(new Date(article.date_posted))}
                description={article.short_description}
                views={article.views}
                likes={article.likes}
                img={generateURL(400, 225, article.landscape_cover)}
              />
            ))}
          </div>

          <PaginationSlider
            initial_page_number={currentPageNumber}
            total_number_of_pages={lastPage}
          />
        </section>
      </div>
    </main>
  );
};

export default ArticleSearchResults;
