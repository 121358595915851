import CardShieldIcon from "src/Icons/IconoirCardShield";
import { getClass } from "src/utils/getClass";

const SpicyGuarantee = ({ children, hideBanner }) => {
  return (
    <div className="relative">
      <div
        className={getClass(
          "absolute bg-yellow-500 px-2 pt-1 pb-2 -mb-2 text-black -translate-y-3/4  opacity-100  w-fit right-0 block -z-10 rounded-t-lg text-xl text-right font-normal line-clamp-1 transition-all",
          hideBanner && "hidden"
        )}
        title="Purchase protected by Spicy Guarantee"
      >
        <a
          href="/frequently-asked-questions#refunds"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center gap-2"
        >
          <CardShieldIcon />
          <span className="text-xs">Risk-Free Purchase</span>
        </a>
      </div>
      <div className="bg-[#333] rounded-lg p-3 z-10">{children}</div>
    </div>
  );
};

export default SpicyGuarantee;
