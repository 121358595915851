import FeaturedSection from "./HomePage/Featured";
import { Featured } from "./util";

export const FeaturedVideos = () => {
  return (
    <FeaturedSection
      title="FEATURED Videos"
      position={Featured.FEATURED_VIDEOS}
      allowedObjects={["video"]}
    />
  );
};
