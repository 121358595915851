import React from "react";
import "./style.css";

const BottomStickyButtons = ({ children }) => {
  return (
    <div className="BottomStickyButtons" data-testid="BottomStickyButtons">
      {children}
    </div>
  );
};

export default BottomStickyButtons;
