import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import TusUpload from "src/components/FileUploadV4";
import { numberCompactData } from "src/utils/number-format";
import RequestError from "../../../../errors/RequestError";
import { useToggle } from "../../../../hooks/useToggle";
import { BASE_UPLOAD_URL, authRequest } from "../../../../utils/Axios";
import { FileTypes } from "../../../FileUploadV3";
import { Input } from "../../../Member/General";
import { AddTags, SubmitButton } from "../../Common";
import { OS } from "../UploadGame";
import { preSelectResources } from "./contentData";
import { deleteResource } from "src/v2/services/gamesService";
import Popup from "src/components/common/Popup";

const FILE_UPLOAD_ENTRYPOINT = `${BASE_UPLOAD_URL}/tus-upload/upload/`;

export const DeleteResource = ({ resource, refresh, onClose }) => {
  const submit = async () => {
    try {
      await deleteResource(resource.id);

      refresh?.();
      toast.warn("Deleted");
    } catch (error) {
      toast.error("failed");
    } finally {
      onClose?.();
    }
  };

  return (
    <div className="AddDownload">
      <h2>Delete Resource {resource.name}</h2>
      <SubmitButton request={submit} label="Delete" />
    </div>
  );
};

export const DeleteResourceHint = ({ gameId, resource, refresh, onClose }) => {
  const [showEditPopup, toggleEditPopup] = useToggle();

  const submit = async () => {
    try {
    } finally {
      onClose?.();
    }
  };

  return (
    <div className="AddDownload">
      <h2 className="text-3xl">Did you know?</h2>
      <p>
        You can edit and reuse this resource instead of deleting it? (This is
        the recommended approach.)
      </p>
      <p>
        Editing the resource instead of deleting it will enable you to:
        <ul className="list-disc pl-5">
          <li>Have better statistics</li>
          <li>Maintain a backup of the previous version</li>
        </ul>
      </p>
      <div className="flex gap-4 w-fit ml-auto">
        <SubmitButton request={submit} label="Delete Anyway" type="secondary" />
        <SubmitButton request={toggleEditPopup} label="Edit Instead" />
      </div>

      <Popup
        showCloseButton
        className="resource-option"
        onClose={toggleEditPopup}
        open={showEditPopup}
        showExitConfirmation
        customExitConfirmationText="Make sure to save any changes you made"
      >
        <EditGameFileUpload
          gameId={gameId}
          resource={resource}
          refresh={refresh}
          onClose={toggleEditPopup}
        />
      </Popup>
    </div>
  );
};

export const GameFileUpload = ({ onSubmit, gameId }) => {
  const [error, setError] = useState(null);
  return (
    <div>
      <h3>Upload Game File</h3>
      <TusUpload
        endpoint={FILE_UPLOAD_ENTRYPOINT}
        type={FileTypes.RESOURCE}
        onError={(error) => setError(error.message)}
        getMetadata={() => ({ game_id: gameId })}
        onSuccess={(file_id) => onSubmit({ file_id })}
        filePrefix={gameId}
        withCredentials
      />
      <RequestError error={error} />
    </div>
  );
};

export const EditLink = ({ resource, refresh, onClose }) => {
  const [newLink, setnewLink] = useState(resource.resource ?? "");
  const [edited, setEdited] = useState({ name: resource.name });
  const [error, setError] = useState();
  const getLink = (link) => {
    if (link !== "") return setnewLink(link);
  };

  const sendLink = async () => {
    try {
      const res = await authRequest({
        url: "/downloads/api/games/downloads/resources/create",
        method: "PUT",
        data: {
          resource_id: resource.id,
          ...edited,
          data: {
            external: newLink,
          },
        },
      });
      if (res.success) refresh?.();
      onClose?.();
      toast.success("Successfully edited");
    } catch (error) {
      setError(error.message);
    }
  };
  return (
    <div>
      <div className="info">
        <p>
          You can edit the names of the "Resources", add steam keys, update /
          upload new files (like with a new version) or change the links. You
          cannot delete resources.
        </p>
      </div>
      <CommonEditables resource={resource} setEdited={setEdited} />
      <Input
        callback={getLink}
        defaultValue={resource.resource}
        label={"Edit Link"}
        // TODO: add validation
      />

      <RequestError error={error} />
      <SubmitButton label="Save" request={sendLink} />
    </div>
  );
};
export const EditGameFileUpload = ({ gameId, resource, refresh, onClose }) => {
  const [edited, setEdited] = useState({ name: resource.name });
  const [currentFileRemoved, toggleCurrentFileRemoved] = useToggle();
  const [error, setError] = useState(null);

  const sendLink = async () => {
    try {
      if (currentFileRemoved && !edited.file_id)
        throw new Error("Wait till the upload is finished");

      const res = await authRequest({
        url: "/downloads/api/games/downloads/resources/create",
        method: "PUT",
        data: {
          resource_id: resource.id,
          ...edited,
          data: {
            file_id: edited.file_id ?? resource.file_id,
          },
        },
      });
      if (res.success) refresh?.();
      onClose?.();
      toast.success("Successfully edited");
    } catch (error) {
      setError(error.message);
    }
  };
  const slices = resource.data.filepath?.split(".");
  const ext = slices[slices.length - 1];

  return (
    <div className="EditFileUpload">
      <h2>Edit {resource.name}</h2>

      <div className="info">
        <p>
          You can edit the names of the "Resources", add steam keys, update /
          upload new files (like with a new version) or change the links. You
          cannot delete resources.
        </p>
      </div>
      <CommonEditables resource={resource} setEdited={setEdited} />

      {!currentFileRemoved ? (
        <section className="uploaded">
          <div>
            Resource.{ext} ({numberCompactData(resource.data.size)})
          </div>

          <SubmitButton
            type="secondary"
            rounded
            icon="fa-close"
            request={toggleCurrentFileRemoved}
          />
        </section>
      ) : (
        <TusUpload
          withCredentials
          endpoint={FILE_UPLOAD_ENTRYPOINT}
          type={FileTypes.RESOURCE}
          onError={(error) => toast.error(error)}
          getMetadata={() => ({ game_id: gameId })}
          onSuccess={(file_id) => setEdited((prev) => ({ ...prev, file_id }))}
          filePrefix={`gamefile_${gameId}`}
        />
      )}
      <RequestError error={error} />
      <SubmitButton
        label="Save"
        request={sendLink}
        disabled={currentFileRemoved && !edited.file_id}
      />
    </div>
  );
};

export const CommonEditables = ({ setEdited, resource }) => {
  return (
    <>
      <Input
        callback={(name) => {
          return setEdited((prev) => ({ ...prev, name }));
        }}
        label={"Name"}
        value={resource.name}
        list={"resource-name"}
      />
      <AddTags
        title={"OS"}
        label="OS"
        max={Infinity}
        dataKey={"operating_system"}
        onChange={setEdited}
        option={OS}
        defaults={resource?.operating_system}
      />
      <datalist id={"resource-name"}>
        {preSelectResources.map((r) => (
          <option key={r.label} value={r.label}>
            {r.label}
          </option>
        ))}
      </datalist>
    </>
  );
};
