import { useContext, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { authRequest } from "../../../../utils/Axios";
import { Loader } from "../../../Common";
import { Input } from "../../../Member/General";
import { inputIteratorValidate } from "../../../Validation";
import { generateURL } from "../../../resizeImages";
import { InnerHeader } from "../../Headers";
import { RoleContext } from "../../RoleContext";
import { SelectTeam } from "../../SelectTeam";
import { inputValidate } from "../../validation";
import "./css/ContentCreateTeam.css";
import { socialLinks } from "./data";

const ContentCreateTeam = ({
  toggler,
  header,
  subHeader,
  editMode = false,
  defaults = {},
}) => {
  const { auth } = useContext(RoleContext);

  const { image, textFields = {}, members, team_id, leaderEmail } = defaults;

  const history = useHistory();
  const [profilePic, setProfilePic] = useState(image || null);
  const [profilePicBlob, setProfilePicBlob] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    biography: "",
    website_link: "",
    facebook: "",
    steam: "",
    twitter: "",
    instagram: "",
    patreon: "",
    itchio: "",
    discord: "",
    subscribestar: "",
    ...textFields,
  });

  const getValue = (key) => (value) => {
    const sanitized = inputValidate(value, /.*/g);

    if (sanitized.validated)
      setFormData((prev) => ({
        ...prev,
        [key]: sanitized.value,
      }));
  };

  const profilePicUpload = (e) => {
    const pic = e.target.files[0];

    setProfilePic(pic);
    setProfilePicBlob(URL.createObjectURL(pic));
  };

  const createTeam = async () => {
    const fd = new FormData();

    const REQUEST_URL = editMode
      ? "/api/content-creator-teams/edit"
      : "/api/content-creator-teams/create";

    setError(null);

    const noErrors = [
      inputIteratorValidate(formData, "Empty Text fields", [
        "support_url",
        "facebook",
        "twitter",
        "steam",
        "instagram",
        "patreon",
        "itchio",
        "discord",
        "subscribestar",
        "website_link",
        "team_id",
      ]),
      profilePic ? true : "Empty Profile Picture",
    ];
    for (const key in formData) {
      fd.append(key, !!formData[key]?.length ? formData[key] : "");
    }
    fd.append("team_id", team_id ?? "");
    fd.append(
      "profile_picture",
      typeof profilePic === "string" ? "current" : profilePic
    );

    if (noErrors.every((elem) => elem === true)) {
      // the request
      setLoading(true);
      try {
        const res = await authRequest({
          url: REQUEST_URL,
          method: "POST",
          data: fd,
        });
        toast.success("Team created successfully.");
        if (res.success) {
          await auth?.();
          history.push(
            generatePath("/cms/personal/teams/edit/:id/members", {
              id: res.team_id,
            })
          );
        }
      } catch (error) {
        setError(error.message);
      }
      setLoading(false);
    } else {
      setError(noErrors.filter((noError) => noError !== true));
    }
  };

  return (
    <main className="member cms member-pages" id="member">
      {loading && <Loader />}
      <div className="body">
        <InnerHeader
          header={header}
          toggler={toggler}
          subHeader={subHeader || "Create Team"}
          element="back-button"
        />

        {editMode && <SelectTeam />}

        <div className="content">
          <div className="content-creator" id="content-creator">
            <div className="create-team general" id="create-team">
              <section className="basic">
                <legend>TEAM information</legend>

                <div className="section-content">
                  <div className="pic">
                    <div className={`avatar ${profilePic && "picture"}`}>
                      {profilePic ? (
                        <img
                          loading="lazy"
                          src={profilePicBlob || generateURL(200, 200, image)}
                          alt="Spicy gaming"
                        />
                      ) : (
                        <span>{formData.name[0]}</span>
                      )}
                      <div className="change-pic">
                        <label htmlFor="general-pic">
                          <div className="fa fa-camera"></div>
                          Change Profile Picture
                        </label>
                      </div>
                    </div>

                    <input
                      type="file"
                      id="general-pic"
                      onChange={profilePicUpload}
                      accept="image/*"
                    />
                  </div>

                  <div className="user-inputs">
                    <Input
                      callback={getValue("name")}
                      value={textFields.name}
                      label="Team Name"
                      options={{ type: "text" }}
                    />

                    <div className="input">
                      <div className="title">Team Bio</div>
                      <div className="input-container">
                        <textarea
                          rows={5}
                          onChange={(e) =>
                            getValue("biography")(e.target.value)
                          }
                          defaultValue={formData.biography}
                          placeholder={"Type Here..."}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="personal">
                <legend>Social Info</legend>
                <div className="section-content">
                  <div className="user-inputs">
                    {socialLinks.map((input, key) => (
                      <Input
                        {...input}
                        key={key}
                        callback={getValue(input.id)}
                        value={textFields[input.id]}
                      />
                    ))}
                  </div>
                </div>
              </section>

              {error &&
                (typeof error === "object" ? (
                  error.map((error, i) => (
                    <div className="error" key={i}>
                      {error}
                    </div>
                  ))
                ) : (
                  <div className="error">{error}</div>
                ))}

              <div className="submit" role="button" onClick={createTeam}>
                {editMode ? "Save Changes" : "Create Team"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ContentCreateTeam;
