const downloadFromUrl = async (url, filename) => {
  // Create a hidden anchor element
  const anchor = document.createElement("a");
  anchor.style.display = "none";

  const res = await fetch(url);
  const blob = await res.blob();
  const blobUrl = window.URL.createObjectURL(blob);

  // Set the anchor's attributes and trigger the download
  anchor.href = url;
  anchor.download = filename; // Set the desired file name
  document.body.appendChild(anchor);
  anchor.click();

  // Clean up resources
  window.URL.revokeObjectURL(url);
  document.body.removeChild(anchor);
};

export default downloadFromUrl;
