import useDocs from "../../hooks/useDocs";
import Header from "../Header";
import { MultiLang } from "../MultiLang";

import "./css/TOS.css";

const TOS = () => {
  const response = useDocs("terms_of_service");
  return (
    <main id="tos">
      <div className="inner">
        <header>
          <h1>
            <strong>
              <MultiLang sl="Spicygaming.net Pogoji Poslovanja">
                Spicygaming.net Terms of Service
              </MultiLang>
            </strong>
          </h1>

          <p>
            <em>
              <MultiLang sl="Te pogoji so bili nazadnje posodobljeni">
                This Terms of Service was last modified on
              </MultiLang>{" "}
              {response.headers?.["last-modified"]}.
            </em>
          </p>
        </header>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: response.data }}
        ></div>
        <br />
        <Header searchBar={false} />
      </div>
    </main>
  );
};

export default TOS;
