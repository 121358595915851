import { useEffect, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";
import { authRequest } from "../../../../utils/Axios";
import { LoaderInside, addImageFiles } from "../../../Common";
import { generateOriginalURL, generateURL } from "../../../resizeImages";
import { MediaUpload, SubmitButton } from "../../Common";

import { toast } from "react-toastify";
import useNewTab from "src/components/Previews/hook";
import { BottomStickyButtons } from "src/components/UtilityComponents/BottomStickyButtons";
import { ASPECT_RATIO } from "src/components/Video/Player/Player";
import VideoPlayer from "src/components/VideoPlayer";
import { vastPullzones } from "src/components/VideoPlayer/pullzones";
import Popup from "src/components/common/Popup";
import { useToggle } from "../../../../hooks/useToggle";
import Error404 from "../../../Errors";
import { EditCollection } from "../../SpicyArt/UploadCollection/EditCollection";
import VideoSelect from "../../Videos/VideoSelect";
import "./style.css";
import AvoidRouteChange from "src/components/AvoidRouteChange";

export const getURLs = (list = []) =>
  list?.map((url) => generateOriginalURL(url));

const GameMedia = ({ id, defaults }) => {
  const newtab = useNewTab();

  const [videoInfo, setVideoInfo] = useState({});
  const [trailerId, setTrailerId] = useState(() => defaults?.formData?.trailer);
  const game = defaults?.formData;

  const [loading, toggleLoading] = useToggle(true);
  const [trailerLoading, toggleTrailerLoading] = useToggle();
  const [notAllowed, toggleNotAllowed] = useToggle();
  const [showUploadPage, toggleUploadPage] = useToggle(false);

  const history = useHistory();

  const [images, setImages] = useState({
    landscape_banner: [],
    square_banner: [],
  });

  useEffect(() => {
    getGameInfo();
  }, []);

  const getGameInfo = async () => {
    try {
      await Promise.all([
        getTrailer(defaults?.formData?.trailer),
        initImages(defaults?.formData, setImages),
      ]);
    } catch (error) {
      toggleNotAllowed(true);
    } finally {
      toggleLoading(false);
    }
  };

  const getTrailer = async (id) => {
    if (!id) return;
    try {
      toggleTrailerLoading(true);
      const res = await authRequest({
        url: "/community/community-videos/video/edit",
        params: { video_id: id },
      });
      setVideoInfo(res);
    } catch (error) {
      toast.error(error.message);
    } finally {
      toggleTrailerLoading(false);
    }
  };

  const submit = (previewMode) => async () => {
    try {
      if (previewMode) {
        newtab.open();
        newtab.revalidateURL(`/game/preview/${id}`);
      }
      if (!(images?.landscape_banner?.length > 0))
        throw new Error("Add Landscape Banner");
      if (!(images?.square_banner?.length > 0))
        throw new Error("Add Square Banner");

      const fd = new FormData();

      for (const image in images) {
        images[image].forEach((img) => fd.append(`${image}[]`, img));
      }
      fd.append("creator_team", game.creator_team?.id);
      fd.append("trailer", trailerId ?? "");

      const res = await authRequest({
        method: "POST",
        data: fd,
        url: "/products/api/games/edit",
        params: {
          game_id: id,
          step: 2,
        },
      });

      if (res.success) {
        toast.success("Media updated successfully");
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      defaults.refreshDefaults?.();
    }
  };
  const getVideoID = (id) => {
    setTrailerId(id);
    getTrailer(id);
    toggleUploadPage(false);
  };
  const removeVideo = () => {
    setVideoInfo({});
  };

  if (loading) return <LoaderInside />;

  if (notAllowed) return <Error404 customMessage={"Loading Content Failed"} />;

  return (
    <div className="Media" data-testid="Media">
      <AvoidRouteChange confirmationMessage="Save changes before leave. All unsaved content will be lost." />
      <main id="creation" className="cms-details">
        <section id="banners">
          <legend>BANNER FIELD</legend>
          <div className="content">
            <MediaUpload
              size="1920x1080"
              text="Add Landscape banner"
              onClick={setImages}
              id="landscape_banner"
              aspect={{ width: 1920, height: 1080 }}
              defaults={{
                imagefiles: images?.landscape_banner,
              }}
            />
            <MediaUpload
              size="500x500"
              text="Add Square banner"
              onClick={setImages}
              id="square_banner"
              aspect={{ width: 500, height: 500 }}
              defaults={{
                imagefiles: images?.square_banner,
              }}
            />
          </div>
        </section>
        <section id="media">
          <legend>Trailer</legend>
          {trailerLoading ? (
            <LoaderInside />
          ) : (
            <div className="content">
              {videoInfo.video_id ? (
                <div className="video">
                  <SubmitButton
                    className="close"
                    icon="fa-close"
                    request={removeVideo}
                  />
                  <VideoPlayer
                    options={{ aspectRatio: "16:9" }}
                    vastZone={vastPullzones.default}
                    videoID={videoInfo.file?.bunny_id}
                    folderID={videoInfo.file?.library_id}
                    pullzoneID={videoInfo.file?.pullzone}
                    thumbnailName={generateURL(
                      720,
                      parseInt(720 / ASPECT_RATIO),
                      videoInfo.thumbnail
                    )}
                  />
                </div>
              ) : (
                <SubmitButton
                  className="upload"
                  icon={"fa-upload"}
                  label={"Upload a Trailer"}
                  request={() => toggleUploadPage()}
                />
              )}
            </div>
          )}
        </section>

        <section>
          <div className="content">
            <EditCollection
              defaultId={game.media_gallery}
              selectedPages={["images"]}
              firstPageId="images"
            />
          </div>
        </section>

        <BottomStickyButtons>
          <SubmitButton label={"Preview"} request={submit(true)} />
          <SubmitButton label={"Save & Publish"} request={submit(false)} />
        </BottomStickyButtons>
      </main>

      <Popup
        open={showUploadPage}
        onClose={toggleUploadPage}
        showCloseButton
        maxWidth={1080}
      >
        <VideoSelect
          getVideoID={getVideoID}
          defaultSuggestions={{
            title: game.title,
            description: game.short_description,
            tags: ["trailer"],
          }}
        />
      </Popup>
    </div>
  );
};

async function initImages(game, setImages) {
  if (game.landscape_banner)
    await addImageFiles(
      "landscape_banner",
      setImages,
      getURLs([game.landscape_banner])
    );
  if (game.square_banner)
    await addImageFiles(
      "square_banner",
      setImages,
      getURLs([game.square_banner])
    );
}

export default GameMedia;
