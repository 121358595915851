import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Input } from "src/components/Member/General";
import { Checkbox } from "src/components/Register";
import { inputIteratorValidate } from "src/components/Validation";
import { authRequest } from "src/utils/Axios";
import { DropdownFilter, Finder, SubmitButton } from "../../Common";
import { useCollectionInfo } from "./UploadCollection";
import { sleep } from "src/utils/sleep";

const GetInfo = ({ moveTab, setId, id, editMode }) => {
  const [filters, setFilters] = useState([]);

  const { collectionInfo, setCollectionInfo } = useCollectionInfo();

  useEffect(() => {
    getTypes();
  }, []);

  const updateInfo = (collectionInfo) =>
    setCollectionInfo((prev) => ({ ...prev, ...collectionInfo }));

  const getTypes = async () => {
    const res = await authRequest({
      method: "GET",
      url: "/products/api/game-filters",
      params: {
        title: "gallery_types",
      },
    });
    setFilters([res]);
  };

  const continueGallery = async () => {
    try {
      if (!collectionInfo.type) throw new Error("Gallery Must have a category");
      if (!collectionInfo.name) throw new Error("Gallery Must have a Name");
      if (!collectionInfo.description)
        throw new Error("Gallery Must have a Description");
      sleep(200); // id has to be set to enable "images" section
      moveTab("preference");
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <section className="cms-details GetInfo">
      <legend>Collection Details</legend>
      <p>
        Start by naming your collection with something meaningful. Add a good
        description of what the collection entails. A good name and description
        will make sure the collection is found easier not just on Spicygaming
        but also the web.
      </p>
      <br />
      <p>
        Select the right category for your collection. Art entails images,
        wallpapers and similar. Animations are for animated short clips and
        images and comics is for all comics you want to share. If you are an
        approved creator you are able to sell your art. Set the price for the
        entire collection and make sure you check the premium option (coming
        soon)
      </p>
      <div className="inputs">
        <Input
          label="Name"
          value={collectionInfo.name}
          callback={(text) => updateInfo({ name: text })}
          required
        />
        <DropdownFilter
          id="category"
          label="Select Category"
          filters={filters}
          onChange={(e) => {
            updateInfo({ type: e.target.value.trim() });
          }}
          title={"gallery_types"}
          defaultValue={collectionInfo.type}
        />
        <div>
          <Finder
            id="game_id"
            fieldNameWantInRequest="id"
            label="Select Game"
            onChange={(e) => updateInfo({ game_id: parseInt(e.target.value) })}
            fieldName="title"
            requestLink={
              window.domainName +
              "/api/content-creators/games/search?title=<search>"
            }
            editMode={!!collectionInfo?.game_id}
            defaults={{
              value: collectionInfo?.game_id,
              indicator: collectionInfo?.game_name,
            }}
          />
        </div>
        <Input
          label="Description"
          callback={(text) => updateInfo({ description: text })}
          value={collectionInfo.description}
          required
        />
      </div>

      <div className="save">
        <SubmitButton
          label={editMode ? "Save Changes" : "Continue"}
          request={continueGallery}
          disabled={!collectionInfo.name}
        />
      </div>
    </section>
  );
};

export default GetInfo;
