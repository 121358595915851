import React, { useContext, useEffect, useState } from "react";
import Header from "../Header";
import Article from "./Article";
import "./css/ArticleDetail.css";

import axios from "axios";
import { Helmet } from "react-helmet";
import OwlCarousel from "react-owl-carousel";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_CDN_URL, authRequest, request } from "../../utils/Axios";
import NewsMini from "../Article/NewsMini";
import Banner from "../Banners";
import { AD_IDS } from "../CMS/Ads/Section";
import { SubmitButton } from "../CMS/Common";
import Reviews from "../GameDetails/Reviews";
import { PreviewContext } from "../Previews/Preview";
import { loggedInValidation } from "../Validation";
import { SimpleDivider } from "../Atoms/SimpleDivider";
import SuggestArticles from "./SuggestArticles";
import { SideCreatorInfo } from "../common/SideCreatorInfo";
import { BreadCrumb } from "../Atoms/BreadCrumb";
import { TYPES } from "../Home/ReviewMini";
import shrinkText from "src/utils/shrinkText";
const { article: article_ad } = AD_IDS;

/**
 *
 * @param {Object} props
 * @param {React.Dispatch<any>} props.editArticle
 * @param {'news'|'blog'|'staff'} props.type - type of article `news`, `blog`, `staff`
 * @returns
 */
const ArticleDetails = (props) => {
  const { author, article, comments, type = "news" } = props;

  const history = useHistory();
  const [comment, setComment] = useState("");
  const [team, setTeam] = useState({});

  const previewMode = useContext(PreviewContext);
  useEffect(() => {
    if (article.team?.id) getTeamInfo(article.team.id);
  }, [article]);

  let sendCommentURL, likeURL;

  switch (type) {
    case "news":
      sendCommentURL = `/news/api/news/game-news/${article.id}/comments`;
      likeURL = `/news/api/news/game-news/like?article_id=${article.id}`;
      break;
    case "blog":
      sendCommentURL = `/news/api/news/blog-posts/${article.id}/comments`;
      likeURL = `/news/api/news/blog-posts/like?article_id=${article.id}`;
      break;
    case "staff":
      sendCommentURL = `/news/api/news/staff-reviews/${article.id}/comments`;
      likeURL = `/news/api/news/staff-reviews/like?article_id=${article.id}`;
      break;

    default:
      throw new Error("Invalid article type. allowed [news, blog, staff]");
  }

  const getTeamInfo = async (team_id) => {
    try {
      const res = await request({
        baseURL: BASE_CDN_URL,
        url: "/api/content-creator-teams/team-profile",
        params: { team_id },
      });
      setTeam(res);
    } catch (error) {}
  };

  const sendComment = async () => {
    try {
      if (comment?.length <= 0) throw new Error("Write a comment");

      const fd = new FormData();
      fd.append("comment_text", comment);

      await authRequest({
        url: sendCommentURL,
        method: "POST",
        data: fd,
      });
      setComment("");
      toast.success("Comment sent successfully");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSearch = ({ search }) => {
    history.push(`/articles/${encodeURI(search)}`);
  };

  const [articleType, { url_prefix }] = Object.entries(TYPES).find(
    ([key, value]) => value.type === type
  ) ?? [null, {}];

  return (
    <main id="article-detail">
      <div className="inner">
        <Helmet>
          <title>{`${article.title} - Spicygaming`}</title>
          <meta
            name="description"
            content={shrinkText(article.short_description, 150)}
          />
          <base href="/" target="_blank" />
        </Helmet>
        <Header gameManu={false} searchCallback={handleSearch} />
        <SimpleDivider />
        <BreadCrumb divider=">">
          <BreadCrumb.Item href="/">Home</BreadCrumb.Item>
          <BreadCrumb.Item href="/articles">{articleType}</BreadCrumb.Item>
          <BreadCrumb.Item href={`${url_prefix}${article.id}`}>
            {article.title}
          </BreadCrumb.Item>
        </BreadCrumb>
        <h1 className="title">{article.title}</h1>
        <div className="description">{article.short_description}</div>
        <div className="article-detail-content">
          <div className="article-container article">
            <Article
              type={type}
              article={article}
              author={author}
              likeURL={likeURL}
            />

            <div className="reviews-container" id="article_reviews">
              <h2 className="title">Comments</h2>
              <div data-disable={!!previewMode}>
                <Reviews type="comment" reviews={comments} />
              </div>

              {loggedInValidation() ? (
                <div className="comment" data-disable={!!previewMode}>
                  <textarea
                    id="review-textarea"
                    rows="5"
                    placeholder={"Write a comment..."}
                    onChange={(e) => setComment(e.target.value)}
                    disabled={!!previewMode}
                    spellCheck
                    value={comment}
                  ></textarea>
                  <br />
                  <SubmitButton
                    className="submit"
                    request={sendComment}
                    label="Submit Comment"
                  />
                </div>
              ) : (
                <Link to={`/login${window.location.pathname}`}>
                  <br />
                  <div>
                    <u>Login</u> to add comments
                  </div>
                </Link>
              )}
            </div>
          </div>

          <aside>
            <SideCreatorInfo team={team} />
            <SimpleDivider />

            <Banner
              id={article_ad.side}
              width={300}
              height={250}
              lazyLoad
              jsAd="85916"
            />
            <SimpleDivider />

            <SuggestArticles
              articleId={article.id}
              teamName={team.name}
              type={type}
            />
          </aside>
        </div>
      </div>
    </main>
  );
};

export default ArticleDetails;
