import React from "react";
import { getClass } from "src/utils/getClass";

const RadioBox = (
  { label, error, id, checked, value, className, onChange, ...inputProps },
  ref
) => {
  return (
    <div className="flex justify-start items-stretch gap-3">
      <input
        {...inputProps}
        ref={ref}
        type="radio"
        id={id}
        checked={checked}
        onChange={(e) => {
          onChange?.(value, e);
        }}
        style={{ colorScheme: "dark" }}
        className={getClass("w-6 h-6 accent-red-600", className)}
      />
      <label className="flex justify-center items-center" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default React.forwardRef(RadioBox);
