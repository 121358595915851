import { useState } from "react";
import GoogleLogin from "react-google-login";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { GOOGLE_CLIENT_ID } from "src/constants/config";
import { request } from "src/utils/Axios";
import { useLanguage } from "../providers/LanguageProvider";
import { Loader, passwordToggle } from "./Common";
import GTags from "./GTags";
import { storeCredentials } from "./Login";
import { MultiLang } from "./MultiLang";
import { GoogleIcon } from "./common/social";
import inputFocuser from "./input_focus";
import { usePostHog } from "posthog-js/react";
import { toast } from "react-toastify";
import { resendVerificationEmail } from "src/v2/services/userService";
import { Helmet } from "react-helmet";
import { Divider } from "./Divider";
import { getGotoRoute } from "src/utils/keepConsistentRouteAfterLogin";

export const PASSWORD_REGEX =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[.A-Za-z\d.!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{6,}$/;

const Register = () => {
  const posthog = usePostHog();
  const language = useLanguage();
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [rePass, setRePass] = useState("");
  const [error, setError] = useState(false);
  const [token, setToken] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agreements, setAgreements] = useState({
    promotions: true,
    newsletter: true,
    terms: false,
  });

  inputFocuser("main.login .inputs .input input", "main.login button");

  const socialSignUp = async (data, method) => {
    setLoading(true);

    try {
      const res = await request({
        method: "post",
        url: "/api/social-signup",
        data: { ...data, agreements },
        withCredentials: true,
      });

      setError(res.details);
      if (res.success) {
        GTags.signUP(method);
        posthog.capture("User Register", { type: method });

        storeCredentials(res);
        window.location.href = getGotoRoute();
      }
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };
  const googleLogin = async (e) => {
    socialSignUp({ google_token: e.tokenId }, "google");
  };
  const googleLoginError = () => {
    setError("Error Occured in Google Login :(");
  };

  const onChange = (state) => (value) => state(value);
  const onChangeAgreement = (key, agreed) =>
    setAgreements((prev) => ({ ...prev, [key]: agreed }));
  const updateToken = (token) => setToken(token);

  const resendVerifyMail = async () => {
    try {
      await resendVerificationEmail(email);
      setError("Resent Verification Link");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const register = async () => {
    try {
      if ([email, password, rePass, token].some((item) => !!!item?.length)) {
        throw new Error("Empty Credentials");
      }
      if (!PASSWORD_REGEX.test(password)) {
        throw new Error(
          "Password need to be at least 6 characters long with a Number and a special character"
        );
      }
      if (password !== rePass) {
        throw new Error("both passwords didn't match!!");
      }
      setLoading(true);
      setError("Requesting...");

      const res = await request({
        url: "/api/members/register",
        data: {
          email,
          password,
          token,
          agreements,
        },
        method: "POST",
      });

      setError(res.details);

      if (res.success) {
        setSuccess(true);
        GTags.signUP("email");
        posthog.capture("User Register", { type: "email" });
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="login register max-w-xl mx-auto">
      <Helmet>
        <title>Sign-up - Spicygaming</title>
        <meta
          name="description"
          content="Register on Spicygamig for free to get access to downloads and writing comments and reviews"
        />
      </Helmet>
      {loading && <Loader />}

      <div className="inner">
        <header>
          <h1>
            <MultiLang sl="Registriraj Račun">REGISTER ACCOUNT</MultiLang>
          </h1>
        </header>
        <div className={`message ${error && "error-msg"}`}>
          {error ? (
            error
          ) : (
            <MultiLang sl="Izberite svoje uporabniške podatke">
              Please register with you account credentials
            </MultiLang>
          )}
        </div>
        {success ? (
          <button onClick={resendVerifyMail}>Resend Verification E-mail</button>
        ) : (
          <div className="contents">
            <div className="inputs">
              <Input
                icon={"fa-user-circle-o"}
                placeholder={inputPlaceholders(language, "email")}
                id="reg-email"
                value={email}
                type="email"
                callback={onChange(setEmail)}
              />
              <Input
                icon={"fa-lock"}
                placeholder={inputPlaceholders(language, "password")}
                id={"reg-password"}
                value={password}
                type={"password"}
                callback={onChange(setPass)}
              />
              <Input
                icon={"fa-lock"}
                placeholder={inputPlaceholders(language, "confirm")}
                id={"reg-conf-password"}
                value={rePass}
                type={"password"}
                callback={onChange(setRePass)}
              />
              <div className="little-btns">
                <Checkbox
                  label={
                    <MultiLang sl="Prijavi me na novice o novostih">
                      Sign me up for latest news and updates
                    </MultiLang>
                  }
                  id={"newsletter"}
                  value={agreements.newsletter}
                  callback={onChangeAgreement}
                />

                <Checkbox
                  label={
                    <MultiLang sl="Prijavi me na promocijska obvestila">
                      Sign me up for some sweet promotions
                    </MultiLang>
                  }
                  id={"promotions"}
                  callback={onChangeAgreement}
                  value={agreements.promotions}
                />
                <Checkbox
                  id={"terms"}
                  callback={onChangeAgreement}
                  value={agreements.terms}
                  label={
                    <>
                      <MultiLang sl="Sprejemam">I accept</MultiLang>{" "}
                      <Link to="/terms-of-service">
                        <MultiLang sl="pogoje poslovanja">
                          terms and conditions
                        </MultiLang>
                      </Link>
                    </>
                  }
                />
              </div>

              <ReCAPTCHA
                sitekey="6Lcs5OgdAAAAAAcIFvgiJ_9hJ_nTsx5hhWY_Q8pl"
                onChange={updateToken}
                theme="dark"
              />
            </div>

            <button onClick={register} disabled={!agreements.terms}>
              <MultiLang sl="Ustvari račun">Create Account</MultiLang>
            </button>
          </div>
        )}

        <div className="footer w-full bg-[#121212]">
          <Divider>or register with</Divider>

          <div className="oauth">
            <div className="oauth-btn">
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                onSuccess={googleLogin}
                onFailure={googleLoginError}
                theme="dark"
                tag="div"
                className="google-btn"
                scope="profile email"
                cookiePolicy={"single_host_origin"}
              >
                <GoogleIcon />
              </GoogleLogin>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="title2">
            <Link to={"/login"}>
              <MultiLang sl="Že naš član? ">Already a member? </MultiLang>
              <MultiLang sl="Prijavi se">Login</MultiLang>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export const Checkbox = ({ label, id, value, callback }) => {
  const onChange = (e) => {
    callback?.(id, e.target.checked);
  };
  return (
    <div className="CheckboxContainer">
      <div className="Checkbox">
        <input type="checkbox" id={id} checked={value} onChange={onChange} />
        <span className="CheckMark"></span>
      </div>
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export const Input = ({ id, icon, placeholder, callback, value, type }) => {
  const onChange = (e) => {
    callback?.(e.target.value);
  };
  return (
    <div className="input">
      <div className={`fa ${icon}`}></div>
      <input
        onChange={onChange}
        value={value}
        type={type}
        name={id}
        id={id}
        placeholder={placeholder}
      />
      {type === "password" && (
        <span className="fa fa-eye " onClick={passwordToggle}></span>
      )}
    </div>
  );
};

export const inputPlaceholders = (lang, key) => {
  return {
    en: {
      email: "Email address..",
      password: "Password...",
      confirm: "Confirm Password...",
    },
    sl: {
      email: "Email naslov..",
      password: "Geslo...",
      confirm: "Potrdite geslo...",
    },
  }[lang][key];
};
export default Register;
