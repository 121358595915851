import useEmblaCarousel from "embla-carousel-react";
import { createContext, useContext } from "react";

const CarouselContext = createContext({
  ref: null,
  api: null,
});

export function useCarousel() {
  return useContext(CarouselContext);
}

/**
 * CarouselProvider component to provide carousel context to its children.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child components that will consume the carousel context.
 * @param {Object} props.options - The configuration options for the Embla Carousel.
 * @param {any[]} props.plugins - The configuration plugins for the Embla Carousel.
 * @returns {JSX.Element} The provider component that wraps its children with carousel context.
 */
const CarouselProvider = ({ children, options, plugins = [] }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options, plugins);

  return (
    <CarouselContext.Provider value={{ ref: emblaRef, api: emblaApi }}>
      {children}
    </CarouselContext.Provider>
  );
};

export default CarouselProvider;
