import React from "react";
import { useLanguage } from "../../providers/LanguageProvider";
import { isDev } from "../../utils/Axios";
import { getClass } from "../../utils/getClass";
import "./style.css";

const MultiLang = ({ children, ...languages }) => {
  const language = useLanguage();

  return (
    <span className={getClass("MultiLang", isDev && "dev")} lang={language}>
      {languages[language] ?? children}
    </span>
  );
};

export default MultiLang;
