import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InnerHeader } from "src/components/CMS/Headers";
import { LoaderInside } from "src/components/Common";
import Error404 from "src/components/Errors";
import { authRequest } from "src/utils/Axios";
import VideoUpload from "./Upload";

const VideoUploadPage = (props) => {
  return (
    <div className="body">
      <InnerHeader {...props} element="back-button" />
      <div className="content">
        <VideoUpload />
      </div>
    </div>
  );
};
export const VideoEditPage = (props) => {
  const params = useParams();
  const [error, setError] = useState();
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: "/community/community-videos/video/edit",
        params: {
          video_id: params.id,
          cms: true,
        },
      });
      setDetails(res);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="body">
      <InnerHeader {...props} element="back-button" />
      <div className="content">
        {loading ? (
          <LoaderInside />
        ) : error ? (
          <Error404 />
        ) : (
          <VideoUpload
            editMode
            details={{
              ...details,
              games: details.games,
            }}
          />
        )}
      </div>
    </div>
  );
};
export default VideoUploadPage;
