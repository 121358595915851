import React, { useState } from "react";
import "./style.css";
import { SubmitButton } from "src/components/CMS/Common";
import { numberCompact } from "src/utils/number-format";
import { toast } from "react-toastify";

const LikeButton = ({
  numOfLikes,
  isLiked: previouslyLiked,
  onLike,
  rounded,
}) => {
  const [isLiked, setIsLiked] = useState(previouslyLiked);

  const onLikeClick = async () => {
    try {
      setIsLiked((prev) => !prev); // for instance response

      const liked = await onLike?.(isLiked);
      if (liked !== undefined) {
        setIsLiked(liked);
      } else {
        setIsLiked((prev) => !prev); // rollback instance response
      }
    } catch (error) {
      setIsLiked(previouslyLiked);
      toast.error("Like Failed");
    }
  };

  const totalLikes = isLiked
    ? numberCompact(numOfLikes + 1)
    : numberCompact(numOfLikes);

  const labelText = isLiked ? "Liked" : "Likes";

  return (
    <SubmitButton
      type="secondary"
      request={onLikeClick}
      icon={isLiked ? "fa-heart" : "fa-heart-o"}
      rounded={rounded}
      label={rounded ? totalLikes : `${totalLikes} ${labelText}`}
    />
  );
};

export default LikeButton;
