import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import relativeDate from "relative-date";
import { LoaderInside } from "src/components/Common";
import { generateURL } from "src/components/resizeImages";
import { BASE_CDN_URL, request } from "src/utils/Axios";

const NewsSection = ({ teamName }) => {
  const [teamGameNews, setTeamGameNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (teamName) getNews();
  }, [teamName]);

  const getNews = async () => {
    try {
      setLoading(true);
      const res = await request({
        url: "/api/content-creator-teams/game-news",
        baseURL: BASE_CDN_URL,
        withCredentials: true,
        params: {
          team_name: teamName,
        },
      });
      setTeamGameNews(res);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <LoaderInside />;

  return (
    <div className="team-news article">
      <h2 className="header">Latest {teamName} news</h2>
      <div className="content ">
        {teamGameNews.map((news) => (
          <FeaturedNews news={news} key={news.id} />
        ))}
      </div>

      {teamGameNews.length === 0 && (
        <div className="nothing">
          <p>No News Articles were Created by {teamName} yet</p>
        </div>
      )}
    </div>
  );
};

const FeaturedNews = ({ news }) => {
  if (!news)
    return (
      <div className="not-found news-article-container">
        <h2>No Game news found for you.</h2>
        You can post News about your games and they will be shown here
      </div>
    );

  const { square_cover, title, short_description, last_updated, id } = news;
  return (
    <div className="news-article-container">
      <Link to={`/news-article/${id}`}>
        <div className="image">
          <img
            loading="lazy"
            src={generateURL(400, 400, square_cover)}
            alt={title}
            draggable="false"
          />
        </div>
        <div className="text">
          <div className="title">{title}</div>
          <div className="info">
            <span className="fa fa-clock-o"></span>
            <span>{relativeDate(new Date(last_updated))}</span>
          </div>
          <div className="description">{short_description}</div>
        </div>
      </Link>
    </div>
  );
};
export default NewsSection;
