import { InnerHeader } from "../../Headers";
import CreateGamesTabs from "./CreateGameTabs";
import "./style.css";

const CreateGame = ({
  header,
  subHeader,
  toggler,
  editMode,
  defaults = {},
}) => {
  return (
    <div className="CreateGame body">
      <InnerHeader
        header={header}
        subHeader={subHeader}
        toggler={toggler}
        element={"back-button"}
      />
      <div className="content">
        <CreateGamesTabs
          gameID={defaults.ID}
          propsForEachPageComponent={{ id: defaults.ID, defaults, editMode }}
        />
      </div>
    </div>
  );
};

export default CreateGame;
