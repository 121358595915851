import { LoaderInside } from "src/components/Common";
import { getBundlesByGameId } from "src/v2/services/gameBundlesService";
import useSWR from "swr";
import GameBundle from "./GameBundle";

const GameBundles = ({ gameId }) => {
  const { isValidating, data } = useSWR(["game-bundles", gameId], () =>
    getBundlesByGameId(gameId)
  );

  if (data?.length === 0) return null;

  return (
    <section className="py-12">
      <legend>
        <h2 className="sub title">Buy in a bundle</h2>
      </legend>
      {isValidating && <LoaderInside />}

      <ul className="grid gap-16">
        {data?.map((bundle) => (
          <li key={bundle.id}>
            <GameBundle bundle={bundle} />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default GameBundles;
