import { useMemo } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Chip } from "src/components/Chip";
import { Checkbox } from "src/components/Register";
import Input from "src/v2/components/form-inputs/Input";
import Form from "src/v2/components/form/Form";

export const AddSaleProducts = ({ products = [] }) => {
  const methods = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "products",
  });

  const groupedProducts = useMemo(() => {
    /**
     * group the list to a list of groups
     * use `group_by` key as the common property for group
     */
    const groupedData = products.reduce((acc, item) => {
      const group = acc.find((g) => g.key === item.group_by);
      if (group) {
        group.items.push(item);
      } else {
        acc.push({
          name: item.group_name,
          items: [item],
          key: item.group_by,
          type: item.product_type,
        });
      }
      return acc;
    }, []);

    return groupedData;
  }, [products]);

  return (
    <div className="max-h-[40dvh] overflow-y-auto">
      {groupedProducts.map((group) => (
        <section key={group.key} className="my-5">
          <legend className="text-neutral-300 text-sm flex items-center gap-2 mb-2">
            <span className="line-clamp-1">{group.name}</span>
            <Chip>{group.type}</Chip>
          </legend>

          <div className="ml-5 grid gap-1">
            {group.items?.map((item) => {
              const index = fields.findIndex(
                (f) =>
                  f.product_id === item.id &&
                  f.product_type === item.product_type
              );

              const isAdded = index !== -1;
              return (
                <div
                  key={item.id}
                  className="flex justify-between items-center min-h-9 w-full max-w-xl"
                >
                  <div className="flex gap-2">
                    <Checkbox
                      value={isAdded}
                      id={item.id}
                      callback={(_, checked) => {
                        if (checked) {
                          append({
                            product_id: item.id,
                            product_type: item.product_type,
                            rate: "10",
                          });
                        } else {
                          remove(index);
                        }
                      }}
                    />
                    <label htmlFor={item.id}>{item.name}</label>
                  </div>

                  {isAdded && (
                    <Form.Item
                      name={`products.${index}.rate`}
                      label="Discount Rate (%)"
                      className="min-h-0 flex gap-2 items-center m-0"
                    >
                      <Input
                        className="py-1 px-2 max-w-28"
                        min="10"
                        type="number"
                      />
                    </Form.Item>
                  )}
                </div>
              );
            })}
          </div>
        </section>
      ))}
    </div>
  );
};
