import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import relativeDate from "relative-date";
import { getNotification } from "../notification-types";
import { Events } from "../../../constants/socket-events";
import { useSocket } from "../../../providers/SocketProvider";
import { authRequest } from "../../../utils/Axios";
import { getClass } from "../../../utils/getClass";
import "./style.css";

const Toast = ({ notification }) => {
  const notify = getNotification(notification.type);
  return (
    <div className="Toast">
      <Link to={notify?.getLink?.(notification)}>{notification.text}</Link>
      <Actions actions={notify?.actions} notification={notification} />
    </div>
  );
};

export const NotificationItem = ({ notification, onClose }) => {
  const notify = getNotification(notification.type);
  const socket = useSocket();
  const onItemClick = () => {
    onClose?.(false);
    sendReadEvent();
  };
  const description = notify?.getDescription?.(notification);

  const sendReadEvent = () => {
    if (!notification.is_read)
      socket.emit(Events.READ_NOTIFICATION, notification.id);
  };

  useEffect(() => {
    if (notify?.getLink === undefined) sendReadEvent();
  }, []);

  return (
    <div
      className={getClass("Notification", !notification.is_read && "new")}
      onClick={onItemClick}
    >
      <Link to={notify?.getLink?.(notification) ?? "#"}>
        <p>{notification.text}</p>
      </Link>
      {description && <p className="description">{description}</p>}
      {!notification.is_read && (
        <Actions
          onClose={onClose}
          actions={notify?.actions}
          notification={notification}
        />
      )}
      <small>{relativeDate(new Date(notification.creation_date))}</small>
    </div>
  );
};

const Actions = ({ actions, notification, onClose }) => {
  const history = useHistory();
  const socket = useSocket();
  if (!actions?.length) return null;
  return (
    <div className="actions">
      {actions?.map((action, i) => {
        const onClick = async (e) => {
          try {
            const result = await action.onClick?.(e, notification);
            if (!notification.is_read)
              socket.emit(Events.READ_NOTIFICATION, notification.id);
            if (result?.link) return history.push(result?.link);
          } catch (error) {
            toast.error(error.message);
          }
        };
        return (
          <button
            key={i}
            onClick={async (e) => {
              await onClick(e);
              onClose?.(false);
            }}
          >
            {action.icon && (
              <span className={getClass("fa", action.icon)}></span>
            )}
            <span>{action.label}</span>
          </button>
        );
      })}
    </div>
  );
};

export const markAllRead = async () => {
  try {
    await authRequest({
      url: "/notifications/api/push/notifications/mark-read",
      method: "POST",
      data: {
        all: true,
      },
    });
  } catch (error) {
    toast.error(error.message);
  }
};

export default Toast;
