import React, { useEffect, useState } from "react";
import { LoaderInside } from "src/components/Common";
import Error404 from "src/components/Errors";
import { Tabs } from "src/components/Tabs";
import { InnerHeader } from "../../Headers";
import TeamDetails from "./Details";
import TeamPayout from "./TeamPayout";
import TeamApps from "./TeamApps";
import "./style.css";
import { useRoleInfo, useTeamID } from "../../RoleContext";
import { useToggle } from "src/hooks/useToggle";
import { authRequest } from "src/utils/Axios";
import TeamMembers from "./TeamMembers";
import TeamGames from "./TeamGames";

const pages = [
  {
    url: "/info",
    displayText: "Team Details",
    component: TeamDetails,
    id: "info",
  },
  {
    url: "/members",
    displayText: "Members",
    component: TeamMembers,
    id: "members",
  },
  {
    url: "/games",
    displayText: "Games",
    component: TeamGames,
    id: "games",
  },
  {
    url: "/apps",
    displayText: "Connected Apps",
    component: TeamApps,
    id: "apps",
  },
  {
    url: "/payout-info",
    displayText: "Payout information",
    component: TeamPayout,
    id: "payout",
  },
];

const username = localStorage.getItem("username");

const EditTeamV2 = ({ header, toggler, subHeader }) => {
  const id = useTeamID();

  const { role: userRole } = useRoleInfo();

  const [team, setTeam] = useState({});
  const [games, setGames] = useState([]);
  const [members, setMembers] = useState([]);
  const [error, setError] = useState();
  const [loading, toggleLoading] = useToggle();

  useEffect(() => {
    (async () => {
      toggleLoading(true);

      await request("/api/content-creator-teams/edit", setTeam);
      await request("/api/content-creator-teams/games/details", setGames);
      await request("/api/content-creator-teams/members/details", setMembers);
      toggleLoading(false);
    })();
  }, [id]);

  const request = async (url, setState) => {
    try {
      if (isNaN(id)) return;
      const res = await authRequest({
        url,
        params: { edit: true, team_id: id, cms: true },
      });

      if (!res.error) {
        setState(res);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const textFields = {
    team_id: team.id,
    name: team.name,
    biography: team.biography,
    support_url: team.support_url,
    facebook: team.facebook_link,
    twitter: team.twitter_link,
    steam: team.steam_link,
    instagram: team.instagram_link,
    patreon: team.patreon_link,
    itchio: team.itchio_link,
    discord: team.discord_link,
    subscribestar: team.subscribestar_link,
    website_link: team.website_link,
  };
  const teamRole = members.find(
    (member) => member.username === username
  )?.team_role;

  return (
    <div className="body cms member-pages EditTeam ">
      <InnerHeader
        header={header}
        toggler={toggler}
        subHeader={subHeader || "Create Team"}
        element="back-button"
      />
      <div className="content">
        {loading ? (
          <LoaderInside />
        ) : (
          <Tabs
            firstPageId="info"
            pages={pages}
            withURLChange
            propsForEachPageComponent={{
              id,
              members,
              textFields,
              image: team.profile_picture,
              games,
              team,
              userRole,
              teamRole,
              editMode: true,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EditTeamV2;
