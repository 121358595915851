import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import relativeDate from "relative-date";
import useSWRInfinite from "swr/infinite";
import { Header, LoadMore, sort } from "../../Common";
import { fetcher } from "../Cms";
import "../css/GameCreation.css";
import { MainHeader } from "../Headers";
import { ContentTile } from "../Tile";
import { toSpicyTokensWithEuro } from "../Users/Withdraw";
import dateFormat from "dateformat";
import { refundsOptions } from "./Options";

const Refunds = ({ toggler, subHeader }) => {
  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) =>
      `${window.domainName}/store/api/refund-requests/list?page=${index + 1}`,
    fetcher,
    { revalidateOnFocus: false }
  );

  const refunds = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];
  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  const sortState = (key) => (order) => {
    mutate(sort(key, refunds, order), false);
  };

  const headers = [
    { text: "PRODUCT", sort: sortState("product") },
    { text: "Amount", sort: sortState("amount") },
    { text: "REASON", sort: sortState("reason") },
    { text: "PURCHASER", sort: sortState("purchaser") },
    { text: "DATE", sort: sortState("creation_date") },
  ];

  const options = refundsOptions();
  return (
    <div className="body">
      <MainHeader toggler={toggler} header={subHeader} buttons={false} />
      <div className="content">
        <main id="game-article" className="cms-details">
          <div
            className="header"
            style={{
              gridTemplateColumns: `var(--game-item-frac) 4fr repeat(${
                headers.length - 2
              }, var(--game-item-frac)) 2fr`,
            }}
          >
            {headers.map((header, i) => (
              <Header header={header} key={i} />
            ))}
          </div>
          <p className="instructions list blue-icon">Refund is Approved</p>

          <TransitionGroup className="body" component="div">
            {refunds.map((refund, i) => {
              const data = [
                refund.product,
                toSpicyTokensWithEuro(refund.amount),
                refund.reason,
                refund.purchaser,
                relativeDate(new Date(refund.creation_date)),
              ];

              return (
                <CSSTransition
                  key={i}
                  timeout={300}
                  classNames="popup"
                  mountOnEnter
                >
                  <ContentTile
                    color={refund.approved ? "#00b4f2" : ""}
                    headers={headers.map((header) => header.text)}
                    data={data}
                    revalidate={() => mutate(refunds, true)}
                    popupData={{
                      deny: {
                        id: refund.id,
                        reason: refund.reason,
                        approve: false,
                      },
                      approve: {
                        id: refund.id,
                        reason: refund.reason,
                        approve: true,
                        refund,
                      },
                      show_full: {
                        sale: { ...refund, transaction_id: refund.transaction },
                        fullData: [
                          { label: "Product", content: refund.product },
                          { label: "Reason", content: refund.reason },
                          { label: "Product ID", content: refund.product_id },
                          {
                            label: "Product Type",
                            content: refund.product_type,
                          },
                          {
                            label: "Amount",
                            content: toSpicyTokensWithEuro(refund.amount),
                          },
                          {
                            label: "Fee",
                            content: toSpicyTokensWithEuro(refund.fee),
                          },
                          {
                            label: "Created On",
                            content: dateFormat(refund.creation_date),
                          },
                          {
                            label: "Transaction ID",
                            content: refund.transaction,
                          },
                        ],
                      },
                    }}
                    options={options}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
          {errors && <div className="error">{errors.error}</div>}
          {error && <div className="error">{error.message}</div>}
          <LoadMore onClick={() => setSize((prev) => prev + 1)} />
        </main>
      </div>
    </div>
  );
};

export default Refunds;
