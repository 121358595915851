import { RoleProvider } from "src/components/CMS/RoleContext";
import { VideoMetaDataProvider } from "src/components/Video/MetadataProvider";
import { GameMetaDataProvider } from "../components/AdultGames/MetaDataProvider";
import { ArticleMetaDataProvider } from "../components/Article/MetaDataProvider";
import { WidthProvider } from "../components/Context";
import { IndexedDBProvider } from "./IndexedDBProvider";
import { LanguageProvider } from "./LanguageProvider";
import { SocketProvider } from "./SocketProvider";
import SpicyStoreProvider from "./SpicyStoreProvider";
import TokenProvider from "./TokenProvider";
import { SWRConfig } from "swr";
import BroughtProductsProvider from "./BroughtProductsProvider";

const Providers = ({ children }) => (
  <SWRConfig value={{ revalidateOnFocus: false }}>
    <RoleProvider>
      <IndexedDBProvider>
        <LanguageProvider>
          <SpicyStoreProvider>
            <BroughtProductsProvider>
              <WidthProvider>
                <GameMetaDataProvider>
                  <ArticleMetaDataProvider>
                    <VideoMetaDataProvider>
                      <SocketProvider>
                        <TokenProvider>{children}</TokenProvider>
                      </SocketProvider>
                    </VideoMetaDataProvider>
                  </ArticleMetaDataProvider>
                </GameMetaDataProvider>
              </WidthProvider>
            </BroughtProductsProvider>
          </SpicyStoreProvider>
        </LanguageProvider>
      </IndexedDBProvider>
    </RoleProvider>
  </SWRConfig>
);

export default Providers;
