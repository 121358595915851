const Description = ({ description }) => {
  return (
    <section>
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </section>
  );
};

export default Description;
