import { useState } from "react";
import { Tabs } from "src/components/Tabs";
import { BundleInfo } from "./BundleInfo";
import { BundleProducts } from "./BundleProducts";

const AddNewBundle = ({
  editMode = false,
  defaultBundleId = null,
  defaultValues,
  defaultPageId = "info",
}) => {
  const [bundleId, setBundleId] = useState(defaultBundleId);

  return (
    <div>
      <h2 className="text-2xl mb-4">
        {editMode ? "Edit Bundle" : "Add New Bundle"}
      </h2>

      <Tabs
        firstPageId={defaultPageId}
        propsForEachPageComponent={{
          bundleId,
          setBundleId,
          defaultValues,
          editMode,
        }}
        pages={[
          {
            component: BundleInfo,
            displayText: "Bundle Info",
            id: "info",
          },
          {
            component: BundleProducts,
            displayText: "Discounted Products",
            id: "products",
            disabled: !bundleId,
          },
        ]}
      />
    </div>
  );
};

export default AddNewBundle;
