import React from "react";
import "./style.css";
import SolarGameboyBold from "src/Icons/SolarGameboyBold";

const GameArcade = () => {
  return (
    <div className="PREVIEW__Arcade" data-testid="Arcade">
      <h1>Spicygaming Exclusive Arcade</h1>
      <SolarGameboyBold width={200} height={200} />
      <h2>Coming Soon</h2>
    </div>
  );
};

export default GameArcade;
