const withPrivacy = (callback) => {
  return (...args) => {
    const allowed = window.ANALYTICS_ACCEPTED; // ? this is setted in <CookieWarning />
    if (allowed) return callback(...args);
  };
};

const search = withPrivacy((query) => {
  window.gtag("event", "search", {
    search_term: query,
  });
});
const signUP = withPrivacy((method) => {
  window.gtag("event", "sign_up", {
    method,
  });
});

const gameClickEvents = withPrivacy((content_type, id) => {
  window.gtag("event", "select_content", {
    content_type,
    item_id: id,
  });
});

const adImpressions = withPrivacy((position, link) => {
  window.gtag("event", "banner_impressions", {
    position,
    link,
  });
});

const adClicks = withPrivacy((position, link) => {
  window.gtag("event", "banner_clicks", {
    position,
    link,
  });
});
const videoPlay = withPrivacy((link) => {
  window.gtag("event", "video_play", {
    link,
  });
});

const addToCart = withPrivacy((price, item) => {
  window.gtag("event", "add_to_cart", {
    currency: "EUR",
    value: price,
    items: [item],
  });
});
const removeFromCart = withPrivacy((price) => {
  window.gtag("event", "remove_from_cart", {
    currency: "EUR",
    value: price,
  });
});

const pageNotFoundError = (url, error) => {
  window.gtag("event", "Page Not Found", {
    url,
    error,
  });
};

const recordPurchase = ({ transaction_id, value, tax, items }) => {
  console.log("executed");

  window.gtag("event", "purchase", {
    transaction_id,

    value,
    tax,
    currency: "EUR",
    items,
  });
};

const beginCheckout = (price, items) => {
  window.gtag("event", "begin_checkout", {
    currency: "EUR",
    value: price,
    items,
  });
};
const refund = (options) => {
  window.gtag("event", "refund", options);
};

const GTags = {
  search,
  signUP,
  gameClickEvents,
  adImpressions,
  adClicks,
  videoPlay,
  addToCart,
  beginCheckout,
  refund,
  recordPurchase,
  pageNotFoundError,
};

export default GTags;
