import { useState } from "react";

/**
 *
 * @returns {[boolean, (value?:boolean) => void]}
 */
export const useToggle = (defaultState) => {
  const [toggle, setToggle] = useState(defaultState ?? false);

  const toggler = (value) => {
    setToggle((prev) => {
      return typeof value === "boolean" ? value : !prev;
    });
  };
  return [toggle, toggler];
};
