import React, { Suspense } from "react";
import { useHistory } from "react-router-dom";
import {
  getFeaturedGames,
  getPreSearchFeaturedGames,
  getPreSearchGames,
} from "src/v2/services/gamesService";
import Banner from "./Banners";
import { Featured } from "./CMS/Ads/FeaturedListPages/util";
import { LoaderInside } from "./Common";
import { useMobile } from "./Context";
import Header from "./Header";
import "./Home/css/Home.css";
import FeaturedNewsWrapper from "./Home/FeaturedWrapper";
import FeaturedGames from "./Home/sections/FeaturedGames";
import { LazyLoaded } from "./LazyLoaded";
import useSWRImmutable from "swr/immutable";
import { getOngoingSaleGames } from "src/v2/services/gameSalesService";
import { Helmet } from "react-helmet";

const Offers = React.lazy(() => import("./Home/sections/Offers"));
const NewsWrapper = React.lazy(() => import("./Home/NewsWrapper"));
const GamesBelow10 = React.lazy(() => import("./Home/sections/GamesBelow10"));
const TopCategories = React.lazy(() => import("./Home/sections/TopCategories"));
const DontMissSection = React.lazy(() => import("./Home/DontMissWrapper"));

const CategorizedGames = React.lazy(() =>
  import("./Home/sections/CatergorizedGames")
);
const PrefilteredSections = React.lazy(() =>
  import("./Home/sections/PrefilteredSections")
);

const Home = () => {
  const isMobile = useMobile();
  const history = useHistory();
  const yr = new Date().getFullYear();

  const { data: saleGames, isValidating } = useSWRImmutable(
    "game-offers",
    getOngoingSaleGames
  );

  const search = ({ search, category }) => {
    const links = {
      Games: `/porn-games/${search}/1`,
      News: `/articles/${search}`,
    };

    const selectedLink = links[category] || "";
    if (!!search.length) history.push(selectedLink);
  };
  return (
    <main className="home" id="home">
      <Helmet>
        <title>SpicyGaming.net - Adult games, Porn Games, Hentai Games</title>
        <meta
          name="description"
          content="1000s of adult games only on Spicygaming.net. Download free porn games. Play Hentai games for pc, mac and android. News, reviews and videos."
        />
      </Helmet>
      <div className="inner">
        <Header gameManu={true} searchCallback={search} />
        <FeaturedGames
          id="featured-games"
          title={"Featured & Recommended"}
          getGames={getFeaturedGames}
        />
        {/* <div className="h-96 aspect-[3] mx-auto my-16 bg-white"></div> */}
        <FeaturedNewsWrapper />

        <LazyLoaded rootMargin={"600px"}>
          {/* ad */}
          <div className="w-fit m-auto flex gap-5">
            <Banner id="banner_1.1" width={300} height={250} jsAd="85914" />
            {!isMobile && (
              <>
                <Banner id="banner_1.2" width={300} height={250} jsAd="85914" />
                <Banner id="banner_1.3" width={300} height={250} jsAd="85914" />
              </>
            )}
          </div>

          <Suspense fallback={<LoaderInside />}>
            <Offers
              games={saleGames}
              loading={isValidating}
              sectionName="Special Offers"
            />
            <PrefilteredSections />
            <TopCategories />
            <GamesBelow10 />
          </Suspense>
        </LazyLoaded>

        <LazyLoaded rootMargin={"600px"}>
          <Suspense fallback={<LoaderInside />}>
            {/* Best Animated porn games */}
            <FeaturedGames
              id="animated-games"
              title={"Best Animated porn games"}
              getGames={() => getPreSearchFeaturedGames({ tags: "Animated" })}
              sectionLink="/porn-games/custom/tags/Animated/1"
            />

            {/* Top Adult Visual novels (landscape design) */}
            <CategorizedGames
              title={"Best Adult Visual Novel Game"}
              id="visual_novels"
              getGames={() => getPreSearchGames({ genre: "Visual Novel" })}
              sectionLink="/porn-games/custom/genre/Visual Novel/1"
            />

            {/* Harem Porn Games (landscape design) */}
            <CategorizedGames
              title={"Best Harem Games"}
              id="harem"
              getGames={() => getPreSearchGames({ tags: "Harem" })}
              sectionLink="/porn-games/custom/tags/Harem/1"
            />

            {/* ad */}
            <div className="w-fit m-auto flex gap-5">
              <Banner id="banner_2.1" width={300} height={250} jsAd="85914" />
              {!isMobile && (
                <>
                  <Banner
                    id="banner_2.2"
                    width={300}
                    height={250}
                    jsAd="85914"
                  />
                  <Banner
                    id="banner_2.3"
                    width={300}
                    height={250}
                    jsAd="85914"
                  />
                </>
              )}
            </div>

            {/*  Best 2D art porn games */}
            <FeaturedGames
              id="2d-games"
              title={"Best 2D porn games"}
              getGames={() => getPreSearchFeaturedGames({ tags: "2dcg" })}
              sectionLink="/porn-games/custom/tags/2dcg/1"
            />

            {/* Open World Porn Games (landscape design) */}
            <CategorizedGames
              title={"Best Open World Porn Games"}
              id="Open World"
              getGames={() => getPreSearchGames({ genre: "Open World" })}
              sectionLink="/porn-games/custom/genre/Open World/1"
            />

            {/* Milf Porn Games (landscape design) */}
            <CategorizedGames
              title={"Best Milf Porn Games"}
              id="Milf Porn Games"
              getGames={() => getPreSearchGames({ tags: "Milf" })}
              sectionLink="/porn-games/custom/tags/Milf/1"
            />

            {/* Porn Games for Mac (landscape design) */}
            <CategorizedGames
              title={"Porn Games for Mac"}
              id="Porn Games for Mac"
              getGames={() => getPreSearchGames({ os: "MacOS" })}
              sectionLink="/porn-games/custom/os/MacOS/1"
            />

            {/* BIG NEW ADD will provide */}
            <div className="w-fit m-auto flex gap-5">
              <Banner id="banner_3.1" width={300} height={250} jsAd="85914" />
              {!isMobile && (
                <>
                  <Banner
                    id="banner_3.2"
                    width={300}
                    height={250}
                    jsAd="85914"
                  />
                  <Banner
                    id="banner_3.3"
                    width={300}
                    height={250}
                    jsAd="85914"
                  />
                </>
              )}
            </div>

            {/* Best 3D art porn games (featured recommended design) */}
            <FeaturedGames
              id={"3d-games"}
              title={"Best 3D porn games"}
              getGames={() => getPreSearchFeaturedGames({ tags: "3dcg" })}
              sectionLink="/porn-games/custom/tags/3dcg/1"
            />

            {/* Html Porn games (landscape design) */}
            <CategorizedGames
              title={"Html Porn games"}
              id="Html Porn games"
              getGames={() => getPreSearchGames({ engine: "HTML" })}
              sectionLink="/porn-games/custom/engine/HTML/1"
            />

            {/* RPG Porn Games (landscape design) */}
            <CategorizedGames
              title={"RPG Porn Games"}
              id="RPG Porn Games"
              getGames={() => getPreSearchGames({ genre: "RPG" })}
              sectionLink="/porn-games/custom/genre/RPG/1"
            />

            {/* Porn Games for Mac (landscape design) */}
            <CategorizedGames
              title={"Porn Games for Windows"}
              id="Porn Games for Windows"
              getGames={() => getPreSearchGames({ os: "Windows" })}
              sectionLink="/porn-games/custom/os/Windows/1"
            />
          </Suspense>
        </LazyLoaded>

        <LazyLoaded rootMargin={"600px"}>
          <Suspense fallback={<LoaderInside />}>
            <DontMissSection
              position={Featured.BEST_OF_PORN_GAMES}
              title="BEST OF PORN GAMES"
              sectionRedirectLink={"/articles/best-of-porn/1?sort=last_updated"}
            />
          </Suspense>

          <Suspense fallback={<LoaderInside />}>
            <NewsWrapper />

            <DontMissSection
              position={Featured.BEST_FROM_ADULT_GAMES}
              title="BEST FROM ADULT GAMES"
              sectionRedirectLink={"/porn-games/1"}
            />

            <DontMissSection
              position={Featured.BEST_PORN_GAMES}
              title={`BEST PORN GAMES OF ${yr}`}
              sectionRedirectLink={"/articles/best-of-porn/1?sort=last_updated"}
            />
          </Suspense>
        </LazyLoaded>
      </div>
    </main>
  );
};

export default Home;
