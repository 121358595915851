import FeaturedSection from "./HomePage/Featured";
import { Featured } from "./util";

export const DontMissConfiguration = () => {
  const yr = new Date().getFullYear();
  return (
    <div>
      <FeaturedSection
        title="BEST of PORN GAMES"
        position={Featured.BEST_OF_PORN_GAMES}
        allowedObjects={[
          "game_news",
          "staff_reviews",
          "blog_posts",
          "games",
          "video",
          "gallery",
        ]}
      />
      <FeaturedSection
        title="BEST FROM ADULT GAMES"
        position={Featured.BEST_FROM_ADULT_GAMES}
        allowedObjects={[
          "game_news",
          "staff_reviews",
          "blog_posts",
          "games",
          "video",
          "gallery",
        ]}
      />
      <FeaturedSection
        title={`BEST PORN GAMES OF ${yr}`}
        position={Featured.BEST_PORN_GAMES}
        allowedObjects={[
          "game_news",
          "staff_reviews",
          "blog_posts",
          "games",
          "video",
          "gallery",
        ]}
      />
    </div>
  );
};
