export const ALL_PRODUCT_TYPES = [
  "Game",
  "Team Donation",
  "Affiliate Sale",
  "Token Purchase",
  "Refund",
];

export const GET_PRODUCTS = ["Token Purchase", "Refund"];
export const SPEND_PRODUCTS = ["Game", "Team Donation", "Affiliate Sale"];
