import { useEffect, useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Required } from "../CMS/Common";
import "./style.css";

/** This is a finder. Includes a search bar
 * @param {Object} props
 * @param {String} props.label - the top label (identifier)
 * @param {Boolean} props.disabled
 * @param {Boolean} props.required
 * @param {Boolean} props.showSearch
 * @param {String} props.id - the id of the Whole radios
 * @param {{name: String, id: String }} props.value - the selected value
 * @param {{name: String, id: String }[]} props.data - Data
 * @param {(value: String) => Promise<{name: String, id: String }[]>} props.update - Update data
 * @param {(value: {name: String, id: String }) => void } props.onChange
 * @param {(value: {name: String, id: String }) => String } props.idExtractor
 *
 * see code for more params and details
 */
const Finder = ({
  id,
  value,
  label,
  required,
  className,
  disabled,
  update,
  onChange,
  idExtractor,
  data,
  showSearch = true,
}) => {
  const [searchedData, setSearchedData] = useState(data ?? []);

  const [showData, setShowData] = useState(false);
  const [error, setError] = useState("");
  const [query, setQuery] = useState("");
  useEffect(() => {
    setSearchedData(data);
  }, [data]);

  const searchButton = useRef(null);
  const toggleShow = () => setShowData((prev) => !prev);
  const updateQuery = (e) => setQuery(e.target.value);

  const onUpdate = async () => {
    try {
      setError(`Searching`);
      const found = await update?.(query);
      setSearchedData(found);
      setError(`Found ${found.length} of results`);
    } catch (error) {
      setError(error.message);
    }
  };

  const onChangeRadio = (elem) => () => {
    onChange?.(elem);
  };

  return (
    <div id="finder" className={className} data-disable={disabled}>
      <p className="input-name">
        {label}
        {required && <Required />}
      </p>
      <div className={`container ${showData && "show"}`}>
        <div className="label" onClick={toggleShow}>
          {value?.id ? value.name : "- Select from below"}
        </div>

        {showData && (
          <>
            {showSearch && (
              <div className="search">
                <input
                  type="search"
                  onChange={updateQuery}
                  id="search-input"
                  placeholder="Search..."
                  value={query}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      searchButton.current?.click();
                    }
                  }}
                />
                <span
                  className="fa fa-search"
                  ref={searchButton}
                  onClick={onUpdate}
                ></span>
              </div>
            )}
            <TransitionGroup component="div" className="radios">
              {!!searchedData.length &&
                searchedData.map((elem) => {
                  const eachID = idExtractor?.(elem);

                  return (
                    <CSSTransition
                      classNames="fade"
                      timeout={300}
                      mountOnEnter
                      unmountOnExit
                      key={eachID}
                    >
                      <div className="radio">
                        <input
                          type="radio"
                          id={eachID}
                          value={elem.id}
                          name={id}
                          checked={elem.id === value?.id}
                          onChange={onChangeRadio(elem)}
                        />
                        <label htmlFor={eachID}>{elem.name}</label>
                      </div>
                    </CSSTransition>
                  );
                })}
            </TransitionGroup>
            {error}
          </>
        )}
      </div>
    </div>
  );
};

export default Finder;
