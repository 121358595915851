import axios from "axios";
import React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { SubmitButton } from "./CMS/Common";
import { inputValidate } from "./CMS/validation";
import { passwordToggle } from "./Common";
import { PASSWORD_REGEX } from "./Register";

const ForgotPassword2 = ({ match }) => {
  // if the user didnt logged in he will be redirect into /login/forget-password/hassanthis type path
  // useEffect request for first validation

  const { reset_code } = match.params;
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [error, setError] = useState(false);

  const [success, setSuccess] = useState(false);

  const reset = async () => {
    const sanitized = inputValidate(password, PASSWORD_REGEX);
    if (sanitized.validated) {
      if (sanitized.value === rePassword) {
        try {
          setError("Requesting...");
          const req = await axios({
            url: window.domainName + "/api/members/forgot-password",
            method: "POST",
            data: {
              reset_code,
              password,
            },
          });

          const res = await req.data;

          if (res.error) setError(res.error);
          if (res.success) setSuccess(true);
        } catch (error) {
          setError(error.message);
        }
      } else {
        setError("both passwords did't match");
      }
    } else {
      setError(
        "Password need to be at least 6 characters long with a Number and a special character"
      );
    }
  };

  const input = (value, state) => state(value);

  const redirect = () => {
    history.push("/login");
  };

  return (
    <main className="login" id="set-new-password">
      <div className="inner">
        {success !== true ? (
          <>
            <header>SET NEW PASSWORD</header>
            <div className="message">
              {!error ? (
                "Please set your new password (a strong and memorable one)"
              ) : (
                <div className="error">{error}</div>
              )}
            </div>
            <div className="inputs">
              <div className="input">
                <div className="fa fa-lock"></div>
                <input
                  type="password"
                  onChange={(e) => input(e.target.value, setPassword)}
                  value={password}
                  id="forgot-password"
                  placeholder="Password.."
                />
                <span className="fa fa-eye " onClick={passwordToggle}></span>
              </div>
              <div className="input">
                <div className="fa fa-lock"></div>
                <input
                  type="password"
                  onChange={(e) => input(e.target.value, setRePassword)}
                  value={rePassword}
                  id="forgot-re-password"
                  placeholder="re-type Password.."
                />
                <span className="fa fa-eye " onClick={passwordToggle}></span>
              </div>
            </div>

            <SubmitButton
              request={reset}
              label="Continue"
              id="password-reset"
              disabled={!(!!password.length && !!rePassword.length)}
            />
          </>
        ) : (
          <>
            <div className="success">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 122.88 122.88"
              >
                <path
                  className="cls-1"
                  fillRule="evenodd"
                  fill="#00a912"
                  d="M61.44,0A61.44,61.44,0,1,1,0,61.44,61.44,61.44,0,0,1,61.44,0Z"
                />
                <path
                  className="cls-2"
                  fillRule="evenodd"
                  fill="#fff"
                  d="M42.37,51.68,53.26,62,79,35.87c2.13-2.16,3.47-3.9,6.1-1.19l8.53,8.74c2.8,2.77,2.66,4.4,0,7L58.14,85.34c-5.58,5.46-4.61,5.79-10.26.19L28,65.77c-1.18-1.28-1.05-2.57.24-3.84l9.9-10.27c1.5-1.58,2.7-1.44,4.22,0Z"
                />
              </svg>
              <h1>Password Changed !</h1>
            </div>

            <button onClick={redirect}>Login</button>
          </>
        )}
      </div>
    </main>
  );
};

export default ForgotPassword2;
