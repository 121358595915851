import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import Banner from "src/components/Banners";
import { AD_IDS } from "src/components/CMS/Ads/Section";
import { Loader, LoaderInside } from "src/components/Common";
import Header from "src/components/Header";
import { SideCreatorInfo } from "src/components/common/SideCreatorInfo";
import SomethingWentWrong from "src/errors/SomethingWentWrong";
import { BASE_CDN_URL, request } from "src/utils/Axios";
import Gallery from "./Gallery";
import updateViewed from "src/utils/updateViewed";
import SuggestCollections from "./SuggestCollections";
import shrinkText from "src/utils/shrinkText";

const FullCollectionPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [loadingTeam, setLoadingTeam] = useState(true);
  const [error, setError] = useState(null);
  const [gallery, setGallery] = useState({});
  const [team, setTeam] = useState({});

  useEffect(() => {
    getDetails();
  }, [id]);
  useEffect(() => {
    if (gallery.team_id) getTeamInfo(gallery.team_id);
  }, [gallery.team_id]);

  const getTeamInfo = async (team_id) => {
    try {
      setLoadingTeam(true);
      const res = await request({
        baseURL: BASE_CDN_URL,
        url: "/api/content-creator-teams/team-profile",
        params: { team_id },
      });
      setTeam(res);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTeam(false);
    }
  };

  const getDetails = async () => {
    try {
      setLoading(true);
      const res = await request({
        url: "/products/api/spicyart/gallery",
        baseURL: BASE_CDN_URL,
        params: {
          gallery_id: id,
        },
      });

      setGallery(res);
    } catch (error) {
      setError(true);
    } finally {
      updateViewed(id, "Gallery");
      setLoading(false);
    }
  };

  if (error)
    return (
      <main id="game-detail" className="FullCollectionPage">
        <SomethingWentWrong />
      </main>
    );

  return (
    <main className="FullCollectionPage">
      {gallery.name && (
        <Helmet>
          <title>{`${gallery.name} | Spicygaming`}</title>
          <meta
            name="description"
            content={shrinkText(gallery.description, 150)}
          />
        </Helmet>
      )}
      <div className="inner">
        <Header
          gameManu={false}
          searchCallback={(query) => {
            if (query.search?.length > 0)
              return history.push(`/galleries/${query.search}/1`);
          }}
        />

        <h1 className="title">
          <span>{gallery.name}</span>
        </h1>
        <div className="content">
          <div className="main-content">
            {loading ? (
              <LoaderInside />
            ) : (
              <Gallery gallery={gallery} team={team} />
            )}
          </div>

          <aside>
            {loadingTeam ? (
              <LoaderInside />
            ) : (
              <>
                <SideCreatorInfo team={team} />
                <Banner
                  id={"IMAGE_GALLERY"}
                  width={300}
                  height={250}
                  jsAd="94330"
                />
                <SuggestCollections
                  galleryId={gallery.id}
                  teamName={team.name}
                />
              </>
            )}
          </aside>
        </div>
      </div>
    </main>
  );
};

export default FullCollectionPage;
