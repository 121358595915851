import { authRequest } from "src/utils/Axios";

export const addVideoToWatchLater = async (id) => {
  await authRequest({
    url: "/community/playlists/user-library",
    method: "POST",
    data: {
      video_id: id,
    },
  });
};
