import React, { useEffect, useRef, useState } from "react";
import { socialLinks } from "../ContentCreateTeam/data";
import { Input } from "src/components/Member/General";
import { useRoleInfo } from "../../RoleContext";
import { inputValidate } from "../../validation";
import { inputIteratorValidate } from "src/components/Validation";
import { authRequest } from "src/utils/Axios";
import { generateURL } from "src/components/resizeImages";
import { Avatar } from "src/components/common/Avatar";
import { toast } from "react-toastify";
import { SubmitButton } from "../../Common";

const TeamDetails = ({ image, textFields = {}, id, editMode }) => {
  const { auth } = useRoleInfo();

  const [profilePic, setProfilePic] = useState(image || null);
  const [profilePicBlob, setProfilePicBlob] = useState(null);

  const [teamID, setTeamID] = useState(id);

  const [formData, setFormData] = useState({
    name: "",
    biography: "",
    website_link: "",
    facebook: "",
    steam: "",
    twitter: "",
    instagram: "",
    patreon: "",
    itchio: "",
    discord: "",
    subscribestar: "",
    ...textFields,
  });

  const getValue = (key) => (value) => {
    const sanitized = inputValidate(value, /.*/g);

    if (sanitized.validated)
      setFormData((prev) => ({
        ...prev,
        [key]: sanitized.value,
      }));
  };

  const profilePicUpload = (e) => {
    const pic = e.target.files[0];

    setProfilePic(pic);
    setProfilePicBlob(URL.createObjectURL(pic));
  };

  const createTeam = async () => {
    const fd = new FormData();

    const REQUEST_URL = editMode
      ? "/api/content-creator-teams/edit"
      : "/api/content-creator-teams/create";

    const noErrors = [
      inputIteratorValidate(formData, "Empty Text fields", [
        "support_url",
        "facebook",
        "twitter",
        "steam",
        "instagram",
        "patreon",
        "itchio",
        "discord",
        "subscribestar",
        "website_link",
        "team_id",
      ]),
      profilePic ? true : "Empty Profile Picture",
    ];
    for (const key in formData) {
      fd.append(key, !!formData[key]?.length ? formData[key] : "");
    }
    fd.append("team_id", teamID ?? "");
    fd.append(
      "profile_picture",
      typeof profilePic === "string" ? "current" : profilePic
    );

    if (noErrors.every((elem) => elem === true)) {
      try {
        const res = await authRequest({
          url: REQUEST_URL,
          method: "POST",
          data: fd,
        });

        if (res.success) {
          toast.success("Successfully saved");
          setTeamID(res.team_id);
          return await auth?.();
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error(noErrors.filter((noError) => noError !== true));
    }
  };
  return (
    <div className="create-team general" id="create-team">
      <section className="basic">
        <legend>TEAM information</legend>

        <div className="section-content">
          <div className="pic">
            <div className={`avatar picture`}>
              <Avatar
                src={profilePicBlob ?? generateURL(200, 200, image)}
                name={formData.name}
                width={200}
              />
              <div className="change-pic">
                <label htmlFor="general-pic">
                  <div className="fa fa-camera"></div>
                  Change Profile Picture
                </label>
              </div>
            </div>

            <input
              type="file"
              id="general-pic"
              onChange={profilePicUpload}
              accept="image/*"
            />
          </div>

          <div className="user-inputs">
            <Input
              callback={getValue("name")}
              value={textFields.name}
              label="Team Name"
              options={{ type: "text" }}
            />

            <div className="input">
              <div className="title">Team Bio</div>
              <div className="input-container">
                <textarea
                  rows={5}
                  onChange={(e) => getValue("biography")(e.target.value)}
                  defaultValue={formData.biography}
                  placeholder={"Type Here..."}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="personal">
        <legend>Social Info</legend>
        <div className="section-content">
          <div className="user-inputs">
            {socialLinks.map((input, key) => (
              <Input
                {...input}
                key={key}
                callback={getValue(input.id)}
                value={textFields[input.id]}
              />
            ))}
          </div>
        </div>
        <SubmitButton label="Save Changes" request={createTeam} />
      </section>
    </div>
  );
};

export default TeamDetails;
