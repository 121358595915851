import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { dateFormatForDateInput } from "src/utils/date-format";
import Input from "src/v2/components/form-inputs/Input";
import Form from "src/v2/components/form/Form";
import SubmitButton from "src/v2/components/SubmitButton";
import { createSale, editSale } from "src/v2/services/gameSalesService";
import { z } from "zod";
import { useTeamID } from "../../../RoleContext";

const saleInfoSchema = z.object({
  name: z.string(),
  expiry: z.string().transform((s) => new Date(s)),
  start_date: z.string().transform((s) => new Date(s)),
});

export const SaleInfo = ({
  editMode,
  moveTab,
  saleId,
  setSaleId,
  isPublicSale = false,
  defaultValues = {},
}) => {
  const teamId = useTeamID();

  const getNextDay = (howManyDays = 1) => {
    const today = new Date();
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + howManyDays);
    return nextDay;
  };

  const form = useForm({
    resolver: zodResolver(saleInfoSchema),
    defaultValues: {
      name: defaultValues.name,
      start_date: dateFormatForDateInput(
        defaultValues.start_date ?? getNextDay(1)
      ),
      expiry: dateFormatForDateInput(defaultValues.expiry ?? getNextDay(2)),
    },
  });

  const onSave = async () => {
    try {
      const isValid = await form.trigger();
      if (!isValid) throw new Error("Invalid Inputs");
      const data = form.getValues();

      if (editMode) {
        await editSale({
          saleId,
          isPublicSale,
          name: data.name,
          startDate: data.start_date,
          expirationDate: data.expiry,
        });
        toast.success("Sale Updated");
      } else {
        const res = await createSale({
          teamId,
          isPublicSale,
          name: data.name,
          startDate: data.start_date,
          expirationDate: data.expiry,
        });
        setSaleId(res.sale_id);
        moveTab("products");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="grid gap-5">
      <p className="text-neutral-300">
        You can create a discounted promotion for individual products. Simply
        select the desired downloads (such as the full version of a game), apply
        the percentage discount, and add them to the sale.
      </p>
      <p className="text-neutral-300">
        To create a bundle or group of products sold together as a package,
        please use the "Bundles" tab to set it up.
      </p>

      <Form form={form}>
        <div className="grid md:grid-cols-2 gap-5">
          <Form.Item name="name" label="Sale Name" className="col-span-full">
            <Input />
          </Form.Item>

          <Form.Item name="start_date" label="Start Date">
            <Input type="date" min={dateFormatForDateInput(getNextDay(1))} />
          </Form.Item>
          <Form.Item name="expiry" label="Expiration Date">
            <Input type="date" min={dateFormatForDateInput(getNextDay(2))} />
          </Form.Item>
        </div>
      </Form>

      <SubmitButton onClick={onSave} className="place-self-end">
        Create Sale
      </SubmitButton>
    </div>
  );
};
