import { createContext, useContext, useRef, useState } from "react";
import { Tabs } from "src/components/Tabs";
import useThrottleRequests from "src/hooks/useThrottleRequests";
import { InnerHeader } from "../../Headers";
import CollectionPreference from "./CollectionPreference";
import GetInfo from "./GetInfo";
import { ImagePermissions } from "./Image";
import UploadImages from "./UploadImages";
import "./style.css";

export const InfoContext = createContext({});

export const pages = [
  {
    displayText: "Info",
    component: GetInfo,
    id: "info",
  },
  {
    displayText: "Preference",
    component: CollectionPreference,
    id: "preference",
  },
  {
    displayText: "Images",
    component: UploadImages,
    id: "images",
  },
];

const UploadCollection = ({ header, subHeader, toggler }) => {
  const [id, setId] = useState();
  const [images, setImages] = useState([]);
  const [premium, setPremium] = useState([]);
  const [metaInfo, setMetaInfo] = useState({});
  const [coverImageId, setCoverImageId] = useState(null);

  const [collectionInfo, setCollectionInfo] = useState({});

  const previouslyUploaded = useRef(new Set());
  const newlyUploaded = useRef(new Map()); // hash -> ID

  const { throttle, updateThrottle } = useThrottleRequests();

  // CHANGES ON THIS FILE SHOULD ALSO BE HAPPENED in src/components/CMS/SpicyArt/UploadCollection/useCollectionInfo.js

  const changeCoverImageId = (image_id) => {
    setCoverImageId(image_id);
  };

  return (
    <InfoContext.Provider
      value={{
        images,
        premium,
        metaInfo,
        coverImageId,
        setImages,
        setPremium,
        setMetaInfo,
        changeCoverImageId,
        previouslyUploaded,
        throttle,
        updateThrottle,
        collectionInfo,
        setCollectionInfo,
        newlyUploaded,
      }}
    >
      <div className="body UploadCollection" data-testid="UploadCollection">
        <InnerHeader
          header={header}
          subHeader={subHeader}
          toggler={toggler}
          element={"back-button"}
        />
        <div className="content">
          <Tabs
            pages={pages.map((p) => {
              if (id) return p;
              if (p.id === "info") return p;

              return { ...p, disabled: true };
            })}
            firstPageId="info"
            propsForEachPageComponent={{
              id,
              setId,
              editMode: !!id,
              permissions: [
                ImagePermissions.CHANGE_COVER,
                ImagePermissions.EDIT_METADATA,
                ImagePermissions.MAKE_PREMIUM,
                ImagePermissions.APPLY_EDIT_TO_ALL_IMAGES,
              ],
            }}
          />
        </div>
      </div>
    </InfoContext.Provider>
  );
};

export const useCollectionInfo = () => {
  return useContext(InfoContext);
};

export default UploadCollection;
