import hashBlob from "blob-to-hash";
import { toast } from "react-toastify";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { DropDownZone } from "src/components/DropDownZone";
import { authRequest } from "src/utils/Axios";
import { SubmitButton } from "../../Common";
import UploadedImage from "./Image";
import { useCollectionInfo } from "./UploadCollection";
import { getCredentails } from "src/components/Validation";
import { ScrollArea } from "src/components/UtilityComponents/ScrollArea";

const BATCH_UPLOAD_THRESHOLD = 3;

const UploadImages = ({ id, customInstructions, onSave, permissions = [] }) => {
  const {
    images,
    premium,
    metaInfo,
    setImages,
    setPremium,
    setMetaInfo,
    previouslyUploaded,
    throttle,
    updateThrottle,
    coverImageId,
    newlyUploaded,
  } = useCollectionInfo();

  const { username } = getCredentails();
  const togglePremium = (hash) => {
    setPremium((prev) => {
      if (prev.includes(hash))
        return prev.filter((currentHash) => currentHash !== hash);
      else return [...prev, hash];
    });
  };

  const formatFiles = async (files) => {
    if (files.find((file) => !file.type.includes("image")))
      toast.error("Non Image files Skipped");

    const images = await Promise.all(
      files
        .filter((file) => file.type.includes("image"))
        .map(async (file) => {
          return { file, hash: await hashBlob("sha256", file) };
        })
    );

    return images;
  };

  const onUpload = async (files) => {
    const uploaded = await formatFiles(files);
    const newImages = uploaded.filter(
      (img) => !images.find((oldImg) => oldImg.hash === img.hash)
    );

    updateThrottle.queueRequests(
      newImages.map((img) => {
        return () => uploadImage(img);
      }),
      BATCH_UPLOAD_THRESHOLD
    );
    if (newImages.length !== uploaded.length)
      toast.warn("Some images have already been uploaded");

    setImages((prev) => {
      return [...prev, ...newImages];
    });
  };

  const updateMetaInfo = (image_id, info) => {
    setMetaInfo((prev) => ({
      ...prev,
      [image_id]: { ...prev[image_id], ...info },
    }));
  };

  const reuseForAll = (info) => {
    const keys = images.map((img) => img.hash);
    setMetaInfo(Object.fromEntries(keys.map((key) => [key, info])));
  };

  const uploadImage = async (image) => {
    try {
      const formdata = new FormData();

      formdata.append("image", image.file);
      formdata.append("hash", image.hash);
      formdata.append("gallery_id", id);
      formdata.append("uploader", username);

      const res = await authRequest({
        url: "/products/api/spicyart/gallery/image/upload",
        method: "POST",
        data: formdata,
      });
      newlyUploaded.current.set(image.hash, res.image_id);
      updateMetaInfo(res.image_id, { id: res.image_id, hash: image.hash });
      updateThrottle.requestSucceededWithData(image.hash);
    } catch (error) {
      updateThrottle.requestFailedWithError(image.hash);
      setImages((prev) =>
        prev.filter((prevImage) => prevImage.hash !== image.hash)
      );
      toast.error(
        <div>
          <div>{error.message}</div>
          <div>Please Reupload the following image:</div>

          <img
            src={URL.createObjectURL(image.file)}
            alt=""
            className="ErrorImageToast"
            width={100}
            height={100}
          />
        </div>
      );
    }
  };

  const saveMetadata = async () => {
    try {
      await authRequest({
        url: "/products/api/spicyart/gallery/image",
        method: "POST",
        data: {
          gallery_id: id,
          images: images.map((img) => {
            const metadata = getMetadataFromId(getImageId(img));
            const id = img.online
              ? img.id
              : newlyUploaded.current.get(img.hash);
            return {
              id,
              hash: img.hash,
              is_cover: coverImageId === img.id,
              description: metadata?.description ?? "",
              tags: metadata?.tags ?? [],
              is_paid: premium.includes(img.id),
              aspect_ratio: metadata?.aspect_ratio,
              game_id: metadata?.game_id,
              game_name: metadata?.game_name,
            };
          }),
        },
      });
      toast.success("Succesfully saved");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const deleteImage = async (image_id) => {
    try {
      await toast.promise(
        authRequest({
          method: "DELETE",
          url: "/products/api/spicyart/gallery/image",
          data: {
            gallery_id: id,
            image_id,
          },
        }),
        {
          error: "Couldn't delete Image",
          pending: "Deleting image",
          success: "Image Deleted",
        }
      );
    } finally {
    }
  };

  const removeImage = async (image_id, imageHash) => {
    await deleteImage(image_id);

    // newly upload images doesn't have id in images. so have to continue with hash
    setImages((prev) => prev.filter((img) => img.hash !== imageHash));
    setMetaInfo((prev) => {
      const old = { ...prev };
      delete old[image_id];
      return old;
    });
  };

  const getUploadStatus = (hash) => {
    if (previouslyUploaded.current.has(hash)) return "DONE";

    if (throttle.values.includes(hash)) {
      previouslyUploaded.current.add(hash);
      return "DONE";
    }
    if (throttle.errors.includes(hash)) {
      return "ERROR";
    }

    return "LOADING";
  };

  const getMetadataFromId = (id) => {
    // used for uploaded images

    const found = Object.entries(metaInfo).find(
      ([_, metadata]) => metadata.id === id
    );

    if (found) return found[1]; // contains metadata
    return {};
  };

  function getImageId(img) {
    return img.online ? img.id : newlyUploaded.current.get(img.hash);
  }

  return (
    <div className="UploadImages">
      <section>
        <legend>Images</legend>
        <p className="instructions">
          {customInstructions ?? (
            <>
              <p>
                We Strongly recommend to add metadata to images. It helps for
                gaining more appearence in search for your images. Add them by
                clicking an edit button on the image.
              </p>
              <br />
              <p>
                If you want to mark a certain image as the cover you do so by
                clicking the "make cover image" Clicking the $ button will mark
                the picture as premium it will be censored and users will only
                get it when the buy a collection of images (coming soon)
              </p>
              <p>After uploading the images click Save changes!</p>
            </>
          )}
        </p>
        <DropDownZone
          disabled={throttle.loading}
          accept={"image/*"}
          onDrop={onUpload}
          onError={(error) => toast.error(error)}
          multiple
        />
        {throttle.totalRequests > 0 && (
          <div className="stats">
            <p>
              Uploading {throttle.percentageLoaded}% of {throttle.totalRequests}{" "}
              Images
            </p>
            <div className="">
              <div
                className="slider"
                style={{ "--fill": throttle.percentageLoaded }}
              ></div>
            </div>
          </div>
        )}

        <ScrollArea maxHeight={"80vh"}>
          <TransitionGroup component="div" className="images">
            {images.map((img) => {
              const image_id = getImageId(img);

              const metadata = getMetadataFromId(image_id);

              return (
                <CSSTransition key={img.id} timeout={300} classNames="popup">
                  <UploadedImage
                    onSave={(info) => {
                      if (metadata.id)
                        return updateMetaInfo(metadata.id, {
                          ...metadata,
                          ...info,
                        });
                    }}
                    reuseInfo={reuseForAll}
                    metadata={metadata}
                    image={img}
                    isPremium={premium.includes(img.id)}
                    onRemove={removeImage}
                    togglePremium={togglePremium}
                    getUploadStatus={() => getUploadStatus(img.hash)}
                    permissions={permissions}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        </ScrollArea>
      </section>

      <div className="save">
        <SubmitButton
          label={"Save Gallery Images"}
          request={async () => {
            await saveMetadata();
            onSave?.();
          }}
          disabled={throttle.loading}
        />
      </div>
    </div>
  );
};

export default UploadImages;
