import dateFormat from "dateformat";
import useSWRInfinite from "swr/infinite";
import { LoadMore, sort } from "../../Common";
import { fetcher } from "../Cms";
import { REVALIDATE_ON_FOCUS } from "../Common";
import { MainHeader } from "../Headers";
import { useRoleInfo } from "../RoleContext";
import Table, { getColumn } from "../Table/Table";
import { invitationsOptions } from "./Options";

const columns = [
  getColumn("TEAM NAME", { sortKey: "member", accessKey: "member" }),
  getColumn("TEAM LEADER", {
    sortKey: "team_leader",
    accessKey: "team_leader",
  }),
  getColumn("INVITED DATE", {
    sortKey: "date_invited",
    accessFn: (invitation) => dateFormat(invitation.date_invited, "longDate"),
  }),
  getColumn("NUM OF MEMBERS", {
    sortKey: "no_of_members",
    accessKey: "no_of_members",
  }),
];

const TeamInvitations = ({ toggler, subHeader }) => {
  const { role } = useRoleInfo();

  const options = invitationsOptions(role);

  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) => {
      return `${window.domainName}/api/content-creators/invitations/list?page=${
        index + 1
      }`;
    },
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );

  const requests = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];

  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  return (
    <div className="body">
      <MainHeader header={subHeader} buttons={false} toggler={toggler} />
      <div className="content">
        <Table
          columns={columns}
          data={requests}
          getOptions={() => options}
          getPopupData={(invitation) => {
            const common = {
              creator_id: invitation.creator,
              team_id: invitation.team_id,
              team: invitation.team_name,
              date_invited: dateFormat(invitation.date_invited, "longDate"),
            };
            return {
              view_team: {
                team: invitation.team_name,
              },
              accept: {
                ...common,
                goingToAccept: true,
              },
              deny: {
                ...common,
                goingToAccept: false,
              },
            };
          }}
          refreshData={() => mutate(requests, true)}
          onSort={(key, order) => mutate(sort(key, requests, order), false)}
        />
        {errors && <div className="error">{errors.error}</div>}
        {error && <div className="error">{error.message}</div>}
        <LoadMore onClick={() => setSize((prev) => prev + 1)} />
      </div>
    </div>
  );
};

export default TeamInvitations;
