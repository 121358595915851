import { z } from "zod";

export const GameEditDataResponse = z.object({
  id: z.number(),

  creator_team: z.object({
    id: z.number(),
    name: z.string(),
  }),

  status: z.string(),
  engine: z.string(),
  genre: z.string(),
  title: z.string(),
  version: z.string(),
  short_description: z.string(),
  description: z.string(),

  published: z.boolean(),
  media_gallery: z.number(),

  operating_systems: z.array(z.string()),
  languages: z.array(z.string()),
  tags: z.array(z.string()),

  // optional stuff
  square_banner: z.string().nullable(),
  landscape_banner: z.string().nullable(),
  trailer: z.any().nullable(),
  thumbnail: z.string().nullable(),
});
