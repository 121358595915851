import round2Decimal from "./to2Decimal";

export function getDiscountRate(originalPrice, discountPrice) {
  const discountAmount = originalPrice - discountPrice;
  const discountRate = (discountAmount / originalPrice) * 100;

  if (isNaN(discountRate)) return 0;

  return Math.round(discountRate);
}
