import React, { useState } from "react";
import { SubmitButton } from "../../CMS/Common";
import { authorizedRequest } from "../../Validation";
import { Input } from "../General";

export const CreateRefund = ({ transaction, close }) => {
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");
  const [error, setError] = useState(null);
  const requestRefund = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url: `${window.domainName}/store/api/refund-requests/create`,
        method: "POST",
        data: {
          transaction_id: transaction.transaction_id,
          reason,
        },
        headers: { "Content-Type": "application/json" },
      });

      setLoading(false);
      if (res.success) return setError("Successfully Requested refund");
      if (res.error) return setError(res.error);
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };
  return (
    <div>
      <h2>Request refund for #{transaction.transaction_id}</h2>
      <Input
        label="Reason for refund"
        callback={(txt) => setReason(txt)}
        value={reason}
      />
      <br />
      {error && <div className="error">{error}</div>}
      <SubmitButton request={requestRefund} label="Request" />
    </div>
  );
};
