import React, { Children, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import findArticleElement from "src/components/ArticleDetails/Elements";
import { useToggle } from "../../../hooks/useToggle";
import Popup from "../../common/Popup";
import "../css/EditorCommon.css";
import allOptions from "./EditorOptions";
import DragIcon from "src/Icons/CiDragVertical";
import { SubmitButton } from "../Common";
import insertAtIndex from "src/utils/insertAtIndex";

const Layout = ({
  children,
  show,
  setShow,
  defaultClose = true,
  onClose,
  className,
}) => {
  const ref = useRef(null);
  const close = () => {
    onClose?.();
    setShow?.(false);
    ref.current?.parentNode?.scrollIntoView();
  };
  useEffect(() => {
    if (show) {
      ref.current?.scrollIntoView();
    }
  }, [ref.current]);

  return (
    <CSSTransition in={show} unmountOnExit classNames="popup" timeout={300}>
      <div id="editor-popup" className={className} ref={ref}>
        <div className="children">
          {defaultClose && <div className="fa fa-close" onClick={close}></div>}
          {Children.map(children, (child) =>
            React.cloneElement(child, { close })
          )}
        </div>
        <div
          className="wrapper"
          onClick={defaultClose ? close : undefined}
        ></div>
      </div>
    </CSSTransition>
  );
};

const ObjectItem = React.memo(
  ({
    index,
    label,
    setObjects,
    object,
    onClose,
    customData,
    images,
    refreshImages,
  }) => {
    const Element = findArticleElement(object.type.toLowerCase());

    const [show, toggleShow] = useToggle();
    const options = Object.values(allOptions);

    const ClickedOption = options.find((option) => option.id === object.id);
    const PopupOption = ClickedOption?.popup;

    const objectDelete = () => {
      setObjects((prev) => prev.filter((_, i) => i !== index));
      onClose?.(object, index);
    };

    const editObjects = (id, name, value) => {
      setObjects((prev) => {
        return prev.map((obj) => {
          if (obj.key === object.key) {
            return {
              key: object.key,
              id,
              type: name,
              content: value,
            };
          }
          return obj;
        });
      });
    };
    return (
      <>
        <div className="object-item object-editor-element">
          <span data-tooltip="Drag to move this item">
            <DragIcon className="drag" />
          </span>
          <div className="item">
            <Element
              toggleEditMode={toggleShow}
              content={object.content}
              data={{ images }}
            />
          </div>
          <div className="buttons">
            <SubmitButton
              type="secondary"
              rounded
              icon="fa-edit"
              request={toggleShow}
            />
            <SubmitButton
              type="secondary"
              rounded
              icon="fa-close"
              request={objectDelete}
            />
          </div>
        </div>
        {/* <div className="object-item selected">
          <div className="index">{index}</div>
          <div className="name " onClick={toggleShow}>
          {label}
          </div>
          <div className="fa fa-close" onClick={objectDelete}></div>
        </div> */}

        <Popup
          open={show}
          onClose={toggleShow}
          giveCloseCallbackToChildren
          showCloseButton
          maxWidth={1080}
          defaultClose={false}
        >
          {ClickedOption && (
            <PopupOption
              {...ClickedOption.props}
              id={object.id}
              images={images}
              refreshImages={refreshImages}
              // only available in editor mode
              edit={true}
              object={object}
              addItem={editObjects}
              customData={customData}
            />
          )}
        </Popup>
      </>
    );
  }
);

const TypeSelector = ({
  options,
  setObjects,
  customData,
  newSetPosition,
  onClose,
  refreshImages,
  images,
}) => {
  const [clickedID, setCickedID] = useState("");
  const [show, toggleShow] = useToggle(false);

  const ClickedOption = options.find((option) => option.id === clickedID);
  const PopupOption = ClickedOption?.popup;

  if (ClickedOption && ClickedOption.popup === undefined) {
    throw Error("key `popup` didn't defined for ID - " + ClickedOption.id);
  }

  const setObjectsHandle = (id, name, value) => {
    const newObject = {
      key: new Date().getTime(),
      id,
      type: name,
      content: value,
    };

    if (newSetPosition) {
      return setObjects((prev) =>
        insertAtIndex(prev, newSetPosition, newObject)
      );
    }

    return setObjects((prev) => [...prev, newObject]);
  };

  return (
    <>
      <div id="finder" className="content-type">
        <h2 className="name">Add Content-Type</h2>
        <div className={`container show`}>
          <div className="content">
            {options.map((option) => (
              <div
                className="category"
                key={option.id}
                onClick={() => {
                  setCickedID(option.id);
                  toggleShow();
                }}
              >
                <span
                  className={`fa ${option.icon ? option.icon : "fa-plus"}`}
                ></span>
                <div className="name">{option.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Popup
        open={show}
        onClose={() => {
          toggleShow();
          onClose?.();
        }}
        giveCloseCallbackToChildren
        maxWidth={1080}
        showCloseButton
        defaultClose={false}
      >
        {ClickedOption && (
          <PopupOption
            {...ClickedOption.props}
            images={images}
            refreshImages={refreshImages}
            customData={customData}
            addItem={setObjectsHandle}
            id={clickedID}
            array={ClickedOption.array}
          />
        )}
      </Popup>
    </>
  );
};
export { Layout, ObjectItem, Layout as Popup, TypeSelector };
