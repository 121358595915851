import { useParams } from "react-router-dom";
import { request } from "src/utils/Axios";
import ArticleSearchResults from "./ArticleSearchResults";
import "./css/ArticleSearch.css";
import { withArticleMetadataType } from "./getArticles";
import { Helmet } from "react-helmet";

const blogPostsURL = "/news/api/news/blog-posts/articles/search";
const reviewsURL = "/news/api/news/staff-reviews/articles/search";
const gameNewsURL = "/news/api/news/game-news/articles/search";

const ArticleSearch = () => {
  const { query = "" } = useParams();

  const searchArticles = async ({
    query,
    page,
    cancelToken,
    sortKey,
    sortOrder,
  }) => {
    const res = await request({
      url: "/news/api/news/articles/search",
      params: { search_term: query, page, sort: sortKey, order: sortOrder },
      cancelToken,
    });
    return res;
  };
  const searchOnlySelectedArticles = async ({
    query,
    page,
    cancelToken,
    sortKey,
    sortOrder,
    selectedArticleType,
  }) => {
    let url;
    switch (selectedArticleType) {
      case "Blog Posts":
        url = blogPostsURL;
        break;
      case "Staff Reviews":
        url = reviewsURL;
        break;
      case "Game News":
        url = gameNewsURL;
        break;

      default:
        break;
    }

    if (!url) return;

    const res = await request({
      url,
      params: {
        search_term: query,
        page,
        num: 30,
        sort: sortKey,
        order: sortOrder,
      },
      cancelToken,
    });
    return withArticleMetadataType(res, selectedArticleType);
  };

  return (
    <>
      <Helmet>
        <title>Porn game news, Adult game reviews - Spicygaming</title>
        <meta
          name="description"
          content="News from the adult gaming world. Porn game reviews, Adult game news, News from adult gaming developers. Game walkthroughs."
        />
      </Helmet>
      <ArticleSearchResults
        title={`${query} Articles`}
        getArticles={searchArticles}
        getOnlySelectedTypeArticles={searchOnlySelectedArticles}
      />
    </>
  );
};

export default ArticleSearch;
