import React from "react";

import "./style.css";

const SomethingWentWrong = () => {
  return (
    <section className="SomethingWentWrong">
      <h2>Aaaah! Something went wrong</h2>

      <p>Brace yourself till we get the error fixed.</p>
      <p>You may also refresh the page or try again later</p>
    </section>
  );
};

export default SomethingWentWrong;
