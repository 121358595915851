import { usePostHog } from "posthog-js/react";
import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authRequest } from "../../utils/Axios";
import { withoutLastPage } from "../../utils/withoutLastPage";
import { getCredentails } from "../Validation";

export const RoleContext = createContext({
  loggedIn: false,
});

export const RoleProvider = (props) => {
  const posthog = usePostHog();
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(true);

  const { username } = getCredentails();

  useEffect(() => {
    auth();
  }, []);

  const getTeams = async () => {
    const res = await authRequest({
      url: "/api/content-creator-teams/list",
    });

    setResponse((prev) => ({
      ...prev,
      teams: withoutLastPage(res)?.map((team) => ({
        name: team.name,
        id: team.id,
        approved: team.approved,
      })),
    }));
  };

  const auth = async () => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: "/api/members/authenticate",
        method: "POST",
      });

      if (res.detail === "success" && res.role) {
        const role = res.role.toLowerCase();

        const userProperties = {
          Role: res.role,
          "2FA_Enabled": res["2fa_enabled"],
        };
        posthog?.identify(username, userProperties);
        window.gtag("set", "user_id", username);
        window.gtag("set", "user_properties", userProperties);

        setResponse({
          role,
          loggedIn: true,
          detail: res.detail,
          affiliateId: res.affiliate_id,
          teamCount: res.no_of_teams,
          defaultTeam: res.default_team,
          defaultTeamName: res.default_team_name,
        });
        if (role === Roles.CONTENT_CREATOR) await getTeams();
        return;
      }
    } catch {
      setResponse({ loggedIn: false });
    } finally {
      setLoading(false);
    }
  };

  return (
    <RoleContext.Provider value={{ ...response, auth, loading }}>
      {props.children}
    </RoleContext.Provider>
  );
};

export const useRoleInfo = () => {
  return useContext(RoleContext);
};

/** for Only use in CMS */
export const useTeamID = () => {
  const { id } = useParams();
  const { defaultTeam } = useRoleInfo();

  const ID = parseInt(id);
  if (isNaN(ID)) return defaultTeam;

  return parseInt(ID);
};

export const Roles = {
  STAFF: "staff",
  ADMIN: "admin",
  CONTENT_CREATOR: "content creator",
  TRUSTED_CONTENT_CREATOR: "trusted content creator",
  MEMBER: "member",
  WRITER: "writer",
  LEADER: "leader",
  CO_LEADER: "co-leader",
};
