import { useState } from "react";

import "../css/GameCreation.css";
import { InnerHeader } from "../Headers";
import NewsArticleInfo from "./ArticleComponents/NewsArticleInfo";
import UploadArticleTabs from "./UploadArticleTabs";

/**
 * @param {Object} props
 * @param {Boolean} props.editMode - Indicates edit mode
 * @param {Object} props.defaults
 * @param {Object} props.defaults.formData - The default formdata _using in editor mode_
 * @param {Object[]} props.defaults.objects - The default objects _using in editor mode_
 * @param {Object} props.defaults.images - The default images _using in editor mode_
 * @returns
 */

const UploadGameNews = ({ header, subHeader, defaults, editMode = false }) => {
  const [postID, setPostID] = useState(defaults?.id);
  const [galleryID, setGalleryID] = useState(defaults?.formData?.media_gallery);

  return (
    <div className="body">
      <InnerHeader
        header={header}
        subHeader={subHeader}
        element={"back-button"}
      />
      <div className="content">
        <main id="article-creation" className="cms-details">
          <UploadArticleTabs
            articleID={postID}
            Info={NewsArticleInfo}
            propsForEachPageComponent={{
              galleryID,
              setGalleryID,
              setPostID,
              postID,
              defaults,
              editMode,
              type: "Game News",
            }}
          />
        </main>
      </div>
    </div>
  );
};

export default UploadGameNews;
