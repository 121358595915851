import { useEffect, useState } from "react";
import { ImageList, ImageSetter } from "./AdManagementComponents";
import React from "react";
import { SubmitButton } from "../Common";
import { authorizedRequest } from "../../Validation";
import { LoaderInside, urlToObject } from "../../Common";
import { generateOriginalURL } from "../../resizeImages";

export const AD_IDS = {
  front: {
    gallery: "front_300_gallery",
    games: "front_728_games",
    news: "front_1920_news",
    footer: "footer_marquee",
    bottom: "home_bottom",
    video: "home_video",
  },
  articles_search: {
    top: "articles_search_1920_top",
    bottom: "articles_search_1920_bottom",
    middle: "articles_search_1920_middle",
  },
  article: {
    side: "article_300_side",
    middle: "article_middle",
  },
  game: {
    under_description: "under_description",
    side: "game_side",
  },
};

// [
//   {
//     url: "https://discord.com/channels/804427165688004648/841304396963774504",
//     weight: 20,
//     file: {},
//     key: 0.3923278450873402,
//   },
// ];

const AdSection = ({ label, needJS, id }) => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   console.log(images);
  // }, [images]);
  useEffect(() => {
    getInfo();
  }, []);

  const getInfo = async () => {
    try {
      const res = await authorizedRequest({
        url: `${window.domainName}/denars/api/native-denar/edit`,
        method: "GET",
        params: {
          ad_spot: id,
        },
      });
      const data = await Promise.all(
        res.images.map(async (item) => {
          const file = await urlToObject(generateOriginalURL(item.filepath));
          return { file, ...item, key: Math.random() };
        })
      );

      setImages(data);
      setLoading(false);
    } catch (error) {}
  };

  const saveChanges = async () => {
    setError(null);
    const fd = new FormData();

    const data = images.map((image) => ({ ...image, file: image.file.name }));
    // console.log(data);

    images.forEach((image) => fd.append(`files[]`, image.file));
    fd.append("images", JSON.stringify(data));
    fd.append("ad_spot", id);

    try {
      const res = await authorizedRequest({
        url: `${window.domainName}/denars/api/native-denar/edit`,
        method: "POST",
        data: fd,
      });
      if (res.success) setError("Successfully saved.");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <section className="ad-section">
      <legend>{label}</legend>
      {loading ? (
        <LoaderInside />
      ) : (
        <>
          <div className="content">
            <div className="inputs">
              <div className="item">
                <input type="radio" defaultChecked={true} name={id} id={id} />
                <ImageSetter id={id} setImages={setImages} />
              </div>
              {needJS && (
                <div className="item">
                  <input type="radio" name={id} id={id} />
                  <div className="input textarea">
                    <label htmlFor={id} className="name">
                      Ad Serving Js
                    </label>
                    <textarea
                      // onChange={(e) => inputGetter(e, "long_description", "", true)}
                      type="text"
                      spellCheck="true"
                      id={id}
                      placeholder="http://example.com (This feature will be available in future)"
                      rows={8}
                      disabled
                    />
                  </div>
                </div>
              )}
            </div>
            <ImageList imagesList={images} setImagesList={setImages} />
          </div>
          {error && <div className="error">{error}</div>}
          <br />
          <SubmitButton label="Save Changes" request={saveChanges} />{" "}
        </>
      )}
    </section>
  );
};

export default AdSection;
