import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const AvoidRouteChange = ({
  blockNavigate = true,
  confirmationMessage = "Are you sure you want to leave?",
}) => {
  const history = useHistory();

  const beforeUnloadListener = (event) => {
    if (blockNavigate) {
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    }
  };
  useEffect(() => {
    const unblock = history.block(() => {
      if (blockNavigate) {
        return confirmationMessage;
      }
    });

    window.addEventListener("beforeunload", beforeUnloadListener);
    return () => {
      unblock();
      window.removeEventListener("beforeunload", beforeUnloadListener);
    };
  }, [history, blockNavigate]);

  return <></>;
};

export default AvoidRouteChange;
