import { useMemo, useState } from "react";
import { getClass } from "src/utils/getClass";
import ByURL from "./ByURL";
import "./style.css";
import { Chip } from "../Chip";

// const page = {
//   component: Info,
//   displayText: "Info",
//   id: "info",
//   disabled: true,
//   url:"string",
//   isNew:false
// }

const Tabs = ({
  firstPageId,
  withURLChange,
  propsForEachPageComponent = {},
  pages = [],
}) => {
  const [selectedPageId, setSelectedPageId] = useState(firstPageId);
  const SelectedPage = useMemo(() => {
    const selected = pages.find((page) => page.id === selectedPageId);
    return selected?.component ?? (() => null);
  }, [pages, selectedPageId]);

  const moveTab = (id) => {
    return setSelectedPageId(id);
  };

  return (
    <div className="Tabs" data-testid="Tabs">
      {withURLChange ? (
        <ByURL
          propsForEachPageComponent={propsForEachPageComponent}
          firstPageId={firstPageId}
          pages={pages}
        />
      ) : (
        <>
          <nav>
            <ul>
              {pages.map((page, key) => (
                <li
                  key={key}
                  onClick={() => {
                    if (page.disabled) return;
                    return moveTab(page.id);
                  }}
                  className={getClass(
                    page.id === selectedPageId && "active",
                    page.disabled && "disabled"
                  )}
                  data-disable={page.disabled}
                >
                  {page.displayText} {page.isNew && <Chip>New</Chip>}
                </li>
              ))}
            </ul>
          </nav>

          <SelectedPage moveTab={moveTab} {...propsForEachPageComponent} />
        </>
      )}
    </div>
  );
};

export default Tabs;
