import { Helmet } from "react-helmet";
import { Redirect, Switch } from "react-router-dom";
import { useToggle } from "../../hooks/useToggle";
import { RoleProvider, useRoleInfo } from "../CMS/RoleContext";
import Error404 from "../Errors";
// import FavDevelopers from "../Member/FavDevelopers";
// import FavGamePlayed from "../Member/FavGamePlayed";
// import FavGamePlaying from "../Member/FavGamePlaying";
import SentryRoute from "src/customRoute";
import { LoaderInside } from "../Common";
import FavGameWishlisted from "../Member/FavGameWishlisted";
import PuchasedGames from "../Member/PurchasedGames";
import SideMenu from "../Member/SideMenu";
import "./style.css";
import MyLibrary from "./WatchLater";

const Library = ({ match }) => {
  const { loggedIn, loading } = useRoleInfo();
  const [showMenu, toggleShowMenu] = useToggle(false);

  if (loading) return <LoaderInside />;

  if (!loggedIn) return <Redirect to="/login/library" />;

  return (
    <RoleProvider>
      <main className="Library member" data-testid="Library">
        <Helmet>
          <title>My Library - Spicygaming</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="inner">
          <SideMenu show={showMenu} toggleShow={toggleShowMenu} links={links} />
          <div className="body">
            <header onClick={toggleShowMenu}>
              {
                links.find((link) =>
                  window.location.pathname.includes(link.link)
                )?.header
              }
            </header>
            <div className="content">
              <Switch>
                <Redirect exact path={match.url} to={links[0]?.link} />

                {links.map((link, i) => (
                  <SentryRoute
                    key={i}
                    path={link.link}
                    component={link.component}
                    {...link.props}
                  />
                ))}

                <SentryRoute path={match.url + "*"}>
                  <Error404 />
                </SentryRoute>
              </Switch>
            </div>
          </div>
        </div>
      </main>
    </RoleProvider>
  );
};

const links = [
  {
    header: "MY LIBRARY",
    link: "/library/purchased-games",
    icon: "fa-gamepad",
    component: PuchasedGames,
    props: { exact: true },
  },
  {
    header: "MY PROFILE",
    link: "/member/general",
    icon: "fa-user",
    component: () => null,
  },
  {
    header: "WHISHLISTED GAMES",
    link: "/library/games/whishlisted",
    icon: "fa-gamepad",
    component: FavGameWishlisted,
  },
  {
    header: "WATCH LATER VIDEOS",
    link: "/library/watch-later",
    icon: "fa-clock-o",
    component: MyLibrary,
  },
  // {
  //   header: { en: "PLAYED GAMES",
  //   link: "/library/games/played",
  //   icon: "fa-gamepad",
  //   component: FavGamePlayed,
  // },
];

export default Library;
