import { useMemo } from "react";
import { useRoleInfo } from "../../RoleContext";

import "./css/VerifyTeamNotification.css";
import { SubmitButton } from "../../Common";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";

const VerifyTeamNotification = () => {
  const info = useRoleInfo();

  const history = useHistory();

  const defaultTeam = useMemo(
    () => info.teams?.find((t) => t.id === info.defaultTeam),
    [info.defaultTeam, info.teams]
  );

  if (!defaultTeam) return null;
  if (defaultTeam.approved) return null;

  const path = generatePath("/cms/personal/teams/edit/:id/payout-info", {
    id: defaultTeam.id,
  });
  const onClick = () => {
    history.push(path);
  };

  // will be hidden in the same page this redirects to
  if (path === window.location.pathname) return null;

  return (
    <div className="VerifyTeamNotification">
      <span>
        Please complete your payout info & verification to get full access to
        the platform.
      </span>
      <SubmitButton
        label={"Continue"}
        icon="fa-chevron-right"
        request={onClick}
      />
    </div>
  );
};

export default VerifyTeamNotification;
