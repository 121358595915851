import React from "react";
import { Link } from "react-router-dom";
import relativeDate from "relative-date";
import { getClass } from "src/utils/getClass";
import { SubmitButton } from "../CMS/Common";
import { generateURL } from "../resizeImages";
import "./css/News.css";
import { MetaInfo } from "./MetaData";

const NewsMini = ({
  likes,
  type = "blog",
  title,
  short_description,
  landscape_cover,
  views,
  date_posted,
  author = "",
  tag = true,
  square = false,
  square_cover,
  url = "",
  id,
  metadata,
  score,
  lazyLoadImage = true,
  isHorizontal = false,
  showLikes = true,
  button = {
    label: "",
    onClick: (id) => null,
    disabled: false,
    disabledText: "",
  },
}) => {
  const onClickButton = () =>
    button.onClick && button.onClick(metadata ? metadata : { id });
  return (
    <div
      role="group"
      aria-label={title}
      className="news-mini"
      data-horizontal={isHorizontal}
    >
      <Link to={url} title={title}>
        <div
          className={getClass("image", "thumbnail", square && "square-aspect")}
        >
          <img
            loading={lazyLoadImage ? "lazy" : "eager"}
            src={
              square
                ? generateURL(350, 350, square_cover)
                : generateURL(400, 225, landscape_cover)
            }
            alt={title}
            height={350}
            width={(square ? 200 : 400, 225)}
          />
        </div>
      </Link>
      <div className="text">
        <Link to={url}>
          <div className="title">{title}</div>
        </Link>
        <div className="info">
          <div className="author">
            <div className="image square-aspect">
              <img
                loading="lazy"
                width={25}
                height={25}
                src="/images/user.svg"
                alt={author}
              />
            </div>
            <div className="name" title={author}>
              {author.slice(0, 15)}
            </div>
          </div>
          <div className="date">
            <span aria-hidden className="fa fa-clock-o"></span>
            {relativeDate(new Date(date_posted))}
          </div>
        </div>
        <div className="description">{short_description}</div>

        {showLikes && (
          <MetaInfo
            type={type}
            likes={likes}
            views={views}
            id={id}
            score={score}
          />
        )}
        <br />
        {!!button.label.length && (
          <SubmitButton
            label={!button.disabled ? button.label : button.disabledText}
            request={onClickButton}
            disabled={button.disabled}
          />
        )}
      </div>
    </div>
  );
};

export default NewsMini;
