import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import relativeDate from "relative-date";
import useSWRInfinite from "swr/infinite";
import { Header, LoadMore, sort } from "../../Common";
import { authorizedRequest } from "../../Validation";
import { fetcher } from "../Cms";
import { REVALIDATE_ON_FOCUS } from "../Common";
import { InnerHeader } from "../Headers";
import { RoleContext } from "../RoleContext";
import { ApproveTile } from "../Tile";
import { contentCretorOptions } from "./Options";
import { authRequest } from "src/utils/Axios";
import Table, { getColumn } from "../Table/Table";

const columns = [
  getColumn("USERNAME", { sortKey: "username", accessKey: "username" }),
  getColumn("USER ROLE", { sortKey: "role", accessKey: "role" }),
  getColumn("SUB ROLE", { sortKey: "subrole", accessKey: "subrole" }),
  getColumn("LAST ACTIVITY", {
    sortKey: "last_sign_in",
    accessFn: (user) => relativeDate(new Date(user.last_sign_in)),
  }),
  getColumn("NO. OF TEAMS", { sortKey: "teams", accessKey: "teams" }),
];

const ContentCreators = ({ header, subHeader, toggler }) => {
  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) =>
      `${window.domainName}/api/content-creators/list?page=${index + 1}`,
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );

  const { role } = useContext(RoleContext);

  const [subRoles, setSubRoles] = useState([]);

  useEffect(() => {
    getRoles("creator_role", setSubRoles);
  }, []);

  const getRoles = async (title, setState) => {
    const req = await axios({
      url: window.domainName + "/products/api/game-filters?title=" + title,
    });
    const res = await req.data;
    const rolesList = await res.option;
    setState(rolesList);
    return rolesList;
  };

  const creators = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];
  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  const search = async (query) => {
    const res = await authRequest({
      url: `/api/content-creators/cms/search?search_term=${query}`,
    });

    if (res.error) return [];
    return res;
  };
  const searchCallback = (data) => {
    mutate(data, false);
  };

  return (
    <div className="body">
      <InnerHeader
        toggler={toggler}
        header={header}
        subHeader={subHeader}
        element={"search"}
        search={{
          func: search,
          callback: searchCallback,
        }}
      />
      <div className="content">
        <Table
          columns={columns}
          data={creators}
          getOptions={(creator) => contentCretorOptions(role, creator)}
          getPopupData={(creator) => ({
            ban: {
              username: creator.username,
              banned: creator.banned,
            },
            make_trusted: {
              username: creator.username,
              isTrusted: creator.approved,
              id: creator.id,
            },
            "change-sub-role": {
              roles: subRoles,
              username: creator.username,
              subrole: true,
              url: "/api/content-creators/subrole/change?username=<username>&subrole=<role>",
            },
            "view-team": {
              username: creator.username,
            },
          })}
          refreshData={() => mutate(creators, true)}
          onSort={(key, order) => mutate(sort(key, creators, order), false)}
          indicators={[
            {
              color: "#00b4f2",
              when: (creator) => creator.approved,
              name: "Trusted Creator",
            },
          ]}
        />

        {errors && <div className="error">{errors.error}</div>}
        {error && <div className="error">{error.message}</div>}
        <LoadMore onClick={() => setSize((prev) => prev + 1)} />
      </div>
    </div>
  );
};

export default ContentCreators;
