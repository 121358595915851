import { useContext } from "react";
import { getCMSPermissions } from "../../utils/getPermissions";
import VideoUploadPage, { VideoEditPage } from "../Video/Upload";
import FeaturedList from "./Ads/FeaturedList";
import BlogArticle from "./Articles/BlogArticle";
import EditBlogArticle from "./Articles/EditArticles/EditBlogArticle";
import EditGameNews from "./Articles/EditArticles/EditGameNews";
import EditStaffReviews from "./Articles/EditArticles/EditStaffReviews";
import GameNews from "./Articles/GameNews";
import GameNewsApproval from "./Articles/GameNewsApproval";
import StaffReviews from "./Articles/StaffReviews";
import UploadBlogArticle from "./Articles/UploadBlogArticle";
import UploadGameNews from "./Articles/UploadGameNews";
import UploadStaffReviews from "./Articles/UploadStaffReviews";
import UserGameReviews from "./Articles/UserGameReviews";
import UserGameReviewsApprovals from "./Articles/UserGameReviewsApprovals";
import PayoutInfo from "./Financial/PayoutInfo";
import AwardsList from "./Games/AwardsList";
import CreateGame from "./Games/CreateGameV2/CreateGame";
import EditGame from "./Games/CreateGameV2/EditGame";
import GameList from "./Games/GameApprovalRequests";
import AllGamesList from "./Games/AllGamesList";
import ReportedLinks from "./Games/ReportedLinks";
import SalesList from "./Games/Sales/SalesLayout";
import CommentModerations from "./Moderation/Comments";
import { FTPTracker } from "./Moderation/FTPTracker";
import GameModerations from "./Moderation/Games";
import ImageModerations from "./Moderation/Images";
import ChangeRequests from "./Payments/ChangeRequests";
import Orders from "./Payments/Orders";
import Payouts from "./Payments/Payouts";
import Refunds from "./Payments/Refunds";
import { Playlists } from "./Playlists";
import CreatePlaylist from "./Playlists/CreatePlaylist";
import { EditPlaylist } from "./Playlists/EditPlaylist";
import { RoleContext, Roles } from "./RoleContext";
import AllCollections from "./SpicyArt/Collections";
import { UploadCollection } from "./SpicyArt/UploadCollection";
import EditCollection from "./SpicyArt/UploadCollection/EditCollection";
import ContentCreateTeam from "./Users/ContentCreateTeam";
import ContentCreators from "./Users/ContentCreators";
import DeveloperTeams from "./Users/DeveloperTeams";
import TeamInvitations from "./Users/TeamInvitations";
import EditTeamV2 from "./Users/Teams/EditTeam";
import UserList from "./Users/UserList";
import WithdrawPage from "./Users/Withdraw";
import { AllVideos } from "./Videos";

function permissions(role) {
  const paths = {
    personal: {
      id: "personal",
      header: "PERSONAL",
      path: "/cms/personal",
      icon: "fa-user",
      paths: {
        users: {
          path: "/users",
          text: "USERS",
          component: UserList,
        },
        content_creators: {
          path: "/content-creators",
          text: "CONTENT CREATORS",
          component: ContentCreators,
        },
        dev_teams: {
          path: "/teams",
          text: "DEVELOPER TEAMS",
          component: DeveloperTeams,
        },
        edit_team: {
          path: "/teams/edit/:id?",
          text: "EDIT TEAM",
          routeProps: { exact: false },
          component: EditTeamV2,
          linkProps: {
            showTeamChoose: true,
          },
        },
        team_invitations: {
          path: "/team-invitations",
          text: "INVITES TO TEAMS",
          component: TeamInvitations,
        },
      },
      otherPaths: {
        create_team: {
          path: "/teams/create-team",
          text: "Create Team",
          component: ContentCreateTeam,
        },
      },
    },
    content: {
      id: "content",
      header: "CONTENT",
      path: "/cms/content",
      icon: "fa-newspaper-o",
      paths: {
        game_list: {
          path: "/games",
          text: "GAMES",
          component: AllGamesList,
        },
        upload_game: {
          path: "/upload-game",
          text: "UPLOAD A NEW GAME",
          component: CreateGame,
          routeProps: { exact: false },
        },
        awards: {
          path: "/game-awards",
          text: "GAME AWARDS",
          component: AwardsList,
        },
        blog_articles: {
          path: "/blog-articles",
          text: "SITE NEWS",
          component: BlogArticle,
        },
        staff_reviews: {
          path: "/staff-reviews",
          text: "GAME REVIEWS",
          component: StaffReviews,
        },
        game_news: {
          path: "/game-news",
          text: "GAME NEWS",
          component: GameNews,
        },
        user_game_reviews: {
          path: "/user-game-reviews",
          text: "USER GAME REVIEWS",
          component: UserGameReviews,
        },
        featured: {
          path: "/featured",
          text: "FEATURED CONTENT",
          routeProps: { exact: false },
          component: FeaturedList,
        },
        videos: {
          path: "/videos",
          text: "VIDEOS",
          component: AllVideos,
        },
        playlists: {
          path: "/playlists",
          text: "PLAYLISTS",
          component: Playlists,
        },
        gallery: {
          path: "/spicy-art",
          text: "SPICY ART",
          component: AllCollections,
        },
      },
      otherPaths: {
        playlist_create: {
          path: "/playlists/create",
          text: "Create Playlist",
          component: CreatePlaylist,
          routeProps: { exact: false },
        },
        playlist_edit: {
          path: "/playlists/edit/:id",
          text: "Edit Playlist",
          component: EditPlaylist,
          routeProps: { exact: false },
        },
        video_upload: {
          path: "/videos/upload",
          text: "Upload Video",
          component: VideoUploadPage,
          routeProps: { exact: false },
        },
        video_edit: {
          path: "/videos/edit/:id",
          text: "Edit Video",
          component: VideoEditPage,
          routeProps: { exact: false },
        },
        edit_game: {
          path: "/games/edit/:id",
          text: "Edit Game",
          component: EditGame,
          routeProps: {
            exact: false,
          },
        },
        upload_blog: {
          path: "/blog-articles/upload-article",
          text: "Upload Site News",
          component: UploadBlogArticle,
          routeProps: {
            exact: false,
          },
        },
        edit_blog: {
          path: "/blog-articles/edit/:id",
          text: "Edit Site News",
          component: EditBlogArticle,
          routeProps: {
            exact: false,
          },
        },
        upload_review: {
          path: "/staff-reviews/upload-review",
          text: "Upload Game Review",
          component: UploadStaffReviews,
          routeProps: {
            exact: false,
          },
        },
        edit_review: {
          path: "/staff-reviews/edit/:id",
          text: "Edit Game Review",
          component: EditStaffReviews,
          routeProps: {
            exact: false,
          },
        },
        upload_news: {
          path: "/game-news/upload-review",
          text: "Upload Game News",
          component: UploadGameNews,
          routeProps: {
            exact: false,
          },
        },
        edit_news: {
          path: "/game-news/edit/:id",
          text: "Edit Game News",
          component: EditGameNews,
          routeProps: {
            exact: false,
          },
        },
        upload_collections: {
          path: "/spicy-art/upload",
          text: "Upload Collection",
          component: UploadCollection,
        },
        edit_collections: {
          path: "/spicy-art/edit/:id",
          text: "Edit Collection",
          component: EditCollection,
        },
      },
    },
    financial: {
      id: "financial",
      header: "FINANCIAL",
      path: "/cms/financial",
      icon: "fa-bitcoin",
      paths: {
        game_sales: {
          path: "/game-sales/:id?",
          text: "SALES & BUNDLES",
          component: SalesList,
          routeProps: {
            exact: false,
          },
          linkProps: {
            isNew: true,
          },
        },
        transactions: {
          path: "/orders",
          text: "ORDERS",
          component: Orders,
          routeProps: {
            exact: false,
          },
        },
        refunds: {
          path: "/refunds",
          text: "REFUNDS",
          component: Refunds,
        },
        change_requests: {
          path: "/change-requests",
          text: "PAYOUT CHANGE REQUESTS",
          component: ChangeRequests,
        },
        payouts: {
          path: "/payouts",
          text: "PAYOUTS",
          component: Payouts,
        },
        withdraw: {
          path: "/withdraw/:id?",
          text: "WITHDRAW",
          component: WithdrawPage,
          linkProps: {
            showTeamChoose: true,
          },
        },
        payout_info: {
          path: "/payout-info/:id?",
          text: "PAYOUT INFO",
          component: PayoutInfo,
          linkProps: {
            showTeamChoose: true,
          },
        },
      },
    },
    moderation: {
      id: "moderation",
      header: "MODERATION",
      path: "/cms/moderation",
      icon: "fa-user-plus",
      paths: {
        game_approval: {
          path: "/game-approval-requests",
          text: "GAME APPROVAL REQUESTS",
          component: GameList,
        },
        reported_links: {
          path: "/reported-links",
          text: "REPORTED LINKS",
          component: ReportedLinks,
        },
        game_news_approval: {
          path: "/game-news-approvals",
          text: "GAME NEWS APPROVALS",
          component: GameNewsApproval,
        },
        user_game_reviews_approvals: {
          path: "/user-game-reviews-approvals",
          text: "USER REVIEWS APPROVALS",
          component: UserGameReviewsApprovals,
        },
        game_reports: {
          path: "/games",
          text: "GAME REPORTS",
          component: GameModerations,
        },
        user_comments: {
          path: "/comments",
          text: "USER COMMENT REPORTES",
          component: CommentModerations,
        },
        images: {
          path: "/images",
          text: "IMAGES REPORTS",
          component: ImageModerations,
        },
        ftp_uploads: {
          path: "/ftp",
          text: "FTP UPLOAD TRACKER",
          component: FTPTracker,
        },
      },
    },
  };

  return {
    paths: getCMSPermissions(role, paths, {
      [Roles.ADMIN]: [
        [
          "personal",
          [
            "users",
            "content_creators",
            "dev_teams",
            "edit_team",
            "team_invitations",
          ],
          ["create_team"],
        ],
        ["content"],
        [
          "financial",
          [
            "game_sales",
            "transactions",
            "refunds",
            "withdraw",
            "change_requests",
            "payouts",
          ],
        ],
        ["moderation"],
      ],
      [Roles.CONTENT_CREATOR]: [
        [
          "personal",
          ["content_creators", "dev_teams", "edit_team", "team_invitations"],
          ["create_team"],
        ],
        [
          "content",
          [
            "game_list",
            "upload_game",
            "game_news",
            "user_game_reviews",
            "videos",
            "gallery",
          ],
          [
            "upload_news",
            "edit_news",
            "edit_game",
            "video_upload",
            "video_edit",
            "edit_collections",
            "upload_collections",
          ],
        ],
        ["financial", ["game_sales", "withdraw", "payout_info"]],
        ["moderation", ["game_approval", "reported_links"]],
      ],

      [Roles.STAFF]: [
        [
          "personal",
          ["users", "content_creators", "dev_teams", "team_invitations"],
          ["create_team"],
        ],
        ["content"],
        ["moderation"],
      ],

      [Roles.WRITER]: [
        [
          "content",
          ["blog_articles", "staff_reviews", "game_news"],
          [
            "upload_blog",
            "edit_blog",
            "upload_review",
            "edit_review",
            "upload_news",
            "edit_news",
          ],
        ],
      ],
      [Roles.MEMBER]: [["content", ["videos"], ["video_upload", "video_edit"]]],
    }),
  };
}
export const usePermissions = () => {
  const { detail, role } = useContext(RoleContext);
  return { ...permissions(role), isLogged: detail === "success" };
};

export default permissions;
