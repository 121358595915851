import React, { useState } from "react";
import { SubmitButton } from "../CMS/Common";
import Popup from "../common/Popup";
import { Input } from "../Member/General";
import { authorizedRequest } from "../Validation";

const ChangePaymentDetails = ({ teamID, onClose, open }) => {
  const [error, setError] = useState(null);
  const [reason, setReason] = useState("");

  const changePayoutDetails = async () => {
    try {
      const res = await authorizedRequest({
        url: `${window.domainName}/store/api/teams/payment-info/change-requests/create`,
        method: "POST",
        data: {
          team_id: teamID,
          reason: reason,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.error) return setError(res.error);
      if (res.success) setError("Request successfully sent");
    } catch {}
  };

  return (
    <Popup
      showCloseButton
      open={open}
      onClose={onClose}
      className="change-details"
      maxWidth={900}
    >
      <h2>Request to Change Payout Details</h2>
      <p>
        for Security Purposes you need to make a request before change your
        payout details
      </p>
      <Input label="Reason for the change" callback={setReason} />
      <div>{error && <div className="error">{error}</div>}</div>
      <SubmitButton
        label="Submit"
        request={changePayoutDetails}
        disabled={!reason?.length}
      />
    </Popup>
  );
};

export default ChangePaymentDetails;
