export const getCMSPermissions = (role, allPaths, specifiedPaths) => {
  if (!role) return undefined;

  const entries = specifiedPaths[role]?.map(([id, subPaths, otherPaths]) => {
    const selectedPath = allPaths[id];

    const selectedOtherPaths = !!otherPaths?.length
      ? Object.fromEntries(
          otherPaths.map((other_id) => [
            other_id,
            allPaths[id]?.otherPaths[other_id],
          ])
        )
      : undefined;

    const selectedSubPaths = !!subPaths?.length
      ? Object.fromEntries(
          subPaths.map((sub_id) => [sub_id, allPaths[id]?.paths[sub_id]])
        )
      : undefined;

    return [
      id,
      {
        ...selectedPath,
        paths: selectedSubPaths ?? selectedPath?.paths,
        otherPaths: selectedOtherPaths ?? selectedPath?.otherPaths,
      },
    ];
  });

  if (!entries?.length) return null;

  return Object.fromEntries(entries);
};
