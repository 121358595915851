export const inputValidate = (value = "", regex = /.*/) => {
  value = value.trim();
  if (regex.test(value)) {
    return {
      validated: true,
      value,
    };
  } else {
    return { validated: false, value: "" };
  }
};

export const VALID_BORDER_STYLE = "1px #c2c2c2 solid";
export const NOT_VALID_BORDER_STYLE = "2px #fb0400 solid";
