import { generatePath } from "react-router-dom";
import { toast } from "react-toastify";
import { PRODUCT_TYPES } from "../../providers/CartProvider";
import { authRequest } from "../../utils/Axios";

const types = [
  {
    type: "Game Creation",
    getLink(n) {
      return `/game/preview/${n.data?.game_id}`;
    },
    actions: [
      {
        label: "Preview",
        icon: "fa-eye",
        async onClick(_e, n) {
          return { link: `/game/preview/${n.data?.game_id}` };
        },
      },
      {
        label: "Publish",
        icon: "fa-gamepad",
        async onClick(_e, n) {
          const res = await authRequest({
            url: `/products/api/games/publish`,
            method: "POST",
            params: {
              game_id: n.data?.game_id,
              publish: true,
            },
          });

          if (res.success) {
            toast.info("Game sent for Review & Approval");
            return { link: `/game/preview/${n.data?.game_id}` };
          }
        },
      },
    ],
  },
  {
    type: "Game Update",
    getLink(n) {
      return `/game/${n.data?.game_id}`;
    },
  },
  {
    type: "Game News",
    getLink(n) {
      return `/news-article/${n.data?.post_id}`;
    },
  },
  {
    type: "Game Approval",
    getLink() {
      return "/cms/moderation/game-approval-requests";
    },
  },
  {
    type: "Game Approval Result",
    getLink(n) {
      if (n.data?.approved) return `/game/${n.data?.game_id}`;
      return `/game/preview/${n.data?.game_id}`;
    },
  },
  {
    type: "Error Report",
    getDescription(n) {
      return n.data?.error;
    },
  },
  {
    type: "Product Checkout",
    getLink(n) {
      const type = n.data?.product_type;
      if (!type) return;
      if (type === PRODUCT_TYPES.TokenPurchase) return "/member/orders";
      if (type === PRODUCT_TYPES.Game) return "/library/purchased-games";
    },
  },
  {
    type: "Steam Key Shortage",
  },
  {
    type: "Game News Approval",
  },
  {
    type: "Game News Approval Result",
    getLink(n) {
      if (n.data?.approved) return `/news-article/${n.data?.game_news_id}`;
      return `/news-article/preview/${n.data?.game_news_id}`;
    },
  },
  {
    type: "Information",
    getLink(n) {
      return n.data?.redirect_url;
    },
  },
  {
    type: "Invalid Affiliate Transaction",
    getDescription(n) {
      return `The Affiliate id ${n.data?.affiliate_id} is invalid`;
    },
  },
  {
    type: "New SpicyTube Video",
    getLink(n) {
      return `/video/${n.data.video_id}`;
    },
  },
  {
    type: "Gallery Download Creation",
    getLink(n) {
      return `/gallery/${n.data.gallery_id}`;
    },
  },
];

export const getNotification = (type) => types.find((t) => t.type === type);
export default types;
