import { useBroughtProducts } from "src/providers/BroughtProductsProvider";
import { getDiscountRate } from "src/utils/getDiscountRate";
import round2Decimal from "src/utils/to2Decimal";

function getOnlyAvailableToBuy(bundleProduct) {
  return bundleProduct.can_buy === true;
}

export default function useBundleFinalPrice(bundleProducts = []) {
  const broughtProducts = useBroughtProducts();

  const finalProducts = bundleProducts
    .filter((bundleProduct) => {
      const foundIndex = broughtProducts.findIndex(
        (broughtProduct) =>
          bundleProduct.product_id === broughtProduct.product_id &&
          bundleProduct.product_type === broughtProduct.product_type
      );
      return foundIndex === -1; // filter out brought products
    })

    .filter(getOnlyAvailableToBuy);

  const finalPrice = finalProducts.reduce(
    (object, product) => {
      return {
        discountedPrice: object.discountedPrice + product.discounted_price,
        total:
          object.total +
          (product.discounted_price / (100 - product.rate)) * 100,
      };
    },
    {
      total: 0,
      discountedPrice: 0,
    }
  );

  return {
    total: round2Decimal(finalPrice.total),
    rate: round2Decimal(
      getDiscountRate(finalPrice.total, finalPrice.discountedPrice)
    ),
    discountedPrice: round2Decimal(finalPrice.discountedPrice),
  };
}
