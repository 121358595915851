import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./css/Game.css";

import { toast } from "react-toastify";
import relativeDate from "relative-date";
import { authRequest } from "../../utils/Axios";
import { getClass } from "../../utils/getClass";
import { numberCompact } from "../../utils/number-format";
import { Chip } from "../Chip";
import { LocalPrice } from "../LocalPrice";
import { MultiLang } from "../MultiLang";
import { BasicImage } from "../SpicyArt/Image";
import { loggedInValidation } from "../Validation";
import { Avatar } from "../common/Avatar";
import { osIconFinder } from "../common/osIconFinder";
import { generateURL } from "../resizeImages";
import { useGamesMetaData } from "./MetaDataProvider";

const Game = ({
  id,
  title,
  short_description,
  square_banner,
  last_updated,
  genre,
  status,
  creator,
  likes,
  views,
  creator_id,
  followers,
  operating_system,
  price,
  discount = {},
  creator_pic,
  vat_amount,
  lazyLoadImage = true,
  is_featured = false,
  is_available_free,
  show_recommend_tag = true,
  isHorizontal = false, // if true, the component will be horizontal
  options = [],
}) => {
  return (
    <div
      role="group"
      aria-label={title}
      className="game adult-game-container"
      data-horizontal={isHorizontal}
    >
      <div className="image">
        <Link to={"/game/" + id}>
          <BasicImage
            loading={lazyLoadImage ? "lazy" : "eager"}
            src={square_banner}
            alt={title}
            width={400}
            height={400}
          />
        </Link>

        {is_featured && show_recommend_tag && (
          <Chip
            aria-label="This Game is Recommended"
            className="badge badge-top badge-left"
          >
            Recommended
          </Chip>
        )}
        {is_available_free && (
          <Link to={"/game/" + id}>
            <Chip className="badge badge-top badge-right" color="orange">
              Try For Free
            </Chip>
          </Link>
        )}
        {!!price && (
          <Chip className="badge badge-right badge-bottom">
            {!!discount.discounted_price ? (
              <div className="leading-none flex gap-1">
                <LocalPrice
                  amountInEUR={discount.discounted_price}
                  hideDisclaimer
                  showOnlyLocalPrice
                />

                <strike className="opacity-80">
                  <LocalPrice
                    amountInEUR={price}
                    hideDisclaimer
                    showOnlyLocalPrice
                  />
                </strike>
              </div>
            ) : (
              <LocalPrice
                amountInEUR={price}
                hideDisclaimer
                showOnlyLocalPrice
              />
            )}
          </Chip>
        )}
        {/* <Chip className="badge badge-bottom badge-left" color="blue">
          Play Online
        </Chip> */}
      </div>
      <div className="game">
        <h3 className="title">
          <Link to={"/game/" + id}>{title}</Link>
        </h3>
        <div className="author">
          <Avatar
            src={generateURL(60, 60, creator_pic)}
            name={creator}
            width={30}
          />

          <Link to={`/team/@${creator}`}>
            <div className="name">{creator}</div>
          </Link>

          {!!options.length && (
            <button className="dots option-dots">
              <span className="material-icons dot">more_horiz</span>
              <div className="menu">
                <span className="material-icons">more_horiz</span>
                {options.map((option, i) => {
                  const onClickOption = () => option.onClick?.(id);
                  return (
                    <div
                      role="button"
                      className="opt"
                      key={i}
                      onClick={onClickOption}
                    >
                      <span className={`fa ${option.icon}`}></span>
                      <span>{option.text}</span>
                    </div>
                  );
                })}
              </div>
            </button>
          )}
        </div>

        <div className="info">
          <div className="row">
            <div
              aria-label={`This game is ${status}`}
              className="part line"
              data-tooltip={status}
            >
              <div className="title">
                <MultiLang sl="Status:">Status:</MultiLang>
              </div>
              <div className="content">{status}</div>
            </div>
            <div
              className="part"
              aria-label={`Updated ${relativeDate(new Date(last_updated))}`}
            >
              <div className="title no-colon">
                <span className="material-icons">update</span>
              </div>
              <div className="content">
                {relativeDate(new Date(last_updated))}
              </div>
            </div>
          </div>
          <div className="row">
            <div
              aria-label={`Category ${genre}`}
              className="part line"
              data-tooltip={genre}
            >
              <div className="title">
                <MultiLang sl="Katergorija:">category:</MultiLang>
              </div>
              <div className="content">{genre}</div>
            </div>
            {operating_system && (
              <div className="part">
                <div className="content">
                  <ul className="os" aria-label="Operating Systems">
                    {operating_system?.map?.((os) => {
                      const [icon] = osIconFinder(os);
                      return (
                        <li aria-label={os} data-tooltip={os} key={os}>
                          <span
                            aria-hidden
                            className={getClass("fa icon", icon)}
                          ></span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>

        <p className="description">{short_description}</p>

        <MetaInfo
          id={id}
          views={views}
          likes={likes}
          followers={Array.isArray(followers) ? followers.length : followers}
          creator_id={creator_id}
        />
      </div>
    </div>
  );
};

export const follow = async (id) => {
  try {
    const res = await authRequest({
      url: `/api/members/follow-team`,
      method: "POST",
      params: {
        team_id: id,
      },
    });
    return res;
  } catch (error) {
    toast.error(error);
  }
};
export const like = async (id) => {
  try {
    const res = await authRequest({
      url: `/products/api/games/${id}/like-game`,
      method: "POST",
    });
    return res;
  } catch (error) {
    toast.error(error);
  }
};
export const MetaInfo = ({
  views,
  likes: initialLikes,
  followers: initialFollowers,
  id,
  creator_id,
}) => {
  const { followed, liked } = useGamesMetaData();
  const [likes, setLikes] = useState({ status: false, likes: initialLikes });
  const [followers, setFollowers] = useState({
    status: false,
    followers: initialFollowers,
  });

  useEffect(() => {
    if (followed.includes(id))
      setFollowers((prev) => ({ ...prev, status: true }));
    if (liked.includes(id)) setLikes((prev) => ({ ...prev, status: true }));
  }, []);

  const updateLikes = async () => {
    loggedInValidation() && setLikes(await like(id));
  };
  const updateFollowers = async () => {
    if (!loggedInValidation()) return;

    const res = await follow(creator_id);
    setFollowers({
      status: res.is_followed,
      followers: res.followers?.length,
    });
  };
  const msgPrefix = loggedInValidation() ? "" : "Login to ";
  return (
    <div className="likes">
      <div className="line"></div>
      <div className="data">
        <div>
          <span aria-hidden className="fa fa-eye"></span>
          <span aria-label={`${views} Views`}>{numberCompact(views)}</span>
        </div>
        <div data-tooltip={`${msgPrefix}Like this Game`} onClick={updateLikes}>
          <span
            aria-hidden
            className={`fa fa-heart clickable ${likes.status && "clicked"}`}
          ></span>
          <span aria-label={`${likes.likes} Likes`}>
            {numberCompact(likes.likes)}
          </span>
        </div>
        {!isNaN(followers.followers) && (
          <div
            data-tooltip={`${msgPrefix} Subscribe to Team`}
            onClick={updateFollowers}
          >
            <span
              aria-hidden
              className={getClass(
                "material-icons",
                "clickable",
                followers.status && "clicked"
              )}
            >
              groups
            </span>
            <span aria-label={`${followers.followers} Subscribers`}>
              {numberCompact(followers.followers)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default Game;
