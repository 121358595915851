const RatingStar = ({ percentage }) => {
  return (
    <div className="tag" data-tooltip="Rating">
      <svg width="24" height="24">
        <defs>
          <linearGradient id="half_grad" x1="0%" y1="100%" x2="0%" y2="0%">
            <stop offset={`${percentage}%`} stopColor="gold" />
            <stop offset="0%" stopColor="#535353" stopOpacity="1" />
          </linearGradient>
        </defs>
        <path
          d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"
          fill="url(#half_grad)"
          strokeWidth={0}
        />
      </svg>
      {Math.floor(percentage)}/100
    </div>
  );
};

export default RatingStar;
