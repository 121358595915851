import { useEffect, useState } from "react";
import Game from "src/components/AdultGames/Game";
import { LoaderInside } from "src/components/Common";
import { MultiLang } from "src/components/MultiLang";
import { BASE_CDN_URL, request } from "src/utils/Axios";

const GamesSection = ({ teamId, teamName }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (teamId) getGames();
  }, [teamId]);

  const getGames = async () => {
    try {
      setLoading(true);
      const res = await request({
        url: "/api/content-creator-teams/games/details",
        baseURL: BASE_CDN_URL,
        withCredentials: true,
        params: {
          team_id: teamId,
        },
      });
      setGames(res);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <LoaderInside />;

  return (
    <div className="part games">
      <h2 className="header">
        <MultiLang sl="Igre">
          {/* wont display games twice if the team has that in name*/}
          {teamName?.toLowerCase().replace("games", "")} GAMES
        </MultiLang>
      </h2>
      <div className="content adult-games">
        {games.map((game, key) => (
          <Game lazyLoadImage={false} {...game} key={key} />
        ))}
      </div>
      {games.length === 0 && (
        <div className="nothing">
          <p>No Games were Created by {teamName} yet</p>
        </div>
      )}
    </div>
  );
};

export default GamesSection;
