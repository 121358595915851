import React, { useState } from "react";
import { Events } from "../../../../constants/socket-events";
import { useSocketEvent } from "../../../../hooks/useSocketEvent";
import useSWRInfinite from "swr/infinite";
import { InnerHeader } from "../../Headers";
import "./style.css";
import { fetcher } from "../../Cms";
import { REVALIDATE_ON_FOCUS } from "../../Common";
import { ContentTile } from "../../Tile";
import relativeDate from "relative-date";
import { numberCompact } from "../../../../utils/number-format";
import round2Decimal from "../../../../utils/to2Decimal";
import { Header, LoadMore } from "../../../Common";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { withoutLastPage } from "../../../../utils/withoutLastPage";
import { ftpOptions } from "../Options";

const example = {
  file_id: "8304b197e51a572795613890",
  fp: "a very cool game/1.0/See.S03E01.720p.WEB.x265-MiNX.mkv",
  size: 281696877,
  started: "2022-11-09T06:58:44.264603Z",
  zone: "store-zone-beta",
  progress: 10485760,
  percentage: 3.72,
};

const FTPTracker = ({ header, subHeader, toggler }) => {
  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) => {
      return `${
        window.domainName
      }/downloads/api/games/downloads/resources/bunny-edge-files/list?page=${
        index + 1
      }`;
    },
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );
  const [nowUploading, setNowUploading] = useState([]);
  const [progresses, setProgresses] = useState({});

  useSocketEvent(Events.FTP, (uploads = []) => {
    uploads.forEach((upload) => {
      setNowUploading((prev) => {
        const item = prev.find(
          (nowUpload) => nowUpload.file_id === upload.file_id
        );
        if (!item) return [...prev, upload];
        return prev;
      });
      setProgresses((prev) => ({
        ...prev,
        [upload.file_id]: upload.progress,
      }));
    });
  });
  useSocketEvent(Events.FTP_DONE, (upload) => {
    setProgresses((prev) => {
      const clone = { ...prev };
      delete clone[upload.file_id];
      return clone;
    });
    setNowUploading((prev) => prev.filter((u) => u.file_id === upload.file_id));
    mutate(data, true);
  });

  const uploading_headers = [
    { text: "PATH" },
    { text: "STORAGE ZONE" },
    { text: "STARTED AT" },
    { text: "PROGRESS" },
    { text: "UPLOADED" },
  ];
  const uploaded_headers = [
    { text: "FILE ID" },
    { text: "FILE PATH" },
    { text: "UPLOADED TO USER ZONE" },
  ];

  const uploaded = data ? withoutLastPage(data.flat()) : [];

  return (
    <div className="FTPTracker body" data-testid="FTPTracker">
      <InnerHeader header={header} subHeader={subHeader} toggler={toggler} />

      <div className="now_uploading content">
        <h2>Now Uploading</h2>
        {!!Object.keys(progresses).length ? (
          Object.keys(progresses).map((key) => {
            const upload = nowUploading.find((up) => up.file_id === key);
            const progress = progresses?.[key];
            const percentage = (progress / upload.size) * 100;
            const data = [
              upload.fp,
              upload.zone,
              relativeDate(new Date(upload.started)),
              <progress value={progress} max={upload.size}>
                {progress}
              </progress>,
              `${round2Decimal(percentage)}% of ${numberCompact(upload.size)}B`,
            ];
            return (
              <ContentTile
                key={key}
                headers={uploading_headers.map((header) => header.text)}
                revalidate={() => {}}
                popupData={{}}
                data={data}
              />
            );
          })
        ) : (
          <p className="fallback">Nothing uploading at the moment</p>
        )}
      </div>

      <div className="uploaded content">
        <h2>Uploaded files</h2>
        <main className="cms-details">
          <div
            className="header"
            style={{
              gridTemplateColumns: `1fr 2fr 1fr 1fr`,
            }}
          >
            {uploaded_headers.map((header, i) => (
              <Header header={header} key={i} />
            ))}
          </div>
          <TransitionGroup className="body" component="div">
            {uploaded.map((upload, i) => {
              const options = ftpOptions();

              const data = [
                upload.file_id,
                upload.filepath,
                String(upload.uploaded_to_user_zone),
              ];

              return (
                <CSSTransition
                  key={i}
                  timeout={300}
                  classNames="popup"
                  mountOnEnter
                >
                  <ContentTile
                    headers={uploaded_headers.map((header) => header.text)}
                    options={options}
                    revalidate={() => mutate(uploaded, true)}
                    popupData={{
                      download: {
                        path: upload.filepath,
                        link: upload.download_link,
                      },
                    }}
                    data={data}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
          {error && <div className="error">{error.message}</div>}
          <LoadMore onClick={() => setSize((prev) => prev + 1)} />
        </main>
      </div>
    </div>
  );
};

export default FTPTracker;
