import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import AvoidRouteChange from "src/components/AvoidRouteChange";
import Popup from "src/components/common/Popup";
import { useToggle } from "src/hooks/useToggle";
import { authRequest } from "src/utils/Axios";
import throttle from "src/utils/throttle";
import { SubmitButton } from "../../Common";
import Editor from "../Editor";
import { arrayObjects } from "../EditorOptions";
import ArticleVersionControl from "./ArticleVersionControl";
import useNewTab from "src/components/Previews/hook";
import { TYPES } from "src/components/Home/ReviewMini";

const requestURL = {
  "Blog Posts": "blog-posts",
  "Staff Reviews": "staff-reviews",
  "Game News": "game-news",
};

const ARTICLE_AUTO_SAVE_TIME = 1000 * 30; // in miliseconds

const ArticleContent = ({ galleryID, defaults, postID, type }) => {
  const newTab = useNewTab();
  const [objects, setObjects] = useState(defaults?.objects ?? []);
  const [showVersionControl, toggleVersionControl] = useToggle();

  const [saved, setSaved] = useState(true);

  useEffect(() => {
    setSaved(false);
  }, [objects]);

  useEffect(() => {
    throttledCreateCachedVersion();
  }, [objects]);

  const formatObjectsForRequest = (objects) => {
    const objectEditorData = objects.map((obj, i) => {
      if (arrayObjects.includes(obj.id)) {
        return {
          ...obj,
          order: i + 1,
          content: obj.content.map((item) => item.id),
        };
      }
      return { ...obj, order: i + 1 };
    });

    return objectEditorData;
  };

  const saveContent = async (preview = false) => {
    try {
      if (preview) newTab.open();

      // API objects and they will only return there IDs in the content
      const objectEditorData = formatObjectsForRequest(objects);
      const mentionedGames = objects
        .filter((object) => object.id === "games")
        .flatMap((object) => object.content?.map((game) => game.id));

      await toast.promise(
        authRequest({
          method: "POST",
          url: `/news/api/news/${requestURL[type]}/edit-content`,
          data: {
            content: objectEditorData,
            mentioned_games: mentionedGames,
            preview,
          },
          params: {
            article_id: postID,
          },
        }),
        { pending: "Saving Changes", success: "Successfully saved" }
      );

      if (preview) {
        newTab.revalidateURL(`${TYPES[type]?.url_prefix}preview/${postID}`);
      }
      setSaved(true);
      await defaults?.revalidateArticleDate?.();
    } catch (error) {
      toast.error(error.message);
      setSaved(false);
    }
  };

  const createCachedVersions = async () => {
    const url = `/news/api/news/${requestURL[type]}/cached-versions/create`;
    const content = formatObjectsForRequest(objects);
    try {
      await authRequest({
        url,
        method: "POST",
        data: {
          article_id: postID,
          content,
        },
      });
    } catch (error) {}
  };

  const throttledCreateCachedVersion = useCallback(
    throttle(createCachedVersions, ARTICLE_AUTO_SAVE_TIME),
    []
  );

  return (
    <div className="ArticleContent">
      <AvoidRouteChange
        blockNavigate={!saved}
        confirmationMessage="Are you sure you want to leave? All unsaved content will be lost."
      />

      <div className="previous-version" onClick={toggleVersionControl}>
        Restore auto-saved Versions
      </div>

      <Editor
        showFullContent
        objects={objects}
        setObjects={setObjects}
        galleryID={galleryID}
        customData={{
          media_gallery: galleryID,
        }}
      />
      <div className="save-content">
        <SubmitButton
          request={() => saveContent(true)}
          label="Save and Preview"
        />
        <SubmitButton
          request={() => saveContent(false)}
          label="Save and Publish"
        />
      </div>

      <Popup
        open={showVersionControl}
        onClose={toggleVersionControl}
        showCloseButton
      >
        <ArticleVersionControl
          urlSegment={requestURL[type]}
          articleID={postID}
        />
      </Popup>
    </div>
  );
};

export default ArticleContent;
