import React from "react";
import { Link } from "react-router-dom";
import {
  Discord,
  Itchio,
  Patreon,
  Social,
  Subscribestar,
} from "../common/social";
import { MultiLang } from "../MultiLang";

import { generateURL } from "../resizeImages";
import { authRequest } from "src/utils/Axios";

export const follow = (id, username, editState) => async () => {
  try {
    const res = await authRequest({
      url: `/api/members/follow-team?team_id=${id}`,
      params: { username },
      method: "POST",
    });
    if (res.success && editState) editState(res.followers);
    return res.is_followed;
  } catch (error) {}
};

const FavDeveloperMini = ({
  id,
  name,
  profile_picture,
  facebook_link,
  twitter_link,
  instagram_link,
  website_link,
  discord_link,
  patreon_link,
  steam_link,
  itchio_link,
  subscribestar_link,
  editFollowers,
}) => {
  return (
    <div className="developer">
      <Link to={`/team/@${name}`}>
        <div className="logo">
          <img
            src={
              (profile_picture && generateURL(300, 300, profile_picture)) ||
              "/images/developer-default.svg"
            }
            alt={name}
          />
        </div>
      </Link>
      <div className="details">
        <Link to={`/team/@${name}`}>
          <div className="name">{name}</div>
        </Link>
        <div className="social">
          <Social path={facebook_link} fa_name="fa-facebook" />
          <Social path={twitter_link} fa_name="fa-twitter" />
          <Social path={instagram_link} fa_name="fa-instagram" />
          <Social path={patreon_link} fa_name="">
            <Patreon />
          </Social>
          <Social path={steam_link} fa_name="fa-steam" />

          <Social path={facebook_link} fa_name="fa-facebook" />
          <Social path={twitter_link} fa_name="fa-twitter" />
          <Social path={instagram_link} fa_name="fa-instagram" />
          <Social path={website_link} fa_name="fa-globe" />
          <Social path={discord_link}>
            <Discord />
          </Social>
          <Social path={patreon_link} fa_name="">
            <Patreon />
          </Social>
          <Social path={steam_link} fa_name="fa-steam" />
          <Social path={itchio_link}>
            <Itchio />
          </Social>
          <Social path={subscribestar_link}>
            <Subscribestar />
          </Social>
        </div>
        <div className="follow-btn" onClick={follow(id, name, editFollowers)}>
          <MultiLang sl="Odsledi">Unsubscribe</MultiLang>
        </div>
      </div>
    </div>
  );
};

export default FavDeveloperMini;
