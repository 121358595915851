import { createContext, useContext } from "react";
import { getUserBroughtProducts } from "src/v2/services/storeService";
import useSWR from "swr";

const BroughtProductsContext = createContext([]);

const BroughtProductsProvider = ({ children }) => {
  const { data } = useSWR("brought-products", () => getUserBroughtProducts(), {
    fallbackData: [],
  });
  return (
    <BroughtProductsContext.Provider value={data ?? []}>
      {children}
    </BroughtProductsContext.Provider>
  );
};

export const useBroughtProducts = () => {
  return useContext(BroughtProductsContext);
};

export default BroughtProductsProvider;
