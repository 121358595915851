import { useContext, useEffect, useState } from "react";
import { authRequest } from "../../utils/Axios";
import { SubmitButton } from "../CMS/Common";
import { RoleContext } from "../CMS/RoleContext";
import { LoaderInside } from "../Common";
import { MultiLang } from "../MultiLang";
import "./css/Notify.css";
import notifications from "./notifications";
import types from "./types";
import { toast } from "react-toastify";

const Notification = () => {
  const URL = `/notifications/api/manage-notifications`;
  const { role } = useContext(RoleContext);

  const [notificationsInfo, setNotificationsInfo] = useState({});
  const [error, setError] = useState(null);

  const callback = (enabled, key, type) => {
    setNotificationsInfo((prev) => ({
      ...prev,
      [type]: { ...prev[type], [key]: enabled },
    }));
  };
  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    try {
      const res = await authRequest({
        url: URL,
        method: "GET",
      });
      setNotificationsInfo(res.settings);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateNotifications = async () => {
    try {
      const res = await authRequest({
        url: URL,
        method: "POST",
        data: {
          settings: notificationsInfo,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.success)
        toast.success("Successfully updated notifications settings");
    } catch (error) {
      toast.error(error.message);
    }
  };

  if (!notificationsInfo.email) return <LoaderInside />;

  return (
    <div className="notification">
      <div className="content">
        {notifications.map((notification) => {
          if (notification.hideFor?.includes(role)) return null;
          return (
            <table key={notification.title?.en}>
              <thead>
                <tr>
                  <th className="title">
                    <MultiLang {...notification.title} />
                  </th>
                  {types.map((type) => (
                    <th key={type.key} className="notification-type">
                      {type.displayText}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {notification.items.map((item) => {
                  if (item.hideFor?.includes(role)) return null;
                  return (
                    <tr key={item.key}>
                      <td>
                        <MultiLang {...item.name} />
                      </td>
                      {types.map((type) => {
                        const notificationType = item.types.find(
                          (itemType) => itemType === type.key
                        );

                        if (!!notificationType) {
                          return (
                            <td key={type.key}>
                              <type.Element
                                item={item}
                                type={notificationType}
                                callback={callback}
                                enabled={
                                  notificationsInfo[type.key]?.[item.key]
                                }
                              />
                            </td>
                          );
                        }
                        return null;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
      </div>
      {error && <div className="error">{error}</div>}

      <SubmitButton label="Save Changes" request={updateNotifications} />
    </div>
  );
};

export default Notification;
