import { useState, useEffect } from "react";

function useSwipe(elementRef) {
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [startX, setStartX] = useState(null);
  const [endX, setEndX] = useState(null);
  const threshold = 150;

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    function handleTouchStart(event) {
      setStartX(event.touches[0].clientX);
    }

    function handleTouchMove(event) {
      setEndX(event.touches[0].clientX);
    }

    function handleTouchEnd() {
      if (startX < endX && endX - startX > threshold) {
        setSwipeDirection("right");
      } else if (startX > endX && startX - endX > threshold) {
        setSwipeDirection("left");
      }
    }

    element.addEventListener("touchstart", handleTouchStart);
    element.addEventListener("touchmove", handleTouchMove);
    element.addEventListener("touchend", handleTouchEnd);
    return () => {
      element.removeEventListener("touchstart", handleTouchStart);
      element.removeEventListener("touchmove", handleTouchMove);
      element.removeEventListener("touchend", handleTouchEnd);
    };
  }, [elementRef, threshold, startX, endX]);

  return swipeDirection;
}

export default useSwipe;
