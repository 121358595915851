import { createContext, useEffect, useState } from "react";
import { authRequest } from "../../utils/Axios";
import { loggedInValidation } from "../Validation";

const INITIAL_STATE = { blog: [], staff: [], news: [] };

export const ArticleMetaDataContext = createContext(INITIAL_STATE);

const getID = (arr) => arr.map((item) => item.article_id);
export const ArticleMetaDataProvider = ({ children }) => {
  const [metaData, setMetaData] = useState(INITIAL_STATE);
  useEffect(() => {
    getMetaData();
  }, []);
  const getMetaData = async () => {
    try {
      const res = await authRequest({
        url: `/api/members/gamer-profile/get-liked-articles`,
      });

      const blog = getID(res.filter((post) => post.type === "Blog Post"));
      const staff = getID(res.filter((post) => post.type === "Staff Reviews"));
      const news = getID(res.filter((post) => post.type === "Game News"));

      if (!res?.error && loggedInValidation())
        setMetaData({ blog, staff, news });
    } catch {}
  };
  return (
    <ArticleMetaDataContext.Provider value={metaData}>
      {children}
    </ArticleMetaDataContext.Provider>
  );
};
