import { useMemo, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useSpicyStore } from "src/providers/SpicyStoreProvider";
import { convertToSpicyTokens } from "src/utils/currency-format";
import { toEuro } from "src/utils/number-format";
import { CONVERSION_RATE } from "../../constants/store";
import { useToggle } from "../../hooks/useToggle";
import {
  CurrencyDisclaimer,
  PRODUCT_TYPES,
} from "../../providers/CartProvider";
import { authRequest } from "../../utils/Axios";
import { SubmitButton } from "../CMS/Common";
import { LoaderInside } from "../Common";
import SpicyTokensPrice, {
  SpicyTokensLogo,
} from "../LocalPrice/SpicyTokensPrice";
import { MultiLang } from "../MultiLang";
import { EmptyView } from "../UtilityComponents/EmptyView";
import { loggedInValidation } from "../Validation";
import Popup from "../common/Popup";
import "./BuyTokens.css";
import PaymentOptions from "./PaymentOptions";
import { Price } from "./Price";
import SelectedPackageDetails from "./SelectedPackageDetails";

const BuyTokens = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [showPaymentPopup, togglePaymentPopup] = useToggle();

  const spicyStore = useSpicyStore();

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("credit_card");

  const [selectedPackage, setSelectedPackage] = useState(null);

  const selected = useMemo(
    () =>
      spicyStore.tokenPackages.find((token) => token.id === selectedPackage),
    [selectedPackage, spicyStore.tokenPackages]
  );

  const onChangePaymentMethod = (e) => {
    setSelectedPaymentMethod(e.target.value);
  };

  const checkout = (id) => () => {
    setSelectedPackage(id);
    togglePaymentPopup(true);
  };

  const onProceed = async (data) => {
    if (!selectedPaymentMethod) throw new Error("Payment method not selected");

    const pkg = spicyStore.tokenPackages.find(
      (token) => token.id === selectedPackage
    );

    return await authRequest({
      url: "/store/api/members/cart/individual-checkout",
      method: "POST",
      data: {
        data: {
          product_type: PRODUCT_TYPES.TokenPurchase,
          product_id: selectedPackage,
          price: pkg?.eur,
        },
        billing: data.billing,
        checkout_method: selectedPaymentMethod,
        transaction_token: data?.token,
      },
    });
  };

  const onSuccessfullBuy = async (orderId) => {
    if (orderId) {
      const info = await spicyStore.getOrderInfo(orderId);
      spicyStore.savePurchaseRecord(info);
    }

    await spicyStore.refresh();

    togglePaymentPopup();
    props.onClose?.(false);
  };
  const redirect = () => {
    history.push("/login" + match.url);
    props.onClose?.(false);
  };

  return (
    <Popup
      open={props.show}
      onClose={props.onClose}
      showCloseButton
      maxWidth={840}
      className="buy-tokens"
    >
      {spicyStore.loading ? (
        <LoaderInside />
      ) : (
        <>
          <header>
            <h2>
              <MultiLang sl="Nakup Žetonov SG">Buy Spicy Tokens</MultiLang>
            </h2>
            <p>
              <MultiLang sl="Spicy žetoni (SG) so ekskluzivna Spicygaming valuta, katero lahko uprabljate na več načinov. Uporabite žetone za nakup iger ter ostale vsebine, kot podporo izdelovalcem iger v obliki donacij, kot napitnino za avtorje člankov, videov in še več. Kupovanje vsebine z žetoni nam omogoča znižati stroške transakcij ter nam s tem omogoča, da več denarja doseže razvijalce ter ustvarjalce. Vsi kupljeni žetoni se shranijo na vašem računu ter imajo rok veljavnosti eno leto od nakupa.">
                Spicy Tokens <SpicyTokensLogo /> are an exclusive Spicygaming
                currency that can be used in a variety of ways on the platform.
                It serves as your store credit. Use Spicy tokens to buy games or
                other content, support the developers or tip the authors of
                articles, videos, art, and more. All tokens bought are valid for
                a year after purchase. {toEuro(1)} ={" "}
                <SpicyTokensPrice tokens={CONVERSION_RATE} />
              </MultiLang>
            </p>
          </header>

          <div className="tokens">
            {!!spicyStore.tokenPackages.length ? (
              spicyStore.tokenPackages.map((token) => (
                <div className="token" key={token.id}>
                  <h3>
                    {token.title}{" "}
                    <SpicyTokensPrice tokens={token.spicy_tokens} />
                  </h3>
                  <Price
                    className="price"
                    priceInTokens={convertToSpicyTokens(token.eur)}
                  />
                  <div className="buttons">
                    {loggedInValidation() ? (
                      <SubmitButton
                        className="submit-token"
                        request={checkout(token.id)}
                        label={
                          <>
                            <span className="fa fa-shopping-cart"></span>
                            &nbsp;
                            <MultiLang sl="nakup">Continue</MultiLang>
                          </>
                        }
                      />
                    ) : (
                      <SubmitButton
                        className="login-button"
                        request={redirect}
                        label={"Login to continue"}
                      />
                    )}
                  </div>
                </div>
              ))
            ) : (
              <EmptyView text={"Couldn't load Packages"} />
            )}
          </div>
          <Popup
            open={showPaymentPopup}
            onClose={togglePaymentPopup}
            maxWidth={1080}
            defaultClose={false}
            showCloseButton
          >
            {selected && (
              <div className="TokenBuyCheckout">
                <SelectedPackageDetails
                  productId={selectedPackage}
                  productType={PRODUCT_TYPES.TokenPurchase}
                  selectedPkg={selected}
                />
                <PaymentOptions
                  onChangePaymentMethod={onChangePaymentMethod}
                  selectedPaymentMethod={selectedPaymentMethod}
                  onProceed={onProceed}
                  onSuccessfullBuy={onSuccessfullBuy}
                  selectablePaymentMethods={["coingate", "credit_card"]}
                />
              </div>
            )}
          </Popup>

          <CurrencyDisclaimer />
        </>
      )}
    </Popup>
  );
};

export default BuyTokens;
