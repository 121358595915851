import React, { useRef, useState } from "react";
import { getClass } from "src/utils/getClass";

const MARGIN = 10;

const SuggestionPanel = ({ suggestionData = [], children, className }) => {
  if (!(children instanceof Function))
    throw Error("children must be a function with suggestion as an argument");

  const [currentIndex, setCurrentIndex] = useState(0);
  const [newOffset, setNewOffset] = useState(0);
  const items = useRef(null);

  const setOffset = (direction = 0) => {
    // guards
    if (!items.current) return;
    if (currentIndex === 0 && direction === -1) return; // no back for first element
    if (currentIndex === suggestionData.length - 1 && direction === +1) return; // no next for last element

    const nextElement = items.current.children[currentIndex + direction];
    const dimensions = nextElement?.getBoundingClientRect();

    if (!dimensions) return;

    setNewOffset(
      (prev) =>
        prev + Math.floor(dimensions.width) * direction + MARGIN * direction
    );
  };
  const handleNext = () => {
    setOffset(+1);
    if (currentIndex < suggestionData.length - 1) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % suggestionData.length);
    }
  };

  const handlePrevious = () => {
    setOffset(-1);
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? suggestionData.length - 1 : prevIndex - 1
      );
    }
  };

  const suggestionStyle = {
    transform: `translateX(${-newOffset}px)`,
  };

  return (
    <div className={getClass("SuggestionPanel", className)}>
      <button className="arrow-button" onClick={handlePrevious}>
        <i className="fa fa-chevron-left"></i>
      </button>
      <div className="suggestions-wrapper">
        <div className="suggestions" ref={items} style={suggestionStyle}>
          {suggestionData.map((suggestion) => children(suggestion))}
        </div>
      </div>
      <button className="arrow-button" onClick={handleNext}>
        <i className="fa fa-chevron-right"></i>
      </button>
    </div>
  );
};

export default SuggestionPanel;
