import BlogArticle from "../ArticleDetails/BlogArticle";
import NewsArticle from "../ArticleDetails/NewsArticle";
import StaffArticle from "../ArticleDetails/StaffArticle";
import GameDetail from "../GameDetails/GameDetail";
import Preview from "./Preview";

export const GamePreview = ({ match }) => {
  return (
    <Preview>
      <GameDetail match={match} preview={true} />
    </Preview>
  );
};
export const BlogArticlePreview = ({ match }) => {
  return (
    <Preview>
      <BlogArticle match={match} preview={true} />
    </Preview>
  );
};
export const StaffArticlePreview = ({ match }) => {
  return (
    <Preview>
      <StaffArticle match={match} preview={true} />
    </Preview>
  );
};
export const NewsArticlePreview = ({ match }) => {
  return (
    <Preview>
      <NewsArticle match={match} preview={true} />
    </Preview>
  );
};
