// useIndexedDB.js

import { useEffect, useState } from "react";

const useIndexedDB = (dbName, storeName) => {
  const [db, setDb] = useState(null);

  useEffect(() => {
    const request = window.indexedDB.open(dbName, 1);

    request.onsuccess = (event) => {
      const database = event.target.result;
      setDb(database);
    };

    request.onupgradeneeded = (event) => {
      const database = event.target.result;
      database.createObjectStore(storeName);
    };
  }, [dbName, storeName]);

  const get = (key, callback) => {
    if (!db) return;

    const transaction = db.transaction([storeName], "readonly");
    const objectStore = transaction.objectStore(storeName);
    const request = objectStore.get(key);

    request.onsuccess = (event) => {
      const result = event.target.result;
      if (result) {
        callback(result);
      } else {
        callback(null);
      }
    };
  };

  const set = (key, value) => {
    if (!db) return;

    const transaction = db.transaction([storeName], "readwrite");
    const objectStore = transaction.objectStore(storeName);

    objectStore.put(value, key);
  };

  return { get, set };
};

export default useIndexedDB;
