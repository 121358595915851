import { useEffect, useRef, useState } from "react";
import useThrottleRequests from "src/hooks/useThrottleRequests";
import { BASE_CDN_URL, BASE_URL, authRequest, request } from "src/utils/Axios";
import { usePermissions } from "../../Permission";
import axios from "axios";

const useCollection = (gallery_id) => {
  const { isLogged } = usePermissions();

  if (!isLogged) throw new Error("Access Denied");

  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const [id, setId] = useState(gallery_id);

  const [images, setImages] = useState([]);
  const [premium, setPremium] = useState([]);
  const [metaInfo, setMetaInfo] = useState({});

  const [coverImageId, setCoverImageId] = useState(null);

  const [collectionInfo, setCollectionInfo] = useState({});

  const previouslyUploaded = useRef(new Set());
  const newlyUploaded = useRef(new Map()); // hash -> ID

  const { throttle, updateThrottle } = useThrottleRequests();

  // CHANGES ON THIS FILE SHOULD ALSO BE HAPPENED in src/components/CMS/SpicyArt/UploadCollection/UploadCollection.jsx

  const requestCanceler = axios.CancelToken.source();

  useEffect(() => {
    if (gallery_id) {
      getDetails();
      setId(gallery_id);
    }

    return () => {
      requestCanceler.cancel();
    };
  }, [gallery_id]);

  const changeCoverImageId = (hash) => {
    setCoverImageId(hash);
  };

  const getDetails = async () => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: "/products/api/spicyart/gallery/edit",
        params: {
          gallery_id: gallery_id,
        },
        cancelToken: requestCanceler.token,
      });

      const metaEntries = res.images.map((img) => [
        img.id,
        {
          description: img.description,
          tags: img.tags,
          id: img.id,
          aspect_ratio: img.aspect_ratio,
          game_id: img.game?.id,
          game_name: img.game?.title,
        },
      ]);

      setMetaInfo(Object.fromEntries(metaEntries));

      setCollectionInfo({
        description: res.description,
        name: res.name,
        price: res.price,
        type: res.type,
        paid: res.price > 0,
        public: res.public,
        game_id: res.game?.id,
        game_name: res.game?.title,
        allow_downloads: res.allow_downloads,
      });

      setImages(
        res.images.map((img) => ({
          id: img.id,
          hash: img.hash,
          online: true, // indicates these are not blobs
          url: img.image,
        }))
      );

      setPremium(res.images.filter((img) => img.is_paid).map((img) => img.id));
      previouslyUploaded.current = new Set(res.images.map((img) => img.hash));
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    images,
    premium,
    metaInfo,
    coverImageId,
    setImages,
    setPremium,
    setMetaInfo,
    changeCoverImageId,
    previouslyUploaded,
    throttle,
    updateThrottle,
    collectionInfo,
    setCollectionInfo,
    loading,
    error,
    id,
    setId,
    newlyUploaded,
  };
};

export const useGalleryPublic = (gallery_id, noCache = false) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const [images, setImages] = useState([]);
  const [galleryInfo, setGalleryInfo] = useState({});

  useEffect(() => {
    if (gallery_id) getDetails(gallery_id);
  }, [gallery_id]);

  const getDetails = async (id) => {
    try {
      setLoading(true);
      const res = await request({
        url: "/products/api/spicyart/gallery",
        baseURL: noCache ? BASE_URL : BASE_CDN_URL,
        params: {
          gallery_id: id,
        },
      });

      setGalleryInfo({
        description: res.description,
        name: res.name,
        price: res.price,
        type: res.type,
        paid: true,
      });

      setImages(res.images);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const refreshGallery = async () => {
    await getDetails(gallery_id);
  };

  return {
    images,
    galleryInfo,
    loading,
    error,
    refreshGallery,
  };
};

export default useCollection;
