import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RequestError from "src/errors/RequestError";
import { request } from "src/utils/Axios";
import { getGotoRoute } from "src/utils/keepConsistentRouteAfterLogin";
import { Loader } from "./Common";
import { SuccessIcon } from "./common/social";
import "./css/EmailVerification.css";
import { storeCredentials } from "./Login";

const EmailVerification = () => {
  const { code } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    sendData(code);
  }, [code]);

  const sendData = async (code) => {
    try {
      const res = await request({
        url: `/api/members/verify-account`,
        method: "POST",
        data: { token: code },
        withCredentials: true,
      });

      storeCredentials({ username: res.username });
      setSuccess(res.success);

      setTimeout(() => {
        window.location.href = getGotoRoute("/porn-games/1");
      }, 1000);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loader />;

  return (
    <main id="email-verification" className="login">
      <div className="inner">
        {error && <RequestError error={error} />}
        {success && (
          <>
            <h1>Congratulations!</h1>
            <div className="image">
              <SuccessIcon />
            </div>
            <p>Your Account has been successfully verified.</p>
          </>
        )}
      </div>
    </main>
  );
};

export default EmailVerification;
