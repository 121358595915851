import { useMemo, useState } from "react";
import relativeDate from "relative-date";
import { Input } from "src/components/Member/General";
import RequestError from "src/errors/RequestError";
import useDebouncedCallback from "src/hooks/useDebounceCallback";
import { convertToSpicyTokens } from "src/utils/currency-format";
import useSWRInfinite from "swr/infinite";
import { toEuro } from "../../../../utils/number-format";
import { withoutLastPage } from "../../../../utils/withoutLastPage";
import { LoadMore, sort } from "../../../Common";
import { fetcher } from "../../Cms";
import Table, { getColumn } from "../../Table/Table";
import { toSpicyTokens } from "../../Users/Withdraw";
import { ordersOptions } from "../Options";

const Section = (type) =>
  function Section() {
    const [search, setSearch] = useState("");
    const onSearchChange = useDebouncedCallback((text) => setSearch(text), 700);

    const { data, error, setSize, mutate } = useSWRInfinite(
      (index) => [
        `${window.domainName}/store/api/orders/all-orders/list?page=${
          index + 1
        }&type=${type}&search_term=${search}`,
        search,
      ],
      fetcher,
      { revalidateOnFocus: false }
    );

    const orders = withoutLastPage(data?.flat?.() ?? []);

    const options = ordersOptions(type);

    const columns = useMemo(
      () => [
        getColumn("STATUS", { sortKey: "status", accessKey: "status" }),
        getColumn("ID", { sortKey: "order_id", accessKey: "order_id" }),
        getColumn("Invoice ID", {
          sortKey: "invoice_id",
          accessKey: "invoice_id",
        }),
        getColumn("AMOUNT", {
          sortKey: "amount",
          accessFn: (order) => {
            const amountEUR = order.amount + (order.vat_amount ?? 0);
            if (type === "tokens") {
              return toSpicyTokens(convertToSpicyTokens(amountEUR));
            }
            return toEuro(amountEUR);
          },
        }),
        getColumn("PURCHASER", {
          sortKey: "purchaser",
          accessKey: "purchaser",
        }),
        getColumn("DATE", {
          sortKey: "creation_date",
          accessFn: (order) => relativeDate(new Date(order.creation_date)),
        }),
      ],
      [type]
    );

    return (
      <div>
        <div className="search">
          <Input callback={onSearchChange} placeholder="Search Order ID" />
          <i className="icon fa fa-search"></i>
        </div>
        <Table
          columnCSSConfig="1fr 3fr 1fr 1fr 1fr 1fr"
          columns={columns}
          data={orders}
          getOptions={() => options}
          getPopupData={(order) => ({
            show_transactions: {
              transactions: order.transactions,
              order_id: order.order_id,
            },
            invoice_regen: {
              type,
              order_id: order.order_id,
            },
          })}
          refreshData={() => mutate(orders, true)}
          onSort={(key, order) => mutate(sort(key, orders, order), false)}
        />
        <RequestError error={error?.message} />
        <LoadMore onClick={() => setSize((prev) => prev + 1)} />
      </div>
    );
  };

export default Section;
