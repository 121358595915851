import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import { authRequest } from "../../utils/Axios";
import { LoaderInside } from "../Common";
import { CancelIcon } from "../common/social";
import Actions from "./Actions";

import "./callback-pages.css";

const PaymentError = () => {
  const query = useQuery();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const orderID = query.get("order_id");

  useEffect(() => {
    getOrderInfo(orderID);
  }, [orderID]);

  const getOrderInfo = async (order_id) => {
    try {
      const res = await authRequest({
        url: `/store/api/orders/status`,
        params: {
          order_id,
        },
      });
      if (res.reason) setError(res.reason);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!orderID) return <Redirect to="/" />;
  if (loading) return <LoaderInside />;

  return (
    <main className="payment-success">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="icon">
        <CancelIcon />
      </div>
      <h1>Error occured on Order #{orderID}</h1>
      <p>{error}</p>

      <Actions />
    </main>
  );
};

export default PaymentError;
