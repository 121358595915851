import { ErrorBoundary } from "@sentry/react";
import { Helmet } from "react-helmet";
import { Redirect, Switch } from "react-router-dom";
import SentryRoute from "src/customRoute";
import { useToggle } from "src/hooks/useToggle";
import { RoleProvider, useRoleInfo } from "../CMS/RoleContext";
import { LoaderInside } from "../Common";
import { ErrorBoundaryFallback } from "../ErrorBoundary";
import Error404 from "../Errors";
import Notification from "../Notifications";
import ContentCreator from "./ContentCreator";
import EarnFreeTokens from "./EarnFreeTokens";
import FavDevelopers from "./FavDevelopers";
import General from "./General";
import Orders from "./Orders";
import { MemberSecurity } from "./Security";
import SideMenu from "./SideMenu";
import { TokenTransfers } from "./TokenTransfers";
import "./css/AddFav.css";
import "./css/Member.css";

const Member = ({ match }) => {
  const { loggedIn, loading } = useRoleInfo();
  const [showMenu, toggleShowMenu] = useToggle(false);

  if (loading) return <LoaderInside />;

  if (!loggedIn) return <Redirect to="/login/member" />;

  return (
    <RoleProvider>
      <main className="member" id="member">
        <Helmet>
          <title>Member area - Spicygaming</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="inner">
          <SideMenu show={showMenu} toggleShow={toggleShowMenu} links={links} />
          <ErrorBoundary
            beforeCapture={(scope) => {
              scope.setTag("location", "Member Page");
            }}
            fallback={ErrorBoundaryFallback}
          >
            <div className="body">
              <header onClick={toggleShowMenu}>
                {
                  links.find((link) =>
                    window.location.pathname.includes(link.link)
                  )?.header
                }
              </header>
              <div className="content">
                <Switch>
                  <Redirect exact path={match.url} to={links[0]?.link} />
                  {links.map((link, i) => (
                    <SentryRoute
                      key={i}
                      path={link.link}
                      component={link.component}
                      {...link.props}
                    />
                  ))}
                  <SentryRoute path={match.url + "*"}>
                    <Error404 />
                  </SentryRoute>
                </Switch>
              </div>
            </div>
          </ErrorBoundary>
        </div>
      </main>
    </RoleProvider>
  );
};

const links = [
  {
    header: "GENERAL SETTINGS",
    link: "/member/general",
    icon: "fa-user",
    component: General,
    props: { exact: true },
  },
  {
    header: "MY LIBRARY",
    link: "/library",
    icon: "fa-gamepad",
    component: () => null,
    props: { exact: true },
  },
  {
    header: "EARN FREE TOKENS",
    link: "/member/earn-free-tokens",
    icon: "fa-money",
    isNew: true,
    component: EarnFreeTokens,
    props: { exact: true },
  },
  {
    header: "FOLLOWED TEAMS",
    link: "/member/followed-developer-teams",
    icon: "fa-code",
    component: FavDevelopers,
    props: { exact: true },
  },
  {
    header: "NOTIFICATION SETTINGS",
    link: "/member/notifications",
    icon: "fa-bell",
    component: Notification,
    props: { exact: true },
  },
  {
    header: "CONTENT CREATOR",
    link: "/member/content-creator",
    icon: "fa-snowflake-o",
    component: ContentCreator,
    props: {},
  },
  // {
  //   header: {en: "HIRE/GET HIRED", sl: ""},
  //   link: "/member/hire",
  //   icon: "fa-briefcase",
  //   component: Hire,
  //   props: { exact: true },
  // }, // removed as not functioning yet

  {
    header: "ORDERS",
    link: "/member/orders",
    icon: "fa-money",
    component: Orders,
    props: { exact: false },
  },
  {
    header: "SECURITY",
    link: "/member/security",
    icon: "fa-lock",
    component: MemberSecurity,
    props: { exact: false },
  },
  {
    header: "TOKEN TRANSFERS",
    link: "/member/token-transfers",
    icon: "fa-exchange",
    component: TokenTransfers,
    props: { exact: true },
  },
];

export default Member;
