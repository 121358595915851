import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { dateFormatForDateInput } from "src/utils/date-format";
import Input from "src/v2/components/form-inputs/Input";
import Form from "src/v2/components/form/Form";
import SubmitButton from "src/v2/components/SubmitButton";
import { createBundle, editBundle } from "src/v2/services/gameBundlesService";
import { z } from "zod";
import { useTeamID } from "../../../RoleContext";

const bundleInfoSchema = z.object({
  name: z.string(),
  expiry: z
    .string()
    .transform((s) => new Date(s))
    .optional(),
  start_date: z
    .string()
    .transform((s) => new Date(s))
    .optional(),
});

export const BundleInfo = ({
  editMode,
  moveTab,
  bundleId,
  setBundleId,
  defaultValues = {},
}) => {
  const teamId = useTeamID();

  const getNextDay = (howManyDays = 1) => {
    const today = new Date();
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + howManyDays);
    return nextDay;
  };

  const form = useForm({
    resolver: zodResolver(bundleInfoSchema),
    defaultValues: {
      name: defaultValues.name,
      start_date: dateFormatForDateInput(defaultValues.start_date),
      expiry: dateFormatForDateInput(defaultValues.expiry),
    },
  });

  const onSave = async () => {
    try {
      const isValid = await form.trigger();
      if (!isValid) throw new Error("Invalid Inputs");
      const data = form.getValues();

      if (editMode) {
        await editBundle({
          bundleId,
          name: data.name,
          startDate: data.start_date,
          expirationDate: data.expiry,
        });
        toast.success("Sale Updated");
      } else {
        const res = await createBundle({
          teamId,
          name: data.name,
          startDate: data.start_date,
          expirationDate: data.expiry,
        });
        setBundleId(res.bundle_id);
        moveTab("products");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="grid gap-5">
      <p className="text-neutral-300">
        Bundles allow you to group multiple products into a single, discounted
        offer. To create a bundle, start by naming it and optionally setting its
        availability period. Next, select the products you want to include in
        the bundle and specify the discount for each one.
      </p>
      <p className="text-neutral-300">
        When a customer purchases the bundle, they will receive all the products
        included in the offer.
      </p>

      <Form form={form}>
        <div className="grid md:grid-cols-2 gap-5">
          <Form.Item name="name" label="Bundle Name" className="col-span-full">
            <Input />
          </Form.Item>

          <Form.Item name="start_date" label="Start Date (optional)">
            <Input type="date" min={dateFormatForDateInput(getNextDay(1))} />
          </Form.Item>
          <Form.Item name="expiry" label="Expiration Date (optional)">
            <Input type="date" min={dateFormatForDateInput(getNextDay(2))} />
          </Form.Item>
        </div>
      </Form>

      <SubmitButton onClick={onSave} className="place-self-end">
        {editMode ? "Save Changes" : "Create Bundle"}
      </SubmitButton>
    </div>
  );
};
