export const imageLengthsCheck = ({
  inputRange = [[]],
  outputRange = [[]],
}) => {
  const output = inputRange.map(
    (input, index) => input?.length === outputRange[index]?.length
  );

  return output.every((elem) => elem === true);
};
