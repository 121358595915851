import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Trumbowyg from "react-trumbowyg";
import { ASPECT_RATIO } from "src/components/Video/Player/Player";
import { generateURL } from "src/components/resizeImages";
import SomethingWentWrong from "src/errors/SomethingWentWrong";
import RequestError from "../../../errors/RequestError";
import { authRequest } from "../../../utils/Axios";
import { getClass } from "../../../utils/getClass";
import { toFormData } from "../../../utils/toFormData";
import { withoutLastPage } from "../../../utils/withoutLastPage";
import { Loader, LoaderInside } from "../../Common";
import { Input, URL_REGEX } from "../../Member/General";
import { authorizedRequest } from "../../Validation";
import VideoPlayer from "../../VideoPlayer";
import { vastPullzones } from "../../VideoPlayer/pullzones";
import { SubmitButton } from "../Common";
import useCollection from "../SpicyArt/UploadCollection/useCollectionInfo";
import VideoSelect from "../Videos/VideoSelect";
import "../css/EditorPopups.css";
import { Article, Game, Member, ObjectLayout } from "./EditorBlocks";
import ImageGallery from "src/components/SpicyArt/Collection";
import { useToggle } from "src/hooks/useToggle";
import Popup from "src/components/common/Popup";
import { EditCollection } from "../SpicyArt/UploadCollection/EditCollection";

/** Props for Popups
 * @typedef {Object} Props - props each popup has
 * @property {String} id - id of the popup
 * @property {(id, name, value) => void} addItem - to set objects to state args(id, name, value)
 * @property {Function} close  to close the popup manually
 * @property {Boolean} edit - if its entered in the editor mode or not
 * @property {Object} [object] - only available if `edit` is true
 * @property {Boolean} array - indicates all edits need to be in one array
 * @property {any} props - Unique props for each component defined in  `./EditorOptions.js`
 */

/**
 * @param {Props} props
 * @returns
 */

const ImageSelector = (props) => {
  const {
    id,
    addItem,
    close,
    edit,
    object,
    customData,
    type,
    refreshImages,
    images,
  } = props;

  const [showGallery, toggleGallery] = useToggle();

  const addImage = (image) => {
    addItem(id, type, image);
    close();
  };

  const onCloseGalleryPopup = () => {
    refreshImages?.();
    toggleGallery();
  };

  const selectedImage = edit ? object.content : null;
  if (images.length <= 0) {
    return (
      <div id="image-choose" className="instructions">
        <div>
          You don't have images uploaded yet upload them in the
          <h4>Media Gallery</h4>
        </div>
        <SubmitButton
          label={"Add images"}
          className="add-button"
          request={toggleGallery}
        />
        <Popup open={showGallery} onClose={onCloseGalleryPopup} showCloseButton>
          <EditCollection
            selectedPages={["images"]}
            firstPageId="images"
            onSaveChanges={() => onCloseGalleryPopup()}
            defaultId={customData.media_gallery}
          />
        </Popup>
      </div>
    );
  }

  return (
    <section className="object-selector" id="image-choose">
      <legend>
        {!edit ? "Choose Image from below" : "Click Image to change"}
      </legend>
      <p>
        Use this to add an image, mind that you need to first upload them to the{" "}
        <b>Media gallery</b> below and add them by click the Add image and
        selecting the image from the list
      </p>
      <br />
      <div className="images">
        {images.map((image) => {
          const isSelected = image.id === selectedImage;
          return (
            <div
              className={getClass("image", isSelected && "selected")}
              key={image.id}
              onClick={() => addImage(image.id)}
            >
              <div className="cover">
                <img src={image.image} alt={image.description} />
              </div>
              <p title={image.description}>{image.description}</p>
            </div>
          );
        })}
      </div>
      <SubmitButton
        label="Add Images"
        className="add-button"
        request={toggleGallery}
      />

      <Popup open={showGallery} onClose={onCloseGalleryPopup}>
        <EditCollection
          selectedPages={["images"]}
          firstPageId="images"
          defaultId={customData.media_gallery}
          onSaveChanges={() => onCloseGalleryPopup()}
        />
      </Popup>
    </section>
  );
};

/**
 * @param {Props} props
 * @returns
 */
const VideoSelector = (props) => {
  const { id, addItem, close, edit, object, type } = props;
  const [video, setVideo] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (edit) getDetails(object.content?.video_id);
  }, [edit]);

  const getDetails = async (id) => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: "/community/community-videos/video/edit",
        params: { video_id: id },
      });
      setVideo(res);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getVideoID = (video_id) => {
    addItem(id, type, { video_id });
    close?.();
  };
  if (edit) {
    return (
      <section className="video object-selector">
        {loading ? (
          <LoaderInside />
        ) : (
          <>
            <RequestError error={error} />
            <legend>{video.title}</legend>
            <VideoPlayer
              options={{ aspectRatio: "16:9" }}
              vastZone={vastPullzones.default}
              videoID={video.file?.bunny_id}
              folderID={video.file?.library_id}
              pullzoneID={video.file?.pullzone}
              thumbnailName={generateURL(
                720,
                parseInt(720 / ASPECT_RATIO),
                video.thumbnail
              )}
            />
          </>
        )}
      </section>
    );
  }

  return <VideoSelect getVideoID={getVideoID} />;
};

/**
 * @param {Props} props
 * @returns
 */
const TextEditor = (props) => {
  const { id, addItem, close, edit = false, object, type } = props;
  const [html, setHtml] = useState("");
  const [error, setError] = useState(null);
  const submitHandle = () => {
    if (html.length <= 0) {
      setError("Type Description or edit current one to save");
    } else {
      addItem(id, type, html);
      close();
    }
  };

  return (
    <section className="object-selector" id="text-editor">
      <legend>Type your description here</legend>
      <p>This will add text input it into the text editor</p>

      <Trumbowyg
        id="react-trumbowyg"
        buttons={[
          ["formatting"],
          ["bold", "italic", "underline", "strikethrough"],
          ["link"],
          ["horizontalRule"],
          "btnGrp-justify",
          "btnGrp-lists",
        ]}
        prefix="modern-ui"
        data={edit ? object.content : ""}
        value={true}
        placeholder="This is my awesome description of my awesome game. 
            It tells players about the story, graphics, sound and gameplay. And more....
            "
        onChange={(e) => {
          setHtml(e.target.innerHTML);
        }}
        defaultLinkTarget={"_blank"}
        removeformatPasted
        tagsToRemove={["script"]}
        semantic
        urlProtocol
        autogrow
        minimalLinks
      />
      <RequestError error={error} />
      <SubmitButton
        label={"Save Changes"}
        request={submitHandle}
        className="add-button"
      />
    </section>
  );
};

/**
 * @param {Props} props
 * @returns
 */

const GameSelector = (props) => {
  const { id, addItem, close, edit, object, type, array } = props;

  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [addGamesPage, setAddGamesPage] = useState(edit ? false : true);

  const [error, setError] = useState(null);
  const [games, setGames] = useState([]);
  const [selectedGames, setSelectedGames] = useState(
    edit ? object.content : []
  );
  useEffect(() => {
    if (array === false && !!selectedGames.length) {
      addItem(id, type, [selectedGames[0]]);
      if (!edit) close();
    }
  }, [selectedGames]);

  const submit = async () => {
    setError(null);
    setLoader(true);
    try {
      const games = await authRequest({
        url: "/products/api/games/search-games",
        params: { title: query, option: "Browse" },
        method: "GET",
      });
      setGames(withoutLastPage(games));

      if (games.length <= 0) {
        setError(`Nothing found for "${query}"`);
      }
    } catch (error) {
      setError("Error occured while searching");
    } finally {
      setLoader(false);
    }
  };
  const addGames = () => {
    if (selectedGames.length > 0) {
      addItem(id, type, selectedGames);
      close();
    } else {
      setError("Select Games to add");
    }
  };
  const pageToggle = (e) => {
    setAddGamesPage((prev) => !prev);
    e.target.scrollIntoView();
  };

  return (
    <section id="game-selector" className="object-selector ">
      <legend>Add Game</legend>
      <p>
        This allows you to connect any games published on spicygaming to the
        article
        <br />
        <br />
      </p>
      {loader && <Loader />}

      <ObjectLayout
        title={"Games"}
        searchPage={addGamesPage}
        pageToggle={pageToggle}
        addObjectsFunction={addGames}
        valueGetter={(e) => setQuery(e.target.value)}
        searchFunction={submit}
        isArray={array}
      >
        {addGamesPage
          ? games.map((game, i) => (
              <Game
                key={i}
                game={game}
                setSelectedGames={setSelectedGames}
                isAdded={
                  selectedGames.find(
                    (selectedGame) => selectedGame.id === game.id
                  )
                    ? true
                    : false
                }
              />
            ))
          : selectedGames.map((game, i) => (
              <Game
                key={i}
                game={game}
                setSelectedGames={setSelectedGames}
                isAdded={true}
              />
            ))}
      </ObjectLayout>
      <br />
      {error && <div className="error">{error}</div>}
    </section>
  );
};
export const GallerySelector = (props) => {
  const { id, addItem, close, edit, object, type, array } = props;

  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [addGalleryPage, setAddGalleryPage] = useState(edit ? false : true);

  const [error, setError] = useState(null);
  const [galleries, setGalleries] = useState([]);
  const [selectedGalleries, setSelectedGalleries] = useState(
    edit ? object.content : []
  );
  useEffect(() => {
    if (array === false && !!selectedGalleries.length) {
      addItem(id, type, [selectedGalleries[0]]);
      if (!edit) close();
    }
  }, [selectedGalleries]);

  const submit = async () => {
    setError(null);
    setLoader(true);
    try {
      const galleries = await authRequest({
        url: "/products/api/spicyart/galleries/search",
        params: { search_term: query, all: true },
        method: "GET",
      });
      setGalleries(withoutLastPage(galleries));

      if (galleries.length <= 0) {
        setError(`Nothing found for "${query}"`);
      }
    } catch (error) {
      setError("Error occured while searching");
    } finally {
      setLoader(false);
    }
  };
  const addGalleries = () => {
    if (selectedGalleries.length > 0) {
      addItem(id, type, selectedGalleries);
      close();
    } else {
      setError("Select Galleries to add");
    }
  };
  const pageToggle = (e) => {
    setAddGalleryPage((prev) => !prev);
    e.target.scrollIntoView();
  };

  return (
    <section id="game-selector" className="object-selector ">
      <legend>Add Gallery</legend>

      {loader && <Loader />}

      <ObjectLayout
        title={"Galleries"}
        searchPage={addGalleryPage}
        pageToggle={pageToggle}
        addObjectsFunction={addGalleries}
        valueGetter={(e) => setQuery(e.target.value)}
        searchFunction={submit}
        isArray={array}
      >
        {addGalleryPage
          ? galleries.map((gallery) => {
              const isAdded = selectedGalleries.find(
                (selected) => selected.id === gallery.id
              );
              return (
                <div key={gallery.id}>
                  <ImageGallery collection={gallery} />

                  <SubmitButton
                    label={isAdded ? "Remove" : "Add"}
                    icon={isAdded ? "fa-minus" : "fa-plus"}
                    type={isAdded ? "secondary" : "primary"}
                    request={() => {
                      if (isAdded) {
                        setSelectedGalleries((prev) =>
                          prev.filter((selected) => selected.id !== gallery.id)
                        );
                      } else {
                        setSelectedGalleries((prev) => [...prev, gallery]);
                      }
                    }}
                  />
                </div>
              );
            })
          : selectedGalleries.map((gallery) => (
              <div key={gallery.id}>
                <ImageGallery collection={gallery} />

                <SubmitButton
                  label={"Remove"}
                  icon={"fa-minus"}
                  type={"secondary"}
                  request={() => {
                    setSelectedGalleries((prev) =>
                      prev.filter((selected) => selected.id !== gallery.id)
                    );
                  }}
                />
              </div>
            ))}
      </ObjectLayout>
      <br />
      {error && <div className="error">{error}</div>}
    </section>
  );
};
/**
 * @param {Props} props
 * @returns
 */
const TeamSelector = (props) => {
  const { id, addItem, close, edit, object, type } = props;
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [addTeamsPage, setAddTeamsPage] = useState(edit ? false : true);
  const [teams, setTeams] = useState([]);
  const [error, setError] = useState(null);
  const [selectedTeams, setSelectedTeams] = useState(
    edit ? object.content : []
  );

  const submit = async () => {
    setLoader(true);
    setError(null);

    try {
      const teams = await authRequest({
        url: "/api/content-creator-teams/search",
        method: "GET",
        params: {
          name: query,
        },
      });

      setTeams(teams);
      setLoader(false);
      if (teams.length <= 0) {
        setError(`Nothing found for "${query}"`);
      }
    } catch (err) {
      setError("Error Occured while Searching");
      setLoader(false);
    }
  };
  const addTeams = () => {
    if (selectedTeams.length > 0) {
      addItem(id, type, selectedTeams);
      close();
    } else {
      setError("No Teams to add");
    }
  };
  const pageToggle = (e) => {
    setAddTeamsPage((prev) => !prev);
    e.target.scrollIntoView();
  };
  return (
    <div id="create-team">
      <div className="team-members">
        <section className="object-selector" id="team-selector">
          <legend>Add Teams</legend>
          <p>
            This allows you to connect any publisher teams on spicygaming to the
            article
          </p>
          <br />
          {loader && <Loader />}
          <RequestError error={error} />
          <ObjectLayout
            title={"Teams"}
            searchPage={addTeamsPage}
            pageToggle={pageToggle}
            addObjectsFunction={addTeams}
            valueGetter={(e) => setQuery(e.target.value)}
            searchFunction={submit}
          >
            {addTeamsPage
              ? teams.map((team, i) => (
                  <Member
                    key={i}
                    team={team}
                    setSelectedTeams={setSelectedTeams}
                    isAdded={
                      selectedTeams.find(
                        (selectedTeam) => selectedTeam.id === team.id
                      )
                        ? true
                        : false
                    }
                  />
                ))
              : selectedTeams.map((team, i) => (
                  <Member
                    key={i}
                    team={team}
                    setSelectedTeams={setSelectedTeams}
                    isAdded={true}
                  />
                ))}
          </ObjectLayout>
        </section>
      </div>
    </div>
  );
};

/**
 * @param {Props} props
 * @returns
 */
const ArticleSelector = (props) => {
  const { id, addItem, close, edit, object, url, type, instructions, array } =
    props;
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [addArticlePage, setAddArticlePage] = useState(edit ? false : true);
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);
  const [selectedArticles, setSelectedArticles] = useState(
    edit ? object.content : []
  );
  useEffect(() => {
    if (array === false && !!selectedArticles.length) {
      addItem(id, type, [selectedArticles[0]]);
      if (!edit) close();
    }
  }, [selectedArticles]);

  const pageToggle = (e) => {
    setAddArticlePage((prev) => !prev);
    e.target.scrollIntoView();
  };
  const submit = async () => {
    setError(null);
    setLoader(true);

    try {
      const articles = await authorizedRequest(
        {
          url: window.domainName + url + query,
          method: "GET",
        },
        {}
      );
      setArticles(articles);
      setLoader(false);

      if (articles.length <= 0) {
        setError(`Nothing found for "${query}"`);
      }
    } catch (error) {
      setLoader(false);
      setError("Error occured while searching");
    }
  };

  const addArticles = () => {
    if (selectedArticles.length > 0) {
      addItem(id, type, selectedArticles);
      close();
    } else {
      setError("No Articles to add");
    }
  };
  return (
    <section className="object-selector article">
      {loader && <Loader />}
      <legend>Add Articles</legend>
      <p>{instructions}</p>
      <br />
      {loader && <Loader />}
      {error && <div className="error">{error}</div>}
      <ObjectLayout
        title={"Articles"}
        searchPage={addArticlePage}
        pageToggle={pageToggle}
        addObjectsFunction={addArticles}
        valueGetter={(e) => setQuery(e.target.value)}
        searchFunction={submit}
      >
        {addArticlePage
          ? articles.map((article, i) => (
              <Article
                key={i}
                article={article}
                setSelectedArticles={setSelectedArticles}
                isAdded={
                  selectedArticles.find(
                    (selectedArticle) => selectedArticle.id === article.id
                  )
                    ? true
                    : false
                }
              />
            ))
          : selectedArticles.map((article, i) => (
              <Article
                key={i}
                article={article}
                setSelectedArticles={setSelectedArticles}
                isAdded={true}
              />
            ))}
      </ObjectLayout>
    </section>
  );
};

export const CallToAction = ({ addItem, id, type, close, object, edit }) => {
  const [link, setLink] = useState();
  const [error, setError] = useState();
  const [file, setFile] = useState();
  const [preview, setPreview] = useState({});
  useEffect(() => {
    if (edit) getUploadedImage();
  }, [edit]);

  const getUploadedImage = async () => {
    try {
      const res = await authRequest({
        url: "/news/api/news/cta-element/upload-cta",
        params: {
          image_id: object.content,
        },
      });
      setPreview(res);
    } catch (error) {
      setError(error.message);
    }
  };

  const getImage = (e) => {
    const image = e.target.files[0];
    setFile(image);
  };
  const uploadFile = async () => {
    try {
      const res = await authRequest({
        url: "/news/api/news/cta-element/upload-cta",
        method: "POST",
        data: toFormData({
          url: link,
          image: file,
        }),
      });
      addItem(id, type, res.image_id);
      close();
    } catch (error) {
      setError(error.message);
    }
  };
  return (
    <section className="CTA object-selector">
      <legend>Add Call to Action</legend>
      <p>Add a image when clicked a link will be opened</p>

      {!file && !edit ? (
        <input type="file" onChange={getImage} accept="image/*" />
      ) : (
        <div className="uploaded_image">
          <img
            src={edit ? preview.image_url : URL.createObjectURL(file)}
            alt="uploaded_cta"
          />
          <button onClick={() => setFile(null)} className="popup-close-button">
            <span className="fa fa-close"></span>
          </button>
        </div>
      )}

      {edit && (
        <p>
          <a
            href={preview.redirect_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {preview.redirect_url}
          </a>
        </p>
      )}
      {!edit && (
        <>
          <Input
            label="Redirect Link"
            regex={URL_REGEX}
            callback={setLink}
            value={link}
          />
          <RequestError error={error} />
          <SubmitButton
            label={"Add CTA"}
            request={uploadFile}
            disabled={!link || !file}
          />
        </>
      )}
    </section>
  );
};
export const Ad = ({ id, type, addItem, close }) => {
  useEffect(() => {
    addItem(id, type, 93598);
    close();
    toast.success("Advertisment Added");
  }, []);
  return null;
};

export const EmbedURL = ({ id, type, addItem, close, edit, object }) => {
  const [iframe, setIframe] = useState(edit ? object.content : "");
  const [valid, setValid] = useState(undefined);

  const ref = useRef();
  useLayoutEffect(() => {
    const isIframe = ref.current?.firstElementChild?.tagName === "IFRAME";
    const childCount = ref.current?.children?.length;

    if (isIframe && childCount === 1) setValid(true);
    else setValid(false);
  }, [iframe]);

  const onChange = (code) => setIframe(code);

  const submit = () => {
    addItem(id, type, iframe);
    close();
  };
  return (
    <section className="embed-url object-selector">
      <legend>Add Embed code</legend>
      <p>You can add any embed codes to your articles.</p>
      <p>Ex:- Youtube videos</p>

      {!edit && (
        <>
          <Input
            controlledValue={iframe}
            callback={onChange}
            label="Paste your Embed code"
          />
          {valid === false && (
            <RequestError
              error={
                <>
                  Only one <code>{"<iframe />"}</code> can be used
                </>
              }
            />
          )}
        </>
      )}
      <h2>Preview</h2>
      <div className="preview">
        {iframe.length <= 0 ? (
          <div className="no_preview">
            <span className="fa fa-image"></span>
            <span>Nothing to preview</span>
          </div>
        ) : (
          <div ref={ref} dangerouslySetInnerHTML={{ __html: iframe }}></div>
        )}
      </div>

      {!edit && <SubmitButton label="Add Embed Item" request={submit} />}
    </section>
  );
};

export {
  ArticleSelector,
  GameSelector,
  ImageSelector,
  TeamSelector,
  TextEditor,
  VideoSelector,
};
