import { useEffect, useMemo, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import useCheckForURLBlock from "src/hooks/useCheckForURLBlock";
import { useSpicyStore } from "src/providers/SpicyStoreProvider";
import useSWRInfinite from "swr/infinite";
import { Events, customEvents } from "../constants/socket-events";
import { useSocketEvent } from "../hooks/useSocketEvent";
import { useToggle } from "../hooks/useToggle";
import {
  getLanguageInfo,
  useChangeLanguage,
  useLanguage,
} from "../providers/LanguageProvider";
import { useSocket } from "../providers/SocketProvider";
import { authRequest } from "../utils/Axios";
import { Badge } from "./Badge";
import BuyTokens from "./BuyTokens";
import { SubmitButton } from "./CMS/Common";
import { Roles, useRoleInfo } from "./CMS/RoleContext";
import { Chip } from "./Chip";
import Toast, { NotificationItem, markAllRead } from "./Notifications/Toast";
import { getNotification } from "./Notifications/notification-types";
import { Avatar } from "./common/Avatar";
import MenuPopup from "./common/Popup/MenuPopup";
import "./css/Nav.css";
import { generateURL } from "./resizeImages";
import FeatureFlag from "./FeatureFlag";
import { Features } from "src/features/allFeatures";
import SpicyTokensPrice, {
  SpicyTokensLogo,
} from "./LocalPrice/SpicyTokensPrice";
import { getUserNotifications } from "src/v2/services/notificationService";
import { withoutLastPage } from "src/utils/withoutLastPage";

export const fetcher = async (url) => {
  const res = await authRequest({ url, method: "GET" }, []);
  return res;
};

const Login = () => (
  <div className="user">
    <div className="avatar">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33.318"
        height="33.317"
        viewBox="0 0 33.318 33.317"
      >
        <g id="profile-user" transform="translate(0 -0.001)">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M16.659,0A16.658,16.658,0,1,0,33.318,16.659,16.659,16.659,0,0,0,16.659,0Zm0,4.981a5.51,5.51,0,1,1-5.509,5.51A5.51,5.51,0,0,1,16.659,4.982Zm0,23.98a12.226,12.226,0,0,1-7.961-2.936,2.348,2.348,0,0,1-.824-1.785,5.557,5.557,0,0,1,5.584-5.555h6.412a5.549,5.549,0,0,1,5.575,5.555,2.342,2.342,0,0,1-.823,1.784A12.222,12.222,0,0,1,16.655,28.962Z"
            fill="#ccc"
          />
        </g>
      </svg>
    </div>

    <Link to="/login">
      <div className="part">login</div>
    </Link>
    <div className="line"></div>
    <Link to="/register">
      <div className="part">register</div>
    </Link>
  </div>
);

const LoggedIn = ({ name }) => {
  const [profile, setProfile] = useState({});
  const socket = useSocket();
  const { tokens } = useSpicyStore();
  const [showNotifications, toggleNotifications] = useToggle();
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const notificationIcon = useRef(null);
  const roleInfo = useRoleInfo();

  useEffect(() => {
    getProfileInfo();

    document.addEventListener(customEvents.PROFILE_CHANGED, getProfileInfo);
    return () => {
      document.removeEventListener(
        customEvents.PROFILE_CHANGED,
        getProfileInfo
      );
    };
  }, []);

  const { data, setSize, mutate } = useSWRInfinite(
    (index) => {
      return ["get-notifications", index];
    },
    (_, index) => getUserNotifications(index + 1),
    { revalidateOnFocus: false }
  );

  const notifications = data ? withoutLastPage(data.flat()) : [];

  useEffect(() => {
    if (data)
      setUnreadNotificationsCount(
        data.flat().filter((n) => n.is_read === false).length
      );
  }, [data]);

  useSocketEvent(Events.NOTIFICATION, (notification, callback) => {
    const notify = getNotification(notification.type);
    const defaultClose = !notify?.actions?.length;

    toast(<Toast notification={notification} />, {
      toastId: notification.id,
      type: notification.data?.notification_type ?? "info",
      onClose: () => {
        if (!notification.is_read)
          socket.emit(Events.READ_NOTIFICATION, notification.id);
      },
    });

    setUnreadNotificationsCount((prev) => prev + 1);
    callback?.(notification.id);
  });

  const markRead = async () => {
    await markAllRead();
    toggleNotifications();
    mutate([]);
  };

  const getProfileInfo = async () => {
    try {
      const res = await authRequest({
        url: "/api/members/profile",
      });
      setProfile(res);
    } catch (error) {}
  };

  return (
    <div className="user">
      <Badge count={unreadNotificationsCount} className="badge notifications">
        <button
          onClick={toggleNotifications}
          ref={notificationIcon}
          className={"part"}
        >
          <div data-tooltip="Notifications"></div>
          <span className="fa fa-bell"></span>
        </button>
      </Badge>

      <div className="line"></div>

      <button className="part">
        <div>
          <div className="username">
            <Avatar
              width={40}
              src={generateURL(100, 100, profile.profile_picture)}
              name={name}
            />
            <span>{name}</span>
          </div>
          <FeatureFlag feature={Features.TOKEN_TRANSACTION}>
            <div
              className="token-amount"
              data-tooltip={`You have ${tokens} Spicygaming Tokens`}
            >
              <SpicyTokensPrice tokens={tokens} width={14} />
            </div>
          </FeatureFlag>
        </div>

        <div id="user-menu" className="">
          <div className="link">
            <Link to="/member">Profile</Link>
          </div>
          <div className="link">
            <Link to="/library">My Library</Link>
          </div>
          {Roles.CONTENT_CREATOR === profile.role?.toLowerCase() && (
            <div className="link">
              <Link
                to={`/team/@${encodeURIComponent(
                  roleInfo?.defaultTeamName
                )}/games`}
              >
                My Team
              </Link>
            </div>
          )}
          {[
            Roles.ADMIN,
            Roles.CONTENT_CREATOR,
            Roles.TRUSTED_CONTENT_CREATOR,
          ].includes(profile.role?.toLowerCase()) && (
            <div className="link">
              <Link to="/cms/content/games">Content Creator</Link>
            </div>
          )}
          {Roles.MEMBER === profile.role?.toLowerCase() && (
            <div className="link">
              <Link to="/cms/content">Content Creator</Link>
            </div>
          )}
          <div className="link">
            <Link to="/logout">Logout</Link>
          </div>
        </div>
      </button>

      <MenuPopup
        anchorElement={notificationIcon}
        className="notification_popup"
        onClose={toggleNotifications}
        open={showNotifications}
      >
        <header>
          <h2>Notifications</h2>
          <SubmitButton
            type="secondary"
            className="remove-all"
            icon={
              <span title="Mark all as Read" className="material-icons">
                done_all
              </span>
            }
            request={markRead}
          />
        </header>

        <div className="content">
          {notifications?.map((notification) => (
            <NotificationItem
              onClose={toggleNotifications}
              key={notification.id}
              notification={notification}
            />
          ))}

          <SubmitButton
            label="Load More"
            request={() => setSize((prev) => prev + 1)}
          />
        </div>
      </MenuPopup>
    </div>
  );
};
const Links = ({ links = [], closeMenu }) => {
  const language = useLanguage();
  return (
    <ul aria-label="Main Navigation Links">
      {links.map((link, index) => (
        <li key={index}>
          <NavLink
            onClick={() => {
              closeMenu?.();
              link.onClick?.();
            }}
            data-disable={link.disabled}
            exact={link.exact}
            activeClassName={!!link.link ? "active" : ""}
            to={link.link ?? "#"}
            className={link.className}
          >
            {link.text?.[language]} {link.new && <Chip>New</Chip>}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
const MobileMenu = ({ closeMenu, navLinks }) => {
  const { loggedIn } = useRoleInfo();
  const { tokens } = useSpicyStore();
  return (
    <div id="mobile-menu">
      <header>
        <div className="logo">
          <img
            src="/images/logo.png"
            height={70}
            width={130}
            alt="the official logo of spicy gaming"
          />
        </div>
        <div className="fa fa-close" onClick={closeMenu}></div>
      </header>

      <Links links={navLinks} closeMenu={closeMenu} />
      <div className="btns">
        {!loggedIn ? (
          <>
            <Link to="/login">
              <div className="login" onClick={closeMenu}>
                <span className="fa fa-user-circle-o"></span>
                Log In
              </div>
            </Link>
            <div className="line"></div>
            <Link to="/register">
              <div className="register" onClick={closeMenu}>
                Register
              </div>
            </Link>
          </>
        ) : (
          <>
            {!!tokens && (
              <>
                <span data-tooltip={`You have ${tokens} Spicygaming Tokens`}>
                  You have <SpicyTokensPrice tokens={tokens} />
                </span>
              </>
            )}
            <Link to="/member">
              <div className="login" onClick={closeMenu}>
                <span className="fa fa-user-circle-o"></span>
                Profile
              </div>
            </Link>

            <Link to="/logout">
              <div className="login" onClick={closeMenu}>
                <span className="fa fa-sign-out"></span>
                Log Out
              </div>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
const Nav = () => {
  const renderedInEmbed = useCheckForURLBlock("embed");

  const { loggedIn } = useRoleInfo();
  const username = localStorage.getItem("username") || null;

  const [isLoggedIn, toggleLoggedIn] = useState(username?.length > 0);

  useEffect(() => {
    if (loggedIn) toggleLoggedIn(loggedIn);
  }, [loggedIn]);
  const [moblieMenu, setMobileMenu] = useState(false);
  const [showSpicyTokenPopup, toggleTokenPopup] = useToggle(false);

  const [showLanguageChange, toggleLanguageChange] = useToggle();

  const language = useLanguage();
  const changeLanguage = useChangeLanguage();

  const langInfo = getLanguageInfo(language);

  const navLinks = [
    { link: "/", text: { sl: "Domov", en: "Store" }, exact: true },
    { link: "/articles/", text: { sl: "Članki", en: "articles" } },
    // {
    //   link: "/porn-games",
    //   text: { sl: "Igre za odrasle", en: "Porn games" },
    // },
    {
      link: "/videos",
      text: { sl: "", en: "Videos" },
    },
    {
      link: "/galleries",
      text: { sl: "", en: "Spicy Art" },
    },
    {
      text: { sl: "Moja Knjižnica", en: "My library" },
      link: "/library/purchased-games",
    },
  ];

  if (renderedInEmbed) return null;

  return (
    <>
      <nav>
        <div className="inner">
          <div className="logo">
            <Link to="/">
              <img
                src="/images/logo.png"
                alt="the official logo of spicy gaming"
              />
            </Link>
          </div>
          <div className="nav-links">
            <Links links={navLinks} />
          </div>
          {/* <button className="language" onClick={toggleLanguageChange}>
            <img src={langInfo?.flag} alt={langInfo?.name} />
            {showLanguageChange && (
              <div className="languages">
                {availableLanguages.map((language) => (
                  <div
                    onClick={() => {
                      changeLanguage(language.code);
                    }}
                    key={language.code}
                  >
                    <img src={language.flag} alt={language.name} />
                    <span>{language.name}</span>
                  </div>
                ))}
              </div>
            )}
          </button> */}

          {isLoggedIn ? <LoggedIn name={username} /> : <Login />}

          <FeatureFlag feature={Features.TOKEN_TRANSACTION}>
            <SubmitButton
              className="buy-tokens-button"
              label={
                <span className="buy-label">
                  Buy <SpicyTokensLogo /> Tokens
                </span>
              }
              request={toggleTokenPopup}
            />
          </FeatureFlag>

          <div className="ham" onClick={() => setMobileMenu(true)}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
        {moblieMenu && (
          <MobileMenu
            navLinks={navLinks}
            closeMenu={() => setMobileMenu(false)}
          />
        )}

        <BuyTokens show={showSpicyTokenPopup} onClose={toggleTokenPopup} />
      </nav>
    </>
  );
};

export default Nav;
