import { useEffect, useRef, useState } from "react";
import { BASE_CDN_URL, request } from "src/utils/Axios";
import { getClass } from "../../utils/getClass";
import { LoaderInside } from "../Common";
import { MultiLang } from "../MultiLang";
import FilterMini, { PriceFilter } from "./FilterMini";
import "./css/Filter.css";
import FeatureFlag from "../FeatureFlag";
import { Features } from "src/features/allFeatures";

export const SEARCH_TYPES = {
  latest: "latest",
  new: "new",
  recommended: "recommended",
  browse: "browse",

  // new config
  best: "best",
};

const Filter = ({
  filter,
  toggle,
  callback = (filters) => null,
  allowedFilters = [],
  type,
  param,
}) => {
  const initialFilters = useRef({
    engine: [],
    status: [],
    os: [],
    languages: [],
    genre: [],
    download_type: [],
    tags: [],
    "video tags": [],
    gallery_types: [],
  });

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeFilters, setActiveFilters] = useState(() => {
    if (type) initialFilters.current[type] = [param];
    return initialFilters.current;
  });

  useEffect(() => {
    getFilters();
  }, []);
  const getFilters = async () => {
    try {
      const res = await request({
        url: "/products/api/game-filters",
        baseURL: BASE_CDN_URL,
        params: {
          title: allowedFilters.join(","),
        },
      });
      if (Array.isArray(res)) setOptions(res);
      else setOptions([res]);
    } finally {
      setLoading(false);
    }
  };

  const clear = () => {
    document
      .querySelectorAll(
        '.adult-games .filters .filter .content .input input[type="checkbox"]'
      )
      .forEach((node) => (node.checked = false));

    setActiveFilters(initialFilters.current);
    callback(initialFilters.current);
  };

  const applyFilter = () => {
    callback(activeFilters);
  };

  return (
    <aside
      aria-label="Filter Your Search"
      className={`filters ${filter && "active"}`}
    >
      <h2 className="header">
        <i aria-hidden className="fa fa-filter"></i>
        <MultiLang sl="Filtriraj">Filters</MultiLang>
        <div className="fa fa-close" onClick={toggle}></div>
      </h2>
      <div className={getClass("body", "filter-loading")}>
        {loading ? (
          <LoaderInside />
        ) : (
          <>
            {options.length > 0 &&
              options?.map((filter, index) => (
                <FilterMini
                  filters={activeFilters}
                  setFilter={setActiveFilters}
                  title={filter.title}
                  options={filter.option}
                  key={index}
                  search={filter.search}
                />
              ))}
            <FeatureFlag feature={Features.TOKEN_TRANSACTION}>
              <PriceFilter
                filters={activeFilters}
                setFilter={setActiveFilters}
              />
            </FeatureFlag>
            <div className="bottom-btns">
              <div className="apply-filter" onClick={applyFilter}>
                <MultiLang sl="Potrdi Filter">apply filter</MultiLang>
              </div>
              <div className="reset-filter" onClick={clear}>
                <MultiLang sl="Resetiraj filter">reset filter</MultiLang>
              </div>
            </div>
          </>
        )}
      </div>
    </aside>
  );
};

export default Filter;
