import axios from "axios";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { LoaderInside } from "../Common";
import Popup from "../common/Popup";
import "./css/AddComponent.css";
import { authRequest } from "src/utils/Axios";

/**
 *
 * @param {Object} props
 * @param {String} props.searchURL - example: /products/api/games/search-games?title=`<query>` **Should use `query` for placeholder string**
 * @returns
 */
const AddComponent = ({
  Component,
  show,
  close,
  title,
  searchURL = "",
  addItems,
  addedItems,
  props = {},
}) => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    setResult([]);
  }, []);

  const search = async (e) => {
    setError(null);
    e.preventDefault();
    const url = searchURL.replace(/<query>/g, query);
    setLoading(true);
    try {
      const res = await authRequest({
        url,
        method: "GET",
      });

      setResult(res);
    } catch (error) {
      setError(error.message);
      setResult([]);
    }
    setLoading(false);
  };

  return (
    <Popup
      showCloseButton
      open={show}
      id="add-things"
      className={"add-things"}
      maxWidth={1000}
      onClose={close}
    >
      <section className="add-games">
        <legend>{title}</legend>
        <div className="body">
          <form onSubmit={search}>
            <div className="search">
              <input
                type="search"
                name=""
                id="add-game-search"
                placeholder="Search.."
                onChange={(e) => setQuery(e.target.value)}
              />
              <button className="fa fa-search" type="submit"></button>
            </div>
          </form>
          {loading && <LoaderInside />}
          <br />
          {error && <div className="error">{error}</div>}

          <Component result={result} {...{ addItems, addedItems }} {...props} />
        </div>
      </section>
    </Popup>
  );
};

export default AddComponent;
