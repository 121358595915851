import { useCallback, useEffect, useState } from "react";
import SubmitButton from "../SubmitButton";
import { useCarousel } from "./Carousel";

export const usePrevNextButtons = () => {
  const { api } = useCarousel();
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!api) return;
    api.scrollPrev();
  }, [api]);

  const onNextButtonClick = useCallback(() => {
    if (!api) return;
    api.scrollNext();
  }, [api]);

  const onSelect = useCallback((api) => {
    setPrevBtnDisabled(!api?.canScrollPrev());
    setNextBtnDisabled(!api?.canScrollNext());
  }, []);

  useEffect(() => {
    if (!api) return;

    onSelect(api);
    api.on("reInit", onSelect);
    api.on("select", onSelect);
  }, [api, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

export const PrevButton = (props) => {
  const { prevBtnDisabled, onPrevButtonClick } = usePrevNextButtons();
  return (
    <SubmitButton
      icon={<span className="fa fa-chevron-left" />}
      {...props}
      disabled={prevBtnDisabled}
      onClick={onPrevButtonClick}
    ></SubmitButton>
  );
};

export const NextButton = (props) => {
  const { nextBtnDisabled, onNextButtonClick } = usePrevNextButtons();
  return (
    <SubmitButton
      icon={<span className="fa fa-chevron-right" />}
      {...props}
      disabled={nextBtnDisabled}
      onClick={onNextButtonClick}
    ></SubmitButton>
  );
};
