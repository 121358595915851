import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { LoaderInside, addImageFiles } from "src/components/Common";
import Finder from "src/components/Finder";
import { Checkbox } from "src/components/Register";
import { useToggle } from "src/hooks/useToggle";
import { authRequest } from "src/utils/Axios";
import { MediaUpload, SubmitButton } from "../../Common";

const formattedGame = (game) => ({ id: game.id, name: game.title });

const PatreonConnect = ({ id, team, games }) => {
  const [autoPost, setAutoPost] = useState(team.auto_post_enabled);
  const [connectedGameId, setConnectedGameId] = useState(null);
  const [loading, toggleLoading] = useToggle();
  const [images, setImages] = useState({
    landscape_banner: [],
    square_banner: [],
  });

  const connectedGame = useMemo(
    () => games?.find((g) => g.id === connectedGameId),
    [connectedGameId, games]
  );

  useEffect(() => {
    getDefaults();
  }, []);

  const getDefaults = async () => {
    try {
      toggleLoading(true);
      const res = await authRequest({
        url: "/news/api/news/game-news/patreon/set-defaults",
        params: { team_id: id },
      });
      setConnectedGameId(res.game_id);
      if (res.landscape_banner)
        await addImageFiles("landscape_banner", setImages, [
          res.landscape_banner,
        ]);
      if (res.square_banner)
        await addImageFiles("square_banner", setImages, [res.square_banner]);
    } catch (error) {
      toast.error(error.message);
    } finally {
      toggleLoading(false);
    }
  };

  const toggleEnableAutoPost = async (resume) => {
    try {
      const res = await toast.promise(
        authRequest({
          url: "/news/api/news/game-news/patreon/enable",
          method: "POST",
          data: { resume },
        }),
        { pending: "Sending request" }
      );
      if (res.success) {
        toast.success("Successfully changed");
        setAutoPost(res.enabled);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const addGame = async () => {
    try {
      const fd = new FormData();

      for (const image in images) {
        images[image].forEach((img) => fd.append(`${image}[]`, img));
      }

      fd.append("game_id", connectedGameId);
      const res = await authRequest({
        url: "/news/api/news/game-news/patreon/set-defaults",
        data: fd,
        method: "POST",
      });

      if (res.success) {
        toast.success("Successfully Added Game");
        setImages({
          landscape_banner: [],
          square_banner: [],
        });
        setConnectedGameId(null);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  if (loading) return <LoaderInside />;

  return (
    <div id="content-creator" className="PatreonConnect">
      <br />

      <Checkbox
        label={"Allow to auto post news from Patreon"}
        id={"enable-patreon"}
        value={autoPost}
        callback={(_id, checked) => toggleEnableAutoPost(checked)}
      />

      <div className="add-game">
        <Finder
          data={games?.map(formattedGame)}
          id="game_id"
          idExtractor={(game) => game.id}
          label="Select Game"
          value={{ id: connectedGame?.id, name: connectedGame?.title }}
          onChange={(game) => setConnectedGameId(game.id)}
          update={(query) =>
            games?.filter((g) => g.title.includes(query)).map(formattedGame)
          }
        />
      </div>
      <section className="banners">
        <legend>BANNER FIELD</legend>
        <div className="content">
          <MediaUpload
            size="1920x1080"
            text="Add Landscape banner"
            onClick={setImages}
            id="landscape_banner"
            aspect={{ width: 1920, height: 1080 }}
            defaults={{
              imagefiles: images?.landscape_banner,
            }}
          />
          <MediaUpload
            size="500x500"
            text="Add Square banner"
            onClick={setImages}
            id="square_banner"
            aspect={{ width: 500, height: 500 }}
            defaults={{
              imagefiles: images?.square_banner,
            }}
          />
        </div>
      </section>
      <center>
        <SubmitButton label="Add Game" request={addGame} />
      </center>
    </div>
  );
};

export default PatreonConnect;
