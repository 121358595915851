import React from "react";
import { useRef } from "react";
import { toast } from "react-toastify";
import { authRequest } from "../../utils/Axios";
import { SubmitButton } from "../CMS/Common";
import { MultiLang } from "../MultiLang";
import "./css/Reviews.css";

const Review = ({ id, score, text, author, type }) => {
  const ref = useRef(null);
  const more = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
    ref.current?.querySelector(`.text .more`).classList.toggle("none");
    let textSelector = ref.current?.querySelector(`.text .view-more`);
    switch (textSelector?.innerText) {
      case "View More":
        textSelector.innerText = "View Less";
        break;
      case "View Less":
        textSelector.innerText = "View More";
        break;

      default:
        break;
    }
  };

  const reportReview = async () => {
    const url =
      type === "review"
        ? "/products/api/reviews/report-review"
        : "/products/api/comments/report-comment";

    try {
      const res = await authRequest({
        url: url,
        method: "POST",
        data: { comment_id: id, review_id: id },
      });
      if (res.success)
        toast.info(`You have reported a comment of user ${author}`);
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div
      className="review"
      ref={ref}
      itemProp="review"
      itemType="https://schema.org/Review"
      itemScope
    >
      <div
        itemProp="reviewRating"
        itemType="https://schema.org/Rating"
        itemScope
      >
        <meta itemProp="ratingValue" content={score} />
        <meta itemProp="bestRating" content="100" />
      </div>

      <div className="info">
        {score && (
          <div className="tag">
            <span
              className="fa fa-star"
              style={{
                background: `linear-gradient(360deg, gold ${score}%, #535353 0%)`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            ></span>
            {score}/100
          </div>
        )}

        <div
          className="author"
          itemProp="author"
          itemType="https://schema.org/Person"
          itemScope
        >
          <meta itemProp="name" content={author} />
          <span>{author}</span>
        </div>
      </div>
      <SubmitButton
        label={
          <div data-tooltip="Report this comment">
            <span className="fa fa-flag-o"></span>
          </div>
        }
        request={reportReview}
        className="report"
      />

      <div className="text">
        <p>{text.slice(0, 500)}</p>

        <span className="more none">{text.slice(500)}</span>
        {text.length > 500 && (
          <span className="view-more" onClick={more}>
            View More
          </span>
        )}
      </div>
    </div>
  );
};
const Reviews = ({
  reviews,
  type = /** @type {"review"|"comment"} */ ("comment"),
}) => {
  return (
    <div id="reviews">
      {reviews.length > 0 ? (
        reviews.map((review) => (
          <Review type={type} {...review} id={review.id} key={review.id} />
        ))
      ) : (
        <div className="no-review">
          <MultiLang sl="Ni še ocen">No reviews yet</MultiLang>
        </div>
      )}
    </div>
  );
};

export default Reviews;
