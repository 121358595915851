import { useRef } from "react";
const useNewTab = () => {
  const newTab = useRef(null);

  const open = (width) => {
    if (!!!newTab.current?.window) {
      newTab.current = window.open(
        "",
        "preview-window",
        `width=${width ?? window.innerWidth}`
      );
      newTab.current?.document.write("<h1>Loading .... </h1>");
      newTab.current?.addEventListener("unload", () => (newTab.current = null));
    }
  };
  const revalidateURL = (url) => {
    if (newTab.current?.window) {
      newTab.current.location.href = url;
    } else {
      newTab.current = window.open(
        url,
        "preview-window",
        `width=${window.innerWidth}`
      );
      newTab.current?.addEventListener("unload", () => (newTab.current = null));
    }
  };
  return { open, revalidateURL };
};

export default useNewTab;
