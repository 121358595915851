import Game from "../AdultGames/Game";
import { SubmitButton } from "../CMS/Common";

const FavGameMini = ({
  id,
  title,
  short_description,
  square_banner,
  genre,
  status,
  last_updated,

  creator,
  views,
  likes,
  num_of_followers,
  creator_id,
  creator_pic,
  button = {
    label: "",
    onClick: (id) => null,
    disabled: false,
    disabledText: "",
  },
  options = [],
}) => {
  const onClickButton = () => button.onClick && button.onClick(id);

  return (
    <div>
      <Game
        id={id}
        creator={creator}
        creator_id={creator_id}
        creator_pic={creator_pic}
        followers={num_of_followers}
        genre={genre}
        last_updated={last_updated}
        likes={likes}
        short_description={short_description}
        square_banner={square_banner}
        title={title}
        status={status}
        views={views}
        options={options}
        is_buyable
      />

      <br />
      {!!button.label.length && (
        <SubmitButton
          label={!button.disabled ? button.label : button.disabledText}
          request={onClickButton}
          disabled={button.disabled}
        />
      )}
    </div>
  );
};
export default FavGameMini;
