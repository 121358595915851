import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { authRequest } from "../../../utils/Axios";
import { Loader } from "../../Common";
import { InnerHeader } from "../Headers";
import { useTeamID } from "../RoleContext";
import { SelectTeam } from "../SelectTeam";
import PaymentInfo from "../Users/ContentCreateTeam/PaymentInfo";
import "./style.css";

const PayoutInfo = ({ header, subHeader }) => {
  const id = useTeamID();
  const [leaderEmail, setLeaderEmail] = useState("");
  const [loading, setLoading] = useState();
  useEffect(() => {
    getTeamInfo();
  }, [id]);

  const getTeamInfo = async () => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: "/api/content-creator-teams/edit",
        params: { team_id: id },
      });
      if (res) setLeaderEmail(res.leader_email);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="financial-payout-info body member-pages">
      <InnerHeader
        header={header}
        subHeader={subHeader}
        element="back-button"
      />
      <SelectTeam />
      {loading ? (
        <Loader />
      ) : (
        <PaymentInfo leaderEmail={leaderEmail} teamID={id} />
      )}
    </div>
  );
};

export default PayoutInfo;
