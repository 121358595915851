import dateFormat from "dateformat";
import React, { useState } from "react";
import relativeDate from "relative-date";
import { useToggle } from "../../hooks/useToggle";
import Popup from "../common/Popup";
import "./css/ApproveTile.css";
import "./css/ContentTile.css";
import "./css/Tile.css";
import { NOT_VALID_BORDER_STYLE } from "./validation";

const unFocus = (e) => e.target.parentElement.parentElement.blur();

const Tile = ({
  options,
  data,
  color,
  popupData = {},
  headers = [],
  banned = false,
  revalidate,
}) => {
  const [expand, setExpand] = useState(false);

  const expandTile = () => setExpand((prev) => !prev);
  const [open, toggleOpen] = useToggle();

  const [clickedID, setClickedID] = useState();

  const ClickedOption = options.find((option) => option.id === clickedID) || {
    popup: () => <></>,
  };

  const onClose = () => {
    toggleOpen(false);
    revalidate?.();
  };

  const {
    title,
    creator,
    last_updated = new Date(),
    creation_date = new Date(),
  } = data;

  return (
    <>
      <div
        id="tile"
        className={(expand && "active") + ""}
        style={{ border: color ?? (banned && NOT_VALID_BORDER_STYLE) }}
      >
        <span className="toggler" onClick={expandTile}></span>
        <div className="item header" onClick={expandTile}>
          game title
        </div>
        <div className="title" onClick={expandTile}>
          {title}
        </div>

        {headers.map((header, i) => (
          <div key={i} className="item header">
            {header}
          </div>
        ))}
        <div className="item username">{creator}</div>
        <div className="item time">{relativeDate(new Date(last_updated))} </div>
        <div className="item">
          {dateFormat(new Date(creation_date), "longDate")}
        </div>
        <button
          className="item dots"
          data-tooltip="Click to see All Available options"
        >
          <span className="material-icons dot" onClick={unFocus}>
            more_horiz
          </span>

          <div className="menu">
            <span className="material-icons" onClick={unFocus}>
              more_horiz
            </span>
            {options.map((option, i) => {
              return (
                <div
                  role="button"
                  className="opt"
                  key={i}
                  onClick={() => {
                    setClickedID(option.id);
                    toggleOpen();
                  }}
                >
                  <span className={`fa ${option.icon}`}></span>
                  <span>{option.text}</span>
                </div>
              );
            })}
          </div>
        </button>
      </div>
      {ClickedOption.popup && (
        <Popup
          open={open}
          onClose={onClose}
          giveCloseCallbackToChildren
          {...ClickedOption.popupProps}
        >
          {/* eslint-disable-next-line */}
          <ClickedOption.popup
            {...ClickedOption.props}
            {...popupData[ClickedOption.id]}
          />
        </Popup>
      )}
    </>
  );
};
const ApproveTile = ({
  options,
  popupData = {},
  isBanned = false,
  isLeader = false,
  data = {},
  headers = [],
  borderColor,
  revalidate,
}) => {
  const [expand, setExpand] = useState(false);

  const expandTile = () => setExpand((prev) => !prev);
  const [open, toggleOpen] = useToggle();

  const [clickedID, setClickedID] = useState();

  const ClickedOption = options?.find((option) => option.id === clickedID) || {
    popup: () => null,
  };

  const onClose = () => {
    toggleOpen(false);
    revalidate?.();
  };

  return (
    <>
      <div
        id="tile"
        className={`approve ${expand && "active"} ${isLeader && "leader"}`}
        style={{
          gridTemplateColumns:
            options && `repeat(${headers.length + 1}, var(--game-item-frac))`,
          border: isBanned && NOT_VALID_BORDER_STYLE,
          borderColor,
        }}
      >
        <span className="toggler" onClick={expandTile}></span>
        {headers.map((header, i) => (
          <div className="item data" key={i}>
            <div className="title">{header}</div>
            <div
              className="content"
              title={data[i]}
              onClick={!expand ? expandTile : undefined}
            >
              {data[i] ? data[i] : <p>Not Available</p>}
            </div>
          </div>
        ))}
        {options && (
          <button
            className="item dots"
            data-tooltip="Click to see All Available options"
          >
            <span
              className="material-icons dot"
              onClick={() => !expand && expandTile()}
            >
              more_horiz
            </span>

            <div className="menu">
              <span className="material-icons" onClick={unFocus}>
                more_horiz
              </span>
              {options.map((option, i) => {
                return (
                  <div
                    role="button"
                    className="opt"
                    key={i}
                    onClick={() => {
                      setClickedID(option.id);
                      toggleOpen();
                    }}
                  >
                    <span className={`fa ${option.icon}`}></span>
                    <span>{option.text}</span>
                  </div>
                );
              })}
            </div>
          </button>
        )}
      </div>
      {ClickedOption.popup && (
        <Popup
          open={open}
          onClose={onClose}
          giveCloseCallbackToChildren
          {...ClickedOption.popupProps}
        >
          {/* eslint-disable-next-line */}
          <ClickedOption.popup
            {...ClickedOption.props}
            {...popupData[ClickedOption.id]}
          />
        </Popup>
      )}
    </>
  );
};
const ContentTile = ({
  headers,
  popupData = {},
  data = [],
  options = [],
  color = "",
  columns,
  revalidate,
}) => {
  const [expand, setExpand] = useState(false);

  const expandTile = () => setExpand((prev) => !prev);

  const [open, toggleOpen] = useToggle();

  const [clickedID, setClickedID] = useState();
  const ClickedOption = options.find((option) => option.id === clickedID) || {
    popup: () => <></>,
  };

  const onClose = () => {
    toggleOpen(false);
    revalidate?.();
  };
  return (
    <>
      <div
        id="tile"
        className={` content-request approve ${expand ? "active" : ""}`}
        style={{
          border: color && NOT_VALID_BORDER_STYLE,
          borderColor: color,
          gridTemplateColumns: columns && `repeat(${columns}, 1fr)`,
        }}
      >
        <span className="toggler" onClick={expandTile}></span>

        {headers.map((header, i) => (
          <div
            className={`item data ${
              header.toUpperCase() === "TITLE" ? "title" : ""
            }`}
            onClick={expandTile}
            key={i}
          >
            <div className="title">{header}</div>
            <div
              className={`content ${
                header.toUpperCase() === "TITLE" ? "title" : ""
              }`}
              title={data[i]}
              data-tooltip={data[i]}
            >
              {data[i] ? data[i] : <p>Not Available</p>}
            </div>
          </div>
        ))}
        {!!options.length && (
          <button
            className="item dots"
            data-tooltip="Click to see All Available options"
          >
            <span className="material-icons dot">more_horiz</span>

            <div className="menu">
              <span className="material-icons" onClick={unFocus}>
                more_horiz
              </span>
              {options.map((option, i) => {
                return (
                  <div
                    role="button"
                    className="opt"
                    key={i}
                    onClick={() => {
                      setClickedID(option.id);
                      toggleOpen();
                    }}
                  >
                    <span className={`fa ${option.icon}`}></span>
                    <span>{option.text}</span>
                  </div>
                );
              })}
            </div>
          </button>
        )}
      </div>

      {ClickedOption.popup && (
        <Popup
          open={open}
          onClose={onClose}
          giveCloseCallbackToChildren
          {...ClickedOption.popupProps}
        >
          {/* eslint-disable-next-line */}
          <ClickedOption.popup
            {...ClickedOption.props}
            {...popupData[ClickedOption.id]}
          />
        </Popup>
      )}
    </>
  );
};
export { ApproveTile };
export { ContentTile };
export default Tile;
