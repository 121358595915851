import { useRef } from "react";
import { useSpicyStore } from "src/providers/SpicyStoreProvider";
import { currencyFormatter } from "src/utils/currency-format";
import { useToggle } from "../../hooks/useToggle";
import { CurrencyDisclaimer } from "../../providers/CartProvider";
import MenuPopup from "../common/Popup/MenuPopup";
import "./style.css";

export default function LocalPrice({
  amountInEUR,
  vat = 0,
  showOnlyLocalPrice = false,
  hideDisclaimer = false,
}) {
  const spicyStore = useSpicyStore();
  const [showDisclaimer, toggleDisclaimer] = useToggle();

  const finalPriceInEUR = amountInEUR + vat;
  const { price, id } = spicyStore.localizePrice(finalPriceInEUR);

  const localDisplayPrice = currencyFormatter(id)?.format(price);

  const euroDisplayPrice = currencyFormatter("EUR").format(finalPriceInEUR);

  const disclaimer = useRef();

  return (
    <span className="LocalPrice">
      {showOnlyLocalPrice && (
        <span data-tooltip={`Equivalent to ${euroDisplayPrice}`}>
          {localDisplayPrice}
          {!hideDisclaimer && (
            <button
              className="info"
              title="click for more info"
              ref={disclaimer}
              onClick={toggleDisclaimer}
            >
              *
            </button>
          )}
        </span>
      )}

      {!showOnlyLocalPrice && (
        <span>
          {euroDisplayPrice}{" "}
          <span className="fade">
            ({localDisplayPrice})
            <button
              className="info"
              title="click for more info"
              ref={disclaimer}
              onClick={toggleDisclaimer}
            >
              *
            </button>
          </span>
        </span>
      )}

      <MenuPopup
        onClose={toggleDisclaimer}
        open={showDisclaimer}
        anchorElement={disclaimer}
      >
        <CurrencyDisclaimer />
      </MenuPopup>
    </span>
  );
}
