// this is used to focus inputs by hitting enter and submit info after the last input

const inputFocuser = (inputsSelector, submitSelector = null) => {
	let inputs = document.querySelectorAll(inputsSelector);
	let submitButton = document.querySelector(submitSelector);
	inputs.forEach(
		(node, i) =>
			(node.onkeypress = (e) =>
				e.key === "Enter"
					? i + 1 !== inputs.length
						? inputs[i + 1].focus()
						: submitButton !== null
						? submitButton.click()
						: null
					: null)
	);
};
export default inputFocuser;
