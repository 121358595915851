import React from "react";
import FeaturedSection from "./HomePage/Featured";
import { Featured } from "./util";

const FeaturedGalleries = () => {
  return (
    <FeaturedSection
      title="FEATURED Galleries"
      position={Featured.FEATURED_GALLERY}
      allowedObjects={["gallery"]}
    />
  );
};

export default FeaturedGalleries;
