import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AvoidRouteChange from "src/components/AvoidRouteChange";
import { BottomStickyButtons } from "src/components/UtilityComponents/BottomStickyButtons";
import { EmptyView } from "src/components/UtilityComponents/EmptyView";
import { toEuro } from "src/utils/number-format";
import { useToggle } from "../../../../hooks/useToggle";
import { authRequest } from "../../../../utils/Axios";
import { Chip } from "../../../Chip";
import { LoaderInside, sort } from "../../../Common";
import Error404 from "../../../Errors";
import useNewTab from "../../../Previews/hook";
import Popup from "../../../common/Popup";
import { SubmitButton } from "../../Common";
import AddDownload from "./AddDownload";
import { AddResource } from "./AddResource";
import { EditDownload } from "./EditDownload";
import { HideDownload } from "./HideDownload";
import Resource from "./Resource";
import "./style.css";

const GameDownloads = ({ id, defaults }) => {
  const [showPopups, setShowPopups] = useState({});
  const [downloads, setDownloads] = useState([]);
  const [loading, toggleLoading] = useToggle(true);
  const [selectedDownload, setSelectedDownload] = useState(null);
  const [error, setError] = useState();
  const [creatorApproved, setCreatorApproved] = useState(false);
  const game = defaults?.formData;

  const newtab = useNewTab();
  useEffect(() => {
    getCreatorApprovedStatus();
    getDownloads();
  }, []);

  const download = useMemo(
    () => downloads.find((download) => download.id === selectedDownload),
    [selectedDownload, downloads]
  );
  const togglePopup = (id) => () => {
    setShowPopups((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const getCreatorApprovedStatus = async () => {
    try {
      const creator = await authRequest({
        url: "/api/content-creator-teams/edit",
        params: {
          team_id: game?.creator_team?.id,
        },
      });
      setCreatorApproved(creator.approved);
    } catch (error) {}
  };

  const getDownloads = async () => {
    try {
      const res = await authRequest({
        url: "/downloads/api/games/downloads/cms",
        params: {
          game_id: id,
          cms: true,
        },
      });

      setDownloads(sort("hidden", res, "asc"));
    } catch {
      setError(true);
    } finally {
      toggleLoading(false);
    }
  };

  const openDownloadPopup = (id, popupId) => () => {
    setSelectedDownload(id);
    togglePopup(popupId)();
  };

  const refresh = () => {
    getDownloads();
  };

  const submit = (previewMode) => async () => {
    try {
      if (previewMode) {
        newtab.open();
        newtab.revalidateURL(`/game/preview/${id}`);
        return toast.info("Don't forget to publish!");
      }
      await authRequest({
        url: "/products/api/games/publish",
        method: "POST",
        params: {
          game_id: id,
          publish: true,
        },
      });
      defaults.refreshDefaults?.();

      toast.info("Game Published");
    } catch (error) {
      toast.error(error.message);
    }
  };

  if (error) {
    return <Error404 />;
  }
  if (loading) {
    return <LoaderInside />;
  }
  return (
    <div className="Content" data-testid="Content">
      <AvoidRouteChange
        blockNavigate={!(selectedDownload === null)}
        confirmationMessage="Save changes before leave. All unsaved content will be lost."
      />

      <div className="title">
        <h2>
          Downloads of <Link to={`/game/${id}`}>{game.title}</Link>
        </h2>
        <SubmitButton
          icon="fa-plus"
          className="add-download"
          request={togglePopup("download")}
          label="Add new Download"
        />
      </div>

      <div className="downloads">
        {!!downloads.length ? (
          downloads.map((download) => (
            <div className="download" key={download.id}>
              <h3>
                <span>
                  {download.name} at{" "}
                  {download.price === 0 ? (
                    <Chip className="span free">Free</Chip>
                  ) : (
                    toEuro(download.price)
                  )}
                  {download.hidden && (
                    <Chip>
                      <span className="fa fa-eye-slash"></span> Hidden
                    </Chip>
                  )}
                </span>
                <div className="actions">
                  <SubmitButton
                    className="add-resource"
                    icon="fa-plus"
                    title="Add new Resource"
                    request={openDownloadPopup(download.id, "resource")}
                  />
                  <SubmitButton
                    className="add-resource"
                    icon="fa-pencil"
                    title="Edit Download"
                    type="secondary"
                    request={openDownloadPopup(download.id, "edit")}
                  />
                  <SubmitButton
                    className="add-resource"
                    icon={download.hidden ? "fa-eye" : "fa-eye-slash"}
                    type="secondary"
                    title="Show/Hide Download"
                    request={openDownloadPopup(download.id, "hide")}
                  />
                </div>
              </h3>
              {download.resources?.length > 0 ? (
                download.resources?.map?.((resource) => (
                  <Resource
                    gameId={id}
                    refresh={getDownloads}
                    resource={resource}
                    key={resource.id}
                  />
                ))
              ) : (
                <EmptyView text={"No resources added"} />
              )}
            </div>
          ))
        ) : (
          <div className="nothing">
            <div className="icon">
              <i className="fa fa-download"></i>
            </div>
            No downloads yet
          </div>
        )}
      </div>

      <BottomStickyButtons>
        <SubmitButton label={"Preview"} request={submit(true)} />
        <SubmitButton label={"Save & Publish"} request={submit(false)} />
      </BottomStickyButtons>

      <Popup onClose={togglePopup("download")} open={showPopups.download}>
        <AddDownload
          gameId={id}
          onClose={togglePopup("download")}
          refresh={refresh}
          creatorApproved={creatorApproved}
          creatorTeamId={game?.creator_team?.id}
        />
      </Popup>

      <Popup onClose={togglePopup("edit")} open={showPopups.edit}>
        <EditDownload
          refresh={refresh}
          onClose={togglePopup("edit")}
          download={download}
          creatorApproved={creatorApproved}
          creatorTeamId={game?.creator_team?.id}
        />
      </Popup>
      <Popup onClose={togglePopup("hide")} open={showPopups.hide}>
        <HideDownload
          download={download}
          onClose={togglePopup("hide")}
          refresh={refresh}
        />
      </Popup>
      <Popup
        showExitConfirmation
        customExitConfirmationText="Closing this window will interrupt the resource upload"
        onClose={togglePopup("resource")}
        showCloseButton
        open={showPopups.resource}
      >
        <AddResource
          onClose={togglePopup("resource")}
          gameId={id}
          creatorTeamId={game?.creator_team?.id}
          onSubmit={refresh}
          creatorApproved={creatorApproved}
          download={download}
        />
      </Popup>
    </div>
  );
};

export default GameDownloads;
