export const CRYPTO_COINS = [
  "BTC",
  "BUSD",
  "ETH",
  "USDT",
  "EOS",
  "DOGE",
  "BNB",
];

export const CRYPTO_NETWORKS = ["BTC", "BEP2", "BSC", "ETH", "SOL"];
export const genSelectables = (coin) => ({ id: coin, name: coin });
