import { Tabs } from "src/components/Tabs";
import { MainHeader } from "../Headers";
import "../css/Common.css";
import FeaturedArticles from "./FeaturedListPages/ArticlePage";
import DevelopersPage from "./FeaturedListPages/DevelopersPage";
import { DontMissConfiguration } from "./FeaturedListPages/DontMissPage";
import FeaturedGalleries from "./FeaturedListPages/GalleryPage";
import { FeaturedGames } from "./FeaturedListPages/GamesPage";
import HomePage from "./FeaturedListPages/HomePage";
import { FeaturedVideos } from "./FeaturedListPages/VideosPage";
import "./FeaturedListPages/css/Fixes.css";

export const FEATURED_IDS = {
  article_main: "Top_Main",
  article_side: "Top_Side",
};
export const DONT_MISS_PREFIX = "Dont_Miss_";
export const DONT_MISS_VIDEO_PREFIX = "Dont_Miss_Video_";
export const ARTICLE_URLS = {
  blog_post: "/news/api/news/blog-posts/mark-as-featured",
  game_news: "/news/api/news/game-news/mark-as-featured",
  staff_review: "/news/api/news/staff-reviews/mark-as-featured",
};

const pages = [
  {
    url: "/home-page",
    displayText: "home page",
    component: HomePage,
    id: "home",
  },
  {
    url: "/games",
    displayText: "featured games",
    component: FeaturedGames,
    id: "games",
  },
  {
    url: "/articles",
    displayText: "featured articles",
    component: FeaturedArticles,
    id: "articles",
  },
  {
    url: "/videos",
    displayText: "featured videos",
    component: FeaturedVideos,
    id: "videos",
  },
  {
    url: "/galleries",
    displayText: "featured galleries",
    component: FeaturedGalleries,
    id: "gallery",
  },
  {
    url: "/dont-miss-section",
    displayText: "Dont miss section",
    component: DontMissConfiguration,
    id: "dont_miss",
  },
  {
    url: "/developers-page",
    displayText: "developers page",
    component: DevelopersPage,
    id: "developers",
  },
];

const FeaturedList = ({ toggler }) => (
  <div className="body">
    <MainHeader buttons={false} toggler={toggler} header="FEATURED LIST" />
    <div className="content" id="ad-management">
      <main id="featured-list" className="cms-details">
        <Tabs withURLChange pages={pages} firstPageId="home" />
      </main>
    </div>
  </div>
);

export default FeaturedList;
