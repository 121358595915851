import { useEffect, useMemo, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { generateRandomNumber } from "src/utils/randomItem";
import Game from "../AdultGames/Game";
import NewsMini from "../Article/NewsMini";
import Banner from "../Banners";
import { AD_IDS } from "../CMS/Ads/Section";
import { LoaderInside } from "../Common";
import Developer from "../GameDevelopers/Developer";
import ImageWithoutPopup, { ImageWithPopup } from "../SpicyArt/Image";
import { ASPECT_RATIO } from "../Video/Player/Player";
import VideoPlayer from "../VideoPlayer";
import { vastPullzones } from "../VideoPlayer/pullzones";
import { generateURL } from "../resizeImages";
import "./css/Elements.css";
import ImageGallery from "../SpicyArt/Collection";
import VideoPlayerEditMode from "../CMS/Articles/ArticleComponents/VideoPlayerEditMode";
import { authRequest } from "src/utils/Axios";

export const isNeedInHorizontal = (arrayLength = 0) => {
  const MAXIMUM_SLIDES_THIS_IS_VALID = 1;
  return arrayLength <= MAXIMUM_SLIDES_THIS_IS_VALID;
};

/**Components for Object description
 * @typedef {Object} Props
 * @property {any} content - the content wanted to display in the Element
 * @property {Function} toggleEditMode - only available in cms
 */

/**
 * @param {Props} props
 * @returns
 */
const HTML = ({ content, toggleEditMode }) => {
  return (
    <div
      onClick={toggleEditMode}
      className="description"
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  );
};

const ImageInArticleEdit = ({ data, content, toggleEditMode }) => {
  const image = useMemo(() => {
    return data.images?.find((img) => img.id === content);
  }, [content, data.images]);

  if (!image) return <LoaderInside />;

  return (
    <div className="element-image" onClick={toggleEditMode}>
      <ImageWithoutPopup
        noRedirect={true}
        image={image}
        loading="lazy"
        width={800}
      />
    </div>
  );
};

const ImageInPublicArticle = ({ data, content, article }) => {
  const image = useMemo(() => {
    return data.gallery.images?.find((img) => img.id === content);
  }, [content, data.gallery]);

  if (data.gallery.loading) return <LoaderInside />;

  return (
    <div className="element-image">
      <ImageWithPopup
        image={image}
        loading="lazy"
        owner={article?.author}
        width={800}
      />
    </div>
  );
};

/**
 * @param {Props} props
 * @returns
 */
const Image = ({ content, data, article, toggleEditMode }) => {
  const editMode = !article;

  if (editMode)
    return (
      <ImageInArticleEdit
        data={data}
        content={content}
        toggleEditMode={toggleEditMode}
      />
    );

  return (
    <ImageInPublicArticle data={data} content={content} article={article} />
  );
};

/**
 * @param {Props} props
 * @returns
 */
const Galleries = ({ content }) => {
  return (
    <OwlCarousel
      className="owl-theme"
      dots
      responsive={{
        0: { items: 1 },
        840: { items: content.length === 1 ? 1 : 2 },
      }}
    >
      {content.map((gallery) => (
        <ImageGallery collection={gallery} key={gallery.id} />
      ))}
    </OwlCarousel>
  );
};

/**
 * @param {Props} props
 * @returns
 */
const Video = ({ content, article }) => {
  const editMode = !article;
  if (content === null) return null;

  if (editMode)
    return (
      <div className="trailer">
        <VideoPlayerEditMode videoId={content.video_id} />
      </div>
    );
  return (
    <div className="trailer">
      <VideoPlayer
        options={{ aspectRatio: "16:9" }}
        vastZone={vastPullzones.default}
        videoID={content.file?.bunny_id}
        folderID={content.file?.library_id}
        pullzoneID={content.file?.pullzone}
        thumbnailName={generateURL(
          720,
          parseInt(720 / ASPECT_RATIO),
          content.thumbnail
        )}
      />
    </div>
  );
};

const Games = ({ content = [] }) => {
  return (
    <div className="adult-games element-games">
      <br />
      <OwlCarousel
        className="owl-theme"
        dots
        margin={20}
        responsive={{
          0: { items: 1 },
          480: { items: isNeedInHorizontal(content.length) ? 1 : 2 },
          1200: { items: isNeedInHorizontal(content.length) ? 1 : 3 },
        }}
      >
        {content?.map((game, key) => (
          <Game
            {...game}
            key={key}
            isHorizontal={isNeedInHorizontal(content.length)}
          />
        ))}
      </OwlCarousel>
    </div>
  );
};

const GameNews = ({ content = [] }) => {
  return (
    <div className="article element-news">
      <br />
      <OwlCarousel
        className="owl-theme"
        dots
        responsive={{
          0: { items: 1 },
          480: { items: isNeedInHorizontal(content.length) ? 1 : 2 },
          1000: { items: isNeedInHorizontal(content.length) ? 1 : 3 },
        }}
      >
        {content?.map((news, key) => (
          <NewsMini
            type={"news"}
            {...news}
            key={key}
            tag={false}
            date_posted={news.last_updated}
            isHorizontal={isNeedInHorizontal(content.length)}
            square={true}
            url={`/news-article/${news.id}`}
          />
        ))}
      </OwlCarousel>
    </div>
  );
};

const Teams = ({ content }) => {
  return (
    <div className="element-teams">
      <br />
      <OwlCarousel
        className="owl-theme"
        dots
        responsive={{
          0: { items: 1 },
          480: { items: isNeedInHorizontal(content.length) ? 1 : 2 },
          1000: { items: isNeedInHorizontal(content.length) ? 1 : 3 },
        }}
      >
        {content?.map((team, key) => (
          <Developer
            developer={team}
            key={key}
            isHorizontal={isNeedInHorizontal(content.length)}
          />
        ))}
      </OwlCarousel>
    </div>
  );
};

const BlogPosts = ({ content }) => {
  return (
    <div className="article element-news">
      <br />
      <OwlCarousel
        className="owl-theme"
        dots
        responsive={{
          0: { items: 1 },
          480: { items: isNeedInHorizontal(content.length) ? 1 : 2 },
          1000: { items: isNeedInHorizontal(content.length) ? 1 : 3 },
        }}
      >
        {content?.map((news, key) => (
          <NewsMini
            type={"blog"}
            {...news}
            key={key}
            tag={false}
            date_posted={news.last_updated}
            isHorizontal={isNeedInHorizontal(content.length)}
            url={`/blog-article/${news.id}`}
            square={true}
          />
        ))}
      </OwlCarousel>
    </div>
  );
};
const StaffReviews = ({ content }) => {
  return (
    <div className="article element-news">
      <br />
      <OwlCarousel
        className="owl-theme"
        dots
        responsive={{
          0: { items: 1 },
          480: { items: isNeedInHorizontal(content.length) ? 1 : 2 },
          1000: { items: isNeedInHorizontal(content.length) ? 1 : 3 },
        }}
      >
        {content?.map((news, key) => (
          <NewsMini
            type={"staff"}
            {...news}
            key={key}
            tag={false}
            date_posted={news.last_updated}
            isHorizontal={isNeedInHorizontal(content.length)}
            url={`/staff-review/${news.id}`}
            square={true}
          />
        ))}
      </OwlCarousel>
    </div>
  );
};

const CTA = ({ content, article }) => {
  const editMode = !article;

  const [preview, setPreview] = useState({});

  useEffect(() => {
    if (editMode) getUploadedImage();
  }, []);

  const getUploadedImage = async () => {
    try {
      const res = await authRequest({
        url: "/news/api/news/cta-element/upload-cta",
        params: {
          image_id: content,
        },
      });
      setPreview(res);
    } finally {
    }
  };

  return (
    <div className="CTA">
      <div className="uploaded_image">
        <a
          href={editMode ? preview.redirect_url : content.redirect_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={editMode ? preview.image_url : content.image_url}
            loading="lazy"
            alt="uploaded_cta"
          />
        </a>
      </div>
    </div>
  );
};

const Ad = ({ content }) => {
  return (
    <Banner
      jsAd={content}
      width={970}
      height={250}
      id={AD_IDS.article.middle + generateRandomNumber()}
    />
  );
};

const Embed = ({ content }) => {
  return (
    <div
      className="embed-url"
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  );
};

const elements = [
  {
    type: "image",
    element: Image,
  },
  {
    type: "gallery",
    element: Galleries,
  },
  {
    type: "html",
    element: HTML,
  },
  {
    type: "video",
    element: Video,
  },
  {
    type: "games",
    element: Games,
  },
  {
    type: "game news",
    element: GameNews,
  },
  {
    type: "teams",
    element: Teams,
  },
  {
    type: "blog posts",
    element: BlogPosts,
  },
  {
    type: "staff reviews",
    element: StaffReviews,
  },
  {
    type: "cta",
    element: CTA,
  },
  {
    type: "ad",
    element: Ad,
  },
  {
    type: "embed",
    element: Embed,
  },
];

export const findArticleElement = (id) => {
  const element = elements.find((e) => e.type === id);
  if (element) return element.element;

  return () => null;
};

export { HTML };
export default findArticleElement;
