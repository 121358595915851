import { Helmet } from "react-helmet";
import Header from "../Header";
import { Link, useHistory, useParams } from "react-router-dom";
import { SimpleDivider } from "../Atoms/SimpleDivider";
import { BreadCrumb } from "../Atoms/BreadCrumb";
import useSWR from "swr";
import { getFullBundleByBundleId } from "src/v2/services/gameBundlesService";
import { Loader, LoaderInside } from "../Common";
import Image from "../SpicyArt/Image";
import generateImageObjectFromPath from "../SpicyArt/generateImageObject";
import { ASPECT_RATIO } from "../Video/Player/Player";
import { findAllOSFromResources, osIconFinder } from "../common/osIconFinder";
import DiscountedPrice from "../LocalPrice/DiscountedPric";
import { LocalPrice } from "../LocalPrice";
import { getClass } from "src/utils/getClass";
import { getDiscountRate } from "src/utils/getDiscountRate";
import GameBundle from "../GameDetails/sections/GameBundle";
import { SliderContainer } from "../GameDetails/sections/GameDetailArticleSlider";
import Game from "../AdultGames/Game";
import { request } from "src/utils/Axios";
import { useBroughtProducts } from "src/providers/BroughtProductsProvider";
import { useMemo } from "react";
import useBundleFinalPrice from "src/hooks/useBundleFinalPrice";
import BundleProductUnavailableOverlay from "./BundleProductUnavailableOverlay";
import IconButton from "src/v2/components/IconButton";
import { useToggle } from "src/hooks/useToggle";
import { ShareMenu } from "../common/ShareMenu";
import Error404 from "../Errors";

const getSimilarGames = async () => {
  return await request({
    url: "/products/api/games/recommended-games",
    params: { num: 8 },
  });
};

const GameBundleDetail = () => {
  const { id: bundleId } = useParams();
  const history = useHistory();
  const [showBundleShare, toggleBundleShare] = useToggle();

  const {
    data: bundle,
    isValidating,
    error,
  } = useSWR(["game-bundle-info", bundleId], () =>
    getFullBundleByBundleId(bundleId)
  );

  const bundlePrice = useBundleFinalPrice(bundle?.products);

  const { data: similarGames, isValidating: loadingSimilarGames } = useSWR(
    "similar-games",
    () => getSimilarGames()
  );

  if (!!error?.message)
    return (
      <main>
        <div className="inner">
          <Error404 customMessage={error.message} />
        </div>
      </main>
    );

  return (
    <main>
      <Helmet>
        <title>{`Buy ${bundle?.name} Bundle - Spicygaming`}</title>
        <meta name="description" content={""} />
      </Helmet>

      {isValidating && <Loader />}
      <div className="inner">
        <Header
          searchCallback={(query) => {
            if (query.search?.length > 0)
              return history.push(`/porn-games/${query.search}/1`);
          }}
        />
        <div className="game-detail-container-outer">
          <SimpleDivider />

          <BreadCrumb divider=">">
            <BreadCrumb.Item href="/">Home</BreadCrumb.Item>
            <BreadCrumb.Item href="/porn-games/1">Games</BreadCrumb.Item>

            <BreadCrumb.Item href={`game/${bundleId}`}>
              {bundle?.name}
            </BreadCrumb.Item>
          </BreadCrumb>

          <header>
            <h1 className="title">
              <span>{bundle?.name}</span>
              <IconButton
                className="text-xl"
                onClick={toggleBundleShare}
                type="secondary"
                icon={<span className="fa fa-share-alt"></span>}
              />
            </h1>
          </header>

          <div className="max-w-screen-lg mb-16">
            <GameBundle minimalLayout bundle={bundle} />
          </div>
          <section className="">
            <legend>
              <h2 className="text-white my-6 font-normal uppercase">
                Items Included In This Bundle
              </h2>
            </legend>

            <div className="grid gap-4 mb-16">
              {bundle?.products.map((product) => (
                <div key={`${product.product_id}-${product.product_type}`}>
                  <BundleProduct
                    product={product}
                    productData={product.product_data}
                  />
                </div>
              ))}
              <div className="max-w-screen-lg bg-neutral-800 rounded-lg p-5 grid grid-cols-[1fr_100px] gap-y-2 md:text-right text-xl">
                <div>Price of individual products: </div>
                <div className="font-medium">
                  <LocalPrice
                    amountInEUR={bundlePrice.total}
                    hideDisclaimer
                    showOnlyLocalPrice
                  />
                </div>
                <div>Bundle discount: </div>
                <div className="font-medium text-yellow-500">
                  {bundlePrice.rate}%
                </div>
                <div>You Save: </div>
                <div className="font-medium text-green-500">
                  <LocalPrice
                    amountInEUR={
                      bundlePrice.total - bundlePrice.discountedPrice
                    }
                    hideDisclaimer
                    showOnlyLocalPrice
                  />
                </div>
                <div>You pay: </div>
                <div className="font-medium text-yellow-500">
                  <LocalPrice
                    amountInEUR={bundlePrice.discountedPrice}
                    hideDisclaimer
                    showOnlyLocalPrice
                  />
                </div>
              </div>
            </div>
            <div className="max-w-screen-lg mb-16">
              <GameBundle minimalLayout bundle={bundle} />
            </div>
          </section>
          <section className="article news section">
            <legend>
              <h2 className="text-white my-6 font-normal uppercase">
                Similar Games
              </h2>
            </legend>
            <div className="content adult-games max-w-screen-lg">
              {loadingSimilarGames ? (
                <LoaderInside />
              ) : (
                <SliderContainer>
                  {similarGames?.map((game, i) => (
                    <Game key={i} {...game} />
                  ))}
                </SliderContainer>
              )}
            </div>
          </section>
        </div>
      </div>

      <ShareMenu
        onClose={toggleBundleShare}
        show={showBundleShare}
        shareLink={window.location.href}
        shareText={`Buy Bundle ${bundle?.name} and Save More!`}
      />
    </main>
  );
};

const BundleProduct = ({ product, productData }) => {
  const broughtProducts = useBroughtProducts();

  const allSupportOS = findAllOSFromResources(productData.resources);

  const isAlreadyBrought = useMemo(
    () =>
      !!broughtProducts.find(
        (p) =>
          p.product_id === product.product_id &&
          p.product_type === product.product_type
      ),
    [broughtProducts]
  );

  const isAvailableToPurchase = product.can_buy;

  const productDiscountPrice = (productData.price * (100 - product.rate)) / 100;

  return (
    <div
      className={getClass(
        "flex flex-col md:flex-row max-w-screen-lg bg-neutral-800 rounded-lg group cursor-pointer relative overflow-hidden",
        !isAvailableToPurchase && "grayscale cursor-not-allowed"
      )}
    >
      {!isAvailableToPurchase && (
        <BundleProductUnavailableOverlay>
          Not Available to Buy
        </BundleProductUnavailableOverlay>
      )}
      {isAlreadyBrought && (
        <BundleProductUnavailableOverlay>
          <Link to="/library" target="_blank" rel="noopener noreferrer">
            Already In Library <span className="fa fa-external-link"></span>
          </Link>
        </BundleProductUnavailableOverlay>
      )}
      <Image
        className="m-0 flex-shrink-0 [&_img]:w-full [&_img]:rounded-md md:[&_img]:w-[revert-layer]"
        noRedirect
        image={generateImageObjectFromPath(product.image, {
          aspect_ratio: ASPECT_RATIO,
        })}
        width={200}
        loading="lazy"
      />

      <div className="py-3 px-6 flex flex-col md:flex-row justify-between md:items-center w-full group">
        <div>
          <Link
            to={`/game/${productData?.game_id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="contents"
          >
            <div className="text-2xl mb-1 group-hover:text-white">
              {product.product_type} - {product.product_name}
            </div>
            <ul
              className="os flex gap-3 text-xl"
              aria-label="Operating Systems"
            >
              {allSupportOS?.map?.((os) => {
                const [icon] = osIconFinder(os);
                return (
                  <li aria-label={os} data-tooltip={os} key={os}>
                    <span
                      aria-hidden
                      className={getClass("fa icon", icon)}
                    ></span>
                  </li>
                );
              })}
            </ul>
          </Link>
        </div>
        <div className="w-fit ml-auto mr-0 mt-5 md:m-0">
          {product.rate > 0 ? (
            <div className="flex items-end">
              <div className="text-3xl font-medium pr-5 text-yellow-500 border-r-2 border-r-yellow-500">
                -{product.rate}%
              </div>
              <div className="pl-5">
                <DiscountedPrice
                  discountedPriceEUR={productDiscountPrice}
                  priceEUR={productData.price}
                />
              </div>
            </div>
          ) : (
            <div className="text-3xl font-medium text-yellow-500">
              {!!productData.price ? (
                <LocalPrice
                  hideDisclaimer
                  amountInEUR={productData.price}
                  showOnlyLocalPrice
                />
              ) : (
                "Free"
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GameBundleDetail;
