import { Link } from "react-router-dom";
import { Chip } from "src/components/Chip";
import { osIconFinder } from "src/components/common/osIconFinder";
import { LocalPrice } from "src/components/LocalPrice";
import DiscountedPrice from "src/components/LocalPrice/DiscountedPric";
import generateImageObjectFromPath from "src/components/SpicyArt/generateImageObject";
import Image from "src/components/SpicyArt/Image";
import { ASPECT_RATIO } from "src/components/Video/Player/Player";
import { getClass } from "src/utils/getClass";

const FeaturedGame = ({ game }) => {
  return (
    <div className="w-fit flex flex-col md:flex-row gap-5 bg-[hsl(0,0,10%)] p-5 rounded-lg">
      <Link className="contents" to={`/game/${game.id}`}>
        <div>
          <Image
            noRedirect
            image={generateImageObjectFromPath(game.landscape_banner, {
              aspect_ratio: ASPECT_RATIO,
            })}
            maxHeight={400}
            width={1440}
            className="m-0"
          />
        </div>
      </Link>
      <div className="w-full md:max-w-96 grid gap-5 content-start">
        <Link to={`/game/${game.id}`}>
          <h2 className="text-lg md:text-3xl">{game.title}</h2>
        </Link>
        <Link className="contents" to={`/game/${game.id}`}>
          <div className="grid grid-cols-2 gap-5 md:min-h-40">
            {game.gallery_imgs?.map((gallery, i) => (
              <div key={i} className="aspect-video overflow-hidden">
                <Image
                  noRedirect
                  className="m-0"
                  image={generateImageObjectFromPath(gallery.image, {
                    aspect_ratio: ASPECT_RATIO,
                  })}
                  width={480}
                />
              </div>
            ))}
          </div>
        </Link>

        <div className="line-clamp-2 max-w-xs text-left">
          {game.tags?.map((tag) => (
            <Link to={`/porn-games/custom/tags/${tag}/1`} key={tag}>
              <Chip className="inline-block my-1">{tag}</Chip>
            </Link>
          ))}
        </div>
        <div className="flex justify-between items-end">
          <ul className="os flex gap-3 text-xl" aria-label="Operating Systems">
            {game.operating_system?.map?.((os) => {
              const [icon] = osIconFinder(os);
              return (
                <li aria-label={os} data-tooltip={os} key={os}>
                  <span
                    aria-hidden
                    className={getClass("fa icon", icon)}
                  ></span>
                </li>
              );
            })}
          </ul>

          {game.price > 0 ? (
            game.discount?.discounted_price ? (
              <DiscountedPrice
                discountedPriceEUR={game.discount?.discounted_price}
                priceEUR={game.price}
              />
            ) : (
              <div className="text-4xl font-semibold text-yellow-500">
                <LocalPrice
                  hideDisclaimer
                  amountInEUR={game.price}
                  showOnlyLocalPrice
                />
              </div>
            )
          ) : (
            <div className="text-4xl font-semibold text-yellow-500">Free</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeaturedGame;
