import { useEffect, useState } from "react";
import { getTeamSaleBySaleId } from "src/v2/services/gameSalesService";
import { useTeamID } from "../../RoleContext";
import { saleSchema } from "./dataModels";
import AddNewSale from "./AddNewSale/AddNewSale";
import { toast } from "react-toastify";
import { LoaderInside } from "src/components/Common";

const EditSale = ({ saleId = null, defaultPageId = "info" }) => {
  const teamId = useTeamID();

  const [loading, setLoading] = useState(false);
  const [sale, setSale] = useState({});

  useEffect(() => {
    if (saleId) getSale(saleId);
  }, [saleId]);

  const getSale = async (saleId) => {
    try {
      setLoading(true);
      const res = await getTeamSaleBySaleId(saleId, teamId);

      const sale = saleSchema.parse(res);

      setSale(sale);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoaderInside />;

  return (
    <div>
      <AddNewSale
        editMode
        defaultSaleId={saleId}
        defaultValues={sale}
        defaultPageId={defaultPageId}
      />
    </div>
  );
};

export default EditSale;
