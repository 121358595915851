import { useEffect, useState } from "react";
import { CONVERSION_RATE } from "../../../constants/store";
import { authRequest } from "../../../utils/Axios";
import { toEuro } from "../../../utils/number-format";
import Popup from "../../common/Popup";
import Finder from "../../Finder";
import { Input } from "../../Member/General";
import { SubmitButton } from "../Common";
import "../css/GameCreation.css";
import "../css/Transactions.css";
import { MainHeader } from "../Headers";
import { Earn, toSpicyTokens } from "../Users/Withdraw";
import AllOrders from "./OrderSections/AllOrders";

import "./OrderSections/style.css";

const Orders = ({ toggler, subHeader }) => {
  const [briefs, setBriefs] = useState([]);
  useEffect(() => {
    getBrief();
  }, []);
  const getBrief = async () => {
    try {
      const res = await authRequest({
        url: `/store/api/spicy-tokens/statistics/overview`,
      });
      if (res.error) return;
      setBriefs([
        {
          value: toEuro(res.tokens_held_as_fee / CONVERSION_RATE),
          tokens: toSpicyTokens(res.tokens_held_as_fee),
          label: "Fees",
        },
        {
          value: toEuro(res.total_held_by_members / CONVERSION_RATE),
          tokens: toSpicyTokens(res.total_held_by_members),
          label: "In Members'",
        },
        {
          value: toEuro(res.total_held_by_teams / CONVERSION_RATE),
          tokens: toSpicyTokens(res.total_held_by_teams),
          label: "In Teams",
        },
        {
          value: toEuro(res.total_tokens / CONVERSION_RATE),
          tokens: toSpicyTokens(res.total_tokens),
          label: "Total",
        },
      ]);
    } catch (error) {}
  };

  return (
    <div className="body transactions" id="ad-management">
      <MainHeader toggler={toggler} header={subHeader} buttons={false} />
      <div className="content">
        <section id="earnings">
          {briefs.map((brief, i) => (
            <Earn {...brief} key={i} />
          ))}
        </section>
        <ExportButton />
        <AllOrders />
      </div>
    </div>
  );
};

const ExportButton = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selected, setSelected] = useState({});
  const [error, setError] = useState();
  const [dates, setDates] = useState({});

  const updateDates = (key) => (value) =>
    setDates((prev) => ({ ...prev, [key]: value }));

  const toggle = () => setShowPopup((prev) => !prev);

  const onChange = (key) => (value) =>
    setSelected((prev) => ({ ...prev, [key]: value }));

  const getTeams = async (query = "") => {
    try {
      const teams = await authRequest({
        url: `/api/content-creator-teams/search`,
        params: {
          name: query,
        },
      });
      return teams;
    } catch (error) {
      return [];
    }
  };
  const getMembers = async (query = "") => {
    try {
      let members = await authRequest({
        url: `/api/members/cms/search`,
        params: {
          search_term: query,
        },
      });
      return members.map((mem) => ({ id: mem.id, name: mem.username }));
    } catch (error) {
      return [];
    }
  };

  const submit = async () => {
    try {
      if (!!dates.start_date && !dates.end_date)
        throw new Error("Both dates are required");

      const res = await authRequest({
        url: `/store/api/transactions/export`,
        data: {
          username: selected.member?.name,
          team_id: selected.team?.id,
          ...dates,
        },
        method: "POST",
      });
      if (res.success) setError("Successfully requested");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <>
      <Popup
        showCloseButton
        maxWidth={840}
        open={showPopup}
        onClose={toggle}
        className="member cms member-pages transactions"
      >
        <div className="export-popup">
          <h2>Export Transactions</h2>
          <div className="inputs">
            <Finder
              value={selected.team}
              label="Team"
              data={[]}
              onChange={onChange("team")}
              update={getTeams}
              idExtractor={(team) => team.id}
              id="team"
            />
            <Finder
              value={selected.member}
              label="Search Member"
              data={[]}
              onChange={onChange("member")}
              update={getMembers}
              idExtractor={(mem) => mem.id}
              id="mem"
            />

            <div className="dates">
              <Input
                label="Start Date"
                type="date"
                callback={updateDates("start_date")}
              />
              <Input
                label="End Date"
                type="date"
                callback={updateDates("end_date")}
              />
            </div>
          </div>

          {error && <div className="error">{error}</div>}
          <SubmitButton label={"Request"} request={submit} />
        </div>
      </Popup>
      <SubmitButton request={toggle} icon="fa-file" label={"Export Data"} />
    </>
  );
};

export default Orders;
