import React, { useState } from "react";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useToggle } from "../../../../hooks/useToggle";
import { fallbackFunc } from "../../../../utils/fallbacks";
import { getClass } from "../../../../utils/getClass";
import { Chip } from "../../../Chip";
import { osIconFinder } from "../../../common/osIconFinder";
import Popup from "../../../common/Popup";
import { Roles, useRoleInfo } from "../../RoleContext";
import { resourceTypes } from "./types";
import { useUUID } from "../CreateGameV2/EditGame";

const Resource = ({ gameId, resource, refresh }) => {
  const uuid = useUUID();
  const { role } = useRoleInfo();
  const [showPopup, togglePopup] = useToggle();
  const [selectedOption, setSelectedOption] = useState();
  const resourceType = resourceTypes.find((r) => r.type === resource.type);
  const OptionPopup = useMemo(() => {
    return (
      resourceType?.actions?.find((action) => action.id === selectedOption)
        ?.popup ?? fallbackFunc
    );
  }, [resourceType?.actions, selectedOption]);

  return (
    <div
      key={resource.id}
      className={getClass(
        "resource",
        uuid === resource.link_unique_id && "reported"
      )}
    >
      <div className="content">
        <span>{resource.name}</span>
        <span className="os">
          {resource.operating_system.map((os) => {
            const [icon] = osIconFinder(os);
            return (
              <span key={os} data-tooltip={os}>
                <span className={getClass("fa icon", icon)}></span>
              </span>
            );
          })}
        </span>
        <Chip>{resourceType?.label}</Chip>
        {resource.hidden && (
          <Chip>
            <span className="fa fa-eye-slash"></span> Hidden
          </Chip>
        )}
      </div>
      <div className="actions">
        {resourceType?.actions?.map?.((action, i) => {
          if (action.id === "delete" && role !== Roles.ADMIN) return null;
          return (
            <button
              key={i}
              data-tooltip={action.label}
              onClick={() => {
                setSelectedOption(action.id);
                togglePopup();
              }}
            >
              <span className={getClass("fa", action.icon)}></span>
            </button>
          );
        })}
      </div>
      <Popup
        showCloseButton
        className="resource-option"
        onClose={togglePopup}
        open={showPopup}
        showExitConfirmation={selectedOption === "edit"}
        customExitConfirmationText="Make sure to save any changes you made"
      >
        <OptionPopup
          gameId={gameId}
          resource={resource}
          refresh={refresh}
          onClose={togglePopup}
        />
      </Popup>
      <div className="dragger">
        {/* <span className="fa fa-bars"></span> */}
      </div>
    </div>
  );
};

export default Resource;
