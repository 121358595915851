import { useState } from "react";
import { getClass } from "src/utils/getClass";

/**
 *
 * @param {Object} props
 * @param {String} props.id - element ID can be used for styling purposes
 * @param {Function} props.onClick - button onClick function
 * @param {boolean} props.disabled - button disabled
 * @param {String} props.className
 * @param {JSX.Element} props.icon
 * @param {"primary"|"secondary"} props.type
 */
const IconButton = (props) => {
  const {
    onClick,
    id,
    className,
    icon,
    title,
    type = "primary",
    disabled = false,
  } = props;
  const [loading, setLoading] = useState(false);
  const onButtonClick = async (e) => {
    if (disabled || loading) {
      e.preventDefault();
      return;
    }

    setLoading(true);
    await onClick?.(e);
    setLoading(false);
  };

  const finalIcon =
    typeof icon === "string" ? (
      <span className={getClass("fa", icon)}></span>
    ) : (
      icon
    );
  return (
    <button
      className={getClass(
        "bg-primary text-white leading-none p-3 rounded-full aspect-square shadow font-normal hover:bg-primary-contrast outline-none focus:ring focus:ring-primary-900",
        disabled && "disabled",
        type === "secondary" &&
          "bg-primary-900 hover:bg-primary-700 text-neutral-300 hover:text-white",
        className
      )}
      id={id}
      onClick={onButtonClick}
      data-disable={disabled}
      data-type={type}
      data-loading={loading}
      data-tooltip={title}
    >
      <div className="flex justify-center items-center aspect-square min-w-5">
        {loading ? (
          <i class="fa fa-spinner animate-spin" aria-hidden="true"></i>
        ) : (
          finalIcon
        )}
      </div>
    </button>
  );
};

export default IconButton;
