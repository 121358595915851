import React, { Suspense, useState } from "react";
import { Route, Switch } from "react-router";
import { Link, Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { authRequest } from "../../utils/Axios";
import { SubmitButton } from "../CMS/Common";
import { Roles, useRoleInfo } from "../CMS/RoleContext";
import { Loader, LoaderInside } from "../Common";
import { loggedInValidation } from "../Validation";
import "./css/ContentCreator.css";
import SentryRoute from "src/customRoute";
import { Checkbox } from "../Register";

const Error404 = React.lazy(() => import("../Errors"));

const ContentCreator = ({ match }) => {
  const { role, auth } = useRoleInfo();

  const isContentCreator =
    role === Roles.TRUSTED_CONTENT_CREATOR || role === Roles.CONTENT_CREATOR;

  const [loading, setLoading] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const history = useHistory();

  const requestRole = async () => {
    try {
      if (loggedInValidation()) {
        const username = localStorage.getItem("username");
        setLoading(true);
        const res = await authRequest({
          url: `/api/members/change-role`,
          method: "POST",
          params: {
            username,
            role: "Content Creator",
          },
        });
        if (res.success) {
          await auth();
          toast.success("Role change successful");
          window.location.href = "/cms/personal/teams/create-team";
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const toggleAgreeTerms = () => setAgreedToTerms((prev) => !prev);

  const navLinks = [match.path, match.path + "/create-team"];

  if (
    isContentCreator ||
    [Roles.ADMIN, Roles.STAFF, Roles.WRITER].includes(role)
  )
    return <Redirect to="/cms" />;
  return (
    <div className="content-creator" id="content-creator">
      <Switch>
        <SentryRoute path={navLinks[0]} exact>
          <div className="request-role">
            {loading && <Loader />}

            <div className="instructions">
              Content creator role is intended for Game developers, artists,
              writers, Voice actores and other creators
              <br />
              Changing role to a content creator and get access to:
              <ul>
                <li>Joining or creating your own studios (teams)</li>
                <li>Uploading games</li>
                <li>Share news with others</li>
                <li>
                  Share your projects like art, comics, stories, sounds and
                  animations (COMING SOON)
                </li>
                <li>
                  Join forces with others, join their teams or ask them to join
                  you on your project
                </li>
                <li>Much more..</li>
              </ul>
              <Checkbox
                id="apply-for-creator"
                callback={toggleAgreeTerms}
                label={
                  <>
                    To apply you have to agree to the{" "}
                    <Link to="/terms-of-service">terms and conditions</Link>
                  </>
                }
              />
            </div>
            <SubmitButton
              label="Change Role"
              request={requestRole}
              disabled={!agreedToTerms}
            />
          </div>
        </SentryRoute>

        <SentryRoute path={match.path + "*"} exact>
          <center>
            <Suspense fallback={<LoaderInside />}>
              <Error404 />
            </Suspense>
          </center>
        </SentryRoute>
      </Switch>
    </div>
  );
};

export default ContentCreator;
