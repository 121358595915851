import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Sort } from "../AdultGames/Header";
import { PaginationSlider } from "../Article/Slider";
import { Loader, sort } from "../Common";
import Header from "../Header";
import { MultiLang } from "../MultiLang";
import Developer from "./Developer";
import "./Developer.css";

const GameDevelopers = ({ match }) => {
  const query = match.params?.query;
  const page = match.params?.page;
  const currentPageNumber = parseInt(page);
  const history = useHistory();
  const [developers, setDevelopers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastPage, setLastPage] = useState(0);

  useEffect(() => {
    searchDevelopers();
  }, [query, page]);

  const searchDevelopers = async () => {
    try {
      setLoading(true);
      const req = await axios({
        url: `${window.domainName}/api/content-creator-teams/main/search`,
        params: {
          search_term: query,
          page,
        },
      });
      const res = await req.data;
      setDevelopers(res?.filter((obj) => !!!obj.last_page));
      setLoading(false);
      setLastPage(res.find((item) => item.last_page !== undefined)?.last_page);
    } catch {}
  };

  const sortDevelopers = ({ sort: key, order }) => {
    return setDevelopers((prev) => [...sort(key, prev, order)]);
  };
  return (
    <main id="game-developers">
      {loading && <Loader />}
      <Helmet>
        <title>Adult Game Developers list - Spicygaming</title>
      </Helmet>

      <div className="inner">
        <Header
          searchCallback={({ search }) => {
            if (!!search) history.push(`/game-developers/${search}/1`);
          }}
        />

        <div className="header">
          <h1>
            <MultiLang sl="Razvijalci iger">Game Developers</MultiLang>
          </h1>
          <button className="sort">
            <i className="fa fa-sort-amount-asc"></i>
            <Sort
              label={<MultiLang sl="Sortiraj A-Z">Sort by A-Z</MultiLang>}
              sort={sortDevelopers}
              sortKey="name"
            />
          </button>
        </div>

        <div className="content">
          {!!developers.length
            ? developers?.map((developer, key) => (
                <Developer key={key} developer={developer} />
              ))
            : !loading && (
                <center className="grid-all">
                  <h2>Nothing found</h2>
                </center>
              )}
        </div>
      </div>
      <PaginationSlider
        initial_page_number={currentPageNumber}
        total_number_of_pages={lastPage}
      />
    </main>
  );
};

export default GameDevelopers;
