import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { createContext } from "react";
import { authorizedRequest } from "../components/Validation";
import { authRequest } from "../utils/Axios";

const TokenContext = createContext({});

const TokenProvider = ({ children }) => {
  const [tokens, setToken] = useState(0);
  useEffect(() => {
    getInfo();
  }, []);
  const getInfo = async () => {
    try {
      const res = await authRequest({
        url: `/store/api/members/tokens`,
      });
      setToken(res.tokens);
    } catch {}
  };

  return (
    <TokenContext.Provider value={{ tokens, refresh: getInfo }}>
      {children}
    </TokenContext.Provider>
  );
};
export const useTokens = () => {
  return useContext(TokenContext);
};

export default TokenProvider;
