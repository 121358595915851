export default function generateImageObjectFromPath(path, options) {
  return {
    id: null,
    filepath: path,
    tags: [],
    gallery_id: null,
    likes: 0,
    uploader: null,
    game: null,
    user_liked: null,
    image: "https://www.spicygaming.net/images/logo.png",
    description: "",
    views: 0,
    is_paid: false,
    approved: true,
    aspect_ratio: 16 / 9,
    ...options,
  };
}
