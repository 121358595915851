import { useRouteMatch } from "react-router-dom";
import { LoadMore } from "src/components/Common";
import { authRequest } from "src/utils/Axios";
import { withoutLastPage } from "src/utils/withoutLastPage";
import useSWRInfinite from "swr/infinite";
import { fetcher } from "../Cms";
import { REVALIDATE_ON_FOCUS } from "../Common";
import { MainHeader } from "../Headers";
import "./style.css";
import { MiniPlaylist } from "../../Playlists/MiniPlaylist";

const Playlists = ({ subHeader, toggler }) => {
  const match = useRouteMatch();
  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) =>
      `${window.domainName}/community/playlists/list?cms=true&page=${
        index + 1
      }`,
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );
  const playlists = data ? withoutLastPage(data.flat()) : [];

  const search = async (query) => {
    try {
      const res = await authRequest({
        url: "",
        params: {
          search_term: query,
        },
      });

      return res;
    } catch (error) {
      return [];
    }
  };
  const searchCallback = (data) => {
    mutate(data, false);
  };

  return (
    <div className="body Videos" data-testid="Videos">
      <MainHeader
        header={subHeader}
        toggler={toggler}
        uploadLink={match.path + "/create"}
        search={{
          func: search,
          callback: searchCallback,
        }}
      />
      <div className="content">
        {playlists.map((playlist) => (
          <MiniPlaylist
            key={playlist.id}
            playlist={playlist}
            actions={["edit", "remove"]}
            onActionTriggered={() => mutate(playlists, true)}
          />
        ))}
      </div>
      <LoadMore onClick={() => setSize((prev) => prev + 1)} />
    </div>
  );
};

export default Playlists;
