import { PRODUCT_TYPES } from "../../../providers/CartProvider";
import { FullTransaction } from "../../CMS/Games/OptionPopups";
import { Roles } from "../../CMS/RoleContext";
import { roleFilter } from "../../CMS/RoleFilter";
import { CreateRefund } from "./OptionPopups";

export const transactionOptions = (role, transaction) => {
  const allUserOptions = [
    {
      icon: "fa-money",
      text: "Transaction",
      id: "full",
      popup: FullTransaction,
    },
  ];

  if (
    (role === Roles.ADMIN &&
      transaction.product_type !== PRODUCT_TYPES.TokenPurchase) ||
    transaction.can_be_refunded
  )
    allUserOptions.push({
      icon: "fa-refresh",
      text: "Refund",
      id: "refund",
      popup: CreateRefund,
    });

  return roleFilter(role, allUserOptions, {
    [Roles.ADMIN]: ["refund", "full"],
    [Roles.CONTENT_CREATOR]: ["refund", "full"],
    [Roles.MEMBER]: ["refund", "full"],
    [Roles.TRUSTED_CONTENT_CREATOR]: ["refund", "full"],
    [Roles.STAFF]: ["refund", "full"],
    [Roles.WRITER]: ["refund", "full"],
  });
};
