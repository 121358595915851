import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RequestError from "../../../errors/RequestError";
import { authRequest } from "../../../utils/Axios";
import { OTP_REGEX } from "../../../utils/regex";
import { Chip } from "../../Chip";
import { SubmitButton } from "../../CMS/Common";
import Popup from "../../common/Popup";
import { MultiLang } from "../../MultiLang";
import { Input } from "../General";
import ChangePrimary from "./ChangePrimary";
import { Disable } from "./Disable";
import { methods_2fa } from "./methods";
import "./style.css";

/**
 *
 * MARK: Some functionalities are commented out due to only one 2fa method is available
 * ex: -> change primary
 */

const Configure2FA = () => {
  const [showPopup, setShowPopup] = useState({});
  const [code, setCode] = useState("");
  const [error, setError] = useState();
  const [otpLink, setOtpLink] = useState(null);
  const [backupCodes, setBackupCodes] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [enabledList, setEnabledList] = useState([]);
  const [selectedMethod, setSelectedMethod] = useState(undefined);

  useEffect(() => {
    getEnabled();
  }, []);

  const togglePopup = (name) =>
    setShowPopup((prev) => ({ ...prev, [name]: !prev[name] }));

  const getEnabled = async () => {
    try {
      const res = await authRequest({
        url: "/trenchauth/mfa/user-active-methods/",
        method: "GET",
      });
      setEnabledList(res.results);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const enable = async (name) => {
    if (name === "app") setOtpLink(null);
    const res = await authRequest({
      url: `/trenchauth/${name}/activate/`,
      method: "POST",
    });
    if (name === "app") {
      const url = `https://api.qrserver.com/v1/create-qr-code/?data=${res.details}`;
      setOtpLink(url);
      togglePopup(name);
    }
  };
  const submit = async () => {
    try {
      setError(null);
      const res = await authRequest({
        url: `/trenchauth/${selectedMethod}/activate/confirm/`,
        method: "POST",
        data: {
          code,
        },
      });
      if (res.backup_codes) {
        setBackupCodes(res.backup_codes);
      }
      setCurrentStep(2);
      await getEnabled();
    } catch (error) {
      setError(error.message);
    }
  };

  const copyBackupCodes = () => {
    navigator.clipboard.writeText(backupCodes.join("\n"));
    setError("Backup codes copied to clipboard");
  };

  return (
    <section className="Configure2FA">
      <legend>
        <MultiLang sl="">Two-factor authentication (2FA)</MultiLang>
      </legend>
      <div className="section-content">
        <p>
          Two-factor authentication adds an additional layer of security to your
          account by requiring more than just a password to sign in.
        </p>
        <ol className="methods">
          {methods_2fa.map((method) => {
            const enabled = enabledList.find((m) => m.name === method.name);
            const onClick = async () => {
              try {
                setSelectedMethod(method.name);
                if (enabled) return togglePopup("disable");
                else {
                  await enable(method.name);
                  togglePopup("enable");
                }
              } catch (error) {
                toast.error(error.message);
              }
            };
            return (
              <li key={method.name}>
                <div className="method">
                  <span className="icon">{method.icon}</span>
                  <span>
                    {method.label} {method.recommended && "(Recommended)"}{" "}
                  </span>
                  {enabled && <Chip className="active">active</Chip>}
                  {/* {enabled?.is_primary && (
                    <Chip>
                      <span
                        onClick={() => togglePopup("change_primary")}
                        className="primary"
                      >
                        Primary
                        <span className="fa fa-pencil"></span>
                      </span>
                    </Chip>
                  )} */}
                </div>
                <SubmitButton
                  icon="fa-key"
                  type={enabled ? "secondary" : "primary"}
                  label={enabled ? "Remove" : "Enable"}
                  request={onClick}
                />
              </li>
            );
          })}
        </ol>
      </div>

      <Popup
        className={"Configure2FA--qr"}
        open={showPopup.enable}
        defaultClose={false}
        showCloseButton
        onClose={() => {
          togglePopup("enable");
          setCurrentStep(1);
          setError(null);
        }}
      >
        {currentStep === 1 ? (
          <>
            <h2>Two-factor Authentication Setup</h2>
            <ol>
              {selectedMethod === "app" && (
                <li>
                  <p>
                    Open the authenticator app on your phone (e.g. Google
                    Authenticator or Microsoft Authenticator) and scan the
                    following QR code:
                  </p>
                  <img
                    src={otpLink}
                    width={280}
                    height={280}
                    alt="Spicygaming 2FA QR"
                  />
                </li>
              )}
              <li>
                {selectedMethod === "app" ? (
                  <p>
                    Enter the 6-digit token generated by your authenticator app
                    into the text box below
                  </p>
                ) : (
                  <p>
                    Enter the 6-digit token sent to your email into the text box
                    below
                  </p>
                )}
                <Input
                  autoFocus
                  placeholder="*****"
                  callback={setCode}
                  regex={OTP_REGEX}
                />
              </li>
            </ol>

            <RequestError error={error} />
            <SubmitButton
              disabled={!(code.length === 6)}
              label="Done"
              request={submit}
            />
          </>
        ) : (
          <>
            <h2>Two-factor Authentication Backup Codes</h2>
            <p>
              Download and print the codes below and keep them stored securely.
            </p>
            <p>
              You can use each code exactly once to log into your account if you
              are unable to generate a verification token from your
              authenticator app
            </p>

            <textarea
              name="codes"
              cols="30"
              rows="5"
              className="backupCodes"
              value={backupCodes.join("\n")}
            ></textarea>
            <RequestError error={error} />
            <SubmitButton label={"Copy"} request={copyBackupCodes} />
          </>
        )}
      </Popup>

      <Popup open={showPopup.disable} onClose={() => togglePopup("disable")}>
        <Disable
          name={selectedMethod}
          onDisabled={async () => {
            await getEnabled();
            togglePopup("disable");
          }}
        />
      </Popup>

      <Popup
        className={"Configure2FA--primary"}
        open={showPopup.change_primary}
        onClose={() => togglePopup("change_primary")}
      >
        <ChangePrimary
          enabledList={enabledList}
          onChange={async () => {
            await getEnabled();
            togglePopup("change_primary");
          }}
        />
      </Popup>
    </section>
  );
};

export default Configure2FA;
