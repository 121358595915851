import { useContext, useEffect } from "react";
import { updateViewedImages } from "src/utils/updateViewed";
import { useGalleryPublic } from "../CMS/SpicyArt/UploadCollection/useCollectionInfo";
import { LazyLoaded } from "../LazyLoaded";
import { PreviewContext } from "../Previews/Preview";
import { findArticleElement } from "./Elements";

/**The Massive description
 * @param {Object} props
 * @param {{ type: String, order: Number, content: any }[]} props.elements - The array of elements
 * @returns {JSX.Element}
 */
function Description({ elements, article }) {
  const gallery = useGalleryPublic(article.media_gallery);

  useEffect(() => {
    updateViewedImages(gallery.images?.map((img) => img.id));
  }, []);

  const previewMode = useContext(PreviewContext);

  elements = elements.sort((a, b) => a.order - b.order); //sorted array

  return (
    <LazyLoaded>
      {elements.map((element) => {
        const elementType = element.type.toLowerCase();

        const Element = findArticleElement(elementType);
        return (
          <div
            className="object-editor-element"
            key={element.order}
            data-disable={!!previewMode}
          >
            <Element
              article={article}
              content={element.content}
              data={{ gallery }}
            />
          </div>
        );
      })}
    </LazyLoaded>
  );
}

export default Description;
