import React from "react";
import { Link } from "react-router-dom";
import { generateURL } from "../resizeImages";

export const getURL = (type, id) => {
  switch (type) {
    case "Blog Posts":
      return `/blog-article/${id}`;
    case "Staff Reviews":
      return `/staff-review/${id}`;
    case "Game News":
      return `/news-article/${id}`;

    default:
      return "";
  }
};
const FeaturedBig = ({ img, title, alt, metadata = {} }) => {
  return (
    <div className="big-image-container">
      <Link to={getURL(metadata.type, metadata.id)}>
        <div className="image">
          <img
            src={generateURL(1080, 720, img)}
            alt={alt}
            draggable="false"
            width={1080}
            height={720}
            loading="eager"
          />
        </div>
      </Link>
      <div className="text">
        <h2 className="title">
          <Link to={getURL(metadata.type, metadata.id)}>{title}</Link>
        </h2>
      </div>
    </div>
  );
};

export default FeaturedBig;
