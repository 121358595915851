import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import {
  init,
  reactRouterV5Instrumentation,
  BrowserTracing,
  ErrorBoundary,
} from "@sentry/react";
import App from "./App";
import { ErrorBoundaryFallback } from "./components/ErrorBoundary";

import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  rageclick: true,
};

const history = createBrowserHistory();

init({
  enabled: window.location.hostname !== "localhost",
  dsn: "https://89e707f7f1b39db1573fa9420e23c143@o1262283.ingest.sentry.io/4506626993291264",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: reactRouterV5Instrumentation(history),
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.2,
  sampleRate: 0.7,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/api\.spicygaming\.net\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  autoSessionTracking: false,
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.6,
  ignoreErrors: [
    "Network Error",
    "NetworkError",
    "AbortError",
    "SyntaxError",
    "g is null",
    "(evaluating 'r[\"@context\"].toLowerCase')",
    "Cannot read properties of null (reading 'style')",
    "WebSocket connection to",
    "Loading CSS chunk 7 failed",
    "Failed to execute 'removeChild'",
    "GET https://www.spicygaming.net:8443/socket.io",
    "The element or ID supplied is not valid.",
  ],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <ErrorBoundary
        fallback={ErrorBoundaryFallback}
        beforeCapture={(scope) => {
          scope.setTag("location", "Main Website");
        }}
      >
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={options}
        >
          <App />
        </PostHogProvider>
      </ErrorBoundary>
    </Router>
  </React.StrictMode>,
  document.getElementById("SpicyGaming")
);
