import { CONVERSION_RATE } from "src/constants/store";
import { SpicyTokensLogo } from "../LocalPrice/SpicyTokensPrice";

const faqSection = (title, questions = []) => {
  return {
    title,
    questions,
  };
};

const faqQuestion = (title, answer) => {
  return {
    title,
    answer,
  };
};

export const faqData = [
  faqSection("Registering on the Platform", [
    faqQuestion(
      "How do I register an account on SpicyGaming?",
      <div>
        To register, visit the <a href="/register">register link</a>. Fill out
        the required fields or connect your Google account for a quick signup.
      </div>
    ),
  ]),

  faqSection("Buying Tokens", [
    faqQuestion(
      "What are SpicyTokens and how do I buy them?",
      <div>
        SpicyTokens are the currency used on our platform. To buy tokens:
        <ol>
          <li>
            Fill out your payment details in your{" "}
            <a href="/member/general">user profile</a>.
          </li>
          <li>Click the big red "Buy Tokens" button in navigation bar.</li>
          <li>Select a token package.</li>
          <li>
            Complete the purchase using one of our supported payment options
            (payment cards or cryptocurrency).
          </li>
        </ol>
      </div>
    ),
    faqQuestion(
      "How can I spend my SpicyTokens?",
      "You can use SpicyTokens to acquire content, support your favorite content creators through donations or tips, spend them in our arcade, and more."
    ),
  ]),

  faqSection("Earning Free Tokens", [
    faqQuestion(
      "How can I earn free SpicyTokens?",

      <div>
        <p>
          We will reward users who will add value to the community of
          Spicygaming. You can earn free tokens by:
        </p>

        <ul>
          <li>Posting meaningful content, comments, and reviews.</li>
          <li>Sharing quality user-generated content.</li>
          <li>Being helpful to other members of the community</li>
          <li>
            Participating in various events hosted by SpicyGaming and its
            partners.
          </li>
          <li>By participating in our affilation scheme</li>
        </ul>
        <p>Free tokens can be spent just like purchased tokens.</p>
      </div>
    ),
  ]),
  faqSection("Refunds", [
    faqQuestion(
      "Refund Grace Period",
      <div>
        At Spicygaming, we offer a 14-day grace period for all transactions made
        within our platform, excluding token package purchases. If you believe
        that a transaction did not meet your expectations, you may request a
        refund. If your reasons are deemed sufficient, we will refund the tokens
        spent on the transaction.
      </div>
    ),
    faqQuestion(
      "How to Initiate a Refund",
      <div>
        To initiate a refund:
        <ol>
          <li>Go to your profile and navigate to your orders.</li>
          <li>Select the token order you encountered issues with.</li>
          <li>
            Click on the three dots (...) next to the order and select "Show
            Transaction."
          </li>
          <li>
            Identify the problematic transaction (e.g., game purchase) and click
            the three dots (...) again.
          </li>
          <li>
            Select "Refund" to start the refund process. We will contact you
            shortly thereafter to assist with your request.
          </li>
        </ol>
      </div>
    ),
  ]),

  faqSection("Sending Donations", [
    faqQuestion(
      "How do I donate tokens to a content creator?",
      <div>
        To show appreciation, click the "Support" button under the content
        creator's profile to donate tokens. This is a great way to support
        creators, especially those offering free content.
      </div>
    ),
  ]),

  faqSection("Downloading Games", [
    faqQuestion(
      "How do I download games?",
      <div>
        To download games:
        <ol>
          <li>Visit the game page that you like</li>
          <li>
            Click on the download button of the version that you would like to
            aquire. The download will be added to your{" "}
            <a href="/library/purchased-games">content library</a>.
          </li>
          <li>
            Some downloads are free, while others require tokens to unlock.
          </li>
        </ol>
      </div>
    ),
  ]),

  faqSection("Content Library", [
    faqQuestion(
      "Where can I find the content I've acquired?",
      <div>
        All acquired content is stored in your{" "}
        <a href="/library">Content Library</a>. It is organized into categories
        like Games, Art, Video, etc., for easy access.
      </div>
    ),
  ]),

  faqSection("Changing Password", [
    faqQuestion(
      "How do I change my password?",
      <div>
        To change your password:
        <ol>
          <li>Log in to your account.</li>
          <li>
            Visit your profile and click on{" "}
            <a href="/member/security">Security</a>.
          </li>
          <li>Enter your old password and select a new one.</li>
        </ol>
      </div>
    ),
    faqQuestion(
      "What if I forget my password?",
      <div>
        If you've forgotten your password, click on the{" "}
        <a href="/forgot-password">Forgot Password</a> link on the login page to
        reset it.
      </div>
    ),
  ]),

  faqSection("Setting Up 2FA", [
    faqQuestion(
      "How do I set up Two-Factor Authentication (2FA)?",
      <div>
        To enhance your security:
        <ol>
          <li>Visit your profile.</li>
          <li>
            Click on <a href="/member/security">Security</a>.
          </li>
          <li>Click "Enable 2FA" and follow the instructions.</li>
        </ol>
      </div>
    ),
  ]),

  faqSection("Playing a Game", [
    faqQuestion(
      "How do I play a game?",
      <div>
        Some games come as a Steam key while others are provided as game files.
        To play a game that comes as a .zip file:
        <ol>
          <li>
            Download the version suitable for your device and operating system.
          </li>
          <li>Unzip the file into a directory.</li>
          <li>
            Access the newly generated directory and find the executable file
            (e.g., .exe) to run the game.
          </li>
        </ol>
        If you require further assistance with running the game, please reach
        out to us or the developer of the game.
      </div>
    ),
  ]),

  faqSection("Activating a Steam Key", [
    faqQuestion(
      "How do I activate a Steam Key for a game?",
      <div>
        To activate a Steam Key:
        <ol>
          <li>Log in to your Steam account on the Steam client.</li>
          <li>Click "+ Add a Game" at the bottom left.</li>
          <li>
            Select "Activate a Product on Steam..." and follow the on-screen
            instructions.
          </li>
        </ol>
      </div>
    ),
  ]),

  faqSection("Managing Notifications", [
    faqQuestion(
      "How do I manage my notifications?",
      <div>
        To manage your notifications:
        <ol>
          <li>Log in to your account.</li>
          <li>Visit your profile and click on "Notifications."</li>
          <li>Adjust your notification preferences for various activities.</li>
        </ol>
      </div>
    ),
  ]),

  faqSection("Reporting a Problem", [
    faqQuestion(
      "How do I report a problem or a bug?",
      <div>
        To report an issue:
        <ol>
          <li>
            Visit the <a href="/contact">Contact</a> section of our website.
          </li>
          <li>You can reach out to us by Email, Discord or Twitter </li>
          <li>Our support team will review and address the issue promptly.</li>
        </ol>
      </div>
    ),
  ]),
];

export const faqDataCreators = [
  faqSection("Applying for a Content Creator Account", [
    faqQuestion(
      "How do I apply for a content creator account?",
      <div>
        First, register a normal user account. Then, visit your profile page,
        click on the <a href="/member/content-creator">CONTENT CREATOR link</a>,
        and follow the on-screen instructions.
      </div>
    ),
  ]),

  faqSection("Creating a Team (Studio)", [
    faqQuestion(
      "Why do I need to create a team?",
      <div>
        A team (studio) is necessary to upload content. Teams are the true
        owners of the content you upload, acting like a studio or company. They
        can have several members with different permissions, enabling task
        delegation.
      </div>
    ),
    faqQuestion(
      "How do I create a team?",
      <div>
        Visit your content creator dashboard, click on Developer Teams, and then
        click on the red Create a Team button.{" "}
        <a href="/cms/personal/teams/create-team">
          <i className="fa fa-external-link"></i>
        </a>
      </div>
    ),
  ]),

  faqSection("Getting Verified", [
    faqQuestion(
      "Why do I need to get verified?",
      <div>
        Verification is required to sell your content on the platform. This
        involves inserting payout information in your team edits and signing the
        agreement located below the payout details. We might require additional
        information such as proof or incorporation as they may be requested
        later by payment providers or tax authorities.
      </div>
    ),
    faqQuestion(
      "How do I get Verified?",
      <div>
        Log in to your content creator dashboard. Click on the teams list and
        edit the selected team you wish to verify. (if you only have 1 team you
        can lso click the edit team link on the content creator dahsboard)
        Navigate to the payout tab and fill oout all of the reuquired fields.
        Lastly sign the agreement below the payout information.
      </div>
    ),
  ]),

  faqSection("Uploading Games", [
    faqQuestion(
      "How do I upload games?",
      <div>
        In your content creator dashboard, click on the Games link followed by
        the Upload a Game button{" "}
        <a href="/cms/content/upload-game/info">
          <i className="fa fa-external-link"></i>
        </a>{" "}
        . Fill out all required fields, upload quality banners and images, and
        attach the downloads. Make sure your team is verified if adding a priced
        download. Publish your game after each edit. Your game will then undergo
        a review process before appearing on the site.
      </div>
    ),
  ]),

  faqSection("Setting Up Pricing", [
    faqQuestion(
      "How do I set up pricing for my content?",
      <div>
        You need a verified team account to set up pricing. After verification,
        you can add pricing to your downloads. Pricing is made in Spicy tokens (
        {CONVERSION_RATE} <SpicyTokensLogo /> = 1€).
      </div>
    ),
  ]),

  faqSection("Creating Sales", [
    faqQuestion(
      "How do I create a discount sale for my products?",
      <div>
        To create a discount sale for your products, follow these steps:
        <ul>
          <li>Navigate to the Sales & Bundles page in your CMS.</li>
          <li>Click on the "Add a New Sale" button.</li>
          <li>
            Fill out the required details for your sale, including the sale
            name, start date, and expiration date.
          </li>
          <li>
            Once the sale is created, add the products you want to include in
            the sale, specifying the discount for each.
          </li>
          <li>Don't forget to save your changes!</li>
        </ul>
        <p>Your sale will be active starting from the date you specified.</p>
      </div>
    ),
  ]),

  faqSection("Posting Videos", [
    faqQuestion(
      "How do I post videos?",
      <div>
        Post videos directly from the video page of the platform or via your
        content creator dashboard by clicking the Videos link and then the
        Upload a New Video button.
      </div>
    ),
  ]),

  faqSection("Posting Art", [
    faqQuestion(
      "How is art posted on the platform?",
      <div>
        Most art is posted automatically from games and articles. To upload your
        own art gallery, visit your content creator dashboard, click on the
        Spicy Art link, and follow the instructions. If your team is verified,
        you can assign a price to the gallery.
      </div>
    ),
  ]),

  faqSection("Posting Articles", [
    faqQuestion(
      "How do I post game news?",
      <div>
        Visit your content creator dashboard, click on Game News links, and
        click the Upload button. Select your correct team when posting. If your
        team is verified, you can assign a price to the news, locking some
        behind a paywall.
      </div>
    ),
    faqQuestion(
      "What other types of articles can I post?",
      <div>
        Certain content creators can post additional articles, including game
        reviews, in-depth articles about the industry or products, interviews,
        and written fiction such as erotica. Verified teams can assign a price
        to these articles.
      </div>
    ),
  ]),

  faqSection("Withdrawing Tokens", [
    faqQuestion(
      "How do I withdraw tokens?",
      <div>
        You need a verified team account and a registered company. You will need
        to provide a full invoice for the withdrawal amount. All the details
        about the withdrawal can be found on the{" "}
        <a href="/cms/financial/withdraw">withdraw tokens section</a> of the
        content creator dashboard.
      </div>
    ),
    faqQuestion(
      "PAYOUT OPTIONS",
      <div>
        We offer different payout options. You can select the one you prefer
        when inserting the payout information for your selected team. Please
        review the fees of the payouts when you do.
      </div>
    ),
  ]),

  faqSection("Connecting Your Patreon Account", [
    faqQuestion(
      "How do I connect my Patreon account?",
      <div>
        Connect your Patreon account to your team by clicking the Edit Team link
        in your content creator dashboard, visiting the Connected Apps tab, and
        following the on-screen instructions. This enables automatic sharing of
        your public news on Patreon as game news on Spicygaming.
      </div>
    ),
  ]),

  faqSection("Service Agreement", [
    faqQuestion(
      "Do I need to sign a service agreement?",
      <div>
        Yes, to sell your content on the platform, you need to sign a service
        agreement with Spicygaming. You can find the latest details of the
        agreement{" "}
        <a href="https://spicygames.b-cdn.net/Spicygaming%20-%20Service%20Agreement.pdf">
          here
        </a>
        .
      </div>
    ),
  ]),
];
