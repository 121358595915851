import { generatePath } from "react-router-dom";
import { RedirectTo } from "../Common";
import { Roles } from "../RoleContext";
import { roleFilter } from "../RoleFilter";
import {
  ApproveNews,
  ApproveUserReview,
  Delete,
  DeleteReview,
  DeleteUserReview,
  EditReview,
  PublishArticle,
  ReportReview,
  ViewReview,
} from "./OptionPopups";

const blogOptions = (role, details) => {
  const allUserOptions = [
    {
      icon: "fa-eye",
      text: "View Article",
      id: "view",
      popup: ({ id }) => <RedirectTo to={`/blog-article/${id}`} />,
    },
    {
      icon: "fa-edit",
      text: "Edit",
      id: "edit",
      popup: ({ id }) => (
        <RedirectTo to={`${window.location.pathname}/edit/${id}`} />
      ),
    },
    {
      icon: details.published ? "fa-ban" : "fa-check",
      text: details.published ? "Unpublish Article" : "Publish Article",
      id: "publish",
      popup: PublishArticle,
      props: {
        url: "/news/api/news/blog-posts/publish",
      },
    },
    {
      icon: "fa-trash",
      text: "Delete",
      popup: Delete,
      id: "delete",
      danger: true,
    },
  ];

  return allUserOptions;
};

const staffReviewsOptions = (details) => {
  const allUserOptions = [
    {
      icon: "fa-eye",
      text: "View Article",
      id: "view",
      popup: ({ id }) => <RedirectTo to={`/staff-review/${id}`} />,
    },
    {
      icon: "fa-edit",
      text: "Edit",
      id: "edit",
      popup: ({ id }) => (
        <RedirectTo to={`${window.location.pathname}/edit/${id}`} />
      ),
    },
    {
      icon: details.published ? "fa-ban" : "fa-check",
      text: details.published ? "Unpublish Article" : "Publish Article",
      id: "publish",
      popup: PublishArticle,
      props: {
        url: "/news/api/news/staff-reviews/publish",
      },
    },
    {
      icon: "fa-trash",
      popup: DeleteReview,
      text: "Delete",
      id: "delete",
      danger: true,
    },
  ];

  return allUserOptions;
};
const gameNewsOptions = (role, details) => {
  const allPermissions = [
    {
      icon: "fa-eye",
      text: "View News",
      id: "view_news",
      popup: ({ id }) => <RedirectTo to={`/news-article/${id}`} />,
    },
    {
      icon: "fa-edit",
      text: "Edit",
      id: "edit",
      popup: ({ id }) => (
        <RedirectTo to={`${window.location.pathname}/edit/${id}`} />
      ),
    },
    {
      icon: details.published ? "fa-ban" : "fa-check",
      text: details.published ? "Unpublish Article" : "Publish Article",
      id: "publish",
      popup: PublishArticle,
      props: {
        url: "/news/api/news/game-news/publish",
      },
    },
    {
      icon: "fa-ban",
      text: "Deny",
      id: "deny",
      popup: (props) => <ApproveNews {...props} approve={false} />,
    },
    {
      icon: "fa-trash",
      popup: Delete,
      text: "Delete",
      id: "delete",
      danger: true,
    },
  ];

  return roleFilter(role, allPermissions, {
    [Roles.ADMIN]: ["view_news", "edit", "publish", "deny", "delete"],
    [Roles.STAFF]: ["view_news", "edit", "publish", "deny", "delete"],
    [Roles.TRUSTED_CONTENT_CREATOR]: ["view_news", "edit", "publish", "delete"],
    [Roles.CONTENT_CREATOR]: ["view_news", "edit", "publish", "delete"],
  });
};

const gameNewsApprovalOptions = (role) => {
  const allPermissions = [
    {
      icon: "fa-edit",
      text: "Edit",
      id: "edit",
      popup: ({ id }) => {
        return (
          <RedirectTo
            to={generatePath("/cms/content/game-news/edit/:id", { id })}
          />
        );
      },
    },
    {
      icon: "fa-check",
      text: "Approve",
      popup: ApproveNews,
      id: "approve",
    },
    {
      icon: "fa-trash-o",
      text: "Delete",
      popup: Delete,
      id: "delete",
      danger: true,
    },
  ];

  return roleFilter(role, allPermissions, {
    [Roles.ADMIN]: ["edit", "approve", "deny", "delete"],
    [Roles.STAFF]: ["edit", "approve", "deny", "delete"],
  });
};
const userGameReviewOptions = (role) => {
  const allPermissions = [
    {
      icon: "fa-eye",
      text: "View Review",
      id: "view",
      popup: ViewReview,
    },
    {
      icon: "fa-ban",
      text: "Deny",
      id: "deny",
      popup: (props) => <ApproveUserReview {...props} approve={false} />,
    },
    {
      icon: "fa-edit",
      text: "Edit",
      id: "edit",
      popup: EditReview,
    },
    {
      icon: "fa-ban",
      text: "Report",
      id: "report",
      popup: ReportReview,
    },
    {
      icon: "fa-trash",
      popup: DeleteUserReview,
      text: "Delete",
      id: "delete",
      danger: true,
    },
  ];

  return roleFilter(role, allPermissions, {
    [Roles.ADMIN]: ["view", "edit", "delete", "deny"],
    [Roles.STAFF]: ["view", "edit", "delete", "deny"],
    [Roles.CONTENT_CREATOR]: ["view", "report"],
    [Roles.TRUSTED_CONTENT_CREATOR]: ["view", "report"],
  });
};
const userGameReviewApprovalOptions = (role) => {
  const allPermissions = [
    {
      icon: "fa-eye",
      text: "View Review",
      id: "view",
      popup: ViewReview,
    },
    {
      icon: "fa-check",
      text: "Approve",
      id: "approve",
      popup: (props) => <ApproveUserReview {...props} approve={true} />,
    },
    {
      icon: "fa-edit",
      text: "Edit",
      id: "edit",
      popup: EditReview,
    },
    {
      icon: "fa-trash",
      popup: DeleteUserReview,
      text: "Delete",
      id: "delete",
      danger: true,
    },
  ];
  return roleFilter(role, allPermissions, {
    [Roles.ADMIN]: ["view", "edit", "delete", "approve"],
    [Roles.STAFF]: ["view", "edit", "delete", "approve"],
  });
};
export { userGameReviewApprovalOptions };
export default userGameReviewOptions;
export { gameNewsApprovalOptions };
export { gameNewsOptions };
export { staffReviewsOptions };
export { blogOptions };
