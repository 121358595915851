import React from "react";
import "./style.css";

const ScrollArea = ({ children, maxHeight }) => {
  return (
    <div
      className="ScrollArea"
      data-testid="ScrollArea"
      style={{ "--max-height": maxHeight }}
    >
      {children}
    </div>
  );
};

export default ScrollArea;
