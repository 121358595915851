import { useState } from "react";
import relativeDate from "relative-date";
import { MetaInfo } from "../../AdultGames/Game";
import { Social } from "../../common/social";
import input_focus from "../../input_focus";
import { generateURL } from "../../resizeImages";
import { SubmitButton } from "../Common";
import { Avatar } from "src/components/common/Avatar";

const ObjectLayout = ({
  children,
  title,
  searchPage,
  valueGetter,
  searchFunction,
  pageToggle,
  addObjectsFunction,
}) => {
  input_focus("#object-selecter-search", "#object-selecter-submit");
  const [placeholder, setPlaceholder] = useState(true);

  return (
    <>
      <div className="title">
        {searchPage ? (
          <>
            <div className="search">
              <input
                type="search"
                id="object-selecter-search"
                placeholder="Search.."
                onChange={valueGetter}
              />
              <button
                className="fa fa-search"
                id="object-selecter-submit"
                onClick={() => {
                  searchFunction();
                  setPlaceholder(false);
                }}
              ></button>
            </div>
            <SubmitButton
              label={` Edit Selected ${title}`}
              request={pageToggle}
            />
          </>
        ) : (
          <SubmitButton
            label={
              <>
                <div className="fa fa-arrow-left"></div>
                back
              </>
            }
            className="add-button"
            request={pageToggle}
          />
        )}
      </div>
      {placeholder && searchPage && (
        <div className="placeholder">
          <div className="cover">
            <img loading="lazy" src="/images/search.svg" alt="" />
          </div>
          <h2>{searchPage ? `Search ${title} to upload` : ""}</h2>
        </div>
      )}

      <div className="content adult-games">{children}</div>
      <SubmitButton
        label={searchPage ? `Add ${title}` : "Save Changes"}
        request={addObjectsFunction}
        className="add-button"
      />
    </>
  );
};

const Game = ({ isAdded, game, setSelectedGames }) => {
  const {
    creator,
    creator_id,
    engine,
    followers = [],
    genre,
    id,
    last_updated,
    likes,
    square_banner,
    short_description,
    status,
    title,
    views,
  } = game;
  const days = relativeDate(new Date(last_updated));
  const add = () => {
    setSelectedGames((prev) => [...prev, game]);
  };
  const remove = () => {
    setSelectedGames((prev) => prev.filter((game) => game.id !== id));
  };
  return (
    <div className="game adult-game-container">
      <div className="image">
        <img
          loading="lazy"
          src={generateURL(200, 200, square_banner)}
          alt={title}
        />
      </div>
      <div className="title">{title}</div>
      <div className="author">
        <div className="image">
          <img loading="lazy" src="/images/user.svg" alt={creator} />
        </div>
        <div className="name">{creator}</div>
      </div>
      <div className="info">
        <div className="row">
          <div className="part line">
            <div className="title">Status</div>
            <div className="content">{status}</div>
          </div>
          <div className="part">
            <div className="title no-colon">
              <span className="material-icons">update</span>
            </div>
            <div className="content">{days}</div>
          </div>
        </div>
        <div className="row">
          <div className="part line">
            <div className="title">category</div>
            <div className="content">{genre}</div>
          </div>
          <div className="part">
            <div className="title">engine</div>
            <div className="content">{engine}</div>
          </div>
        </div>
      </div>

      <div className="description">{short_description}</div>
      <MetaInfo
        {...{ views, likes, id, creator_id }}
        followers={followers.length}
      />
      <div
        className={`button ${isAdded ? "added-btn" : "add-btn"}`}
        onClick={isAdded ? remove : add}
      >
        {isAdded ? "Remove" : "+ Add"}
      </div>
    </div>
  );
};
const Member = ({ isAdded, setSelectedTeams, team }) => {
  const {
    facebook_link,
    id,
    instagram_link,
    name,
    twitter_link,
    profile_picture,
  } = team;
  const add = () => {
    setSelectedTeams((prev) => [...prev, team]);
  };
  const remove = () => {
    setSelectedTeams((prev) => prev.filter((team) => team.id !== id));
  };
  return (
    <div className="info">
      <Avatar
        name={name}
        src={generateURL(200, 200, profile_picture)}
        width={100}
      />
      <div className="details">
        <div className="name">{name}</div>

        <div className="social">
          <Social fa_name="fa-facebook" path={facebook_link} />
          <Social fa_name="fa-twitter" path={twitter_link} />
          <Social fa_name="fa-instagram" path={instagram_link} />
        </div>
        <div
          className={`button ${isAdded ? "added-btn" : "add-btn"}`}
          onClick={isAdded ? remove : add}
        >
          {isAdded ? "Remove" : "+ Add"}
        </div>
      </div>
    </div>
  );
};
const Article = ({ article, isAdded, setSelectedArticles }) => {
  const {
    id,
    author,
    likes,
    title,
    short_description,
    square_cover,
    views,
    days,
  } = article;
  const add = () => {
    setSelectedArticles((prev) => [...prev, article]);
  };
  const remove = () => {
    setSelectedArticles((prev) => prev.filter((article) => article.id !== id));
  };
  return (
    <div className="news-mini">
      <div className="image ">
        <img
          loading="lazy"
          src={generateURL(200, 200, square_cover)}
          alt={title}
          width={200}
          height={200}
        />
      </div>
      <div className="text">
        <div className="info">
          <div className="author">
            <div className="image">
              <img loading="lazy" src="/images/user.svg" alt="" />
            </div>
            <div className="name">{author}</div>
          </div>
          <div className="date">
            <span className="fa fa-clock-o"></span>
            {days} Days ago
          </div>
        </div>

        <div className="title">{title}</div>
        <div className="description">{short_description}</div>
        <div className="likes">
          <div className="line"></div>
          <div className="data">
            <div>
              <span className="fa fa-eye"></span>
              <span>{views}</span>
            </div>
            <div>
              <span className="fa fa-heart"></span>
              <span>{likes}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`button ${isAdded ? "added-btn" : "add-btn"}`}
        onClick={isAdded ? remove : add}
      >
        {isAdded ? "Remove" : "+ Add"}
      </div>
    </div>
  );
};
export { Article };
export { Game };
export { Member };
export { ObjectLayout };
