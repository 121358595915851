import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

/**
 * One page pagination slider
 * @param {Object} props
 * @param {Number} props.value_of_section - current page number
 * @param {Array}  props.slides_array - array of contained data
 * @param {Number}  props.num_of_elements_display - elements in each page
 * @param {Function} props.func_of_section - current page number setter
 * @param {Function} props.display_array_func - data setter for each page
 * @returns {JSX.Element}
 */
const Slider = (props) => {
  // value_of_section -> the page number of data displayed
  // func_of_section 	-> the function used to set the page number (Use State)
  // last_slide 			-> last page of data displaying
  const {
    value_of_section,
    func_of_section,
    slides_array,
    display_array_func,
    num_of_elements_display,
  } = props;

  const last_slide = slides_array.length / num_of_elements_display;

  let current_slide = value_of_section - 1;

  useEffect(() => {
    display_array_func(
      slides_array.filter((value, index) =>
        index >= num_of_elements_display * current_slide &&
        index < num_of_elements_display * (current_slide + 1)
          ? value
          : ""
      )
    );
    // eslint-disable-next-line
  }, [current_slide]);
  const rounder = (value) => {
    //to get the nearest high value for last page
    return Math.floor(value) + 0.5 <= value
      ? Math.round(value)
      : Math.round(value + 1);
  };

  var last = rounder(last_slide - 0.1); // the last value of articles pages

  // incrementors & decrementors
  const increment = () => {
    if (value_of_section < last) {
      func_of_section(value_of_section + 1);
    }
  };
  const decrement = () => {
    if (value_of_section > 1) {
      func_of_section(value_of_section - 1);
    }
  };
  const exactValueSetter = (value) => {
    if (value_of_section < last) {
      func_of_section(value);
    } else {
      return;
    }
  };
  const valueSetter = (value) => {
    if (value <= last) {
      return value;
    } else {
      return 0;
    }
  };
  const Number = ({ difference }) => {
    const indicator = valueSetter(value_of_section + difference);
    if (indicator === last || indicator <= 0) return null;
    return (
      <div
        className={`number ${indicator === current_slide + 1 && "current"}`}
        onClick={() => exactValueSetter(value_of_section + difference)}
      >
        {indicator}
      </div>
    );
  };
  return (
    <div className="desktop-slider">
      <div className="material-icons">
        <span className="button" onClick={decrement}>
          navigate_before
        </span>
      </div>
      <div className="numbers">
        <Number difference={-2} />
        <Number difference={-1} />
        <Number difference={0} />
        <Number difference={1} />
        {last - 2 > value_of_section && <div className="dots">...</div>}

        <div
          className={`number last ${current_slide + 1 === last && "current"}`}
          onClick={() => exactValueSetter(last)}
        >
          {last}
        </div>
      </div>

      <div className="material-icons ">
        <span className="button" onClick={increment}>
          keyboard_arrow_right
        </span>
      </div>
    </div>
  );
};

/**
 * @param {Object} props
 * @param {number} props.total_number_of_pages - the total number of search results pages
 * @param {number} props.initial_page_number - the current page number
 * @param {Function} props.onClick
 * @returns {JSX.Element}
 */
const PaginationSlider = (props) => {
  const { total_number_of_pages, initial_page_number = 1, onClick } = props;
  const [pageNumber, setPageNumber] = useState(initial_page_number);
  const history = useHistory();

  useEffect(() => {
    setPageNumber(initial_page_number);
  }, [initial_page_number]);
  useEffect(() => {
    if (onClick) onClick(pageNumber);
    else
      history.push({
        pathname: `./${pageNumber}`,
        search: window.location.search,
      });
  }, [pageNumber]);

  // incrementors & decrementors
  const increment = () => {
    setPageNumber((prev) => {
      if (prev >= total_number_of_pages) {
        return prev;
      } else {
        return prev + 1;
      }
    });
  };
  const decrement = () => {
    setPageNumber((prev) => {
      if (prev <= 1) {
        return prev;
      } else {
        return prev - 1;
      }
    });
  };
  const Number = ({ different_of_first_number }) => {
    const currentPageNumber = pageNumber + different_of_first_number;
    const pageNumberSetter = () => {
      setPageNumber((prev) =>
        currentPageNumber >= total_number_of_pages ? prev : currentPageNumber
      );
    };
    if (currentPageNumber >= total_number_of_pages || currentPageNumber <= 0)
      return null;
    return (
      <div
        className={`number ${currentPageNumber === pageNumber && "current"}`}
        title={`The ${currentPageNumber} page of matching results`}
        onClick={pageNumberSetter}
      >
        {currentPageNumber}
      </div>
    );
  };
  return (
    <div className="desktop-slider">
      <button
        className="fa fa-chevron-left button"
        onClick={decrement}
      ></button>

      <div className="numbers">
        <Number different_of_first_number={-2} />
        <Number different_of_first_number={-1} />
        <Number different_of_first_number={0} />
        <Number different_of_first_number={1} />

        {total_number_of_pages - 2 > pageNumber && (
          <div className="dots">...</div>
        )}
        <div
          className={`number last ${
            total_number_of_pages === pageNumber && "current"
          }`}
          onClick={() => setPageNumber(total_number_of_pages)}
        >
          {total_number_of_pages}
        </div>
      </div>

      <button
        className="fa fa-chevron-right button"
        onClick={increment}
      ></button>
    </div>
  );
};
export { PaginationSlider };
export default Slider;
