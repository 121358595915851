import { useMemo } from "react";
import { getCountries } from "src/utils/fetchCountries";
import SearchSelect from "src/v2/components/form-inputs/SearchSelect";
import useSWR from "swr";

const SelectCountry = ({ value, onChange }) => {
  const { data: countries } = useSWR("countries", () => getCountries());

  const selected = useMemo(() => {
    return countries?.find((c) => c.code === value);
  }, [value]);

  return (
    <SearchSelect
      onChange={onChange}
      value={value}
      defaultSelectedLabel={selected?.name}
    >
      {countries?.map((c) => (
        <SearchSelect.Option key={c.id} label={c.name} value={c.id} />
      ))}
    </SearchSelect>
  );
};

export default SelectCountry;
