import React, { useState } from "react";
import { useMemo } from "react";
import { toast } from "react-toastify";
import RequestError from "../../../errors/RequestError";
import { useToggle } from "../../../hooks/useToggle";
import { authRequest } from "../../../utils/Axios";
import { OTP_REGEX } from "../../../utils/regex";
import { SubmitButton } from "../../CMS/Common";
import { Input } from "../General";
import { methods_2fa } from "./methods";

export const Disable = ({ name, onDisabled }) => {
  const [code, setCode] = useState();
  const [error, setError] = useState();
  const [showInput, toggleInput] = useToggle();

  const method = useMemo(
    () => methods_2fa.find((m) => m.name === name),
    [name]
  );

  const disable = async () => {
    try {
      if (!code) throw new Error("Enter Code");
      await authRequest({
        url: `/trenchauth/${name}/deactivate/`,
        method: "POST",
        data: {
          code,
        },
      });
      await onDisabled?.();
      toast.warn(`${method.label} removed`);
    } catch (error) {
      setError("Code invalid or expired.");
    }
  };

  const init = async () => {
    try {
      if (name === "email") await method.sendCode?.();
      toggleInput();
    } catch (error) {
      setError(error.message);
    }
  };
  return (
    <div className="Configure2FA--disable">
      <h2>Are you sure you want to remove {method?.label}?</h2>
      {/* <p>
        Note: You cannot deactivate a primary MFA method if there are other
        methods active! Change primary method to a different one first, and then
        deactivate the method.
      </p> */}
      <RequestError error={error} />
      {showInput ? (
        <div className="input">
          <Input callback={setCode} label="Enter Code" regex={OTP_REGEX} />
          <SubmitButton
            label="Remove Method"
            request={disable}
            disabled={!code}
          />
        </div>
      ) : (
        <SubmitButton label="Confirm" request={init} />
      )}
    </div>
  );
};
