import React from "react";
import { EditCollection } from "../../SpicyArt/UploadCollection/EditCollection";

const ArticleMedia = ({ galleryID }) => {
  return (
    <section id="media">
      <legend>MEDIA GALLERY</legend>

      <div>
        <EditCollection
          selectedPages={["images"]}
          firstPageId="images"
          defaultId={galleryID}
        />
      </div>
    </section>
  );
};

export default ArticleMedia;
