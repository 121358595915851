import axios from "axios";
import dateFormat from "dateformat";
import { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import relativeDate from "relative-date";
import { useSpicyStore } from "src/providers/SpicyStoreProvider";
import useSWR from "swr";
import RequestError from "../../../errors/RequestError";
import { authRequest } from "../../../utils/Axios";
import { LoaderInside } from "../../Common";
import { Input } from "../../Member/General";
import TeamMember from "../../Member/TeamMember";
import { authorizedRequest } from "../../Validation";
import { generateURL } from "../../resizeImages";
import { Finder, SubmitButton } from "../Common";
import { Roles, useRoleInfo } from "../RoleContext";
import { ApproveTile, ContentTile } from "../Tile";
import "../css/TilePopups.css";
import { teamGamesOptions } from "./Options";
import { toSpicyTokensWithEuro } from "./Withdraw";

const Ban = ({ username, close, banned }) => {
  useEffect(() => clearTimeout(2500), []);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const banUser = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url: window.domainName + `/api/members/ban?username=${username}`,
        method: "POST",
      });
      setLoading(false);
      setError(res.success);
      if (res.success) {
        setTimeout(() => {
          close();
        }, 2500);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  if (banned) {
    return (
      <div className="ban">
        {error && <div className="error">{error}</div>}
        {loading && <LoaderInside />}
        <h1 className="header">Banned User. Unban him?</h1>
        <p>{username} is already banned.</p>

        <div className="buttons">
          <div className="button  red" onClick={close}>
            Keep banned.
          </div>
          <div className="button green" onClick={banUser}>
            Unban Him
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="ban">
      {error && <div className="error">{error}</div>}
      {loading && <LoaderInside />}
      <h1 className="header">Ban {username} ?</h1>
      <p>
        Banning a User will disable his permissions in the system. He won't able
        to Add comments, ratings and such things
      </p>
      <div className="buttons">
        <div className="button green" onClick={close}>
          No
        </div>
        <div className="button  red" onClick={banUser}>
          Yes, Ban him!
        </div>
      </div>
    </div>
  );
};

const ViewTeams = ({ username }) => {
  const [loading, setLoading] = useState(false);
  const fetcher = async (url) => {
    setLoading(true);
    const res = await authorizedRequest({ url, method: "GET" }, []);
    setLoading(false);

    return res;
  };

  const { data } = useSWR(
    `${window.domainName}/api/content-creators/teams?username=${username}`,
    fetcher
  );

  const teams = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];

  if (loading) return <LoaderInside />;
  return (
    <div id="content-creator" className="team-view">
      <div className="developers">
        <h3>{username}'s Teams</h3>
        <div className="team-info">
          {teams.length > 0 ? (
            teams.map((team, i) => (
              <TeamMember
                logo={team.profile_picture}
                name={team.name}
                key={i}
                members_count={team.no_of_members}
              />
            ))
          ) : (
            <p>{username} has no Teams</p>
          )}
        </div>
      </div>
    </div>
  );
};
/**
 *
 * @param {Object} props
 * @param {String} props.url - /url-you-need?username=`<username>`&role-or-something=`<role>`
 * @returns
 */
const ChangeRole = (props) => {
  const { roles = [], url, username, subrole, team_id } = props;
  const [submitted, setSubmitted] = useState(false);
  const [newRole, setRole] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const submit = async (e) => {
    let backendURL =
      window.domainName +
      url.replace("<username>", username).replace("<role>", newRole);
    if (team_id) backendURL = backendURL.replace("<team_id>", team_id);

    setLoading(true);
    const res = await authorizedRequest({
      url: backendURL,
      method: "POST",
    });
    setLoading(false);
    setError(res.error);

    if (!res.error) setSubmitted(true);
  };
  if (loading) return <LoaderInside />;

  if (submitted) {
    if (subrole)
      return (
        <div>
          <center>
            <h1>Sub role changed</h1>
          </center>
        </div>
      );

    return (
      <div>
        <center>
          <h1>Request Sent</h1>
          <br />
          <p>Role will be changed after reviewing</p>
        </center>
      </div>
    );
  }
  return (
    <div id="game-upload-link" className="role-change">
      <div className="title">What Role You Changing to?</div>

      {error && <div className="error">{error}</div>}
      <div className="inputs">
        <div className="input">
          <div className="name">Select Role from below</div>
          <select
            name=""
            id="request-role"
            onChange={(e) => setRole(e.target.value)}
          >
            <option>- Select from below</option>

            {roles.map((item, i) => (
              <option key={i} value={item.param}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      <SubmitButton
        label="Changing Role"
        request={submit}
        disabled={!!!newRole}
      />
    </div>
  );
};

const DeleteTeam = ({ name, members, games, created, close, id }) => {
  const { auth } = useRoleInfo();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const deleteGame = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url:
          window.domainName + `/api/content-creator-teams/delete?team_id=${id}`,
        method: "DELETE",
      });

      setError(res.success || res.error);
      setLoading(false);
      auth && auth();
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;

  return (
    <div className="game-delete">
      <h2 className="header">Delete Team {name}?</h2>

      <ul className="info">
        <li>
          No. of Games this team Created - <strong>{games}</strong>
        </li>

        <li>Created on {dateFormat(created, "default")}</li>
        <li>
          No. of Members this team have - <strong>{members}</strong>{" "}
        </li>
        <li>
          View Team Profile -{" "}
          <a href={`/team/@${name}`} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        </li>
      </ul>

      <p>
        This action cannot be undone. Once you remove a Team, It will be removed
        completely from the system
      </p>
      <div className="buttons">
        <div className="button green" onClick={close}>
          No
        </div>
        <div className="button  red" onClick={deleteGame}>
          Delete it.
        </div>
      </div>
    </div>
  );
};

const TeamMembers = ({ team, id, team_role, logged_in_user_role }) => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  const getRoles = async (title, setState) => {
    const req = await axios({
      url: window.domainName + "/products/api/game-filters?title=" + title,
    });
    const res = await req.data;
    const rolesList = await res.option;
    setState(rolesList);
    return rolesList;
  };
  useEffect(() => {
    getRoles("team_role", setRoles);
  }, []);

  const headers = ["USERNAME", "TEAM ROLE", "SUB ROLE", "LAST ACTIVITY"];

  const fetcher = async (url) => {
    setLoading(true);
    const res = await authorizedRequest({ url, method: "GET" }, []);
    setLoading(false);

    return res;
  };

  const { data, mutate } = useSWR(
    `${window.domainName}/api/content-creator-teams/members?team_id=${id}`,
    fetcher
  );

  const members = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];

  if (loading) return <LoaderInside />;

  return (
    <div id="content-creator" className="team-view">
      <main className="popup-tile">
        <center>
          <h1>Members of {team}</h1>
        </center>
        <br />
        <div
          className="header"
          style={{
            gridTemplateColumns: `repeat(${headers.length}, var(--game-item-frac)) 2fr`,
          }}
        >
          {headers.map((header, i) => (
            <div className="item" key={i}>
              {header}
            </div>
          ))}
        </div>
        <div className="body ">
          {members.length <= 0 && (
            <center>
              <br />
              <h3>No members yet</h3>
            </center>
          )}
          {members.map((member, i) => {
            const data = [
              member.username,
              member.team_role,
              member.subrole,
              relativeDate(new Date(member.last_sign_in)),
            ];
            let options = [
              {
                icon: "fa-user",
                text: "View His Profile",
                id: "view_profile",
                popup: () => <Redirect to={`/@${member.username}`} />,
              },
            ];

            if (logged_in_user_role === Roles.ADMIN || team_role === "Leader") {
              options = [
                ...options,
                {
                  icon: "fa-users",
                  text: "Change Team Role",
                  id: "change_member_role",
                  popup: ChangeRole,
                },
                {
                  icon: "fa-trash-o",
                  text: "Remove from Team",
                  id: "remove_member",
                  popup: RemoveMember,
                },
              ];
            }
            return (
              <ApproveTile
                headers={headers}
                key={i}
                data={data}
                revalidate={() => mutate(members, true)}
                options={options}
                popupData={{
                  remove_member: {
                    id: member.id,
                    username: member.username,
                    team_id: id,
                  },
                  change_member_role: {
                    roles,
                    role: member.role,
                    username: member.username,
                    team_id: id,
                    url: "/api/members/team-role-change?username=<username>&role=<role>&team_id=<team_id>",
                  },
                }}
              />
            );
          })}
        </div>
      </main>
    </div>
  );
};

export const RemoveMember = ({ id, team_id, username, close }) => {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [error, setError] = useState();
  const remove = async () => {
    setLoading(true);
    try {
      const res = await authRequest({
        url: `/api/content-creator-teams/members/remove`,
        method: "POST",
        data: {
          team_id: team_id,
          creator_ids: [id],
        },
      });
      setMsg(res.success);
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  };
  return (
    <div className="role-approve">
      {loading && <LoaderInside />}
      {msg && <div className="error">{msg}</div>}
      <h2>Remove {username} ?</h2>

      <br />
      <br />
      <p>This Member will removed from you team</p>

      <br />
      <br />
      <RequestError error={error} />

      <div className="buttons">
        <div className="button green" onClick={close}>
          No
        </div>
        <div className="button  red" onClick={remove}>
          Remove him!
        </div>
      </div>
    </div>
  );
};

const TeamGames = ({ team, id, role: team_role, user_role }) => {
  const [loading, setLoading] = useState(false);

  const headers = ["TITLE", "CREATOR", "LAST UPDATED", "CREATION DATE"];

  const fetcher = async (url) => {
    setLoading(true);
    const res = await authorizedRequest({ url, method: "GET" }, []);
    setLoading(false);

    return res;
  };

  const { data, mutate } = useSWR(
    `${window.domainName}/api/content-creator-teams/games?team_id=${id}`,
    fetcher
  );

  const games = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];

  if (loading) return <LoaderInside />;

  return (
    <div id="content-creator" className="team-view">
      <main className="popup-tile">
        <center>
          <h1>Games by {team}</h1>
        </center>
        <br />
        <div
          className="header"
          style={{
            gridTemplateColumns: `repeat(${headers.length}, var(--game-item-frac)) 2fr`,
          }}
        >
          {headers.map((header, i) => (
            <div className="item" key={i}>
              {header}
            </div>
          ))}
        </div>
        <div className="body ">
          {games.length <= 0 && (
            <center>
              <br />
              <h3>No Games yet</h3>
            </center>
          )}
          {games.map((game, i) => {
            const data = [
              game.title,
              game.creator,
              relativeDate(new Date(game.last_updated)),
              dateFormat(new Date(game.creation_date), "longDate"),
            ];
            const commonProps = {
              title: game.title,
              creator: game.creator,
              created: game.creation_date,
              id: game.id,
            };

            const options = teamGamesOptions(team_role, user_role, game);
            return (
              <ApproveTile
                headers={headers}
                key={i}
                data={data}
                revalidate={() => mutate(games, true)}
                options={options}
                isBanned={!game.published}
                popupData={{
                  edit: { id: game.id },
                  publish: commonProps,
                  delete: commonProps,
                  approve: commonProps,
                }}
              />
            );
          })}
        </div>
      </main>
    </div>
  );
};
const TeamTransactions = ({ name, id }) => {
  const [loading, setLoading] = useState(false);

  const headers = ["PRODUCT TYPE", "ID", "AMOUNT (SG)", "PURCHASER", "DATE"];

  const fetcher = async (url) => {
    setLoading(true);
    const res = await authRequest({ url, method: "GET" });
    setLoading(false);

    return res;
  };

  const { data, mutate, error } = useSWR(
    `/store/api/teams/sales/list?team_id=${id}`,
    fetcher
  );

  const transactions =
    data?.flat().filter((obj) => !(obj.error || obj.last_page)) ?? [];

  if (loading) return <LoaderInside />;

  return (
    <div className="team-view team-transactions">
      <main className="cms-details">
        <RequestError error={error} />
        <center>
          <h2>Transactions of {name}</h2>
        </center>
        <br />
        <div
          className="header"
          style={{
            gridTemplateColumns: `var(--game-item-frac) 4fr repeat(${
              headers.length - 2
            }, var(--game-item-frac)) 2fr`,
          }}
        >
          {headers.map((header, i) => (
            <div className="item" key={i}>
              {header}
            </div>
          ))}
        </div>
        <p className="instructions list">Transaction Refunded</p>
        <div className="body">
          {transactions.map((transaction, i) => {
            const data = [
              transaction.product_type,
              transaction.transaction_id,
              toSpicyTokensWithEuro(transaction.amount - transaction.fee),
              transaction.purchaser,
              relativeDate(new Date(transaction.creation_date)),
            ];

            return (
              <ContentTile
                headers={headers}
                key={i}
                data={data}
                revalidate={() => mutate(transactions, true)}
                color={transaction.is_refunded ? "#ff0400" : ""}
              />
            );
          })}
        </div>
      </main>
    </div>
  );
};

const LeaveTeam = ({ name, close, id }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const leave = async () => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: `/api/content-creators/teams/leave`,
        method: "POST",
        params: {
          team_id: id,
        },
      });

      setError(res.success || res.error);
      setLoading(false);
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;

  return (
    <div className="game-delete">
      <h2 className="header">Leave Team {name}?</h2>
      <br />
      <br />
      <p>By leaving the team, You will not a member of {name} anymore!</p>
      <br />
      <br />
      <div className="buttons">
        <div className="button green" onClick={close}>
          No
        </div>
        <div className="button  red" onClick={leave}>
          Leave
        </div>
      </div>
    </div>
  );
};

const Member = ({ invite, isAdded, member }) => {
  const [invited, setInvited] = useState(false);
  const {
    id,
    username,
    role,
    subrole,
    profile_picture,
    facebook_link,
    instagram_link,
    twitter_link,
  } = member;

  const sendInvite = async () => {
    await invite(id);
    setInvited(true);
  };
  return (
    <div className="info">
      <div className="logo">
        {profile_picture ? (
          <div className="image">
            <img
              loading="lazy"
              src={generateURL(200, 200, profile_picture)}
              alt=""
            />
          </div>
        ) : (
          <div className="avatar">{username[0]}</div>
        )}
      </div>
      <div className="details">
        <div className="name" title={username}>
          {username}
        </div>
        <div className="title">
          <div className="cat">
            <span className="name">role</span>
            <span className="value">{role}</span>
          </div>
          <div className="cat">
            <span className="name">sub role</span>
            <span className="value">{subrole}</span>
          </div>
        </div>
        <div className="social">
          {facebook_link && (
            <Link to={facebook_link}>
              <span className="fa fa-facebook"></span>
            </Link>
          )}
          {twitter_link && (
            <Link to={twitter_link}>
              <span className="fa fa-twitter"></span>
            </Link>
          )}
          {instagram_link && (
            <Link to={instagram_link}>
              <span className="fa fa-instagram"></span>
            </Link>
          )}
        </div>
        <SubmitButton
          request={sendInvite}
          type={invite && !invited ? "primary" : "secondary"}
          label={
            invite && !invited ? (isAdded ? "Remove" : "+ Invite") : "Invited!"
          }
          disabled={!(invite && !invited)}
        />
      </div>
      <br />
      <br />
    </div>
  );
};

const inviteCreators = async (creator_id, team_id) => {
  // send it

  await authorizedRequest({
    url: `${window.domainName}/api/content-creator-teams/invite-creator`,
    method: "POST",
    data: {
      team_id,
      creator_id,
    },
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const InviteCreator = ({ id }) => {
  const [query, setQuery] = useState("");
  const [error, setError] = useState();
  const [inviteableCreators, setInviteableCreators] = useState([]);
  const [loading, setLoading] = useState(false);

  const search = async (e) => {
    setLoading(true);
    e.preventDefault();

    const creators = await authorizedRequest({
      url: `${window.domainName}/api/content-creator-teams/inviteable-creators/search`,
      params: { team_id: id, search_term: query },
    });
    if (creators.error) setError(creators.error);
    else setInviteableCreators(creators);

    setLoading(false);
  };

  const invite = (team_id) => async (creator_id) => {
    return await inviteCreators(creator_id, team_id);
  };

  return (
    <div className="add-things" id="add-things">
      <section>
        <legend>Send Invitations</legend>
        <div className="body">
          <form onSubmit={search}>
            <div className="search">
              <input
                type="search"
                id="send-invitations"
                placeholder="Search.."
                onChange={(e) => setQuery(e.target.value)}
              />
              <button className="fa fa-search" type="submit"></button>
            </div>
          </form>
          <br />
          {loading && <LoaderInside />}
          {error && <div className="error">{error}</div>}

          <div id="create-team">
            <div className="team-members">
              <div className="content">
                {inviteableCreators.length > 0 ? (
                  inviteableCreators.map((member) => (
                    <Member
                      member={member}
                      isAdded={false}
                      invite={invite(id)}
                    />
                  ))
                ) : (
                  <center>
                    <p>Nothing found</p>
                  </center>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const AllInvites = ({ id }) => {
  const [invites, setInvites] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getInvites();
  }, []);
  const getInvites = async () => {
    try {
      const res = await authRequest({
        url: `/api/content-creator-teams/sent-invitations`,
        params: {
          team_id: id,
        },
      });
      if (!res.error) setInvites(res);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-things all-invites" id="add-things">
      <section>
        <legend>All Invitations</legend>
        <div className="body">
          {loading && <LoaderInside />}
          <div id="create-team">
            <div className="team-members">
              <div className="content">
                {invites.map((member) => (
                  <Member
                    key={member.username}
                    member={member}
                    isAdded={false}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const AcceptInvite = ({
  team_id,
  creator_id,
  date_invited,
  team,
  close,
  goingToAccept,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const acceptOrDeny = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url: `${window.domainName}/api/content-creators/invitations/accept`,
        method: "POST",
        params: {
          team_id,
          creator_id,
          accept: goingToAccept,
        },
      });
      setLoading(false);
      if (res.error) setError(res.error);

      if (res.success) {
        setError(res.success);
        setTimeout(() => {
          close();
        }, 2000);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="game-delete">
      {error && <div className="error">{error}</div>}
      {loading && <LoaderInside />}
      <h1 className="header">
        {goingToAccept ? "Accept" : "Deny"} Invitation from {team} ?
      </h1>
      <div className="info">
        <p>Invited on {date_invited}</p>
      </div>

      <p>
        Hey, <br />
        This is an invitation to you to join the Team <strong>{team}</strong>.
      </p>
      <br />
      <div className="buttons">
        <div
          className={`button ${!goingToAccept ? "green" : "red"}`}
          onClick={close}
        >
          Accept Later
        </div>
        <div
          className={`button ${goingToAccept ? "green" : "red"}`}
          onClick={acceptOrDeny}
        >
          {goingToAccept ? "Accept it!" : "Deny Invite"}
        </div>
      </div>
    </div>
  );
};
const MakeAsTrustedCreator = ({ id, close, isTrusted, username }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const acceptOrDeny = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url: `${window.domainName}/api/content-creators/mark-as-trusted`,
        method: "POST",
        params: {
          creator_id: id,
          assign: !isTrusted,
        },
      });

      setLoading(false);
      if (res.error) setError(res.error);

      if (res.success) {
        setError("Successfully Changed");
        setTimeout(() => {
          close();
        }, 2000);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="game-delete">
      {error && <div className="error">{error}</div>}
      {loading && <LoaderInside />}
      <h1 className="header">
        {isTrusted ? "Demote to Creator" : "Promote as Trusted Creator"} ?
      </h1>
      <div className="info">
        <p>Username - {username}</p>
      </div>

      <p>
        {isTrusted
          ? "This user will be just content creator after accepting this."
          : "This user will get all benefits as a trusted content creator after accepting this"}
      </p>
      <br />
      <div className="buttons">
        <div
          className={`button ${isTrusted ? "green" : "red"}`}
          onClick={close}
        >
          Go Back
        </div>
        <div
          className={`button ${!isTrusted ? "green" : "red"}`}
          onClick={acceptOrDeny}
        >
          Accept it!
        </div>
      </div>
    </div>
  );
};

export const ApproveTeam = ({ close, approve, name, id }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const approveTeam = async () => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: `/api/content-creator-teams/approve`,
        method: "POST",
        data: {
          team_id: id,
          approve,
        },
      });

      setLoading(false);
      if (res.success)
        setError(approve ? "Successfully Approved" : "Successfully Denied");
      if (res.error) return setError(res.error);
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;

  return (
    <div className="game-approve">
      <h2>
        {approve ? "Approve" : "Deny"} Team {name}?
      </h2>
      <br />
      <div className="buttons">
        <div className={`button ${!approve ? "green" : "red"}`} onClick={close}>
          Close
        </div>
        <div
          className={`button ${approve ? "green" : "red"}`}
          onClick={approveTeam}
        >
          {approve ? "Approve it!" : "Deny"}
        </div>
      </div>
    </div>
  );
};

export const SetAsDefaultTeam = ({ close, id, name }) => {
  const { auth } = useRoleInfo();
  const setAsDefault = async () => {
    try {
      const res = await authRequest({
        url: "/api/content-creators/set-default-team",
        method: "POST",
        data: {
          team_id: id,
        },
      });

      if (res.success) {
        await auth?.();
        toast.success(
          `Team "${name}" has been successfully set as the default team`
        );
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      close?.();
    }
  };
  return (
    <div className="set-as-default">
      <h2>Set {name} as Default Team?</h2>
      <p>
        Default team will be linked to all the quick links in the CMS. for
        example quick withdrawal or sales link in the menu.
      </p>

      <SubmitButton label="Continue" request={setAsDefault} />
    </div>
  );
};
export const SendTokens = ({ close, id, name }) => {
  const { refresh } = useSpicyStore();
  const [amount, setAmount] = useState(0);
  const send = async () => {
    try {
      const res = await authRequest({
        url: "/store/api/members/token-transfers",
        method: "POST",
        data: {
          receiver_id: id,
          amount,
        },
      });

      if (res.success) {
        await refresh?.();
        toast.success(`Tokens Successfully sent`);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      close?.();
    }
  };
  return (
    <div className="set-as-default">
      <h2>Send Tokens to {name}?</h2>
      <p>
        The amount of tokens you entered will be transferred to this user.
        <br />
        Tokens will be deducted from your account
      </p>
      <Input
        label={"Amount (Spicygaming Tokens)"}
        callback={setAmount}
        placeholder={1000}
      />

      <SubmitButton label="Send Tokens" request={send} />
    </div>
  );
};
export const RewardGame = ({ close, user_id }) => {
  const [game, setGame] = useState();
  const send = async () => {
    try {
      const res = await authRequest({
        url: "/store/api/members/reward-game",
        method: "POST",
        data: {
          receiver_id: user_id,
          game_id: game,
        },
      });

      if (res.success) {
        toast.success(`Game rewarded successfully`);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      close?.();
    }
  };
  return (
    <div className="reward-game">
      <Finder
        id="game_id"
        fieldNameWantInRequest="id"
        label="Select Game"
        onChange={(e) => setGame(e.target.value)}
        fieldName="title"
        requestLink={
          window.domainName +
          "/api/content-creators/games/search?title=<search>"
        }
      />

      <br />

      <SubmitButton label="Reward game" request={send} />
    </div>
  );
};

export const EditAdZone = ({ close, id, name }) => {
  const [zone, setZone] = useState();
  const send = async () => {
    try {
      const res = await authRequest({
        url: "/api/content-creator-teams/edit-ad-zone",
        method: "POST",
        data: {
          team_id: id,
          zone_id: zone,
        },
      });

      if (res.success) {
        toast.success(`Edited Ad zone successfully successfully`);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      close?.();
    }
  };
  return (
    <div className="ad-zone">
      <h2>Edit Ad zone of {name}</h2>
      <br />
      <Input label="Ad Zone" callback={(zone) => setZone(zone)} />

      <SubmitButton label="Save" request={send} disabled={!zone} />
    </div>
  );
};

export {
  AcceptInvite,
  AllInvites,
  Ban,
  ChangeRole,
  DeleteTeam,
  InviteCreator,
  LeaveTeam,
  MakeAsTrustedCreator,
  TeamGames,
  TeamMembers,
  TeamTransactions,
  ViewTeams,
  inviteCreators,
};
