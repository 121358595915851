import { BASE_CDN_URL, request } from "src/utils/Axios";
import { sort } from "../Common";
import {
  Featured,
  getPublicFeaturedContent,
} from "../CMS/Ads/FeaturedListPages/util";
import { withoutLastPage } from "src/utils/withoutLastPage";

export const withArticleMetadataType = (list, type) =>
  list.map((article) => ({
    ...article,
    metadata: { id: article.id, type },
  }));

export const getLatestGameNews = async () => {
  try {
    const res = await request({
      url: "/news/api/news/game-news/latest",
      baseURL: BASE_CDN_URL,
      params: {
        home: true,
        num: 6,
      },
    });
    return sort(
      "date_posted",
      withArticleMetadataType(withoutLastPage(res), "Game News"),
      "dsc"
    );
  } catch (error) {
    return [];
  }
};

export const getMostReadNews = async () => {
  try {
    const res = await getPublicFeaturedContent(Featured.HOME_MOST_READ);
    return sort("date_posted", res, "dsc");
  } catch {
    return [];
  }
};
export const getGameNewsForYou = async () => {
  try {
    const res = await request({
      url: "/news/api/news/game-news/articles/search",
      baseURL: BASE_CDN_URL,
      params: {
        num: 6,
        home: true,
        page: 1,
      },
    });
    return sort(
      "date_posted",
      withArticleMetadataType(withoutLastPage(res), "Game News"),
      "dsc"
    );
  } catch {
    return [];
  }
};
export const getBestOfPorn = async () => {
  try {
    const res = await request({
      url: "/news/api/news/blog-posts/articles/search",
      baseURL: BASE_CDN_URL,
      params: {
        category: "Best of",
        num: 6,
        home: true,
        page: 1,
      },
    });
    return sort(
      "date_posted",
      withArticleMetadataType(withoutLastPage(res), "Blog Posts"),
      "dsc"
    );
  } catch (error) {
    return [];
  }
};
export const getInterviews = async () => {
  try {
    const res = await request({
      url: "/news/api/news/blog-posts/articles/search",
      baseURL: BASE_CDN_URL,
      params: {
        category: "Interview",
        num: 6,
        home: true,
        page: 1,
      },
    });
    return sort(
      "date_posted",
      withArticleMetadataType(withoutLastPage(res), "Blog Posts"),
      "dsc"
    );
  } catch (error) {
    return [];
  }
};

export const getBestPornGamesOfYear = async () => {
  try {
    const res = await request({
      url: "/products/api/featured-spots/featured-list",
      baseURL: BASE_CDN_URL,
      params: { position: Featured.BEST_PORN_GAMES, articles_only: true },
    });
    return sort("date_posted", res, "dsc");
  } catch (error) {
    return [];
  }
};

export const getLatestIndustryBlog = async () => {
  try {
    const res = await request({
      url: "/news/api/news/blog-posts/articles/search",
      baseURL: BASE_CDN_URL,
      params: {
        category: "Adult Gaming Industry",
        num: 6,
        home: true,
        page: 1,
      },
    });
    return sort(
      "date_posted",
      withArticleMetadataType(withoutLastPage(res), "Blog Posts"),
      "dsc"
    );
  } catch (error) {
    return [];
  }
};
export const getAdultGameReviews = async () => {
  try {
    const res = await request({
      url: "/news/api/news/staff-reviews/articles/search",
      baseURL: BASE_CDN_URL,
      params: {
        num: 6,
        home: true,
        page: 1,
      },
    });
    return sort(
      "date_posted",
      withArticleMetadataType(withoutLastPage(res), "Staff Reviews"),
      "dsc"
    );
  } catch (error) {
    return [];
  }
};
