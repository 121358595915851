import dateFormat from "dateformat";
import { useEffect, useState } from "react";
import RequestError from "../../../errors/RequestError";
import { authRequest } from "../../../utils/Axios";
import { LoaderInside } from "../../Common";
import Trophy from "../../GameDetails/Trophy";
import { authorizedRequest } from "../../Validation";
import { SubmitButton } from "../Common";
import { Roles } from "../RoleContext";
import { toSpicyTokensWithEuro } from "../Users/Withdraw";

const Delete = ({ title, creator, created, close, id }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const deleteGame = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url: window.domainName + `/products/api/games/delete?game_id=${id}`,
        method: "DELETE",
      });

      setError(res.success || res.error);
      setLoading(false);
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;

  return (
    <div className="game-delete">
      <h2 className="header">Delete {title}?</h2>

      <div className="info">
        <p>
          Game by <strong>{creator}</strong>
        </p>
        <p>Created on {dateFormat(created, "default")}</p>
      </div>

      <p>
        This action cannot be undone. Once you remove a game, It will be removed
        completely from the system
      </p>
      <div className="buttons">
        <div className="button green" onClick={close}>
          No
        </div>
        <div className="button  red" onClick={deleteGame}>
          Delete it.
        </div>
      </div>
    </div>
  );
};

const ApproveGame = ({ title, creator, created, close, id }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const approveGame = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url:
          window.domainName +
          `/products/api/games/approve?game_id=${id}&approve=true`,
        method: "POST",
      });

      setError(res.success || res.error);
      setLoading(false);
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;

  return (
    <div className="game-approve">
      <h2>Approve {title} ?</h2>
      <div className="info">
        <p>
          Game by <strong>{creator}</strong>
        </p>
        <p>Created on {dateFormat(created, "default")}</p>
      </div>
      <p>
        Once you approve this game. This will appear in search results and
        available for downloading
      </p>
      <div className="buttons">
        <div className="button red" onClick={close}>
          No
        </div>
        <div className="button  green" onClick={approveGame}>
          Yes, Approve it!
        </div>
      </div>
    </div>
  );
};

const DenyGame = ({ title, creator, created, close, id }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const denyGame = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url:
          window.domainName +
          `/products/api/games/approve?game_id=${id}&approve=false`,
        method: "POST",
      });

      setError(res.success || res.error);
      setLoading(false);
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;

  return (
    <div className="game-approve">
      <h2>Deny {title} ?</h2>
      <div className="info">
        <p>
          Game by <strong>{creator}</strong>
        </p>
        <p>Created on {dateFormat(created, "default")}</p>
      </div>
      <p>
        If you deny this game, this will disappear from search results and users
        cannot download this game anymore. This will not removed from the
        Database. You can approve this game in anytime
      </p>
      <div className="buttons">
        <div className="button green" onClick={close}>
          No
        </div>
        <div className="button  red" onClick={denyGame}>
          Deny it!
        </div>
      </div>
    </div>
  );
};
const PublishGame = ({
  title,
  creator,
  created,
  close,
  id,
  publish = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const denyGame = async () => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: `/products/api/games/publish`,
        method: "POST",
        params: {
          game_id: id,
          publish,
        },
      });

      setTimeout(() => {
        close();
      }, 1000);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;

  return (
    <div className="game-approve">
      <h2>
        {!publish && "Un"}Publish {title} ?
      </h2>
      <div className="info">
        <p>
          Game by <strong>{creator}</strong>
        </p>
        <p>Created on {dateFormat(created, "default")}</p>
      </div>
      {publish ? (
        <p>
          Publishing this game, will appear in the search results and users can
          download this game.
        </p>
      ) : (
        <p>
          If you unpublish this game, this will disappear from search results
          and users cannot download this game anymore. This will not be removed.
          You can publish this game in anytime
        </p>
      )}
      <div className="buttons">
        <div className="button green" onClick={close}>
          No
        </div>
        <div className="button  red" onClick={denyGame}>
          {!publish && "Un"}Publish it!
        </div>
      </div>
    </div>
  );
};

const GameAwards = ({ id, title, role }) => {
  const [loading, setLoading] = useState(true);
  const [awards, setAwards] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    getAwards();
  }, []);
  const getAwards = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url: window.domainName + `/products/api/games/${id}/awards`,
      });

      setLoading(false);
      if (res.error) return setError(res.error);

      setAwards(res);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };
  const removeAward = (award_name) => async () => {
    try {
      const res = await authorizedRequest({
        url: `${window.domainName}/products/api/game-awards/assign`,
        method: "POST",
        params: {
          game_id: id,
          award_name,
          assign: false,
        },
      });
      if (res.success) {
        setAwards((prev) => prev.filter((award) => award.name !== award_name));
        return setError(`Successfully removed award ${award_name}`);
      }
    } catch (error) {}
  };
  return (
    <div className="awards">
      <h2>Awards for {title}</h2>
      {loading && <LoaderInside />}
      {error && <div className="error">{error}</div>}
      <div className="content">
        {!!awards.length ? (
          awards.map((award, key) => (
            <div className="award">
              <Trophy icon={award.icon} text={award.name} key={key} />
              {role === Roles.ADMIN && (
                <SubmitButton
                  label="Remove award"
                  request={removeAward(award.name)}
                />
              )}
            </div>
          ))
        ) : (
          <center>No awards yet</center>
        )}
      </div>
    </div>
  );
};

const AddGameAward = ({ id, title }) => {
  const [loading, setLoading] = useState(true);
  const [awards, setAwards] = useState([]);
  const [error, setError] = useState(null);
  const [addedAwards, setAddedAwards] = useState([]);
  useEffect(() => {
    getAwards(`/products/api/games/${id}/awards`, setAddedAwards);
    getAwards("/products/api/game-awards/list", setAwards);
  }, []);
  const getAwards = async (url, setState) => {
    try {
      const res = await authorizedRequest({
        url: window.domainName + url,
      });

      setLoading(false);
      if (res.error) return setError(res.error);

      setState(res);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };
  const addAward = (award) => async () => {
    try {
      const res = await authorizedRequest({
        url: `${window.domainName}/products/api/game-awards/assign`,
        method: "POST",
        params: {
          game_id: id,
          award_name: award.name,
          assign: true,
        },
      });
      if (res.success) {
        setAddedAwards((prev) => [...prev, award]);
        return setError(`Successfully added award ${award.name}`);
      }
    } catch (error) {}
  };
  return (
    <div className="awards">
      <h2>Add Award for {title}</h2>
      {loading && <LoaderInside />}
      {error && <div className="error">{error}</div>}
      <div className="content">
        {!!awards.length ? (
          awards.map((award, key) => (
            <div className="award">
              <Trophy icon={award.icon} text={award.name} key={key} />

              <SubmitButton
                label="Add award"
                request={addAward(award)}
                disabled={addedAwards
                  .map((award) => award.name)
                  .includes(award.name)}
              />
            </div>
          ))
        ) : (
          <center>No awards yet</center>
        )}
      </div>
    </div>
  );
};

const DeleteReportedLink = ({ close, uuid, game, date }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const deleteLink = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url: window.domainName + `/products/api/broken-links/delete`,
        method: "DELETE",
        params: {
          link_uuid: uuid,
        },
      });
      if (res.success) setError("Successfully removed.");
      else if (res.error) setError(res.error);

      setLoading(false);
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;

  return (
    <div className="game-delete">
      <h2 className="header">Resolved {game} Link?</h2>

      <div className="info">
        <p>
          Reported <strong>{date}</strong>
        </p>
      </div>

      <p>
        If you have checked the link that was reported and corrected it. You can
        remove the reported link notification by clicking the resolved button
        below.
      </p>
      <div className="buttons">
        <div className="button red" onClick={close}>
          Not yet
        </div>
        <div className="button  green" onClick={deleteLink}>
          Resolved
        </div>
      </div>
    </div>
  );
};
export const SendWarning = ({ close, id, title }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const send = async () => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: "/products/api/games/send-warning",
        method: "POST",
        data: {
          game_id: id,
        },
      });
      if (res.success) setError("Successfully sent.");
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoaderInside />;

  return (
    <div className="game-delete">
      <h2 className="header">Send warning about {title}?</h2>
      <div className="info"></div>
      <p>
        This will send a warning message to the owner about the game and
        unapprove it.
      </p>
      <RequestError error={error} />
      <div className="buttons">
        <SubmitButton label="Send" request={send} />
      </div>
    </div>
  );
};

const FullTransaction = ({ sale, fullData }) => {
  const data = [
    { label: "Product", content: sale.product },
    { label: "Product ID", content: sale.product_id },
    { label: "Product Type", content: sale.product_type },
    { label: "Team", content: sale.team },
    { label: "Amount", content: toSpicyTokensWithEuro(sale.amount) },
    { label: "Fee", content: toSpicyTokensWithEuro(sale.fee) },
    { label: "Created On", content: dateFormat(sale.creation_date) },
    { label: "Transaction ID", content: sale.transaction_id },
  ];
  return (
    <div className="transaction-popup">
      <h1>#{sale.transaction_id}</h1>
      <table className="info">
        {(fullData ?? data).map((record, i) => (
          <tr className="record" key={i}>
            <td className="title">{record.label}</td>
            <td className="content">{record.content}</td>
          </tr>
        ))}
      </table>
    </div>
  );
};

export { FullTransaction };
export { DeleteReportedLink };
export { AddGameAward };
export { GameAwards };
export { PublishGame };
export { DenyGame };
export { ApproveGame };
export { Delete };
