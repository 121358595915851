import { generatePath, Redirect } from "react-router-dom";

import { RedirectTo } from "../Common";
import { Delete, PublishGame } from "../Games/OptionPopups";
import { Roles } from "../RoleContext";
import { roleFilter } from "../RoleFilter";
import {
  AcceptInvite,
  AllInvites,
  ApproveTeam,
  Ban,
  ChangeRole,
  DeleteTeam,
  EditAdZone,
  InviteCreator,
  LeaveTeam,
  MakeAsTrustedCreator,
  RewardGame,
  SendTokens,
  SetAsDefaultTeam,
  TeamGames,
  TeamMembers,
  TeamTransactions,
  ViewTeams,
} from "./OptionPopups";

const userListOptions = (role) => {
  const allPermissions = [
    {
      icon: "fa-edit",
      text: "Edit User",
      id: "edit",
      popup: () => <RedirectTo to="/member/general" from={"/cms"} />,
    },
    {
      icon: "fa-ban",
      text: "Ban User",
      id: "ban",
      popup: Ban,
    },
    {
      icon: "fa-gift",
      text: "Send Tokens",
      id: "send_tokens",
      popup: SendTokens,
    },
    {
      icon: "fa-gift",
      text: "Reward Game",
      id: "reward_game",
      popup: RewardGame,
    },
  ];

  return roleFilter(role, allPermissions, {
    [Roles.ADMIN]: ["ban", "change-role", "send_tokens", "reward_game"],
    [Roles.STAFF]: ["ban", "change-role"],
    [Roles.CONTENT_CREATOR]: ["edit"],
    [Roles.TRUSTED_CONTENT_CREATOR]: ["edit"],
  });
};

const developeTeamsOptions = (role) => {
  const allPermissions = [
    {
      icon: "fa-eye",
      text: "See Team",
      id: "see_team",
      popup: ({ username }) => <RedirectTo to={`/team/@${username}`} />,
    },
    {
      icon: "fa-users",
      text: "Set as Default Team",
      id: "default_team",
      popup: SetAsDefaultTeam,
    },
    {
      icon: "fa-user",
      text: "Members",
      id: "members",
      popup: TeamMembers,
      popupProps: {
        maxWidth: 800,
      },
    },
    {
      icon: "fa-gamepad",
      text: "Games",
      id: "games",
      popup: TeamGames,
      popupProps: {
        maxWidth: 800,
      },
    },
    {
      icon: "fa-edit",
      text: "Edit Team",
      id: "edit",
      popup: ({ id }) => (
        <RedirectTo to={`${window.location.pathname}/edit/${id}`} />
      ),
    },

    {
      icon: "fa-user-plus",
      text: "Invite Creator",
      id: "invite_creator",
      popup: InviteCreator,
    },
    {
      icon: "fa-money",
      text: "Withdraw Tokens",
      id: "withdraw",
      popup: ({ id }) => {
        const path = generatePath("/cms/financial/withdraw/:id?", { id });
        return <RedirectTo to={path} />;
      },
    },
    {
      icon: "fa-users",
      text: "All Invitations",
      id: "all_invites",
      popup: AllInvites,
    },
    {
      icon: "fa-sign-out",
      text: "Leave Team",
      id: "leave_team",
      popup: LeaveTeam,
    },
    {
      icon: "fa-check",
      text: "Approve Team",
      id: "approve",
      popup: ApproveTeam,
    },
    {
      icon: "fa-money",
      text: "Team Transactions",
      id: "transactions",
      popup: TeamTransactions,
      popupProps: {
        maxWidth: 840,
      },
    },
    {
      icon: "fa-handshake-o",
      text: "Show Affiliate Links",
      id: "affiliate_links",
      popup: ({ id }) => {
        const path = generatePath("/cms/content/affiliate-links/:id?", { id });
        return <RedirectTo to={path} />;
      },
    },
    {
      icon: "fa-money",
      text: "Edit Ad Zone ID",
      id: "ad_zone",
      popup: EditAdZone,
      popupProps: {
        maxWidth: 840,
      },
    },
    {
      icon: "fa-trash-o",
      text: "Delete Team",
      id: "delete",
      popup: DeleteTeam,
      danger: true,
    },
  ];

  return roleFilter(role, allPermissions, {
    [Roles.ADMIN]: [
      "see_team",
      "members",
      "games",
      "edit",
      "delete",
      "withdraw",
      "transactions",
      "approve",
      "affiliate_links",
      "ad_zone",
    ],
    [Roles.STAFF]: ["see_team", "members", "games"],
    [Roles.CONTENT_CREATOR]: [
      "see_team",
      "default_team",
      "members",
      "games",
      "leave_team",
    ],
    [Roles.TRUSTED_CONTENT_CREATOR]: [
      "see_team",
      "default_team",
      "members",
      "games",
      "leave_team",
    ],
    leader: [
      "see_team",
      "default_team",
      "edit",
      "members",
      "games",
      "invite_creator",
      "all_invites",
      "affiliate",
      "withdraw",
      "transactions",
    ],
    "co-leader": [
      "see_team",
      "default_team",
      "edit",
      "members",
      "games",
      "leave_team",
      "invite_creator",
      "all_invites",
      "transactions",
    ],
  });
};

const contentCretorOptions = (role, details) => {
  const allPermissions = [
    {
      icon: "fa-group",
      text: "View Teams",
      id: "view-team",
      popup: ViewTeams,
      popupProps: {
        maxWidth: 800,
      },
    },
    {
      icon: "fa-ban",
      text: "Ban User",
      id: "ban",
      popup: Ban,
    },
    {
      icon: "fa-user-circle",
      text: "Change Sub Role",
      id: "change-sub-role",
      popup: ChangeRole,
    },
    {
      icon: details.approved ? "fa-ban" : "fa-check-circle",
      text: details.approved
        ? "Demote to Creator"
        : "Promote as Trusted Creator",
      id: "make_trusted",
      popup: MakeAsTrustedCreator,
    },
  ];
  return roleFilter(role, allPermissions, {
    [Roles.ADMIN]: [
      "view-team",
      "ban",
      "change-role",
      "change-sub-role",
      "make_trusted",
    ],
    [Roles.STAFF]: ["view-team", "ban", "change-role", "change-sub-role"],
    [Roles.CONTENT_CREATOR]: ["view-team", "change-sub-role"],
    [Roles.TRUSTED_CONTENT_CREATOR]: ["view-team", "change-sub-role"],
  });
};

const invitationsOptions = (role) => {
  const allPermissions = [
    {
      icon: "fa-eye",
      text: "View Team",
      id: "view_team",
      popup: ({ team }) => <RedirectTo to={`/team/@${team}`} />,
    },
    {
      icon: "fa-check",
      text: "Accept",
      id: "accept",
      popup: AcceptInvite,
    },
    {
      icon: "fa-ban",
      text: "Deny",
      id: "deny",
      popup: AcceptInvite,
    },
  ];

  return allPermissions;
};

const teamGamesOptions = (team_role, user_role, game) => {
  const allUserOptions = [
    {
      icon: "fa-gamepad",
      text: "See Game",
      id: "see",
      popup: () => <Redirect to={`/game/${game.id}`} />,
    },
    {
      icon: "fa-edit",
      text: "Edit Game",
      id: "edit",
      popup: ({ id }) => {
        const path = generatePath("/cms/content/games/edit/:id/:uuid?", { id });
        return <Redirect to={path} />;
      },
    },
    {
      icon: game.published ? "fa-ban" : "fa-check",
      text: game.published ? "Unpublish Game" : "Publish Game",
      id: "publish",
      popup: PublishGame,
    },
    {
      icon: "fa-trash",
      text: "Delete Game",
      id: "delete",
      popup: Delete,
      danger: true,
    },
  ];

  return roleFilter(team_role ?? user_role, allUserOptions, {
    [Roles.ADMIN]: ["delete", "publish", "edit", "see"],
    [Roles.LEADER]: ["delete", "publish", "edit", "see"],
    [Roles.CO_LEADER]: ["edit", "see"],
    [Roles.STAFF]: ["see"],
    [Roles.CONTENT_CREATOR]: ["see"],
    [Roles.TRUSTED_CONTENT_CREATOR]: ["see"],
  });
};

export { teamGamesOptions };
export { invitationsOptions };
export { contentCretorOptions };
export { developeTeamsOptions };
export { userListOptions };
