import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { getClass } from "src/utils/getClass";
import Input from "./Input";
import { Checkbox } from "src/components/Register";
import useId from "src/hooks/useId";
import RadioBox from "./RadioBox";

const SearchSelectContext = createContext({});

const SearchSelect = ({
  value,
  className,
  disabled,
  onSearch,
  onChange,
  children,
  selectMultiple = false,
  defaultSelectedLabel,
  error: inputInvalidError,
}) => {
  const [showData, setShowData] = useState(false);
  const [error, setError] = useState("");
  const [query, setQuery] = useState("");

  const [selectedLabel, setSelectedLabel] = useState(
    defaultSelectedLabel ?? "- Select from below"
  );

  const searchButton = useRef(null);
  const toggleShow = () => setShowData((prev) => !prev);
  const updateQuery = (e) => setQuery(e.target.value);

  const onUpdate = async () => {
    try {
      setError(`Searching`);
      await onSearch?.(query);
      setError("");
    } catch (error) {
      setError(error.message);
    }
  };

  const onInputChange = (checkedValue, label, checked) => {
    if (selectMultiple) {
      if (checked) {
        onChange?.([...value, checkedValue]);
        setSelectedLabel(`${value.length + 1} items Selected`);
      } else {
        onChange?.(
          value.filter((v) => {
            const stringV = JSON.stringify(v);
            const checkedStringV = JSON.stringify(checkedValue);
            return stringV !== checkedStringV;
          })
        );
        setSelectedLabel(`${value.length - 1} items Selected`);
      }
    } else {
      onChange?.(checkedValue);
      setSelectedLabel(label);
      // automatically close the dropdown when selected
      setShowData(false);
    }
  };

  return (
    <div
      className={getClass(
        "bg-neutral-800 max-h-16 relative",
        showData && "z-[999]",
        className
      )}
      data-disable={disabled}
    >
      <div
        className={getClass(
          "bg-neutral-800 px-6 border border-neutral-400 rounded w-full focus:ring outline-none focus:ring-neutral-600 text-white",
          showData && "shadow-xl shadow-black",
          inputInvalidError && "border-red-600 focus:ring-red-600/60"
        )}
      >
        <div
          onClick={toggleShow}
          className="py-4 flex justify-between cursor-pointer select-none"
        >
          <div className="line-clamp-1">{selectedLabel}</div>
          <div
            className={getClass(
              "fa fa-chevron-up transition text-red-600 text-lg",
              showData && "rotate-180"
            )}
          ></div>
        </div>

        {showData && (
          <>
            <div className="relative mb-3">
              <Input
                className="pr-16"
                type="search"
                onChange={updateQuery}
                placeholder="Search..."
                value={query}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    searchButton.current?.click();
                  }
                }}
              />
              <button
                className="absolute left-auto px-5 right-0 my-auto inset-0"
                ref={searchButton}
                onClick={onUpdate}
              >
                <span className="fa fa-search"></span>
              </button>
            </div>

            <SearchSelectContext.Provider
              value={{
                value,
                selectMultiple,
                searchQuery: query,
                onChange: onInputChange,
              }}
            >
              <div className="max-h-64 overflow-y-auto mt-4 grid grid-cols-2 gap-y-2 gap-x-1">
                {children}
              </div>
            </SearchSelectContext.Provider>
            <span className="py-3 px-4 block">{error}</span>
          </>
        )}
      </div>
    </div>
  );
};

const SearchSelectOption = ({ label, value: optionValue }) => {
  const id = useId();
  const {
    onChange,
    value: inputValue,
    selectMultiple,
    searchQuery,
  } = useContext(SearchSelectContext);

  const isMatch = useMemo(() => {
    const inputValueString = JSON.stringify(inputValue);
    const optionValueString = JSON.stringify(optionValue);

    if (selectMultiple) {
      // inputValue is []
      return !!inputValue?.find((v) => {
        return JSON.stringify(v) === optionValueString;
      });
    }

    // inputValue is primitive
    return inputValueString === optionValueString;
  }, [inputValue]);

  if (
    !String(label).toLowerCase().includes(searchQuery?.toLowerCase()) &&
    !String(optionValue).toLowerCase().includes(searchQuery?.toLowerCase())
  )
    return null;

  return (
    <div className="mb-2">
      {selectMultiple ? (
        <div className={getClass("w-full flex")}>
          <Checkbox
            id={id}
            value={isMatch}
            callback={(_, checked) => {
              onChange?.(optionValue, label, checked);
            }}
          />
          <label className="pl-2" htmlFor={id}>
            {label}
          </label>
        </div>
      ) : (
        <RadioBox
          onChange={() => {
            onChange?.(optionValue, label, !isMatch);
          }}
          id={id}
          checked={isMatch}
          label={label}
        />
      )}
    </div>
  );
};

SearchSelect.Option = SearchSelectOption;

export default SearchSelect;
