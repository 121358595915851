export const osIconFinder = (os) => {
  let icon;
  let color;
  switch (os?.toLowerCase()) {
    case "windows":
      icon = "fa-windows";
      color = "other";
      break;

    case "macos":
      icon = "fa-apple";
      color = "grey";
      break;

    case "ios":
      icon = "fa-apple";
      color = "grey";
      break;

    case "android":
      icon = "fa-android";
      color = "green";
      break;

    case "linux":
      icon = "fa-linux";
      color = "blue";
      break;
    default:
      color = "grey";
      icon = "";
      break;
  }
  return [icon, color];
};

export const findAllOSFromResources = (resources = []) => {
  const allOs = [];

  for (let i = 0; i < resources.length; i++) {
    const resourceOS = resources[i]?.operating_system;
    allOs.push(resourceOS);
  }

  const unique = new Set(allOs.flat());

  return [...unique];
};
