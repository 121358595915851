import { useEffect, useState } from "react";
import { LoaderInside } from "src/components/Common";
import { MiniPlaylist } from "src/components/Playlists/MiniPlaylist";
import { Player } from "src/components/Video/Player";
import { BASE_CDN_URL, request } from "src/utils/Axios";

const PlaylistsSection = ({ teamId, teamName }) => {
  const [playlists, setPlaylists] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (teamId) getPlaylists();
  }, [teamId]);

  const getPlaylists = async () => {
    try {
      setLoading(true);
      const res = await request({
        url: "/community/team/playlists",
        baseURL: BASE_CDN_URL,
        withCredentials: true,
        params: {
          team_id: teamId,
        },
      });
      setPlaylists(res);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <LoaderInside />;
  return (
    <section className="SearchPage">
      <h2 className="header">Playlists From {teamName}</h2>
      <div className="search_results_content">
        {playlists.map((playlist) => (
          <MiniPlaylist key={playlist.id} playlist={playlist} />
        ))}
      </div>

      {playlists.length === 0 && (
        <div className="nothing">
          <p>No Playlists were Created by {teamName} yet</p>
        </div>
      )}
    </section>
  );
};

export default PlaylistsSection;
