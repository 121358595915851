import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Divider } from "../Divider";
import { faqData, faqDataCreators } from "./faqData";
import "./style.css";

const FAQ = () => {
  const location = useLocation();

  useEffect(() => {
    if (!location.hash) return;
    const section = document.querySelector(location.hash);

    if (section) {
      section.scrollIntoView();
    }
  }, []);

  return (
    <main className="FAQ" data-testid="FAQ">
      <Helmet>
        <title>Frequently Asked Questions - Spicygaming</title>
        <base href="/" target="_blank" />
      </Helmet>
      <div className="inner">
        <h1>Frequently Asked Questions (FAQ)</h1>

        <FAQContent data={faqData} />
        <Divider>Only For Content Creators</Divider>
        <FAQContent data={faqDataCreators} />

        <p>
          <br />
          <br />
          This FAQ aims to provide a comprehensive guide to help you navigate
          the process of becoming a content creator on Spicygaming. If you have
          further questions, feel free to reach out to our support team.
        </p>
      </div>
    </main>
  );
};

const FAQContent = ({ data }) => (
  <div className="content">
    {data.map((section, i) => {
      const id = section.title.toLowerCase().replace(/ /g, "-");

      return (
        <div className="section" key={i} id={id}>
          <h2>
            <a className="RefLink" href={`/frequently-asked-questions#${id}`}>
              #
            </a>
            {section.title}
          </h2>
          <div className="questions">
            {section.questions.map((question, j) => (
              <details key={j}>
                <summary>{question.title}</summary>
                <div>{question.answer}</div>
              </details>
            ))}
          </div>
        </div>
      );
    })}
  </div>
);

export default FAQ;
