import React from "react";
import { getClass } from "../../utils/getClass";
import "./style.css";

const colors = {
  blue: "#4169E1",
  orange: "#FF4500",
  red: "#cc0300",
};
const Chip = ({ children, className, selected, color }) => {
  return (
    <span
      className={getClass("Chip", className)}
      data-selected={selected}
      data-testid="Chip"
      style={{ backgroundColor: colors[color] }}
    >
      {children}
    </span>
  );
};

export default Chip;
