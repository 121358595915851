import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Finder from "src/components/Finder";
import { Input } from "src/components/Member/General";
import { authRequest } from "src/utils/Axios";
import { SubmitButton } from "../Common";
import { InnerHeader } from "../Headers";
import VideoSelect from "../Videos/VideoSelect";

const CreatePlaylist = (props) => {
  const { editMode, details = {} } = props;
  const history = useHistory();
  const [selectedVideos, setSelectedVideos] = useState(details.videos ?? []);
  const [name, setName] = useState(details.name ?? "");
  const [team, setTeam] = useState(details.team ?? null);

  const getVideoID = (id) => {
    setSelectedVideos((prev) => [...prev, id]);
  };

  const getTeams = async (query = "") => {
    try {
      const teams = await authRequest({
        url: `/api/content-creator-teams/search`,
        params: {
          name: query,
        },
      });
      return teams;
    } catch (error) {
      return [];
    }
  };

  const createPlaylist = async () => {
    try {
      if (selectedVideos.length < 3) throw new Error("Add at least 3 videos");
      if (name.length <= 0) throw new Error("Add a name");
      if (team === null) throw new Error("Select a Team");
      const res = await authRequest({
        url: "/community/playlists",
        method: editMode ? "PUT" : "POST",
        data: {
          name,
          playlist_id: details.id,
          team_id: team.id,
          videos: selectedVideos,
        },
      });
      if (res.success)
        toast.success(
          `Successfully ${editMode ? "edited" : "created"} playlist ${name}`
        );
      history.push("/cms/content/playlists");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div id="Create_Playlist" className="body">
      <InnerHeader {...props} element="back-button" />
      <div className="content object-selector">
        <section className="basic">
          <legend>
            {editMode
              ? `Edit ${details.name} Playlist`
              : "Create a new playlist"}
          </legend>
          <div className="info">
            <Input
              label="Playlist Name"
              value={name}
              callback={(text) => setName(text)}
              required
            />

            <Finder
              required
              value={team}
              label="Team"
              data={[]}
              onChange={(id) => setTeam(id)}
              update={getTeams}
              idExtractor={(team) => team.id}
              id="team"
            />
          </div>
        </section>

        <VideoSelect getVideoID={getVideoID} alreadyAdded={selectedVideos} />
        <div className="create_button">
          <SubmitButton
            label={editMode ? "Save Playlist" : "Create Playlist"}
            icon="fa-list"
            request={createPlaylist}
          />
        </div>
      </div>
    </div>
  );
};

export default CreatePlaylist;
