import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { debounceAsync } from "src/utils/throttle";
import { useToggle } from "../../hooks/useToggle";
import { getClass } from "../../utils/getClass";
import { FileNotFoundError, TinyButtonLoader } from "../Common";
import { URL_REGEX } from "../Member/General";
import { authorizedRequest } from "../Validation";
import Popup from "../common/Popup";
import checkAspectRatio, {
  getGreatestCommonDivider,
} from "../common/validateImage";
import { useUUID } from "./Games/CreateGameV2/EditGame";
import "./css/MediaUpload.css";
import { NOT_VALID_BORDER_STYLE, VALID_BORDER_STYLE } from "./validation";
import { Checkbox } from "../Register";

export const _URL = window.URL || window.webkitURL;

export const getPreviewImage = (file) => {
  if (file instanceof FileNotFoundError) return "/images/no-file.png";

  return _URL.createObjectURL(file);
};

/**
 * @param {Object} props
 * @param {String} props.text - label for the item
 * @param {String} props.size - Recommended Image Resolution
 * @param {String} props.id - valueSetter ID
 * @param {Function} props.onClick - Images Setter
 * @param {Number} props.imgCount - maximum number of images can be uploaded
 * @param {Object} props.defaults
 * @param {Object} props.aspect - aspect ratio of images
 * @param {Number} props.aspect.width
 * @param {Number} props.aspect.height
 * @param {any[]} props.defaults.imagefiles  - default images Files [ uses in edit mode ]
 * @returns {JSX.Element}
 */
const MediaUpload = (props) => {
  const { text, size, id, onClick, imgCount = 1, defaults, aspect } = props;

  const [previewImages, setPreviewImages] = useState(
    defaults?.imagefiles?.map(getPreviewImage) || []
  );
  const [images, setImages] = useState(defaults?.imagefiles || []);

  const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/gif", "image/webp"];
  const MAX_SIZE = 1024 * 1024 * 5;

  const [error, setError] = useState(null);

  useEffect(() => {
    onClick((prev) => ({ ...prev, [id.toLowerCase()]: images }));
  }, [images]);

  const fileUploadHandle = (e) => {
    const files = e.target.files;

    if (images.length + files.length <= imgCount) {
      [...files].forEach((file) => {
        setError(null);
        if (file.size > MAX_SIZE) return setError("Maximum Image size is 5MB");
        if (file.type === "image/gif")
          return setError(
            <span style={{ textTransform: "initial" }}>
              Only .webp animated pictures are supported, please convert your
              gif files to Webp before uploading. <br /> You can do that &nbsp;
              <a
                href="https://ezgif.com/gif-to-webp"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
            </span>
          );

        if (ALLOWED_TYPES.includes(file.type)) {
          const preview = getPreviewImage(file);

          const addImage = ({ width }) => {
            if (width) {
              if (width <= aspect.width) {
                setImages((prev) => [...prev, file]);
                setPreviewImages((prev) => [...prev, preview]);
              } else {
                return setError(`Maximum Resolution is ${size}`);
              }
            } else {
              setImages((prev) => [...prev, file]);
              setPreviewImages((prev) => [...prev, preview]);
            }
          };
          if (aspect) {
            const aspectRatio = aspect.width / aspect.height;
            const commonDivider = getGreatestCommonDivider(
              aspect.width,
              aspect.height
            );

            checkAspectRatio(preview, aspectRatio)
              .then(addImage)
              .catch(() =>
                setError(
                  `Supported aspect ratio is ${aspect.width / commonDivider}:${
                    aspect.height / commonDivider
                  }`
                )
              );
          } else addImage({});
        } else {
          setError(() => (
            <>
              unsupported file type ! <br />
              <small>(.png .jpeg .jpg .webp allowed)</small>
            </>
          ));
        }
      });
    } else {
      setError(`You can only upload ${imgCount - images.length} images more.`);
    }
  };

  return (
    <div id="media-upload">
      <span className="size">Preferable resolution for images is {size}</span>
      <input
        type="file"
        id={id}
        accept="image/*"
        hidden
        multiple={true}
        onChange={fileUploadHandle}
        disabled={previewImages.length >= imgCount ? true : false}
      />
      <label htmlFor={id}>
        <span className="fa fa-photo"></span>
        {text}
      </label>
      <div className="images">
        <TransitionGroup component="div" className="images">
          {previewImages.map((img, i) => (
            <CSSTransition key={i} timeout={300} classNames="popup">
              <div className="image">
                <img loading="lazy" src={img} alt="" />
                <span
                  className="fa fa-close"
                  onClick={() => {
                    setPreviewImages((prev) => {
                      _URL.revokeObjectURL(img);
                      return prev.filter((item) => item !== img);
                    });
                    setImages((prev) =>
                      prev.filter((item) => item !== prev[i])
                    );
                  }}
                ></span>
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
      {error !== null && error !== false && (
        <div className="error img">{error}</div>
      )}
    </div>
  );
};

/**
 * @param {Object} props
 * @param {String} props.title - label for the item
 * @param {String} props.buttonText - Button text
 * @param {String} props.list - Added items list
 * @param {String} props.instruction - instructions for the user how to upload them
 * @param {Boolean} props.icon - wheather it has icon or not in the list items
 * @param {Boolean} props.customField - wheather user can upload  custom type for category
 * @param {Array} props.defaults - Default Items (used in editor mode)
 */
const GameUpload = (props) => {
  const {
    title,
    buttonText,
    list,
    onClick = () => null,
    icon = true,
    instruction = "",
    customField = false,
    defaults = [],
  } = props;
  const uuid = useUUID();

  const [popup, togglePopup] = useToggle();
  const [links, setLinks] = useState(defaults);

  useEffect(() => {
    onClick((prev) => ({
      ...prev,
      downloads: {
        ...prev.downloads,
        [title?.toLowerCase()?.replace(/ /g, "_")]: links,
      },
    }));
  }, [links]);
  return (
    <div id="game-upload">
      <div className="title">
        <span>{title}</span>
        <div className="button" onClick={togglePopup}>
          {buttonText}
        </div>
      </div>
      <div className="instruction">{instruction}</div>
      {links.length > 0
        ? links.map((link, i) => (
            <div
              className="row"
              key={i}
              data-reported={
                link.link_unique_id === uuid && !!link.link_unique_id && !!uuid
              }
            >
              <div className="tag">
                {icon && <div className={`fa ${link.icon}`}></div>}
                <span>
                  {icon && "For"} {link.custom_type ?? link.type}
                </span>
              </div>
              <span className="url" title={link.link}>
                {link.link}
              </span>
              <div data-tooltip="Remove Link">
                <div
                  className="fa fa-close"
                  onClick={() =>
                    setLinks((prev) => {
                      return prev.filter((item) => item.type !== link.type);
                    })
                  }
                ></div>
              </div>
            </div>
          ))
        : `Click upload button to upload ${title.toLowerCase()}`}

      <Popup open={popup} onClose={togglePopup} id="game-upload-link">
        <GameUploadLink
          id={title}
          onClick={setLinks}
          list={list}
          customField={customField}
          onClose={togglePopup}
        />
      </Popup>
    </div>
  );
};

const GameUploadLink = ({ id, onClose, list = [], onClick, customField }) => {
  const [formData, setFormData] = useState({
    type: "",
    link: "",
  });
  const [error, setError] = useState(null);

  const inputGetter = (e, key, regex = /.*/) => {
    let value = e.target.value.trim();
    if (value.match(regex) !== null && value.match(regex)[0] === value) {
      e.target.style.border = VALID_BORDER_STYLE;
      setFormData((prev) => ({ ...prev, [key]: e.target.value }));
    } else {
      setFormData((prev) => ({ ...prev, [key]: "" }));

      e.target.style.border = NOT_VALID_BORDER_STYLE;
    }
  };

  const submit = () => {
    try {
      if (
        formData.type === "" ||
        formData.custom_type === "" ||
        formData.link === ""
      ) {
        throw new Error("fill all fields");
      }

      onClick((prev) => [...prev, formData]);
      onClose();
    } catch (error) {
      setError(error.message);
    }
  };
  return (
    <>
      <div className="title">UPLOAD GAME LINK</div>
      {error !== null && error !== false && (
        <div className="error">{error}</div>
      )}
      <div className="inputs">
        <div className="input">
          <div className="name">Select {id}</div>
          <select
            id="request-role"
            onChange={(e) => {
              let value = e.target[e.target.selectedIndex].dataset.icon;
              if (value) {
                setFormData((prev) => ({ ...prev, icon: value.trim() }));
              }
              inputGetter(e, "type");
            }}
          >
            <option>- Select from below</option>

            {list.map((item, i) => (
              <option key={i} value={item.param} data-icon={item.icon}>
                {item.name}
              </option>
            ))}
          </select>

          {customField && (
            <>
              <br />
              <div
                className="name"
                onClick={() =>
                  document
                    .querySelector("#custom-input")
                    .removeAttribute("disabled")
                }
              >
                add custom {id} [click here]
              </div>
              <input
                id="custom-input"
                type="text"
                disabled={true}
                onChange={(e) => inputGetter(e, "custom_type")}
                placeholder={"add your own " + id + " name"}
              />
            </>
          )}
        </div>
        <div className="input">
          <div className="name">Game URL</div>
          <input
            type="url"
            placeholder="http://example.com"
            onChange={(e) => inputGetter(e, "link", URL_REGEX)}
          />
        </div>
      </div>
      <SubmitButton label="Save Link" request={submit} />
    </>
  );
};

const AddTags = ({
  title,
  option,
  required,
  label,
  instruction,
  onChange,
  dataKey,
  max = 20,
  defaults,
}) => {
  const [expand, toggleExpand] = useState(false);
  const [selectedTags, setSelectedTags] = useState(new Set(defaults || []));
  const [searchTags, setSearchTags] = useState([]);
  const [search, setSearch] = useState("");
  const searchBox = useRef(null);

  useEffect(() => {
    if (option !== undefined) setSearchTags(option);
  }, [option]);

  useEffect(() => {
    if (expand) searchBox.current?.focus();
  }, [expand]);

  useEffect(() => {
    onChange((prev) => {
      return { ...prev, [dataKey]: [...selectedTags] };
    });
  }, [selectedTags]);

  const searchSetter = (e) => {
    setSearch(e.target.value);
    setTimeout(() => {
      if (option !== undefined) {
        let filteredOptions = option.filter((option) =>
          option.name.toLowerCase().includes(e.target.value) ? option : null
        );
        e.target.value.length !== 0
          ? setSearchTags(filteredOptions)
          : setSearchTags(option);
      }
    }, 100);
  };
  const addTags = (param) => (_, checked) => {
    setSelectedTags((prev) => {
      const copy = new Set([...prev]);

      if (copy.size >= max) {
        document.querySelector("#add-tags .container").style.border =
          NOT_VALID_BORDER_STYLE;
      }
      if (checked) {
        if (!(copy.size >= max)) {
          copy.add(param);
        }
      } else {
        copy.delete(param);
      }
      return copy;
    });
  };
  return (
    <div id="add-tags">
      <div className="name">
        {label}
        {required && <Required />}
      </div>

      <div className={`container ${expand ? "show" : ""}`}>
        <div className="tags" onClick={() => toggleExpand((prev) => !prev)}>
          {selectedTags.size > 0 ? (
            <>
              {selectedTags.size >= 1 && (
                <div className="tag">{[...selectedTags][0]} </div>
              )}
              {selectedTags.size >= 2 && (
                <div className="tag">{[...selectedTags][1]} </div>
              )}
              {selectedTags.size >= 3 && (
                <div className="tag last">{[...selectedTags][2]} </div>
              )}
              {selectedTags.size >= 4 && (
                <>
                  <div className="dot">...</div>
                  <div className="tag tag-counter">{selectedTags.size - 3}</div>
                </>
              )}
            </>
          ) : (
            <div className="no-tags">Select from Below</div>
          )}
        </div>
        <div className="search">
          <input
            ref={searchBox}
            type="search"
            value={search}
            onChange={searchSetter}
            id="search-input"
            placeholder="Search..."
          />
          <span className="fa fa-search"></span>
        </div>
        <div className="checkboxs">
          {searchTags.length > 0
            ? searchTags.map((tag) => {
                const id = `${title}-${tag.param}`?.replace(/( |\/|&)/g, "_");

                return (
                  <Checkbox
                    label={tag.name}
                    id={id}
                    callback={addTags(tag.param)}
                    key={tag.param}
                    value={selectedTags.has(tag.param)}
                  />
                );
              })
            : "No Tags"}
        </div>
      </div>
      <div className="instruction">{instruction}</div>
    </div>
  );
};

/**
 * `items`, `selectedItems` should follow this layout. `realtimeFetch` should return this type
 * ```js
 *    [{name: "Display Name", param: "identifier"}]
 * ```
 *
 * @param {*} param0
 * @returns
 */
export const AddTagsV2 = ({
  items,
  label,
  instruction,
  onChange,
  max = 20,
  realtimeFetch,
  selectedItems = [],
}) => {
  const [expand, toggleExpand] = useState(false);
  const [filteredItems, setFilteredItems] = useState(items ?? []);
  const [loading, toggleLoading] = useToggle();
  const searchBox = useRef(null);
  const container = useRef(null);
  const [selectedTags, addSelectedTags] = useState(
    new Set(selectedItems.map((tag) => tag.param) || [])
  );
  const allCache = useRef(items ?? []);

  useEffect(() => {
    if (expand) searchBox.current?.focus();
  }, [expand]);

  useEffect(() => {
    onChange?.(
      [...selectedTags.values()].map((tag) =>
        allCache.current.find((item) => item.param === tag)
      )
    );
  }, [selectedTags]);

  const debouncedFetch = useCallback(debounceAsync(realtimeFetch, 500), []);

  const getFiltered = async (e) => {
    const search = e.target.value;
    if (realtimeFetch) {
      if (search.length <= 0) return;

      toggleLoading(true);

      const res = await debouncedFetch(search);
      setFilteredItems(res);
      allCache.current = allCache.current.concat(res);
      toggleLoading(false);
      return;
    }
    setFilteredItems(
      items.filter(
        (item) => !!item.name.toLowerCase().includes(search?.toLowerCase())
      )
    );
  };

  const addTags = (item) => (_, checked) => {
    addSelectedTags((prev) => {
      if (checked) {
        if (selectedTags.size < max) {
          prev.add(item.param);
          container.current.style.border = VALID_BORDER_STYLE;
        } else {
          container.current.style.border = NOT_VALID_BORDER_STYLE;
        }
      } else {
        prev.delete(item.param);
      }

      return new Set(prev.values());
    });
  };

  const getName = (item) => {
    const foundItem = allCache.current?.find(
      (current) => String(current.param) === item
    );
    return foundItem?.name;
  };
  return (
    <div id="add-tags">
      <div className="name">{label}</div>

      <div ref={container} className={getClass("container", expand && "show")}>
        <div className="tags" onClick={() => toggleExpand((prev) => !prev)}>
          {selectedTags.size > 0 ? (
            <>
              {selectedTags.size >= 1 && (
                <div className="tag">{getName([...selectedTags][0])} </div>
              )}
              {selectedTags.size >= 2 && (
                <div className="tag">{getName([...selectedTags][1])} </div>
              )}
              {selectedTags.size >= 3 && (
                <div className="tag last">{getName([...selectedTags][2])} </div>
              )}
              {selectedTags.size >= 4 && (
                <>
                  <div className="dot">...</div>
                  <div className="tag tag-counter">{selectedTags.size - 3}</div>
                </>
              )}
            </>
          ) : (
            <div className="no-tags">Select from Below</div>
          )}
        </div>
        <div className="search">
          <input
            ref={searchBox}
            type="search"
            onChange={getFiltered}
            id="search-input"
            placeholder="Search..."
          />
          <span className="fa fa-search"></span>
        </div>
        <div className="checkboxs">
          {loading
            ? "Loading..."
            : filteredItems.length > 0
            ? filteredItems.map((item) => {
                const id = `${label}-${item.param}`?.replace(/( |\/|&)/g, "_");

                return (
                  <Checkbox
                    id={id}
                    label={item.name}
                    callback={addTags(item)}
                    value={selectedTags.has(item.param)}
                  />
                );
              })
            : "Nothing found. Search something different"}
        </div>
      </div>
      <div className="instruction">{instruction}</div>
    </div>
  );
};

/**
 * @deprecated
 */
const DropdownFilter = ({
  label,
  filters = [],
  title,
  id,
  onChange,
  defaultValue,
  required = false,
}) => {
  return (
    <div className="input">
      <label className="name">
        {label}
        {required && <span className="required"></span>}
      </label>
      <select onChange={(e) => onChange(e, id, /.*/)} value={defaultValue}>
        <option value={null} onClick={() => null}>
          - Select from below
        </option>
        {filters.length > 0
          ? filters
              .find((filter) => filter.title.toLowerCase() === title)
              .option.map((option, i) => (
                <option key={i} value={option.param}>
                  - {option.name}
                </option>
              ))
          : ""}
      </select>
    </div>
  );
};

/** This is a finder. Includes a search bar
 * @param {Object} props
 * @param {String} props.label - the top label (identifier)
 * @param {String} props.requestLink - provide full URL with search query placeholder `<search>`
 * @param {String} props.id - the id of value setter
 * @param {String} props.fieldNameWantInRequest - field name in the request
 * @param {String} props.fieldName - key of object where data stored
 * @param {Boolean} props.required - wheather requeired or not
 * @param {Function} props.onChange - value setter for the active item
 * @param {Boolean} props.editMode - enable editmode features
 * @param {Object} props.defaults - defaults in edit mode
 * @param {String} props.defaults.indicator - defaults name
 * @param {Number} props.defaults.value - defaults ID
 * @param {Boolean} props.json - if want data with json type
 *
 * @deprecated
 * see code for more params and details
 */
const Finder = (props) => {
  const {
    label,
    fieldName,
    onChange,
    id,
    fieldNameWantInRequest,
    editMode,
    requestLink = "",
    required = false,
    defaults,
    json = false,
  } = props;
  const [search, setSearch] = useState("");
  const [error, setError] = useState("Type the Name to Search");
  const [toggle, setToggle] = useState(false);
  const [searchTags, setSearchTags] = useState([]);
  const [selected, setSelected] = useState(defaults?.indicator);

  const getSearchQuery = (e) => {
    setSearch(e.target.value);
  };
  const setSelectedInput = (e) => {
    onChange?.(e, id, /.*/);
    setSelected(e.target.dataset.name);
  };
  const request = async () => {
    if (!!search.length) {
      setError("Searching ...");
      setSearchTags([]);
      /** add <search> placeholder for request query in the @param`requestLink`
       *  https://api.spicygaming.net/products/api/games/search-games?title=<search>
       */
      try {
        const res = await authorizedRequest({
          url: requestLink.replace("<search>", search),
          method: "GET",
        });
        const data =
          res?.filter((obj) => !obj.hasOwnProperty("last_page")) || [];
        if (!!!data.length) {
          setError("Nothing found! Try another Name");
        } else {
          setError(data.length + " Results found");
          if (res.error) setError(res.error);
        }
        setSearchTags(data);
      } catch (error) {
        setError("Error occured");
      }
    } else {
      setSearchTags([]);
      setError("Type the Query");
    }
  };
  return (
    <div id="finder">
      <label className="name">
        {label}
        {required && <span className="required"></span>}
      </label>
      <div className={`container ${toggle && "show"}`}>
        <div className="label" onClick={() => setToggle((prev) => !prev)}>
          {selected ? selected : "- Select from below"}
        </div>

        {toggle && (
          <>
            <div className="search">
              <input
                type="search"
                value={search}
                onKeyPress={(e) =>
                  e.key === "Enter" && e.target.nextElementSibling.click()
                }
                onChange={getSearchQuery}
                id="search-input"
                placeholder="Search..."
              />
              <span className="fa fa-search" onClick={request}></span>
            </div>

            {/* to indecate the default value  */}

            {editMode && defaults && (
              <div className="radio">
                <input
                  type="radio"
                  name={`radio-${id}`}
                  defaultChecked={true}
                  onChange={(e) => onChange?.(e, id, /.*/)}
                  id={`radio-default-${id}`}
                  value={
                    json ? JSON.stringify(defaults?.value) : defaults?.value
                  }
                />
                <label htmlFor={`radio-default-${id}`}>
                  {defaults?.indicator}
                </label>
              </div>
            )}
            <TransitionGroup component="div" className="radios">
              {/* <div className="radios"> */}
              {searchTags.length > 0
                ? searchTags.map((elem, i) => {
                    const data = fieldNameWantInRequest
                      ? elem[fieldNameWantInRequest]
                      : elem[fieldName];

                    const radioID = `radio-${elem[fieldName]}-${id}`;

                    return (
                      <CSSTransition
                        classNames="fade"
                        timeout={300}
                        mountOnEnter
                        unmountOnExit
                        key={i}
                      >
                        <div className="radio">
                          <input
                            type="radio"
                            name={`radio-${id}`}
                            onChange={setSelectedInput}
                            id={radioID}
                            value={json ? JSON.stringify(data) : data}
                            data-name={elem[fieldName]}
                          />
                          <label htmlFor={radioID}>{elem[fieldName]}</label>
                        </div>
                      </CSSTransition>
                    );
                  })
                : error}
              {/* </div> */}
            </TransitionGroup>
          </>
        )}
      </div>
    </div>
  );
};

/**
 *
 * @param {Object} props
 * @param {String | JSX.Element} props.label - The label of the button. User will see this
 * @param {String} props.id - element ID can be used for styling purposes
 * @param {Function} props.request - button onClick function
 * @param {boolean} props.disabled - button disabled
 * @param {boolean} props.rounded
 * @param {String} props.className
 * @param {"primary"|"secondary"} props.type
 */
const SubmitButton = (props) => {
  const {
    rounded,
    label,
    request,
    id,
    className,
    icon,
    title,
    type = "primary",
    disabled = false,
  } = props;
  const [loading, setLoading] = useState(false);
  const onclick = async (e) => {
    if (!disabled && !loading) {
      setLoading(true);
      await request?.(e);
      setLoading(false);
    }
  };
  return (
    <button
      className={`submit loader ld-over-full-inverse ${className} submit-button ${
        disabled && "disabled"
      }`}
      id={id || "game-creation-submit"}
      onClick={onclick}
      data-disable={disabled}
      data-type={type}
      data-rounded={rounded}
      data-loading={loading}
      data-tooltip={title}
      aria-label={label}
    >
      {typeof icon === "string" ? (
        <span className={getClass("fa", "icon", icon)}></span>
      ) : (
        icon
      )}

      {loading && !rounded ? (
        <TinyButtonLoader backgroundColor="#fff" />
      ) : (
        label && <span className="label">{label}</span>
      )}
      <div className="ld">
        <div className="svg-loader">
          <svg className="svg-container" viewBox="0 0 100 100">
            <circle className="loader-svg bg" cx="50" cy="50" r="45"></circle>
            <circle
              className="loader-svg animate"
              cx="50"
              cy="50"
              r="45"
            ></circle>
          </svg>
        </div>
      </div>
    </button>
  );
};

const Required = () => {
  return (
    <div className="required" data-tooltip="This is Required Field">
      <span></span>
    </div>
  );
};

const idGen = (type) => {
  return type.toLowerCase().replace(/ /g, "_");
};

const RedirectTo = ({ to }) => {
  const history = useHistory();
  history.push(to);
  return null;
};

const REVALIDATE_ON_FOCUS = false;

export {
  AddTags,
  DropdownFilter,
  Finder,
  GameUpload,
  MediaUpload,
  REVALIDATE_ON_FOCUS,
  RedirectTo,
  Required,
  SubmitButton,
  idGen,
};
