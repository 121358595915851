import { toast } from "react-toastify";
import { CONVERSION_RATE } from "src/constants/store";
import { numberCompact } from "src/utils/number-format";
import SubmitButton from "src/v2/components/SubmitButton";
import {
  getUserAffiliateOverview,
  getUserAffiliateStats,
} from "src/v2/services/userService";
import useSWR from "swr";
import { LoaderInside } from "../Common";
import { LocalPrice } from "../LocalPrice";
import SpicyTokensPrice from "../LocalPrice/SpicyTokensPrice";

const EarnFreeTokens = () => {
  const { isValidating, data: overview } = useSWR("user-affiliate", () =>
    getUserAffiliateOverview()
  );

  const affiliateId = overview?.affiliate_id;

  const onAffiliateLinkCopy = async () => {
    await navigator.clipboard.writeText(
      `${window.location.origin}/?af=${affiliateId}`
    );
    toast.success("Link copied. Share with others to earn free tokens!");
  };

  if (isValidating) return <LoaderInside />;

  return (
    <section>
      <legend>Earn Free Tokens</legend>
      <div className="section-content grid gap-5">
        <AffiliateStats />
        <p>
          Earn free tokens by sharing your unique link! Simply copy the link
          below and share it across the web: on forums, social media or chats.
          For every purchase made through your link, you'll receive{" "}
          <SpicyTokensPrice tokens={50} /> Spicy Tokens as a reward.
        </p>
        <p>
          You can also add your {affiliateId} to the end of any url.
          <div>
            For example:{" "}
            <span className="border-b border-neutral-500 p-px">
              https://www.spicygaming.net/game/1493/?af={affiliateId}
            </span>
          </div>
        </p>
        <SubmitButton
          className="m-0"
          icon="fa-copy"
          onClick={onAffiliateLinkCopy}
        >
          Copy your Link
        </SubmitButton>
      </div>
    </section>
  );
};

const AffiliateStats = () => {
  const { data: stats, isValidating } = useSWR("user-affiliate-stats", () =>
    getUserAffiliateStats()
  );

  return (
    <div className="bg-neutral-200/5 py-3 rounded-lg flex flex-col md:flex-row justify-evenly divide-neutral-600 divide-y md:divide-x md:divide-y-0 min-h-32">
      {!isValidating && (
        <>
          <div className="md:px-5 py-3 md:py-0 w-full grid place-items-center">
            <div>Users Referred</div>
            <div className="text-white text-4xl">
              {numberCompact(stats?.users_referred)}
            </div>
          </div>
          <div className="md:px-5 py-3 md:py-0 w-full grid place-items-center">
            <div>Total Purchases</div>
            <div className="text-white text-4xl">
              {numberCompact(stats?.total_sales)}
            </div>
          </div>
          <div className="md:px-5 py-3 md:py-0 w-full grid place-items-center">
            <div>Earned Tokens</div>
            <div className="text-white text-4xl my-2">
              <SpicyTokensPrice tokens={stats?.profit} />
            </div>
            <div>
              <LocalPrice
                amountInEUR={stats?.profit / CONVERSION_RATE}
                hideDisclaimer
                showOnlyLocalPrice
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EarnFreeTokens;
