import { Link } from "react-router-dom";
import { MetaInfo } from "../AdultGames/Game";
import { Chip } from "../Chip";
import { LocalPrice } from "../LocalPrice";
import { BasicImage } from "../SpicyArt/Image";
import { ASPECT_RATIO } from "../Video/Player/Player";
import "./css/AdultGameWide.css";
import "./css/NewsMiniWide.css";

const AdultGame = ({
  id,
  days,
  short_description,
  landscape_banner,
  title,
  views,
  likes,
  creator_id,
  price,
  is_available_free,
  maxWidth = 400,
  discount = {},
}) => {
  return (
    <div role={"group"} aria-label={title} className="AdultGameWide">
      <Link to={"/game/" + id}>
        <div className="image">
          <BasicImage
            loading="lazy"
            width={maxWidth}
            height={maxWidth / ASPECT_RATIO}
            src={landscape_banner}
            alt={title}
          />
          {!!price && (
            <Chip className="badge badge-right badge-top">
              {!!discount.discounted_price ? (
                <div className="leading-none flex gap-1">
                  <LocalPrice
                    amountInEUR={discount.discounted_price}
                    hideDisclaimer
                    showOnlyLocalPrice
                  />

                  <strike className="opacity-80">
                    <LocalPrice
                      amountInEUR={price}
                      hideDisclaimer
                      showOnlyLocalPrice
                    />
                  </strike>
                </div>
              ) : (
                <LocalPrice
                  amountInEUR={price}
                  showOnlyLocalPrice
                  hideDisclaimer
                />
              )}
            </Chip>
          )}

          {is_available_free && (
            <Link to={"/game/" + id}>
              <Chip className="badge badge-bottom badge-right" color="orange">
                Try For Free
              </Chip>
            </Link>
          )}
        </div>
      </Link>

      <div className="text">
        <div className="info">
          <span aria-hidden className="fa fa-clock-o"></span>
          <span>{days}</span>
        </div>
        <h3 className="title">
          <Link to={"/game/" + id}>{title}</Link>
        </h3>
        <p className="description line-clamp-2">{short_description}</p>

        <MetaInfo
          views={views}
          likes={likes ?? 0}
          id={id}
          creator_id={creator_id}
        />
      </div>
    </div>
  );
};

export default AdultGame;
