import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Popup from "src/components/common/Popup";
import { logoutUser } from "src/components/Logout";
import RequestError from "src/errors/RequestError";
import { useToggle } from "src/hooks/useToggle";
import { sleep } from "src/utils/sleep";
import { deleteUser, deleteUserConfirm } from "src/v2/services/userService";
import { z } from "zod";
import Input from "../../form-inputs/Input";
import Select from "../../form-inputs/Select";
import Form from "../../form/Form";
import SubmitButton from "../../SubmitButton";

const deleteReasons = [
  "I no longer use the service",
  "I found a better alternative",
  "I had issues with the service",
  "Privacy concerns",
  "Customer support was unsatisfactory",
  "Other",
];

const DeleteAccount = () => {
  const [showConfirmPopup, toggleConfirmPopup] = useToggle();
  const [showDeletePopup, toggleDeletePopup] = useToggle();
  const [confirmError, setConfirmError] = useState(null);
  const [deleteError, setDeleteError] = useState(null);

  const form = useForm({
    resolver: zodResolver(
      z.object({
        code: z.string().length(6),
        reason: z.string(),
      })
    ),
  });

  const onClick = async () => {
    try {
      await deleteUser();
      toast.warn("Confirmation Email sent. Check inbox to continue");
      await sleep(2000);
      toggleConfirmPopup();
    } catch (error) {
      setDeleteError(error.message);
    }
  };

  const onDeleteConfirm = async () => {
    try {
      const isValid = await form.trigger();
      if (!isValid) return;

      const data = form.getValues();
      await deleteUserConfirm(data.code, data.reason);

      toggleConfirmPopup(false);
      await logoutUser("/");
    } catch (error) {
      setConfirmError(error.message);
    }
  };

  return (
    <section className="mt-16 text-red-500">
      <legend className="!border-b-red-500">DELETE ACCOUNT</legend>

      <SubmitButton onClick={toggleDeletePopup}>Delete Account</SubmitButton>
      <Popup showCloseButton open={showDeletePopup} onClose={toggleDeletePopup}>
        <div className="grid gap-5 text-red-500">
          <h3 className="text-xl">Warning: Account Deletion</h3>

          <RequestError error={deleteError} />
          <p>
            You are about to delete your account. This action is permanent and
            cannot be undone. By proceeding, you will lose access to all
            purchased content and any associated data.
          </p>

          <p>
            To confirm your account deletion, you will need to complete a
            two-factor authentication (2FA) process. Please check your email for
            the confirmation code.
          </p>

          <p>
            If you are sure you want to proceed, please confirm your decision
            below.
          </p>

          <SubmitButton onClick={onClick} type="secondary">
            Continue
          </SubmitButton>
        </div>
      </Popup>

      <Popup
        defaultClose={false}
        showCloseButton
        open={showConfirmPopup}
        onClose={toggleConfirmPopup}
      >
        <h3 className="text-xl mb-5">Confirm Account Deletion</h3>

        <RequestError error={confirmError} />
        <Form form={form}>
          <Form.Item label="Enter OTP Code" name="code">
            <Input placeholder="xxxxxx" />
          </Form.Item>
          <Form.Item label="Enter Reason" name="reason">
            <Select
              options={deleteReasons.map((r) => ({ value: r, label: r }))}
            />
          </Form.Item>

          <SubmitButton onClick={onDeleteConfirm}>Delete Account</SubmitButton>
        </Form>
      </Popup>
    </section>
  );
};

export default DeleteAccount;
