import { Input, URL_REGEX } from "../../../Member/General";
import {
  GameFileUpload,
  DeleteResource,
  EditGameFileUpload,
  EditLink,
  DeleteResourceHint,
} from "./Options";
import { AddSteamKeys, EditSteamKeys } from "./SteamKeys";

export const types = {
  LINK: "External Link",
  STEAM: "Steam Keys",
  FILE: "Hosted File",
};

export const resourceTypes = [
  {
    type: types.LINK,
    name: "External Link",
    label: "External Link",
    availableForFree: true,
    availableForPaid: false,
    addNewPopup({ onSubmit }) {
      const submit = (url) => {
        onSubmit({ external: url });
      };
      return (
        <Input
          callback={submit}
          label="URL"
          placeholder="Enter the external URL"
          required
          example="https://mega.nz/yourfile"
          // TODO: add validation
        />
      );
    },
    warning:
      "The content is hosted outside off SpicyGaming, extraction may be needed",
    actions: [
      {
        id: "edit",
        label: "Edit",
        icon: "fa-pencil",
        popup: EditLink,
      },
      {
        id: "delete",
        label: "Delete",
        icon: "fa-trash",
        popup: DeleteResource,
      },
    ],
  },
  {
    label: "Steam",
    type: types.STEAM,
    name: "Steam Keys",
    addNewPopup: AddSteamKeys,
    availableForFree: false,
    availableForPaid: true,
    warning:
      "The content is activated with a key on Steam, a working Steam account is needed",
    actions: [
      {
        id: "edit",
        label: "Edit",
        icon: "fa-pencil",
        popup: EditSteamKeys,
      },
      {
        id: "delete",
        label: "Delete",
        icon: "fa-trash",
        popup: DeleteResource,
      },
    ],
  },
  {
    label: "File",
    type: types.FILE,
    name: "File Upload",
    addNewPopup: GameFileUpload,
    availableForFree: false,
    availableForPaid: true,
    warning: "The content is hosted on our servers, extraction may be needed",
    actions: [
      {
        id: "edit",
        label: "Edit",
        icon: "fa-pencil",
        popup: EditGameFileUpload,
      },
      {
        id: "delete",
        label: "Delete (Admin Only)",
        icon: "fa-trash-o",
        popup: DeleteResource,
      },
      {
        id: "delete_hint",
        label: "Delete",
        icon: "fa-trash",
        popup: DeleteResourceHint,
      },
    ],
  },
];
