import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import BillingForm, {
  billingSchema,
} from "src/components/BuyTokens/BillingForm";
import { getClass } from "src/utils/getClass";
import Input from "src/v2/components/form-inputs/Input";
import Form from "src/v2/components/form/Form";
import { z } from "zod";
import RequestError from "../../../errors/RequestError";
import useBankart, { BANKART_PUBLIC_TOKEN } from "../../../hooks/useBankart";
import { useToggle } from "../../../hooks/useToggle";
import { authRequest } from "../../../utils/Axios";
import { Agreement } from "../../BuyTokens/data";
import { SubmitButton } from "../../CMS/Common";
import { Loader } from "../../Common";
import "./style.css";
import { usePostHog } from "posthog-js/react";
import { useSWRConfig } from "swr";

const INPUT_STYLES = {
  background: "#262626",
  border: "1px #a3a3a3 solid",
  color: "hsl(0deg 0% 100%)",
  outline: "none",
  position: "relative",
  width: "100%",
  padding: "1.1rem 1.5rem",
  borderRadius: "0.25rem",
  fontFamily: "Rubik, sans-serif",
  fontSize: "16px",
  resize: "none",
};

const dataSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  month: z.string(),
  year: z.string(),
});

const thisYear = new Date().getFullYear();

const BankartPopup = ({ onProceed, onSuccessfullBuy }) => {
  const posthog = usePostHog();
  const payment = useBankart();
  const swr = useSWRConfig();

  const billingForm = useForm({
    resolver: zodResolver(billingSchema),
  });

  const dataForm = useForm({
    resolver: zodResolver(dataSchema),
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [checked, toggleChecked] = useToggle(false);
  const [shouldSaveBilling, toggleShouldSaveBilling] = useToggle(false);

  useEffect(() => {
    payment.current?.init(
      BANKART_PUBLIC_TOKEN,
      "number_div",
      "cvv_div",
      function (payment) {
        payment.setNumberStyle(INPUT_STYLES);
        payment.setCvvStyle(INPUT_STYLES);
        payment.setCvvPlaceholder("Type here...");
        payment.setNumberPlaceholder("Type here...");

        setLoading(false);
      }
    );
  }, [payment]);

  const updateProfile = async (data) => {
    try {
      await authRequest({
        url: "/api/members/profile",
        method: "POST",
        data,
      });
    } catch (error) {
      setError(error.message);
    }
  };

  const proceed = async () => {
    const isValid = await dataForm.trigger();
    if (!isValid) return toast.error("Please Fill the Inputs");

    const isBillingValid = await billingForm.trigger();
    if (!isBillingValid) return toast.error("Please Fill Billing info");

    const data = dataForm.getValues();
    const billingInfo = billingForm.getValues();

    return new Promise((resolve) => {
      payment.current?.tokenize(
        data, //additional data, MUST include card_holder (or first_name & last_name), month and year
        async function (token, cardData) {
          setError(null);
          try {
            if (shouldSaveBilling) {
              await updateProfile(billingInfo);
            }
            const res = await onProceed?.({
              token,
              cardData,
              billing: billingInfo,
            });
            if (res.redirect_url) window.location.replace(res.redirect_url);
            else {
              await onSuccessfullBuy?.(res.order_id);
              toast.success("Payment successful");
            }
          } catch (error) {
            posthog.capture("Payment Error", {
              type: "Credit Card",
              error: error.message,
            });
            setError(error.message);
          } finally {
            resolve();
          }
        },
        function (errors) {
          if (!!errors.length) setError(errors[0].message);
          resolve();
        }
      );
    });
  };

  return (
    <div className="Bankart">
      <h3>Credit Card</h3>
      <p>We will never store any credit card information on our servers</p>
      {loading && <Loader />}

      <Form form={dataForm} className="grid md:grid-cols-6 gap-3">
        <Form.Item
          label="First Name"
          name="first_name"
          className="min-h-0 md:col-span-3"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="last_name"
          className="min-h-0 md:col-span-3"
        >
          <Input />
        </Form.Item>

        <SpecialInput
          label="Card Number"
          name="number_div"
          className={"md:col-span-full"}
        />

        <SpecialInput label="Cvv" name="cvv_div" className={"md:col-span-2"} />

        <Form.Item
          label="Expiration Month"
          name="month"
          className="min-h-0 md:col-span-2"
        >
          <RangedSelect start={1} end={12} autoComplete="cc-exp-month" />
        </Form.Item>
        <Form.Item
          label="Expiration Year"
          name="year"
          className="min-h-0 md:col-span-2"
        >
          <RangedSelect
            start={thisYear}
            end={thisYear + 20}
            autoComplete="cc-exp-year"
          />
        </Form.Item>
      </Form>

      <BillingForm
        form={billingForm}
        shouldSaveBilling={shouldSaveBilling}
        toggleShouldSaveBilling={toggleShouldSaveBilling}
      />

      <RequestError error={error} />

      <Agreement checked={checked} onChecked={toggleChecked} />
      <SubmitButton request={proceed} label="Proceed" disabled={!checked} />

      <div className="badges">
        <div className="badge">
          <img
            src="/images/cc/visa-secure_dkbg_blu_72dpi.jpg"
            alt="visa verified"
            draggable="false"
          />
        </div>
        <div className="badge">
          <img
            src="/images/cc/mc_idcheck_hrz_rgb_rev.svg"
            alt="mastercard verified"
            draggable="false"
          />
        </div>
      </div>
    </div>
  );
};

const SpecialInput = ({ label, name, className }) => {
  return (
    <div
      className={getClass("input text-input", className)}
      data-disable="false"
      data-special
    >
      <div className="title">{label}</div>
      <div className="input-container">
        <div id={name}></div>
      </div>
    </div>
  );
};

const RangedSelect = ({ start, end, ...props }) => {
  const range = [...Array(end - start + 1).keys()].map((x) => x + start);
  return (
    <div className="text-input">
      <select
        name={props.id}
        onChange={props.onChange}
        autoComplete={props.autoComplete}
      >
        <option unselectable="on">Select from below</option>
        {range.map((i) => (
          <option value={i} key={i}>
            {i}
          </option>
        ))}
      </select>
    </div>
  );
};

export default BankartPopup;
