import React, { useEffect, useMemo, useRef, useState } from "react";
import { AddTagsV2, Finder, SubmitButton } from "../../Common";
import Popup from "src/components/common/Popup";
import { Input } from "src/components/Member/General";
import { useToggle } from "src/hooks/useToggle";
import { Checkbox } from "src/components/Register";
import { toast } from "react-toastify";
import { Chip } from "src/components/Chip";
import { useCollectionInfo } from "./UploadCollection";
import { authRequest } from "src/utils/Axios";
import { ASPECT_RATIO } from "src/components/Video/Player/Player";

export const ImagePermissions = {
  EDIT_METADATA: "edit_metadata",
  MAKE_PREMIUM: "make_premium",
  CHANGE_COVER: "change_cover",
  APPLY_EDIT_TO_ALL_IMAGES: "apply_edit_to_all_images",
};

const UploadedImage = ({
  metadata,
  image,
  isPremium,
  togglePremium,
  onRemove,
  reuseInfo,
  onSave,
  getUploadStatus,
  // permission list from "ImagePermissions"
  permissions = [],
}) => {
  const { coverImageId, changeCoverImageId } = useCollectionInfo();
  const [info, setInfo] = useState({});
  const [showPopup, togglePopup] = useToggle();
  const [selectedTags, setSelectedTags] = useState([]);
  const [reuse, toggleReuse] = useToggle();
  const [deletePopup, toggleDeletePopup] = useToggle();
  const [aspectRatio, setAspectRatio] = useState(
    metadata.aspect_ratio ?? ASPECT_RATIO
  );

  const isCoverImage = metadata.id && metadata.id === coverImageId;

  const uploadStatus = getUploadStatus();

  const url = useMemo(() => {
    if (image.online) return image.url;
    return URL.createObjectURL(image.file);
  }, [image]);

  useEffect(() => {
    if (metadata) {
      setInfo({
        description: metadata.description,
      });
      setSelectedTags(formatTags(metadata.tags));
    }
  }, [metadata.id]);

  useEffect(() => {
    if (uploadStatus === "DONE" && !image.online) {
      // newly uploaded images will be updated with aspect ratio onces they are done uploading to server
      onSave?.({ aspect_ratio: aspectRatio });
    }
  }, [uploadStatus]);

  const changeAspectRatio = (e) => {
    const width = e.target.naturalWidth;
    const height = e.target.naturalHeight;
    const aspect_ratio = width / height;
    setAspectRatio(aspect_ratio);

    if (image.online) {
      //  previously uploaded images will be updated with aspect ratio immediately
      onSave?.({ aspect_ratio });
    }
  };

  const updateInfo = (info) => {
    return setInfo((prev) => ({ ...prev, ...info }));
  };

  const onClose = () => {
    onSave?.({
      ...info,
      tags: selectedTags.map((t) => t.param),
    });
    toast.success("Successfully updated.");
    if (reuse) {
      reuseInfo?.({ ...info, tags: selectedTags.map((t) => t.param) });
      toast.success("All images Updated");
    }
    togglePopup();
  };

  const getTags = async (search) => {
    try {
      const res = await authRequest({
        url: "/products/api/spicyart/gallery/image/search-tags",
        params: {
          search_term: search,
        },
      });
      return formatTags(res.map((t) => t.name));
    } catch (error) {
      return [];
    }
  };

  const formatTags = (tags = []) => {
    return tags.map((tag) => ({
      param: tag,
      name: tag?.replace(/_/g, " "),
    }));
  };

  return (
    <div
      className="image"
      data-premium={isPremium}
      data-upload-status={uploadStatus}
      data-cover-image={isCoverImage}
    >
      <div className="image">
        <img
          onLoad={changeAspectRatio}
          width={250}
          src={url}
          alt={image.name}
        />

        {permissions.includes(ImagePermissions.CHANGE_COVER) && !isPremium && (
          <Chip className={"cover-button"}>
            <button onClick={() => changeCoverImageId(metadata.id)}>
              {isCoverImage ? "Cover Image" : "Make Cover Image"}
            </button>
          </Chip>
        )}

        <div className="options">
          {permissions.includes(ImagePermissions.MAKE_PREMIUM) &&
            !isCoverImage && (
              <SubmitButton
                request={() => togglePremium(image.id)}
                className="makePremium"
                icon="fa-usd"
              />
            )}
          {permissions.includes(ImagePermissions.EDIT_METADATA) && (
            <SubmitButton
              request={togglePopup}
              className="edit"
              icon="fa-edit"
            />
          )}
        </div>
      </div>
      <caption onClick={togglePopup}>{info.description}</caption>

      <div className="tags" onClick={togglePopup}>
        {selectedTags.slice(0, 5).map((tag) => (
          <Chip key={tag.name}>{tag.name}</Chip>
        ))}
        {selectedTags.length > 5 && "..."}
      </div>

      {getUploadStatus() !== "LOADING" && (
        <span className="fa fa-close" onClick={toggleDeletePopup}></span>
      )}

      <Popup
        open={deletePopup}
        onClose={toggleDeletePopup}
        showCloseButton
        className={"Player_action_confirmation"}
      >
        <h3>Delete Image?</h3>
        <p>
          This image will be deleted and removed completely from this gallery.
        </p>

        <SubmitButton
          label={"Delete"}
          icon={"fa-trash"}
          request={async () => {
            await onRemove?.(metadata.id, image.hash);
            toggleDeletePopup();
          }}
        />
      </Popup>

      <Popup showCloseButton open={showPopup} onClose={togglePopup}>
        <section className="cms-details GetInfo">
          <h2>Image Details</h2>
          <br />

          <p>
            Providing a good image description will help to be discovered more
            on the web. Add the tags that you think suits the image best, use
            our search to help find the tags.
          </p>
          <div className="inputs">
            <Input
              label="Description"
              callback={(text) => {
                if (text) return updateInfo({ description: text });
              }}
              value={info.description}
            />

            <AddTagsV2
              label="Tags"
              max={40}
              showAllAtFirst
              items={formatTags(metadata?.tags)}
              instruction="You can add minimum 1 tags and maximum 40"
              selectedItems={selectedTags}
              realtimeFetch={getTags}
              onChange={(tags) => {
                setSelectedTags(tags);
              }}
            />
            <br />
            <Finder
              id="game_id"
              fieldNameWantInRequest="id"
              label="Select Game"
              onChange={(e) =>
                updateInfo({ game_id: parseInt(e.target.value) })
              }
              fieldName="title"
              requestLink={
                window.domainName +
                "/api/content-creators/games/search?title=<search>"
              }
              editMode={metadata?.game_id}
              defaults={{
                value: metadata?.game_id,
                indicator: metadata?.game_name,
              }}
            />

            <br />
            {permissions.includes(
              ImagePermissions.APPLY_EDIT_TO_ALL_IMAGES
            ) && (
              <Checkbox
                label={
                  "Apply the description and tags to all the images in this collection"
                }
                id={"reuse"}
                callback={() => toggleReuse()}
              />
            )}
          </div>

          <div className="save">
            <br />
            <SubmitButton label={"Save"} request={onClose} />
          </div>
        </section>
      </Popup>
    </div>
  );
};

export default UploadedImage;
