import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { generatePath, Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import relativeDate from "relative-date";
import { SubmitButton } from "src/components/CMS/Common";
import { LoaderInside } from "src/components/Common";
import Popup from "src/components/common/Popup";
import { generateURL } from "src/components/resizeImages";
import useSwipe from "src/hooks/useSwipe";
import { authRequest } from "src/utils/Axios";
import { formatDuration, numberCompact } from "src/utils/number-format";
import "./style.css";
import { addVideoToWatchLater } from "src/components/CMS/Playlists/utils";
import { useVideosMetaData } from "../MetadataProvider";
import { Avatar } from "src/components/common/Avatar";

const VIDEO_MAX_WIDTH = 400;
export const ASPECT_RATIO = 16 / 9;

const Player = ({
  video,
  onActionTriggered,
  getVideoId,
  disableAction = false,
  horizontal = false,
  lazyLoadImage = true,
  maxWidth = VIDEO_MAX_WIDTH,
  actions = [],
}) => {
  const { ifLiked, updateMetaData } = useVideosMetaData();
  const history = useHistory();
  const thumbnail = useRef();
  const [previewLoading, setPreviewLoading] = useState(false);

  const swipeDirection = useSwipe(thumbnail);
  useEffect(() => {
    if (swipeDirection === "right") {
      togglePreview(true)();
    } else if (swipeDirection === "left") {
      togglePreview(false)();
    }
  }, [swipeDirection]);

  useLayoutEffect(() => {
    const image = thumbnail.current;
    if (image) {
      image.addEventListener("load", removePreviewLoading);
      return () => {
        image.removeEventListener("load", removePreviewLoading);
      };
    }
  }, [thumbnail]);

  const removePreviewLoading = () => setPreviewLoading(false);

  const allActions = useMemo(
    () => [
      {
        id: "edit",
        icon: "fa-edit",
        text: "Edit Video",
        onClick: () =>
          history.push(
            generatePath("/cms/content/videos/edit/:id", { id: video.id })
          ),
      },
      {
        id: "add_to_watch_later",
        icon: "fa-plus",
        text: "Add To Watch Later",
        onClick: () => addVideoToWatchLater(video.id),
      },
      {
        id: "remove",
        icon: "fa-trash-o",
        text: "Delete Video",
        confirmination: {
          title: "Are you sure you want to delete this video?",
          description:
            "This video will be removed completely from the platform and this action cannot be undone",
        },
        onClick: async (video) => {
          await authRequest({
            url: "/community/community-videos/video",
            method: "DELETE",
            data: {
              video_id: video.id,
            },
          });
        },
      },
    ],
    [history, video.id]
  );

  const [confirmation, setConfirmation] = useState(null);
  const clearConfirmation = () => setConfirmation(null);
  const selectedActionForConfirmation = useMemo(
    () => allActions.find((action) => action.id === confirmation),
    [allActions, confirmation]
  );

  const src = generateURL(maxWidth, maxWidth / ASPECT_RATIO, video.thumbnail);

  const togglePreview = (showPreview) => () => {
    if (showPreview) {
      setPreviewLoading(true);
      thumbnail.current.src = video.preview_url;
    } else {
      thumbnail.current.src = src;
    }
  };

  const like = async () => {
    const isLiked = ifLiked(video.id);
    try {
      await toast.promise(
        authRequest({
          url: "/community/community-videos/video/like",
          method: "POST",
          data: { video_id: video.id },
        }),
        {
          pending: isLiked ? "Disliking..." : "Liking...",
          success: isLiked ? "Disliked" : "Liked",
        }
      );
      await updateMetaData();
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="Player" data-horizontal={horizontal} data-testid="Player">
      <div
        className="image"
        title={!video.encoded && "This video is still processing."}
      >
        <span className="duration">{formatDuration(video.length)}</span>
        <Link to={`/video/${video.id}`}>
          <img
            ref={thumbnail}
            onPointerEnter={togglePreview(true)}
            onPointerLeave={togglePreview(false)}
            className="thumbnail"
            width={maxWidth}
            height={maxWidth / ASPECT_RATIO}
            src={src}
            alt={video.title}
            loading={lazyLoadImage ? "lazy" : "eager"}
          />
        </Link>

        {(previewLoading || !video.encoded) && <LoaderInside />}
      </div>
      <div>
        <div className="details">
          <h3>
            <Link to={`/video/${video.id}`}>{video.title}</Link>
          </h3>
          {actions.length > 0 && (
            <button className="options">
              <span className="fa fa-ellipsis-v"></span>

              <div className="menu">
                {allActions
                  .filter((action) => actions.includes(action.id))
                  .map((action) => {
                    const onClickOption = async () => {
                      if (action.confirmination)
                        return setConfirmation(action.id);
                      const output = action.onClick?.(video);
                      if (output instanceof Promise) {
                        try {
                          await toast.promise(output, {
                            pending: "Working...",
                            success: "Successful",
                            error: "Error Occured",
                          });
                        } catch {}
                      }

                      onActionTriggered?.(action.id, video);
                    };
                    return (
                      <div
                        role="button"
                        className="opt"
                        key={action.id}
                        onClick={onClickOption}
                      >
                        <span className={`fa ${action.icon}`}></span>
                        <span>{action.text}</span>
                      </div>
                    );
                  })}
              </div>
            </button>
          )}
        </div>
        <Link to={`/team/@${video.team}/videos`}>
          <div className="author">
            {!horizontal && (
              <Avatar
                src={generateURL(72, 72, video.profile_picture)}
                name={video.team}
                width={30}
              />
            )}

            <span className="uploader">{video.team}</span>
          </div>
        </Link>
        <hr />
        <div className="details">
          <div className="meta">
            <span>
              <span className="fa fa-eye"></span>
              <span>{numberCompact(video.views)}</span>
            </span>
            <button onClick={like} data-tooltip={`Like this Video`}>
              <span
                className={"fa fa-heart"}
                data-clicked={ifLiked(video.id)}
              ></span>
              <span>
                {numberCompact(
                  ifLiked(video.id) ? video.likes + 1 : video.likes
                )}
              </span>
            </button>
            <span>{relativeDate(new Date(video.creation_date))}</span>
          </div>
        </div>
        {getVideoId && (
          <SubmitButton
            disabled={disableAction}
            label="Add Video"
            className="get-id"
            request={() => getVideoId?.(video.id)}
          />
        )}
      </div>

      <Popup
        open={!!confirmation}
        onClose={clearConfirmation}
        showCloseButton
        className={"Player_action_confirmation"}
      >
        <h3>{selectedActionForConfirmation?.confirmination?.title}</h3>
        <p>{selectedActionForConfirmation?.confirmination?.description}</p>

        <SubmitButton
          label={selectedActionForConfirmation?.text}
          icon={selectedActionForConfirmation?.icon}
          request={async () => {
            const output = selectedActionForConfirmation?.onClick?.(video);
            if (output instanceof Promise) {
              await toast.promise(output, {
                pending: "Working...",
                success: "Successful",
                error: "Error Occured",
              });
            }
            onActionTriggered?.(selectedActionForConfirmation?.id, video);

            clearConfirmation();
          }}
        />
      </Popup>
    </div>
  );
};

export default Player;
