import { AxiosRequestConfig } from "axios";
import { authRequest } from "src/utils/Axios";

export const getCredentails = () => {
  let username = localStorage.getItem("username") || null;
  return { username };
};

const loggedInValidation = () => {
  // a simple validation from frontend
  let username = localStorage.getItem("username");
  if (username !== null) {
    return true;
  } else {
    return false;
  }
};

/** Make authorized Requests
 * @param {AxiosRequestConfig} config - axios configuration object for send the requests
 * @param {any} initialReturn - anything you want to return if the Authorization credentials are not found
 * @use `authRequest` /utils/Axios
 * @deprecated
 */
const authorizedRequest = async (config, initialReturn) => {
  try {
    const res = await authRequest({
      ...config,
      baseURL: "",
      headers: {
        "Content-Type": "multipart/form-data",
        ...config.headers,
      },
    });

    return res;
  } catch (error) {
    return initialReturn;
  }
};

/** Validation of data iterators
 *
 * This Fucntion is used to validate Inputs. You need to pass an array or iterator to `items` to check the validation
 * @param {Array} items - object to check (array or object)
 * @param {String} errorMsg - error text if not valid
 * @param {Array<String>} [optionals] - the optional inputs which don't need to check. should be an array of keys
 * @returns {true|string}  `true` if it is validated, if not validate returns the `errorMsg`
 */
const inputIteratorValidate = (items, errorMsg, optionals = []) => {
  const noErrors = [];
  const missedKeys = [];

  for (const key in items) {
    if (!optionals.includes(key)) {
      if (typeof items[key] === "object" && items[key]) {
        if (Object.keys(items[key])?.length > 0) {
          noErrors.push(true);
        } else {
          noErrors.push(false);
          missedKeys.push(key);
        }
      } else if (items[key] && String(items[key])?.length > 0) {
        noErrors.push(true);
      } else {
        noErrors.push(false);
        missedKeys.push(key);
      }
    }
  }
  const missingArray = missedKeys
    .map((key) => key.replace(/_/g, " "))
    .join(", ");
  if (noErrors.every((noError) => noError === true)) {
    return true;
  }
  return `${errorMsg} ( missing ${missingArray} )`;
};
export { authorizedRequest, inputIteratorValidate, loggedInValidation };
