import dateFormat from "dateformat";
import { useContext } from "react";
import useSWRInfinite from "swr/infinite";
import { LoadMore, sort } from "../../Common";
import { authorizedRequest } from "../../Validation";
import { fetcher } from "../Cms";
import { REVALIDATE_ON_FOCUS } from "../Common";
import { MainHeader } from "../Headers";
import { RoleContext } from "../RoleContext";
import Table, { getColumn } from "../Table/Table";
import "../css/GameCreation.css";
import userGameReviewOptions from "./Options";
import { authRequest } from "src/utils/Axios";
import relativeDate from "relative-date";

const columns = [
  getColumn("Game Title", { sortKey: "game_title", accessKey: "game_title" }),
  getColumn("Review", { accessKey: "text" }),
  getColumn("Username", { accessKey: "author" }),
  getColumn("score", { sortKey: "score", accessKey: "score" }),
  getColumn("Date Posted", {
    sortKey: "date_posted",
    accessFn: (game) => relativeDate(new Date(game.date_posted)),
  }),
];

const UserGameReviews = ({ toggler, subHeader }) => {
  // menu button togglers
  const { role } = useContext(RoleContext);

  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) => {
      return `${window.domainName}/products/api/reviews/list?page=${index + 1}`;
    },
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );

  const reviews = data
    ? data.flat().filter((obj) => !(obj.error || obj.last_page))
    : [];

  const errors = data
    ? data.flat().find((obj) => obj.hasOwnProperty("error"))
    : "";

  const search = async (query) => {
    const res = await authRequest({
      url: `/products/api/reviews/cms/search?search_term=${query}`,
    });

    if (res.error) return [];
    return res;
  };
  const searchCallback = (data) => {
    mutate(data, false);
  };
  const getPopupData = (review) => {
    const common = {
      text: review.text,
      game_title: review.game_title,
      creator: review.author,
      created: review.date_posted,
      score: review.score,
      id: review.id,
    };
    return {
      delete: { ...common },
      report: { ...common },
      deny: { ...common },
      view: {
        review: review.text,
        creator: review.author,
      },
      edit: {
        review: review.text,
        creator: review.author,
        id: review.id,
      },
    };
  };
  return (
    <div className="body">
      <MainHeader
        toggler={toggler}
        header={subHeader}
        search={{
          func: search,
          callback: searchCallback,
        }}
      />
      <div className="content">
        <div className="body">
          <Table
            columnCSSConfig="1fr 3fr 1fr 1fr 1fr"
            columns={columns}
            data={reviews}
            getOptions={() => userGameReviewOptions(role)}
            getPopupData={getPopupData}
            refreshData={() => mutate(reviews, true)}
            onSort={(key, order) => mutate(sort(key, reviews, order), false)}
            indicators={[
              {
                color: "#ff4242",
                when: (review) => review.score < 35,
                name: "Score below 35",
              },
              {
                color: "#fff565",
                when: (review) => review.score < 75,
                name: "Score below 75",
              },
            ]}
          />
        </div>
        {errors && <div className="error">{errors.error}</div>}
        {error && <div className="error">{error.message}</div>}

        <LoadMore onClick={() => setSize((prev) => prev + 1)} />
      </div>
    </div>
  );
};

export default UserGameReviews;
