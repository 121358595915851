import { createContext, useContext, useEffect } from "react";
import { io, Socket } from "socket.io-client";
import { isDev } from "../utils/Axios";

const SOCKET_URL = isDev
  ? "https://beta.spicygaming.net:8443"
  : "https://www.spicygaming.net:8443";

const SocketContext = createContext(null);

const username = localStorage.getItem("username");

export const SocketProvider = ({ children }) => {
  const socket = io(SOCKET_URL, { auth: { username } });
  useEffect(() => {
    if (username) {
      socket.on("connect", () => {
        console.debug("Socket connected", socket.id);
      });

      socket.on("disconnect", (reason) => {
        console.debug("Socket disconnected:", reason);
        socket.connect();
      });
    }
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

/**
 * @returns {Socket}
 */
export const useSocket = () => useContext(SocketContext);
