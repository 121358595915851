import React from "react";
import { Helmet } from "react-helmet";
import "./css/Errors.css";
import ProhibitedIcon from "src/Icons/FluentEmojiProhibited";

import { useHistory } from "react-router-dom";
import SubmitButton from "src/v2/components/SubmitButton";

const Error404 = ({ customMessage }) => {
  const history = useHistory();

  const redirect = () => {
    history.push("/");
  };
  return (
    <main className="error-404">
      <Helmet>
        <title>Page not Found - Spicygaming</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="grid place-items-center">
        <div className="image">
          <img loading="lazy" src="/images/Error404.svg" alt="page not found" />
        </div>
        <div className="content grid place-items-center">
          <h1>{customMessage ?? "Page Not Found!"}</h1>
          <p>
            We are sorry but the page was not found. If you think this is an
            error contact{" "}
            <a href="mailto:support@spicygaming.net">Spicygaming support</a>
          </p>

          <SubmitButton onClick={redirect} icon="fa-building">
            Go To Store Page
          </SubmitButton>
        </div>
      </div>
    </main>
  );
};

export const ErrorNotAllowed = () => {
  const history = useHistory();
  return (
    <main className="error-404">
      <div className="image">
        <ProhibitedIcon width={200} height={200} />
      </div>
      <div className="content">
        <h1>RESTRICTED PAGE / PAGE UNDER CONSTRUCTION</h1>

        <SubmitButton onClick={() => history.goBack()}>Go Back</SubmitButton>
      </div>
    </main>
  );
};

export default Error404;
