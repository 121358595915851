import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { BASE_CDN_URL, BASE_URL, request } from "src/utils/Axios";

import { usePostHog } from "posthog-js/react";
import shrinkText from "src/utils/shrinkText";
import updateViewed from "src/utils/updateViewed";
import { useToggle } from "../../hooks/useToggle";
import "../ArticleDetails/css/ArticleDetail.css";
import { BreadCrumb } from "../Atoms/BreadCrumb";
import { SimpleDivider } from "../Atoms/SimpleDivider";
import { SubmitButton } from "../CMS/Common";
import "../CMS/css/ldbtn.min.css";
import { Loader } from "../Common";
import GTags from "../GTags";
import Header from "../Header";
import { generateOriginalURL } from "../resizeImages";
import GameDetailGame from "./GameDetail_Game";
import GameDetailInfo from "./GameDetail_Info";
import ReportGame from "./ReportGame";
import "./css/GameDetail.css";

// import ForceLogin from "../ForceLogin";
// import Error404 from "../Errors";
// import AdultGames from "../AdultGames/AdultGames";

const Error404 = React.lazy(() => import("../Errors"));

const GameDetail = ({ match, preview = false }) => {
  const gameID = match.params.game;
  const history = useHistory();

  const [showGameReport, toggleGameReport] = useToggle();

  const posthog = usePostHog();
  const [game, setGame] = useState({});
  const [creator, setCreator] = useState({});
  const [similarGames, setSimilarGames] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (gameID) {
      setGame({});
      getData(`/products/api/games/${gameID}`, setGame, true);
      getData("/products/api/games/recommended-games?num=8", setSimilarGames);
      updateViewed(gameID, "Game");
    }
  }, [gameID]);

  useEffect(() => {
    if (game.creator_id)
      getData(
        "/api/content-creator-teams/team-profile?team_id=" + game.creator_id,
        setCreator
      );
  }, [game]);

  const getData = async (url, state, initialRequest = false) => {
    try {
      if (initialRequest) setError(null);
      const res = await request({
        method: "GET",
        url,
        withCredentials: true,
        params: { preview },
        baseURL: preview ? BASE_URL : BASE_CDN_URL,
      });

      state(res);
    } catch (error) {
      if (initialRequest) {
        setNotFound(true);
        setError(error.message);
        GTags.pageNotFoundError(window.location.href, error.message);
        posthog?.capture("Page Not Found", {
          url: window.location.href,
          error: error.message,
        });
      }
    }
  };

  const gotoWishlist = () => {
    history.push("/library/games/whishlisted");
  };
  if (notFound)
    return (
      <Suspense fallback={<Loader />}>
        <Error404 customMessage={error} />
      </Suspense>
    );

  if (!game.title)
    return (
      <main style={{ minHeight: "500vh" }}>
        <Loader />
      </main>
    );

  const metaDescription = [
    `Download ${game.title} porn game for ${game.operating_system
      ?.map((os) => (os === "Android" ? "Android APK" : os))
      ?.join(", ")}`,
    game.short_description,
  ];

  return (
    <main id="game-detail">
      <Helmet>
        <title>{`Download ${game.title} porn game - Spicygaming`}</title>
        <meta
          name="description"
          content={shrinkText(metaDescription.join(". "), 150)}
        />
        <link
          rel="canonical"
          href={`https://www.spicygaming.net/game/${gameID}`}
        ></link>
      </Helmet>

      <div className="inner">
        <Header
          searchCallback={(query) => {
            if (query.search?.length > 0)
              return history.push(`/porn-games/${query.search}/1`);
          }}
          extra={
            <SubmitButton
              request={gotoWishlist}
              className="w-full md:w-auto mt-4 md:m-0"
              type="secondary"
              label="See Wishlist"
            />
          }
        />
        <div className="game-detail-container-outer">
          <div itemType="https://schema.org/Product" itemScope>
            <meta itemProp="name" content={game.title} />
            <meta itemProp="description" content={game.short_description} />
            <link
              itemProp="image"
              href={generateOriginalURL(game.landscape_banner)}
            />
            <link
              itemProp="image"
              href={generateOriginalURL(game.square_banner)}
            />

            <SimpleDivider />

            <BreadCrumb divider=">">
              <BreadCrumb.Item href="/">Home</BreadCrumb.Item>
              <BreadCrumb.Item href="/porn-games/1">Games</BreadCrumb.Item>
              <BreadCrumb.Item href={`game/${gameID}`}>
                {game.title} v{game.version}
              </BreadCrumb.Item>
            </BreadCrumb>

            <header>
              <h1 className="title">
                <span>
                  {game.title} v{game.version}
                </span>
                <SubmitButton
                  label={
                    <div data-tooltip="Report Game">
                      <span className="fa fa-flag-o"></span>
                    </div>
                  }
                  request={toggleGameReport}
                  className="report"
                />
              </h1>
              <div className="description">{game.short_description}</div>
            </header>
            <div className="game-detail-container">
              <GameDetailInfo
                creator={creator}
                game={game}
                similarGames={similarGames}
                seeMoreReviews={() =>
                  getData(
                    "/products/api/games/recommended-games",
                    setSimilarGames
                  )
                }
              />

              <GameDetailGame game={game} creator={creator} />
            </div>
            <ReportGame
              id={gameID}
              open={showGameReport}
              onClose={toggleGameReport}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default GameDetail;
