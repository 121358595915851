import React, { Children, useEffect } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import { SubmitButton } from "src/components/CMS/Common";
import { useToggle } from "src/hooks/useToggle";
import "./style.css";

/**

@typedef {Object} ContentProps
@property {React.ReactNode} children - The children elements to be rendered inside the component.
@property {boolean} open
@property {Function} onClose - Callback function to handle close action.
@property {string} className - Additional class names for the component.
@property {string} id - The ID for the component.
@property {React.CSSProperties} style - Inline styles for the component.
@property {boolean} giveCloseCallbackToChildren - Whether to pass the close callback to children elements.
@property {number} maxWidth - Maximum width of the component.
@property {boolean} showCloseButton - Whether to show a close button.
@property {boolean} defaultClose - Whether to use the default wrapper close behavior.
@property {boolean} showExitConfirmation - Whether to show an exit confirmation dialog.
@property {string|null} customExitConfirmationText - Custom text for the exit confirmation dialog.
*/

/**
 *
 * @param {ContentProps} props
 */
const Content = ({
  children,
  onClose,
  className,
  id,
  style,
  giveCloseCallbackToChildren = false,
  maxWidth = 600,
  showCloseButton = false,
  defaultClose = true,
  showExitConfirmation = false,
  customExitConfirmationText = null,
}) => {
  const [showConfirm, toggleShowConfirm] = useToggle(false);

  useEffect(() => {
    return () => {
      toggleShowConfirm(false);
    };
  }, []);

  const popupCloseHandler = () => {
    if (!showExitConfirmation) return onClose?.();
    toggleShowConfirm(true);
  };

  return ReactDOM.createPortal(
    <div className="popup">
      <div
        className="wrapper"
        onClick={defaultClose ? popupCloseHandler : undefined}
      ></div>
      <div
        id={id}
        className={`content ${className}`}
        style={{ "--max-width": maxWidth, ...style }}
      >
        {showCloseButton && (
          <button
            className="fa fa-close popup-close-button"
            onClick={popupCloseHandler}
          ></button>
        )}
        <div className="inner">
          {giveCloseCallbackToChildren
            ? Children.map(children, (child) =>
                React.cloneElement(child, {
                  close: onClose,
                })
              )
            : children}
        </div>
      </div>
      <ConfirmationPopup
        customExitConfirmationText={customExitConfirmationText}
        onCloseConfirmPopup={toggleShowConfirm}
        onCloseMainPopup={onClose}
        open={showConfirm}
      />
    </div>,
    document.querySelector("#popup-portal")
  );
};

const ConfirmationPopup = ({
  open,
  onCloseConfirmPopup,
  onCloseMainPopup,
  customExitConfirmationText,
}) => {
  if (!open) return null;
  return (
    <Popup
      className="Popup-confirmation"
      onClose={onCloseConfirmPopup}
      open={open}
    >
      <h2>Are you sure you want to close this window?</h2>
      <p>{customExitConfirmationText}</p>

      <div className="buttons">
        <SubmitButton
          type="secondary"
          label="Close"
          request={onCloseMainPopup}
        />
        <SubmitButton label="Keep Open" request={onCloseConfirmPopup} />
      </div>
    </Popup>
  );
};

/**
 * **Use this Popup** (this is a standard popup)
 * @param {ContentProps} props
 */
const Popup = (props) => {
  return (
    <CSSTransition
      classNames="fade"
      unmountOnExit
      timeout={200}
      in={props.open}
    >
      <Content {...props} />
    </CSSTransition>
  );
};

export default Popup;
