import React from "react";
import { Link } from "react-router-dom";
import { MetaInfo } from "../Article/MetaData";
import { TYPES } from "./ReviewMini";
import "./css/NewsMiniWide.css";
import { ASPECT_RATIO } from "../Video/Player/Player";

const NewsMini = ({
  id,
  time,
  description,
  img,
  title,
  views,
  likes,
  type,
  maxWidth = 400,
}) => {
  const articleType = TYPES[type];

  return (
    <div role="group" aria-label={title} className="NewsMiniWide">
      <Link to={articleType?.url_prefix + id} title={title}>
        <div className="image">
          <img
            loading="lazy"
            width={maxWidth}
            height={maxWidth / ASPECT_RATIO}
            src={img}
            alt={title}
            draggable="false"
          />
        </div>
      </Link>
      <div className="text">
        <div className="info">
          <span aria-hidden className="fa fa-clock-o"></span>
          <span>{time}</span>
        </div>
        <Link to={articleType?.url_prefix + id}>
          <div className="title">{title}</div>
        </Link>
        <div className="description">{description}</div>

        <MetaInfo id={id} likes={likes} views={views} type="blog" />
      </div>
    </div>
  );
};

export default NewsMini;
