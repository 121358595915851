import { backend } from "./Axios";

// only one refresh request will be occured until this time passes from last sent one
const REFRESH_TIME_GAP = 5 * (1000 * 60); // 5 minutes

const refreshTokenRequestFinishedEventType =
  "custom:refreshTokenRequestFinished";

/**
 * This function refresh the auth-token and return a callback,
 * which returns a promise to wait till the refresh token is finished executing.
 *
 * if success, a custom event will be fired with `{ detail: true }` and the promise fulfilled

 * if not success and 401, logs out user with alert
 *
 * if not success and not 401, event fired with `{ detail: false }` and the promise fulfilled
 *
 * if the refersh token requested in the same callback, no listner will be returned, only a success response
 */
export const refresh = async () => {
  const refreshWaitCallback = () =>
    new Promise((resolve) => {
      window.document.addEventListener(
        refreshTokenRequestFinishedEventType,
        resolve
      );
    });

  if (Date.now() - window.last_refreshed < REFRESH_TIME_GAP) {
    return refreshWaitCallback;
  }
  if (window.refresh_started) {
    return refreshWaitCallback;
  }

  try {
    window.refresh_started = true;
    await backend({
      url: "/api/members/refresh-token",
      method: "POST",
      withCredentials: true,
    });
    window.last_refreshed = Date.now();
    window.refresh_started = false;

    setTimeout(() => {
      window.document.dispatchEvent(
        new CustomEvent(refreshTokenRequestFinishedEventType, { detail: true })
      );
    }, 500);
  } catch (error) {
    if (error.response?.status === 401) {
      // user may logged out.
      localStorage.clear();
      alert("Your loggin session has expired. Please login again");
      window.location.replace(`/login${window.location.pathname}`);
    } else {
      window.document.dispatchEvent(
        new CustomEvent(refreshTokenRequestFinishedEventType, { detail: false })
      );
    }
  }

  // refresh token requested in this refresh() call
  // so no listner required to be returned
  // returning a listner in this case, creates a dead-lock
  return async () => ({ detail: true });
};
