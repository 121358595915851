import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import RequestError from "../errors/RequestError";
import { useToggle } from "../hooks/useToggle";
import { request } from "../utils/Axios";
import { OTP_REGEX } from "../utils/regex";
import { SubmitButton } from "./CMS/Common";
import { Loader } from "./Common";
import { storeCredentials } from "./Login";
import { Input } from "./Member/General";
import { MultiLang } from "./MultiLang";
import Popup from "./common/Popup";
import "./css/Login.css";

const Login2FA = () => {
  const [token, setToken] = useState(null);
  const [error, setError] = useState(false);
  const [code, setCode] = useState();
  const [loading, setLoading] = useState(false);
  const [showBackup, toggleShowBackup] = useToggle();
  const [backupCode, setBackupCode] = useState();

  const history = useHistory();

  const credentials = history.location.state;

  useEffect(() => {
    init(credentials);
  }, [credentials]);

  const init = async (credentials) => {
    try {
      const res = await request({
        url: "/trenchauth/login/",
        method: "POST",
        data: {
          username: credentials.username,
          password: credentials.password,
        },
      });
      setToken(res.ephemeral_token);
    } catch (error) {
      setError(error.message);
    }
  };

  const submit = async (usingBackupCodes = false) => {
    try {
      setLoading();
      setError("Requesting...");

      if (!usingBackupCodes && !code)
        throw new Error("Enter code from your authenticator app");
      if (!token) throw new Error("Invalid Attempt");
      const res = await request({
        url: "/trenchauth/login/code/",
        method: "POST",
        withCredentials: true,
        data: {
          code: usingBackupCodes ? backupCode : code,
          remember: credentials.remember,
          ephemeral_token: token,
        },
      });

      storeCredentials({
        key: res.auth_token,
        username: credentials.username,
      });

      setError("Successfully logged in");

      if (credentials.customLink === true) {
        setTimeout(() => {
          window.location.pathname = `/${credentials.link}`;
        }, 250);
      } else {
        setTimeout(() => {
          window.location.pathname = "/";
        }, 250);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!credentials?.username)
    return (
      <main className="login">
        <div className="inner">
          <header>Login Failed</header>
          <button onClick={() => history.push("/login")}>Try Again</button>
        </div>
      </main>
    );
  return (
    <main className="login">
      {loading && <Loader />}
      <div className="inner">
        <header>
          <h1>
            <MultiLang sl="">Verify Your Login</MultiLang>
          </h1>
        </header>
        <div className={`message ${error && "error-msg"}`}>
          {error ? (
            error
          ) : (
            <MultiLang sl="">
              {credentials.method === "app"
                ? "Enter the code from your two-factor application below."
                : "Enter the code you recieved to your email."}
            </MultiLang>
          )}
        </div>
        <div className="inputs">
          <Input callback={setCode} placeholder="******" regex={OTP_REGEX} />
        </div>

        <button onClick={() => submit()}>
          <MultiLang sl="">Proceed</MultiLang>
        </button>

        <div className="footer">
          <div className="title2">
            <div onClick={toggleShowBackup}>
              <MultiLang sl="">Enter Backup code instead</MultiLang>
            </div>
            <br />
            <br />
            <Link to={"/login"}>
              <MultiLang sl="">Back to login page</MultiLang>
            </Link>
          </div>
        </div>
      </div>
      <Popup className={"login"} open={showBackup} onClose={toggleShowBackup}>
        <h2>Enter Backup code</h2>
        <br />
        <p>Enter a backup code you've saved earlier</p>
        <p>You can use each code exactly once to log into your account.</p>
        <Input
          callback={setBackupCode}
          label="Backup Code"
          value={backupCode}
        />
        <RequestError error={error} />
        <SubmitButton label="Proceed" request={() => submit(true)} />
      </Popup>
    </main>
  );
};

export default Login2FA;
