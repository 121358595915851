import React, { useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import RequestError from "../../../../errors/RequestError";
import { authRequest } from "../../../../utils/Axios";
import { Input } from "../../../Member/General";
import { AddTags, SubmitButton } from "../../Common";
import { OS } from "../UploadGame";
import { preSelectResources } from "./contentData";
import { resourceTypes, types } from "./types";
import { Link, generatePath } from "react-router-dom";
import generateImageObjectFromPath from "src/components/SpicyArt/generateImageObject";

export const resourceInputs = [
  {
    name: "name",
    required: true,
    label: "Name",
    placeholder: "My Awesome Game v1.0",
  },
  {
    name: "operating_system",
    Component({ updateInfo }) {
      return (
        <div className="resource-name">
          <div className="item">
            <AddTags
              required
              label="Select OS"
              title={"OS"}
              max={Infinity}
              dataKey={"operating_system"}
              onChange={updateInfo}
              option={OS}
            />
          </div>
        </div>
      );
    },
  },
];

export const AddResource = ({
  download,
  onClose,
  onSubmit,
  gameId,
  creatorApproved,
  creatorTeamId,
}) => {
  const [info, setInfo] = useState({});
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const submitDisabled = useMemo(() => {
    if (!info.type) return true;
    if (!info.name) return true;
    if (info.operating_system?.length <= 0) return true;

    if (info.type === types.FILE && !data[info.type]?.file_id) return true;
    if (info.type === types.LINK && !data[info.type]?.external) return true;
    if (info.type === types.STEAM && !data[info.type]) return true;
  }, [data, info.name, info.type, info.operating_system]);

  const TypeInputs = useMemo(() => {
    const fallback = () => null;
    return React.forwardRef(
      resourceTypes.find((res) => res.type === info.type)?.addNewPopup ??
        fallback
    );
  }, [info.type]);

  /** This is used to upload bulk steam keys after the resource creation.
   *  the upload process will be done in the {@link TypeInputs}
   *  @param {Function} onResourceCreation will be called
   *  */
  const typeInputsRef = useRef({});

  const updateInfo = (key) => (value) => {
    setInfo((prev) => ({ ...prev, [key]: value }));
  };

  const updateType = (e) => {
    const type = e.target.value;
    return setInfo((prev) => ({ ...prev, type }));
  };

  const onSubmitTypeData = (data) => {
    setData((prev) => ({ ...prev, [info.type]: data }));
  };

  const submit = async () => {
    try {
      if (!info.type) throw new Error("Please select a type");
      if (!info.name) throw new Error("Please add a name");
      if (info.operating_system?.length <= 0)
        throw new Error("Please add Operating systems");

      if (info.type === types.FILE && !data[info.type]?.file_id)
        throw new Error("Please wait for the file upload to be completed");

      if (info.type === types.STEAM && !data[info.type])
        throw new Error("Upload Bulk file or add Keys");

      const res = await authRequest({
        url: "/downloads/api/games/downloads/resources/create",
        method: "POST",
        data: {
          download_id: download.id,
          type: info.type,
          name: info.name,
          operating_system: info.operating_system,
          data: data[info.type],
        },
      });
      await typeInputsRef.current?.onResourceCreation?.(res);
      onSubmit?.(res);
      onClose?.();
      toast.success(`Resource ${info.name} created successfully`);
    } catch (error) {
      setError(error.message);
    }
  };
  return (
    <div className="AddResource">
      <h2>Add New Resource for {download?.name}</h2>
      <div className="info">
        <p>Each "Download" contains "Resources"</p>
        <p>
          Select from the list of names or describe what the resource contains:
          For example Game-v021.zip Select all the OS this resource is suitable
          for
        </p>
        <div>
          Resources can be:
          <ul>
            <li>
              Files: (Game files, image packs, pdf), hosted on our servers.
            </li>
            <li>Steam keys: Insert the Steam keys for your games.</li>
            <li>
              External links to Files (<strong>Only for Free downloads</strong>
              ): You can use one of the approved providers
              <strong>
                (Mega, Gdrive, Wow, GoFile, UploadHaven, Mixdrop).
              </strong>
            </li>
          </ul>
        </div>

        <p>You can have several "Resources" in each "Download"</p>
      </div>

      <div className="fields">
        <div className="select-type">
          <label htmlFor="type">Select Type</label>
          <select name="resource_type" onChange={updateType}>
            <option>Select from below</option>
            {resourceTypes
              .filter((resourceType) => {
                if (resourceType.type === types.FILE)
                  // if creator is approved he can upload files even for free downloads
                  return creatorApproved === true;
                if (download.price <= 0) return resourceType.availableForFree;
                if (download.price > 0) return resourceType.availableForPaid;
                return false;
              })
              .map((resourceType) => (
                <option value={resourceType.type} key={resourceType.type}>
                  {resourceType.name}
                </option>
              ))}
          </select>
        </div>
        {creatorApproved || info.type === types.LINK ? (
          <>
            {resourceInputs.map((input) => {
              if (input.Component)
                return (
                  <input.Component
                    download={download}
                    key={input.name}
                    updateInfo={setInfo}
                    info={info}
                  />
                );
              return (
                <Input
                  key={input.name}
                  callback={updateInfo(input.name)}
                  controlledValue={info[input.name] ?? ""}
                  label={input.label}
                  placeholder={input.placeholder}
                  required={input.required}
                  list={"resource-" + input.name}
                />
              );
            })}
            <TypeInputs
              ref={typeInputsRef}
              gameId={gameId}
              data={data?.[info.type]}
              onSubmit={onSubmitTypeData}
            />
          </>
        ) : (
          info.type && (
            <p>
              Your team has not been verified yet!. thus you can only add{" "}
              <strong>External Link</strong> for the resources.
              <br />
              <Link
                to={generatePath("/cms/personal/teams/edit/:id/payout-info", {
                  id: creatorTeamId,
                })}
              >
                Please complete the verification here
              </Link>
            </p>
          )
        )}
      </div>
      <RequestError error={error} />
      <SubmitButton request={submit} label="Submit" disabled={submitDisabled} />

      <datalist id={"resource-name"}>
        {preSelectResources.map((r) => (
          <option key={r.label} value={r.label}>
            {r.label}
          </option>
        ))}
      </datalist>
    </div>
  );
};
