import { Children, useCallback, useEffect, useRef, useState } from "react";
import useIntersection from "src/hooks/useIntersection";
import "./style.css";

export const loadLazyLoadableContent = () => {
  document.dispatchEvent(new CustomEvent("custom:loadLazyLoadableContent"));
};

const LazyLoaded = ({ children, rootMargin }) => {
  return (
    <div className="LazyLoaded" data-testid="LazyLoaded">
      {Children.map(children, (child, key) => {
        return (
          <ObservableElement
            rootMargin={rootMargin}
            element={child}
            key={key}
          />
        );
      })}
    </div>
  );
};

const observerExceptionUAs = [
  "Prerender (+https://github.com/prerender/prerender)",
];

const ObservableElement = ({ element, rootMargin }) => {
  const ref = useRef(null);
  const [intersected, setIntersected] = useState(() => {
    if (observerExceptionUAs.find((ua) => navigator.userAgent.includes(ua)))
      return true;
    return false;
  });
  const intersection = useIntersection(ref, {
    rootMargin: rootMargin ?? "400px",
    once: true,
  });

  const callback = useCallback(() => {
    setIntersected(true);
  }, []);

  useEffect(() => {
    document.addEventListener("custom:loadLazyLoadableContent", callback);
    return () => {
      document.removeEventListener("custom:loadLazyLoadableContent", callback);
    };
  }, [callback]);

  useEffect(() => {
    if (intersection?.isIntersecting && !intersected) {
      setIntersected(true);
    }
  }, [intersected, intersection]);

  return (
    <div ref={ref}>
      {intersected ? element : <div className="blank_placeholder"></div>}
    </div>
  );
};

export default LazyLoaded;
