import { CSSTransition, TransitionGroup } from "react-transition-group";
import relativeDate from "relative-date";
import useSWRInfinite from "swr/infinite";
import { withoutLastPage } from "../../../utils/withoutLastPage";
import { fetcher } from "../../CMS/Cms";
import { ContentTile } from "../../CMS/Tile";
import { toSpicyTokensWithEuro } from "../../CMS/Users/Withdraw";
import { Header, LoadMore, sort } from "../../Common";
import "./style.css";

const TokenTransfers = () => {
  const { data, error, setSize, mutate } = useSWRInfinite(
    (index) =>
      `${window.domainName}/store/api/members/token-transfers?page=${
        index + 1
      }`,
    fetcher,
    { revalidateOnFocus: false }
  );

  const orders = withoutLastPage(data?.flat?.() ?? []);

  const sortState = (key) => (order) => {
    mutate(sort(key, orders, order), false);
  };

  const headers = [
    { text: "ID", sort: sortState("id") },
    { text: "SENDER", sort: sortState("sender") },
    { text: "RECIEVER", sort: sortState("reciever") },
    { text: "AMOUNT", sort: sortState("amount") },
    { text: "DATE", sort: sortState("creation_date") },
  ];

  return (
    <main className="cms-details TokenTransfers">
      <div
        className="header"
        style={{
          gridTemplateColumns: `var(--game-item-frac) 4fr repeat(${
            headers.length - 2
          }, var(--game-item-frac)) 2fr`,
        }}
      >
        {headers.map((header, i) => (
          <Header header={header} key={i} />
        ))}
      </div>
      <TransitionGroup className="body" component="div">
        {orders.map((order) => {
          const data = [
            order.transaction.transaction_id,
            order.receiver,
            order.sender,
            toSpicyTokensWithEuro(order.amount),
            relativeDate(new Date(order.transaction.creation_date)),
          ];

          return (
            <CSSTransition
              key={order.id}
              timeout={300}
              classNames="popup"
              mountOnEnter
            >
              <ContentTile
                headers={headers.map((header) => header.text)}
                data={data}
              />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
      {error && <div className="error">{error.message}</div>}
      <LoadMore onClick={() => setSize((prev) => prev + 1)} />
    </main>
  );
};

export default TokenTransfers;
