import { useRef } from "react";
import { useEffect } from "react";
import { isDev } from "../utils/Axios";

const TEST_TOKEN = "tNjPFt2vJCcVLhH2qBy2";
const PROD_TOKEN = "cIpZNWS0MMZXtnB8M2qW";
export const BANKART_PUBLIC_TOKEN = !isDev ? PROD_TOKEN : TEST_TOKEN;

const useBankart = () => {
  const payment = useRef(null);
  useEffect(() => {
    if (!window.PaymentJs) return null;
    payment.current = new window.PaymentJs();
    return () => {
      payment.current = null;
    };
  }, []);

  return payment;
};
export default useBankart;
