import React, { useState } from "react";
import { toast } from "react-toastify";
import { authRequest } from "../../utils/Axios";
import { SubmitButton } from "../CMS/Common";
import Popup from "../common/Popup";
import { Input } from "../Member/General";

const ReportGame = ({ open, onClose, id }) => {
  const [reason, setReason] = useState();
  const [selectedOption, setSelectedOption] = useState(undefined);

  const onChangeOption = (e) => {
    const method = e.target.value;
    setSelectedOption(method);
  };

  const onChange = (value) => {
    setReason(value);
  };

  const sendReport = async () => {
    try {
      const res = await authRequest({
        url: "/products/api/games/report-game",
        method: "POST",
        data: { game_id: id, reason: selectedOption },
      });
      if (res.success) toast.info("You have reported this game");
    } catch (error) {
      toast.error(error.message);
    } finally {
      onClose?.(false);
    }
  };
  return (
    <Popup
      className="game-report-popup"
      showCloseButton
      open={open}
      onClose={onClose}
    >
      <h2>Report this game</h2>
      <p>Tell us a little bit why you want to report this game.</p>
      <div className="options">
        {options.map((option) => (
          <div className="option" key={option.label}>
            <input
              type="radio"
              name={id + "option"}
              id={id + option.label}
              value={option.label}
              checked={option.label === selectedOption}
              onChange={onChangeOption}
            />

            <label htmlFor={id + option.label}>{option.label}</label>
          </div>
        ))}
      </div>

      <SubmitButton
        label="Report"
        disabled={selectedOption === undefined}
        request={sendReport}
      />
    </Popup>
  );
};

const options = [
  { label: "Spam or Scam" },
  { label: "Underage or child abuse" },
  { label: "Hateful or abusive content" },
  { label: "Violent or repulsive content" },
  { label: "Infirnges my rights" },
  { label: "Harassment" },
];

export default ReportGame;
