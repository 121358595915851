import { useState } from "react";
import { Tabs } from "src/components/Tabs";
import { SaleInfo } from "./AddSaleInfo";
import { SaleProducts } from "./SaleProducts";
import { Chip } from "src/components/Chip";

const AddNewSale = ({
  editMode = false,
  defaultSaleId = null,
  defaultValues,
  defaultPageId = "info",
  isPublicSale = false,
}) => {
  const [saleId, setSaleId] = useState(defaultSaleId);

  return (
    <div>
      <h2 className="text-2xl mb-4">
        {editMode ? "Edit Sale" : "Add New Sale"}
        {isPublicSale && <Chip>Public Sale</Chip>}
      </h2>

      <Tabs
        firstPageId={defaultPageId}
        propsForEachPageComponent={{
          saleId,
          setSaleId,
          defaultValues,
          editMode,
          isPublicSale,
        }}
        pages={[
          {
            component: SaleInfo,
            displayText: "Sale Info",
            id: "info",
          },
          {
            component: SaleProducts,
            displayText: "Discounted Products",
            id: "products",
            disabled: !saleId,
          },
        ]}
      />
    </div>
  );
};

export default AddNewSale;
