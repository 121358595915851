import { isEnabledFeature } from "src/features/enabledFeatures";

const FeatureFlag = ({ children, feature }) => {
  const isEnabled = isEnabledFeature(feature);
  if (isEnabled) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default FeatureFlag;
