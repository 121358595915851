import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import GTags from "src/components/GTags";
import useQuery from "../../hooks/useQuery";
import { authRequest } from "../../utils/Axios";
import { LoaderInside } from "../Common";
import { SuccessIcon } from "../common/social";
import Actions from "./Actions";
import "./callback-pages.css";
import { useSpicyStore } from "src/providers/SpicyStoreProvider";

const PaymentSuccess = () => {
  const query = useQuery();
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const orderID = query.get("order_id");
  const store = useSpicyStore();

  useEffect(() => {
    getOrderInfo();
  }, []);

  const getOrderInfo = async () => {
    try {
      const info = await store.getOrderInfo(orderID);
      setInfo(info);
      store.savePurchaseRecord(info);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!orderID) return <Redirect to="/" />;
  if (loading) return <LoaderInside />;

  return (
    <main className="payment-success">
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <h1>
        Your Order #{orderID} was <span className="green">Successfully</span>
        Completed
      </h1>
      <div className="icon">
        <SuccessIcon />
      </div>
      <Actions />
    </main>
  );
};

export default PaymentSuccess;
