// IndexedDBContext.js

import React, { createContext, useContext } from "react";
import useIndexedDB from "src/hooks/useIndexedDB";

const IndexedDBContext = createContext({
  get: (_key, _callback) => {},
  set: (_key, _value) => {},
});

export const IndexedDBProvider = ({ children }) => {
  const indexedDB = useIndexedDB("Spicygaming", "SpicygamingStore");

  return (
    <IndexedDBContext.Provider value={indexedDB}>
      {children}
    </IndexedDBContext.Provider>
  );
};

export const useIndexedDBGlobal = () => {
  return useContext(IndexedDBContext);
};

export default IndexedDBContext;
