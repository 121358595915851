import { useEffect, useState } from "react";
import relativeDate from "relative-date";
import { MultiLang } from "src/components/MultiLang";
import { Avatar } from "src/components/common/Avatar";
import {
  Discord,
  Itchio,
  Patreon,
  Social,
  Subscribestar,
} from "src/components/common/social";
import { generateURL } from "src/components/resizeImages";
import RequestError from "src/errors/RequestError";
import { BASE_CDN_URL, request } from "src/utils/Axios";

const AboutSection = ({ teamId, teamName }) => {
  const [members, setMembers] = useState([]);
  const [teamInfo, setTeamInfo] = useState({});
  const [error, setError] = useState();
  useEffect(() => {
    if (teamId) getMembers();
  }, [teamId]);
  useEffect(() => {
    getTeamInfo();
  }, []);

  const getTeamInfo = async () => {
    try {
      const res = await request({
        url: "/api/content-creator-teams/team-profile",
        withCredentials: true,
        params: {
          team_name: teamName,
        },
      });
      setTeamInfo(res);
    } catch (error) {
      setError("Failed to load");
    }
  };

  const getMembers = async () => {
    try {
      const res = await request({
        url: "/api/content-creator-teams/members/details",
        withCredentials: true,
        params: {
          team_id: teamId,
        },
      });
      setMembers(res);
    } catch (error) {}
  };
  return (
    <div className="about">
      <RequestError error={error} />
      <section>
        <legend>
          <h2 className="header">Description</h2>
          <p>{teamInfo.biography}</p>
          {teamInfo.support_url && (
            <p className="support">
              support:{" "}
              <a
                href={teamInfo.support_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {teamInfo.support_url}
              </a>
            </p>
          )}
        </legend>
      </section>
      <section>
        <legend>
          <h2 className="header">Links</h2>
        </legend>
        <div className="social">
          <Social
            path={teamInfo.facebook_link}
            fa_name="fa-facebook"
            title="Facebook"
          />
          <Social
            path={teamInfo.twitter_link}
            fa_name="fa-twitter"
            title="Twitter"
          />
          <Social
            path={teamInfo.instagram_link}
            fa_name="fa-instagram"
            title="Instagram"
          />
          <Social
            path={teamInfo.website_link}
            fa_name="fa-globe"
            title="Website"
          />
          <Social path={teamInfo.discord_link} title="Discord">
            <Discord />
          </Social>
          <Social path={teamInfo.patreon_link} title="Patreon">
            <Patreon />
          </Social>
          <Social path={teamInfo.steam_link} fa_name="fa-steam" title="Steam" />
          <Social path={teamInfo.itchio_link} title="Itchio">
            <Itchio />
          </Social>
          <Social path={teamInfo.subscribestar_link} title="Subscribestar">
            <Subscribestar />
          </Social>
        </div>
      </section>
      <section>
        <legend>
          <h2 className="header">
            <MultiLang sl="Člani">TEAM MEMBERS</MultiLang>
          </h2>
        </legend>
        <div className="part member">
          <div className="content">
            {members.map((member, i) => (
              <MemberMini {...member} key={i} />
            ))}
          </div>
        </div>
      </section>
      <section>
        <legend>
          <h2 className="header">Stats</h2>
          <ul className="stats">
            <li>Joined {relativeDate(new Date(teamInfo.creation_date))}</li>
            <li>{teamInfo.member_count} Members</li>
            <li>{teamInfo.videos_count} Videos</li>
            <li>{teamInfo.games_count} Games</li>
            <li>{teamInfo.followers} Followers</li>
          </ul>
        </legend>
      </section>
    </div>
  );
};

const MemberMini = ({
  id,
  username,
  profile_picture,
  role,
  subrole,
  facebook_link,
  twitter_link,
  instagram_link,
  website_link,
  patreon_link,
  steam_link,
}) => (
  <div className="member-mini">
    <Avatar
      name={username}
      src={generateURL(300, 300, profile_picture)}
      width={150}
    />

    <div className="name">{username}</div>

    <div className="meta">
      <div className="cart">
        <div className="title">Role</div>
        <div className="content">{role}</div>
      </div>
      <div className="cart">
        <div className="title">Sub-Role</div>
        <div className="content">{subrole}</div>
      </div>
    </div>
    <div className="social">
      <Social path={facebook_link} fa_name="fa-facebook" />
      <Social path={twitter_link} fa_name="fa-twitter" />
      <Social path={instagram_link} fa_name="fa-instagram" />
      <Social path={patreon_link} fa_name="">
        <Patreon />
      </Social>
      <Social path={steam_link} fa_name="fa-steam" />

      <Social path={facebook_link} fa_name="fa-facebook" />
      <Social path={twitter_link} fa_name="fa-twitter" />
      <Social path={instagram_link} fa_name="fa-instagram" />
      <Social path={website_link} fa_name="fa-globe" />

      <Social path={patreon_link} fa_name="">
        <Patreon />
      </Social>
      <Social path={steam_link} fa_name="fa-steam" />
    </div>
  </div>
);
export default AboutSection;
