import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import relativeDate from "relative-date";
import { isNeedInHorizontal } from "src/components/ArticleDetails/Elements";
import Banner from "src/components/Banners";
import Game from "src/components/AdultGames/Game";
import { AD_IDS } from "src/components/CMS/Ads/Section";
import { Chip } from "src/components/Chip";
import { Loader } from "src/components/Common";
import Error404 from "src/components/Errors";
import { LazyLoaded } from "src/components/LazyLoaded";
import VideoPlayer from "src/components/VideoPlayer";
import { vastPullzones } from "src/components/VideoPlayer/pullzones";
import { generateURL } from "src/components/resizeImages";
import RequestError from "src/errors/RequestError";
import { BASE_CDN_URL, authRequest, request } from "src/utils/Axios";

import { numberCompact } from "src/utils/number-format";
import { ASPECT_RATIO } from "../Player/Player";
import VideoComments from "./Comments";
import CreatorInfo, { ContentMetaInfo } from "./CreatorInfo";
import SuggestVideos from "./SuggestedVideos";
import "./style.css";
import { MiniPlaylist } from "src/components/Playlists/MiniPlaylist";
import { Helmet } from "react-helmet";
import updateViewed from "src/utils/updateViewed";
import { useVideosMetaData } from "../MetadataProvider";

const VideoFullPage = () => {
  const { id } = useParams();
  const { ifLiked, updateMetaData } = useVideosMetaData();
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState({});
  const [error, setError] = useState(null);
  const [relatedPlaylists, setRelatedPlaylists] = useState([]);

  useEffect(() => {
    getDetails(id);
    getRelatedPlaylists(id);
    updateViewed(id, "Videos");
  }, [id]);

  const getDetails = async (id) => {
    try {
      setInfo({}); // reset previous video
      setLoading(true);
      const res = await request({
        url: "/community/community-videos/video",
        baseURL: BASE_CDN_URL,
        params: { video_id: id },
        withCredentials: true,
      });
      setInfo(res);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  const getRelatedPlaylists = async (id) => {
    try {
      setLoading(true);
      const res = await request({
        url: "/community/community-videos/video/related-playlists",
        baseURL: BASE_CDN_URL,
        params: { video_id: id },
      });
      setRelatedPlaylists(res);
    } catch {}
  };

  const likeVideo = async () => {
    const isLiked = ifLiked(info.video_id);

    try {
      await toast.promise(
        authRequest({
          url: "/community/community-videos/video/like",
          method: "POST",
          data: { video_id: info.video_id },
        }),
        {
          pending: isLiked ? "Disliking..." : "Liking...",
          success: isLiked ? "Disliked" : "Liked",
        }
      );
      await updateMetaData();
    } catch (error) {
      toast.error(error.message);
    }
  };
  if (error) return <Error404 />;

  return (
    <main className="FullPage" data-testid="FullPage">
      <Helmet>
        <title>{`Watch ${info.title} porn video - Spicygaming`}</title>
        <base href="/" target="_blank" />
      </Helmet>
      <div className="inner">
        {loading ? (
          <Loader />
        ) : (
          <div className="content">
            <article>
              {!info.file?.encoded && (
                <RequestError error="This video is still processing. Please visit later" />
              )}
              <VideoPlayer
                options={{ aspectRatio: "16:9" }}
                vastZone={info.team.zone_id ?? vastPullzones.default}
                videoID={info.file?.bunny_id}
                folderID={info.file?.library_id}
                pullzoneID={info.file?.pullzone}
                thumbnailName={generateURL(
                  720,
                  parseInt(720 / ASPECT_RATIO),
                  info.thumbnail
                )}
              />
              <h1>
                <span>{info.title}</span>
              </h1>
              <LazyLoaded>
                <div className="info">
                  <CreatorInfo
                    likeContent={likeVideo}
                    numOfLikes={info.likes}
                    team={info.team}
                    shareLink={window.location.href}
                    shareText={info.title}
                    videoID={info.video_id}
                    isLiked={ifLiked}
                  />
                  <ContentMetaInfo
                    dangerouslySetInnerHTML
                    creation_date={info.creation_date}
                    description={info.description}
                    tags={info.tags.map((t) => ({ param: t, name: t }))}
                    views={info.views}
                    setTagUrl={(param) => `/videos/tag/${param}/`}
                  />
                </div>

                {!!info.games?.length && (
                  <section className="adult-games element-games">
                    <hr />
                    <legend>
                      <h2>Games Mentioned</h2>
                    </legend>
                    <OwlCarousel
                      className="owl-theme"
                      dots
                      responsive={{
                        0: { items: 1 },
                        480: {
                          items: isNeedInHorizontal(info.games?.length) ? 1 : 2,
                        },
                        1200: {
                          items: isNeedInHorizontal(info.games?.length) ? 1 : 3,
                        },
                      }}
                    >
                      {info.games?.map((game) => (
                        <Game
                          {...game}
                          key={game.id}
                          isHorizontal={isNeedInHorizontal(info.games?.length)}
                        />
                      ))}
                    </OwlCarousel>
                  </section>
                )}
                {!!relatedPlaylists.length && (
                  <section className="adult-games element-games">
                    <hr />
                    <legend>
                      <h2>Continue watching</h2>
                    </legend>
                    <OwlCarousel
                      className="owl-theme"
                      dots
                      margin={20}
                      responsive={{
                        0: { items: 1 },
                        840: { items: 2 },
                        1200: { items: 3 },
                      }}
                    >
                      {relatedPlaylists.map((playlist) => (
                        <MiniPlaylist key={playlist.id} playlist={playlist} />
                      ))}
                    </OwlCarousel>
                  </section>
                )}

                <hr />
                <VideoComments videoId={id} />
              </LazyLoaded>
            </article>
            <aside>
              <Banner
                id={AD_IDS.front.gallery}
                width={300}
                height={250}
                jsAd="85914"
              />
              <hr />
              <SuggestVideos teamName={info.team.name} videoId={id} />
            </aside>
          </div>
        )}
      </div>
    </main>
  );
};

export default VideoFullPage;
