import React, { useMemo, useState } from "react";
import RequestError from "../../../errors/RequestError";
import { authRequest } from "../../../utils/Axios";
import { OTP_REGEX } from "../../../utils/regex";
import { SubmitButton } from "../../CMS/Common";
import { Input } from "../General";
import { methods_2fa } from "./methods";

const ChangePrimary = ({ enabledList, onChange }) => {
  const currentPrimary = useMemo(
    () => enabledList.find((m) => m.is_primary),
    [enabledList]
  );
  const [code, setCode] = useState();
  const [error, setError] = useState();
  const [selectedOption, setSelectedOption] = useState(currentPrimary?.name);

  const changePrimary = async () => {
    try {
      setError(null);
      await authRequest({
        url: "/trenchauth/mfa/change-primary-method/",
        method: "POST",
        data: {
          method: selectedOption,
          code,
        },
      });
      await onChange?.();
    } catch {
      setError("Code invalid or expired.");
    }
  };
  const onChangeOption = (e) => {
    const method = e.target.value;
    setSelectedOption(method);
  };

  return (
    <div className="">
      <h2>Select Primary Method</h2>
      <p>
        Note: To switch to the other method first select it then enter the code
        from the currently used method.
      </p>
      <p>
        For example you want to switch from Email to App:
        <ul>
          <li>Select App</li>
          <li>
            then enter the code from your email which you can get by clicking
            the send code button.
          </li>
        </ul>
      </p>

      {enabledList.map((m) => {
        const method = methods_2fa.find((method) => method.name === m.name);
        return (
          <div className="option" key={m.name}>
            <input
              type="radio"
              name={"2fa_method"}
              id={"2fa_method" + m.name}
              value={m.name}
              defaultChecked={m.is_primary}
              onChange={onChangeOption}
            />

            <label htmlFor={"2fa_method" + m.name}>{method?.label}</label>

            {method?.sendCode && (
              <SubmitButton
                disabled={m.name !== selectedOption}
                label="Send Code"
                request={method?.sendCode}
              />
            )}
          </div>
        );
      })}

      {currentPrimary?.name !== selectedOption && (
        <div className="input">
          <Input
            callback={setCode}
            label={`Enter Code from current Primary Method`}
            regex={OTP_REGEX}
          />
          <RequestError error={error} />
          <SubmitButton
            label="Change Primary Method"
            request={changePrimary}
            disabled={!code || !selectedOption}
          />
        </div>
      )}
    </div>
  );
};

export default ChangePrimary;
