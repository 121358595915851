const TransactionFees = () => {
  return (
    <div className="TransactionFees">
      <h2>Transaction Fees</h2>
      <div className="content">
        <p>
          In the below table you will see the current transaction fees that are
          imposed on every transaction that occours when a user aquires content
          or sends a donation. The transaction fees are as follows:
        </p>
        <ul>
          <li>Games: 20%</li>
          <li>Art: 20%</li>
          <li>Video: 20%</li>
          <li>Articles: 20%</li>
          <li>Voice: 20%</li>
          <li>Donations 20%</li>
          <li>Other 20%</li>
        </ul>
      </div>
    </div>
  );
};

export default TransactionFees;
