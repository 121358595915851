import { useContext, useEffect, useState } from "react";
import { Chip } from "src/components/Chip";
import { LoaderInside } from "src/components/Common";
import { WidthContext } from "src/components/Context";
import { BASE_CDN_URL, request } from "src/utils/Axios";
import {
  SuggestionPanelTypes,
  allSuggestionPanelTypes,
} from "src/components/Video/utils";
import SuggestionPanel from "src/components/Video/FullPage/SuggestionChips";
import ImageGallery from "../Collection";

const SuggestCollections = ({ galleryId, teamName }) => {
  const mobile = useContext(WidthContext);
  const [loading, setLoading] = useState(true);
  const [recommendations, setRecommendations] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(
    SuggestionPanelTypes.FEATURED.param
  );

  useEffect(() => {
    if (selectedSuggestion) getWatchNext(galleryId, selectedSuggestion);
  }, [selectedSuggestion, galleryId]);

  const getWatchNext = async (id, type) => {
    try {
      setLoading(true);
      const res = await request({
        url: "/products/api/spicyart/galleries/recommend",
        // baseURL: BASE_CDN_URL,
        params: { gallery_id: id, type },
      });
      setRecommendations(res);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="SugguestCollections">
      <section className="suggestions">
        <SuggestionPanel suggestionData={allSuggestionPanelTypes}>
          {(type) => (
            <div
              key={type.param}
              onClick={() => setSelectedSuggestion(type.param)}
            >
              <Chip
                selected={selectedSuggestion === type.param}
                className="tag"
              >
                {type.name?.replace("#{team}", teamName)}
              </Chip>
            </div>
          )}
        </SuggestionPanel>
      </section>
      <section className="watch-next">
        <legend>
          <h2>Explore Next</h2>
        </legend>
        <div className="content">
          {loading ? (
            <LoaderInside />
          ) : (
            recommendations.map((recommendation, i) => (
              <ImageGallery
                collection={recommendation}
                horizontal={!mobile}
                key={recommendation.id}
              />
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default SuggestCollections;
