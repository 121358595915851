import AllOrders from "../../CMS/Payments/OrderSections/AllOrders";

const Orders = () => {
  return (
    <div id="ad-management">
      <AllOrders hiddenSections={["geo"]} />
    </div>
  );
};

export default Orders;
