import axios from "axios";
import { useEffect, useState } from "react";
import { BASE_CDN_URL, BASE_URL } from "src/utils/Axios";
import updateViewed from "src/utils/updateViewed";
import Error404 from "../Errors";
import ArticleDetails from "./ArticleDetail";
import { LoaderLayout } from "./BlogArticle";

function StaffArticle({ match, preview = false }) {
  const articleID = match.params.article;
  const [article, setArticle] = useState({});
  const [comments, setComments] = useState([]);
  const [author, setAuthor] = useState({});

  useEffect(() => {
    request(`/news/api/news/staff-reviews?article_id=${articleID}`, setArticle);
    request(`/news/api/news/staff-reviews/${articleID}/comments`, setComments);
    updateViewed(articleID, "Staff Reviews");
  }, [articleID]);
  useEffect(() => {
    if (article.author && !article.error) {
      request(
        `/api/members/public-profile?username=${article.author}`,
        setAuthor
      );
    }
  }, [article]);

  const request = async (url, state) => {
    try {
      state({});

      const req = await axios({
        method: "get",
        url: url,
        baseURL: preview ? BASE_URL : BASE_CDN_URL,
        params: { preview },
      });
      const res = await req.data;
      state(res);
    } catch {}
  };

  if (article.error) return <Error404 />;
  if (!article.id) {
    return <LoaderLayout />;
  }
  return (
    <ArticleDetails
      article={article}
      comments={comments}
      author={author}
      type="staff"
    />
  );
}

export default StaffArticle;
