import React, { useState } from "react";
import Slider from "../Article/Slider";
import FavDeveloperMini from "./FavDeveloperMini";
import "./css/FavDeveloper.css";
import { authorizedRequest, getCredentails } from "../Validation";
import { useEffect } from "react";
import { MAX_ELEMS_IN_SLIDE } from "./FavGames";

const FavDevelopers = () => {
  const { username } = getCredentails();
  const [gamesValue, setGamesValue] = useState(1);
  const [displayArray, setDisplayArray] = useState([]);
  const [devs, setDevs] = useState([]);

  useEffect(() => {
    getDevelopers();
  }, []);

  useEffect(() => {
    setDisplayArray(devs.slice(0, MAX_ELEMS_IN_SLIDE));
  }, [devs]);

  const getDevelopers = async () => {
    try {
      const res = await authorizedRequest({
        url: `${window.domainName}/api/members/${username}/followed-teams`,
      });
      setDevs(res);
    } catch {}
  };

  return (
    <div className="fav-developers">
      <div className="body">
        {displayArray.map((dev, index) => (
          <FavDeveloperMini key={index} {...dev} editFollowers={setDevs} />
        ))}
      </div>
      <Slider
        value_of_section={gamesValue}
        func_of_section={setGamesValue}
        display_array_func={setDisplayArray}
        slides_array={devs}
        num_of_elements_display={MAX_ELEMS_IN_SLIDE}
      />
    </div>
  );
};

export default FavDevelopers;
