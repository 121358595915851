import { useRef, useState } from "react";
import * as tus from "tus-js-client";

import { toast } from "react-toastify";
import { refresh } from "src/utils/authenticate";
import { numberCompactData } from "src/utils/number-format";
import { SubmitButton } from "./CMS/Common";
import { DragDropFile } from "./FileUpload/DragDropFile";
import "./css/FileUploadV4.css";

export const FileTypes = {
  TRAILER: "Trailer",
  ARTICLE: "Article",
  RESOURCE: "Resource",
  VIDEO: "Community Video",
};

const Status = {
  SUCCESS: "Success",
  FAILED: "Failed",
  PAUSED: "Paused",
  RESUMED: "Resumed",
};
function TusUpload({
  onSuccess,
  onError,
  children,
  maxFileSize,
  acceptedFileTypes,
  endpoint,
  getMetadata,
  getAuthHeaders,
  filePrefix: filenamePrefix,
  withCredentials = false,
}) {
  const uploader = useRef();

  const [status, setStatus] = useState();
  const [uploading, setUploading] = useState(false);

  const [data, setData] = useState({
    name: "",
    uploaded: 0,
    total: 0,
    percentage: 0,
  });

  const getFiles = (files) => {
    // MARK: only one file is uploaded no matter how much user provided
    const [file] = files;
    uploadFile(file);
  };

  function startOrResumeUpload(upload) {
    // Check if there are any previous uploads to continue.
    upload.findPreviousUploads().then(function (previousUploads) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }

      // Start the upload
      upload.start();
    });
  }

  const uploadFile = async (file) => {
    try {
      changeData({ name: file.name });
      setUploading(true);

      const metadata = getMetadata?.(file) ?? {};
      const headers = await getAuthHeaders?.(file);

      const [fileId, removeFileId] = getTUSFileId(file);

      // console.log("Current File ID: ", fileId);

      // // Create a new tus upload
      const upload = new tus.Upload(file, {
        endpoint: endpoint,
        retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
        headers: headers,
        metadata: {
          filetype: file.type,
          filename: [filenamePrefix, file.name.replace(/\s/g, "_")].join("-"),
          file_id: fileId,
          ...metadata,
        },
        onBeforeRequest: function (req) {
          var xhr = req.getUnderlyingObject();
          if (withCredentials) {
            xhr.withCredentials = true;
          }
        },
        onAfterResponse: function (_, res) {
          const statusCode = res.getStatus();
          // if access token needs to be refreshed
          if (statusCode === 401) {
            refresh().then(() => {
              setStatus(Status.RESUMED);
              startOrResumeUpload(upload);
            });
          }
        },
        onError: function (error) {
          setStatus(Status.FAILED);

          const hasResponse = error.originalResponse?.getBody();

          if (hasResponse) {
            const backendError = JSON.parse(hasResponse);
            // immediate abort if error from backend
            if (backendError.from_backend) {
              setUploading(false);
              upload.abort();
            }
            return onError?.(new Error(backendError.message));
          }
          return onError?.(error.message);
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          const percentage = (bytesUploaded / bytesTotal) * 100;
          changeData({
            uploaded: bytesUploaded,
            total: bytesTotal,
            percentage: Math.floor(percentage * 100) / 100, // to get 2 decimal places
          });
        },
        onSuccess: function () {
          toast.success("File Successfully uploaded");
          onSuccess?.(fileId);
          setStatus(Status.SUCCESS);
          setUploading(false);
          removeFileId();
        },
      });
      uploader.current = upload;

      setStatus(Status.RESUMED);
      startOrResumeUpload(upload);
    } catch (error) {
      onError?.(error);
      setStatus(Status.FAILED);
    }
  };

  const pauseOrResume = () => {
    if (uploader.current) {
      if (status === Status.PAUSED) {
        startOrResumeUpload(uploader.current);
        setStatus(Status.RESUMED);
      }
      if (status === Status.RESUMED) {
        uploader.current.abort();
        setStatus(Status.PAUSED);
      }
    }
  };

  const changeData = (data) => {
    setData((prev) => ({ ...prev, ...data }));
  };

  const getTUSFileId = (file) => {
    const fileId = [
      "@tus",
      file.name,
      file.type,
      file.size,
      file.lastModified,
    ].join("-");

    const removeFileId = () => {
      localStorage.removeItem(fileId);
    };

    const uuid = localStorage.getItem(fileId);
    if (!!uuid) {
      return [uuid, removeFileId];
    }

    const newUuid = window.crypto.randomUUID();
    localStorage.setItem(fileId, newUuid);

    return [newUuid, removeFileId];
  };

  return (
    <div className="TusUpload">
      {uploading ? (
        <div className="uploading">
          <h2>Uploading {data.name}</h2>

          <div className="content">
            <div className="slider" style={{ "--fill": data.percentage }}></div>
            {status === Status.FAILED ? (
              <SubmitButton
                label="Retry"
                icon="fa-reload"
                request={() => startOrResumeUpload(uploader.current)}
              />
            ) : (
              <SubmitButton
                request={pauseOrResume}
                icon={status === Status.PAUSED ? "fa-play" : "fa-pause"}
                label={status === Status.PAUSED ? "Resume" : "Pause"}
              />
            )}
          </div>
          <p>
            Uploaded {numberCompactData(data.uploaded)} of{" "}
            {numberCompactData(data.total)} ({data.percentage}%){" "}
            <span
              className="cancel"
              onClick={() => {
                setUploading(false);
                uploader.current.abort();
              }}
            >
              Cancel the Upload
            </span>
          </p>
        </div>
      ) : status === Status.SUCCESS ? (
        <div className="content">
          <h2>
            <i className="fa fa-check"></i>
            Successfully uploaded {data.name}
          </h2>
        </div>
      ) : (
        <DragDropFile
          onFilesUpload={getFiles}
          maxFileSize={maxFileSize}
          acceptedFileTypes={acceptedFileTypes}
          allowMultiple={false}
          maxFiles={1}
        />
      )}
      {children}
    </div>
  );
}

export default TusUpload;
