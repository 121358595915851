import { useContext, useEffect, useState } from "react";
import { numberCompact } from "src/utils/number-format";
import { authorizedRequest, loggedInValidation } from "../Validation";
import { ArticleMetaDataContext } from "./MetaDataProvider";

/**
 *
 * @param {Object} props
 * @param {Number} props.id
 * @param {Number} props.views
 * @param {Number} props.likes
 * @param {'news'|'blog'|'staff'} props.type - type of article `news`, `blog`, `staff`
 *
 * @returns
 */
export const MetaInfo = ({
  id,
  views,
  likes: initialLikes,
  score,
  type = "news",
}) => {
  const url = {
    news: "/news/api/news/game-news/like",
    blog: "/news/api/news/blog-posts/like",
    staff: "/news/api/news/staff-reviews/like",
  };
  const liked = useContext(ArticleMetaDataContext)[type];
  const [likes, setLikes] = useState({ status: false, likes: initialLikes });

  useEffect(() => {
    if (liked.includes(id)) setLikes((prev) => ({ ...prev, status: true }));
  }, []);

  const updateLikes = async () => {
    try {
      if (loggedInValidation()) {
        const res = await authorizedRequest({
          url: window.domainName + url[type],
          method: "POST",
          params: {
            article_id: id,
          },
        });
        if (res.error) return;
        setLikes(res);
      }
    } catch (error) {}
  };
  const msgPrefix = loggedInValidation() ? "" : "Login to ";

  return (
    <div className="likes">
      <div className="line"></div>
      <div className="data">
        <div title={`${views} Views`}>
          <span aria-hidden className="fa fa-eye"></span>
          <span>{numberCompact(views)}</span>
        </div>
        <div
          onClick={updateLikes}
          data-tooltip={`${msgPrefix}Like this Article`}
        >
          <span
            aria-hidden
            className={`fa fa-heart clickable ${likes.status && "clicked"}`}
          ></span>
          <span title={`${likes.likes} Likes`}>
            {numberCompact(likes.likes)}
          </span>
        </div>
        {score && (
          <div>
            <span
              aria-hidden
              className="fa fa-star"
              style={{
                background: `linear-gradient(360deg, gold ${score}%, #535353 0%)`,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            ></span>
            {score}/100
          </div>
        )}
      </div>
    </div>
  );
};
