import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Image from "./Image";

const MasonryImagesGrid = ({ images, owner, maxColumnCount }) => {
  const getColumn = (n) => {
    if (!maxColumnCount) return n;
    if (n <= maxColumnCount) return n;
    return maxColumnCount;
  };
  return (
    <ResponsiveMasonry
      columnsCountBreakPoints={{
        480: getColumn(1),
        600: getColumn(2),
        840: getColumn(3),
        1080: getColumn(4),
      }}
    >
      <Masonry gutter="2rem" className="search_results_content">
        {images?.map((image, i) => (
          <Image
            owner={owner}
            key={image.id}
            loading={i < 5 ? "eager" : "lazy"}
            image={image}
            width={600}
            maxHeight={600}
          />
        ))}
        {/* <Banner
          jsAd="95324"
          id="video_search_within_results"
          width={300}
          height={250}
          revalidateDependencies={[
            currentTag,
            query,
            currentPageNumber,
          ]}
        /> */}
      </Masonry>
    </ResponsiveMasonry>
  );
};

export default MasonryImagesGrid;
