import React, {
  Suspense,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { addToWishlist } from "src/v2/services/gamesService";
import {
  BASE_CDN_URL,
  BASE_URL,
  authRequest,
  request,
} from "../../utils/Axios";
import Game from "../AdultGames/Game";
import { SimpleDivider } from "../Atoms/SimpleDivider";
import Banner from "../Banners";
import { AD_IDS } from "../CMS/Ads/Section";
import { SubmitButton } from "../CMS/Common";
import { Loader, LoaderInside } from "../Common";
import { MultiLang } from "../MultiLang";
import { PreviewContext } from "../Previews/Preview";
import { loggedInValidation } from "../Validation";
import CreatorInfo, { ContentMetaInfo } from "../Video/FullPage/CreatorInfo";
import Popup from "../common/Popup";
import "./css/Info.css";
import ArticleSlider, {
  SliderContainer,
} from "./sections/GameDetailArticleSlider";
import GameAwards from "./sections/GameDetailAwards";
import Description from "./sections/GameDetailDescription";
import GameDownloads from "./sections/GameDetailDownloads";
import GameMedia from "./sections/GameDetailMedia";
import GameSpecs from "./sections/GameDetailSpecs";
import GameBundles from "./sections/GameDetailBundles";

const Reviews = React.lazy(() => import("./Reviews"));
const ForceLogin = React.lazy(() => import("../ForceLogin"));

const GameDetailInfo = (props) => {
  const { game, similarGames, seeMoreReviews, creator } = props;

  const { game: gameID } = useParams();
  const previewMode = useContext(PreviewContext);

  const match = useRouteMatch();
  const notLogged = !loggedInValidation();

  const downloadSectionRef = useRef(null);
  const commentSectionRef = useRef(null);

  const [showLoginPopup, toggleLoginPopup] = useState(false);

  const [sentComment, setSentComment] = useState(false);
  const [score, setScore] = useState(0);
  const [review, setReview] = useState("");

  // static data
  const [downloads, setDownloads] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [awards, setAwards] = useState([]);
  const [images, setImages] = useState([]);
  const [news, setNews] = useState([]);
  const [staffReviews, setStaffReviews] = useState([]);
  const [blogArticles, setBlogArticles] = useState([]);

  useEffect(() => {
    getData(`/downloads/api/games/downloads?game_id=${gameID}`, setDownloads);
    getData(`/products/api/games/${gameID}/reviews?num=10`, setReviews);
    getData(`/products/api/games/${gameID}/awards`, setAwards);
    getData(`/news/api/news/game-news/game?game_id=${gameID}`, setNews);
    getData(`/news/api/news/blog-post/game?game_id=${gameID}`, setBlogArticles);
    getData(
      `/news/api/news/staff-reviews/game?game_id=${gameID}`,
      setStaffReviews
    );
    getGallery();
  }, [gameID]);

  const getData = async (url, state) => {
    try {
      const res = await request({
        method: "GET",
        url,
        withCredentials: true,
        params: { preview: previewMode },
        baseURL: previewMode ? BASE_URL : BASE_CDN_URL,
      });

      state(res);
    } catch {}
  };

  const getGallery = async () => {
    try {
      if (!game.media_gallery) return;

      const res = await request({
        url: "/products/api/spicyart/gallery",
        baseURL: previewMode ? BASE_URL : BASE_CDN_URL,
        params: {
          gallery_id: game.media_gallery,
        },
      });

      setImages(res.images);
    } catch (error) {}
  };

  const likeGame = async () => {
    const res = await authRequest({
      url: `/products/api/games/${gameID}/like-game`,
      method: "POST",
    });
    return res.status;
  };

  const onClickAddToWishlist = async () => {
    try {
      await addToWishlist(gameID);
      toast.success(<p>{game.title} added to your Wishlist</p>);
    } catch (error) {
      toast.error("Adding to wishlist failed");
    }
  };

  const sentReview = async () => {
    try {
      if (notLogged) return toggleLoginPopup(true);

      if (review?.trim()?.length <= 0)
        throw new Error("Please write a review about this game");

      if (parseInt(score) > 100 || parseInt(score) <= 0)
        throw new Error("Score is invalid");

      const res = await authRequest({
        url: `/products/api/games/${gameID}/reviews/create`,
        method: "POST",
        data: {
          score: parseInt(score),
          text: review,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      setReviews((prev) => [...prev, res]);
      setSentComment(true);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div id="game-detail-info">
      <Popup
        open={notLogged && showLoginPopup}
        onClose={() => toggleLoginPopup(false)}
        showCloseButton
      >
        <Suspense fallback={<Loader />}>
          <ForceLogin url={"/login" + match.url} />
        </Suspense>
      </Popup>
      <GameMedia
        trailer={game.trailer}
        landscapeBannerPath={game.landscape_banner}
        images={images}
      />
      <section className="section">
        <CreatorInfo
          likeContent={likeGame}
          numOfLikes={game.likes}
          team={creator}
          shareLink={window.location.href}
          shareText={game.title}
          isLiked={game.user_liked}
          actionButtons={[
            <SubmitButton
              label={"Add to Wishlist"}
              icon="fa-plus"
              request={onClickAddToWishlist}
            />,
          ]}
        />
        <ContentMetaInfo
          dangerouslySetInnerHTML
          creation_date={game.last_updated}
          views={game.views}
        />
      </section>
      <SimpleDivider />
      <GameDownloads
        ref={downloadSectionRef}
        creatorApproved={creator.approved}
        game={game}
        gameID={gameID}
        downloads={downloads}
      />
      <GameBundles gameId={gameID} />
      <SimpleDivider />
      <Description description={game.description} />
      <SimpleDivider />
      <GameSpecs game={game} />

      <Banner
        jsAd="93504"
        width={970}
        height={250}
        id={AD_IDS.game.under_description}
      />
      {!!awards?.length && (
        <>
          <SimpleDivider />
          <GameAwards awards={awards} />
        </>
      )}
      <ArticleSlider
        title={`${game.title} was also mentioned in`}
        articles={blogArticles}
        type="blog"
      />
      <ArticleSlider
        title={`${game.title} Review`}
        articles={staffReviews}
        type="staff"
      />

      <ArticleSlider
        title={`Latest ${game.title} News`}
        articles={news}
        type="news"
      />

      <SimpleDivider />

      <section className="reviews section" ref={commentSectionRef}>
        <legend>
          <h2 className="sub title">
            <MultiLang sl="Ocene">Reviews</MultiLang>
          </h2>
        </legend>

        {!!reviews.length && (
          <div
            itemProp="aggregateRating"
            itemType="https://schema.org/AggregateRating"
            itemScope
          >
            <meta itemProp="reviewCount" content={reviews.length} />
            <meta itemProp="ratingValue" content={game.avg_score} />
            <meta itemProp="bestRating" content="100" />
          </div>
        )}

        <div className="content">
          <Suspense fallback={<LoaderInside />}>
            <Reviews
              type="review"
              reviews={reviews}
              seeMoreReviews={seeMoreReviews}
            />
          </Suspense>
          {sentComment ? (
            <div className="sent">Review Sent</div>
          ) : !notLogged ? (
            <>
              <div className="comment1">
                <input
                  onChange={
                    previewMode ? undefined : (e) => setScore(e.target.value)
                  }
                  data-disable={!!previewMode}
                  disabled={!!previewMode}
                  type="number"
                  min={1}
                  value={score}
                  max={100}
                  id="review-number"
                  placeholder="Score..."
                />
                <div className="score">/ 100</div>
              </div>

              <div className="comment">
                <textarea
                  onChange={
                    // previewMode ? undefined :
                    (e) => setReview(e.target.value)
                  }
                  id="review-textarea"
                  rows="3"
                  value={review}
                  placeholder={"Write a comment..."}
                  data-disable={!!previewMode}
                  disabled={!!previewMode}
                ></textarea>
                <SubmitButton
                  label={"Submit Review"}
                  disabled={!!previewMode}
                  request={previewMode ? undefined : sentReview}
                />
              </div>
            </>
          ) : (
            <h2>
              <br />
              <Link to={"/login" + window.location.pathname}> Login </Link>to
              submit a Review
            </h2>
          )}
        </div>
      </section>
      <SimpleDivider />
      <GameDownloads
        ref={downloadSectionRef}
        creatorApproved={creator.approved}
        game={game}
        gameID={gameID}
        downloads={downloads}
      />
      <SimpleDivider />

      <section className="article news section">
        <legend>
          <h2 className="sub title">
            <MultiLang sl="Podobne igre">Similar Games</MultiLang>
          </h2>
        </legend>
        <div className="content adult-games">
          {!similarGames.length ? (
            <LoaderInside />
          ) : (
            <SliderContainer>
              {similarGames?.map((game, i) => (
                <Game key={i} {...game} />
              ))}
            </SliderContainer>
          )}
        </div>
      </section>
    </div>
  );
};

export default GameDetailInfo;
