import { useCallback, useMemo, useState } from "react";
import ReactOwlCarousel from "react-owl-carousel";
import { generateURL } from "src/components/resizeImages";
import generateImageObjectFromPath from "src/components/SpicyArt/generateImageObject";
import Image from "src/components/SpicyArt/Image";
import { ASPECT_RATIO } from "src/components/Video/Player/Player";
import VideoPlayer from "src/components/VideoPlayer";
import { vastPullzones } from "src/components/VideoPlayer/pullzones";

const GameMedia = ({ landscapeBannerPath, images = [], trailer = null }) => {
  const landscapeBanner = generateImageObjectFromPath(landscapeBannerPath);

  const [selectedMedia, setSelectedMedia] = useState(() => {
    if (trailer)
      return {
        mediaItem: trailer,
        type: "Video",
      };

    return {
      mediaItem: landscapeBanner,
      type: "Image",
    };
  });

  const onSelectedChange = useCallback(
    (type, mediaItem) => () => {
      setSelectedMedia({
        mediaItem,
        type,
      });
    },
    []
  );

  const bigMediaItem = useMemo(() => {
    switch (selectedMedia?.type) {
      case "Image":
        return (
          <Image
            removeImageCompression
            image={selectedMedia?.mediaItem}
            loading="eager"
            maxHeight={450}
            width={800}
            noRedirect
          />
        );
      case "Video":
        return (
          <VideoPlayer
            options={{ aspectRatio: "16:9" }}
            vastZone={vastPullzones.default}
            videoID={selectedMedia?.mediaItem.file?.bunny_id}
            folderID={selectedMedia?.mediaItem.file?.library_id}
            pullzoneID={selectedMedia?.mediaItem.file?.pullzone}
            thumbnailName={generateURL(
              720,
              parseInt(720 / ASPECT_RATIO),
              selectedMedia?.mediaItem.thumbnail
            )}
          />
        );

      default:
        return null;
    }
  }, [selectedMedia]);

  return (
    <section>
      <div>{bigMediaItem}</div>
      <br />
      {!!images.length && (
        <GameMediaSlider
          onSelectedChange={onSelectedChange}
          images={images}
          trailer={trailer}
          landscapeBanner={landscapeBanner}
        />
      )}
    </section>
  );
};

const GameMediaSlider = ({
  trailer,
  onSelectedChange,
  landscapeBanner,
  images,
}) => {
  const content = useMemo(() => {
    //  using memo to avoid rerender and mess the slider
    return (
      <ReactOwlCarousel
        dots
        autoplay
        autoplayHoverPause
        autoplaySpeed={1500}
        autoplayTimeout={5000}
        className="owl-theme game-media-slider"
        margin={10}
        responsive={{
          0: { items: 2 },
          480: { items: 3 },
          1080: { items: 5 },
        }}
      >
        {trailer && (
          <div className="trailer" onClick={onSelectedChange("Video", trailer)}>
            <Image
              noRedirect
              loading="lazy"
              width={200}
              image={generateImageObjectFromPath(trailer?.thumbnail)}
            />
            <img
              src="/images/play.png"
              width={50}
              height={50}
              alt="Play trailer icon"
              className="play-icon h-12 w-1/2"
            />
          </div>
        )}
        <div onClick={onSelectedChange("Image", landscapeBanner)}>
          <Image
            noRedirect
            loading="lazy"
            width={200}
            image={landscapeBanner}
          />
        </div>
        {images.map((image) => (
          <div key={image.id} onClick={onSelectedChange("Image", image)}>
            <Image
              noRedirect
              loading="lazy"
              maxHeight={100}
              width={200}
              image={image}
            />
          </div>
        ))}
      </ReactOwlCarousel>
    );
  }, []);
  return content;
};

export default GameMedia;
