import React from "react";
import "./style.css";
import Configure2FA from "../2FA/Configure";
import MemberPasswordReset from "../PasswordReset";
import DeleteAccount from "src/v2/components/member/security/DeleteAccount";

const MemberSecurity = () => {
  return (
    <div className="Security" data-testid="Security">
      <Configure2FA />
      <MemberPasswordReset />
      <DeleteAccount />
    </div>
  );
};

export default MemberSecurity;
