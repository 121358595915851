import { useRouteMatch } from "react-router-dom";
import { LoadMore, LoaderInside } from "src/components/Common";
import { Player } from "src/components/Video/Player";
import { authRequest } from "src/utils/Axios";
import { withoutLastPage } from "src/utils/withoutLastPage";
import useSWRInfinite from "swr/infinite";
import { fetcher } from "../Cms";
import { REVALIDATE_ON_FOCUS } from "../Common";
import { MainHeader } from "../Headers";
import "./style.css";
import RequestError from "src/errors/RequestError";
import ImageCollection from "src/components/SpicyArt/Collection";
import { useToggle } from "src/hooks/useToggle";
import { Checkbox } from "src/components/Register";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const AllCollections = ({ subHeader, toggler }) => {
  const [showEmpty, toggleShowEmpty] = useToggle();
  const match = useRouteMatch();
  const { data, error, setSize, mutate, isValidating } = useSWRInfinite(
    (index) => [
      `${window.domainName}/products/api/spicyart/galleries?page=${
        index + 1
      }&empty=${showEmpty}`,
      showEmpty,
    ],
    fetcher,
    { revalidateOnFocus: REVALIDATE_ON_FOCUS }
  );
  const collections = data ? withoutLastPage(data.flat()) : [];

  const search = async (query) => {
    try {
      // send name, type, owner_id, game_id and page
      const res = await authRequest({
        url: "/products/api/spicyart/galleries/search",
        params: {
          search_term: query,
          page: 1,
        },
      });

      return res;
    } catch (error) {
      return [];
    }
  };
  const searchCallback = (data) => {
    mutate(data, false);
  };

  return (
    <div className="body AllCollections" data-testid="Gallery">
      <MainHeader
        header={subHeader}
        toggler={toggler}
        uploadLink={match.path + "/upload"}
        search={{
          func: search,
          callback: searchCallback,
        }}
      />
      <section className="content">
        <legend>All Galleries</legend>
        <Checkbox
          value={showEmpty}
          callback={() => toggleShowEmpty()}
          id={"empty"}
          label={
            <p title="Some Galleries created with ariticles might not have images. They are hidden by default">
              Show Empty Galleries
            </p>
          }
        />

        <TransitionGroup className="collections" component="div">
          {collections.map((collection) => (
            <CSSTransition
              key={collection.id}
              timeout={300}
              classNames="popup"
              mountOnEnter
            >
              <ImageCollection
                actions={["edit", "download", "delete"]}
                collection={collection}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
        {isValidating && <LoaderInside />}
        <LoadMore onClick={() => setSize((prev) => prev + 1)} />
        <RequestError error={error} />
      </section>
    </div>
  );
};

export default AllCollections;
