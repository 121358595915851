import React, { useContext } from "react";
import { Link } from "react-router-dom";
import relativeDate from "relative-date";
import { WidthContext } from "../Context";
import { generateURL } from "../resizeImages";
import { BasicImage } from "../SpicyArt/Image";

export const TYPES = {
  "Blog Posts": { url_prefix: "/blog-article/", type: "blog" },
  "Staff Reviews": { url_prefix: "/staff-review/", type: "staff" },
  "Game News": { url_prefix: "/news-article/", type: "news" },
};
const GameNewsMini = ({
  id,
  date_posted,
  short_description = "",
  title,
  square_cover,
  type,
}) => {
  const mobile = useContext(WidthContext);
  const width = mobile ? 350 : 200;

  const articleType = TYPES[type];

  return (
    <div role="group" aria-label={title} className=" GameNewsMini">
      <Link to={articleType?.url_prefix + id}>
        <div className="image">
          <BasicImage
            loading="lazy"
            src={square_cover}
            height={width}
            width={width}
            alt={title}
          />
        </div>
      </Link>
      <div className="text">
        <h3 className="title" title={title}>
          <Link to={articleType?.url_prefix + id}>{title}</Link>
        </h3>
        <div className="info">
          <span aria-hidden className="fa fa-clock-o"></span>
          <span>{relativeDate(new Date(date_posted))}</span>
        </div>
        <p className="description" title={short_description}>
          {short_description}
        </p>
      </div>
    </div>
  );
};

export default GameNewsMini;
