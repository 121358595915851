import { BASE_CDN_URL, request } from "src/utils/Axios";
import ArticleSearchResults from "./ArticleSearchResults";
import { withArticleMetadataType } from "./getArticles";
import { Helmet } from "react-helmet";

export const GameNewsSearch = () => {
  const searchArticles = async ({
    query,
    page,
    cancelToken,
    sortKey,
    sortOrder,
  }) => {
    const res = await request({
      url: "/news/api/news/game-news/articles/search",
      params: {
        search_term: query,
        page,
        num: 30,
        sort: sortKey,
        order: sortOrder,
      },
      cancelToken,
    });
    return withArticleMetadataType(res, "Game News");
  };

  return (
    <>
      <Helmet>
        <title>Porn game news - Spicygaming</title>
        <meta
          name="description"
          content="News from the adult gaming world. Porn game reviews, Adult game news, News from adult gaming developers. Game walkthroughs."
        />
      </Helmet>
      <ArticleSearchResults
        title={"Porn Game News"}
        getArticles={searchArticles}
      />
    </>
  );
};

export const AdultIndustrySearch = () => {
  const searchArticles = async ({
    query,
    page,
    cancelToken,
    sortKey,
    sortOrder,
  }) => {
    const res = await request({
      url: "/news/api/news/blog-posts/articles/search",
      baseURL: BASE_CDN_URL,

      params: {
        search_term: query,
        page,
        category: "Adult Gaming Industry",
        sort: sortKey,
        order: sortOrder,
        num: 30,
      },
      cancelToken,
    });
    return withArticleMetadataType(res, "Blog Posts");
  };

  return (
    <>
      <Helmet>
        <title>News from Adult Industry - Spicygaming</title>
        <meta
          name="description"
          content="Latest news from the adult industry. News about adult games, porn videos, art and the sex industry"
        />
      </Helmet>
      <ArticleSearchResults
        title={"Adult industry Articles"}
        getArticles={searchArticles}
      />
    </>
  );
};

export const BestOfBlogSearch = () => {
  const searchArticles = async ({
    query,
    page,
    cancelToken,
    sortKey,
    sortOrder,
  }) => {
    const res = await request({
      url: "/news/api/news/blog-posts/articles/search",
      baseURL: BASE_CDN_URL,
      params: {
        category: "Best of",
        page,
        sort: sortKey,
        order: sortOrder,
        query,
        num: 30,
      },
      cancelToken,
    });
    return withArticleMetadataType(res, "Blog Posts");
  };

  return (
    <>
      <Helmet>
        <title>Best of Porn - Spicygaming</title>
        <meta
          name="description"
          content="News from the adult gaming world. Porn game reviews, Adult game news, News from adult gaming developers. Game walkthroughs."
        />
      </Helmet>
      <ArticleSearchResults
        title={"The Best of Porn"}
        getArticles={searchArticles}
      />
    </>
  );
};

export const ReviewArticlesSearch = () => {
  const searchArticles = async ({
    query,
    page,
    cancelToken,
    sortKey,
    sortOrder,
  }) => {
    const res = await request({
      url: "/news/api/news/staff-reviews/articles/search",
      baseURL: BASE_CDN_URL,
      params: {
        page,
        query,
        num: 30,
        sort: sortKey,
        order: sortOrder,
      },
      cancelToken,
    });
    return withArticleMetadataType(res, "Staff Reviews");
  };

  return (
    <>
      <Helmet>
        <title>Porn game reviews - Spicygaming</title>
        <meta
          name="description"
          content="Hundreds of in-depth reviews of adult games. A selection of our top porn games."
        />
      </Helmet>
      <ArticleSearchResults
        title={"PORN GAME REVIEWS"}
        getArticles={searchArticles}
      />
    </>
  );
};

export const InterviewsArticlesSearch = () => {
  const searchArticles = async ({
    query,
    page,
    cancelToken,
    sortKey,
    sortOrder,
  }) => {
    const res = await request({
      url: "/news/api/news/blog-posts/articles/search",
      baseURL: BASE_CDN_URL,
      params: {
        category: "Interview",
        page,
        query,
        sort: sortKey,
        order: sortOrder,
        num: 30,
      },
      cancelToken,
    });
    return withArticleMetadataType(res, "Blog Posts");
  };

  return (
    <>
      <Helmet>
        <title>Interviews with Adult Content Creators - Spicygaming</title>
        <meta
          name="description"
          content="A selection of interviews with adult content creators and adult game developers. Every month a new interview"
        />
      </Helmet>
      <ArticleSearchResults
        title={"Interviews with Adult Content Creators"}
        getArticles={searchArticles}
      />
    </>
  );
};
