import { useState } from "react";
import { authRequest } from "../../../utils/Axios";
import { LoaderInside } from "../../Common";
import { authorizedRequest } from "../../Validation";

import dateFormat from "dateformat";
import relativeDate from "relative-date";
import { useSpicyStore } from "src/providers/SpicyStoreProvider";
import { CONVERSION_RATE } from "../../../constants/store";
import { useLanguage } from "../../../providers/LanguageProvider";
import { addSign } from "../../../utils/number-format";
import { GET_PRODUCTS } from "../../../utils/transactions";
import { ApproveTile } from "../../CMS/Tile";
import { toSpicyTokensWithEuro } from "../../CMS/Users/Withdraw";
import { Header, sort } from "../../Common";
import GTags from "../../GTags";
import { transactionOptions } from "../../Member/Orders/Options";
import { useRoleInfo } from "../RoleContext";
import { Input } from "src/components/Member/General";
import { SubmitButton } from "../Common";

export const ApproveChangeRequest = ({ close, id, approve, reason, team }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const approveRequest = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url: `${window.domainName}/store/api/teams/payment-info/change-requests/approve`,
        method: "POST",
        data: {
          request_id: id,
          approve,
        },
        headers: { "Content-Type": "application/json" },
      });

      setLoading(false);
      if (res.success)
        setError(approve ? "Successfully Approved" : "Successfully Denied");
      if (res.error) return setError(res.error);
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;

  return (
    <div className="game-approve">
      <h2>
        {approve ? "Approve" : "Deny"} Change Request for {team} ?
      </h2>
      <div className="info">
        <p>Reason: {reason}</p>
      </div>
      <div className="buttons">
        <div className={`button ${!approve ? "green" : "red"}`} onClick={close}>
          Approve Later
        </div>
        <div
          className={`button ${approve ? "green" : "red"}`}
          onClick={approveRequest}
        >
          {approve ? "Approve it!" : "Deny"}
        </div>
      </div>
    </div>
  );
};
export const ApproveRefund = ({ close, approve, id, reason, refund }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { refresh } = useSpicyStore();

  const approveRefund = async () => {
    setLoading(true);
    try {
      const res = await authRequest({
        url: `/store/api/refund-requests/approve`,
        method: "POST",
        data: {
          refund_id: id,
          approve,
        },
      });

      if (res.success) {
        GTags.refund({
          transaction_id: refund.transaction,
          value: refund.value / CONVERSION_RATE,
          currency: "EUR",
          tax: refund.fee,
          items: [
            {
              item_id: refund.product_id,
              item_name: refund.product,
              item_brand: refund.product_type,
            },
          ],
        });
        setError(approve ? "Successfully Approved" : "Successfully Denied");
        await refresh?.();
      }

      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;

  return (
    <div className="game-approve">
      <h2>{approve ? "Approve" : "Deny"} Refund?</h2>
      <div className="info">
        <p>{reason}</p>
      </div>
      <div className="buttons">
        <div className={`button ${!approve ? "green" : "red"}`} onClick={close}>
          Approve Later
        </div>
        <div
          className={`button ${approve ? "green" : "red"}`}
          onClick={approveRefund}
        >
          {approve ? "Approve it!" : "Deny"}
        </div>
      </div>
    </div>
  );
};

export const MarkCompleted = ({ payout_id, close }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const markCompleted = async () => {
    try {
      setLoading(true);
      const res = await authorizedRequest({
        url: `${window.domainName}/store/api/payout-requests/complete-payout`,
        method: "POST",
        data: { payout_id },
        headers: { "Content-Type": "application/json" },
      });

      setLoading(false);
      if (res.success) setError("Successfully Completed");
      if (res.error) return setError(res.error);
      setTimeout(() => {
        close();
      }, 5000);
    } catch (error) {
      setError("Error Occured! Request didn't sent");
    }
  };
  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;

  return (
    <div className="game-approve">
      <h2>Mark this payout as completed?</h2>
      <div className="info">
        <p></p>
      </div>
      <div className="buttons">
        <div className={`button  red `} onClick={close}>
          Close
        </div>
        <div className={`button green`} onClick={markCompleted}>
          Mark it
        </div>
      </div>
    </div>
  );
};

export const Transactions = ({
  transactions: initialTransactions,
  order_id,
  showRelativeToBuyer = false, // this will be used to show the transactions in buyers perspective of view
}) => {
  const language = useLanguage();
  const [transactions, setTransactions] = useState(initialTransactions);
  const { role } = useRoleInfo();

  const sortState = (key) => (order) => {
    setTransactions(sort(key, transactions, order), false);
  };

  const headers = [
    { text: { en: "PRODUCT" }, sort: sortState("product") },
    {
      text: { en: "PRODUCT TYPE" },
      sort: sortState("product_type"),
    },
    { text: { en: "PURCHASER" }, sort: sortState("purchaser") },
    { text: { en: "AMOUNT" }, sort: sortState("amount") },
    { text: { en: "DATE" }, sort: sortState("creation_date") },
  ];

  return (
    <main className="cms-details order-transactions">
      <h2>Transactions of Order #{order_id}</h2>
      <div
        className="header"
        style={{
          position: "static",
          gridTemplateColumns: `repeat(${headers.length}, var(--game-item-frac)) 2fr`,
        }}
      >
        {headers.map((header, i) => (
          <Header header={{ ...header, text: header.text[language] }} key={i} />
        ))}
      </div>
      <div className="body" component="div">
        {transactions.map((transaction) => {
          const options = transactionOptions(role, transaction);

          const amount = showRelativeToBuyer
            ? GET_PRODUCTS.includes(transaction.product_type)
              ? addSign(Math.abs(transaction.amount))
              : 0 - transaction.amount
            : transaction.amount;

          const data = [
            transaction.product,
            transaction.product_type,
            transaction.purchaser,
            toSpicyTokensWithEuro(amount),
            relativeDate(new Date(transaction.creation_date)),
          ];

          return (
            <ApproveTile
              key={transaction.transaction_id}
              headers={headers.map((header) => header.text[language])}
              data={data}
              options={options}
              popupData={{
                refund: {
                  transaction,
                },
                full: {
                  sale: transaction,
                  fullData: [
                    { label: "Product", content: transaction.product },
                    {
                      label: "Product ID",
                      content: transaction.product_id,
                    },
                    {
                      label: "Product Type",
                      content: transaction.product_type,
                    },
                    { label: "Team", content: transaction.team },
                    {
                      label: "Amount",
                      content: toSpicyTokensWithEuro(amount),
                    },
                    {
                      label: "Created On",
                      content: dateFormat(transaction.creation_date),
                    },
                    {
                      label: "Transaction ID",
                      content: transaction.transaction_id,
                    },
                  ],
                },
              }}
            />
          );
        })}
      </div>
    </main>
  );
};

export const EditVatPercentage = ({
  country_code,
  country_name,
  vat_percentage = 0,
  close,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [vatPercentage, setVatPercentage] = useState(vat_percentage);

  const saveVatPercentage = async () => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: "/store/api/payment-info/countries/set-vat",
        method: "POST",
        data: { country_code, vat: vatPercentage },
      });

      if (res.success) setError("Successfully Completed");
      setTimeout(() => {
        close();
      }, 2000);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;

  return (
    <div className="game-approve">
      <h2>Edit Vat Percentage of {country_name}</h2>
      <div className="info">
        <Input
          callback={(t) => setVatPercentage(parseFloat(t))}
          label={"Vat Percentage %"}
          value={vat_percentage}
        />
      </div>
      <div className="buttons">
        <SubmitButton label={"Save"} request={saveVatPercentage} />
      </div>
    </div>
  );
};
export const RegenInvoice = ({ type, order_id, close }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const saveVatPercentage = async () => {
    try {
      setLoading(true);
      const res = await authRequest({
        url: "/store/api/orders/invoices/regenerate",
        params: { type, order_id },
      });

      if (res.success) setError("Successfully Completed");
      setTimeout(() => {
        close();
      }, 2000);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  if (loading) return <LoaderInside />;
  if (error.length > 0) return <div className="error">{error}</div>;

  return (
    <div className="game-approve">
      <h2>Regenerate Invoice for order {order_id} ?</h2>
      <br />
      <div className="buttons">
        <SubmitButton label={"Continue"} request={saveVatPercentage} />
      </div>
    </div>
  );
};
