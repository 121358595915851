// Throttle function
const throttle = (callback, delay) => {
  let inThrottle;
  return (...args) => {
    if (!inThrottle) {
      callback(...args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), delay);
    }
  };
};

// Throttle function for async functions
export const throttleAsync = (callback, delay) => {
  let throttling = false;
  let lastArgs;

  return async (...args) => {
    if (!throttling) {
      throttling = true;
      lastArgs = args;

      try {
        await callback(...args);
      } finally {
        throttling = false;
        if (lastArgs) {
          setTimeout(() => {
            if (lastArgs) {
              callback(...lastArgs);
              lastArgs = null;
            }
          }, delay);
        }
      }
    }
  };
};

// Debounce function for async functions
export const debounceAsync = (callback, delay) => {
  let timeoutId;

  return async (...args) => {
    clearTimeout(timeoutId);

    return new Promise((resolve) => {
      timeoutId = setTimeout(async () => {
        resolve(await callback(...args));
        // resolve(await callback(...args));
      }, delay);
    });
  };
};
export default throttle;
