import { LogosGoogleIcon } from "src/Icons/ LogosGoogleIcon";
import { authRequest } from "../../../utils/Axios";

export const methods_2fa = [
  {
    name: "app",
    label: "Authenticator App",
    recommended: true,
    icon: <LogosGoogleIcon />,
  },
  // {
  //   name: "email",
  //   label: "Email Address Verification",
  //   async sendCode() {
  //     const res = await authRequest({
  //       url: "/trenchauth/code/request/",
  //       method: "POST",
  //       data: {
  //         method: "email",
  //       },
  //     });
  //     return res;
  //   },
  // },
];
